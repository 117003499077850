import ADDRESS from "@/setting";
import axios from "axios"
import StrategicModel, { StrategicItemModel, StrategicItemIndicatorModel, MissionModel, ObjectiveModel } from "@/model/strategic";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class StrategicServices {
  public getStrategicList(query: any) {
    return new Promise<PaginateResponse<StrategicModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/strategics/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<StrategicModel> = response.data.docs.map((el: any) => new StrategicModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<StrategicModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getStrategicSingle(id: string) {
    return new Promise<StrategicModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/strategic/get/${id}`);
      let doc: StrategicModel = new StrategicModel(response.data.doc)
      resolve(doc)
    })
  }
  public getStrategicFromBudgetYear(budgetYear: number) {
    return new Promise<{ doc: StrategicModel, strategies: Array<StrategicItemModel>, tactics: Array<StrategicItemModel>, plans: Array<StrategicItemModel> }>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/strategic/get-by-budgetYear/${budgetYear}`);
      console.log(response);
      let doc: StrategicModel = new StrategicModel(response.data.doc)
      let strategies: Array<StrategicItemModel> = response.data.strategies.map((item: any) => new StrategicItemModel(item))
      let tactics: Array<StrategicItemModel> = response.data.tactics.map((item: any) => new StrategicItemModel(item))
      let plans: Array<StrategicItemModel> = response.data.plans.map((item: any) => new StrategicItemModel(item))
      resolve({ doc, strategies, tactics, plans })
    })
  }



  public deleteStrategicSingle(id: string) {
    return new Promise<StrategicModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/strategic/delete/${id}`);
      let doc: StrategicModel = new StrategicModel(response.data.doc)
      resolve(doc)
    })
  }

  public updateStrategic(id: string, Strategic: StrategicModel) {
    console.log('res', id);
    return new Promise<StrategicModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/strategic/update/${id}`, Strategic.toJSON());
      let doc: StrategicModel = new StrategicModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteStrategicMission(id: string) {
    return new Promise<MissionModel>(async (resolve, reject) => {
      let query = {
        id: id
      }
      let response = await axios.delete(`${ADDRESS}/strategic/delete-mission/`, { data: { id: id } });
      let doc: MissionModel = new MissionModel(response.data.doc)
      let response2 = await axios.get(`${ADDRESS}/strategic/get/${id}`);
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteStrategicObjective(id: string) {
    return new Promise<ObjectiveModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/strategic/delete-objective/`, { data: { id: id } });
      let doc: ObjectiveModel = new ObjectiveModel(response.data.doc)
      let response2 = await axios.get(`${ADDRESS}/strategic/get/${id}`);
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public createStrategic(Strategic: StrategicModel) {
    return new Promise<StrategicModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/strategic/create/`, Strategic);
      let doc: StrategicModel = new StrategicModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public createStrategicItem(strategic_id: string, Strategic: StrategicItemModel) {
    return new Promise<StrategicItemModel>(async (resolve, reject) => {
      Strategic.strategic_id = strategic_id
      let responseCreate = await axios.post(`${ADDRESS}/strateicItem/create/`, Strategic);
      console.log('res',responseCreate);
      let doc: StrategicItemModel = new StrategicItemModel(responseCreate.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public updateStrategicItem(strategic_id: string, StrategicItem: StrategicItemModel) {
    return new Promise<StrategicItemModel>(async (resolve, reject) => {
      let responseUpdate = await axios.patch(`${ADDRESS}/strateicItem/update/${StrategicItem.id}`, StrategicItem);
      let response = await axios.get(`${ADDRESS}/strategic/get/${strategic_id}`);
      let doc: StrategicItemModel = new StrategicItemModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteStrategicItem(id: string) {
    return new Promise<StrategicItemModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/strategic/delete-item/`, { data: { id: id } });
      console.log('res', response)
      if (response.data.status == 'error') {
        const toast = useToast();
        toast.error("กรุณาลบรายการย่อยก่อน", {
          timeout: 2000,
          position: POSITION.BOTTOM_RIGHT,
        });
      } else {
        const toast = useToast();
        toast.success("ลบข้อมูลแล้ว (ref:S-U2)", {
          timeout: 2000,
          position: POSITION.BOTTOM_RIGHT,
        });
      }
      let doc: StrategicItemModel = new StrategicItemModel(response.data.doc)
      resolve(doc)
    })
  }
  public createStrategicItemIndicator(strategic_id: string, Strategic: StrategicItemIndicatorModel) {
    return new Promise<StrategicItemIndicatorModel>(async (resolve, reject) => {
      let responseUpdate = await axios.post(`${ADDRESS}/strateicItem/create-indicator/`, Strategic);
      // let doc: StrategicItemIndicatorModel = new StrategicItemIndicatorModel(response.data.doc)
      console.log({ res: responseUpdate });

      let response = await axios.get(`${ADDRESS}/strategic/get/${strategic_id}`);
      let doc: StrategicItemIndicatorModel = new StrategicItemIndicatorModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public updateStrategicItemIndicator(strategic_id: string, Strategic: StrategicItemIndicatorModel) {
    return new Promise<string>(async (resolve, reject) => {
      let responseUpdate = await axios.patch(`${ADDRESS}/strateicItem/update-indicator/`, Strategic);
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }
  public deleteStrategicItemIndicator(id: string) {
    return new Promise<StrategicItemIndicatorModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/strategic/delete-item-indicator/`, { data: { id: id } });
      let doc: StrategicItemIndicatorModel = new StrategicItemIndicatorModel(response.data.doc)
      let response2 = await axios.get(`${ADDRESS}/strategic/get/${id}`);
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}

