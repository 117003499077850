
import { Options, Vue } from "vue-class-component";
import BudgetModel from "@/model/budget";
import TableRow from "@/views/withdraw/components/TableRow.vue";
import Selecto from "selecto";
import TextField from "../../../components/TextField.vue";
import TextFieldNumber from "../../../components/TextFieldNumber.vue";
import { ActiveBudgetSingleClass } from "@/data/budget";
import Container from "typedi";
import { ActiveProjectSingleClass } from "@/data/project";
import { ActiveWithdrawSingleClass, ActiveWithdrawListClass } from "@/data/withdraw";
import * as _ from "lodash"

@Options({
  components: {
    TableRow,
  },
})
export default class PlanTableComponent extends Vue {
  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  withdrawList: ActiveWithdrawListClass = Container.get(ActiveWithdrawListClass)
  withdraw: ActiveWithdrawSingleClass = Container.get(
    ActiveWithdrawSingleClass
  );
  budgetitems: Array<any> = [];
  sumSelected: number = 0

  sortByNumberString(budgets: Array<BudgetModel>) {
    budgets = _.sortBy(budgets, el => {
      try {
        return parseFloat(el.number)
      } catch (error) {
        return 0
      }
    })
    return budgets
  }
  // get withdraws(){
  //   let results = []
  //   try {
  //     for(const budget of this.activeProject.data!.budgets){
  //       results.push(...budget.withdraws)
  //       for(const child1 of budget!.children!){
  //         results.push(...child1.withdraws)
  //         for(const child2 of child1!.children!){
  //           results.push(...child2.withdraws)
  //         }
  //       }
  //     }
  //     return results
  //   } catch (error) {
  //     return []
  //   }
  // }
  mounted() {
    this.initSelecto();
    this.withdrawList.getWithdrawList({ search: { budget: {project_id:this.$route.params.id} } })
  }
  initSelecto() {
    try {
      const selecto = new Selecto({
        container: document.getElementById("selecto-container")!,
        rootContainer: null,
        selectableTargets: [".selecto-target"],
        selectByClick: true,
        selectFromInside: true,
        continueSelect: false,
        toggleContinueSelect: "shift",
        keyContainer: window,
        hitRate: 100,
      });
      selecto.on("select", (e) => {
        this.sumSelected = 0
        e.selected.forEach((el) => {
          console.log(el.innerHTML)
          let num = 0
          try {
            num = parseFloat(el.innerHTML.split(",").join(""))
            this.sumSelected += num
          } catch (error) {

          }
        });
        e.added.forEach((el) => {
          el.classList.add("selected");
        });
        e.removed.forEach((el) => {
          el.classList.remove("selected");
        });
      });
    } catch (error) {
      console.log({ error });
    }
  }
}
