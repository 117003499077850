
import { Options, Vue } from "vue-class-component";
import Container from "typedi";
import ProjectModel from "@/model/project";
import { useStore } from "vuex";
import { ActiveBudgetYearClass } from "@/data/system";

@Options({
  components: {
  },
})
export default class ProjectListView extends Vue {
  store = useStore();
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  docs: Array<ProjectModel> = [];
  pages: number = 0;
  total: number = 0;
  show: number = 20;
  page: number = 1;
  sector_id = "";
  search = "";
  budgetYear = new Date().getFullYear() + 543;
  budgetSource = "";
  toEditPath(budgetYear:string){
    this.$router.push("/portal/overlap/report-form/"+budgetYear)
  }
  renderTableTool(item: ProjectModel) {
    let result:Array<string> = ["Edit"]
    return result;
  }
  mounted() {
    this.fetchData()
  }
  async fetchData() {
    this.yearSetting.getBudgetYearSetting();
    
  }
}
