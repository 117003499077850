
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    budget: Object,
    level: Number,
  },
})
export default class PlanTableComponent extends Vue {
  budget!: any;
  level!: number
  showRows!: string[];
  get rowLength() {
    try {
      return this.showRows.length;
    } catch (error) {
      return 0;
    }
  }
}
