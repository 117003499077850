
import { Options, Vue } from "vue-class-component";
import {
  IndicatorMetricModel,
  IndicatorResponsiblePersonModel,
} from "@/model/indicator";

//@ts-ignore
import Chart, { Chart as chartjs, TooltipItem } from "chart.js/auto";
import { ActiveStrategicBudgetYearClass } from "@/data/strategic";
import { ReportA10Class } from "@/data/report";
import Container from "typedi";
import DocumentPDF from "@/document/pdf";
import { DocumentExcel } from "@/document/xlsx";
import { ActiveCabinetTargetListClass } from "@/data/cabinetTarget";
import axios from "axios";
import ADDRESS from "@/setting";
@Options({
  components: {},
})
export default class ReportA10View extends Vue {
  reportData: ReportA10Class = Container.get(ReportA10Class);
  myChart!: any;

  cabinet: Array<number> = [0, 0, 0, 0];

  get projects() {
    try {
      return this.reportData.data.plans
        .map((p: any) => p.products)
        .flat()
        .map((p: any) => p.activities)
        .flat()
        .map((p: any) => p.projects)
        .flat();
    } catch (error) {
      return [];
    }
  }
  
  getFiscalYear(date: Date): number {
    const currentMonth = date.getMonth();
    let fiscalYear = date.getFullYear();
    if (currentMonth > 9) fiscalYear++;
    return fiscalYear + 543;
  }
  
  mounted() {
    this.initData();
  }
  async initData() {
    let query: any = {};
    query.search = this.$route.params.budgetYear;
    await this.reportData.getReportA10ListT2(query);
    this.initChart();
  }
  async initChart()  {
    var element = document.getElementById("chart-A06") as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    let months = sumAllProduct(this.projects.map((p: any) => p.months));
    let actuals = sumAllProduct(this.projects.map((p: any) => p.actuals));

    
    let response1 = await axios.post(
      `${ADDRESS}/dashboard/${this.$route.params.budgetYear}/งบลงทุน`,{search:""}
    );
    let data1 = response1.data;
    let response2 = await axios.post(
      `${ADDRESS}/dashboard/${this.$route.params.budgetYear}/งบอุดหนุน`,{search:""}
    );
    let data2 = response2.data;
    console.log({data1,data2})
    const mappedMonths = months.reduce((acc, current, index) => {
      if (index === 0) {
        acc.push(current);
      } else {
        acc.push(acc[index - 1] + current);
      }
      return acc;
    }, []);
    const mappedActuals = actuals.reduce((acc, current, index) => {
      if (index === 0) {
        acc.push(current);
      } else {
        acc.push(acc[index - 1] + current);
      }
      return acc;
    }, []);
    try {
      this.myChart.destroy();
    } catch (error) {}
    function sumMatrix(mat1: number[], mat2: number[]): number[] {
      if (mat1.length !== mat2.length) {
        throw new Error("Both matrices must have the same length");
      }

      let result: number[] = [];
      for (let i = 0; i < mat1.length; i++) {
        result.push(mat1[i] + mat2[i]);
      }
      return result;
    }
    function sumAllProduct(matrices: number[][]) {
      try {
        let sum = new Array(matrices[0].length).fill(0);
        for (const m of matrices) {
          sum = sumMatrix(sum, m);
        }
        return sum;
      } catch (error) {
        return [];
      }
    }
    function repeatElements(arr: number[]): number[] {
    let newArray: number[] = [];

    for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < 3; j++) {
            newArray.push(arr[i]);
        }
    }

        return newArray;
    }
    setTimeout(() => {

      this.myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((el) =>
          this.resolveMonth(el)
        ),
        datasets: [
          {
            label: "แผน",
            data: mappedMonths,
            backgroundColor: ["rgba(54, 162, 235, 0.7)"],
            borderColor: ["rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
          {
            label: "ผล",
            data: mappedActuals,
            backgroundColor: ["rgba(160, 54, 235, 0.7)"],
            borderColor: ["rgba(160, 54, 235, 1)"],
            borderWidth: 1,
          },
          {
            label: "มติครม. (งบลงทุน)",
            type:"line",
            data: repeatElements(data1.cabinet).map((n:any)=>(1/100)*n*mappedMonths[mappedMonths.length-1]),
            borderColor: ["rgba(160, 54, 235, 1)"],
            borderWidth: 1,
            borderDash: [10,5]
          },
          {
            label: "มติครม. (งบอุดหนุน)",
            type:"line",
            data: repeatElements(data2.cabinet).map((n:any)=>(1/100)*n*mappedMonths[mappedMonths.length-1]),
            borderColor: ["rgba(235, 54, 160, 1)"],
            borderWidth: 1,
            borderDash: [10,5]
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    }, 500)
  }
  async generateReportPDF() {
    const documentPDF = new DocumentPDF();
    documentPDF.createA06(
      this.reportData,
      parseInt(this.$route.params.budgetYear as string)
    );
    documentPDF.save(`reportA06.pdf`);
  }
  async generateReportExcel() {
    let documentExcel = new DocumentExcel();
    documentExcel.createA06(
      this.reportData,
      parseInt(this.$route.params.budgetYear as string),
      this.sumTotalCollection,
      this.sumTotalInvestment
    );
  }
  getProjectByType(item: any, type: string) {
    try {
      let found = item.sumTypes.find((el: any) => el.name === type);
      return found.amount;
    } catch (error) {
      return 0;
    }
  }
  getActivityByType(item: any, type: string) {
    try {
      let sum = 0;
      item.projects.forEach((project: any) => {
        let found = this.getProjectByType(project, type);
        sum += found;
      });
      return sum;
    } catch (error) {
      console.log({ error });
      return 0;
    }
  }
  getProductByType(item: any, type: string) {
    try {
      let sum = 0;
      item.activities.forEach((activity: any) => {
        let found = this.getActivityByType(activity, type);
        sum += found;
      });
      return sum;
    } catch (error) {
      console.log({ error });
      return 0;
    }
  }
  getItemByType(item: any, type: string) {
    try {
      let sum = 0;
      item.products.forEach((product: any) => {
        let found = this.getProductByType(product, type);
        sum += found;
      });
      return sum;
    } catch (error) {
      console.log({ error });
      return 0;
    }
  }
  getTotal(type: string) {
    try {
      let sum = 0;
      this.reportData.data.plans.forEach((item: any) => {
        let found = this.getItemByType(item, type);
        sum += found;
      });
      return sum;
    } catch (error) {
      console.log({ error });
      return 0;
    }
  }

  resolveMonth(n: number) {
    let year = 2565;
    switch (n) {
      case 0:
        return "ตุลาคม " + year;
        break;
      case 1:
        return "พฤศจิกายน " + year;
        break;
      case 2:
        return "ธันวาคม " + year;
        break;
      case 3:
        return "มกราคม " + (year + 1);
        break;
      case 4:
        return "กุมภาพันธ์ " + (year + 1);
        break;
      case 5:
        return "มีนาคม " + (year + 1);
        break;
      case 6:
        return "เมษายน " + (year + 1);
        break;
      case 7:
        return "พฤษภาคม " + (year + 1);
        break;
      case 8:
        return "มิถุนายน " + (year + 1);
        break;
      case 9:
        return "กรกฏาคม " + (year + 1);
        break;
      case 10:
        return "สิงหาคม " + (year + 1);
        break;
      case 11:
        return "กันยายน " + (year + 1);
        break;

      default:
        return n;
        break;
    }
  }

  get types() {
    try {
      return this.reportData.projects![0].types;
    } catch (error) {}
  }
  get sumTotalInvestment() {
    try {
      return this.reportData.data.plans
        .map((product: any) => product.sumTotalInvestment)
        .flat()
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }
  get sumTotalCollection() {
    try {
      return this.reportData.data.plans
        .map((product: any) => product.sumTotalCollection)
        .flat()
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }
}
