import ADDRESS from "@/setting";
import axios from "axios"
import AccountModel from "@/model/account";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class AccountServices {
  public getAccountList(query: any) {
    return new Promise<PaginateResponse<AccountModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/accounts/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<AccountModel> = response.data.docs.map((el: any) => new AccountModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<AccountModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getAccountSingle(id: string) {
    return new Promise<AccountModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/account/get/${id}`);
      let doc: AccountModel = new AccountModel(response.data.doc)
      resolve(doc)
    })
  }

  public updateAccount(id: string, Account: AccountModel) {
    return new Promise<AccountModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/account/update/${id}`, Account.toJSON());
      
      let doc: AccountModel = new AccountModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteAccount(id: string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/account/delete/${id}`);
      const toast = useToast();
      toast.success("ลบข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }

  public createAccount(Account: AccountModel) {
    return new Promise<AccountModel>(async (resolve, reject) => {
      console.log('response')
      let response = await axios.post(`${ADDRESS}/account/create/`, Account);
      console.log('response',response)
      let doc: AccountModel = new AccountModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
