import ADDRESS from "@/setting";
import axios from "axios"
import AssignmentModel, { AssignmentMemberModel, AssignmentAttachmentModel, AssignmentReplyAttachmentModel } from "@/model/assignment";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";
import { useStore } from "vuex";

export default class AssignmentServices {
  store = useStore()
  public getAssignmentList(query: any) {
    return new Promise<PaginateResponse<AssignmentModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/assignments/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<AssignmentModel> = response.data.docs.map((el: any) => new AssignmentModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<AssignmentModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getMyAssignmentSingle(id: string) {
    return new Promise<AssignmentModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/assignment/get/${id}`);
      let doc: AssignmentModel = new AssignmentModel(response.data.doc)
      resolve(doc)
    })
  }

  public updateMyAssignment(id: string, MyAssign: AssignmentModel) {
    return new Promise<AssignmentModel>(async (resolve, reject) => {
      console.log('response1', { id }, { MyAssign })
      let response = await axios.patch(`${ADDRESS}/assignment/update/${id}`, MyAssign.toJSON());
      console.log('response2', { response })
      let doc: AssignmentModel = new AssignmentModel(response.data.doc)
      console.log('response3', { doc })
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createMyAssignment(MyAssign: AssignmentModel) {
    return new Promise<AssignmentModel>(async (resolve, reject) => {
      console.log('response1', { MyAssign })
      let response = await axios.post(`${ADDRESS}/assignment/create/`, MyAssign);
      console.log('response2', { response })
      let doc: AssignmentModel = new AssignmentModel(response.data.doc)
      console.log('response3', { doc })
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteMyAssignmentMemberSingle(id: string) {
    return new Promise<AssignmentMemberModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/assignment/delete-member/${id}`);
      let doc: AssignmentMemberModel = new AssignmentMemberModel(response.data.doc)
      const toast = useToast();
      toast.success("ลบสำเร็จแล้ว", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteMyAssignmentAttachmentSingle(id: string) {
    return new Promise<AssignmentAttachmentModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/assignment/delete-attachment/${id}`);
      let doc: AssignmentAttachmentModel = new AssignmentAttachmentModel(response.data.doc)
      const toast = useToast();
      toast.success("ลบไฟล์แนบแล้ว", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteMyAssignmentReplyAttachmentSingle(id: string) {
    return new Promise<AssignmentReplyAttachmentModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/assignment/delete-reply-attachment/${id}`);
      let doc: AssignmentReplyAttachmentModel = new AssignmentReplyAttachmentModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบไฟล์แนบแล้ว", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteMyAssignmentSingle(id: string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/assignment/delete/${id}`);
      const toast = useToast();
      toast.warning("ลบรายการแล้ว", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }
  public async getAssignmentNotification(id: string) {
    let response = await axios.get(`${ADDRESS}/assignment/notification/${id}`);
    let data = response.data
    return data
  }
}
