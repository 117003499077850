
import { Options, Vue } from "vue-class-component";
import { ActiveBudgetYearClass } from "@/data/system";
import { PreparationSingleClass } from "@/data/preparation";
import Container from "typedi";
import { ActiveProjectSingleClass } from "@/data/project";
import NumberField from "../../../components/NumberField.vue";
import { Watch } from "vue-property-decorator";

@Options({
  components: {},
  props:{
    modelValue: Array,
    label: String,
    disabled:Boolean,
  }
})
export default class PreparationView extends Vue {
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);
  modelValue!:Array<number>
  val:Array<number> = [0,0,0,0,0]
  disabled!:boolean
  grandTotalFinalAmount = ""
  async initData() {
    this.yearSetting.getBudgetYearSetting();
  }
  mounted() {
    this.initData();
    if (!Array.isArray(this.val)) this.val = [0,0,0,0]
    try {
      // this.val = this.modelValue as Array<number> ?? [0,0,0,0];
      this.grandTotalFinalAmount = this.project.data!.grandTotalFinalAmount.formatComma()
      this.val = this.modelValue
    } catch (error) {
      this.val = [0,0,0,0,0]
    }
    if(this.val===undefined) this.val = [0,0,0,0,0]
    console.log('val',this.val);
    console.log('modelValue',this.modelValue);
  }
  get budgetYear() {
    let arr: Array<Object> = [];
    this.yearSetting.data?.forEach((el) => {
      arr.push({
        name: el.text1,
        value: parseInt(el.name),
      });
    });
    return arr;
  }
  emitUp(){
    this.$emit("update:modelValue", this.val);
  }
}
