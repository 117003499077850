import ADDRESS from "@/setting";
import axios from "axios"
import Model from "@/model/followup";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class FollowUpServices {
  toast = useToast();
  public getFollowUpList(query: any) {
    return new Promise<PaginateResponse<Model>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/followups/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<Model> = response.data.docs.map((el: any) => new Model(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<Model>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getFollowUpSingle(smisCode: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/followup/get-by-smis-code/${smisCode}`);
      console.log("doc", response.data)
      let doc: Model = new Model(response.data.doc??{})
      resolve(doc)
    })
  }

  public deleteFollowUpSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/followup/delete/${id}`);
      let doc: Model = new Model(response.data.doc)
      this.toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteFollowUpMemberSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/followup/delete-member/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }

  public updateFollowUp(id: string, FollowUp: Model) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/followup/update/${id}`, FollowUp.toJSON());
      let doc: Model = new Model(response.data.doc)
      this.toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createFollowUp(smisCode:string, FollowUp: Model) {
    FollowUp.smisCode = smisCode
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/followup/create/`, FollowUp);
      console.log("response.data", response.data)
      let doc: Model = new Model(response.data.doc)
      this.toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

}
