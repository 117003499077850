import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    userId: "",
    username: "",
    firstName:"",
    fullName: "",
    token: "",
    jwt: "",
    sAMAccountName: "",
    position: "",
    roles: [],
    rights: [""],
    sector1: "",
    sector2: "",
    sector3: "",
    sector4: "",
    budgetYears: [],
    sectors: [],
    telephone: "",
    email: "",
    erpNotification: 0,
    adjustNotification: 0,
    budgetNotification: 0,
    assignmentTotalNotification: 0,
    assignmentOwnerNotification: 0,
    assignmentMemberNotification: 0,
    accesses: [],
    base64Logo: require("./logo").logo,
  },
  getters: {
    isDeveloper(state) {
      return true
    },
    hasRight: (state) => (right: string) => {
      return state.rights.includes(right)
    },
    isAdmin(state, data) {
      //@ts-ignore
      let isAdmin = state.roles.includes("ผู้ดูแลระบบ")
      return isAdmin
    }

  },
  mutations: {
    setUser(state, data) {
      console.log(data)
      state.username = data.username
      state.fullName = `${data.prefix} ${data.firstName} ${data.lastName}`
      state.firstName = data.firstName
      state.userId = data.id
      state.token = data.token
      state.sAMAccountName = data.sAMAccountName
      state.jwt = data.jwt
      state.roles = data.roles
      state.rights = data.rights
      state.telephone = data.extensionNumber
      state.email = data.email
      state.accesses = data.accesses
      state.position = data.position
      state.sector1 = data.sector1_id
      state.sector2 = data.sector2_id
      state.sector3 = data.sector3_id
      state.sector4 = data.sector4_id
    },
    setSectors(state, data) {
      state.sectors = data
    },
    logout(state, data) {

    },
    setBudgetYear(state, data) {
      state.budgetYears = data
    },
    setERPNotification(state, data) {
      state.erpNotification = data
    },
    setAdjustNotification(state, data) {
      state.adjustNotification = data
    },
    setBudgetNotification(state, data) {
      state.budgetNotification = data
    },
    setAssignOwnerNotification(state, data) {
      state.assignmentOwnerNotification = data
    },
    setAssignMemberNotification(state, data) {
      state.assignmentMemberNotification = data
    },
    setAssignTotalNotification(state, data) {
      state.assignmentTotalNotification = data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState(
    {
      key: "SAT221",
      paths: ["username", "fullName","firstName","sAMAccountName", "userId", "token", "jwt", "roles", "rights", "telephone", "email", "position", "sector1", "sector2", "sector3", "sector4", "sector5", "accesses", "erpNotification", "adjustNotification"]
    }
  )
  ]
})
