
import { Options, Vue } from "vue-class-component";
import { v4 as uuidv4 } from "uuid";
import PlanningTask from "@/components/PlanningTask.vue";
import * as d3 from "d3";
@Options({
  components: {},
})
export default class PlanningForm extends Vue {
  activeTask: any = {};
  activeIndex: number = 0;
  tasks: Array<any> = [
    { name: "ผังยุทธศาสตร์ฺ", id: 1, period: 0, depend_id: 0 },
    { name: "วัตถุประสงค์เชิงยุทธศาสตร์", id: 2, period: 1, depend_id: 1 },
  ];
  viewportWidth: number = 0;
  viewportHeight: number = 0;
  svg!: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;

  findLevel(data:any){
    let level = 0
    let found = this.tasks.find(task=>task.id===data.parent)
    while(found) {
      console.log("found", level)
      found = this.tasks.find(task=>task.id===found.depend_id)
      level++
      if(level > 10) return 10
    }

    return level
  }
  buildStrings(arr: any, parent: any, c: any) {
    return arr.reduce((r: any, e: any) => {
      if (e.depend_id == parent) {
        var children = this.buildStrings(
          arr,
          e.id,
          c + e.id + ";" + e.period + ","
        );
        if (!children.length) this.result.push(c + e.id + ";" + e.period);
        r.push(e);
      }
      return r;
    }, []);
  }
  findItem(id: number) {
    try {
      return this.tasks.find((task) => task.id == id);
    } catch (error) {
      return {};
    }
  }
  get mapResult() {
    let result = this.result.map((rs) => {
      let split = rs.split(",");
      let totalDay = 0;
      let path = "";
      let route = [];
      try {
        totalDay = split
          .map((sp: string) => parseInt(sp.split(";")[1]))
          .reduce((a: number, b: number) => a + b, 0);
      } catch (error) {}
      try {
        path = split.map((sp: string) => parseInt(sp.split(";")[0])).join("-");
      } catch (error) {}
      try {
        route = split.map((sp: string) => parseInt(sp.split(";")[0]));
      } catch (error) {}
      return {
        original: rs,
        route,
        path,
        totalDay,
        end: route[route.length - 1],
        isMax: false,
      };
    });
    let max = Math.max(...result.map((rs) => rs.totalDay));
    result.forEach((rs) => {
      if (rs.totalDay === max) rs.isMax = true;
    });
    return result;
  }

  saveActive() {
    let found = this.tasks.find((task) => task.id === this.activeTask.id);
    found.name = this.activeTask.name;
    found.period = this.activeTask.period;
    this.result = [];
    this.drawTree(this.svg);
  }

  mounted() {
    this.result = [];
    this.initialize();
  }
  async initialize() {
    let container = document.getElementById(
      "strategic-container"
    ) as HTMLElement;
    this.viewportHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByName("body")[0].clientHeight;
    // this.viewportWidth =
    //   window.innerWidth ||
    //   document.documentElement.clientWidth ||
    //   document.getElementsByName("body")[0].clientWidth;
    this.viewportWidth = container.offsetWidth;
    this.initD3();
  }

  initD3() {
    let svg = d3
      .select("#myd3")
      .classed("svg-container", true)
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", `0 0 ${this.viewportWidth} ${this.viewportHeight}`)
      .classed("svg-content-responsive", true)
      .attr("width", `${this.viewportWidth}px`)
      .attr("height", `${this.viewportHeight}px`);
    this.svg = svg;
    this.drawTree(svg);
  }

  drawTree(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>) {
    d3.selectAll("g > *").remove();
    // set the dimensions and margins of the diagram
    var margin = { top: 20, right: 190, bottom: 30, left: 50 },
      width = this.viewportWidth - margin.left - margin.right,
      height = this.viewportHeight - margin.top - margin.bottom;

    // declares a tree layout and assigns the size
    var treemap = d3.tree().size([height, width]);

    //  assigns the data to a hierarchy using parent-child relationships
    console.log(this.tasks);
    console.log(this.generateTree(this.tasks));
    var nodes: any = d3.hierarchy(
      {
        name: "ประเด็นผังยุทธศาสตร์",
        children: this.generateTree(this.tasks),
      },
      (d: any) => {
        return d.children;
      }
    );

    nodes = treemap(nodes);

    let g = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // adds the links between the nodes
    var link = g
      .selectAll(".link")
      .data(nodes.descendants().slice(1))
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", (d: any) => {
        return (
          "M" +
          d.y +
          "," +
          d.x +
          "L" +
          (d.y + d.parent.y) / 2 +
          "," +
          d.x +
          " " +
          (d.y + d.parent.y) / 2 +
          "," +
          d.parent.x +
          " " +
          d.parent.y +
          "," +
          d.parent.x
        );
      });

    // adds each node as a group
    var node = g
      .selectAll(".node")
      .data(nodes.descendants())
      .enter()
      .append("g")
      .attr("class", (d: any) => {
        // return "node" + (d.children ? " node--internal" : " node--leaf");
        return "node";
      })
      .attr("transform", (d: any) => {
        return "translate(" + d.y + "," + d.x + ")";
      });

    // adds the circle to the node
    // node.append("circle").attr("r", 10);
    node
      .append("rect")
      .transition().duration(1000)
      .attr("width", "8rem")
      .attr("height", "2rem")
      .attr("x", "-2.5rem")
      .attr("y", "-1rem")
      .attr("class", "rect-node");
      
    node
      .append("rect")
      .attr("width", "0.1rem")
      .attr("height", "2rem")
      .attr("x", "-2.5rem")
      .attr("y", "-1rem")
      .attr("class", "rect-leading");

    // adds the text to the node
    node
      .append("text")
      .attr("dy", ".35em")
      .attr("x", (d: any) => {
        // return d.children ? -13 : 13;
        return -30;
      })
      .style("text-anchor", (d: any) => {
        // return d.children ? "start" : "end";
        return "start";
      })
      .text((d: any) => {
        return d.data.name;
      });
    node
      .append("text")
      .attr("dy", ".35em")
      .attr("x", 40)
      .attr("y", 10)
      .attr("style", "cursor:pointer")
      .style("text-anchor", (d: any) => {
        return "end";
      })
      .text((d: any) => {
        if(this.findLevel(d.data)===0) return "เพิ่มเป้าประสงค์"
        else if(this.findLevel(d.data)===1) return "เพิ่มยุทธศาสตร์"
        else if(this.findLevel(d.data)===2) return "เพิ่มกลยุทธ"
        else if(this.findLevel(d.data)===3) return "เพิ่มแผนงาน"
        return "เพิ่ม";
      })
      .on("click", (e, d: any) => {
        this.addTask(d.data.id);
        this.drawTree(this.svg);
      });
    node
      .append("text")
      .attr("dy", ".35em")
      .attr("x", -20)
      .attr("y", 10)
      .attr("style", "cursor:pointer")
      .attr("fill", "darkOrange")
      .style("text-anchor", (d: any) => {
        return "end";
      })
      .text((d: any) => {
        return this.findLevel(d.data)
        return "ลบ";
      })
      .on("click", (e, d: any) => {
        this.deleteTask(d.data.id);
        this.drawTree(this.svg);
      });

    node
      .append("text")
      .attr("dy", ".35em")
      .attr("x", 40)
      .attr("y", -30)
      .attr("style", "cursor:pointer")
      .style("text-anchor", (d: any) => {
        return "end";
      })
      .text((d: any) => {
        return "แก้ไข";
      })
      .on("click", (e, d: any) => {
        this.activeTask = d.data;
        let btn = this.$refs.editModalPlanning as HTMLElement;
        btn.click();
      });
  }
  result: Array<any> = [];

  generateTree(elements: Array<any>) {
    console.log({ elements });
    var nodes: Array<any> = [];
    var createNode = (elm: any) => {
      var node = {
        id: elm.id,
        name: elm.name,
        period: elm.period ?? 0,
        children: [],
        parent: elm.depend_id,
      };
      return node;
    };
    var fillElements = (elements: any, node: any) => {
      elements.forEach((elm: any) => {
        if (elm.depend_id === node.id) {
          var child = node.children.find((child: any) => {
            return child.id === elm.id;
          });
          if (!child) {
            node.children.push(createNode(elm));
          }
        }
      });
    };
    var fillTree = (elements: any, node: any) => {
      fillElements(elements, node);
      if (node.children.length > 0) {
        node.children.forEach((n: any) => {
          fillElements(elements, n);
          fillTree(elements, n);
        });
      }
    };
    elements.forEach((elm: any) => {
      if (!elm.depend_id) {
        var root = createNode(elm);
        fillTree(elements, root);
        nodes.push(root);
      }
    });
    return nodes;
  }

  get level0() {
    return this.tasks.filter((task) => task.depend_id === null);
  }
  deleteTask(id: string) {
    let i = this.tasks.findIndex((task) => task.id === id);
    // console.log("deleting", this.tasks[i], id)
    this.tasks.splice(i, 1);
    this.result = [];
  }
  getLevel() {}
  getChild(id: string) {
    return this.tasks.filter((task) => task.depend_id === id);
  }
  addTask(dependOn: number) {
    this.tasks.push({
      id: uuidv4(),
      name: "-",
      day: 3,
      depend_id: dependOn,
    });
    this.result = [];
  }
}
