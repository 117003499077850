
import { Options, Vue } from 'vue-class-component';
import store from "@/store"
import ADDRESS from "@/setting";
import axios from "axios";
import ProjectServices from "@/service/project";
import AdjustmentServices from "@/service/adjustment";
import AssignmentServices from './service/assignment';
@Options({
  components: {
  },
})
export default class App extends Vue {

  mounted() {
    this.getNotification()
    this.getAdjustPending()
  }
  async getNotification() {
    // console.log('userId',store.state.userId);
    
    let budget = await new ProjectServices().getNotification(store.state.userId)
    console.log(budget);
    store.commit("setBudgetNotification", budget.data.notification_count);
    // if(store.getters.isAdmin === true) {
    //   store.commit("setBudgetNotification", budget.data.admin_count);
    // } else {
    //   store.commit("setBudgetNotification", budget.data.notification_count);
    // }
  }
  async getAdjustPending() {
    let budget = await new AdjustmentServices().getAdjustPending(store.state.userId)
    let assignments = await new AssignmentServices().getAssignmentNotification(store.state.userId) //store.state.userId
    console.log({assignments});
    if(store.getters.isAdmin === true) {
      store.commit("setAdjustNotification", budget.data.admin_count);
    } else {
      store.commit("setAdjustNotification", budget.data.notification_count);
    }
    try {
      let owner = assignments.find((item:any)=>item.type==="owner"&&item.name==="ดำเนินการแล้ว")
      let member = assignments.find((item:any)=>item.type==="member"&&item.name==="ยังไม่ดำเนินการ")
      let owner_count = 0
      let member_count = 0
      if(owner) owner_count = owner.count
      if(member) member_count = member.count
      console.log({assignments, owner_count, member_count})
      store.commit("setAssignOwnerNotification", owner_count);
      store.commit("setAssignMemberNotification", member_count);
      store.commit("setAssignTotalNotification", owner_count+member_count);
    } catch (error) {
      console.log("error",error)
    }
  }
}
