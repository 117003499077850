
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modelValue: Number,
    label: String,
    "onUpdate:modelValue": Function,
    disabled:Boolean,
  },
})
export default class Dropdown extends Vue {
  label!:string;
  modelValue!: number;
  val:number= 0;
  disabled!:boolean
  mounted(){
    this.val = this.modelValue
  }
  emitUp() {
    console.log(this.val)
    this.$emit("update:modelValue", this.val);
  }
}
