
import { Options, Vue } from "vue-class-component";
import { ActiveBudgetYearClass } from "@/data/system";
import { PreparationSingleClass } from "@/data/preparation";
import Container from "typedi";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class PreparationView extends Vue {
  yearSetting:ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  dataSingle: PreparationSingleClass = Container.get( PreparationSingleClass );
  fromYear:number = 0
  toYear:number = 0
  store = useStore();

  async getData() {
    this.yearSetting.getBudgetYearSetting();
  }
  mounted() {
    this.getData()
  }
  get budgetYear() {
    let arr : Array<Object> = []
    this.yearSetting.data?.forEach((el)=>{
      arr.push({
        name:el.name,
        value:parseInt(el.text1)
      })
    })
    return arr
  }
  async submitForm() {
    await this.dataSingle.createPreparationSingle({fromYear:this.fromYear,toYear:this.toYear});
  }
}
