
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveProjectSingleClass } from "@/data/project";
import { ActiveWithdrawListClass } from "@/data/withdraw";
import { confirmAction } from "@/decorator";
import BudgetModel from "@/model/budget";
import Container from "typedi";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    budget: Object,
    level: Number,
    budgetParent: String,
    budgetChild: String,
    tudNgern: Boolean,
  },
})
export default class PlanTableComponent extends Vue {
  budget!: any;
  level!: number;
  budgetParent!: string;
  budgetChild!: string;
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  withdrawList: ActiveWithdrawListClass = Container.get(
    ActiveWithdrawListClass
  );
  menuIsVisible = false;
  tudNgern!: boolean;
  tryFormatting(n: number) {
    try {
      return n.formatFull();
    } catch (error) {
      return "-";
    }
  }
  get isWithdrawn(): boolean {
    try {
      let filter = this.withdrawList.data!.docs.filter(
        (w: any) => w.budget.code === this.budget.code
      );
      let mapped = filter.map((f) => f.amount);
      let reduced = mapped.reduce((a, b) => a + b, 0);
      return reduced > 0;
    } catch (error) {
      return false;
    }
  }
  filterWithdrawsAmountTotalBudget(): number {
    try {
      let filter = this.withdrawList.data!.docs.filter(
        (w: any) => w.budget.code === this.budget.code
      );
      let mapped = filter.map((f) => f.amount);
      let reduced = mapped.reduce((a, b) => a + b, 0);
      return reduced;
    } catch (error) {
      return 0;
    }
  }
  filterWithdrawsAmount(month: number): number {
    try {
      // let filter = this.withdrawList.data!.docs.filter((w: any) => w.month === month&&w.budget_id===this.budget.id);
      let filter = this.withdrawList.data!.docs.filter(
        (w: any) => w.month === month && w.budget.code === this.budget.code
      );
      let mapped = filter.map((f) => f.amount);
      let reduced = mapped.reduce((a, b) => a + b, 0);
      return reduced;
    } catch (error) {
      return 0;
    }
  }
  filterWithdraws(month: number): Array<any> {
    try {
      // let filter = this.withdrawList.data!.docs.filter((w: any) => w.month === month&&w.budget_id===this.budget.id);
      let filter = this.withdrawList.data!.docs.filter(
        (w: any) => w.month === month && w.budget.code === this.budget.code
      );
      return filter;
    } catch (error) {
      return [];
    }
  }
  monthClass(month: number) {
    try {
      let found = this.budget.items.find((item: any) => item.month === month);
      if (found.isActive) {
        if (this.level === 1) return "bg-plan1";
        else if (this.level === 2) return "bg-plan2";
        else if (this.level === 3) return "bg-plan3";
      } else return "";
    } catch (error) {
      return "";
    }
  }
  getIndicatorCode() {
    try {
      let arr = (this.budget ?? {}).indicators;
      arr = arr.map((a: any) => a.indicator.code);
      return arr;
    } catch (error) {
      return "-";
    }
  }
  getIndicatorFull() {
    try {
      let arr = (this.budget ?? {}).indicators;
      arr = arr.map((a: any) => ({
        code: a.indicator.code,
        name: a.indicator.name,
      }));
      arr = arr.map((item: any) => item.code + ":" + item.name);
      return arr;
    } catch (error) {
      return "-";
    }
  }
  showContextMenu() {
    this.menuIsVisible = !this.menuIsVisible;
  }
  openModal() {
    this.$nextTick(() => {
      let ref = this.$refs.openModalFromTableRow as HTMLElement;
      ref.click();
    });
  }
  activeChild() {
    let parent_id = this.budget.id;
    this.activeBudget.data = new BudgetModel({ parent_id: parent_id });
    this.activeBudget.parent = this.budgetParent;
    this.activeBudget.child = this.budgetChild;
    this.openModal();
  }
  activeItem() {
    if (this.budget.isSpecial) {
      alert(
        "กิจกรรมนี้เป็นกิจกรรมควบคุมพิเศษ กรุณาติดต่อ admin หรือ ผู้ประสานงานโครงการ"
      );
    } else {
      console.log(this.budget);
      this.activeBudget.data = new BudgetModel({});
      this.activeBudget.data = this.budget;
      this.activeBudget.parent = this.budgetParent;
      this.activeBudget.child = this.budgetChild;
      this.openModal();
    }
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteItem() {
    this.activeBudget.data = this.budget;
    await this.activeBudget.deleteBudgetSingle();
    await this.activeProject.updateProjectSingle();
  }
  async clearBudget() {
    this.activeBudget.data = new BudgetModel({});
    this.openModal();
  }
}
