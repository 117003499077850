
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modelValue: [String, Number],
    label: String,
    "onUpdate:modelValue": Function,
    disabled:Boolean,
  },
})
export default class Dropdown extends Vue {
  label!:string;
  modelValue!: string|number;
  val:string|number = ""
  disabled!:boolean
  mounted(){
    this.val = this.modelValue
  }
  emitUp() {
    console.log(this.val)
    this.$emit("update:modelValue", this.val);
    this.$emit("change", this.val);
    this.$emit("disabled", this.disabled);
  }
}
