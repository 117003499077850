
import { Options, Vue } from "vue-class-component";
import AccountModel from "@/model/account";
import {
  ActiveAccountListClass,
  ActiveAccountSingleClass,
} from "@/data/account";
import Container from "typedi";

@Options({
  components: {},
})
export default class AccountFormView extends Vue {
  disabled:boolean = false
  dataSingle: ActiveAccountSingleClass = Container.get(
    ActiveAccountSingleClass
  );
  mounted() {
    this.disabled = this.$route.path.search('/detail')!=-1
    if (this.$route.params.id !== undefined) this.initData();
    else { 
      this.dataSingle.data = new AccountModel({})
    }
  }
  async initData() {
    await this.dataSingle.getAccountSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createAccountSingle();
    } else {
      this.dataSingle.updateAccountSingle();
    }
  }
}
