import { PaginateResponse } from "@/common";
import PreparationModel from "@/model/preparation";
import PreparationServices from "@/service/preparation";
import { Service } from 'typedi';

@Service()
export class PreparationSingleClass {
  data?: PreparationModel;
  loading: boolean = true;
  async createPreparationSingle(query: any) {
    console.log('query', query);
    this.loading = true
    this.data = await new PreparationServices().createPreparation(query)
    this.loading = false
  }
}