
import { Options, Vue } from "vue-class-component";
import { ActiveBudgetSingleClass } from "@/data/budget";
import Container from "typedi";
import { ActiveIndiciatorListClass } from "@/data/indicator";
import { ActiveAdjustmentSingleClass } from "@/data/adjustment";
import { Watch } from "vue-property-decorator";
import BudgetModel, {BudgetIndicatorModel} from "@/model/budget";

@Options({
  components: {},
})
export default class PlanTableComponent extends Vue {

  doc: BudgetModel = new BudgetModel({})
  adjustment: ActiveAdjustmentSingleClass = Container.get(ActiveAdjustmentSingleClass);
  budget: ActiveBudgetSingleClass = Container.get(ActiveBudgetSingleClass);
  indicatorList: ActiveIndiciatorListClass = Container.get(ActiveIndiciatorListClass);

  mounted() {
    
  }
  @Watch("project.data")
  async initData(){
    await this.indicatorList.getIndiciatorList({search:{budgetYear:this.adjustment.data!.budgetYear}});
  }
  pustIndicator(){
    this.budget.data!.indicators.push(new BudgetIndicatorModel({}))
  }
  submitForm() {
    this.budget.updateBudgetSingle();
  }
}
