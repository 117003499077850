
import { Options, Vue } from "vue-class-component";
import Container from "typedi";
import ProjectModel from "@/model/project";
import { useStore } from "vuex";
import { ActiveBudgetYearClass } from "@/data/system";
import { ActiveBudgetListClass, ActiveBudgetSingleClass } from "@/data/budget";
import BudgetModel from "@/model/budget";

import { useToast, POSITION } from "vue-toastification";
import { DocumentExcel } from "@/document/xlsx";
@Options({
  components: {},
})
export default class ProjectListView extends Vue {
  store = useStore();
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  dataList: ActiveBudgetListClass = Container.get(ActiveBudgetListClass);
  pages: number = 0;
  total: number = 0;
  show: number = 20;
  page: number = 1;
  sector_id = "";
  search = "";
  budgetYear = new Date().getFullYear() + 543;
  budgetSource = "";
  progress = 0

  get totalBudget() {
    try {
      return this.dataList.data?.docs
        .map((item) => item.overlapBudgetAmount ?? 0)
        .reduce((a, b) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }
  get totalOverlap() {
    try {
      return this.dataList.data?.docs
        .map((item) => item.overlapRemainingAmount ?? 0)
        .reduce((a, b) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }
  get grandTotal() {
    try {
      return (this.totalBudget ?? 0) + (this.totalOverlap ?? 0);
    } catch (error) {
      return 0;
    }
  }
  get count() {
    try {
      return this.dataList.data!.docs.length
    } catch (error) {
      return 0;
    }
  }
  renderTableTool(item: ProjectModel) {
    let result: Array<string> = ["Edit"];
    return result;
  }
  async updateAllItem() {
    let total = 1
    let count = 0
    try {
      total = this.dataList.data!.docs.length
    } catch (error) {
      
    }
    let progress = 0
    for(const item of this.dataList.data!.docs){
      await this.updateBudget(item)
      console.log("item", item)
      count++
      this.progress = (count / total) * 100
    }

    const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      this.progress = 100
  }
  updateBudget(item: any) {
    return new Promise(async (resolve, reject) => {
      let activeBudget = new ActiveBudgetSingleClass()
      activeBudget.data = new BudgetModel({
        ...item,
        // overlapBudgetAmount: 30,
        // overlapRemainingAmount: 40,
        // overlapContractNumber: "AA",
        // overlapStartDate: new Date(),
        // overlapEndDate: new Date(),
        // overlapDescription: "BB",
        // overlapReason: "CC",
      });
      await activeBudget.updateBudgetSingleLite();
      console.log("done")
      resolve("done");
    });
  }
  mounted() {
    this.fetchData();
  }
  async fetchData() {
    let budgetYear = parseInt(this.$route.params.budgetYear as string);
    this.yearSetting.getBudgetYearSetting();
    this.dataList.getBudgetList({
      search: {
        isOverlap: true,
        project: {
          budgetYear,
        },
      },
    });
  }

  exportExcel(){
    let budgetYear = parseInt(this.$route.params.budgetYear as string);
    let docExcel = new DocumentExcel();
    docExcel.createOverlapDetail(this.dataList.data?.docs!,budgetYear);
  }
}
