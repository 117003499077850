export  default class SectorModel {
  id?: string;
  budgetYear: number;
  q1: number;
  q2: number;
  q3: number;
  q4: number;
  q1Investment:number;
  q2Investment:number;
  q3Investment:number;
  q4Investment:number;
  q1Support:number;
  q2Support:number;
  q3Support:number;
  q4Support:number;
  q1Annual:number;
  q2Annual:number;
  q3Annual:number;
  q4Annual:number;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.budgetYear = jsonData.budgetYear;
    this.q1 = jsonData.q1;
    this.q2 = jsonData.q2;
    this.q3 = jsonData.q3;
    this.q4 = jsonData.q4;
    this.q1Investment = jsonData.q1Investment;
    this.q2Investment = jsonData.q2Investment;
    this.q3Investment = jsonData.q3Investment;
    this.q4Investment = jsonData.q4Investment;
    this.q1Support = jsonData.q1Support;
    this.q2Support = jsonData.q2Support;
    this.q3Support = jsonData.q3Support;
    this.q4Support = jsonData.q4Support;
    this.q1Annual = jsonData.q1Annual;
    this.q2Annual = jsonData.q2Annual;
    this.q3Annual = jsonData.q3Annual;
    this.q4Annual = jsonData.q4Annual;
  }
  toJSON(){
    let obj = Object.assign({},this)
    
    return JSON.parse(JSON.stringify(obj))
  }
}