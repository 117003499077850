
import { Options, Vue } from "vue-class-component";
import {
  ActiveBudgetYearClass,
  ActiveTypeClass,
  ActiveBudgetSourceClass,
} from "@/data/system";
import {
  ActiveReportListClass,
  ReportA01Class,
  ReportA02Class,
  ReportA04Class,
  ReportA08Type1Class,
  ReportA08Type2Class,
} from "@/data/report";
import SectorModel from "@/model/sector";
import { ActiveSectorListClass } from "@/data/sector";
import Container from "typedi";
import ADDRESS from "@/setting";
import DocumentA from "@/document/pdf";
import { DocumentExcel } from "@/document/xlsx";
import ReportModel from "@/model/report";
import { useStore } from "vuex";
@Options({
  components: {},
})
export default class DocumentA08ListView extends Vue {
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  dataExpenseType: ActiveTypeClass = Container.get(ActiveTypeClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  budgetSourceSetting: ActiveBudgetSourceClass = Container.get(
    ActiveBudgetSourceClass
  );
  sectorData: Array<SectorModel> = [];
  dataList: ActiveReportListClass = Container.get(ActiveReportListClass);
  docs: Array<ReportModel> = [];
  pages: number = 0;
  total: number = 0;
  active: number = 0;
  filter1: any = "";
  filter2: any = "";
  filter3: any = "";
  filter4: any = "";
  store = useStore();
  ADDRESS = ADDRESS;

  reportA01: ReportA01Class = Container.get(ReportA01Class);
  reportA02: ReportA02Class = Container.get(ReportA02Class);
  reportA04: ReportA04Class = Container.get(ReportA04Class);
  reportA08Type1: ReportA08Type1Class = Container.get(ReportA08Type1Class);
  reportA08Type2: ReportA08Type2Class = Container.get(ReportA08Type2Class);

  get budgetYear() {
    let arr: Array<Object> = [];
    this.yearSetting.data?.forEach((el) => {
      arr.push({
        name: el.name,
        value: parseInt(el.text1),
      });
    });
    return arr;
  }
  get sector() {
    let arr: Array<Object> = [];
    this.sectorData.forEach((el) => {
      arr.push({
        name: el.name,
        value: el.id,
      });
    });
    return arr;
  }
  get budgetSource() {
    let arr: Array<Object> = [];
    this.budgetSourceSetting.data?.forEach((el) => {
      arr.push({
        name: el.name,
        value: el.name,
      });
    });
    return arr;
  }
  get expenseTypes() {
    let arr: Array<Object> = [];
    this.dataExpenseType.data?.forEach((el) => {
      arr.push({
        name: el.name,
        value: el.name,
      });
    });
    return arr;
  }
  mounted() {
    this.getData();
  }
  async getData() {
    this.dataExpenseType.getTypeSetting("ประเภทค่าใช้จ่ายในหน้าแผน")
    this.yearSetting.getBudgetYearSetting();
    await this.budgetSourceSetting.getBudgetSourceSetting(
      "แหล่งงบประมาณในคำขอ"
    );
    await this.sectorList.getSectorList({search: { category: "ฝ่าย/สำนัก" }});
    this.sectorData = this.sectorList.data!.docs;
  }
  async getReport(name: string, type: string) {
    let query:any = {};
    if(name == "A08Type2"){
      query = {
        budgetYear: this.filter1,
        expenseType: this.filter4,
        sector_id:this.filter2
      };
    }
    else{
      query = {
        budgetYear: this.filter1,
        sector_id:this.filter2
      };
    }
    if(query.sector_id == "") delete query.sector_id
    //getdata
    if (name == "A08Type1") {
      await this.reportA08Type1.getReportA08Type1List(query);
    }else if (name == "A08Type2") {
      await this.reportA08Type2.getReportA08Type2List(query);
    }
    //generatePDF/Excel
    if (type == "PDF") {
      this.generateReportPDF(name);
    } else {
      this.generateReportExcel(name);
    }
  }
  //PDF
  async generateReportPDF(name: string) {
    console.log("generatePDF");
    const documentA = new DocumentA();
     if (name == "A08Type1") {
      documentA.create08Type1(this.reportA08Type1.data!, this.filter1);
    } else if (name == "A08Type2") {
      documentA.create08Type2(this.reportA08Type2.data!, this.filter1,this.filter4);
    }
    documentA.save(`report${name}.pdf`);
  }
  //EXCEL
  async generateReportExcel(name: string) {
    console.log("generateExcel");
    let docExcel = new DocumentExcel();
     if (name == "A08Type1") {
      docExcel.createA08Type1(this.reportA08Type1.data!, this.filter1);
    } else if (name == "A08Type2") {
      docExcel.createA08Type2(this.reportA08Type2.data!, this.filter1,this.filter4);
    } else {
      // docExcel.createA01(this.docs, 2565, 30);
    }
  }
}
