
import { ActiveProjectSingleClass } from "@/data/project";
import { ActiveWithdrawListClass } from "@/data/withdraw";
import BudgetModel from "@/model/budget";
import Container from "typedi";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    budget: Object,
    level: Number,
    budgetParent: String,
    budgetChild: String,
    tudNgern: Boolean,
    cb: Function,
  },
})
export default class PlanTableComponent extends Vue {
  budget!: BudgetModel;
  level!: number;
  budgetParent!: string;
  budgetChild!: string;
  cb!: Function;

  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );

  withdrawList: ActiveWithdrawListClass = Container.get(
    ActiveWithdrawListClass
  );
  monthClass(month: number) {
    try {
      let found = this.budget.items.find((item: any) => item.month === month);
      if (found!.isActive) {
        if (this.level === 1) return "bg-plan1";
        else if (this.level === 2) return "bg-plan2";
        else if (this.level === 3) return "bg-plan3";
      } else return "";
    } catch (error) {
      return "";
    }
  }

  filterWithdraws(month: number): Array<any> {
    try {
      // let filter = this.data.withdraws.filter((w: any) => w.month === month);
      // let filter = this.withdrawList.data!.docs.filter((w: any) => w.month === month&&w.budget_id===this.budget.id);
      let filter = this.withdrawList.data!.docs.filter(
        (w: any) => w.month === month && w.budget.code === this.budget.code
      );
      return filter;
    } catch (error) {
      return [];
    }
  }
  sumWithdraws(month: number) {
    try {
      let result = this.filterWithdraws(month)
        .map((w: any) => w.amount)
        .reduce((a, b) => a + b, 0);
      return result;
    } catch (error) {
      return -1;
    }
  }
}
