import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import BudgetModel from "@/model/budget";
import WithdrawModel from "@/model/withdraw";
import BudgetServices from "@/service/budget";
import ProjectServices from "@/service/project";
import WithdrawServices from "@/service/withdraw";
import { DateTime } from "luxon";
import Container, { Service } from 'typedi';

@Service()
export class ActiveBudgetSingleClass {
  parent?: string
  child?: string
  data?: BudgetModel;
  activeLevel: number = -1;
  loading: boolean = true;
  list = Container.get(ActiveBudgetListClass)
  withdraws?: Array<WithdrawModel>

    
  parentNamelevel(active:number) {
    switch (active) {
      case 1:
        return this.parent
      case 2:
        return this.child 
      case 3:
        return (this.data!.number ?? "-") + " " + (this.data!.name ?? "-")
      default:
        return "?"
    }
  }


  get level1Name() {
    switch (this.activeLevel) {
      case 1:
        return (this.data!.number ?? "-") + " " + (this.data!.name ?? "-")
      case 2:
        return this.parent
      case 3:
        return this.parent
      default:
        return "?"
    }
    return (this.data!.number ?? "-") + " " + (this.data!.name ?? "-")
  }
  get level2Name() {
    switch (this.activeLevel) {
      case 1:
        return "-"
      case 2:
        return (this.data!.number ?? "-") + " " + (this.data!.name ?? "-")
      case 3:
        return this.child +"/" +this.parent
      default:
        return "?"
    }
  }
  get level3Name() {
    switch (this.activeLevel) {
      case 1:
        return "-"
      case 2:
        return "-"
      case 3:
        return (this.data!.number ?? "-") + " " + (this.data!.name ?? "-")
      default:
        return "?"
    }
  }
  // get level2Name() {
  //   if (this.activeLevel === 2) return this.parent
  //   return "-"
  // }
  // get level3Name() {

  // }
  get hasChild() {
    try {

      return this.data?.children?.length! > 0
    } catch (error) {
      return false
    }
  }
  plannedAmountOfMonth(i: number) {
    try {
      return this.data?.items.find(item => item.month === i)?.amount
    } catch (error) {
      return 0
    }
  }


  get budgetArray() {
    let budget = this.data?.items?.map(item => item.amount)!
    return budget
  }
  get spentArray() {
    let spending: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    spending.forEach((item, i) => {
      let filter = this.withdraws?.filter(withdraw => withdraw.month === i)
      spending[i] = filter?.map(f => f.tempAmount ?? 0).reduce((a, b) => a + b, 0) ?? 0
    })
    return spending
  }
  get remainArray() {
    let results: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    results.forEach((item, i) => {
      results[i] = this.budgetArray[i] - this.spentArray[i]
    })
    return results
  }
  get remainAccumArray() {
    let preps: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let budgets: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let spends: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let results: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let b1 = 0
    let b2 = 0
    let b3 = 0
    let w1 = 0
    let w2 = 0
    let w3 = 0
    preps.forEach((item, i) => {
      preps[i] = this.budgetArray[i] - this.spentArray[i]
      budgets[i] = this.budgetArray[i]
      spends[i] = this.spentArray[i]
    })
    preps.forEach((item, i) => {
      if ([0, 1, 2].includes(i)) {
        b1 = budgets[0]
        w1 = spends[0]
        b2 = budgets[1]
        w2 = spends[1]
        b3 = budgets[2]
        w3 = spends[2]
      } else if ([3, 4, 5].includes(i)) {
        b1 = budgets[3]
        w1 = spends[3]
        b2 = budgets[4]
        w2 = spends[4]
        b3 = budgets[5]
        w3 = spends[5]
      } else if ([6, 7, 8].includes(i)) {
        b1 = budgets[6]
        w1 = spends[6]
        b2 = budgets[7]
        w2 = spends[7]
        b3 = budgets[8]
        w3 = spends[8]
      } else if ([9, 10, 11].includes(i)) {
        b1 = budgets[9]
        w1 = spends[9]
        b2 = budgets[10]
        w2 = spends[10]
        b3 = budgets[11]
        w3 = spends[11]
      }
      if (i === 0) results[0] = (b1 - w1 - w2 - w3) < 0 ? 0 : b1 - w1 - w2 - w3
      else if (i === 1) results[1] = (b1 + b2 - w1 - w2 - w3) < 0 ? 0 : b1 + b2 - w1 - w2 - w3
      else if (i === 2) results[2] = (b1 + b2 + b3 - w1 - w2 - w3) < 0 ? 0 : b1 + b2 + b3 - w1 - w2 - w3
      if (i === 3) results[3] = (b1 - w1 - w2 - w3) < 0 ? 0 : b1 - w1 - w2 - w3
      else if (i === 4) results[4] = (b1 + b2 - w1 - w2 - w3) < 0 ? 0 : b1 + b2 - w1 - w2 - w3
      else if (i === 5) results[5] = (b1 + b2 + b3 - w1 - w2 - w3) < 0 ? 0 : b1 + b2 + b3 - w1 - w2 - w3
      if (i === 6) results[6] = (b1 - w1 - w2 - w3) < 0 ? 0 : b1 - w1 - w2 - w3
      else if (i === 7) results[7] = (b1 + b2 - w1 - w2 - w3) < 0 ? 0 : b1 + b2 - w1 - w2 - w3
      else if (i === 8) results[8] = (b1 + b2 + b3 - w1 - w2 - w3) < 0 ? 0 : b1 + b2 + b3 - w1 - w2 - w3
      if (i === 9) results[9] = (b1 - w1 - w2 - w3) < 0 ? 0 : b1 - w1 - w2 - w3
      else if (i === 10) results[10] = (b1 + b2 - w1 - w2 - w3) < 0 ? 0 : b1 + b2 - w1 - w2 - w3
      else if (i === 11) results[11] = (b1 + b2 + b3 - w1 - w2 - w3) < 0 ? 0 : b1 + b2 + b3 - w1 - w2 - w3
    })
    return results
  }


  withdrawableMonths(m: number): Array<number> {

    //let monthNow: Date = DateTime.fromObject({day:13,month:6,year:2023}).toJSDate()
    let monthNow: Date = DateTime.now().toJSDate()
    let results: Array<number> = [];
    switch (m) {
      case 0:
        results = [0];
        break;
      case 1:
        results = [0, 1];
        break;
      case 2:
        results = [0, 1, 2];
        break;
      case 3:
        results = [3];
        break;
      case 4:
        results = [3, 4];
        break;
      case 5:
        results = [3, 4, 5];
        break;
      case 6:
        results = [6];
        break;
      case 7:
        results = [6, 7];
        break;
      case 8:
        results = [6, 7, 8];
        break;
      case 9:
        results = [9];
        break;
      case 10:
        results = [9, 10];
        break;
      case 11:
        results = [9, 10, 11];
        break;
      default:
        results = [];
        break;
    }
    let monthToday = monthNow.getMonth();
    let monthTodayDate = monthNow.getDate();
    if (monthToday === 0) {
      if (monthTodayDate >= 1 && monthTodayDate <= 15)
        results.push(...[0, 1, 2]);
    }
    if (monthToday === 3) {
      if (monthTodayDate >= 1 && monthTodayDate <= 15)
        results.push(...[3, 4, 5]);
    }
    if (monthToday === 6) {
      if (monthTodayDate >= 1 && monthTodayDate <= 15)
        results.push(...[6, 7, 8]);
    }
    console.log("results", { results })
    return results
  }
  // }
  //   if (m >= 0 && m <= 2)
  //     return [0, 1, 2];
  //   else if (m >= 3 && m <= 5)
  //     return [3, 4, 5];
  //   else if (m >= 6 && m <= 8)
  //     return [6, 7, 8];
  //   else if (m >= 9 && m <= 11)
  //     return [9, 10, 11];
  //   else
  //     return [0,0,0 ]
  // }
  monthRemain(n: number, type: string) {
    if (type === "PR") return this.remainArray.reduce((a, b) => a + b, 0)
    let current = 0
    let previous_1 = 0
    let previous_2 = 0
    let previous_3 = 0
    let previous_4 = 0
    let previous_5 = 0
    let previous_6 = 0
    let months = this.withdrawableMonths(n)
    try { current = this.remainArray[n] } catch (error) { }
    try { previous_1 = this.remainArray[months[0]] } catch (error) { }
    try { previous_2 = this.remainArray[months[1]] } catch (error) { }
    try { previous_3 = this.remainArray[months[2]] } catch (error) { }
    try { previous_4 = this.remainArray[months[3]] } catch (error) { }
    try { previous_5 = this.remainArray[months[4]] } catch (error) { }
    try { previous_6 = this.remainArray[months[5]] } catch (error) { }
    return (previous_1 ?? 0) + (previous_2 ?? 0) + (previous_3 ?? 0) + (previous_4 ?? 0) + (previous_5 ?? 0) + (previous_6 ?? 0)
  }
  async calculateRemaining() {
    let query = {
      search: {
        budget_id: this.data!.id
      }
    }
    let response = await new WithdrawServices().getWithdrawList(query)
    this.withdraws = response.docs
    console.log("tw", this.withdraws)
    return this.withdraws.map(item => item.amount)
  }
  async getBudgetSingle(id: string) {
    this.loading = true
    this.data = await new BudgetServices().getBudgetSingle(id)
    this.loading = false
  }
  async updateBudgetSingle() {
    // console.log("data", this.data)
    this.loading = true
    delete this.data?.withdrawn
    this.data = await new BudgetServices().updateBudget(this.data!.id!, this.data!)
    this.getBudgetSingle(this.data!.id!)
    this.loading = false
  }
  updateBudgetSinglePromise(): Promise<string> {
    return new Promise(async (resolve, reject) => {

      // console.log("data", this.data)
      this.loading = true
      delete this.data?.withdrawn
      this.data = await new BudgetServices().updateBudget(this.data!.id!, this.data!)
      this.getBudgetSingle(this.data!.id!)
      this.loading = false
      resolve("done")
    })
  }
  async updateBudgetSingleLite() {
    // console.log("data", this.data)
    this.loading = true
    delete this.data?.withdrawn
    this.data = await new BudgetServices().updateBudgetLite(this.data!.id!, this.data!)
    this.loading = false
  }
  async createBudgetSingle(project_id: string) {
    console.log(this.data)
    this.loading = true
    this.data = await new BudgetServices().createBudget(project_id, this.data!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteBudgetSingle() {
    this.loading = true
    this.data = await new BudgetServices().deleteBudgetSingle(this.data!.id!)
    this.loading = false
  }
  async specialBudgetSingle(id: string) {
    this.loading = true
    this.data = await new BudgetServices().specialBudgetSingle(id)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteBudgetIndicatorSingle(indicator_id: string) {
    console.log("ส่งอะไรมา", indicator_id)
    this.loading = true
    await new BudgetServices().deleteBudgetIndicatorSingle(indicator_id)
    this.loading = false
  }
}

@Service()
export class ActiveBudgetListClass {
  data?: PaginateResponse<BudgetModel>;
  loading: boolean = true;
  async getBudgetList(query: any) {
    this.loading = true
    this.data = await new BudgetServices().getBudgetList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteBudgetFromList(id: string) {
    this.loading = true
    let tmp = await new BudgetServices().deleteBudgetSingle(id)
    let index = this.data!.docs.findIndex(doc => doc.id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }
}
@Service()
export class ActiveBudgetParentSingleClass {
  data?: BudgetModel;
  loading: boolean = true;
  list = Container.get(ActiveBudgetListClass)
  async getBudgetParentSingle(id: string) {
    this.loading = true
    this.data = await new BudgetServices().getBudgetSingle(id)
    this.loading = false
  }
}