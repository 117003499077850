import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-end selecto-target px-1" }
const _hoisted_2 = {
  class: "d-flex justify-content-stretch h-100 position-relative show-erp-item flex-column",
  style: {"width":"70px"}
}
const _hoisted_3 = { class: "drag-money-target w-100" }
const _hoisted_4 = {
  class: "erp-child-item position-absolute h-100 bg-white",
  style: {"z-index":"999"}
}
const _hoisted_5 = { class: "list-group" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "ms-2" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (item, i) => {
    return _createElementVNode("td", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.budget.withdrawnAmountOfMonthFormat(i)), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterWithdraws(i), (withdraw) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "list-group-item",
                onClick: ($event: any) => (_ctx.cb(withdraw))
              }, [
                _createElementVNode("div", _hoisted_7, [
                  (withdraw.status==='deleted')
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(["withdraw-deleted text-nowrap", [withdraw.isSent ? 'withdraw-sent' : '', 'withdraw-draft']])
                      }, _toDisplayString(withdraw.smisCode) + " [" + _toDisplayString((withdraw.tempAmount??withdraw.amount??0).formatFull()) + " บาท]", 3))
                    : (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: _normalizeClass(["text-nowrap", [withdraw.isSent ? 'withdraw-sent' : '', 'withdraw-draft']])
                      }, [
                        _createTextVNode(_toDisplayString(withdraw.smisCode) + " [" + _toDisplayString((withdraw.tempAmount??withdraw.amount??0).formatFull()) + " บาท] ", 1),
                        (withdraw.isSent&&((withdraw.amountERP??0))>0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, "คืน" + _toDisplayString(((withdraw.amount??0)-(withdraw.amountERP??0)).formatFull()) + " บาท ", 1))
                          : _createCommentVNode("", true)
                      ], 2)),
                  _createElementVNode("div", _hoisted_9, [
                    (!withdraw.isSent)
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: "fa-solid fa-trash-alt text-danger",
                          onClick: ($event: any) => (_ctx.deleteWithdraw(withdraw))
                        }, null, 8, _hoisted_10))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 8, _hoisted_6))
            }), 256))
          ])
        ])
      ])
    ])
  }), 64))
}