import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "table plan-table table-bordered w-100",
  id: "selecto-container",
  style: {"font-size":"0.65rem"}
}
const _hoisted_3 = {
  class: "d-none",
  ref: "modalWithdrawItemMoneyBtn",
  "data-bs-toggle": "modal",
  "data-bs-target": "#modalWithdrawItemMoney"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableHeader = _resolveComponent("TableHeader")!
  const _component_TableBody = _resolveComponent("TableBody")!
  const _component_TableRow10Sum = _resolveComponent("TableRow10Sum")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createVNode(_component_TableHeader),
        _createVNode(_component_TableBody, { showRows: _ctx.showRows }, null, 8, ["showRows"]),
        _createVNode(_component_TableRow10Sum)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, null, 512)
  ], 64))
}