
import { ActiveProjectSingleClass } from "@/data/project";
import { ActiveWithdrawListClass } from "@/data/withdraw";
import BudgetModel from "@/model/budget";
import Container from "typedi";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    budget: Object,
    level: Number,
    budgetParent: String,
    budgetChild: String,
    tudNgern:Boolean,
  },
})
export default class PlanTableComponent extends Vue {
  withdrawList: ActiveWithdrawListClass = Container.get(
    ActiveWithdrawListClass
  );
  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );

  get totalBudget() {
    try {
      let map = this.activeProject.data?.budgets
        .map((budget) =>
          budget.items.map((item) => item.amount).reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0);
      return map;
    } catch (error) {
      return 0;
    }
  }
}
