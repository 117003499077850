
import { Options, Vue } from "vue-class-component";
import AttendantModel from "@/model/attendant";
import { ActiveAttendantListClass, ActiveAttendantSingleClass } from "@/data/attendant";
import { ClockData } from "@/data/clock";
import Container from "typedi";
import { DateTime } from "luxon";
import { Watch } from "vue-property-decorator";
import { ActiveProjectListClass } from "@/data/project";
import { time } from "console";
import { list } from "pdfkit";
import DocumentA from "@/document/pdf";
import store from "@/store";
import { ActiveSectorSingleClass } from "@/data/sector";

@Options({
  components: {},
})
export default class AttendantView extends Vue {
  dataList: ActiveAttendantListClass = Container.get(ActiveAttendantListClass);
  dataSingle: ActiveAttendantSingleClass = Container.get(ActiveAttendantSingleClass);
  clock!: ClockData
  time: String = ""
  today = new Date()
  fromDate: Date = DateTime.now().startOf("month").toJSDate();
  toDate: Date = DateTime.now().endOf("month").toJSDate();
  docs: Array<AttendantModel> = [];
  list: Array<AttendantModel> = [];
  pages: number = 0;
  total: number = 0;
  in: String = ""
  out: String = ""
  store = store

  updateTime() {
    this.time = DateTime.now().toFormat("H:mm:ss");
    setTimeout(() => {
      this.updateTime();
    }, 1000);
  }
  mounted() {
    this.fetchData();
    this.fetchList();
    this.updateTime();
  }
  async checkin() {
    await this.dataSingle.UserSignIn();
  }
  async checkout() {
    await this.dataSingle.UserSignOut();
  }
  async fetchData() {
    let dt = DateTime.fromJSDate(this.today)
    let query = {
      search: {
        staff: store.state.userId,
        inTime: {
          gte: dt.startOf('day').toISO(),
          lt: dt.endOf('day').toISO()
        }
      },
    };
    await this.dataList.getAttendantList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
    let prep = {
      docs: this.docs,
      pages: this.pages,
      total: this.total
    }
  }
  async fetchList() {
    let query = {
      search: {
        staff: store.state.userId,
      },
    };
    await this.dataList.getAttendantList(query);
    this.list = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
    let prep = {
      docs: this.list,
      pages: this.pages,
      total: this.total
    }
    this.in = this.dataList.userInTime(prep)
    this.out = this.dataList.userOutTime(prep)
  }

  sector1: ActiveSectorSingleClass = Container.get(
    ActiveSectorSingleClass
  );
  sector2: ActiveSectorSingleClass = Container.get(
    ActiveSectorSingleClass
  );
  sector3: ActiveSectorSingleClass = Container.get(
    ActiveSectorSingleClass
  );
  sector4: ActiveSectorSingleClass = Container.get(
    ActiveSectorSingleClass
  );
  async generatePDF() {
    console.log("generatePDF");
    const documentA = new DocumentA();
    await this.sector1.getSectorSingle(this.store.state.sector1)
    await this.sector2.getSectorSingle(this.store.state.sector2)
    await this.sector3.getSectorSingle(this.store.state.sector3)
    await this.sector4.getSectorSingle(this.store.state.sector4)
    documentA.attendant(this.dataList.data, this.fromDate, this.toDate, this.sector1.data!.name, this.sector2.data!.name, this.sector3.data!.name);
    documentA.save(`reportAttendant.pdf`);
  }
}
