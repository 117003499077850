import ADDRESS from "@/setting";
import axios from "axios"
import SectorModel from "@/model/sector";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class SectorServices {
  public getSectorList(query: any) {
    return new Promise<PaginateResponse<SectorModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/sectors/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<SectorModel> = response.data.docs.map((el: any) => new SectorModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<SectorModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getSectorSingle(id: string) {
    return new Promise<SectorModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/sector/get/${id}`);
      let doc: SectorModel = new SectorModel(response.data.doc)
      resolve(doc)
    })
  }

  public deleteSectorSingle(id: string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/sector/delete/${id}`);
      let doc: SectorModel = new SectorModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }

  public updateSector(id: string, Sector: SectorModel) {
    return new Promise<SectorModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/sector/update/${id}`, Sector.toJSON());

      let doc: SectorModel = new SectorModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createSector(Sector: SectorModel) {
    return new Promise<SectorModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/sector/create/`, Sector);
      let doc: SectorModel = new SectorModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
