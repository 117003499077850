import BudgetModel from "../budget";
import UserModel from "../user";

export default class WithdrawModel {
  id?: string;
  withdrawType?: string;
  status?: string;
  smisCode?: string;
  budget_code?: string;
  comment?: string;
  project_code?: string;
  month?: number;
  tempAmount?: number;
  amountERP?:number
  erpData?: string;
  isSent?: boolean;
  activeMonths?: string;
  budget?: BudgetModel;
  budget_id?: string;
  user_id?: string;
  createdBy: UserModel;
  items?: Array<WithdrawItemModel>;
  createdAt?: Date;
  sentDate: Date;
  erp?: Array<any>;

  get amount(){
    try {
      return this.items!.map(item=>item.amount).reduce((a,b)=>a+b,0)
    } catch (error) {
      return this.tempAmount!
    }
  }

  set amount(n:number){
    this.tempAmount = n
  }
  get sAMAccountName(){
    return this.createdBy.sAMAccountName
  }

  constructor(jsonData: any) {
    
  
    let items:Array<WithdrawItemModel> = []
    try {
      items = jsonData.items.map((m:any)=>new WithdrawItemModel(m))
    } catch (error) {
       
    }
    this.id = jsonData.id;
    this.withdrawType = jsonData.withdrawType
    this.status = jsonData.status
    this.smisCode = jsonData.smisCode
    this.budget_code = jsonData.budget_code
    this.comment = jsonData.comment
    this.project_code = jsonData.project_code
    this.month = jsonData.month ?? -1
    this.amount = jsonData.amount
    this.amountERP = jsonData.amountERP
    this.createdBy = new UserModel(jsonData.createdBy??{})
    this.erpData = jsonData.erpData
    this.isSent = jsonData.isSent??false
    this.activeMonths = jsonData.activeMonths
    this.budget = new BudgetModel(jsonData.budget??{})
    this.budget_id = jsonData.budget_id
    this.user_id = jsonData.user_id
    this.items = items
    this.createdAt = new Date(jsonData.createdAt)
    this.sentDate = jsonData.sentDate??new Date();
    this.erp = jsonData.erp
  }

  get amountFormat(){
    try {
      return this.amount!.formatFull()
    } catch (error) {
      return "-"
    }
  }
  get createdAtFormat(){
    try {
      return this.createdAt?.toThaiTime()
    } catch (error) {
      return "-"
    }
  }
  get sentDateFormat(){
    try {
      if(this.sentDate != undefined) return this.sentDate?.toThaiTime()
      else return "-"
    } catch (error) {
      return "-"
    }
  }
  toJSON() {
    
    let obj: any = Object.assign({}, this)
    obj.id = obj.id
    
    return JSON.parse(JSON.stringify(obj))
  }
}
export class WithdrawItemModel {
  id?: string;
  name: string
  product: string
  code: string
  account: string
  description: string
  qty: number
  unit: string
  amount: number
  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name ?? "";
    this.product = jsonData.product ?? "";
    this.code = jsonData.code ?? "";
    this.account = jsonData.account ?? "";
    this.description = jsonData.description ?? "";
    this.qty = jsonData.qty ?? 0;
    this.unit = jsonData.unit ?? "";
    this.amount = jsonData.amount ?? 0;
  }
  get amountFormat(){
    try {
      return this.amount!.formatFull()
    } catch (error) {
      return "-"
    }
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.id = obj.id
    
    return JSON.parse(JSON.stringify(obj))
  }
}


/*

export class WithdrawModel {
  id?: string;
  level?: string;
  withdrawType?: string;
  smisCode?: string;
  comment?: string;
  month?: string;
  amount?: string;
  erpData?: string;
  isSent?: string;
  activeMonths?: string;
  sentDate?: string;
  budget?: string;
  budget_id?: string;
  items?: Array<any>;

  constructor(jsonData: any) {
    this.level = obj.level
    this.withdrawType = obj.withdrawType
    this.smisCode = obj.smisCode
    this.comment = obj.comment
    this.month = obj.month
    this.amount = obj.amount
    this.erpData = obj.erpData
    this.isSent = obj.isSent
    this.activeMonths = obj.activeMonths
    this.sentDate = obj.sentDate
    this.budget = obj.budget
    this.budget_id = obj.budget_id
    this.items = obj.items
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.id = obj.id
    
    return JSON.parse(JSON.stringify(obj))
  }
}
*/