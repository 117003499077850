
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveProjectSingleClass } from "@/data/project";
import BudgetModel from "@/model/budget";
import Container from "typedi";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    budget: Object,
    level: Number,
    budgetParent: String,
    budgetChild: String,
    tudNgern:Boolean,
  },
})
export default class PlanTableComponent extends Vue {
  budget!: any;
  level!: number;
  budgetParent!: string;
  budgetChild!: string;

  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  menuIsVisible = false;
  tudNgern!:boolean
  monthClass(month: number) {
    try {
      let found = this.budget.items.find((item: any) => item.month === month);
      if (found.isActive) {
        if (this.level === 1) return "bg-plan1";
        else if (this.level === 2) return "bg-plan2";
        else if (this.level === 3) return "bg-plan3";
      } else return "";
    } catch (error) {
      return "";
    }
  }
  getIndicatorCode() {
    try {
      let arr = (this.budget ?? {}).indicators;
      arr = arr.map((a: any) => a.indicator.code);
      return arr;
    } catch (error) {
      return "-";
    }
  }
  getIndicatorFull() {
    try {
      let arr = (this.budget ?? {}).indicators;
      arr = arr.map((a: any) => ({
        code: a.indicator.code,
        name: a.indicator.name,
      }));
      return arr;
    } catch (error) {
      return "-";
    }
  }
  updateBudget(){
    let activeBudget = Container.get(ActiveBudgetSingleClass)
    activeBudget.data = new BudgetModel(this.budget)
    activeBudget.updateBudgetSingle()
  }
}
