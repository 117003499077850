import ADDRESS from "@/setting";
import axios from "axios"
import CabinetTargetModel from "@/model/cabinetTarget";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class CabinetTargetServices {
  public getCabinetTargetList(query: any) {
    return new Promise<PaginateResponse<CabinetTargetModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/cabinetTargets/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<CabinetTargetModel> = response.data.docs.map((el: any) => new CabinetTargetModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<CabinetTargetModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getCabinetTargetSingle(id: string) {
    return new Promise<CabinetTargetModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/cabinetTarget/get/${id}`);
      let doc: CabinetTargetModel = new CabinetTargetModel(response.data.doc)
      resolve(doc)
    })
  }

  public deleteCabinetTargetSingle(id: string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/cabinetTarget/delete/${id}`);
      let doc: CabinetTargetModel = new CabinetTargetModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }

  public updateCabinetTarget(id: string, CabinetTarget: CabinetTargetModel) {
    return new Promise<CabinetTargetModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/cabinetTarget/update/${id}`, CabinetTarget.toJSON());

      let doc: CabinetTargetModel = new CabinetTargetModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createCabinetTarget(CabinetTarget: CabinetTargetModel) {
    return new Promise<CabinetTargetModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/cabinetTarget/create/`, CabinetTarget);
      let doc: CabinetTargetModel = new CabinetTargetModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
