import IndicatorProfileModel from "../indicator";

export  default class StrategicModel {
  id?: string;
  name: string;
  vision:string;
  budgetYear:number;
  missions:Array<MissionModel>;
  objectives:Array<ObjectiveModel>;
  items:Array<StrategicItemModel>;

  constructor(jsonData: any) {
    let missions:Array<MissionModel> = []
    try {
      missions = jsonData.missions.map((m:any)=>new MissionModel(m))
    } catch (error) {
      
    }
    let objectives:Array<ObjectiveModel> = []
    try {
      objectives = jsonData.objectives.map((m:any)=>new ObjectiveModel(m))
    } catch (error) {
      
    }
    let items:Array<StrategicItemModel> = []
    try {
      items = jsonData.items.map((m:any)=>new StrategicItemModel(m))
    } catch (error) {
      
    }
    this.id = jsonData.id;
    this.name = jsonData.name;
    this.vision = jsonData.vision
    this.budgetYear = jsonData.budgetYear ?? 0
    this.missions = missions
    this.objectives = objectives
    this.items = items

    
  }
  toJSON(){
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    // obj.mission = obj.mission.map((o:any)=>o.toJSON)
    console.log("mission", obj.missions)
    
    return JSON.parse(JSON.stringify(obj))
  }
}
export class ObjectiveModel {
  id?: string;
  name: string;
  strategic: string;
  strategic_id: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name;
    this.strategic = jsonData.strategic;
    this.strategic_id = jsonData.strategic_id;
  }
  toJSON(){
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return JSON.parse(JSON.stringify(obj))
  }
}
export class MissionModel {
  id?: string;
  name: string;
  strategic: string;
  strategic_id: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name;
    this.strategic = jsonData.strategic;
    this.strategic_id = jsonData.strategic_id;
  }
  toJSON(){
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return JSON.parse(JSON.stringify(obj))
  }
}

export class StrategicItemModel {
  id?: string;
  parent_id: string;
  name: string;
  indicators: Array<StrategicItemIndicatorModel>;
  children: Array<StrategicItemModel>;   
  indicator_id: string;
  strategic: string;
  strategic_id: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.parent_id = jsonData.parent_id;
    this.name = jsonData.name;
    this.indicators = (jsonData.indicators ?? []).map((item:any)=>new StrategicItemIndicatorModel(item))
    this.children = (jsonData.children ?? []).map((item:any)=>new StrategicItemModel(item))
    this.indicator_id = jsonData.indicator_id;
    this.strategic = jsonData.strategic;
    this.strategic_id = jsonData.strategic_id;
  }
  toJSON(){
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return JSON.parse(JSON.stringify(obj))
  }
}


export class StrategicItemIndicatorModel {
  id?: string;
  name?: string;
  indicator_id?: string;
  indicator: IndicatorProfileModel;
  strategicItem_id?: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name;
    this.indicator_id = jsonData.indicator_id;
    this.indicator = new IndicatorProfileModel(jsonData.indicator??{});
    this.strategicItem_id = jsonData.strategicItem_id;
    
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return JSON.parse(JSON.stringify(obj))
  }
}