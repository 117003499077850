import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import KPIReviewModel from "@/model/kpiReview";
import KPIReviewServices from "@/service/kpiReview";
import { Service } from 'typedi';

@Service()
export class ActiveReviewIndiciatorSingleClass {
  data?: KPIReviewModel;
  loading: boolean = true;
  async getReviewIndiciatorSingle(id: string) {
    this.loading = true
    this.data = await new KPIReviewServices().getIndiciatorReviewSingle(id)
    this.loading = false
  }
  async refreshReviewIndiciatorSingle() {
    this.loading = true
    this.data = await new KPIReviewServices().getIndiciatorReviewSingle(this.data!.id!)
    this.loading = false
  }
  async updateReviewIndiciatorSingle() {
    console.log("update")
    this.loading = true
    this.data = await new KPIReviewServices().updateIndiciatorReview(this.data!.id!, this.data!)
    this.loading = false
  }
  async createReviewIndiciatorSingle() {
    console.log("create")
    this.loading = true
    this.data = await new KPIReviewServices().createIndiciatorReview(this.data!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteIndicatorReviewEvidence(id:string) {
    this.loading = true
    let response = await new KPIReviewServices().deleteIndicatorReviewEvidence(id)
    this.refreshReviewIndiciatorSingle()
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteIndicatorReviewResult(id:string){
    this.loading = true
    let response = await new KPIReviewServices().deleteIndicatorReviewResult(id)
    this.refreshReviewIndiciatorSingle()
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteIndicatorReviewAttachment(id:string) {
    this.loading = true
    let response = await new KPIReviewServices().deleteIndicatorReviewAttachment(id)
    this.refreshReviewIndiciatorSingle()
    this.loading = false
  }
  
}

@Service()
export class ActiveReviewIndiciatorListClass {
  data?: PaginateResponse<KPIReviewModel>;
  loading: boolean = true;
  async getReviewIndiciatorList(query: any) {
    this.loading = true
    this.data = await new KPIReviewServices().getIndiciatorReviewList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
}