import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import WithdrawModel from "@/model/withdraw";
import WithdrawServices from "@/service/withdraw";
import { Service } from 'typedi';

@Service()
export class ActiveWithdrawSingleClass {
  data?: WithdrawModel;
  loading: boolean = true;
  query: any = {};

  get amountFormat() {
    try {
      return this.data?.amount.formatFull()
    } catch (error) {
      return "0.00"
    }
  }

  async getWithdrawSingle(id: string) {

    this.loading = true
    this.data = await new WithdrawServices().getWithdrawSingle(id)
    this.loading = false
  }
  async sendToERP() {
    this.loading = true
    this.data = await new WithdrawServices().sendToERP(this.data!.id!, this.data!)
    this.loading = false
  }
  async sendItemToERPK2(id: string, smisCode: string, sAMAccountName: string) {
    this.loading = true
    this.data = await new WithdrawServices().sendItemToERPK2(id, smisCode, sAMAccountName)
    this.loading = false
  }
  async updateWithdrawSingle() {
    delete this.data!.budget

    this.loading = true
    this.data = await new WithdrawServices().updateWithdraw(this.data!.id!, this.data!)
    this.loading = false
  }
  async createWithdrawSingle(budget_id: string, user_id: string) {
    this.loading = true
    this.data = await new WithdrawServices().createWithdraw(budget_id, this.data!, user_id)
    this.loading = false
    this.getWithdrawSingle(this.data.id as string)
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteWithdrawSingle() {
    this.loading = true
    this.data = await new WithdrawServices().deleteWithdrawSingle(this.data!.id!)
    this.loading = false
  }
}

@Service()
export class ActiveWithdrawListClass {
  data?: PaginateResponse<WithdrawModel>;
  loading: boolean = true;
  get totalAmount() {
    try {
      let mapped: Array<number> = this.data!.docs.map(doc => doc.amount ?? 0)
      return mapped.reduce((a, b) => a + b, 0)
    } catch (error) {
      return 0
    }
  }
  get totalAmountFormat() {
    try {
      return this.totalAmount.formatFull()
    } catch (error) {
      return "0.00"
    }
  }
  get totalAmountSupport() {
    try {
      let mapped: Array<number> = this.data!.docs.filter(item => item.budget!.budgetTypeCategory === "งบอุดหนุน").map(doc => doc.amount ?? 0)
      return mapped.reduce((a, b) => a + b, 0)
    } catch (error) {
      return 0
    }
  }
  get sumSupport() {
    try {
      let all = this.data!.docs.filter(item => item.budget!.budgetTypeCategory === "งบอุดหนุน")
      let preps: Array<any> = new Array(12).fill(0)
      preps.forEach((el, i) => {
        preps[i] = all.filter(a => a.month === i).map(item => item.tempAmount ?? 0).reduce((a, b) => a + b, 0)
      });
      return preps
    } catch (error) {
      return new Array(12).fill(0)
    }
  }
  get sumInvest() {
    try {
      let all = this.data!.docs.filter(item => item.budget!.budgetTypeCategory === "งบลงทุน")
      let preps: Array<any> = new Array(12).fill(0)
      preps.forEach((el, i) => {
        preps[i] = all.filter(a => a.month === i).map(item => item.tempAmount ?? 0).reduce((a, b) => a + b, 0)
      });
      return preps
    } catch (error) {
      return new Array(12).fill(0)
    }
  }
  get totalAmountInvest() {
    try {
      let mapped: Array<number> = this.data!.docs.filter(item => item.budget!.budgetTypeCategory === "งบลงทุน").map(doc => doc.amount ?? 0)
      return mapped.reduce((a, b) => a + b, 0)
    } catch (error) {
      return 0
    }
  }
  get sumTotal() {
    try {
      let all = this.data!.docs
      let preps: Array<any> = new Array(12).fill(0)
      preps.forEach((el, i) => {
        preps[i] = all.filter(a => a.month === i).map(item => item.tempAmount ?? 0).reduce((a, b) => a + b, 0)
      });
      return preps
    } catch (error) {
      return new Array(12).fill(0)
    }
  }
  async getWithdrawList(query: any) {
    this.loading = true
    this.data = await new WithdrawServices().getWithdrawList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteWithdrawFromList(id: string) {
    this.loading = true
    let tmp = await new WithdrawServices().deleteWithdrawSingle(id)
    let index = this.data!.docs.findIndex(doc => doc.id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}