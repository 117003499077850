import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import PortalView from '../views/PortalView.vue'
import HomeView from '../views/HomeView.vue'
import DashboardView from '../views/dashboard/DashboardView.vue'

import ProjectMainView from '../views/project/ProjectMainView.vue'
import ProjectFormView from '../views/project/ProjectFormView.vue'
import ProjectListView from '../views/project/ProjectListView.vue'
import ProjectPlanView from '../views/project/ProjectPlanView.vue'
import PetitionFormView from '../views/project/petition/PetitionFormView.vue'
import PetitionListView from '../views/project/petition/PetitionListView.vue'

import WithdrawMainView from '../views/withdraw/WithdrawMainView.vue'
import WithdrawFormView from '../views/withdraw/WithdrawFormView.vue'
import WithdrawListView from '../views/withdraw/WithdrawListView.vue'
import WithdrawSummaryView from "@/views/withdraw/WithdrawSummaryView.vue"
import ERPOutBoundView from '../views/withdraw/ERPOutBoundView.vue'
import K2OutBoundView from '../views/withdraw/K2OutBoundView.vue'


import PositionMainView from '../views/position/PositionMainView.vue'
import PositionFormView from '../views/position/PositionFormView.vue'
import PositionListView from '../views/position/PositionListView.vue'
import AccountListView from '../views/account/AccountListView.vue'
import AccountFormView from '../views/account/AccountFormView.vue'

import SectorMainView from '../views/sector/SectorMainView.vue'
import SectorMapView from '../views/sector/SectorMapView.vue'
import SectorFormView from '../views/sector/SectorFormView.vue'
import SectorListView from '../views/sector/SectorListView.vue'

import CabinetTargetMainView from '../views/cabinetTarget/CabinetTargetMainView.vue'
import CabinetTargetFormView from '../views/cabinetTarget/CabinetTargetFormView.vue'
import CabinetTargetListView from '../views/cabinetTarget/CabinetTargetListView.vue'


import SystemMainView from '../views/system/SystemMainView.vue'
import SystemFormView from '../views/system/SystemFormView.vue'
import SystemListView from '../views/system/SystemListView.vue'

import AssignMainView from '../views/assignment/AssignMainView.vue'
import MyAssignFormView from '../views/assignment/MyAssignFormView.vue'
import MyAssignListView from '../views/assignment/MyAssignListView.vue'
import HistoryAssignListView from '../views/assignment/HistoryAssignListView.vue'
import HistoryAssignFormView from '../views/assignment/HistoryAssignFormView.vue'
import Attendant from '../views/assignment/Attendant.vue'


import DocumentFormView from '../views/document/DocumentFormView.vue'
import DocumentListView from '../views/document/DocumentListView.vue'
import DocumentMainView from '../views/document/DocumentMainView.vue'
import PRFormView from '../views/publicrelation/PRFormView.vue'
import PRListView from '../views/publicrelation/PRListView.vue'
import PRMainView from '../views/publicrelation/PRMainView.vue'
import PRDetailView from '../views/publicrelation/PRDetailView.vue'
import FlashNewView from '../views/publicrelation/FlashNewView.vue'

import UACMainView from '../views/uac/UACMainView.vue'
import UACFormView from '../views/uac/UACFormView.vue'
import UACListView from '../views/uac/UACListView.vue'

import ReportMainView from '../views/report/ReportMainView.vue'
import ReportListView from '../views/report/ReportListView.vue'
import ReportA08View from '../views/report/ReportA08View.vue'
import ReportA10View from '../views/report/ReportA10View.vue'
import ReportB01View from '../views/report/ReportB01View.vue'
import ReportB02View from '../views/report/ReportB02View.vue'
import ReportB19View from '../views/report/ReportB19View.vue'
import ReportB03View from '../views/report/ReportB03View.vue'
import ReportB04View from '../views/report/ReportB04View.vue'
import ReportB05View from '../views/report/ReportB05View.vue'
import ReportA06View from '../views/report/ReportA06View.vue'

import KPIMainView from '../views/kpi/KPIMainView.vue'
import IndicatorFormView from '../views/kpi/indicator/IndicatorFormView.vue'
import IndicatorListView from '../views/kpi/indicator/IndicatorListView.vue'
import PreparationView from '../views/kpi/PreparationView.vue'

import IndicatorReviewMainView from '../views/kpi/indicatorReview/IndicatorReviewMainView.vue'
import IndicatorReviewFormView from '../views/kpi/indicatorReview/IndicatorRewiewFormView.vue'
import IndicatorReviewListView from '../views/kpi/indicatorReview/IndicatorReviewListView.vue'
import IndicatorReviewKPIView from '../views/kpi/indicatorReview/IndicatorReviewListKPIView.vue'

import StrategicMainView from '../views/strategic/StrategicMainView.vue'
import StrategicFormView from '../views/strategic/StrategicFormView.vue'
import StrategicMapView from '../views/strategic/StrategicMapView.vue'
import StrategicListView from '../views/strategic/StrategicListView.vue'

import AdjustmentMainView from '../views/adjustment/AdjustmentMainView.vue'
import AdjustmentListView from '../views/adjustment/AdjustmentListView.vue'
import AdjustmentRequestListView from '../views/adjustment/AdjustmentRequestListView.vue'
import AdjustmentD1FormView from '../views/adjustment/D1FormView.vue'
import AdjustmentD3FormView from '../views/adjustment/D3FormView.vue'
import AdjustmentPlanView from '../views/adjustment/AdjustmentPlanView.vue'

import FollowUpMainView from '../views/followup/FollowUpMainView.vue'
import FollowUpFormView from '../views/followup/FollowUpFormView.vue'
import FollowUpListView from '../views/followup/FollowUpListView.vue'

import OverlapMainView from '../views/overlap/OverlapMainView.vue'
import OverlapFormView from '../views/overlap/OverlapFormView.vue'
import OverlapListView from '../views/overlap/OverlapListView.vue'
import OverlapReportListView from '../views/overlap/OverlapReportListView.vue'
import OverlapReportFormView from '../views/overlap/OverlapReportFormView.vue'
import OverlapReportDetailView from '../views/overlap/OverlapReportDetailView.vue'

import LoginView from '../views/login/LoginView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/portal',
    name: 'portal',
    component: PortalView,
    children: [
      {
        path: 'kpi',
        name: 'kpi',
        component: KPIMainView,
        children: [
          {
            path: 'indicator',
            name: 'indicator list',
            component: IndicatorListView
          },
          {
            path: 'indicator/create',
            name: 'indicator form',
            component: IndicatorFormView
          },
          {
            path: 'indicator/edit/:id',
            name: 'indicator edit',
            component: IndicatorFormView
          },

          {
            path: 'strategic',
            name: 'strategic Main',
            component: StrategicMainView,
            children: [
              {
                path: '',
                name: 'strategic  list',
                component: StrategicListView
              },
              {
                path: 'create',
                name: 'strategic  form',
                component: StrategicFormView
              },
              {
                path: 'edit/:id',
                name: 'strategic  edit',
                component: StrategicFormView
              },
              {
                path: 'map/:id',
                name: 'strategic  map',
                component: StrategicMapView
              }
            ]
          },
          {
            path: 'review',
            name: 'indicator main',
            component: IndicatorReviewMainView,
            children: [
              {
                path: '',
                name: 'indicator kpi list',
                component: IndicatorReviewKPIView
              },
              {
                path: 'list/:id',
                name: 'indicator review list',
                component: IndicatorReviewListView
              },
              {
                path: 'create/:indicatorid',
                name: 'indicator review  form',
                component: IndicatorReviewFormView
              },
              {
                path: 'edit/:indicatorid/:id',
                name: 'indicator review  edit',
                component: IndicatorReviewFormView
              },
              {
                path: 'map/:id',
                name: 'indicator review  detail',
                component: StrategicMapView
              }
            ]
          },
          {
            path: 'preparation',
            name: 'indicator preparation list',
            component: PreparationView
          },
        ]
      },
      {
        path: 'project',
        name: 'project',
        component: ProjectMainView,
        children: [
          {
            path: 'list',
            name: 'project list',
            component: ProjectListView
          },
          {
            path: 'create',
            name: 'project form',
            component: ProjectFormView
          },
          {
            path: 'edit/:id',
            name: 'project edit',
            component: ProjectFormView
          },
          {
            path: 'detail/:id',
            name: 'project detail',
            component: ProjectFormView
          },
          {
            path: 'plan/:id',
            name: 'project plan',
            component: ProjectPlanView
          },
          {
            path: 'petition/create/:projectid',
            name: 'petition create',
            component: PetitionFormView
          },
          {
            path: 'petition/edit/:id',
            name: 'petition edit',
            component: PetitionFormView
          },
          {
            path: 'petition/list/:projectid',
            name: 'petition list',
            component: PetitionListView
          },
        ]
      },
      {
        path: 'adjustment',
        name: 'adjustment',
        component: AdjustmentMainView,
        children: [
          {
            path: '',
            name: 'adjustment list',
            component: AdjustmentListView
          },
          {
            path: 'plan/edit/:id',
            name: 'adjustment plan',
            component: AdjustmentPlanView
          },
          {
            path: 'request/:id',
            name: 'adjustment request list',
            component: AdjustmentRequestListView
          },
          {
            path: 'request/create/:projectid',
            name: 'D1 form',
            component: AdjustmentD1FormView
          },
          {
            path: 'request/edit/:id',
            name: 'D1 edit',
            component: AdjustmentD1FormView
          },
          {
            path: 'request/detail/:id',
            name: 'D1 detail',
            component: AdjustmentD1FormView
          },
          {
            path: 'request/edit/:id',
            name: 'D1 Edit',
            component: AdjustmentD1FormView
          },
          {
            path: 'd3/:id',
            name: 'D3 form',
            component: AdjustmentD3FormView
          },
          {
            path: 'd3/detail/:id',
            name: 'D3 detail',
            component: AdjustmentD3FormView
          },
        ]
      },
      {
        path: 'withdraw',
        name: 'withdraw',
        component: WithdrawMainView,
        children: [
          {
            path: 'list',
            name: 'erp withdraw',
            component: WithdrawListView
          },
          {
            path: 'edit/:id',
            name: 'erp withdraw edit',
            component: WithdrawFormView
          },
          {
            path: 'detail/:id',
            name: 'erp withdraw detail',
            component: WithdrawFormView
          },
          {
            path: 'form/:id',
            name: 'erp withdraw form',
            component: WithdrawFormView
          },
          {
            path: 'summary',
            name: 'erp summary',
            component: WithdrawSummaryView
          },
          {
            path: 'report',
            name: 'erp report',
            component: HomeView
          },
          {
            path: 'delete',
            name: 'erp delete',
            component: HomeView
          },
          {
            path: 'erp/outbound',
            name: 'erp outbound',
            component: ERPOutBoundView
          },
          {
            path: 'k2/outbound',
            name: 'k2 outbound',
            component: K2OutBoundView
          },
        ]
      },
      {
        path: 'overlap',
        name: 'overlap',
        component: OverlapMainView,
        children: [
          {
            path: 'list',
            name: 'overlap list',
            component: OverlapListView
          },
          {
            path: 'edit/:id',
            name: 'overlap edit',
            component: OverlapFormView
          },
          {
            path: 'report-list',
            name: 'overlap report list',
            component: OverlapReportListView
          },
          {
            path: 'report-form/:budgetYear',
            name: 'overlap report form',
            component: OverlapReportFormView
          },
          {
            path: 'report-detail/:budgetYear',
            name: 'overlap report detail',
            component: OverlapReportDetailView
          },
          // {
          //   path: 'overlap/report',
          //   name: 'overlap report',
          //   component: OverlapReportView
          // },
        ]
      },
      {
        path: 'followup',
        name: 'follow',
        component: FollowUpMainView,
        children: [
          {
            path: '',
            name: 'follow project',
            component: FollowUpListView
          },
          {
            path: 'form/:id',
            name: 'follow project form',
            component: FollowUpFormView
          },
          {
            path: 'edit/:id',
            name: 'follow project edit',
            component: FollowUpFormView
          },
          {
            path: 'detail/:id',
            name: 'follow project detail',
            component: FollowUpFormView
          },
        ]
      },
      {
        path: 'report',
        name: 'report',
        component: ReportMainView,
        children: [
          {
            path: '',
            name: 'report list',
            component: ReportListView,

          },
          {
            path: 'A06/:budgetYear',
            name: 'Report A06',
            component: ReportA06View
          },
          {
            path: 'A10/:budgetYear',
            name: 'Report A10',
            component: ReportA10View
          },
          {
            path: 'A08',
            name: 'Report A08',
            component: ReportA08View
          },          
          {
            path: 'B01/detail/:id',
            name: 'Report B01',
            component: ReportB01View
          },
          {
            path: 'B02/detail/:id',
            name: 'Report B02',
            component: ReportB02View
          },
          {
            path: 'B18/detail/:indicatorid/:id',
            name: 'Report B18',
            component: IndicatorReviewFormView
          },
          {
            path: 'B19/detail/:budgetYear',
            name: 'Report B19',
            component: ReportB19View
          },
          {
            path: 'B03',
            name: 'Report B03',
            component: ReportB03View
          },
          {
            path: 'B04',
            name: 'Report B04',
            component: ReportB04View
          },
          {
            path: 'B05',
            name: 'Report B05',
            component: ReportB05View
          }
        ]
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardView
      },
      {
        path: 'pr',
        name: 'pr',
        component: PRMainView,
        children: [
          {
            path: 'announcement',
            name: 'pr announcement',
            component: PRListView
          },
          {
            path: 'announcement/create',
            name: 'pr announcement form',
            component: PRFormView
          },
          {
            path: 'announcement/edit/:id',
            name: 'pr announcement edit',
            component: PRFormView
          },
          {
            path: 'announcement/detail/:id',
            name: 'pr announcement detail',
            component: PRDetailView
          },
          {
            path: 'flashnews',
            name: 'pr flashnews',
            component: FlashNewView
          },
          {
            path: 'document',
            name: 'pr document',
            component: DocumentListView
          },
          {
            path: 'document/create',
            name: 'pr document form',
            component: DocumentFormView
          },
          {
            path: 'document/edit/:id',
            name: 'pr document edit',
            component: DocumentFormView
          },
        ]
      },
      {
        path: 'assignment',
        name: 'assignment',
        component: AssignMainView,
        children: [
          {
            path: 'my-assignment',
            name: 'my assignment',
            component: MyAssignListView
          },
          {
            path: 'my-assignment/create',
            name: 'my assignment form',
            component: MyAssignFormView
          },
          {
            path: 'my-assignment/edit/:id',
            name: 'my assignment edit',
            component: MyAssignFormView
          },
          {
            path: 'my-assignment/detail/:id',
            name: 'my assignment detail',
            component: MyAssignFormView
          },
          {
            path: 'assignment-submission',
            name: 'assignment submission',
            component: HistoryAssignListView
          },
          {
            path: 'assignment-submission/edit/:id',
            name: 'assignment submission edit',
            component: HistoryAssignFormView
          },
          {
            path: 'assignment-submission/detail/:id',
            name: 'assignment submission detail',
            component: HistoryAssignFormView
          },
          {
            path: 'attendant',
            name: 'attendant',
            component: Attendant
          }
        ]
      },
      {
        path: 'uac',
        name: 'uac',
        component: UACMainView,
        children: [
          {
            path: '',
            name: 'uac list',
            component: UACListView
          },
          {
            path: 'create',
            name: 'uac form',
            component: UACFormView
          },
          {
            path: 'edit/:id',
            name: 'uac edit',
            component: UACFormView
          },
          {
            path: 'detail/:id',
            name: 'uac detail',
            component: UACFormView
          },
        ]
      },
      // {
      //   path: 'uac',
      //   name: 'uac',
      //   component: UACFormView,
      //   children: [
      //     {
      //       path: 'uac',
      //       name: 'uac list',
      //       component:  UACListView
      //     },
      //     {
      //       path: 'uac/edit/:id',
      //       name: 'uac edit',
      //       component: UACFormView
      //     },
      //     {
      //       path: 'uac/create',
      //       name: 'uac form',
      //       component: UACFormView
      //     },
      //   ]
      // },
      {
        path: 'setting',
        name: 'setting',
        component: PositionMainView,
        children: [
          {
            path: 'system',
            name: 'system',
            component: SystemListView
          },
          {
            path: 'system/edit/:id',
            name: 'system edit',
            component: SystemFormView
          },
          {
            path: 'system/create',
            name: 'system form',
            component: SystemFormView
          },
          {
            path: 'position',
            name: 'position',
            component: PositionListView
          },
          {
            path: 'position/edit/:id',
            name: 'position edit',
            component: PositionFormView
          },
          {
            path: 'position/detail/:id',
            name: 'position detail',
            component: PositionFormView
          },
          {
            path: 'position/create',
            name: 'position form',
            component: PositionFormView
          },
          {
            path: 'sector',
            name: 'sector',
            component: SectorListView
          },
          {
            path: 'sector/map',
            name: 'sector map',
            component: SectorMapView
          },
          {
            path: 'sector/edit/:id',
            name: 'sector edit',
            component: SectorFormView
          },
          {
            path: 'sector/detail/:id',
            name: 'sector detail',
            component: SectorFormView
          },
          {
            path: 'sector/create',
            name: 'sector form',
            component: SectorFormView
          },

          {
            path: 'cabinetTarget',
            name: 'cabinetTarget',
            component: CabinetTargetListView
          },
          {
            path: 'cabinetTarget/edit/:id',
            name: 'cabinetTarget edit',
            component: CabinetTargetFormView
          },
          {
            path: 'cabinetTarget/detail/:id',
            name: 'cabinetTarget detail',
            component: CabinetTargetFormView
          },
          {
            path: 'cabinetTarget/create',
            name: 'cabinetTarget form',
            component: CabinetTargetFormView
          },

          {
            path: 'account',
            name: 'account',
            component: AccountListView
          },
          {
            path: 'account/edit/:id',
            name: 'account edit',
            component: AccountFormView
          },
          {
            path: 'account/detail/:id',
            name: 'account detail',
            component: AccountFormView
          },
          {
            path: 'account/create',
            name: 'account form',
            component: AccountFormView
          },

        ]
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
