
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveProjectSingleClass } from "@/data/project";
import { confirmAction } from "@/decorator";
import BudgetModel from "@/model/budget";
import Container from "typedi";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    budget: Object,
    level: Number,
    budgetParent: String,
    budgetChild: String,
    tudNgern:Boolean,
  },
})
export default class PlanTableComponent extends Vue {
  budget!: any;
  level!: number;
  budgetParent!: string;
  budgetChild!: string;

  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  menuIsVisible = false;
  tudNgern!:boolean
  monthClass(month: number) {
    try {
      let found = this.budget.items.find((item: any) => item.month === month);
      if (found.isActive) {
        if (this.level === 1) return "bg-plan1";
        else if (this.level === 2) return "bg-plan2";
        else if (this.level === 3) return "bg-plan3";
      } else return "";
    } catch (error) {
      return "";
    }
  }
  getIndicatorCode() {
    try {
      let arr = (this.budget ?? {}).indicators;
      arr = arr.map((a: any) => a.indicator.code);
      return arr;
    } catch (error) {
      return "-";
    }
  }
  getIndicatorFull() {
    try {
      let arr = (this.budget ?? {}).indicators;
      arr = arr.map((a: any) => ({
        code: a.indicator.code,
        name: a.indicator.name,
      }));
      arr = arr.map((item:any)=>(item.code+":"+item.name))
      return arr;
    } catch (error) {
      return "-";
    }
  }
  showContextMenu() {
    if (this.activeProject.data!.step < 3) {
    this.menuIsVisible = !this.menuIsVisible;
    } else {
      alert("ไม่สามารถแก้ไขได้โครงการถูกอนุมัติ");
    }
  }
  showModal(){
    this.openModal();
  }
  openModal() {
    this.$nextTick(() => {
      let ref = this.$refs.openModalFromTableRow as HTMLElement;
      ref.click();
    });
  }
  activeChild() {
    let parent_id = this.budget.id;
    let budgetTypeCategory = this.budget.budgetTypeCategory
    this.activeBudget.data = new BudgetModel({ parent_id: parent_id, budgetTypeCategory:budgetTypeCategory });
    this.activeBudget.parent = this.budgetParent;
    this.activeBudget.child = this.budgetChild;
    this.openModal();
  }
  activeItem() {
    if(this.budget.isSpecial){
    alert("กิจกรรมนี้เป็นกิจกรรมควบคุมพิเศษ กรุณาติดต่อ admin หรือ ผู้ประสานงานโครงการ")
    }
   else  if (this.activeProject.data!.step < 3) {
      console.log(this.budget);
      this.activeBudget.data = new BudgetModel({});
      this.activeBudget.data = this.budget;
      this.activeBudget.parent = this.budgetParent;
      this.activeBudget.child = this.budgetChild;
      this.openModal();
    } else {
      alert("ไม่สามารถแก้ไขได้โครงการถูกอนุมัติ");
    }
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteItem() {
    this.activeBudget.data = this.budget;
    console.log("deleting...!");
    await this.activeBudget.deleteBudgetSingle();
    await this.activeProject.getProjectSingle(this.$route.params.id as string);
    console.log("delete done!");
  }
  async clearBudget() {
    console.log("lkkkkk")
    this.activeBudget.data = new BudgetModel({});
    this.openModal();
  }
}
