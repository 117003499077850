export default class IndicatorProfileModel {
  id?: string;
  budgetYear: Number;
  type: string;
  sector: string;
  code: string;
  name: string;
  unit: string;
  weight: string;
  description: string;
  calculation: string;
  criteria: string;
  metric: Array<IndicatorMetricModel>
  responsibles: Array<IndicatorResponsiblePersonModel>
  notes: string;
  basicInformation: string;
  basicUnit: string;
  basicHistoryYear1: string;
  basicHistoryYear2: string;
  basicHistoryYear3: string;
  basicHistoryAmount1: string;
  basicHistoryAmount2: string;
  basicHistoryAmount3: string;
  source: string;
  createdIP: string;
  modifiedIP: string;
  createdAt: Date;
  modifiedAt: Date;

  constructor(obj: any) {
    this.id = obj.id
    this.budgetYear = obj.budgetYear
    this.type = obj.type
    this.sector = obj.sector
    this.code = obj.code
    this.name = obj.name
    this.unit = obj.unit
    this.weight = obj.weight
    this.description = obj.description
    this.calculation = obj.calculation
    this.criteria = obj.criteria
    this.metric = (obj.metric ?? []).map((item: any) =>{
      let finishDate = item.finishDate ?? new Date().toISOString()
      return  new IndicatorMetricModel({...item, finishDate})
    });
    this.responsibles = obj.responsibles ?? [].map((item: any) => new IndicatorResponsiblePersonModel(item));
    this.notes = obj.notes
    this.basicInformation = obj.basicInformation
    this.basicUnit = obj.basicUnit
    this.basicHistoryYear1 = obj.basicHistoryYear1
    this.basicHistoryYear2 = obj.basicHistoryYear2
    this.basicHistoryYear3 = obj.basicHistoryYear3
    this.basicHistoryAmount1 = obj.basicHistoryAmount1
    this.basicHistoryAmount2 = obj.basicHistoryAmount2
    this.basicHistoryAmount3 = obj.basicHistoryAmount3
    this.source = obj.source
    this.createdIP = obj.createdIP
    this.modifiedIP = obj.modifiedIP
    this.createdAt = obj.createdAt
    this.modifiedAt = obj.modifiedAt
  }

  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id

    try {
      obj.metric = obj.metric.map((el: any) => {
        return new IndicatorMetricModel(el).toJSON()
      })
    } catch (error) {

    }
    try {
      obj.responsibles = obj.responsibles.map((el: any) => {
        return new IndicatorResponsiblePersonModel(el).toJSON()
      })
    } catch (error) {

    }
    return JSON.parse(JSON.stringify(obj))
  }
}
export class IndicatorMetricModel {
  id?: string;
  name: string;
  percentage: string;
  finishDate: Date;
  score: number;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name;
    this.percentage = jsonData.percentage;
    this.finishDate = jsonData.finishDate;
    this.score = jsonData.score;
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id

    return JSON.parse(JSON.stringify(obj))
  }
}
export class IndicatorResponsiblePersonModel {
  id?: string;
  name: string;
  type: string;
  telephone: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name;
    this.type = jsonData.type;
    this.telephone = jsonData.telephone;
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    return JSON.parse(JSON.stringify(obj))
  }
}
