
import { Options, Vue } from "vue-class-component";
import {
  IndicatorMetricModel,
  IndicatorResponsiblePersonModel,
} from "@/model/indicator";
import { ActiveIndiciatorSingleClass } from "@/data/indicator";
import Container from "typedi";
import { DocumentDocx } from "@/document/docx/B";
import { ReportB05Class } from "@/data/report";
import LoadingWidget from "@/components/LoadingWidget.vue";
import { DocumentExcel } from "@/document/xlsx";

@Options({
  components: {},
})
export default class ReportB01View extends Vue {
  IndicatorMetricModel = IndicatorMetricModel;
  IndicatorResponsiblePersonModel = IndicatorResponsiblePersonModel;
  dataSingle: ReportB05Class = Container.get(ReportB05Class);
  exportExcel(){

    let docExcel = new DocumentExcel();
    docExcel.createB05(this.dataSingle.data!, 2565);
  }
  mounted() {
    this.fetchData();
  }
  async fetchData() {
    await this.dataSingle.getReportB05List({ budgetYear: 2565 });
  }
}
