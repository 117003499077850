import BudgetModel, { BudgetItemModel } from "../budget";
import SectorModel from "../sector";
import { SettingMemberModel } from "../system";
import * as _ from "lodash";
import AdjustmentModel from "../adjustment";

export default class ProjectModel {
  id?: string;
  budgetSource: string;
  budgetType: string;
  budgetYear: number;
  sector: SectorModel;
  sector_id: string;
  code: string;
  name: string;
  step: number;
  coordinatorName: string;
  budgets: Array<BudgetModel>
  items: Array<ProjectItemModel>
  totalBudgetAmount?: number;
  totalWithdrawAmount?: number;
  totalRemainingAmount?: number;
  asPlanned: string;
  progress: number;
  settings?: Array<SettingMemberModel>;
  adjustment: Array<AdjustmentModel>
  approvals?: Array<ProjectApproval>;
  approver1_id?: string;
  approver2_id?: string;
  approver3_id?: string;
  approver4_id?: string;
  approver5_id?: string;
  approver6_id?: string;
  c7ApproveName?: string;
  c7ApproveDate?: Date;
  c7Signature?: string;

  project_code?: string;
  indicatorsResolved: Array<string>;
  isRequest?: boolean;

  findItem(code: string) {
    try {
      return this.items!.find(item => item.code === code)
    } catch (error) {
      this.items.push(new ProjectItemModel({}))
      return this.items![this.items.length - 1]
    }
    return new ProjectItemModel({})
  }
  get cleanStep() {
    if (this.step >= 0) return this.step
    else return Math.abs(this.step)
  }

  get stepPercent() {
    try {
      let maxStep = 3
      let result = 100 * (this.cleanStep / maxStep)
      return result
    } catch (error) {
      return 0
    }
  }

  get totalBudgetAmountFormat() {
    try {
      return (this.totalBudgetAmount ?? 0).formatFull()
    } catch (error) {
      return "0.00"
    }
  }
  get totalWithdrawAmountFormat() {
    try {
      return (this.totalWithdrawAmount ?? 0).formatFull()
    } catch (error) {
      return "0.00"
    }
  }
  get totalRemainingAmountFormat() {
    try {
      return (this.totalRemainingAmount ?? 0).formatFull()
    } catch (error) {
      return "0.00"
    }
  }
  constructor(jsonData: any, settings?: Array<SettingMemberModel>) {
    let newSettings: Array<SettingMemberModel> = Object.assign([], settings)
    // console.log({ newSettings })
    let budgets = []
    try {
      budgets = (jsonData.budgets ?? []).map((o: any) => new BudgetModel(o))
    } catch (error) {

    }
    let items: Array<ProjectItemModel> = []
    try {
      items = (jsonData.items ?? []).map((o: any) => new ProjectItemModel(o))
    } catch (error) {

    }
    newSettings.forEach(setting => {
      let foundItem = items.find(item => item.code === setting.text1)
      if (foundItem) {
        setting.id = foundItem.id
      } else {
        delete setting.id
        items.push(new ProjectItemModel({ code: setting.text1, itemType: setting.text2, name: setting.name }))
      }
    });
    this.id = jsonData.id;
    this.budgetSource = jsonData.budgetSource ?? "";
    this.budgetType = jsonData.budgetType ?? "";
    this.budgetYear = jsonData.budgetYear ?? 0;
    this.step = jsonData.step;
    this.sector = jsonData.sector;
    this.adjustment = jsonData.adjustment??[];
    this.sector_id = jsonData.sector_id ?? "";
    this.code = jsonData.code ?? "";
    this.name = jsonData.name ?? "";
    this.coordinatorName = jsonData.coordinatorName ?? "";
    this.budgets = budgets
    this.items = items.map(item => new ProjectItemModel(item))
    this.totalBudgetAmount = jsonData.totalBudgetAmount;
    this.totalWithdrawAmount = jsonData.totalWithdrawAmount;
    this.totalRemainingAmount = jsonData.totalRemainingAmount;
    this.asPlanned = jsonData.asPlanned;
    this.progress = jsonData.progress;
    this.approvals = (jsonData.approvals ?? []).map((item: any) => new ProjectApproval(item));
    this.approver1_id = jsonData.approver1_id;
    this.approver2_id = jsonData.approver2_id;
    this.approver3_id = jsonData.approver3_id;
    this.approver4_id = jsonData.approver4_id;
    this.approver5_id = jsonData.approver5_id;
    this.approver6_id = jsonData.approver6_id;
    this.indicatorsResolved = jsonData.indicatorsResolved;
    this.isRequest = jsonData.isRequest ?? false;
    this.c7ApproveName = jsonData.c7ApproveName;
    this.c7ApproveDate = jsonData.c7ApproveDate;
    this.c7Signature = jsonData.c7Signature;
    this.project_code = jsonData.project_code;
  }

  get sectorName() {
    try {
      return this.sector.name
    } catch (error) {
      return "-"
    }
  }
  get indicatorsText() {
    try {
      let array = this.budgets.map(budget => budget.indicators).flat().map(item => item.indicator)
      let result = array.map(item => `${item.code}`)
      result = _.uniq(result);
      return result.join(", ")
      // return array.map(item => `${item.code}`).join(", ")
    } catch (error) {
      return ""
    }
  }
  get indicatorsFullText() {
    try {
      let array = this.budgets.map(budget => budget.indicators).flat().map(item => item.indicator)
      let result = array.map(item => `${item.code}-${item.name}`)
      result = _.uniq(result);
      return result.join(", ")
    } catch (error) {
      return ""
    }
  }
  get fullName() {
    return this.code + " " + this.name
  }
  get grandTotalFinalAmount() {
    try {
      let mapped = this.budgets.map(budget => budget.totalAmount ?? 0)
      let reduced = mapped.reduce((a, b) => a + b, 0)
      return reduced
    } catch (error) {
      return 0
    }
  }
  get grandTotalSupportAmount() {
    try {
      let mapped = this.budgets.map(budget => budget.totalSupportAmount ?? 0)
      let reduced = mapped.reduce((a, b) => a + b, 0)
      if (reduced == 0) return reduced.formatFull()
      else return reduced.formatFull()
    } catch (error) {
      return "0.00"
    }
  }
  get grandTotalSupportAmountA09() {
    try {
      let mapped = this.budgets.map(budget => budget.totalSupportAmount ?? 0)
      let reduced = mapped.reduce((a, b) => a + b, 0)
      if (reduced == 0) return reduced
      else return reduced
    } catch (error) {
      return "0.00"
    }
  }

  get grandTotalSupportNumber() {
    try {
      let mapped = this.budgets.map(budget => budget.totalSupportAmount ?? 0)
      let reduced = mapped.reduce((a, b) => a + b, 0)
      if (reduced == 0) return reduced
      else return reduced
    } catch (error) {
      return 0
    }
  }
  get grandTotalInvestAmount() {
    try {
      let mapped = this.budgets.map(budget => budget.totalInvestAmount ?? 0)
      let reduced = mapped.reduce((a, b) => a + b, 0)
      if (reduced == 0) return reduced.formatFull()
      else return reduced.formatFull()
    } catch (error) {
      return "0.00"
    }
  }

  get grandTotalInvestAmountA09() {
    try {
      let mapped = this.budgets.map(budget => budget.totalInvestAmount ?? 0)
      let reduced = mapped.reduce((a, b) => a + b, 0)
      if (reduced == 0) return reduced
      else return reduced
    } catch (error) {
      return "0.00"
    }
  }

  get grandTotalInvestNumber() {
    try {
      let mapped = this.budgets.map(budget => budget.totalInvestAmount ?? 0)
      let reduced = mapped.reduce((a, b) => a + b, 0)
      if (reduced == 0) return reduced
      else return reduced
    } catch (error) {
      return 0
    }
  }
  get grandTotalSupportArray() {
    try {
      let filtered = this.budgets.filter(b => b.budgetTypeCategory === "งบอุดหนุน")
      let results: Array<number> = []
      let resultsFormat: Array<string> = []
      for (let i = 0; i < 12; i++) {
        results.push(filtered.map(budget => budget.items[i]).map(item => item.amount ?? 0).reduce((a, b) => a + b, 0))
      }
      resultsFormat = results.map(rs => rs == 0 ? rs.formatDash() : rs.formatFull())
      return resultsFormat
    } catch (error) {
      return 0
    }
  }
  get grandTotalSupportValue() {
    try {
      let filtered = this.budgets.filter(b => b.budgetTypeCategory === "งบอุดหนุน")
      let results: Array<number> = []
      let resultsFormat: Array<string> = []
      for (let i = 0; i < 12; i++) {
        results.push(filtered.map(budget => budget.items[i]).map(item => item.amount ?? 0).reduce((a, b) => a + b, 0))
      }
      return results
    } catch (error) {
      return 0
    }
  }
  get grandTotalInvestArray() {
    let filtered = this.budgets.filter(b => b.budgetTypeCategory === "งบลงทุน")
    let results: Array<number> = []
    let resultsFormat: Array<string> = []
    for (let i = 0; i < 12; i++) {
      results.push(filtered.map(budget => budget.items[i]).map(item => item.amount ?? 0).reduce((a, b) => a + b, 0))
    }
    resultsFormat = results.map(rs => rs == 0 ? rs.formatDash() : rs.formatFull())
    return resultsFormat
  }
  get grandTotalInvestValue() {
    let filtered = this.budgets.filter(b => b.budgetTypeCategory === "งบลงทุน")
    let results: Array<number> = []
    for (let i = 0; i < 12; i++) {
      results.push(filtered.map(budget => budget.items[i]).map(item => item.amount ?? 0).reduce((a, b) => a + b, 0))
    }
    return results
  }
  get grandTotalArray() {

    let results: Array<number> = []
    let resultsFormat: Array<string> = []
    for (let i = 0; i < 12; i++) {
      results.push(this.budgets.map(budget => budget.items[i]).map(item => item.amount ?? 0).reduce((a, b) => a + b, 0))
    }
    resultsFormat = results.map(rs => rs == 0 ? rs.formatDash() : rs.formatFull())
    return resultsFormat
  }
  get grandTotalArrayA09() {

    let results: Array<number> = []
    let resultsFormat: Array<string> = []
    for (let i = 0; i < 12; i++) {
      results.push(this.budgets.map(budget => budget.items[i]).map(item => item.amount ?? 0).reduce((a, b) => a + b, 0))
    }
    resultsFormat = results.map(rs => rs == 0 ? rs.formatDash() : rs.formatComma())
    return resultsFormat
  }
  get grandTotalArrayValue() {

    let results: Array<number> = []
    let resultsFormat: Array<string> = []
    for (let i = 0; i < 12; i++) {
      results.push(this.budgets.map(budget => budget.items[i]).map(item => item.amount ?? 0).reduce((a, b) => a + b, 0))
    }
    return results
  }
  toJSON() {
    let obj = Object.assign({}, this)
    delete obj.settings
    return JSON.parse(JSON.stringify(obj))
  }
}

export class ProjectItemModel {
  id?: string;
  name: string;
  itemType: string;
  code: string;
  value: any;
  constructor(jsonData: any) {
    this.id = jsonData.id
    this.name = jsonData.name ?? ""
    this.itemType = jsonData.itemType ?? ""
    this.code = jsonData.code ?? ""
    this.value = jsonData.value
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return JSON.parse(JSON.stringify(obj))
  }
}

export class ProjectStrategicModel {
  id?: string;
  name?: string;
}
export class ProjectTacticModel {
  id?: string;
  name?: string;
}
export class ProjectPlanModel {
  id?: string;
  name?: string;
}
export class ProjectIndicator {
  id?: string;
  name?: string;
}
export class ProjectBudget {
  id?: string;
  name?: string;
  year?: string;
  amount?: number;
}
export class ProjectResponsible {
  id?: string;
  name?: string;
  position?: string;
  sector1?: string;
  sector2?: string;
  telephone?: string;
  email?: string;
  signature?: string;
}


export class ProjectApproval {
  id?: string;
  reason: string;
  fromStep: number;
  toStep: number;
  createdAt: Date;
  maxStep = 3;
  constructor(jsonData: any, maxStep?: number) {
    this.id = jsonData.id
    this.reason = jsonData.reason ?? "-"
    this.fromStep = jsonData.fromStep ?? -10
    this.toStep = jsonData.toStep ?? -10
    this.createdAt = new Date(jsonData.createdAt)
    this.maxStep = maxStep ?? 3
  }
  get createdAtFormat() {
    return this.createdAt.toThaiTime()
  }
  get cleanFromStep() {
    if (this.fromStep >= 0) return this.fromStep
    else return Math.abs(this.fromStep)
  }

  get fromStepPercent() {
    try {

      let result = 100 * (this.cleanFromStep / this.maxStep)
      return result
    } catch (error) {
      return 0
    }

  }
  get cleanToStep() {
    if (this.toStep >= 0) return this.toStep
    else return Math.abs(this.toStep)
  }

  get toStepPercent() {
    try {
      let result = 100 * (this.cleanToStep / this.maxStep)
      return result
    } catch (error) {
      return 0
    }
  }
}