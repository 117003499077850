
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import SystemModel from "@/model/system";
import { useStore } from "vuex";
import {
  ActiveProjectListClass,
  ActiveProjectSingleClass,
} from "@/data/project";
import {
  ActiveAdjustmentListClass,
  ActiveAdjustmentSingleClass,
} from "@/data/adjustment";
import Container from "typedi";
import AdjustmentModel, { AdjustmentAttachmentModel } from "@/model/adjustment";
import ApprovalProgress from "./components/ApprovalProgress.vue";
import { ActiveSectorListClass } from "@/data/sector";
import { ActiveBudgetYearClass } from "@/data/system";
import { AttachmentSingleClass } from "@/data/adjustment";
import ADDRESS from "@/setting";
import { confirmAction } from "@/decorator";

@Options({
  components: { ApprovalProgress },
})
export default class ProjectListView extends Vue {
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);
  adjustmentList: ActiveAdjustmentListClass = Container.get(
    ActiveAdjustmentListClass
  );
  dataSingle: ActiveAdjustmentSingleClass = Container.get(
    ActiveAdjustmentSingleClass
  );
  store = useStore();
  ADDRESS = ADDRESS;
  docs: Array<AdjustmentModel> = [];
  callouts = [
    "C7(รับผิดชอบ)",
    "C8(รับผิดชอบ)",
    "C9(รับผิดชอบ)",
    "C7(แผน)",
    "C8(แผน)",
    "C9(แผน)",
    "C10(รองผู้ว่าฯ)",
    "C11(ผู้ว่าฯ)",
  ];
  pages: number = 0;
  total: number = 0;
  sector_id = "";
  search = "";
  active: number = 0;
  type: string = "";
  isDetail: boolean = false;

  renderTableTool(item: AdjustmentModel) {
    let result:Array<string> = []
    if(this.store.getters.hasRight("budget_form_delete")) result.push("Delete")

      if(item.step < 6) {
        if(item.step === 0) {
          if(this.project.data!.approver1_id == this.store.state.userId) {
            result.push("Approve")
            result.push("Reject")
          }
        }
        else if(item.step === 1) {
          if(this.project.data!.approver2_id == this.store.state.userId) {
            result.push("Approve")
            result.push("Reject")
          }
        }
        else if(item.step === 2) {
          if(this.project.data!.approver3_id == this.store.state.userId) {
            result.push("Approve")
            result.push("Reject")
          }
        }
        else if(item.step === 3) {
          if(this.project.data!.approver4_id == this.store.state.userId) {
            result.push("Approve")
            result.push("Reject")
          }
        }
        else if(item.step === 4) {
          if(this.project.data!.approver5_id == this.store.state.userId) {
            result.push("Approve")
            result.push("Reject")
          }
        }
      else {
          if(this.project.data!.approver6_id == this.store.state.userId) {
            result.push("Approve")
            result.push("Reject")
          }
        }
      }
      if(item.step ==6 && item.step <8) {
        if(this.store.getters.isAdmin === true) {
          result.push("Approve"); result.push("Reject")
        }
      } 
      // if(this.project.data!.approver1_id == this.store.state.userId || this.project.data!.approver2_id == this.store.state.userId || 
      // this.project.data!.approver3_id == this.store.state.userId || this.project.data!.approver4_id == this.store.state.userId ||
      // this.project.data!.approver5_id == this.store.state.userId || this.project.data!.approver6_id == this.store.state.userId) {
      //   {result.push("Approve"); result.push("Reject")}
      // }
    
    if(this.store.getters.hasRight("budget_form_editsector") && this.store.state.sector2===item.sector_id) {
      result.push("Edit")
    }
    return result;
  }
  budgetYear = new Date().getFullYear() + 543;
  get attachments() {
    return this.dataSingle.data!.attachments.filter(
      (a) => a.category === this.type || a.category === undefined
    );
  }
  mounted() {
    this.yearSetting.getBudgetYearSetting();
    this.sectorList.getSectorList({ search: { category: "ฝ่าย/สำนัก" } });
    this.fetchData();
  }
  async fetchData() {
    this.project.getProjectSingle(this.$route.params.id as string);
    let query: any = {
      search: {
        project_id: this.$route.params.id,
      },
    };
    this.adjustmentList.getAdjustmentList(query);
  }
  openModalItem(adjustment: AdjustmentModel, type: string) {
    this.dataSingle.data = adjustment;
    this.type = type;
    (this.$refs.openmodal as HTMLElement).click();
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteItem(id: any, index: number, item: AdjustmentAttachmentModel) {
    if (id != undefined) {
      let attachment: AttachmentSingleClass = Container.get(
        AttachmentSingleClass
      );
      attachment!.data = item;
      await attachment.deleteAttachmentSingle();
      this.$nextTick(() => {
        this.fetchData();
      });
    } else {
      try {
        this.attachments.splice(index, 1);
        this.$nextTick(() => {
          this.fetchData();
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
  async submitForm() {
    this.dataSingle.data!.attachments.forEach((el) => {
      if (el.category === undefined) el.category = this.type;
    });
    await this.dataSingle.updateAdjustmentSingle();
    this.$nextTick(() => {
      this.fetchData();
    });
  }
}
