import { DateTime } from "luxon";
import IndicatorProfileModel from "../indicator";
import ProjectModel from "../project";
import { SettingMemberModel } from "../system";
import UserModel from "../user";
import WithdrawModel from "../withdraw";

export default class BudgetModel {
  id?: string;
  code: string;
  number: string;
  name: string;
  operationType: string;
  budgetType: string;
  budgetTypeName: string;
  budgetTypeCategory: string;
  budgetTypeCode: string;

  qty?: number
  expenseType: string;
  description: string;
  goals: string;
  priority: number;
  revision: number;
  project_id: string;
  parent_id: string;
  responsible_id: string;
  responsible: UserModel;
  withdraws: Array<WithdrawModel>;
  children?: Array<BudgetModel>;
  items: Array<BudgetItemModel>;
  withdrawn?: Array<BudgetItemModel>;
  withdrawnMapped: Array<number>
  diffMapped: Array<number>
  indicators: Array<BudgetIndicatorModel>;
  settings?: Array<SettingMemberModel>
  isSpecial?: Boolean
  isOverlap?: Boolean
  overlapType?: string
  project?: ProjectModel

  overlapBudgetAmount?: number
  overlapRemainingAmount?: number
  overlapContractNumber?: string
  overlapStartDate?: Date
  overlapEndDate?: Date
  overlapDescription?: string
  overlapReason?: string

  get hasChild() {
    try {

      return this.children?.length! > 0
    } catch (error) {
      return false
    }
  }
  get indicatorCode() {
    try {
      let arr = this.indicators;
      arr = arr.map((a: any) => a.indicator.code);
      return arr.join(", ");
    } catch (error) {
      return "-";
    }
  }
  get indicatorFull() {
    try {
      let arr: Array<any> = this.indicators ?? [];
      arr = arr.map((a: any) => ({
        code: a.indicator.code,
        name: a.indicator.name,
      }));
      arr = arr.map((item: any) => (item.code + ":" + item.name))
      return arr;
    } catch (error) {
      return "-";
    }
  }
  plannedAmountOfMonth(i: number): number {
    try {
      return this.items.find(item => item.month === i)?.amount!
    } catch (error) {
      return 0
    }
  }

  plannedAmountOfMonthFormat(i: number) {
    return this.plannedAmountOfMonth(i).formatFull()
  }

  withdrawnAmountOfMonth(i: number): number {
    try {
      return this.withdraws.filter(item => item.status !== "deleted").filter(item => item.month === i).map(item => item.amountERP ?? item.tempAmount ?? 0).reduce((a, b) => a + b, 0)

    } catch (error) {
      return 0
    }
  }

  withdrawnAmountMonth(i: number) {
    if (this.hasChild) {
      let sum = 0
      let children1: BudgetModel[] = this.children ?? []
      children1.forEach((child1) => {
        let children2: BudgetModel[] = child1.children ?? []
        if (children2.length > 0) {
          children2.forEach((child2) => {
            sum += child2.withdrawnAmountOfMonth(i)
          });
        }
        else {
          sum += child1.withdrawnAmountOfMonth(i)
        }
      });
      // let reduce = (this.children??[]).map(child=>child.withdrawnAmountOfMonth(i)).reduce((a,b)=>a+b,0).formatFull()
      return sum
    } else {
      return this.withdrawnAmountOfMonth(i)
    }
  }
  withdrawnAmountOfMonthFormat(i: number) {
    return this.withdrawnAmountMonth(i).formatFull()
  }
  diffOfMonth(i: number): number {
    try {
      let withdrawn = this.withdrawnAmountMonth(i);
      let budget = this.items.find(item => item.month === i)!.amount
      return budget - withdrawn
    } catch (error) {
      return 0
    }
  }
  diffOfMonthFormat(i: number) {
    return this.diffOfMonth(i).formatFull()
  }
  diffOfAccum(i: number): number {
    if ([0, 1, 2].includes(i)) {
      let b1 = this.items.find(item => item.month === 0)!.amount
      let w1 = this.withdrawnAmountMonth(0)
      let b2 = this.items.find(item => item.month === 1)!.amount
      let w2 = this.withdrawnAmountMonth(1)
      let b3 = this.items.find(item => item.month === 2)!.amount
      let w3 = this.withdrawnAmountMonth(2)
      if (i === 0) {
        if (b1 - w1 - w2 - w3 < 0) return 0
        return b1 - w1 - w2 - w3
      } else if (i === 1) {
        if (b1 + b2 - w1 - w2 - w3 < 0) return 0
        return b1 + b2 - w1 - w2 - w3
      } else if (i === 2) {
        if (b1 + b2 + b3 - w1 - w2 - w3 < 0) return 0
        return b1 + b2 + b3 - w1 - w2 - w3
      }
    }
    else if ([3, 4, 5].includes(i)) {
      let b1 = this.items.find(item => item.month === 3)!.amount
      let w1 = this.withdrawnAmountMonth(3)
      let b2 = this.items.find(item => item.month === 4)!.amount
      let w2 = this.withdrawnAmountMonth(4)
      let b3 = this.items.find(item => item.month === 5)!.amount
      let w3 = this.withdrawnAmountMonth(5)
      if (i === 3) {
        if (b1 - w1 - w2 - w3 < 0) return 0
        return b1 - w1 - w2 - w3
      } else if (i === 4) {
        if (b1 + b2 - w1 - w2 - w3 < 0) return 0
        return b1 + b2 - w1 - w2 - w3
      } else if (i === 5) {
        if (b1 + b2 + b3 - w1 - w2 - w3 < 0) return 0
        return b1 + b2 + b3 - w1 - w2 - w3
      }
    }
    else if ([6, 7, 8].includes(i)) {
      let b1 = this.items.find(item => item.month === 6)!.amount
      let w1 = this.withdrawnAmountMonth(6)
      let b2 = this.items.find(item => item.month === 7)!.amount
      let w2 = this.withdrawnAmountMonth(7)
      let b3 = this.items.find(item => item.month === 8)!.amount
      let w3 = this.withdrawnAmountMonth(8)
      if (i === 6) {
        if (b1 - w1 - w2 - w3 < 0) return 0
        return b1 - w1 - w2 - w3
      } else if (i === 7) {
        if (b1 + b2 - w1 - w2 - w3 < 0) return 0
        return b1 + b2 - w1 - w2 - w3
      } else if (i === 8) {
        if (b1 + b2 + b3 - w1 - w2 - w3 < 0) return 0
        return b1 + b2 + b3 - w1 - w2 - w3
      }
    }
    else if ([9, 10, 11].includes(i)) {
      let b1 = this.items.find(item => item.month === 9)!.amount
      let w1 = this.withdrawnAmountMonth(9)
      let b2 = this.items.find(item => item.month === 10)!.amount
      let w2 = this.withdrawnAmountMonth(10)
      let b3 = this.items.find(item => item.month === 11)!.amount
      let w3 = this.withdrawnAmountMonth(11)
      if (i === 9) {
        if (b1 - w1 - w2 - w3 < 0) return 0
        return b1 - w1 - w2 - w3
      } else if (i === 10) {
        if (b1 + b2 - w1 - w2 - w3 < 0) return 0
        return b1 + b2 - w1 - w2 - w3
      } else if (i === 11) {
        if (b1 + b2 + b3 - w1 - w2 - w3 < 0) return 0
        return b1 + b2 + b3 - w1 - w2 - w3
      }
    }
    return 0
  }

  diffOfAccumFormat(i: number) {
    return this.diffOfAccum(i).formatFull()
  }

  remainingAmountOfMonth(i: number): number {
    try {
      return this.plannedAmountOfMonth(i) - this.withdrawnAmountOfMonth(i)
    } catch (error) {
      return 0
    }
  }
  remainingAmountOfMonthFormat(i: number) {
    return this.remainingAmountOfMonth(i).formatFull()
  }

  get isZero() {
    try {
      let mapped = !this.items.map(item => item.amount != 0).reduce((a, b) => a || b, false)
      return mapped
    } catch (error) {
      return false;
    }
  }
  set budgetCombined(budgetType: string) {
    let split = budgetType.split(",")
    this.budgetType = budgetType
    this.budgetTypeName = split[0].trim()
    this.budgetTypeCategory = split[1].trim()
    this.budgetTypeCode = split[2].trim()
  }
  get budgetCombined() {
    return this.budgetType
    // return `${this.budgetTypeName},${this.budgetTypeCategory},${this.budgetTypeCode}`
  }

  constructor(jsonData: any, settings?: Array<SettingMemberModel>) {


    let items: Array<BudgetItemModel> = new Array(12).fill(new BudgetItemModel({}))
    // console.log('items',jsonData.items);
    if (jsonData.items == undefined) {
      items = items.map((item, i) => new BudgetItemModel({ ...item, month: i }))
      console.log('1');

    } else if (jsonData.items.length === 0) {
      items = new Array(12).fill(new BudgetItemModel({}))
      console.log('2');
    } else {
      items = (jsonData.items ?? []).map((o: any) => new BudgetItemModel(o))
    }
    let withdrawn: Array<BudgetItemModel> = new Array(12).fill(new BudgetItemModel({}))
    if (jsonData.withdrawn == undefined) {
      withdrawn = withdrawn.map((item, i) => new BudgetItemModel({ ...withdrawn, month: i }))
    } else if (jsonData.withdrawn.length === 0) {
      withdrawn = new Array(12).fill(new BudgetItemModel({}))
    } else {
      withdrawn = (jsonData.withdrawn ?? []).map((o: any) => new BudgetItemModel(o))
    }
    let indicators: Array<BudgetIndicatorModel> = []
    if (jsonData.indicators == undefined) {
      indicators = []
    } else {
      indicators = (jsonData.indicators ?? []).map((o: any) => new BudgetIndicatorModel(o))
    }
    let withdraws: Array<WithdrawModel> = []
    if (jsonData.withdraws == undefined) {
      withdraws = []
    } else {
      withdraws = (jsonData.withdraws ?? []).map((o: any) => new WithdrawModel(o))
    }
    this.id = jsonData.id
    this.code = jsonData.code ?? "";
    this.number = jsonData.number ?? "";
    this.name = jsonData.name ?? "";
    this.operationType = jsonData.operationType ?? ""
    this.budgetType = jsonData.budgetType ?? ""
    this.budgetTypeName = jsonData.budgetTypeName ?? ""
    this.budgetTypeCategory = jsonData.budgetTypeCategory ?? ""
    this.budgetTypeCode = jsonData.budgetTypeCode ?? ""
    this.expenseType = jsonData.expenseType ?? ""
    this.description = jsonData.description ?? ""
    this.goals = jsonData.goals ?? ""
    this.qty = jsonData.qty
    this.priority = jsonData.priority ?? 0
    this.revision = jsonData.revision ?? 0
    this.project_id = jsonData.project_id ?? "";
    this.parent_id = jsonData.parent_id ?? "";
    this.responsible_id = jsonData.responsible_id ?? "";
    this.responsible = new UserModel(jsonData.responsible ?? {})
    this.items = items;
    this.withdrawn = withdrawn;
    this.withdrawnMapped = jsonData.withdrawnMapped ?? []
    this.diffMapped = jsonData.diffMapped ?? []
    this.isSpecial = jsonData.isSpecial ?? false
    this.isOverlap = jsonData.isOverlap ?? false
    this.overlapType = jsonData.overlapType
    this.indicators = indicators ?? [];
    this.withdraws = withdraws;
    this.settings = settings;

    this.overlapBudgetAmount = jsonData.overlapBudgetAmount ?? 0
    this.overlapRemainingAmount = jsonData.overlapRemainingAmount ?? 0
    this.overlapContractNumber = jsonData.overlapContractNumber ?? ""
    this.overlapStartDate = jsonData.overlapStartDate ?? DateTime.now().toJSDate()
    this.overlapEndDate = jsonData.overlapEndDate ?? DateTime.now().toJSDate()
    this.overlapDescription = jsonData.overlapDescription ?? ""
    this.overlapReason = jsonData.overlapReason ?? ""


    try {
      this.children = jsonData.children.map((child: any) => new BudgetModel(child))
    } catch (error) {

    }
    if (jsonData.project != undefined) {
      this.project = new ProjectModel(jsonData.project)
    }
  }

  get projectName() {
    try {
      return this.project!.code + ":" + this.project!.name
    } catch (error) {
      return "-"
    }
  }

  get sectorName() {
    try {
      return this.project?.sectorName
    } catch (error) {
      return "-"
    }
  }
  get monthAmount01() {
    try {
      return this.items[0].amount
    } catch (error) { }
  }
  get monthAmount02() {
    try {
      return this.items[1].amount
    } catch (error) { }
  }
  get monthAmount03() {
    try {
      return this.items[2].amount
    } catch (error) { }
  }
  get monthAmount04() {
    try {
      return this.items[3].amount
    } catch (error) { }
  }
  get monthAmount05() {
    try {
      return this.items[4].amount
    } catch (error) { }
  }
  get monthAmount06() {
    try {
      return this.items[5].amount
    } catch (error) { }
  }
  get monthAmount07() {
    try {
      return this.items[6].amount
    } catch (error) { }
  }
  get monthAmount08() {
    try {
      return this.items[7].amount
    } catch (error) { }
  }
  get monthAmount09() {
    try {
      return this.items[8].amount
    } catch (error) { }
  }
  get monthAmount10() {
    try {
      return this.items[9].amount
    } catch (error) { }
  }
  get monthAmount11() {
    try {
      return this.items[10].amount
    } catch (error) { }
  }
  get monthAmount12() {
    try {
      return this.items[11].amount
    } catch (error) { }
  }

  get totalAmount() {
    try {
      return this.items.map(item => item.amount ?? 0).reduce((a, b) => a + b, 0)
    } catch (error) {
      return -1
    }
  }
  get totalSupportAmount() {
    try {
      if (this.budgetTypeCategory === "งบอุดหนุน")
        return this.items.map(item => item.amount ?? 0).reduce((a, b) => a + b, 0)
      else
        return 0
    } catch (error) {
      return -1
    }
  }
  get totalInvestAmount() {
    try {
      if (this.budgetTypeCategory === "งบลงทุน")
        return this.items.map(item => item.amount ?? 0).reduce((a, b) => a + b, 0)
      else
        return 0
    } catch (error) {
      return -1
    }
  }
  get totalAmountFormat() {
    try {
      return this.totalAmount.formatDash()
    } catch (error) {
      return "0.00"
    }
  }
  get totalSupportAmountFormat() {
    try {
      if (this.totalSupportAmount == 0) return this.totalSupportAmount.formatDash()
      else return this.totalSupportAmount.formatFull()
    } catch (error) {
      return "0.00"
    }
  }
  get totalInvestAmountFormat() {
    try {
      if (this.totalInvestAmount == 0) return this.totalInvestAmount.formatDash()
      else return this.totalInvestAmount.formatFull()
    } catch (error) {
      return "0.00"
    }
  }

  get monthAmountFormat01() {
    try {
      if (this.items[0].amount == 0) return this.items[0].amount.formatDash()
      else return this.items[0].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat02() {
    try {
      if (this.items[1].amount == 0) return this.items[1].amount.formatDash()
      else return this.items[1].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat03() {
    try {
      if (this.items[2].amount == 0) return this.items[2].amount.formatDash()
      else return this.items[2].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat04() {
    try {
      if (this.items[3].amount == 0) return this.items[3].amount.formatDash()
      else return this.items[3].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat05() {
    try {
      if (this.items[4].amount == 0) return this.items[4].amount.formatDash()
      else return this.items[4].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat06() {
    try {
      if (this.items[5].amount == 0) return this.items[5].amount.formatDash()
      else return this.items[5].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat07() {
    try {
      if (this.items[6].amount == 0) return this.items[6].amount.formatDash()
      else return this.items[6].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat08() {
    try {
      if (this.items[7].amount == 0) return this.items[7].amount.formatDash()
      else return this.items[7].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat09() {
    try {
      if (this.items[8].amount == 0) return this.items[8].amount.formatDash()
      else return this.items[8].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat10() {
    try {
      if (this.items[9].amount == 0) return this.items[9].amount.formatDash()
      else return this.items[9].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat11() {
    try {
      if (this.items[10].amount == 0) return this.items[10].amount.formatDash()
      else return this.items[10].amount.formatFull()
    } catch (error) { }
  }
  get monthAmountFormat12() {
    try {
      if (this.items[11].amount == 0) return this.items[11].amount.formatDash()
      else return this.items[11].amount.formatFull()
    } catch (error) { }
  }
  get monthWithdrawnAmountFormat01() {
    try { return this.withdrawnMapped[0].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat02() {
    try { return this.withdrawnMapped[1].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat03() {
    try { return this.withdrawnMapped[2].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat04() {
    try { return this.withdrawnMapped[3].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat05() {
    try { return this.withdrawnMapped[4].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat06() {
    try { return this.withdrawnMapped[5].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat07() {
    try { return this.withdrawnMapped[6].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat08() {
    try { return this.withdrawnMapped[7].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat09() {
    try { return this.withdrawnMapped[8].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat10() {
    try { return this.withdrawnMapped[9].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat11() {
    try { return this.withdrawnMapped[10].formatFull() } catch (error) { return "0.00" }
  }
  get monthWithdrawnAmountFormat12() {
    try { return this.withdrawnMapped[11].formatFull() } catch (error) { return "0.00" }
  }

  get monthDiffAmountFormat01() {
    try { return this.diffMapped[0].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat02() {
    try { return this.diffMapped[1].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat03() {
    try { return this.diffMapped[2].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat04() {
    try { return this.diffMapped[3].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat05() {
    try { return this.diffMapped[4].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat06() {
    try { return this.diffMapped[5].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat07() {
    try { return this.diffMapped[6].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat08() {
    try { return this.diffMapped[7].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat09() {
    try { return this.diffMapped[8].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat10() {
    try { return this.diffMapped[9].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat11() {
    try { return this.diffMapped[10].formatFull() } catch (error) { return "0.00" }
  }
  get monthDiffAmountFormat12() {
    try { return this.diffMapped[11].formatFull() } catch (error) { return "0.00" }
  }
  get fullName() {
    return this.code + " " + this.name
  }
  toJSON() {
    let obj = Object.assign({}, this)
    obj.items = obj.items.map(o => new BudgetItemModel(o).toJSON())
    return JSON.parse(JSON.stringify(obj))
  }
}
export class BudgetItemModel {
  id?: string;
  name: string
  month: number
  isActive: boolean;
  amount: number
  constructor(jsonData: any) {
    this.id = jsonData.id
    this.name = jsonData.name ?? "";
    this.month = jsonData.month ?? 0;
    this.isActive = jsonData.isActive ?? false;
    this.amount = jsonData.amount ?? 0;
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return JSON.parse(JSON.stringify(obj))
  }
}

export class BudgetIndicatorModel {
  id?: string;
  name: string
  indicator_id: string
  indicator: IndicatorProfileModel
  constructor(jsonData: any) {
    this.id = jsonData.id;
    // console.log({obj},this.id, jsonData.id)
    this.name = jsonData.name ?? "";
    this.indicator_id = jsonData.indicator_id ?? "";
    this.indicator = jsonData.indicator ?? new IndicatorProfileModel({});
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return JSON.parse(JSON.stringify(obj))
  }
}
