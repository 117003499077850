import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row mb-2" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 0,
  class: "form-label"
}
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.label!=undefined)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("textarea", {
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitUp())),
        disabled: _ctx.disabled
      }, null, 40, _hoisted_4), [
        [_vModelText, _ctx.val]
      ])
    ])
  ]))
}