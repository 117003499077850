import SectorModel from "../sector";

export default class UserModel {
  id?: string;
  username: string;
  nickName: string;
  password: string;
  email: string;
  prefix: string;
  firstName: string;
  lastName: string;
  number: string;
  telephone: string;
  extensionNumber: string;
  line: string;
  roles: Array<string>
  role: string;
  accesses: Array<AccessesMemberModel>
  isActive:boolean;
  // accesses: string;
  sAMAccountName: string;
  sector1_id: string;
  sector2_id: string;
  sector3_id: string;
  sector4_id: string;
  sector5_id: string;

  sector1?: SectorModel;
  sector2?: SectorModel;
  sector3?: SectorModel;
  sector4?: SectorModel;
  sector5?: SectorModel;

  sector1Obj: string;
  sector2Obj: string;
  sector3Obj: string;
  sector4Obj: string;
  sector5Obj: string;
  position: string;
  level: number;
  signature: string;
  rights:Array<string>
  get fullName() {
    return (this.prefix ?? "") + (this.firstName ?? "") + " " + (this.lastName ?? "")
  }
  set fullName(str: string) {

  }

  // get rights(){
  //   return this.accesses.map(item=>item.name)
  // }
  // set rights(obj:any){
  //   // console.log({obj.name})
  //   console.log(this.accesses, obj.name)
  //   let foundIndex = this.accesses.findIndex(access=>access.name===obj)
  //   console.log(foundIndex)
  //   // if(foundIndex!=-1){
  //   //   this.accesses.splice(foundIndex)
  //   // } else {
  //   //   this.accesses.push(new AccessesMemberModel({name:right}))
  //   // }
  // }

  toggleUAC(right:string){
    let foundIndex = this.accesses.findIndex(access=>access.name===right)
    if(foundIndex!=-1){
      this.accesses.splice(foundIndex)
    } else {
      this.accesses.push(new AccessesMemberModel({name:right}))
    }
  }

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.username = jsonData.username;
    this.nickName = jsonData.nickName;
    this.password = jsonData.password;
    this.email = jsonData.email;
    this.rights = jsonData.rights??[];
    this.prefix = jsonData.prefix;
    this.firstName = jsonData.firstName;
    this.lastName = jsonData.lastName;
    this.sAMAccountName = jsonData.sAMAccountName
    this.number = jsonData.number;
    this.telephone = jsonData.telephone;
    this.extensionNumber = jsonData.extensionNumber;
    this.line = jsonData.line;
    this.role = jsonData.role;
    this.roles = jsonData.roles;
    this.isActive = jsonData.isActive
    this.accesses = (jsonData.accesses ?? []).map((item: any) => new AccessesMemberModel(item));
    this.sector1_id = jsonData.sector1_id;
    this.sector2_id = jsonData.sector2_id;
    this.sector3_id = jsonData.sector3_id;
    this.sector4_id = jsonData.sector4_id;
    this.sector5_id = jsonData.sector5_id;
    this.sector1 = jsonData.sector1;
    this.sector2 = jsonData.sector2;
    this.sector3 = jsonData.sector3;
    this.sector4 = jsonData.sector4;
    this.sector5 = jsonData.sector5;
    this.sector1Obj = jsonData.sector1Obj;
    this.sector2Obj = jsonData.sector2Obj;
    this.sector3Obj = jsonData.sector3Obj;
    this.sector4Obj = jsonData.sector4Obj;
    this.sector5Obj = jsonData.sector5Obj;
    this.position = jsonData.position;
    this.level = jsonData.level;
    this.signature = jsonData.signature;

  }
  toJSON() {
    let obj = Object.assign({}, this)
    
    return JSON.parse(JSON.stringify(obj))
  }
}
export class RoleMemberModel {
  id?: string;
  name?: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name ?? "";
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return JSON.parse(JSON.stringify(obj))
  }
}
export class AccessesMemberModel {
  id?: string;
  name?: string;

  constructor(jsonData: any) {
    this.id = jsonData.id
    this.name = jsonData.name ?? "";
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return JSON.parse(JSON.stringify(obj))
  }
}