
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import PositionModel from "@/model/position";
import { ActivePositionListClass } from "@/data/position";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class PositionFormView extends Vue {
  dataList: ActivePositionListClass = Container.get(ActivePositionListClass);
  docs: Array<PositionModel> = [];
  pages: number = 0;
  total: number = 0;
  store = useStore();

  renderTableTool(item: PositionModel) {
    let result:Array<string> = ["Detail"]
    if(this.store.getters.hasRight("setting_position_editall")) result.push("Edit")
    if(this.store.getters.hasRight("setting_position_detail")) result.push("Delete")
    if(this.store.getters.hasRight("setting_position_delete")) result.push("Detail")
    return result;
  }
  mounted() {
    this.initData();
  }
  async initData() {
    await this.dataList.getPositionList({});
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
