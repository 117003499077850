
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import AdjustmentServices from "@/service/adjustment";
import { Console } from "console";


@Options({

  components: {},
})
export default class ModalHistoryComponent extends Vue {
    history:any ={}
    resolveMonth(n:number){
    switch (n) {
      case 1:
        return "ต.ค." 
        break;
      case 2:
        return "พ.ย." 
        break;
      case 3:
        return "ธ.ค." 
        break;
      case 4:
        return "ม.ค."
        break;
      case 5:
        return "ก.พ."
        break;
      case 6:
        return "มี.ค."
        break;
      case 7:
        return "เม.ย."
        break;
      case 8:
        return "พ.ค."
        break;
      case 9:
        return "มิ.ย."
        break;
      case 10:
        return "ก.ค."
        break;
      case 11:
        return "ส.ค."
        break;
      case 12:
        return "ก.ย."
        break;
    
      default:
        return n
        break;
    }
  }
  mounted() {
    this.initData()
  }
  async initData() {
   this.history = await new AdjustmentServices().getAdjustmentHistory(this.$route.params.id as string)
   console.log(history)
    
  }
  getTotal(before:Array<any>,after:Array<any>){
    let sumBefore  = before.map(item=>item.amount??0).reduce((a,b)=>a+b,0)
    let sumAfter  = after.map(item=>item.amount??0).reduce((a,b)=>a+b,0)
    return sumAfter-sumBefore
  }
}
