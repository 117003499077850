
import { ReportA10Class } from "@/data/report";
import { ReportA01Model, ReportA02Model, ReportA03ItemModel, ReportA04Model, ReportA07Model, ReportA08Type1Model, ReportA08Type2Model, ReportB03Model, ReportB04Model, ReportB05Model } from "@/model/report";
import ExcelJS from "exceljs"
import { ActiveProjectSingleClass, ActiveProjectForPetitionClass } from "@/data/project";
import { ActivePetitionSingleClass } from "@/data/petition";
import { saveAs } from 'file-saver';
import Container from "typedi";
import * as _ from "lodash"
import { Alignment } from "docx";
import BudgetModel from "@/model/budget";
import { SettingMemberModel } from "@/model/system";


export class DocumentExcel {
  before: Array<any> = []
  after: Array<any> = []
  workbook = new ExcelJS.Workbook()
  worksheet = this.workbook.addWorksheet('Report')
  sumAmountBefore(doc: any, item: any, type: string) {
    try {
      let arr: Array<number> = doc.findBudgetFromFlatted(item, 'before').items.map((el: any) => el.amount ?? 0)
      return arr.reduce((a, b) => a + b, 0).formatComma()
    } catch (error) {
    }
  }
  sumAmountAfter(doc: any, item: any, type: string) {
    try {
      let arr: Array<number> = doc.findBudgetFromFlatted(item, 'after').items.map((el: any) => el.amount ?? 0)
      return arr.reduce((a, b) => a + b, 0).formatComma()
    } catch (error) {
    }
  }
  getMonth(i: number) {
    switch (i) {
      case 0: return `ตุลาคม`; break;
      case 1: return `พฤศจิกายน`; break;
      case 2: return `ธันวาคม`; break;
      case 3: return `มกราคม`; break; 
      case 4: return `กุมภาพันธ์`; break;
      case 5: return `มีนาคม`; break;
      case 6: return `เมษายน`; break;
      case 7: return `พฤษภาคม`; break;
      case 8: return `มิถุนายน`; break;
      case 9: return `กรกฎาคม`; break;
      case 10: return `สิงหาคม`; break;
      case 11: return `กันยายน`; break;
    }
  }
  getMonthYear(i: number, budgetYear:number) {
    switch (i) {
      case 0: return `ตุลาคม ${(budgetYear)-1}`; break;
      case 1: return `พฤศจิกายน ${(budgetYear)-1}`; break;
      case 2: return `ธันวาคม ${(budgetYear-1)}`; break;
      case 3: return `มกราคม ${budgetYear}`; break; 
      case 4: return `กุมภาพันธ์ ${budgetYear}`; break;
      case 5: return `มีนาคม ${budgetYear}`; break;
      case 6: return `เมษายน ${budgetYear}`; break;
      case 7: return `พฤษภาคม ${budgetYear}`; break;
      case 8: return `มิถุนายน ${budgetYear}`; break;
      case 9: return `กรกฎาคม ${budgetYear}`; break;
      case 10: return `สิงหาคม ${budgetYear}`; break;
      case 11: return `กันยายน ${budgetYear}`; break;
    }
  }
  async createB03(doc: ReportB03Model, budgetYear: number) {

    this.worksheet.addRow([`รายงานงบประมาณ กกท.หัวข้อ ${doc.type} ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`])
    this.worksheet.mergeCells('A1:F1')
    this.worksheet.getCell("A2").value = "ฝ่าย/สำนัก"
    this.worksheet.getCell("B2").value = "ลำดับ"
    this.worksheet.getCell("C2").value = "รายการที่"
    this.worksheet.getCell("D2").value = "การดำเนินงานหลัก"
    this.worksheet.getCell("E2").value = "งบประมาณ"
    this.worksheet.getCell("F2").value = "หมายเหตุ"
    this.worksheet.getColumn("A").width = 20
    this.worksheet.getColumn("C").width = 40
    this.worksheet.getColumn("D").width = 40
    this.worksheet.getColumn("E").width = 60
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("B2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("C2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("D2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("E2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("F2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    doc.items.forEach((item, i) => {
      this.worksheet.addRow([
        item.budgetSeq == 1 ? item.sectorName : "",
        item.budgetSeq == 1 ? item.seq : "",
        item.budgetSeq == 1 ? (item.projectCode + " " + item.projectName + `(${item.projectAmount})`) : "",
        item.budgetNumber + " " + item.budgetName,
        item.budget,
        item.remark
      ])
    })
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 2) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (cn >= 5 && cn <= 5) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right'
            }
            cell.numFmt = '#,##0;[Red]-#,##0;-';
          }
          if (cn === 3) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
              wrapText: true
            }
          }
        })
      }
    })
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB03.xlsx");
    });
  }
  async createB04(doc: ReportB04Model, budgetYear: number) {
    this.worksheet.addRow([`รายงานจำนวนผู้ช่วยปฏิบัติงาน กกท. ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`])
    this.worksheet.mergeCells('A1:K1')
    this.worksheet.getCell("A2").value = "ลำดับ"
    this.worksheet.getCell("B2").value = "ฝ่าย/สำนัก"
    this.worksheet.getCell("C2").value = "รายการที่"
    this.worksheet.getCell("D2").value = "ปริญญาโท"
    this.worksheet.getCell("E2").value = "ปริญญาตรี"
    this.worksheet.getCell("F2").value = "ปริญญาตรี (เชี่ยวชาญ)"
    this.worksheet.getCell("G2").value = "ปวส."
    this.worksheet.getCell("H2").value = "ปวช."
    this.worksheet.getCell("I2").value = "ม.6"
    this.worksheet.getCell("J2").value = "ม.3"
    this.worksheet.getCell("K2").value = "หมายเหตุ"
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getColumn("B").width = 70
    this.worksheet.getColumn("C").width = 70
    // this.worksheet.getCell("A1").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("A2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("B2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("C2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("D2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("E2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("F2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("G2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("H2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("I2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("J2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }
    // this.worksheet.getCell("K2").style = { font:{bold:true},fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDE5F0' } } }

    console.log(doc)
    doc.items.forEach((item, i) => {
      this.worksheet.addRow(
        [
          i + 1,
          item.sectorName ?? "",
          item.projectCode + " " + item.projectName,
          item.master ?? 0,
          item.bachelor ?? 0,
          item.professional ?? 0,
          item.highVocational ?? 0,
          item.vocational ?? 0,
          item.grade12 ?? 0,
          item.grade9 ?? 0,
          item.remark ?? "",

        ]);
    })
    this.worksheet.addRow(['', '', 'รวมจำนวนผู้ช่วยปฏิบัติงาน', doc.sumMaster
      , doc.sumBachelor
      , doc.sumProfessional
      , doc.sumHighVocational
      , doc.sumVocational
      , doc.sumGrade12
      , doc.sumGrade9
      , ""])
    this.worksheet.eachRow((row, rn) => {
      row.eachCell((cell, cn) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      })
    })
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB04.xlsx");
    });
  }
  async createB05(doc: ReportB05Model, budgetYear: number) {
    this.worksheet.getCell("A1").value = `สรุปแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`
    this.worksheet.getCell("A2").value = "ผลผลิต/กิจกรรม/กิจกรรมย่อย/รายการ"
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:R1')
    this.worksheet.mergeCells('A2:A3')
    this.worksheet.getCell("B2").value = "ไตรมาส 1"
    this.worksheet.getCell("F2").value = "ไตรมาส 2"
    this.worksheet.getCell("J2").value = "ไตรมาส 3"
    this.worksheet.getCell("N2").value = "ไตรมาส 4"
    this.worksheet.mergeCells('B2:E2')
    this.worksheet.mergeCells('F2:I2')
    this.worksheet.mergeCells('J2:M2')
    this.worksheet.mergeCells('N2:Q2')
    this.worksheet.getCell("B3").value = "ต.ค."
    this.worksheet.getCell("C3").value = "พ.ย."
    this.worksheet.getCell("D3").value = "ธ.ค."
    this.worksheet.getCell("E3").value = "รวม"
    this.worksheet.getCell("F3").value = "ม.ค."
    this.worksheet.getCell("G3").value = "ก.พ."
    this.worksheet.getCell("H3").value = "มี.ค."
    this.worksheet.getCell("I3").value = "รวม"
    this.worksheet.getCell("J3").value = "เม.ย."
    this.worksheet.getCell("K3").value = "พ.ค."
    this.worksheet.getCell("L3").value = "มิ.ย."
    this.worksheet.getCell("M3").value = "รวม"
    this.worksheet.getCell("N3").value = "ก.ค."
    this.worksheet.getCell("O3").value = "ส.ค."
    this.worksheet.getCell("P3").value = "ก.ย."
    this.worksheet.getCell("Q3").value = "รวม"
    this.worksheet.getCell("R2").value = "รวมทั้งสิ้น"
    this.worksheet.mergeCells('R2:R3')
    this.worksheet.getColumn("A").width = 80
    this.worksheet.getColumn("B").width = 12
    this.worksheet.getColumn("C").width = 12
    this.worksheet.getColumn("D").width = 12
    this.worksheet.getColumn("E").width = 12
    this.worksheet.getColumn("F").width = 12
    this.worksheet.getColumn("G").width = 12
    this.worksheet.getColumn("H").width = 12
    this.worksheet.getColumn("I").width = 12
    this.worksheet.getColumn("J").width = 12
    this.worksheet.getColumn("K").width = 12
    this.worksheet.getColumn("L").width = 12
    this.worksheet.getColumn("M").width = 12
    this.worksheet.getColumn("N").width = 12
    this.worksheet.getColumn("O").width = 12
    this.worksheet.getColumn("P").width = 12
    this.worksheet.getColumn("Q").width = 12
    this.worksheet.getColumn("R").width = 12
    // console.log(docs)
    // this.worksheet.get("A1:B16").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }

    doc.items.forEach((doc: any, i: number) => {
      this.worksheet.addRow([new Array(doc.level).fill("    ").join("") + doc.name, ...doc.months]);
      // this.worksheet.addRow([doc.name, ...doc.budget, doc.sumBudget]);
      // // 99ccff
      // this.worksheet.getRow(4 + i).eachCell((cell, cn) => {
      //   let color = "FFFFFFFF"
      //   if (doc.level == 1) color = 'FF3399FF'
      //   else if (doc.level == 2) color = 'FFCCE6FF'
      //   else if (doc.level == 3) color = 'FFFFFFFF'
      //   cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: color } } }
      // })
    })

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 2 && rn <= 3) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
        })
      }
      if (rn > 2) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (cn >= 2 && cn <= 18) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right'
            }
            cell.numFmt = '#,##0;[Red]-#,##0;-';
          }
        })
      }
    })

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB05.xlsx");
    });
  }
  async createB14(docs: any, budgetYear: number) {
    this.worksheet.getCell("A1").value = `สรุปแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`
    this.worksheet.getCell("A2").value = "ผลผลิต/กิจกรรม/กิจกรรมย่อย/รายการ"
    this.worksheet.getCell("B2").value = "สถานะ"
    this.worksheet.getCell("C2").value = "ผลการเบิก/จ่าย"
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:R1')
    this.worksheet.mergeCells('A2:A3')
    this.worksheet.mergeCells('B2:B3')
    this.worksheet.mergeCells('C2:C3')
    this.worksheet.getCell("D2").value = "ไตรมาส 1"
    this.worksheet.getCell("H2").value = "ไตรมาส 2"
    this.worksheet.getCell("L2").value = "ไตรมาส 3"
    this.worksheet.getCell("P2").value = "ไตรมาส 4"
    this.worksheet.mergeCells('D2:G2')
    this.worksheet.mergeCells('H2:K2')
    this.worksheet.mergeCells('L2:O2')
    this.worksheet.mergeCells('P2:R2')
    this.worksheet.getCell("D3").value = "ต.ค."
    this.worksheet.getCell("E3").value = "พ.ย."
    this.worksheet.getCell("F3").value = "ธ.ค."
    this.worksheet.getCell("G3").value = "รวม"
    this.worksheet.getCell("H3").value = "ม.ค."
    this.worksheet.getCell("I3").value = "ก.พ."
    this.worksheet.getCell("J3").value = "มี.ค."
    this.worksheet.getCell("K3").value = "รวม"
    this.worksheet.getCell("L3").value = "เม.ย."
    this.worksheet.getCell("M3").value = "พ.ค."
    this.worksheet.getCell("N3").value = "มิ.ย."
    this.worksheet.getCell("O3").value = "รวม"
    this.worksheet.getCell("P3").value = "ก.ค."
    this.worksheet.getCell("Q3").value = "ส.ค."
    this.worksheet.getCell("R3").value = "ก.ย."
    this.worksheet.getCell("S3").value = "รวม"
    this.worksheet.getCell("T3").value = "รวมทั้งสิ้น"
    this.worksheet.getColumn("A").width = 80
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("C").width = 20
    this.worksheet.getColumn("D").width = 12
    this.worksheet.getColumn("E").width = 12
    this.worksheet.getColumn("F").width = 12
    this.worksheet.getColumn("G").width = 12
    this.worksheet.getColumn("H").width = 12
    this.worksheet.getColumn("I").width = 12
    this.worksheet.getColumn("J").width = 12
    this.worksheet.getColumn("K").width = 12
    this.worksheet.getColumn("L").width = 12
    this.worksheet.getColumn("M").width = 12
    this.worksheet.getColumn("N").width = 12
    this.worksheet.getColumn("O").width = 12
    this.worksheet.getColumn("P").width = 12
    this.worksheet.getColumn("Q").width = 12
    this.worksheet.getColumn("R").width = 12
    this.worksheet.getColumn("S").width = 12
    this.worksheet.getColumn("T").width = 12
    // console.log(docs)

    docs.forEach((doc: any, i: number) => {
      this.worksheet.addRow([doc.name, (doc.status ?? "?"), doc.progress, ...doc.budget, doc.sumBudget]);
      // 99ccff
      this.worksheet.getRow(4 + i).eachCell((cell, cn) => {
        let color = "FFFFFFFF"
        if (doc.level == 1) color = 'FF3399FF'
        else if (doc.level == 2) color = 'FFCCE6FF'
        else if (doc.level == 3) color = 'FFFFFFFF'
        cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: color } } }
      })
    })

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB14.xlsx");
    });
  }
  async createB15(docs: any, budgetYear: number) {
    this.worksheet.getCell("A1").value = `สรุปแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`
    this.worksheet.getCell("A2").value = "ผลผลิต/กิจกรรม/กิจกรรมย่อย/รายการ"
    this.worksheet.getCell("B2").value = "สถานะ"
    this.worksheet.getCell("C2").value = "ผลการเบิก/จ่าย"
    this.worksheet.getCell("D2").value = "ไตรมาส 1"
    this.worksheet.getCell("E2").value = "ไตรมาส 2"
    this.worksheet.getCell("F2").value = "ไตรมาส 3"
    this.worksheet.getCell("G2").value = "ไตรมาส 4"
    this.worksheet.getCell("H2").value = "รวมทั้งสิ้น"
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:H1')
    this.worksheet.mergeCells('A2:A3')
    this.worksheet.mergeCells('B2:B3')
    this.worksheet.mergeCells('C2:C3')
    this.worksheet.mergeCells('D2:D3')
    this.worksheet.mergeCells('E2:E3')
    this.worksheet.mergeCells('F2:F3')
    this.worksheet.mergeCells('G2:G3')
    this.worksheet.mergeCells('H2:H3')
    this.worksheet.getColumn("A").width = 80
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("C").width = 20
    this.worksheet.getColumn("D").width = 12
    this.worksheet.getColumn("E").width = 12
    this.worksheet.getColumn("F").width = 12
    this.worksheet.getColumn("G").width = 12
    this.worksheet.getColumn("H").width = 12
    // console.log(docs)

    docs.forEach((doc: any, i: number) => {
      this.worksheet.addRow([doc.name, (doc.status ?? "?"), doc.progress, ...doc.quarter, doc.sumWithdrawn]);
      // 99ccff
      this.worksheet.getRow(4 + i).eachCell((cell, cn) => {
        let color = "FFFFFFFF"
        if (doc.level == 1) color = 'FF3399FF'
        else if (doc.level == 2) color = 'FFCCE6FF'
        else if (doc.level == 3) color = 'FFFFFFFF'
        cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: color } } }
      })
    })

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB15.xlsx");
    });
  }
  async createB16(docs: Array<any>, budgetYear: number) {

    this.worksheet.addRow([`รายงานงบประมาณ กกท.หัวข้อ "งบอุดหนุน" ประจำปีงบประมาณ พ.ศ. ${budgetYear ?? new Date().getFullYear() + 543}`])
    this.worksheet.mergeCells('A1:F1')
    this.worksheet.getCell("A2").value = "ฝ่าย/สำนัก"
    this.worksheet.getCell("B2").value = "ลำดับ"
    this.worksheet.getCell("C2").value = "รายการที่"
    this.worksheet.getCell("D2").value = "การดำเนินงานหลัก"
    this.worksheet.getCell("E2").value = "งบประมาณ"
    this.worksheet.getCell("F2").value = "หมายเหตุ"
    this.worksheet.getColumn("A").width = 20
    this.worksheet.getColumn("C").width = 40
    this.worksheet.getColumn("D").width = 40
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("B2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("C2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("D2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("E2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    this.worksheet.getCell("F2").style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF3399FF' } } }
    docs.forEach((doc, i) => {
      let l1ProjectName = ""
      try { l1ProjectName = (doc.pj[0].projectCode ?? "") + (doc.pj[0].name ?? "") } catch (error) { }
      doc.project.forEach((pj: any, j: number) => {
        pj.items.forEach((item: any, k: number) => {
          let sectorName = doc.sector
          let projectName = (pj.projectCode ?? "") + (pj.name ?? "")
          this.worksheet.addRow([j == 0 ? sectorName : "", k == 0 ? j + 1 : "", k == 0 ? projectName : "", k + 1 + ". " + item.subject, item.sumMonths, ""])
        });
      });
    })
    console.log(docs)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB16.xlsx");
    });
  }
  async createB17(docs: Array<any>, budgetYear: number) {
    this.worksheet.addRow(['รายการที่', 'ฝ่าย/สำนัก', 'สถานะ', 'ผลการดำเนินงาน', 'งบประมาณ', `ผลการเบิกจ่ายรายไตรมาส ${budgetYear}`, '', '', , '', '', '', , '', '', '', '', '', '', '', '', 'การเบิกจ่าย', 'เงินเหลือจ่าย', '	เงินคงเหลือ']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.addRow(['', '', '', '', '', 'ไตรมาส 1', '', '', '', 'ไตรมาส 2', '', '', '', 'ไตรมาส 3', '', '', '', 'ไตรมาส 4']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.addRow(['', '', '', '', '', 'ต.ค.', 'พ.ย.', 'ธ.ค.', 'รวม', 'ม.ค.', 'ก.พ.	', 'มี.ค.', 'รวม', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'รวม	', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'รวม']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.mergeCells('F1:U1')
    this.worksheet.mergeCells('F2:I2')
    this.worksheet.mergeCells('J2:M2')
    this.worksheet.mergeCells('N2:Q2')
    this.worksheet.mergeCells('R2:U2')
    this.worksheet.mergeCells('A1:A3')
    this.worksheet.mergeCells('B1:B3')
    this.worksheet.mergeCells('C1:C3')
    this.worksheet.mergeCells('D1:D3')
    this.worksheet.mergeCells('E1:E3')
    this.worksheet.mergeCells('V1:V3')
    this.worksheet.mergeCells('W1:W3')
    this.worksheet.mergeCells('X1:X3')
    this.worksheet.getColumn("A").width = 100
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("D").width = 20
    this.worksheet.getColumn("V").width = 10
    this.worksheet.getColumn("W").width = 10
    this.worksheet.getColumn("X").width = 10

    console.log(docs)
    docs.forEach((doc, i) => {
      this.worksheet.addRow([doc.projectCode + doc.projectsName, doc.sector, doc.status, doc.percentage, doc.budget, ...doc.months, doc.withdrawn, doc.surplus, doc.remaining])
    })
    this.worksheet.addRow(['', '', '', 'รวมงบประมาณ',
      docs.map(doc => doc.budget).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[0]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[1]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[2]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[3]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[4]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[5]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[6]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[7]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[8]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[9]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[10]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[11]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[12]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[13]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[14]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[15]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.withdrawn).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.surplus).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.remaining).reduce((a, b) => a + b, 0),
    ])

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB17.xlsx");
    });
  }
  async createB19(docs: Array<any>) {
    this.worksheet.getColumn("A").width = 100
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 10


    docs.forEach((doc, i) => {
      this.worksheet.addRow([doc.name]);
      this.worksheet.addRow([doc.intro]);
      this.worksheet.addRow(['ชื่อรายการ', 'ลำดับ', 'การดำเนินงาน', 'งบประมาณ']).alignment = { vertical: 'middle', horizontal: 'center' };
      doc.projects.forEach((project: any) => {
        this.worksheet.addRow(["รายการที่ " + (project.code ?? "") + (project.name ?? ""), 1, project.items[0].subject, project.items[0].budget]);
        project.items.filter((item: any, ii: number) => ii != 0).forEach((item: any, ii: number) => {
          this.worksheet.addRow(["", ii + 2, item.subject, item.budget])
        })
      })
      this.worksheet.addRow([""])
      this.worksheet.addRow([""])
    })

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB19.xlsx");
    });
  }
  async createB24(docs: Array<any>, budgetYear: number) {
    this.worksheet.addRow(['รายการที่', 'ฝ่าย/สำนัก', 'สถานะ', 'ผลการดำเนินงาน', 'งบประมาณ', `ผลการเบิกจ่ายรายไตรมาส ${budgetYear}`, '', '', , '', '', '', , '', '', '', '', '', '', '', '', 'การเบิกจ่าย', 'เงินเหลือจ่าย', '	เงินคงเหลือ']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.addRow(['', '', '', '', '', 'ไตรมาส 1', '', '', '', 'ไตรมาส 2', '', '', '', 'ไตรมาส 3', '', '', '', 'ไตรมาส 4']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.addRow(['', '', '', '', '', 'ต.ค.', 'พ.ย.', 'ธ.ค.', 'รวม', 'ม.ค.', 'ก.พ.	', 'มี.ค.', 'รวม', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'รวม	', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'รวม']).alignment = { vertical: 'middle', horizontal: 'center' };
    this.worksheet.mergeCells('F1:U1')
    this.worksheet.mergeCells('F2:I2')
    this.worksheet.mergeCells('J2:M2')
    this.worksheet.mergeCells('N2:Q2')
    this.worksheet.mergeCells('R2:U2')
    this.worksheet.mergeCells('A1:A3')
    this.worksheet.mergeCells('B1:B3')
    this.worksheet.mergeCells('C1:C3')
    this.worksheet.mergeCells('D1:D3')
    this.worksheet.mergeCells('E1:E3')
    this.worksheet.mergeCells('V1:V3')
    this.worksheet.mergeCells('W1:W3')
    this.worksheet.mergeCells('X1:X3')
    this.worksheet.getColumn("A").width = 100
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("D").width = 20
    this.worksheet.getColumn("V").width = 10
    this.worksheet.getColumn("W").width = 10
    this.worksheet.getColumn("X").width = 10

    console.log(docs)
    docs.forEach((doc, i) => {
      this.worksheet.addRow([doc.projectCode + doc.projectsName, doc.sector, doc.status, doc.percentage, doc.budget, ...doc.months, doc.withdrawn, doc.surplus, doc.remaining])
    })
    this.worksheet.addRow(['', '', '', 'รวมงบประมาณ',
      docs.map(doc => doc.budget).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[0]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[1]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[2]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[3]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[4]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[5]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[6]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[7]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[8]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[9]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[10]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[11]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[12]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[13]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[14]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.months[15]).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.withdrawn).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.surplus).reduce((a, b) => a + b, 0),
      docs.map(doc => doc.remaining).reduce((a, b) => a + b, 0),
    ])

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB24.xlsx");
    });
  }
  async createB27(docs: Array<any>) {
    this.worksheet.getCell("A1").value = "#"
    this.worksheet.getCell("B1").value = "วันที่"
    this.worksheet.getCell("C1").value = "ชื่อ-สกุล"
    this.worksheet.getCell("F1").value = "ตำแหน่ง"
    this.worksheet.getCell("G1").value = "เข้างาน"
    this.worksheet.getCell("H1").value = "เลิกงาน"
    this.worksheet.getColumn("B").width = 10
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 30
    this.worksheet.getColumn("H").width = 30
    this.worksheet.mergeCells('C1:E1')
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H1").alignment = { vertical: 'middle', horizontal: 'center' }

    docs.forEach((doc, i) => {
      this.worksheet.addRow([i + 1, new Date, doc.prefix, doc.firstName, doc.lastName, doc.position, doc.inTime, doc.outTime]);
    })
    // worksheet.getCell("A1").style = {fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFFFF00'}}}
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportB27.xlsx");
    });
  }
  //reportA
  // "รายงานแบบที่ 9",
  async createA09(docs: any) {
    console.log(docs);
    console.log("Adjust");
    let ListSectors: Array<any> = [];
    let ListResponsibles: Array<any> = [];
    let responsibles = []
    try {
      responsibles = docs.data.items.find((el: any) => el.code == "responsibles").value
    } catch (error) {

    }
    try {
      responsibles?.forEach((el: any) => {
        ListResponsibles.push([
          `ผู้รับผิดชอบโครงการ: ${el.fullName}  เบอร์โทร: ${el.telephoone ?? "-"}`,
        ])
      })
    } catch (error) {
      
    }

    let sectors = []
    try {
      sectors = docs.data.items.find((el: any) => el.code == "sectors").value
    } catch (error) {

    }
    const sortByNumberString = (budgets: Array<BudgetModel>) => {
      budgets = _.sortBy(budgets, el => {
        try {
          return parseFloat(el.number)
        } catch (error) {
          return 0
        }
      })
      return budgets
    }
  try { 
  sectors?.forEach((el: any, i: number) => {
    let position = ""
    switch (i) {
      case 0:
        position = "หัวหน้างาน"
        break;

      case 1:
        position = "ผู้อำนวยการกอง"
        break;

      case 2:
        position = "ผู้อำนวยการฝ่าย"
        break;
    }
    if (el.fullName != undefined) {
      ListSectors.push(
        [
          `${el.position ?? "-"} : ${el.fullName} email: ${el.email ?? "-"} เบอร์โทร: ${el.telephoone ?? "-"}`,
        ],
      )
    }
  })
  
      } catch (error) {
        
      }
        

    let totalBudget = docs.data?.budgets.map((budget: any) => budget.items.map((item: any) => item.amount).reduce((a: any, b: any) => a + b, 0)).reduce((a: any, b: any) => a + b, 0)

    const resolveIndicator = (id: string) => {
      // try {
      //   let arr = (docs.data ?? { budgets: [] }).indicators.find((ind: any) => ind.id == id)
      //   // if (arr === undefined) return { code: "", name: "" }
      //   return arr.code
      // } catch (error) {
      //   return "-";
      // }
      try {
        let found = (docs.data ?? { budgets: [] }).indicators.find((ind: any) => ind.id == id)
        if (found === undefined) return { code: "", name: "" }
        return found.code
      } catch (error) {
        return ""
      }
    }
    const totalAmount = () => {
      try {
        let map = docs.data?.budgets
          .map((budget: any) =>
            budget.items.map((item: any) => item.amount).reduce((a: number, b: number) => a + b, 0)
          )
          .reduce((a: number, b: number) => a + b, 0);
        return map
      } catch (error) {
        return 0
      }
    }
    const IndicatorCode = () => {
      try {
        let arr = (docs.data?.budgets ?? {}).indicators;
        arr = arr.map((a: any) => a.indicator.code);
        return arr;
      } catch (error) {
        return "-";
      }
    }
    console.log(docs.kpi12)
    console.log(docs.kpi6)
    const kpi6 = () => {
      try {
        let found = docs.kpi6 ?? [].map((a: any, i: number) => {

          return i + 1 + "." + a
        })
        console.log('found', found)
        return found
      } catch (error) {

      }
    }
    const kpi12 = () => {
      try {
        let found = docs.kpi12 ?? [].map((a: any, i: number) => {
          return i + 1 + "." + a
        })
        console.log('found', found)
        return found
      } catch (error) {

      }
    }
    const sortKPI = (arr: string) => {
      try {
        const newArr: string[] = arr.split(", ");
        console.log(newArr)
        return _.sortBy(newArr)

      } catch (error) {
        "-"
      }

    }
    let isMonthActive = (months: Array<any>, month: number): boolean => {
      try {

        let found = months.find((item: any) => item.month === month);
        return found.isActive
      } catch (error) {
        return false
      }
    }

    // //header
    this.worksheet.addRow([`แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${docs.data?.budgetYear ?? new Date().getFullYear() + 543}`])
    this.worksheet.mergeCells('A1:T1')
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A1").font = { size: 14 };
    this.worksheet.getCell("A2").value = `รายการที่ : ${docs.data?.fullName}`
    this.worksheet.getCell("A2").font = { size: 10 };
    this.worksheet.mergeCells('A2:K2')
    this.worksheet.getCell("L2").value = `${docs.data.budgetSource ?? ''} : ${(totalBudget || 0).formatFull()} บาท`
    this.worksheet.getCell("L2").font = { size: 10 };
    this.worksheet.mergeCells('L2:T2')
    this.worksheet.getCell("A3").value = `KPI 6 เดือน`
    this.worksheet.getCell("A3").font = { size: 10 };
    this.worksheet.mergeCells('A3:B3')
    this.worksheet.getCell("A3").alignment = { wrapText: true }
    this.worksheet.getCell("C3").font = { size: 10 };
    this.worksheet.getCell("C3").value = kpi6().join(",")
    this.worksheet.mergeCells('C3:K3')
    this.worksheet.getCell("C3").alignment = { wrapText: true }
    this.worksheet.mergeCells('L3:T3')
    this.worksheet.getCell("A4").value = `KPI 12 เดือน`
    this.worksheet.getCell("A4").font = { size: 10 };
    this.worksheet.getCell("A4").alignment = { wrapText: true }
    this.worksheet.mergeCells('A4:B4')
    this.worksheet.getCell("C4").font = { size: 10 };
    this.worksheet.getCell("C4").value = kpi12().join(",")
    this.worksheet.mergeCells('C4:K4')
    this.worksheet.getCell("C4").alignment = {
      wrapText: true
    }
    this.worksheet.mergeCells('L4:T4')
    this.worksheet.getCell("A5").value = `ตัวชี้วัดที่เกี่ยวข้อง: ${sortKPI(docs.data!.indicatorsText)}`
    this.worksheet.getCell("A5").font = { size: 10 };
    this.worksheet.mergeCells('A5:K5')
    // this.worksheet.getCell("C5").value = `${sortKPI(docs.data!.indicatorsText)}`
    // this.worksheet.getCell("C5").alignment = {
    //   wrapText: true
    // }
    // this.worksheet.mergeCells('C5:K5')
    // this.worksheet.mergeCells('L5:T5')
    // //body
    this.worksheet.getCell("A6").value = `ลำดับ\nที่`
    this.worksheet.getCell("A6").font = { size: 10 };
    this.worksheet.getCell("A6").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.mergeCells('A6:A7')
    this.worksheet.getCell("A6").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
    this.worksheet.getCell("B6").value = "การดำเนินงานหลัก"
    this.worksheet.getCell("B6").font = { size: 10 };
    this.worksheet.getCell("B6").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.mergeCells('B6:C7')
    this.worksheet.getCell("B6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D6").value = "ผลผลิตและค่าเป้าหมาย"
    this.worksheet.getCell("D6").font = { size: 10 };
    this.worksheet.getCell("D6").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.mergeCells('D6:D7')
    this.worksheet.getCell("D6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E6").value = "ผู้รับผิดชอบ"
    this.worksheet.getCell("E6").font = { size: 10 };
    this.worksheet.getCell("E6").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.mergeCells('E6:E7')
    this.worksheet.getCell("E6").alignment = { vertical: 'middle', horizontal: 'center' }

    this.worksheet.getCell("F6").value = `${docs.data?.budgetSource ?? '-'} `
    this.worksheet.mergeCells('F6:G6')
    this.worksheet.getCell("F6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F6").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };

    // this.worksheet.getCell("F6").value = `งบอุดหนุน`
    // this.worksheet.mergeCells('F6:F7')
    // this.worksheet.getCell("F6").alignment = { vertical: 'middle', horizontal: 'center' }
    // this.worksheet.getCell("G6").value = `งบอุดหนุน`
    // this.worksheet.mergeCells('G6:G7')
    // this.worksheet.getCell("G6").alignment = { vertical: 'middle', horizontal: 'center' }

    this.worksheet.getCell("H6").value = `${docs.data!.budgetYear - 1 || 0}`
    this.worksheet.getCell("H6").font = { size: 10 };
    this.worksheet.mergeCells('H6:J6')
    this.worksheet.getCell("H6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H6").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("F7").value = "งบอุดหนุน"
    this.worksheet.getCell("F7").font = { size: 10 };
    this.worksheet.getCell("F7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("G7").value = "งบลงทุน"
    this.worksheet.getCell("G7").font = { size: 10 };
    this.worksheet.getCell("G7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("H7").value = "ต.ค."
    this.worksheet.getCell("H7").font = { size: 10 };
    this.worksheet.getCell("H7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("I7").value = "พ.ย."
    this.worksheet.getCell("I7").font = { size: 10 };
    this.worksheet.getCell("I7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("J7").value = "ธ.ค."
    this.worksheet.getCell("J7").font = { size: 10 };
    this.worksheet.getCell("J7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("H7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("J7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K6").value = `${docs.data!.budgetYear || 0}`
    this.worksheet.mergeCells('K6:S6')
    this.worksheet.getCell("K6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K6").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("K7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("K7").value = "ม.ค."
    this.worksheet.getCell("K7").font = { size: 10 };
    this.worksheet.getCell("K7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L7").value = "ก.พ."
    this.worksheet.getCell("L7").font = { size: 10 };
    this.worksheet.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("M7").value = "มี.ค."
    this.worksheet.getCell("M7").font = { size: 10 };
    this.worksheet.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("N7").value = "เม.ย."
    this.worksheet.getCell("N7").font = { size: 10 };
    this.worksheet.getCell("N7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("N7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("O7").value = "พ.ค."
    this.worksheet.getCell("O7").font = { size: 10 };
    this.worksheet.getCell("O7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("O7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("P7").value = "มิ.ย."
    this.worksheet.getCell("P7").font = { size: 10 };
    this.worksheet.getCell("P7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("P7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("Q7").value = "ก.ค."
    this.worksheet.getCell("Q7").font = { size: 10 };
    this.worksheet.getCell("Q7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("Q7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("R7").value = "ส.ค."
    this.worksheet.getCell("R7").font = { size: 10 };
    this.worksheet.getCell("R7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("R7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("S7").value = "ก.ย."
    this.worksheet.getCell("S7").font = { size: 10 };
    this.worksheet.getCell("S7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("S7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("T6").value = "ความเชื่อมโยง"
    this.worksheet.getCell("T6").font = { size: 10 };
    this.worksheet.mergeCells('T6:T7')
    this.worksheet.getCell("T6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("T7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    this.worksheet.getCell("T7").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
      argb:'ff99cccc'
        // argb: 'FFe4ecfc'
      }
    };
    // //widthß
    this.worksheet.getColumn("A").width = 5
    this.worksheet.getColumn("B").width = 5
    this.worksheet.getColumn("C").width = 27
    this.worksheet.getColumn("D").width = 20
    this.worksheet.getColumn("E").width = 12
    this.worksheet.getColumn("F").width = 10
    this.worksheet.getColumn("G").width = 10
    this.worksheet.getColumn("H").width = 10
    this.worksheet.getColumn("I").width = 9
    this.worksheet.getColumn("J").width = 9
    this.worksheet.getColumn("K").width = 9
    this.worksheet.getColumn("L").width = 9
    this.worksheet.getColumn("M").width = 9
    this.worksheet.getColumn("N").width = 9
    this.worksheet.getColumn("O").width = 9
    this.worksheet.getColumn("P").width = 9
    this.worksheet.getColumn("Q").width = 9
    this.worksheet.getColumn("R").width = 9
    this.worksheet.getColumn("S").width = 9
    this.worksheet.getColumn("T").width = 10.33
    let current_row = 8;
    let start_row = 8;
    let last_item_row = 8;
    sortByNumberString((docs.data ?? { budgets: [] }).budgets).forEach((lv1: any, i: number) => {
      this.worksheet.addRow([
        lv1.number,
        `${lv1.name} (${lv1.code})`,
        null,
        lv1.goals,
        lv1.responsible.firstName,
        lv1.totalSupportAmount,
        lv1.totalInvestAmount,
        (lv1 ?? {}).monthAmount01 ?? 0,
        (lv1 ?? {}).monthAmount02 ?? 0,
        (lv1 ?? {}).monthAmount03 ?? 0,
        (lv1 ?? {}).monthAmount04 ?? 0,
        (lv1 ?? {}).monthAmount05 ?? 0,
        (lv1 ?? {}).monthAmount06 ?? 0,
        (lv1 ?? {}).monthAmount07 ?? 0,
        (lv1 ?? {}).monthAmount08 ?? 0,
        (lv1 ?? {}).monthAmount09 ?? 0,
        (lv1 ?? {}).monthAmount10 ?? 0,
        (lv1 ?? {}).monthAmount11 ?? 0,
        (lv1 ?? {}).monthAmount12 ?? 0,
        lv1.indicators.map((ind: any) => ind.indicator.code).join("\n"),


      ]);
      let row = this.worksheet.getRow(current_row++)
      row.eachCell({ includeEmpty: true }, (cell, cn) => {
        console.log(cn);

        if (cn >= 8 && cn < 20) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              // argb: 'FFD1E2F5'
              argb: isMonthActive(lv1.items, cn - 8) ? 'FFCCCCCC' : 'FFFFFFFF'
            }
          };
          cell.font = {
            size: 10
          };
        }
        if (cn >= 6 && cn < 20) {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          }
          cell.numFmt = '#,##0;[Red]-#,##0;';
          cell.font = {
            size: 10
          };
        }else{
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
            wrapText:true
          }
          cell.font = {
            size: 10
          };
        }
      })
      //   // #d1e2f5
      sortByNumberString(lv1.children).forEach((lv2: any, j: number) => {
        this.worksheet.addRow([
          "",
          `  ${lv2.number} ${lv2.name} (${lv2.code})`,
          null,
          lv2.goals,
          lv2.responsible.firstName,
          lv2.totalSupportAmount,
          lv2.totalInvestAmount,
          (lv2 ?? {}).monthAmount01 ?? 0,
          (lv2 ?? {}).monthAmount02 ?? 0,
          (lv2 ?? {}).monthAmount03 ?? 0,
          (lv2 ?? {}).monthAmount04 ?? 0,
          (lv2 ?? {}).monthAmount05 ?? 0,
          (lv2 ?? {}).monthAmount06 ?? 0,
          (lv2 ?? {}).monthAmount07 ?? 0,
          (lv2 ?? {}).monthAmount08 ?? 0,
          (lv2 ?? {}).monthAmount09 ?? 0,
          (lv2 ?? {}).monthAmount10 ?? 0,
          (lv2 ?? {}).monthAmount11 ?? 0,
          (lv2 ?? {}).monthAmount12 ?? 0,
          lv2.indicators.map((ind: any) => ind.indicator.code).join("\n"),
        ]);
        let row = this.worksheet.getRow(current_row++)
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          // console.table(lv2.monthsFlag)
          if (cn >= 8 && cn < 20) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: isMonthActive(lv2.items, cn - 8) ? 'FFe4ecfc' : 'FFFFFFFF'
                // argb: 'FFe4ecfc'
              }
            };
            cell.font = {
              size: 10
            };
          }
          if (cn >= 6 && cn < 20) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
            }
            cell.font = {
              size: 10
            };
            cell.numFmt = '#,##0;[Red]-#,##0;';
          }else{
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
              wrapText:true
            }
            cell.font = {
              size: 10
            };
          }
        })
        sortByNumberString(lv2.children).forEach((lv3: any, k: number) => {
          this.worksheet.addRow([
            "",
            `    ${lv3.number} ${lv3.name} (${lv3.code})`,
            null,
            lv3.goals,
            lv3.responsible.firstName,
            lv3.totalSupportAmount,
            lv3.totalInvestAmount,
            (lv3 ?? {}).monthAmount01 ?? 0,
            (lv3 ?? {}).monthAmount02 ?? 0,
            (lv3 ?? {}).monthAmount03 ?? 0,
            (lv3 ?? {}).monthAmount04 ?? 0,
            (lv3 ?? {}).monthAmount05 ?? 0,
            (lv3 ?? {}).monthAmount06 ?? 0,
            (lv3 ?? {}).monthAmount07 ?? 0,
            (lv3 ?? {}).monthAmount08 ?? 0,
            (lv3 ?? {}).monthAmount09 ?? 0,
            (lv3 ?? {}).monthAmount10 ?? 0,
            (lv3 ?? {}).monthAmount11 ?? 0,
            (lv3 ?? {}).monthAmount12 ?? 0,
            sortByNumberString(lv3.indicators).map((ind: any) => ind.indicator.code).join("\n"),
          ]);
          let row = this.worksheet.getRow(current_row++)
          row.eachCell({ includeEmpty: true }, (cell, cn) => {
            // console.table(lv3.monthsFlag)
            if (cn >= 8 && cn < 20) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: isMonthActive(lv3.items, cn - 8) ? 'FFf5f8f9' : 'FFFFFFFF'
                  // argb: 'FFf5f8f9'
                }
              };
              cell.font = {
                size: 10
              };  
            }
            if (cn == 4) {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'left',
              }
              cell.font = {
                size: 10
              };
  
            }else if (cn==5) {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'left',
              }
              cell.font = {
                size: 10
              };
  
            }else if (cn >= 6 && cn < 20) {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'right',
              }
              cell.font = {
                size: 10
              };
  
            }else{
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'left',
                wrapText:true
              }
              cell.numFmt = '#,##0;[Red]-#,##0;';
              cell.font = {
                size: 10
              };
            }

          })
        })
      })
      for (var i = 0; i < 14; i++) {
        this.worksheet.getColumn(6 + i).numFmt = '#,##0;[Red]-#,##0;';
        
      }
    })
    let result: Array<any> = [];
    docs.data!.grandTotalArrayValue.forEach((lv1: any) => {
      result.push(
        lv1
      )
    })
    this.worksheet.addRow([
      `รวม${docs.data.budgetSource ?? '-'}`,
      "",
      "",
      "",
      "",
      docs.data!.grandTotalSupportAmountA09,
      docs.data!.grandTotalInvestAmountA09,
      ...result,
      ""
    ])
    console.log('row', current_row, start_row)
    for (let i = 0; i < current_row - start_row; i++) {
      this.worksheet.mergeCells(start_row + i, 2, start_row + i, 3)
      this.worksheet.getRow(start_row + i).getCell(1).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left'
      }
      this.worksheet.getRow(start_row + i).height = 30   
    }

    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "",
      totalAmount(),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ])
    let lastRow = this.worksheet.rowCount
    console.log('lastrow', lastRow);

    this.worksheet.mergeCells(lastRow - 1, 1, lastRow, 5)
    this.worksheet.mergeCells(lastRow, 6, lastRow, 7)
    for (var i = 0; i < 13; i++) {
      this.worksheet.mergeCells(lastRow - 1, 8 + i, lastRow, 8 + i)
    }
    
    this.worksheet.getRow(lastRow - 1).eachCell({ includeEmpty: true }, (cell, cn) => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'right',
      }
      cell.font = {
        size: 10
      };
      cell.numFmt = '#,##0;[Red]-#,##0;';
    })
    this.worksheet.getRow(lastRow).eachCell({ includeEmpty: true }, (cell, cn) => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'right',
      }
      cell.font = {
        size: 10
      };
      cell.numFmt = '#,##0;[Red]-#,##0;';
    })
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 6) {

        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.font = { size: 10 };
        })
      }
    })
    ListResponsibles.map((el => {
      const row = this.worksheet.addRow([...el])
      row.eachCell((cell) => {
        cell.font = { size: 10 };
      });
    }))
    ListSectors.map((el => {
      const row = this.worksheet.addRow([...el])
      row.eachCell((cell) => {
        cell.font = { size: 10 };
      });
    }))
    // return 
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA09.xlsx");
    })
  }

  async createA01(doc: ReportA01Model, budgetYear: Number) {
    console.log("groupedData");
    // const groupedData = _.groupBy(doc.docs,['indicatorCode']);
    const sortedData = _.sortBy(doc.docs, ['indicatorCode']);
    const totalAmount = doc.sums.reduce((accumulator, item) => {
      return accumulator + item.amount;
    }, 0);

    //header
    this.worksheet.addRow([`รายงานความเชื่อมโยงตัวชี้วัด ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:H1')
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").value = `ลำดับ`

    this.worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B2").value = `ตัวชี้วัด`
    this.worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C2").value = `รายการ`
    this.worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D2").value = `ข้อการดำเนินงาน/กิจกรรม`
    this.worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E2").value = `ผู้รับผิดชอบ`
    this.worksheet.getCell("F2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F2").value = `งบประมาณ`
    this.worksheet.getCell("G2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G2").value = `แหล่งงบประมาณ`
    this.worksheet.getCell("H2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H2").value = `ฝ่ายและสำนักที่รับผิดชอบ`
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 80
    this.worksheet.getColumn("C").width = 50
    this.worksheet.getColumn("D").width = 50
    this.worksheet.getColumn("E").width = 50
    this.worksheet.getColumn("F").width = 50
    this.worksheet.getColumn("G").width = 50
    this.worksheet.getColumn("H").width = 50


    let myArray = doc.docs
    let newArray: Array<any> = []
    myArray.forEach((el, i) => {
      newArray.push({ items: el.items, code: el.indicatorCode })
    })
    let groupedData = newArray.flat()
    let newGroupedData = _.groupBy(groupedData, 'code')
    let x = 0
    for (const [k, v] of Object.entries(newGroupedData)) {
      console.log({ k, v })
      let i = 0
      for (const group of newGroupedData[k]) {
        let j = 0
        let ind_length = group.items.length
        let itemGrouped = _.groupBy(group.items, 'code')
        for (const [k2, v2] of Object.entries(itemGrouped)) {
          let kk = 0
          let item_length = v2.length
          let item = group.items[0]
          for (const el of v2) {
            this.worksheet.addRow([
              `${x + 1}`,
              `${(k + " : " + item.name ?? "")}`,
              `${(item.code ?? "") + " " + (item.name ?? "")}`,
              `${el.number + ". " + el.subject}`,
              `${el.responsible}`,
              el.budget,
              `${(el.type ?? ",").split(",")[0]}`,
              `${el.sectorName}`,
            ])
            kk++
          }
          if (j === 0) {
            let rowCount = this.worksheet.rowCount
            this.worksheet.mergeCells(rowCount - item_length + 1, 3, rowCount, 3)
          }
          if (j === 0) {
            let rowCount = this.worksheet.rowCount
            this.worksheet.mergeCells(rowCount - item_length + 1, 1, rowCount, 1)
          }
          if (j === 0) {
            let rowCount = this.worksheet.rowCount
            this.worksheet.mergeCells(rowCount - ind_length + 1, 2, rowCount, 2)
          }
          j++
        }
        i++
      }
      x++
    }
    // sortedData.forEach((el, i) => {
    //   let start = this.worksheet.rowCount + 1
    //   el.items.forEach((item,j) => {
    //     this.worksheet.addRow([
    //       `${i + 1}`,
    //       `${el.indicatorCode}`+" : "+`${el.name}`,
    //       `${item.code}` + "  " + `${item.name}`,
    //       `${item.number}` + " " + `${item.subject}`,
    //       `${item.responsible}`,
    //       item.budget,
    //       `${(item.type ?? ",").split(",")[0]}`,
    //       `${item.sectorName}`,
    //     ])
    //   })
    //   let end = this.worksheet.rowCount
    //   this.worksheet.getRow(start).alignment = {vertical: 'middle', horizontal: 'center' 

    //   }
    //   this.worksheet.mergeCells(start,2,end,2)
    //   this.worksheet.mergeCells(start,1,end,1)
    // });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 2) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.alignment = {
            vertical: "top"
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })

        row.getCell("F").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("G").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("H").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("I").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("J").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("K").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("L").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("M").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("N").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("O").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("P").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("Q").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("R").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("S").numFmt = '#,##0.00;[Red]-#,##0.00'
      }
      if (rn === 2) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDCE6F1' }
          }
        })
      }
    })
    doc.sums.forEach((item, i) => {

      this.worksheet.addRow([
        "",
        "",
        "",
        "",
        "รวม" + item.name,
        item.amount,
        "",
        "",
      ])
    })

    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "รวมทั้งสิ้น",
      totalAmount.formatFull(),
      "",
      "",
    ])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA01.xlsx");
    });
  }
  async createA02(doc: ReportA02Model, budgetYear: Number) {
    //header
    this.worksheet.addRow([`การรายงานผลใแต่ละกิจกรรมที่สอดคล้องกับตัวชี้วัด หรือความเสี่ยง`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:I1')
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A2:I2')
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").value = `ลำดับ`
    this.worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B3").value = `ตัวชี้วัด`
    this.worksheet.getCell("C3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C3").value = `รายการ`
    this.worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D3").value = `ข้อการดำเนินงาน`
    this.worksheet.getCell("E3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E3").value = `ผลการดำเนินงาน`
    this.worksheet.getCell("F3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F3").value = `ผู้รับผิดชอบ`
    this.worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G3").value = `งบประมาณ`
    this.worksheet.getCell("H3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H3").value = `แหล่งงบประมาณ`
    this.worksheet.getCell("I3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I3").value = `ฝ่ายและสำนักที่รับผิดชอบ`
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 40
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    this.worksheet.getColumn("I").width = 20
    doc.indicators.forEach((doc, i) => {
      let start = this.worksheet.rowCount + 1
      doc.items.forEach((item, j) => {
        this.worksheet.addRow([
          `${i + 1}`,
          `${doc.indicatorCode} ${doc.indicatorType}`,
          `${item.projectCode} ${item.projectName}`,

          `${item.budgetNumber} ${item.budgetName}`,
          `${item.followUp}`,
          `${item.responsible}`,
          `${item.budgetAmount}`,
          item.budgetTypeName,
          `${item.sectorName}`

        ])
      })
      let end = this.worksheet.rowCount
      this.worksheet.getRow(start).alignment = {
        vertical: 'middle', horizontal: 'center'

      }
      this.worksheet.mergeCells(start, 2, end, 2)
      this.worksheet.mergeCells(start, 1, end, 1)
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 3) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA02.xlsx");
    });
  }
  async createA03(docs: Array<any>, budgetYear: Number) {
    //header
    this.worksheet.addRow([`รายละเอียดการปรับรายการเบิก/ยืม`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:H1')
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A2:H2')
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").value = `ลำดับ`
    this.worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B3").value = `เลขที่SMIS`
    this.worksheet.getCell("C3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C3").value = `จำนวนเงิน`
    this.worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D3").value = `วันที่ทำรายการ (วัน/เดือน/ปี)เป็น พ.ศ.`
    this.worksheet.getCell("E3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E3").value = `รหัสข้อการดำเนินงาน(bdgltrmID)`
    this.worksheet.getCell("F3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F3").value = `รหัสข้อการดำเนินงาน (bdgID)`
    this.worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G3").value = `สาเหตุที่ดำเนินการลบเลข SMIS`
    this.worksheet.getCell("H3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H3").value = `ผู้ดำเนินการลบ`
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 40
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    // this.worksheet.getColumn("I").width = 20

    docs.forEach((doc: ReportA03ItemModel, i: number) => {
      this.worksheet.addRow([
        `${i + 1}`,
        `${doc.smisCode}`,
        `${doc.amount}`,
        doc.createdAt,
        doc.budget.code,
        doc.project.code,
        "-",
        doc.createdBy.sAMAccountName + "/" + doc.createdBy.fullName
      ])
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 3) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA03.xlsx");
    });
  }
  async createA05(docs: Array<any>, doc: any, budgetYear: Number) {
    //header
    this.worksheet.addRow([`รายงานสรุปแผนและผลการเบิกจ่ายโดยแยกประเภทงบประมาณ และแหล่งงบประมาณ\r\nประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:I1')
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").value = `ลำดับ`
    this.worksheet.getCell("B2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B2").value = `รายการที่`
    this.worksheet.getCell("C2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C2").value = `ฝ่ายและสำนักที่รับผิดชอบ`
    this.worksheet.getCell("D2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D2").value = `แหล่งงบประมาณ`
    this.worksheet.getCell("E2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E2").value = `งบประมาณ`
    this.worksheet.mergeCells('E2:F2')
    this.worksheet.getCell("G2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G2").value = `ผลการเบิกจ่าย`
    this.worksheet.getCell("H2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H2").value = `งบประมาณคงเหลือ`
    this.worksheet.getCell("I2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I2").value = `หมายเหตุ`
    this.worksheet.mergeCells('A2:A3')
    this.worksheet.mergeCells('B2:B3')
    this.worksheet.mergeCells('C2:C3')
    this.worksheet.mergeCells('D2:D3')
    this.worksheet.mergeCells('G2:G3')
    this.worksheet.mergeCells('H2:H3')
    this.worksheet.mergeCells('I2:I3')
    this.worksheet.getCell("E3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E3").value = `งบอุดหนุน`
    this.worksheet.getCell("F3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F3").value = `งบลงทุน`
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 40
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    this.worksheet.getColumn("I").width = 20
    let groupProjectCode = _.sortBy(docs, 'projectName')
    groupProjectCode.forEach((el: any, i: number) => {
      this.worksheet.addRow([i + 1,
      el.projectName,
      el.sectorName,
      el.budgetSource,
      el.budgetSupport,
      el.budgetInvestment,
      el.actual,
      el.diff,
        ""
      ])
    });

    let lastRow = 1

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 2) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          if (cn >= 5 && cn <= 8) row.getCell(cn).numFmt = '#,##0.00;[Red]-#,##0.00';
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
      lastRow = rn
    })
    this.worksheet.addRow([null, null, null, "รวม", doc.sumBudgetInvestment, doc.sumBudgetSupport, doc.sumActual, doc.sumDiff, null])
    this.worksheet.addRow([null, null, null, "รวมทั้งสิ้น", doc.sumBudgetInvestment + doc.sumBudgetSupport])

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= lastRow) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          if (cn >= 5 && cn <= 8) row.getCell(cn).numFmt = '#,##0.00;[Red]-#,##0.00';
          if (cn >= 5 && cn <= 8) {
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
        })
      }
      lastRow = rn
    })
    this.worksheet.mergeCells(lastRow, 5, lastRow, 6)
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA05.xlsx");
    });
  }
  async createA04(doc: ReportA04Model, budgetYear: Number) {
    //header
    this.worksheet.addRow([`รายละเอียดกิจกรรมและงบประมาณที่ต้องการจากแหล่งเงินและแผนการปฏิบัติงานฯ ที่ต่างกัน`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:I1')
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A2:I2')
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").value = `ลำดับ`
    this.worksheet.mergeCells('A3:A4')
    this.worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B3").value = `กิจกรรม / ข้อการดำเนินงาน`
    this.worksheet.mergeCells('B3:B4')
    this.worksheet.getCell("C3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C3").value = `ผู้รับผิดชอบ`
    this.worksheet.mergeCells('C3:C4')
    this.worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D3").value = `งบประมาณ`
    this.worksheet.mergeCells('D3:E3')
    this.worksheet.getCell("D4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D4").value = `งบอุดหนุน`
    this.worksheet.getCell("E4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E4").value = `งบลงทุน`
    this.worksheet.getCell("F3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F3").value = `แหล่งงบประมาณ`
    this.worksheet.mergeCells('F3:F4')
    this.worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G3").value = `รายการที่`
    this.worksheet.mergeCells('G3:G4')
    this.worksheet.getCell("H3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H3").value = `ฝ่ายและสำนักที่รับผิดชอบ`
    this.worksheet.mergeCells('H3:H4')
    this.worksheet.getCell("I3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I3").value = `หมายเหตุ`
    this.worksheet.mergeCells('I3:I4')
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 40
    this.worksheet.getColumn("C").width = 30
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    this.worksheet.getColumn("I").width = 20
    doc.items.forEach((doc, i) => {
      this.worksheet.addRow([
        `${i + 1 }`,
        `${doc.number}` + ". " + `${doc.name}`,
        `${doc.responsible}`,
        doc.supportBudget,
        doc.investmentBudget,
        `${doc.source}`,
        `${doc.projectName}`,
        `${doc.sectorName}`,
        ``,
      ])
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 3) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
        row.getCell("D").numFmt = '#,##0.00;[Red]-#,##0.00'
        row.getCell("E").numFmt = '#,##0.00;[Red]-#,##0.00'
      }
      if (rn >= 3 && rn <= 4) {

        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDCE6F1' }
          }
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA04.xlsx");
    });
  }
  async createA06(reportData: ReportA10Class, budgetYear: number, sumTotalCollection: number, sumTotalInvestment: number) {
    let types = () => {
      try {
        return reportData.projects![0].types;
      } catch (error) { }
    }
    let getProjectByType = (item: any, type: string) => {
      try {
        let found = item.sumTypes.find((el: any) => el.name === type);
        return found.amount;
      } catch (error) {
        return 0;
      }
    }
    let getActivityByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.projects.forEach((project: any) => {
          let found = getProjectByType(project, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getProductByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.activities.forEach((activity: any) => {
          let found = getActivityByType(activity, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getItemByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.products.forEach((product: any) => {
          let found = getProductByType(product, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }

    this.worksheet.addRow([`รายงานสรุปแผนและผลการเบิกจ่ายเป็นรายเดือน/รายไตรมาส/รายปี`])
    this.worksheet.mergeCells('A1:AN1')
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.mergeCells('A2:AN2')
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").value = "ลำดับ"
    this.worksheet.mergeCells('A3:A6')
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B3").value = "ผลผลิต/กิจกรรม/รายการ"
    this.worksheet.mergeCells('B3:B6')
    this.worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C3").value = "ฝ่ายและสำนักที่รับผิดชอบ"
    this.worksheet.mergeCells('C3:C6')
    this.worksheet.getCell("C3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D3").value = "แหล่งงบประมาณ"
    this.worksheet.mergeCells('D3:D6')
    this.worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E3").value = "งบประมาณ"
    this.worksheet.mergeCells('E3:F4')
    this.worksheet.getCell("E3").alignment = { vertical: 'middle', horizontal: 'center' }
    // this.worksheet.getCell("F4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E5").value = "งบอุดหนุน"
    this.worksheet.mergeCells('E5:E6')
    this.worksheet.getCell("E5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F5").value = "งบลงทุน"
    this.worksheet.mergeCells('F5:F6')
    this.worksheet.getCell("F5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G3").value = "แผน/ผลการเบิกจ่าย"
    this.worksheet.mergeCells('G3:AN3')
    this.worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G4").value = "ไตรมาสที่ 1"
    this.worksheet.mergeCells('G4:N4')
    this.worksheet.getCell("G4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("O4").value = "ไตรมาสที่ 2"
    this.worksheet.mergeCells('O4:V4')
    this.worksheet.getCell("O4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("W4").value = "ไตรมาสที่ 3"
    this.worksheet.mergeCells('W4:AD4')
    this.worksheet.getCell("W4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AE4").value = "ไตรมาสที่ 4"
    this.worksheet.mergeCells('AE4:AL4')
    this.worksheet.getCell("AE4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AM4").value = "รวมทั้งสิ้น"
    this.worksheet.mergeCells('AM4:AN4')
    this.worksheet.getCell("AM4").alignment = { vertical: 'middle', horizontal: 'center' }
    //ไตรมาส1
    this.worksheet.getCell("G5").value = "ต.ค."
    this.worksheet.mergeCells('G5:H5')
    this.worksheet.getCell("G5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I5").value = "พ.ย."
    this.worksheet.mergeCells('I5:J5')
    this.worksheet.getCell("I5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K5").value = "ธ.ค."
    this.worksheet.mergeCells('K5:L5')
    this.worksheet.getCell("K5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M5").value = "รวม"
    this.worksheet.mergeCells('M5:N5')
    this.worksheet.getCell("M5").alignment = { vertical: 'middle', horizontal: 'center' }
    //ไตรมาส2
    this.worksheet.getCell("O5").value = "ม.ค."
    this.worksheet.mergeCells('O5:P5')
    this.worksheet.getCell("O5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("Q5").value = "ก.พ."
    this.worksheet.mergeCells('Q5:R5')
    this.worksheet.getCell("Q5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("S5").value = "มี.ค."
    this.worksheet.mergeCells('S5:T5')
    this.worksheet.getCell("S5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("U5").value = "รวม"
    this.worksheet.mergeCells('U5:V5')
    this.worksheet.getCell("U5").alignment = { vertical: 'middle', horizontal: 'center' }
    //ไตรมาส3
    this.worksheet.getCell("W5").value = "เม.ย."
    this.worksheet.mergeCells('W5:X5')
    this.worksheet.getCell("W5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("Y5").value = "พ.ค."
    this.worksheet.mergeCells('Y5:Z5')
    this.worksheet.getCell("Y5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AA5").value = "มิ.ย."
    this.worksheet.mergeCells('AA5:AB5')
    this.worksheet.getCell("AA5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AC5").value = "รวม"
    this.worksheet.mergeCells('AC5:AD5')
    this.worksheet.getCell("AC5").alignment = { vertical: 'middle', horizontal: 'center' }
    //ไตรมาส4
    this.worksheet.getCell("AE5").value = "ก.ค."
    this.worksheet.mergeCells('AE5:AF5')
    this.worksheet.getCell("AE5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AG5").value = "ส.ค."
    this.worksheet.mergeCells('AG5:AH5')
    this.worksheet.getCell("AG5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AI5").value = "ก.ย."
    this.worksheet.mergeCells('AI5:AJ5')
    this.worksheet.getCell("AI5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AK5").value = "รวม"
    this.worksheet.mergeCells('AK5:AL5')
    this.worksheet.getCell("AK5").alignment = { vertical: 'middle', horizontal: 'center' }
    //แผนผล
    this.worksheet.getCell("AM5").value = "แผน"
    this.worksheet.mergeCells('AM5:AM6')
    this.worksheet.getCell("AM5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AN5").value = "ผล"
    this.worksheet.mergeCells('AN5:AN6')
    this.worksheet.getCell("AN5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G6").value = "แผน"
    this.worksheet.getCell("G6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H6").value = "ผล"
    this.worksheet.getCell("H6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I6").value = "แผน"
    this.worksheet.getCell("I6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("J6").value = "ผล"
    this.worksheet.getCell("J6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K6").value = "แผน"
    this.worksheet.getCell("K6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L6").value = "ผล"
    this.worksheet.getCell("L6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M6").value = "แผน"
    this.worksheet.getCell("M6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("N6").value = "ผล"
    this.worksheet.getCell("N6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("O6").value = "แผน"
    this.worksheet.getCell("O6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("P6").value = "ผล"
    this.worksheet.getCell("P6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("Q6").value = "แผน"
    this.worksheet.getCell("Q6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("R6").value = "ผล"
    this.worksheet.getCell("R6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("S6").value = "แผน"
    this.worksheet.getCell("S6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("T6").value = "ผล"
    this.worksheet.getCell("T6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("U6").value = "แผน"
    this.worksheet.getCell("U6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("V6").value = "ผล"
    this.worksheet.getCell("V6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("W6").value = "แผน"
    this.worksheet.getCell("W6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("X6").value = "ผล"
    this.worksheet.getCell("X6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("Y6").value = "แผน"
    this.worksheet.getCell("Y6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("Z6").value = "ผล"
    this.worksheet.getCell("Z6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AA6").value = "แผน"
    this.worksheet.getCell("AA6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AB6").value = "ผล"
    this.worksheet.getCell("AB6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AC6").value = "แผน"
    this.worksheet.getCell("AC6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AD6").value = "ผล"
    this.worksheet.getCell("AD6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AE6").value = "แผน"
    this.worksheet.getCell("AE6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AF6").value = "ผล"
    this.worksheet.getCell("AF6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AG6").value = "แผน"
    this.worksheet.getCell("AG6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AH6").value = "ผล"
    this.worksheet.getCell("AH6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AI6").value = "แผน"
    this.worksheet.getCell("AI6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AJ6").value = "ผล"
    this.worksheet.getCell("AJ6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AK6").value = "แผน"
    this.worksheet.getCell("AK6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AL6").value = "ผล"
    this.worksheet.getCell("AL6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AM6").value = "แผน"
    this.worksheet.getCell("AM6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("AN6").value = "ผล"
    this.worksheet.getCell("AN6").alignment = { vertical: 'middle', horizontal: 'center' }
    //width
    this.worksheet.getColumn("A").width = 30
    this.worksheet.getColumn("B").width = 80
    this.worksheet.getColumn("C").width = 60
    this.worksheet.getColumn("D").width = 40
    this.worksheet.getColumn("E").width = 30
    this.worksheet.getColumn("F").width = 30
    this.worksheet.getColumn("G").width = 30
    this.worksheet.getColumn("H").width = 30
    this.worksheet.getColumn("I").width = 30
    this.worksheet.getColumn("J").width = 30
    this.worksheet.getColumn("K").width = 30
    this.worksheet.getColumn("L").width = 30
    this.worksheet.getColumn("M").width = 30
    this.worksheet.getColumn("N").width = 30
    this.worksheet.getColumn("O").width = 30
    this.worksheet.getColumn("P").width = 30
    this.worksheet.getColumn("Q").width = 30
    this.worksheet.getColumn("R").width = 30
    this.worksheet.getColumn("S").width = 30
    this.worksheet.getColumn("T").width = 30
    this.worksheet.getColumn("U").width = 30
    this.worksheet.getColumn("V").width = 30
    this.worksheet.getColumn("W").width = 30
    this.worksheet.getColumn("X").width = 30
    this.worksheet.getColumn("Y").width = 30
    this.worksheet.getColumn("Z").width = 30
    this.worksheet.getColumn("AA").width = 30
    this.worksheet.getColumn("AB").width = 30
    this.worksheet.getColumn("AC").width = 30
    this.worksheet.getColumn("AD").width = 30
    this.worksheet.getColumn("AE").width = 30
    this.worksheet.getColumn("AF").width = 30
    this.worksheet.getColumn("AG").width = 30
    this.worksheet.getColumn("AH").width = 30
    this.worksheet.getColumn("AI").width = 30
    this.worksheet.getColumn("AJ").width = 30
    this.worksheet.getColumn("AK").width = 30
    this.worksheet.getColumn("AL").width = 30
    this.worksheet.getColumn("AM").width = 30
    this.worksheet.getColumn("AN").width = 30
    //this.worksheet.mergeCells("A3:C3")
    reportData.data.plans!.forEach((item: any, i: number) => {
      //แผนงาน
      let rowI = this.worksheet.addRow([
        item.name,
        "",
        "",
        "",
        sumTotalCollection,
        sumTotalInvestment,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ])
      rowI.eachCell(cell => {
        cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF91bbe0' } } }
      })

      let row2 = this.worksheet.rowCount
      this.worksheet.mergeCells(row2, 1, row2, 3)
      //ผลผลิต
      item.products.forEach((pro: any, i: number) => {
        let rowP = this.worksheet.addRow([
          "ผลผลิตที่ " + pro.name,
          "",
          "",
          "",
          pro.sumTotalCollection.formatComma(),
          pro.sumTotalInvestment.formatComma(),
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ])
        rowP.eachCell(cell => {
          cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDEE6F0' } } }
        })
        //กิจกรรมหลัก
        pro.activities.forEach((act: any, i: number) => {
          let rowA = this.worksheet.addRow([
            `กิจกรรมหลักที่ ${i + 1}` + act.name,
            "",
            "",
            "",
            act.sumTotalCollection.formatComma(),
            act.sumTotalInvestment.formatComma(),
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",

          ])
          rowA.eachCell(cell => {
            cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFd4ddef' } } }
          })
          //โครงการ
          act.projects.forEach((project: any, i: number) => {
            this.worksheet.addRow([
              i + 1,
              `รายการ ${project.code} ${project.name}`,
              project.sector,
              project.budgetSource,
              project.budgetType == "งบอุดหนุน" ? project.total : "",
              project.budgetType == "งบลงทุน" ? project.total : "",
              project.months[0],
              project.actuals[0],
              project.months[1],
              project.actuals[1],
              project.months[2],
              project.actuals[2],
              project.monthsQ1,
              project.actualsQ1,
              project.months[3],
              project.actuals[3],
              project.months[4],
              project.actuals[4],
              project.months[5],
              project.actuals[5],
              project.monthsQ2,
              project.actualsQ2,
              project.months[6],
              project.actuals[6],
              project.months[7],
              project.actuals[7],
              project.months[8],
              project.actuals[8],
              project.monthsQ3,
              project.actualsQ3,
              project.months[9],
              project.actuals[9],
              project.months[10],
              project.actuals[10],
              project.months[11],
              project.actuals[11],
              project.monthsQ4,
              project.actualsQ4,
              project.monthsSum,
              project.actualsSum




            ])
          })
        })
      })
    })

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 1) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (cn >= 6) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center'
            }
            cell.numFmt = '#,##0.00;[Red]-#,##0.00;';
          }
        })
      }
    })

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA06.xlsx");
    });

  }


  async createA07(doc: ReportA07Model, budgetType: string, budgetYear: number): Promise<any> {
    //header
    this.worksheet.addRow([`รายงานแผนเทียบกับผลการเบิกจ่ายโดยแยกเป็นรายฝ่ายและสำนักของ กกท. (มีร้อยละ) และสามารถเปรียบเทียบการแสดงผลระหว่างฝ่ายและสำนัก`])
    this.worksheet.addRow([`แหล่งงบประมาณ : ${budgetType}`])
    this.worksheet.addRow([(doc.budgetInvestment + doc.budgetSupport).formatFull()])
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }

    this.worksheet.addRow(["ลำดับ", "รายการที่", "แหล่งงบประมาณ", "งบประมาณ", "", "", "", "ผลการเบิกจ่าย", "", "งบประมาณคงเหลือ", "คงเหลือคิดเป็น\r\nร้อยละ", "ผลการเบิกจ่าย\r\nคิดเป็นร้อยละ\r\nของภาพรวม\r\n(งบประจำปี)"])
    this.worksheet.addRow(["", "", "", "รวม\r\n(อุดหนุน + ลงทุน)", "งบอุดหนุน", "งบลงทุน", "ร้อยละ", "งบประมาณ", "ร้อยละ", "", ""])
    let totalRow = this.worksheet.addRow(["", "รวมทั้งสิ้น", "", (doc.budgetInvestment + doc.budgetSupport).formatFull(),
      doc.budgetInvestment.formatFull(),
      doc.budgetSupport.formatFull(),
      ((doc.budgetInvestment + doc.budgetSupport) / (doc.budgetInvestment + doc.budgetSupport)).formatFull(),
      doc.actual.formatFull(),
      (((doc.actual) / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatFull(),
      doc.diff.formatFull(),
      (((doc.diff) / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatFull(),
      (((doc.diff) / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatFull(),])

    totalRow.eachCell(cell => {
      cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFfbcead' } } }
    })
    this.worksheet.mergeCells('A1:L1')
    this.worksheet.mergeCells('A2:L2')
    this.worksheet.mergeCells('A3:L3')
    this.worksheet.mergeCells('A4:L4')

    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 75
    this.worksheet.getColumn("C").width = 25
    this.worksheet.getColumn("D").width = 17
    this.worksheet.getColumn("E").width = 17
    this.worksheet.getColumn("F").width = 17
    this.worksheet.getColumn("G").width = 17
    this.worksheet.getColumn("H").width = 17
    this.worksheet.getColumn("I").width = 17
    this.worksheet.getColumn("J").width = 17
    this.worksheet.getColumn("K").width = 17
    this.worksheet.getColumn("L").width = 17

    this.worksheet.mergeCells('A5:A6')
    this.worksheet.mergeCells('B5:B6')
    this.worksheet.mergeCells('C5:C6')
    this.worksheet.mergeCells('D5:G5')
    this.worksheet.mergeCells('H5:I5')
    this.worksheet.mergeCells('J5:J6')
    this.worksheet.mergeCells('K5:K6')
    this.worksheet.mergeCells('L5:L6')

    this.worksheet.getCell("L5").alignment = {
      wrapText: true
    }
    doc.sectors.forEach((sector, i) => {
      let sectorRow = this.worksheet.addRow(["",
        sector.name,
        "",
        (sector.budgetInvestment + sector.budgetSupport).formatFull(),
        sector.budgetInvestment.formatFull(),
        sector.budgetSupport.formatFull(),
        (100 * (sector.budgetInvestment + sector.budgetSupport) / (doc.budgetInvestment + doc.budgetSupport)).formatFull(),
        sector.actual.formatFull(),
        ((sector.actual / (sector.budgetInvestment + sector.budgetSupport)) * 100).formatFull(),
        sector.diff.formatFull(),//คงเหลือ
        ((sector.diff / (sector.budgetInvestment + sector.budgetSupport)) * 100).formatFull(),
        ((sector.diff / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatFull(),
      ])
      sectorRow.eachCell(cell => {
        cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFfce5d5' } } }
      })
      sector.projects.forEach((project, j) => {
        this.worksheet.addRow([j + 1,
        project.projectName,
        project.budgetSource,
        (project.budgetInvestment + project.budgetSupport).formatFull(),
        project.budgetInvestment.formatFull(),
        project.budgetSupport.formatFull(),
        (((project.budgetInvestment + project.budgetSupport) / (sector.budgetInvestment + sector.budgetSupport)) * 100).formatFull(),
        project.actual.formatFull(),
        ((project.actual / (project.budgetInvestment + project.budgetSupport)) * 100).formatFull(),//ต้องทำ
        project.diff.formatFull(),//คงเหลือ
        ((project.diff / (project.budgetInvestment + project.budgetSupport)) * 100).formatFull(),//ต้องทำ
        ((project.diff / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatComma()
        ])
      })
    })


    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn <= 6) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
          }
        })
      }
      if (rn > 4) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (cn >= 4) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right'
            }
            cell.numFmt = '#,##0.00;[Red]-#,##0.00;';
          }
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA07.xlsx");
    });
  }
  async createA10(reportData: ReportA10Class, budgetYear: number) {

    let types = () => {
      try {
        return reportData.projects![0].types;
      } catch (error) { }
    }
    let getProjectByType = (item: any, type: string) => {
      try {
        let found = item.sumTypes.find((el: any) => el.name === type);
        return found.amount;
      } catch (error) {
        return 0;
      }
    }
    let getActivityByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.projects.forEach((project: any) => {
          let found = getProjectByType(project, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getProductByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.activities.forEach((activity: any) => {
          let found = getActivityByType(activity, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getItemByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.products.forEach((product: any) => {
          let found = getProductByType(product, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getTotal = (type: string) => {
      try {
        let sum = 0
        reportData.data.plans.forEach((item: any) => {
          let found = getItemByType(item, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    //header
    this.worksheet.addRow([`แผนการปฏิบัติงานและแผนการใช้จ่ายงบประะมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.mergeCells('A1:L1')

    this.worksheet.addRow([
      "ผลผลิต / กิจกรรม / รายการ",
      "",
      "",
      "ฝ่าย / สำนัก",
      "ไตรมาส 1",
      "ไตรมาส 2",
      "ไตรมาส 3",
      "ไตรมาส 4",
      "รวมงบประมาณได้รับจัดสรร",
      ...types()!.map(item => item),
      "หน้า"])
    this.worksheet.getRow(2).height = 20
    this.worksheet.getRow(2).eachCell(cell => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      }
    })
    this.worksheet.mergeCells("A2:C2")
    // ...types()!.map(typeStr=>({text:getItemByType(item,typeStr)}))
    // this.worksheet.getCell("L2").value = `หน้า`
    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 10
    this.worksheet.getColumn("C").width = 90
    this.worksheet.getColumn("D").width = 30
    this.worksheet.getColumn("E").width = 20
    this.worksheet.getColumn("F").width = 20
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    this.worksheet.getColumn("I").width = 20
    this.worksheet.getColumn("J").width = 20
    this.worksheet.getColumn("K").width = 20
    this.worksheet.getColumn("L").width = 20
    this.worksheet.addRow([
      "รวมงบประมาณทั้งสิ้น",
      null,
      null,
      "",
      reportData.data.plans.map((el: any) => el.sumQ1 ?? 0).reduce((a: number, b: number) => a + b, 0),
      reportData.data.plans.map((el: any) => el.sumQ2 ?? 0).reduce((a: number, b: number) => a + b, 0),
      reportData.data.plans.map((el: any) => el.sumQ3 ?? 0).reduce((a: number, b: number) => a + b, 0),
      reportData.data.plans.map((el: any) => el.sumQ4 ?? 0).reduce((a: number, b: number) => a + b, 0),
      reportData.data.plans.map((el: any) => el.sumTotal ?? 0).reduce((a: number, b: number) => a + b, 0),
      ...types()!.map(item => getTotal(item)),
      "",
    ])
    this.worksheet.mergeCells("A3:C3")
    reportData.data.plans!.forEach((item: any, i: number) => {
      //แผนงาน
      let rowI = this.worksheet.addRow([
        item.name,
        "",
        "",
        "",
        item.sumQ1,
        item.sumQ2,
        item.sumQ3,
        item.sumQ4,
        item.sumTotal,
        ...types()!.map(typeStr => getItemByType(item, typeStr)),
        "",
      ])
      rowI.eachCell(cell => {
        cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF91bbe0' } } }
      })

      let row2 = this.worksheet.rowCount
      this.worksheet.mergeCells(row2, 1, row2, 3)
      //ผลผลิต
      item.products.forEach((pro: any, i: number) => {
        let rowP = this.worksheet.addRow([
          "ผลผลิตที่ " + pro.name,
          "",
          "",
          "",
          pro.sumQ1,
          pro.sumQ2,
          pro.sumQ3,
          pro.sumQ4,
          pro.sumTotal,
          ...types()!.map(typeStr => getProductByType(pro, typeStr)),
          "",
        ])
        rowP.eachCell(cell => {
          cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDEE6F0' } } }
        })
        //กิจกรรมหลัก
        pro.activities.forEach((act: any, i: number) => {
          let rowA = this.worksheet.addRow([
            "กิจกรรมหลักที่ " + act.name,
            "",
            "",
            "",
            act.sumQ1,
            act.sumQ2,
            act.sumQ3,
            act.sumQ4,
            act.sumTotal,
            ...types()!.map(typeStr => getActivityByType(act, typeStr)),
            "",
          ])
          rowA.eachCell(cell => {
            cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFd4ddef' } } }
          })
          //โครงการ
          act.projects.forEach((project: any, i: number) => {
            this.worksheet.addRow([
              "รายการ",
              project.code,
              project.name,
              project.sector,
              project.q1,
              project.q2,
              project.q3,
              project.q4,
              project.total,
              ...types()!.map(typeStr => getProjectByType(project, typeStr)),
              project.page ?? "-",
            ])
          })
        })
      })
    })

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 1) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (cn >= 5) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right'
            }
            cell.numFmt = '#,##0.00;[Red]-#,##0.00;';
          }
        })
      }
    })

    // this.worksheet.addRow([])
    // this.worksheet.addRow([])
    // this.worksheet.addRow([])
    // this.worksheet.addRow([])
    // this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    // this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])
    // let current_row = 2
    // let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA10.xlsx");
    });
  }
  createA08Type1(doc: ReportA08Type1Model, budgetYear: Number) {
    let data: Array<any> = []
    this.worksheet.addRow([`รายงานค่าใช้จ่ายในประเภทต่างๆที่อยู่ในหน้าแผนฯ โดยสามารถรวมและแยกเป็นฝ่ายและสำนักต่างๆ`])
    this.worksheet.addRow(['การจ้างผู้ช่วยปฏิบัติงานฯ'])
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.addRow([""])
    this.worksheet.mergeCells('A1:I1')
    this.worksheet.mergeCells('A2:I2')
    this.worksheet.mergeCells('A3:I3')
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }

    this.worksheet.addRow(["ลำดับ", "รายการที่", "ฝ่ายและสำนักที่รับผิดชอบ", "แหล่งงบประมาณ", "วุฒิการศึกษา (จำนวนคน)", "", "", "", "จำนวนงบประมาณ", "", "", "", "อื่นๆ", "", "", "", "", "", "รวมงบ\r\nประมาณ\r\nทั้งสิ้น", "หมายเหตุ"])
    this.worksheet.addRow(["", "", "", "", "ป.ตรี", "ป.โท", "อื่นๆ", "รวม", "ป.ตรี", "ป.โท", "อื่นๆ", "รวม", "สวัสดิการ", "ค่าครอง\r\nชีพพิเศษ", "ค่า\r\nสวัสดิการ\r\nพื้นที่ \r\n3 จังหวัด\r\nชายแดน\r\nภาคใต้", "ค่าเบี้ย\r\nเสี่ยงภัย\r\n3 จังหวัด\r\nชายแดน\r\nภาคใต้", "อื่นๆ", "รวม"])
    this.worksheet.mergeCells("A5:A6")
    this.worksheet.mergeCells("B5:B6")
    this.worksheet.mergeCells("C5:C6")
    this.worksheet.mergeCells("D5:D6")
    this.worksheet.mergeCells("E5:H5")
    this.worksheet.mergeCells("I5:L5")
    this.worksheet.mergeCells("M5:R5")
    this.worksheet.mergeCells("S5:S6")
    this.worksheet.mergeCells("T5:T6")//note
    // this.worksheet.mergeCells("I5:L5")
    // this.worksheet.mergeCells("M5:M6")

    this.worksheet.getColumn("A").width = 5
    this.worksheet.getColumn("B").width = 75
    this.worksheet.getColumn("C").width = 25
    this.worksheet.getColumn("D").width = 17
    this.worksheet.getColumn("E").width = 17
    this.worksheet.getColumn("F").width = 17
    this.worksheet.getColumn("G").width = 17
    this.worksheet.getColumn("H").width = 17
    this.worksheet.getColumn("I").width = 17
    this.worksheet.getColumn("J").width = 17
    this.worksheet.getColumn("K").width = 17
    this.worksheet.getColumn("L").width = 17
    this.worksheet.getColumn("M").width = 17
    this.worksheet.getColumn("N").width = 17
    this.worksheet.getColumn("O").width = 17
    this.worksheet.getColumn("P").width = 17
    this.worksheet.getColumn("Q").width = 17
    this.worksheet.getColumn("R").width = 17
    this.worksheet.getColumn("S").width = 17
    this.worksheet.getColumn("T").width = 17

    doc.items.forEach((el, i) => {
      this.worksheet.addRow([
        i + 1,
        el.projectCode + " " + el.projectName,
        el.sectorName,
        el.budgetSource,
        el.bachelor,
        el.master,
        el.other,
        el.sumQty,
        el.bachelorAmount,
        el.masterAmount,
        el.otherAmount,
        el.sumAmount,
        el.otherObject.benefit,
        el.otherObject.specialLiving,
        el.otherObject.threeProvinceBanefit,
        el.otherObject.threeProvinceRisk,
        el.otherObject.other,
        el.otherObject.total,
        el.finalTotal,
        ""
      ])
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn <= 5) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
          }
        })
      }
      if (rn > 4) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (rn >= 8 && cn >= 5 && cn <= 12) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right'
            }
            cell.numFmt = '#,##0;[Red]-#,##0;-';
          }
        })
      }
      if (rn >= 5 && rn <= 6) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDCE6F1' }
          }
        })
      }
    })
    this.worksheet.addRow(["", "", "", "รวม", doc.sumBachelor, doc.sumMaster, doc.sumOther, doc.sumTotalQty, doc.sumBachelorAmount, doc.sumMasterAmount, doc.sumOtherAmount, doc.sumTotalAmount, doc.sumtotalInOtherBenefit, doc.sumtotalInOtherSpecialLiving, doc.sumtotalInOtherThreeProvinceBanefit, doc.sumtotalInOtherThreeProvinceRisk, doc.sumtotalInOtherOther, doc.sumtotalInOtherTotal, doc.sumFinalTotal])//doc.sum
    let row = this.worksheet.rowCount
    let lastRow = this.worksheet.getRow(row)
    lastRow.eachCell((cell, cn) => {
      if (cn >= 5 && cn <= 19) {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cell.numFmt = '#,##0;[Red]-#,##0;-';
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA08.xlsx");
    });
  }
  createA08Type2(doc: ReportA08Type2Model, budgetYear: Number, expenseTypes: string) {
    let data: Array<any> = []
    this.worksheet.addRow([`รายงานค่าใช้จ่ายในประเภทต่างๆ (${expenseTypes})`])
    this.worksheet.addRow([`ประจำปีงบประมาณ พ.ศ. ${budgetYear}`])
    this.worksheet.addRow([""])
    this.worksheet.mergeCells('A1:I1')
    this.worksheet.mergeCells('A2:I2')
    this.worksheet.mergeCells('A3:I3')
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }

    this.worksheet.addRow(["ลำดับ", "รายการที่", "ฝ่ายและสำนักที่รับผิดชอบ", "แหล่งงบประมาณ", "ข้อการดำเนินงานหลัก/ย่อย", "ผลผลิตและค่าเป้าหมาย", "จำนวนงบประมาณ", "", "ส่วนต่าง"])
    this.worksheet.addRow(["", "", "", "", "", "", "แผนตั้งต้น", "แผนอนุมัติล่าสุด"])
    this.worksheet.mergeCells("A5:A6")
    this.worksheet.mergeCells("B5:B6")
    this.worksheet.mergeCells("C5:C6")
    this.worksheet.mergeCells("D5:D6")
    this.worksheet.mergeCells("E5:E6")
    this.worksheet.mergeCells("F5:F6")
    this.worksheet.mergeCells("G4:H4")
    this.worksheet.mergeCells("I5:I6")
    //this.worksheet.mergeCells("G5:H5")
    // this.worksheet.mergeCells("I5:L5")
    // this.worksheet.mergeCells("M5:M6")
   
    this.worksheet.getColumn("A").width = 5
    this.worksheet.getColumn("B").width = 50
    this.worksheet.getColumn("C").width = 25
    this.worksheet.getColumn("D").width = 17
    this.worksheet.getColumn("E").width = 75
    this.worksheet.getColumn("F").width = 50
    this.worksheet.getColumn("G").width = 17
    this.worksheet.getColumn("H").width = 17
    this.worksheet.getColumn("I").width = 17
    this.worksheet.getColumn("J").width = 17
    this.worksheet.getColumn("K").width = 17
    this.worksheet.getColumn("L").width = 17
    doc.items.forEach((el, i) => {
      this.worksheet.addRow([
        i + 1,
        el.projectCode + " " + el.projectName,
        el.sectorName,
        el.budgetTypeName,
        el.budgetNumber + " " + el.budgetName,
        el.budgetGoals,
        el.budgetBefore,
        el.budgetAfter,
        el.diff
      ])
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn <= 4) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
          }
        })
      }
      if (rn >= 4) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (cn == 1) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center'
            }
          }
          if(cn == 2){
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
              wrapText: true
            }
          }
          if (cn >= 3 && cn <= 8) {
       
            if (rn == 5 || rn == 6) {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center',
                wrapText: true
              }
            } else {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'left',
                wrapText: true
              }
            }
            cell.numFmt = '#,##0;[Red]-#,##0;-';
          }
          if (cn >= 9 && cn <= 12) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
              wrapText: true
            }
            cell.numFmt = '#,##0.00;[Red]-#,##0.00;-';
          }
        })
      }
      if (rn >= 4 && rn <= 6) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDCE6F1' }
          }
        })
      }
    })
    this.worksheet.addRow(["", "", "", "", "", "รวม", doc.sumBefore, doc.sumAfter, doc.sumDiff])
    let row = this.worksheet.rowCount
    let lastRow = this.worksheet.getRow(row)
    lastRow.eachCell((cell, cn) => {
      if (cn >= 7 && cn <= 9) {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        cell.numFmt = '#,##0;[Red]-#,##0;-';
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["", (".").repeat(60) + "ลงชื่อ"])
    this.worksheet.addRow(["", "        ชาญยุทธ คำหวาน       "])

    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ReportA08.xlsx");
    });
  }
  async createPT(id: string, project_id: string) {

    let sumQ1 = (item: any) => {
      if (item != undefined) {
        let m1 = item[0]
        let m2 = item[1]
        let m3 = item[2]
        let result = m1 + m2 + m3
        return result
      }
      else {
        return 0
      }


    }
    let sumQ2 = (item: any) => {
      if (item != undefined) {
        let m1 = item[3]
        let m2 = item[4]
        let m3 = item[5]
        let result = m1 + m2 + m3
        return result
      }
      else {
        return 0
      }

    }
    let sumQ3 = (item: any) => {
      if (item != undefined) {
        let m1 = item[6]
        let m2 = item[7]
        let m3 = item[8]
        let result = m1 + m2 + m3
        return result
      }
      else {
        return 0
      }

    }
    let sumQ4 = (item: any) => {
      if (item != undefined) {
        let m1 = item[9]
        let m2 = item[10]
        let m3 = item[11]
        let result = m1 + m2 + m3
        return result
      }
      else {
        return 0
      }

    }
    let dataSingle: ActivePetitionSingleClass = Container.get(
      ActivePetitionSingleClass);
    let projectActiveData: ActiveProjectSingleClass = Container.get(
      ActiveProjectSingleClass);
    let petitionData: ActiveProjectForPetitionClass = Container.get(
      ActiveProjectForPetitionClass);
    await projectActiveData.getProjectSingle(project_id);
    await dataSingle.getPetitionSingle(id);
    await petitionData.getForEditPetition(project_id, dataSingle.data!.fromRevision, dataSingle.data!.toRevision)

    console.log({ id, project_id });
    console.log({ petitionData })

    console.log(dataSingle, petitionData, projectActiveData, "DDD")


    //headerหน่วยงาน / ส่วนราชการเจ้าของงบประมาณ  
    this.worksheet.getCell("A1").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A1").value = `แผนการเบิกจ่ายเงินงบประมาณงบเงินอุดหนุน  ประเภทเงินอุดหนุนทั่วไป พ.ศ.  ${projectActiveData.data!.budgetYear}`
    this.worksheet.mergeCells('A1:M1')
    //
    this.worksheet.getCell("A2").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A2").value = `หน่วยงาน/ส่วนราชการเจ้าของงบประมาณ`
    this.worksheet.mergeCells('A2:C2')
    this.worksheet.getCell("D2").alignment = { vertical: 'middle' }
    this.worksheet.getCell("D2").value = `การกีฬาแห่งประเทศไทย`
    this.worksheet.mergeCells('D2:F2')
    this.worksheet.getCell("G2").alignment = { vertical: 'middle' }
    this.worksheet.getCell("G2").value = `องค์กรที่ได้รับเงินอุดหนุน`
    this.worksheet.mergeCells('G2:H2')
    this.worksheet.getCell("I2").alignment = { vertical: 'middle' }
    this.worksheet.getCell("I2").value = `การกีฬาแห่งประเทศไทย`
    this.worksheet.mergeCells('I2:K2')
    //
    this.worksheet.getCell("A3").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A3").value = `รหัสหน่วยงาน`
    this.worksheet.getCell("B3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B3").value = `50604`
    this.worksheet.getCell("C3").alignment = { vertical: 'middle' }
    this.worksheet.getCell("C3").value = `ลักษณะงาน`
    this.worksheet.getCell("D3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D3").value = `081011`
    this.worksheet.getCell("E3").alignment = { vertical: 'middle' }
    this.worksheet.getCell("E3").value = `ประเภทผลผลิต/ โครงการ`
    this.worksheet.mergeCells('E3:F3')
    this.worksheet.getCell("G3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G3").value = `1`
    this.worksheet.getCell("H3").alignment = { vertical: 'middle', horizontal: 'right' }
    this.worksheet.getCell("H3").value = `บัญชี`
    this.worksheet.getCell("I3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I3").value = `${dataSingle.data!.accountCode}`
    // this.worksheet.getCell("J3").alignment = { vertical: 'middle', horizontal: 'center' }
    // this.worksheet.getCell("J3").value = `งบเงินอุดหนุน`
    //
    this.worksheet.getCell("A4").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A4").value = `แผนงบประมาณ`
    this.worksheet.getCell("B4").alignment = { vertical: 'middle' }
    this.worksheet.getCell("B4").value = `${dataSingle.data!.planName}`
    this.worksheet.mergeCells('B4:M4')
    //
    this.worksheet.getCell("A5").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A5").value = `ผลผลิต/โครงการ`
    this.worksheet.getCell("B5").alignment = { vertical: 'middle' }
    this.worksheet.getCell("B5").value = `${dataSingle.data!.product}`
    // this.worksheet.getCell("C5").alignment = { vertical: 'middle' }
    // this.worksheet.getCell("C5").value = `การบริหารจัดการกีฬาเพื่อความเป็นเลิศ`
    this.worksheet.mergeCells('B5:M5')
    //
    this.worksheet.getCell("A6").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A6").value = `กิจกรรมที่ ${dataSingle.data!.activityNumber}`
    this.worksheet.getCell("B6").alignment = { vertical: 'middle' }
    this.worksheet.getCell("B6").value = `${dataSingle.data!.activityName}`
    this.worksheet.mergeCells('B6:H6')
    this.worksheet.getCell("I6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I6").value = `รหัสงบประมาณ`
    this.worksheet.getCell("J6").alignment = { vertical: 'middle' }
    this.worksheet.getCell("J6").value = `${dataSingle.data!.budgetCode}`
    this.worksheet.getCell("L6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L6").value = `รหัสผลผลิต`
    this.worksheet.getCell("M6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M6").value = `${dataSingle.data!.productCode}`
    //
    this.worksheet.getCell("A7").alignment = { vertical: 'middle' }
    this.worksheet.getCell("A7").value = `รายการที่ ${projectActiveData.data?.code}`
    this.worksheet.getCell("B7").alignment = { vertical: 'middle' }
    this.worksheet.getCell("B7").value = `${projectActiveData.data?.name}`
    this.worksheet.mergeCells('B7:H7')
    this.worksheet.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I7").value = `รหัสกิจกรรมหลัก`
    this.worksheet.getCell("J7").alignment = { vertical: 'middle' }
    this.worksheet.getCell("J7").value = `${dataSingle.data!.activityMainCode}`
    this.worksheet.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L7").value = `รหัสบัญชีย่อย`
    this.worksheet.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M7").value = `${dataSingle.data!.accountSubCode}`
    //
    this.worksheet.addRow([""])
    this.worksheet.getCell("A9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A9").value = `วงเงินงบประมาณ`
    this.worksheet.mergeCells('A9:A10')
    this.worksheet.getCell("B9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B9").value = `ไตรมาสที่ 1`
    this.worksheet.mergeCells('B9:D9')
    this.worksheet.getCell("E9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E9").value = `ไตรมาสที่ 2`
    this.worksheet.mergeCells('E9:G9')
    this.worksheet.getCell("H9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H9").value = `ไตรมาสที่ 3`
    this.worksheet.mergeCells('H9:J9')
    this.worksheet.getCell("K9").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K9").value = `ไตรมาสที่ 4`
    this.worksheet.mergeCells('K9:M9')
    this.worksheet.getCell("B10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B10").value = `ตุลาคม ${projectActiveData.data!.budgetYear - 1}`
    this.worksheet.getCell("C10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C10").value = `พฤศจิกายน ${projectActiveData.data!.budgetYear - 1}`
    this.worksheet.getCell("D10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D10").value = `ธันวาคม ${projectActiveData.data!.budgetYear - 1}`
    this.worksheet.getCell("E10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E10").value = `มกราคม ${projectActiveData.data!.budgetYear}`
    this.worksheet.getCell("F10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F10").value = `กุมภาพันธ์ ${projectActiveData.data!.budgetYear}`
    this.worksheet.getCell("G10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G10").value = `มีนาคม ${projectActiveData.data!.budgetYear}`
    this.worksheet.getCell("H10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H10").value = `เมษายน ${projectActiveData.data!.budgetYear}`
    this.worksheet.getCell("I10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I10").value = `พฤษภาคม ${projectActiveData.data!.budgetYear}`
    this.worksheet.getCell("J10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("J10").value = `มิถุนายน ${projectActiveData.data!.budgetYear}`
    this.worksheet.getCell("K10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K10").value = `กรกฎาคม ${projectActiveData.data!.budgetYear}`
    this.worksheet.getCell("L10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L10").value = `สิงหาคม ${projectActiveData.data!.budgetYear}`
    this.worksheet.getCell("M10").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M10").value = `กันยายน ${projectActiveData.data!.budgetYear}`
    //width
    this.worksheet.getColumn("A").width = 18
    this.worksheet.getColumn("B").width = 20
    this.worksheet.getColumn("C").width = 16
    this.worksheet.getColumn("D").width = 16
    this.worksheet.getColumn("E").width = 16
    this.worksheet.getColumn("F").width = 16
    this.worksheet.getColumn("G").width = 16
    this.worksheet.getColumn("H").width = 16
    this.worksheet.getColumn("I").width = 16
    this.worksheet.getColumn("J").width = 16
    this.worksheet.getColumn("K").width = 16
    this.worksheet.getColumn("L").width = 16
    this.worksheet.getColumn("M").width = 16
    this.worksheet.addRow([petitionData.petition!.data!.before_months.reduce((a: number, b: number) => a + b, 0),
    dataSingle.data!.quarter >= 1 ? sumQ1(petitionData.petition!.data!.after_months) : null,
      null,
      null,
    dataSingle.data!.quarter >= 2 ? sumQ2(petitionData.petition!.data!.after_months) : null,
      null,
      null,
    dataSingle.data!.quarter >= 3 ? sumQ3(petitionData.petition!.data!.after_months) : null,
      null,
      null,
    dataSingle.data!.quarter >= 4 ? sumQ4(petitionData.petition!.data!.after_months) : null,
      null,
      null])
    this.worksheet.addRow(['',
      dataSingle.data!.quarter >= 1 ? sumQ1(petitionData.petition!.data!.before_months) : null,
      null,
      null,
      dataSingle.data!.quarter >= 2 ? sumQ2(petitionData.petition!.data!.before_months) : null,
      null,
      null,
      dataSingle.data!.quarter >= 3 ? sumQ3(petitionData.petition!.data!.before_months) : null,
      null,
      null,
      dataSingle.data!.quarter >= 4 ? sumQ4(petitionData.petition!.data!.before_months) : null,
      null,
      null])

    this.worksheet.addRow([])
    let lastRow2 = this.worksheet.rowCount
    this.worksheet.addRow([`หมายเหตุ`, dataSingle.data!.notes, "", "", "", "", "", "", "", "", "", "", ""])

    this.worksheet.mergeCells(lastRow2 + 1, 2, lastRow2 + 1, 13)
    this.worksheet.mergeCells(lastRow2 + 2, 2, lastRow2 + 2, 13)
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    if (dataSingle.data!.quarter === 1) {
      this.worksheet.addRow(['', '', '', '', '', ''])
      this.worksheet.addRow(["", "", "", "", "", "", "", "", "", `ลงชื่อ` + (".").repeat(60), "", "", ""])
      this.worksheet.addRow(["", "", "", "", "", "", "", "", "", "(ชาญยุทธ คำหวาน)", "", "", ""])
      this.worksheet.addRow(["", "", "", "", "", "", "", "", "", "หัวหน้างานพัฒนาและประสานแผน", "", "", ""])
    } else if (dataSingle.data!.quarter === 2) {
      this.worksheet.addRow(['', 'รายการ', 'ไตรมาสที่ 1', 'ไตรมาสที่ 2', 'รวม'])
      this.worksheet.addRow(["", "หน้าแผน", sumQ1(petitionData.petition!.data!.before_months), sumQ2(petitionData.petition!.data!.before_months),
        sumQ1(petitionData.petition!.data!.before_months) + sumQ2(petitionData.petition!.data!.before_months)
        , "", "", "", `ลงชื่อ` + (".").repeat(60), "", "", ""])
      this.worksheet.addRow(["", "วางฎีกา", sumQ1(petitionData.petition!.data!.after_months), null,
        sumQ1(petitionData.petition!.data!.after_months),
        , "", "", "", "(ชาญยุทธ คำหวาน)", "", "", ""])
      this.worksheet.addRow(["", "วางฎีกาในไตรมาสที่ 2", "", "",
        (sumQ1(petitionData.petition!.data!.before_months) + sumQ2(petitionData.petition!.data!.before_months)) - sumQ1(petitionData.petition!.data!.after_months),
        , "", "", "", "หัวหน้างานพัฒนาและประสานแผน", "", "", ""])
    } else if (dataSingle.data!.quarter === 3) {

      this.worksheet.addRow(['', 'รายการ', 'ไตรมาสที่ 1', 'ไตรมาสที่ 2', 'ไตรมาสที่ 3', 'รวม'])
      this.worksheet.addRow(["", "หน้าแผน", sumQ1(petitionData.petition!.data!.before_months), sumQ2(petitionData.petition!.data!.before_months), sumQ3(petitionData.petition!.data!.before_months),
        sumQ1(petitionData.petition!.data!.before_months) + sumQ2(petitionData.petition!.data!.before_months) + sumQ3(petitionData.petition!.data!.before_months)
        , "", "", "", `ลงชื่อ` + (".").repeat(60), "", "", ""])
      this.worksheet.addRow(["", "วางฎีกา", sumQ1(petitionData.petition!.data!.after_months), sumQ2(petitionData.petition!.data!.after_months), null,
        sumQ1(petitionData.petition!.data!.after_months) + sumQ2(petitionData.petition!.data!.after_months),
        , "", "", "(ชาญยุทธ คำหวาน)", "", "", ""])
      this.worksheet.addRow(["", "วางฎีกาในไตรมาสที่ 3", "", "", "",
        (sumQ1(petitionData.petition!.data!.before_months) + sumQ2(petitionData.petition!.data!.before_months) + sumQ3(petitionData.petition!.data!.before_months))
        -
        (sumQ1(petitionData.petition!.data!.after_months) + sumQ2(petitionData.petition!.data!.after_months)),
        , "", "", "หัวหน้างานพัฒนาและประสานแผน", "", "", ""])
    } else if (dataSingle.data!.quarter === 4) {
      this.worksheet.addRow(['', 'รายการ', 'ไตรมาสที่ 1', 'ไตรมาสที่ 2', 'ไตรมาสที่ 3', 'ไตรมาสที่ 4', 'รวม'])
      this.worksheet.addRow(["", "หน้าแผน", sumQ1(petitionData.petition!.data!.before_months), sumQ2(petitionData.petition!.data!.before_months), sumQ3(petitionData.petition!.data!.before_months), sumQ4(petitionData.petition!.data!.before_months),
        sumQ1(petitionData.petition!.data!.before_months) + sumQ2(petitionData.petition!.data!.before_months) + sumQ3(petitionData.petition!.data!.before_months) + sumQ4(petitionData.petition!.data!.before_months)
        , "", "", `ลงชื่อ` + (".").repeat(60), "", "", ""])
      this.worksheet.addRow(["", "วางฎีกา", sumQ1(petitionData.petition!.data!.after_months), sumQ2(petitionData.petition!.data!.after_months), sumQ3(petitionData.petition!.data!.after_months), null,
        sumQ1(petitionData.petition!.data!.after_months) + sumQ2(petitionData.petition!.data!.after_months) + sumQ3(petitionData.petition!.data!.after_months),
        , "", "(ชาญยุทธ คำหวาน)", "", "", ""])
      this.worksheet.addRow(["", "วางฎีกาในไตรมาสที่ 4", "", "", "", "",
        (sumQ1(petitionData.petition!.data!.before_months) + sumQ2(petitionData.petition!.data!.before_months) + sumQ3(petitionData.petition!.data!.before_months) + sumQ4(petitionData.petition!.data!.after_months))
        -
        (sumQ1(petitionData.petition!.data!.after_months) + sumQ2(petitionData.petition!.data!.after_months) + sumQ3(petitionData.petition!.data!.after_months)),
        , "", "หัวหน้างานพัฒนาและประสานแผน", "", "", ""])
      // this.worksheet.addRow(["", "หน้าแผน", "", "", "", "", "", "", "", `ลงชื่อ` + (".").repeat(60), "", "", ""])
      // this.worksheet.addRow(["", "วางฎีกาในไตรมาสที่ 2", "", "", "", "", "", "", "", "(ชาญยุทธ คำหวาน)", "", "", ""])
      // this.worksheet.addRow(["", "", "", "", "", "", "", "", "", "หัวหน้างานพัฒนาและประสานแผน", "", "", ""])
    }
    this.worksheet.mergeCells(lastRow2 + 7, 10, lastRow2 + 7, 12)
    this.worksheet.mergeCells(lastRow2 + 8, 10, lastRow2 + 8, 12)
    this.worksheet.mergeCells(lastRow2 + 9, 10, lastRow2 + 9, 12)

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 8 && rn < 12) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ฎีกา.xlsx");
    });


  }
  createOverlapDetail(docs: Array<any>, budgetYear: number) {

    let totalBudget = () => {
      try {
        return docs
          .map((item) => item.overlapBudgetAmount ?? 0)
          .reduce((a, b) => a + b, 0);
      } catch (error) {
        return 0;
      }
    }
    let totalOverlap = () => {
      try {
        return docs
          .map((item) => item.overlapRemainingAmount ?? 0)
          .reduce((a, b) => a + b, 0);
      } catch (error) {
        return 0;
      }
    }
    let grandTotal = () => {
      try {
        return totalBudget() + totalOverlap()
      } catch (error) {
        return 0;
      }
    }
    let count = () => {
      try {
        return docs.length
      } catch (error) {
        return 0;
      }
    }

    let incrementLetter = (current: string) => {
      return ((parseInt(current, 36) + 1).toString(36)).replace(/0/g, 'a')
    }
    this.worksheet.addRow(["รายการเงินอุดหนุนที่ขอกันเงินไว้เบิกเหลื่อมปี ประจำปีงบประมาณ พ.ศ. " + budgetYear])
    this.worksheet.addRow([`รายการกันเงินไว้เบิกเหลื่อมปีที่มีสัญญาจ้างเรียบร้อยแล้ว จำนวน ${count()} รายการกันเงินไว้เบิกเหลื่อมปีที่มีสัญญาจ้างเรียบร้อยแล้ว`])
    this.worksheet.addRow([`วงเงิน ${totalOverlap().formatFull()} บาท`])
    this.worksheet.mergeCells("A1:K1")
    this.worksheet.mergeCells("A2:K2")
    this.worksheet.mergeCells("A3:K3")
    this.worksheet.getColumn("B").width = 30
    this.worksheet.getColumn("D").width = 50
    this.worksheet.getColumn("J").width = 30
    this.worksheet.getColumn("K").width = 40
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.addRow(["ที่", "รายการงบประมาณ", "ฝ่ายและสำนัก", "การดำเนินงาน/กิจกรรม", "งบประมาณ", null, "สัญญา/ใบสั่ง", null, null, "รายละเอียดเช่นงวดที่/จำนวนเงิน/วันที่รับของ", "เหตุผลการขอกันเงินไว้เบิกเหลื่อมปี"])
    this.worksheet.addRow(["ที่", "รายการงบประมาณ", "ฝ่ายและสำนัก", "การดำเนินงาน/กิจกรรม", "วงเงินตามสัญญาจ้าง", "คงเหลือกันเงินเหลื่อมปี", "เลขที่สัญญา/ใบสั่ง", "เริ่มสัญญา", "สิ้นสุดสัญญา", "รายละเอียดเช่นงวดที่/จำนวนเงิน/วันที่รับของ", "เหตุผลการขอกันเงินไว้เบิกเหลื่อมปี"])
    let ltr = "A"
    for (let i = 0; i < 11; i++) {
      this.worksheet.getCell(`${ltr}4`).alignment = { vertical: 'middle', horizontal: 'center' }
      this.worksheet.getCell(`${ltr}5`).alignment = { vertical: 'middle', horizontal: 'center' }
      ltr = incrementLetter(ltr).toUpperCase()
      console.log({ ltr })
    }
    this.worksheet.mergeCells("A4:A5")
    this.worksheet.mergeCells("B4:B5")
    this.worksheet.mergeCells("C4:C5")
    this.worksheet.mergeCells("D4:D5")
    this.worksheet.mergeCells("E4:F4")
    this.worksheet.mergeCells("G4:I4")
    this.worksheet.mergeCells("J4:J5")
    this.worksheet.mergeCells("K4:K5")
    docs.forEach((item, i) => {
      this.worksheet.addRow([i + 1,
      item.projectName,
      item.sectorName,
      item.code + " " + item.name,
      item.overlapBudgetAmount,
      item.overlapRemainingAmount,
      item.overlapContractNumber,
      new Date(item.overlapStartDate).toThaiShort(),
      new Date(item.overlapEndDate).toThaiShort(),
      item.overlapDescription,
      item.overlapReason
      ])
    })
    this.worksheet.addRow([null, null, null, "รวม", totalBudget(), totalOverlap()])

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 0) {
        row.eachCell({ includeEmpty: false }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "รายละเอียดกันเหลื่อม.xlsx");
    });
  }
  createOverlapForm(docs: Array<any>, budgetYear: number) {

    let grandTotal = () => {
      try {
        let filtered = docs
        let mapped = filtered.map(
          (item) =>
            (item.overlapBudgetAmount ?? 0) + (item.overlapRemainingAmount ?? 0)
        );
        let reduced = mapped.reduce((a, b) => a + b, 0);
        return {
          count: filtered.length,
          amount: reduced,
        };
      } catch (error) { }
      return {
        count: 0,
        amount: 0,
      };
    }
    let uniqueBudgetTypeCategory = () => {
      try {
        let results = _.uniq(
          docs.map((item) => item.budgetTypeCategory)
        );
        results = results.sort((a, b) => a.localeCompare(b));
        results.reverse();
        return results;
      } catch (error) {
        return [];
      }
    }
    let uniqueBudgetTypeName = () => {
      try {
        let results = _.uniq(
          docs.map((item) => item.budgetTypeName)
        );
        results = results.sort((a, b) => a.localeCompare(b));
        results.reverse();
        return results;
      } catch (error) {
        return [];
      }
    }

    let getByName = (name: string) => {
      try {
        let filtered = docs.filter(
          (item) => item.budgetTypeName === name
        );
        let mapped = filtered.map(
          (item) =>
            (item.overlapBudgetAmount ?? 0) + (item.overlapRemainingAmount ?? 0)
        );
        let reduced = mapped.reduce((a, b) => a + b, 0);
        return {
          count: filtered.length,
          amount: reduced,
        };
      } catch (error) { }
      return {
        count: 0,
        amount: 0,
      };
    }

    let getByNameCategory = (name: string, category: string) => {
      try {
        let filtered = docs.filter(
          (item) =>
            item.budgetTypeName === name && item.budgetTypeCategory === category
        );
        let mapped = filtered.map(
          (item) =>
            (item.overlapBudgetAmount ?? 0) + (item.overlapRemainingAmount ?? 0)
        );
        let reduced = mapped.reduce((a, b) => a + b, 0);
        return {
          count: filtered.length,
          amount: reduced,
        };
      } catch (error) { }
      return {
        count: 0,
        amount: 0,
      };
    }

    let getByNameCategoryOverlap = (name: string, category: string, overlap: string) => {
      try {
        let filtered = docs.filter(
          (item) =>
            item.budgetTypeName === name &&
            item.budgetTypeCategory === category &&
            item.overlapType === overlap
        );
        let mapped = filtered.map(
          (item) =>
            (item.overlapBudgetAmount ?? 0) + (item.overlapRemainingAmount ?? 0)
        );
        let reduced = mapped.reduce((a, b) => a + b, 0);
        return {
          count: filtered.length,
          amount: reduced,
        };
      } catch (error) { }
      return {
        count: 0,
        amount: 0,
      };
    }
    this.worksheet.addRow(["รายการเงินอุดหนุนที่ขอกันเงินไว้เบิกเหลื่อมปี ประจำปีงบประมาณ พ.ศ. " + budgetYear])

    this.worksheet.mergeCells("A1:E1")
    this.worksheet.addRow(["ลำดับ", "หมวดงบประมาณ", "จำนวนรายการ", "จำนวนเงิน", "หน้า"])
    let firstRow = this.worksheet.addRow(["", "รวมเป็นจำนวนทั้งสิ้น", grandTotal().count, grandTotal().amount, ""])

    firstRow.eachCell(cell => {
      cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFd4ddef' } } }
    })

    this.worksheet.getColumn("A").width = 5
    this.worksheet.getColumn("B").width = 30
    this.worksheet.getColumn("C").width = 10
    this.worksheet.getColumn("D").width = 10
    uniqueBudgetTypeName().forEach((name, i) => {
      let row = this.worksheet.addRow([i + 1, name, getByName(name).count, getByName(name).amount, ""])
      row.eachCell(cell => {
        cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF91bbe0' } } }
      })
      uniqueBudgetTypeCategory().forEach((category, j) => {
        let row1 = this.worksheet.addRow(["", `${i + 1}.${j + 1}) ` + category, getByNameCategory(name, category).count, getByNameCategory(name, category).amount, ""])
        let row2 = this.worksheet.addRow(["", "  รายการที่มีสัญญาจ้างเรียบร้อยแล้ว", getByNameCategoryOverlap(name, category, "รายการที่มีสัญญาจ้างเรียบร้อยแล้ว").count, getByNameCategoryOverlap(name, category, "รายการที่มีสัญญาจ้างเรียบร้อยแล้ว").amount, ""])
        let row3 = this.worksheet.addRow(["", "  รายการที่อยู่ระหว่างกระบวนการจัดซื้อจัดจ้าง", getByNameCategoryOverlap(name, category, "รายการที่อยู่ระหว่างกระบวนการจัดซื้อจัดจ้าง").count, getByNameCategoryOverlap(name, category, "รายการที่อยู่ระหว่างกระบวนการจัดซื้อจัดจ้าง").amount, ""])
        row1.eachCell(cell => {
          cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFd4ddef' } } }
        })
      });
    });
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn > 0) {
        row.eachCell({ includeEmpty: false }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        })
      }
    })
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "สารบัญกันเหลื่อม.xlsx");
    });
  }
  async createAdjustTable(doc: any,sumBefore:any,sumAfter:any) {
    //header
    this.worksheet.addRow([`แบบเสนอปรับ${doc.data!.name}`])
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A1").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.mergeCells('A1:F1')
    this.worksheet.addRow([`รายการที่ ${doc.data!.projectName}`])
    this.worksheet.getCell("A2").alignment = { vertical: 'middle', horizontal: 'left' }
    this.worksheet.getCell("A2").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.mergeCells('A2:F2')
    this.worksheet.getCell("A3").alignment = { vertical: 'middle', horizontal: 'left' }
    this.worksheet.getCell("A3").value = `${doc.data!.sectorName}`
    this.worksheet.getCell("A3").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.mergeCells('A3:F3')
    this.worksheet.getCell("A4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A4").value = `ที่`
    this.worksheet.mergeCells('A4:A5')
    this.worksheet.getCell("A4").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.getCell("B4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B4").value = `แผนเดิม`
    this.worksheet.getCell("B4").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.mergeCells('B4:C4')
    this.worksheet.getCell("B5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("B5").value = `(ข้อ) การดำเนินงาน`
    this.worksheet.getCell("B5").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.getCell("C5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("C5").value = `จำนวนเงิน (บาท)`
    this.worksheet.getCell("C5").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.getCell("D4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D4").value = `แผนที่ปรับ`
    this.worksheet.mergeCells('D4:E4')
    this.worksheet.getCell("D4").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.getCell("D5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D5").value = `(ข้อ) การดำเนินงาน`
    this.worksheet.getCell("D5").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.getCell("E5").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E5").value = `จำนวนเงิน (บาท)`
    this.worksheet.getCell("E5").font = { size: 16,bold:true,name:"TH SarabunPSK" };
    this.worksheet.getCell("F4").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F4").value = `เหตุผลประกอบการปรับแผน`
    this.worksheet.mergeCells('F4:F5')
    this.worksheet.getCell("F4").font = { size: 16,bold:true,name:"TH SarabunPSK" };

    //width
    this.worksheet.getColumn("A").width = 10
    this.worksheet.getColumn("B").width = 50
    this.worksheet.getColumn("C").width = 25
    this.worksheet.getColumn("D").width = 50
    this.worksheet.getColumn("E").width = 25
    this.worksheet.getColumn("F").width = 50
    this.worksheet.getColumn("G").width = 20
    this.worksheet.getColumn("H").width = 20
    this.worksheet.getColumn("I").width = 20
    let data: Array<Array<any>> = [];
    doc.data!.budgets.forEach((el: any, i: number) => {
     
      let startMonth = null;
      let endMonth = null;
      let rangeBefore =""
     
      for (let i = 0; i < (doc.findBudgetFromFlatted(el, 'before')).items.length; i++) {
        const month = (doc.findBudgetFromFlatted(el, 'before')).items[i];
        if (month.isActive) {
          if (startMonth === null) {
            startMonth = month.month;
          }
          endMonth = month.month;
        }
      }
      if (startMonth !== null && endMonth !== null) {
        rangeBefore = `${this.getMonth(startMonth)} - ${this.getMonth(endMonth)}`;
      } else {
        console.log("No active months found.");
      }
      let startMonthAfter = null;
      let endMonthAfter = null;
      let rangeAfter =""
     
      for (let i = 0; i < (doc.findBudgetFromFlatted(el, 'after')).items.length; i++) {
        const month = (doc.findBudgetFromFlatted(el, 'after')).items[i];
        if (month.isActive) {
          if (startMonthAfter === null) {
            startMonthAfter = month.month;
          }
          endMonthAfter = month.month;
        }
      }
      if (startMonthAfter !== null && endMonthAfter !== null) {
        rangeAfter = `${this.getMonth(startMonthAfter)} - ${this.getMonth(endMonthAfter)}`;
      } else {
        console.log("No active months found.");
      }
      let start = this.worksheet.rowCount + 1
      let code1AdjustBF = ""
      let code2AdjustBF = ""
      let code3AdjustBF = ""
      let code1AdjustAT = ""
      let code2AdjustAT = ""
      let code3AdjustAT = ""
  
      if(el.level1_code!=""){
        code1AdjustBF = `ข้อการดำเนินการหลักที่ ${doc.getBeforeLevel1(el.level1_code)?.map((el: any) => el.number ?? "")} ${doc.getBeforeLevel1(el.level1_code)?.map((el: any) => el.name ?? "")}`
        //doc.getBeforeLevel1(item.level1_code)?.map((el:any)=>el.number)}}  {{dataSingle.getBeforeLevel1(item.level1_code)?.map((el:any)=>el.name??0)
        code1AdjustAT = `ข้อการดำเนินการหลักที่ ${doc.getAfterLevel1(el.level1_code)?.map((el: any) => el.number ?? "")} ${doc.getAfterLevel1(el.level1_code)?.map((el: any) => el.name ?? "")}`
      }
      if(el.level2_code!=""){
        code2AdjustBF = `ข้อการดำเนินการรองที่ ${doc.getBeforeLevel2ForDocument(el.level1_code,el.level2_code)?.number ?? ""} ${doc.getBeforeLevel2ForDocument(el.level1_code,el.level2_code)?.name ?? ""}`
        //doc.getBeforeLevel1(item.level1_code)?.map((el:any)=>el.number)}}  {{dataSingle.getBeforeLevel1(item.level1_code)?.map((el:any)=>el.name??0)
        code2AdjustAT =  `ข้อการดำเนินการรองที่ ${doc.getAfterLevel2ForDocument(el.level1_code,el.level2_code)?.number ?? ""} ${doc.getAfterLevel2ForDocument(el.level1_code,el.level2_code)?.name ?? ""}`
      }
      if(el.level3_code!=""){
        code3AdjustBF = `ข้อการดำเนินการย่อยที่ ${doc.getBeforeLevel3ForDocument(el.level1_code,el.level2_code, el.level3_code)?.number ?? ""} ${doc.getBeforeLevel3ForDocument(el.level1_code,el.level2_code, el.level3_code)?.name ?? ""}`
        //doc.getBeforeLevel1(item.level1_code)?.map((el:any)=>el.number)}}  {{dataSingle.getBeforeLevel1(item.level1_code)?.map((el:any)=>el.name??0)
        code3AdjustAT = `ข้อการดำเนินการย่อยที่ ${doc.getAfterLevel3ForDocument(el.level1_code,el.level2_code, el.level3_code)?.number ?? ""} ${doc.getAfterLevel3ForDocument(el.level1_code,el.level2_code, el.level3_code)?.name ?? ""}`
      }
      this.worksheet.addRow([
       //before
        i + 1,
         `${code1AdjustBF}\r\n`+`${code2AdjustBF} \r\n`+`${code3AdjustBF}\r\n`+
         `ผลผลิตและค่าเป้าหมาย`+`: ${(doc.findBudgetFromFlatted(el, 'before')).goals} \r\n`+
         `แผนการปฏิบัติงาน`+`: ${rangeBefore} ${doc.data!.budgetYear}\r\n`+
         `แผนงบประมาณ`+ `: ${this.sumAmountBefore(doc, el, 'before')} บาท\r\n`,
         `${this.sumAmountBefore(doc, el, 'before')} บาท\n`,

        //after
        `${code1AdjustAT}\r\n`+`${code2AdjustAT} \r\n`+`${code3AdjustAT}\r\n`+
         `ผลผลิตและค่าเป้าหมาย`+`: ${(doc.findBudgetFromFlatted(el, 'after')).goals} \r\n`+
         `แผนการปฏิบัติงาน`+`: ${rangeBefore} ${doc.data!.budgetYear}\n`+
         `แผนงบประมาณ`+ `: ${this.sumAmountAfter(doc, el, 'after')} บาท\n`+`${el.adjustType}\n`+`${el.reason}\n`,
         `${this.sumAmountAfter(doc, el, 'after')} บาท\n`,
         `${el.description3}`
  

         
    ])
    })
    this.worksheet.addRow(["","รวมงบประมาณ",`${sumBefore??0}`,"",`${sumAfter??0}`,""])
    this.worksheet.addRow([])
    let lastRow = this.worksheet.rowCount
   
    this.worksheet.addRow(["ความเห็นประกอบการพิจารณา"])
    this.worksheet.mergeCells(lastRow + 1, 1, lastRow + 1, 6)
    let lastRow2 = this.worksheet.rowCount
    this.worksheet.addRow([`${doc.data.opinion}`])
    this.worksheet.mergeCells(lastRow2 + 1, 1, lastRow2 + 1, 6)

    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >3&& rn <6) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true
          }
        })
      }
      if (rn >5) {
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          cell.alignment = {
            vertical: 'top',
            horizontal: 'left',
            wrapText: true
          }
          cell.font = { size: 16,name:"TH SarabunPSK" };
        })
      }
    })
    this.worksheet.addRow([])
    this.worksheet.addRow([])
    this.worksheet.addRow(["","","","","", "                               อนุมัติ       "])
    this.worksheet.addRow(["","","","","", "                                    "])
    this.worksheet.addRow(["","","","","","      ("+(".").repeat(65)+")"])
    this.worksheet.addRow(["","","","","", "                        ชาญยุทธ คำหวาน       "])
    this.worksheet.addRow(["","","","","", "            ตำแหน่งหัวหน้า ฝ่ายนโยบายและแผน       "])

    let current_row = 2
    let row = this.worksheet.getRow(current_row++)
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "AdjustTable.xlsx");
    });
  }

  async createPlanAdjust(docs: any,adjustName:string) {
    console.log(docs);
    console.log("Adjust");
    let ListSectors: Array<any> = [];
    let ListResponsibles: Array<any> = [];
    let responsibles = []
    try {
      responsibles =JSON.parse(docs.data.items.find((el: any) => el.code == "responsibles").value)
    } catch (error) {

    }
    //console.log(responsibles)
    try {
      responsibles?.forEach((el: any) => {
        
        ListResponsibles.push([
          `ผู้รับผิดชอบโครงการ: ${el.fullName}  เบอร์โทร: ${el.telephoone ?? "-"}`,
        ])
      })
    } catch (error) {
      console.log(error)
      
    }

    let sectors = []
    try {
      sectors = JSON.parse(docs.data.items.find((el: any) => el.code == "sectors").value)
    } catch (error) {

    }
    const sortByNumberString = (budgets: Array<BudgetModel>) => {
      budgets = _.sortBy(budgets, el => {
        try {
          return parseFloat(el.number)
        } catch (error) {
          return 0
        }
      })
      return budgets
    }
  try { 
  sectors?.forEach((el: any, i: number) => {
    let position = ""
    switch (i) {
      case 0:
        position = "หัวหน้างาน"
        break;

      case 1:
        position = "ผู้อำนวยการกอง"
        break;

      case 2:
        position = "ผู้อำนวยการฝ่าย"
        break;
    }
    if (el.fullName != undefined) {
      ListSectors.push(
        [
          `${el.position ?? "-"} : ${el.fullName} email: ${el.email ?? "-"} เบอร์โทร: ${el.telephoone ?? "-"}`,
        ],
      )
    }
  })
  
      } catch (error) {
        
      }
        

    let totalBudget = docs.data?.budgets.map((budget: any) => budget.items.map((item: any) => item.amount).reduce((a: any, b: any) => a + b, 0)).reduce((a: any, b: any) => a + b, 0)

    const resolveIndicator = (id: string) => {
      // try {
      //   let arr = (docs.data ?? { budgets: [] }).indicators.find((ind: any) => ind.id == id)
      //   // if (arr === undefined) return { code: "", name: "" }
      //   return arr.code
      // } catch (error) {
      //   return "-";
      // }
      try {
        let found = (docs.data ?? { budgets: [] }).indicators.find((ind: any) => ind.id == id)
        if (found === undefined) return { code: "", name: "" }
        return found.code
      } catch (error) {
        return ""
      }
    }
    const totalAmount = () => {
      try {
        let map = docs.data?.budgets
          .map((budget: any) =>
            budget.items.map((item: any) => item.amount).reduce((a: number, b: number) => a + b, 0)
          )
          .reduce((a: number, b: number) => a + b, 0);
        return map.formatComma();
      } catch (error) {

      }
    }
    const IndicatorCode = () => {
      try {
        let arr = (docs.data?.budgets ?? {}).indicators;
        arr = arr.map((a: any) => a.indicator.code);
        return arr;
      } catch (error) {
        return "-";
      }
    }

    const kpi6 = () => {
  
      try {
     let kpi6String: string = docs.kpi6??[]
     let combinedString:string[] = JSON.parse(kpi6String);
         return combinedString.join(",")
      } catch (error) {
        console.log(error)
        return ""
      }
    }
    const kpi12 = () => {
      try {
        let kpi12String : string = docs.kpi12??[]
        let combinedString:string[] = JSON.parse(kpi12String);
        return combinedString.join(",")
      } catch (error) {
        console.log(error)
        return ""
      }
    }
    const sortKPI = (arr: string) => {
      try {
        const newArr: string[] = arr.split(", ");
        console.log(newArr)
        return _.sortBy(newArr)

      } catch (error) {
        "-"
      }

    }
    let isMonthActive = (months: Array<any>, month: number): boolean => {
      try {

        let found = months.find((item: any) => item.month === month);
        return found.isActive
      } catch (error) {
        return false
      }
    }

    // //header
    this.worksheet.addRow([`${adjustName}`])
    this.worksheet.mergeCells('A1:T1')
    this.worksheet.getCell("A1").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("A1").font = { size: 14 };
    this.worksheet.getCell("A2").value = `รายการที่ : ${docs.data?.fullName}`
    this.worksheet.getCell("A2").font = { size: 10 };
    this.worksheet.mergeCells('A2:K2')
    this.worksheet.getCell("L2").value = `${docs.data.budgetSource ?? ''} : ${(totalBudget || 0).formatFull()} บาท`
    this.worksheet.getCell("L2").font = { size: 10 };
    this.worksheet.mergeCells('L2:T2')
    this.worksheet.getCell("A3").value = `KPI 6 เดือน`
    this.worksheet.getCell("A3").font = { size: 10 };
    this.worksheet.mergeCells('A3:B3')
    this.worksheet.getCell("A3").alignment = { wrapText: true }
    this.worksheet.getCell("C3").value = kpi6()
    this.worksheet.mergeCells('C3:K3')
    this.worksheet.getCell("C3").alignment = { wrapText: true }
    this.worksheet.mergeCells('L3:T3')
    this.worksheet.getCell("A4").value = `KPI 12 เดือน`
    this.worksheet.getCell("A4").font = { size: 10 };
    this.worksheet.getCell("A4").alignment = { wrapText: true }
    this.worksheet.mergeCells('A4:B4')
    this.worksheet.getCell("C4").value = kpi12()
    this.worksheet.mergeCells('C4:K4')
    this.worksheet.getCell("C4").alignment = {
      wrapText: true
    }
    this.worksheet.mergeCells('L4:T4')
    this.worksheet.getCell("A5").value = `ตัวชี้วัดที่เกี่ยวข้อง: ${sortKPI(docs.data!.indicatorsText)}`
    this.worksheet.getCell("A5").font = { size: 10 };
    this.worksheet.mergeCells('A5:K5')
    // this.worksheet.getCell("C5").value = `${sortKPI(docs.data!.indicatorsText)}`
    // this.worksheet.getCell("C5").alignment = {
    //   wrapText: true
    // }
    // this.worksheet.mergeCells('C5:K5')
    // this.worksheet.mergeCells('L5:T5')
    // //body
    this.worksheet.getCell("A6").value = `ลำดับ\nที่`
    this.worksheet.getCell("A6").font = { size: 10 };
    this.worksheet.mergeCells('A6:A7')
    this.worksheet.getCell("A6").alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
    this.worksheet.getCell("B6").value = "การดำเนินงานหลัก"
    this.worksheet.getCell("B6").font = { size: 10 };
    this.worksheet.mergeCells('B6:C7')
    this.worksheet.getCell("B6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("D6").value = "ผลผลิตและค่าเป้าหมาย"
    this.worksheet.getCell("D6").font = { size: 10 };
    this.worksheet.mergeCells('D6:D7')
    this.worksheet.getCell("D6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("E6").value = "ผู้รับผิดชอบ"
    this.worksheet.getCell("E6").font = { size: 10 };
    this.worksheet.mergeCells('E6:E7')
    this.worksheet.getCell("E6").alignment = { vertical: 'middle', horizontal: 'center' }

    this.worksheet.getCell("F6").value = `${docs.data?.budgetSource ?? '-'} `
    this.worksheet.mergeCells('F6:G6')
    this.worksheet.getCell("F6").alignment = { vertical: 'middle', horizontal: 'center' }

    // this.worksheet.getCell("F6").value = `งบอุดหนุน`
    // this.worksheet.mergeCells('F6:F7')
    // this.worksheet.getCell("F6").alignment = { vertical: 'middle', horizontal: 'center' }
    // this.worksheet.getCell("G6").value = `งบอุดหนุน`
    // this.worksheet.mergeCells('G6:G7')
    // this.worksheet.getCell("G6").alignment = { vertical: 'middle', horizontal: 'center' }

    this.worksheet.getCell("H6").value = `${docs.data!.budgetYear - 1 || 0}`
    this.worksheet.getCell("H6").font = { size: 10 };
    this.worksheet.mergeCells('H6:J6')
    this.worksheet.getCell("H6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("F7").value = "งบอุดหนุน"
    this.worksheet.getCell("F7").font = { size: 10 };
    this.worksheet.getCell("F7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("G7").value = "งบลงทุน"
    this.worksheet.getCell("G7").font = { size: 10 };
    this.worksheet.getCell("G7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("H7").value = "ต.ค."
    this.worksheet.getCell("H7").font = { size: 10 };
    this.worksheet.getCell("I7").value = "พ.ย."
    this.worksheet.getCell("I7").font = { size: 10 };
    this.worksheet.getCell("J7").value = "ธ.ค."
    this.worksheet.getCell("J7").font = { size: 10 };
    this.worksheet.getCell("H7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("I7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("J7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K6").value = `${docs.data!.budgetYear || 0}`
    this.worksheet.mergeCells('K6:S6')
    this.worksheet.getCell("K6").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("K7").value = "ม.ค."
    this.worksheet.getCell("K7").font = { size: 10 };
    this.worksheet.getCell("K7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("L7").value = "ก.พ."
    this.worksheet.getCell("L7").font = { size: 10 };
    this.worksheet.getCell("L7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("M7").value = "มี.ค."
    this.worksheet.getCell("M7").font = { size: 10 };
    this.worksheet.getCell("M7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("N7").value = "เม.ย."
    this.worksheet.getCell("N7").font = { size: 10 };
    this.worksheet.getCell("N7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("O7").value = "พ.ค."
    this.worksheet.getCell("O7").font = { size: 10 };
    this.worksheet.getCell("O7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("P7").value = "มิ.ย."
    this.worksheet.getCell("P7").font = { size: 10 };
    this.worksheet.getCell("P7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("Q7").value = "ก.ค."
    this.worksheet.getCell("Q7").font = { size: 10 };
    this.worksheet.getCell("Q7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("R7").value = "ส.ค."
    this.worksheet.getCell("R7").font = { size: 10 };
    this.worksheet.getCell("R7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("S7").value = "ก.ย."
    this.worksheet.getCell("S7").font = { size: 10 };
    this.worksheet.getCell("S7").alignment = { vertical: 'middle', horizontal: 'center' }
    this.worksheet.getCell("T6").value = "ความเชื่อมโยง"
    this.worksheet.getCell("T6").font = { size: 10 };
    this.worksheet.mergeCells('T6:T7')
    this.worksheet.getCell("T6").alignment = { vertical: 'middle', horizontal: 'center' }
    // //widthß
    this.worksheet.getColumn("A").width = 5
    this.worksheet.getColumn("B").width = 5
    this.worksheet.getColumn("C").width = 27
    this.worksheet.getColumn("D").width = 20
    this.worksheet.getColumn("E").width = 12
    this.worksheet.getColumn("F").width = 10
    this.worksheet.getColumn("G").width = 10
    this.worksheet.getColumn("H").width = 10
    this.worksheet.getColumn("I").width = 9
    this.worksheet.getColumn("J").width = 9
    this.worksheet.getColumn("K").width = 9
    this.worksheet.getColumn("L").width = 9
    this.worksheet.getColumn("M").width = 9
    this.worksheet.getColumn("N").width = 9
    this.worksheet.getColumn("O").width = 9
    this.worksheet.getColumn("P").width = 9
    this.worksheet.getColumn("Q").width = 9
    this.worksheet.getColumn("R").width = 9
    this.worksheet.getColumn("S").width = 9
    this.worksheet.getColumn("T").width = 10.33
    let current_row = 8;
    let start_row = 8;
    let last_item_row = 8;
    sortByNumberString((docs.data ?? { budgets: [] }).budgets).forEach((lv1: any, i: number) => {
      this.worksheet.addRow([
        lv1.number,
        `${lv1.name} (${lv1.code})`,
        null,
        lv1.goals,
        lv1.responsible.firstName,
        lv1.totalSupportAmount,
        lv1.totalInvestAmount,
        (lv1 ?? {}).monthAmount01 ?? 0,
        (lv1 ?? {}).monthAmount02 ?? 0,
        (lv1 ?? {}).monthAmount03 ?? 0,
        (lv1 ?? {}).monthAmount04 ?? 0,
        (lv1 ?? {}).monthAmount05 ?? 0,
        (lv1 ?? {}).monthAmount06 ?? 0,
        (lv1 ?? {}).monthAmount07 ?? 0,
        (lv1 ?? {}).monthAmount08 ?? 0,
        (lv1 ?? {}).monthAmount09 ?? 0,
        (lv1 ?? {}).monthAmount10 ?? 0,
        (lv1 ?? {}).monthAmount11 ?? 0,
        (lv1 ?? {}).monthAmount12 ?? 0,
        lv1.indicators.map((ind: any) => ind.indicator.code).join("\n"),


      ]);
      let row = this.worksheet.getRow(current_row++)
      row.eachCell({ includeEmpty: true }, (cell, cn) => {
        console.log(cn);

        if (cn >= 8 && cn < 20) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              // argb: 'FFD1E2F5'
              argb: isMonthActive(lv1.items, cn - 8) ? 'FFCCCCCC' : 'FFFFFFFF'
            }
          };
          cell.font = {
            size: 10
          };
        }
        if (cn >= 6 && cn < 20) {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'right',
          }
          cell.numFmt = '#,##0;[Red]-#,##0;';
          cell.font = {
            size: 10
          };
        }else{
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
            wrapText:true
          }
          cell.font = {
            size: 10
          };
        }
      })
      //   // #d1e2f5
      sortByNumberString(lv1.children).forEach((lv2: any, j: number) => {
        this.worksheet.addRow([
          "",
          `  ${lv2.number} ${lv2.name} (${lv2.code})`,
          null,
          lv2.goals,
          lv2.responsible.firstName,
          lv2.totalSupportAmount,
          lv2.totalInvestAmount,
          (lv2 ?? {}).monthAmount01 ?? 0,
          (lv2 ?? {}).monthAmount02 ?? 0,
          (lv2 ?? {}).monthAmount03 ?? 0,
          (lv2 ?? {}).monthAmount04 ?? 0,
          (lv2 ?? {}).monthAmount05 ?? 0,
          (lv2 ?? {}).monthAmount06 ?? 0,
          (lv2 ?? {}).monthAmount07 ?? 0,
          (lv2 ?? {}).monthAmount08 ?? 0,
          (lv2 ?? {}).monthAmount09 ?? 0,
          (lv2 ?? {}).monthAmount10 ?? 0,
          (lv2 ?? {}).monthAmount11 ?? 0,
          (lv2 ?? {}).monthAmount12 ?? 0,
          lv2.indicators.map((ind: any) => ind.indicator.code).join("\n"),
        ]);
        let row = this.worksheet.getRow(current_row++)
        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          // console.table(lv2.monthsFlag)
          if (cn >= 8 && cn < 20) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: isMonthActive(lv2.items, cn - 8) ? 'FFe4ecfc' : 'FFFFFFFF'
                // argb: 'FFe4ecfc'
              }
            };
            cell.font = {
              size: 10
            };
          }
          if (cn >= 6 && cn < 20) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
            }
            cell.font = {
              size: 10
            };
            cell.numFmt = '#,##0;[Red]-#,##0;';
          }else{
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
              wrapText:true
            }
            cell.font = {
              size: 10
            };
          }
        })
        sortByNumberString(lv2.children).forEach((lv3: any, k: number) => {
          this.worksheet.addRow([
            "",
            `    ${lv3.number} ${lv3.name} (${lv3.code})`,
            null,
            lv3.goals,
            lv3.responsible.firstName,
            lv3.totalSupportAmount,
            lv3.totalInvestAmount,
            (lv3 ?? {}).monthAmount01 ?? 0,
            (lv3 ?? {}).monthAmount02 ?? 0,
            (lv3 ?? {}).monthAmount03 ?? 0,
            (lv3 ?? {}).monthAmount04 ?? 0,
            (lv3 ?? {}).monthAmount05 ?? 0,
            (lv3 ?? {}).monthAmount06 ?? 0,
            (lv3 ?? {}).monthAmount07 ?? 0,
            (lv3 ?? {}).monthAmount08 ?? 0,
            (lv3 ?? {}).monthAmount09 ?? 0,
            (lv3 ?? {}).monthAmount10 ?? 0,
            (lv3 ?? {}).monthAmount11 ?? 0,
            (lv3 ?? {}).monthAmount12 ?? 0,
            sortByNumberString(lv3.indicators).map((ind: any) => ind.indicator.code).join("\n"),
          ]);
          let row = this.worksheet.getRow(current_row++)
          row.eachCell({ includeEmpty: true }, (cell, cn) => {
            // console.table(lv3.monthsFlag)
            if (cn >= 8 && cn < 20) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: isMonthActive(lv3.items, cn - 8) ? 'FFf5f8f9' : 'FFFFFFFF'
                  // argb: 'FFf5f8f9'
                }
              };
              cell.font = {
                size: 10
              };  
            }
            if (cn == 4) {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'left',
              }
              cell.font = {
                size: 10
              };
  
            }else if (cn==5) {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'left',
              }
              cell.font = {
                size: 10
              };
  
            }else if (cn >= 6 && cn < 20) {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'right',
              }
              cell.font = {
                size: 10
              };
  
            }else{
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'left',
                wrapText:true
              }
              cell.numFmt = '#,##0;[Red]-#,##0;';
              cell.font = {
                size: 10
              };
            }

          })
        })
      })
      for (var i = 0; i < 14; i++) {
        this.worksheet.getColumn(6 + i).numFmt = '#,##0;[Red]-#,##0;';
        
      }
    })
    let result: Array<any> = [];
    docs.data!.grandTotalArrayValue.forEach((lv1: any) => {
      result.push(
        lv1
      )
    })
    this.worksheet.addRow([
      `รวม${docs.data.budgetSource ?? '-'}`,
      "",
      "",
      "",
      "",
      docs.data!.grandTotalSupportAmountA09,
      docs.data!.grandTotalInvestAmountA09,
      ...result,
      ""
    ])
    console.log('row', current_row, start_row)
    for (let i = 0; i < current_row - start_row; i++) {
      this.worksheet.mergeCells(start_row + i, 2, start_row + i, 3)
      this.worksheet.getRow(start_row + i).getCell(1).alignment = {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left'
      }
      this.worksheet.getRow(start_row + i).height = 30   
    }

    this.worksheet.addRow([
      "",
      "",
      "",
      "",
      "",
      totalAmount(),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ])
    let lastRow = this.worksheet.rowCount
    console.log('lastrow', lastRow);

    this.worksheet.mergeCells(lastRow - 1, 1, lastRow, 5)
    this.worksheet.mergeCells(lastRow, 6, lastRow, 7)
    for (var i = 0; i < 13; i++) {
      this.worksheet.mergeCells(lastRow - 1, 8 + i, lastRow, 8 + i)
    }
    
    this.worksheet.getRow(lastRow - 1).eachCell({ includeEmpty: true }, (cell, cn) => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'right',
      }
      cell.font = {
        size: 10
      };
      cell.numFmt = '#,##0;[Red]-#,##0;';
    })
    this.worksheet.getRow(lastRow).eachCell({ includeEmpty: true }, (cell, cn) => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'right',
      }
      cell.font = {
        size: 10
      };
      cell.numFmt = '#,##0;[Red]-#,##0;';
    })
    this.worksheet.eachRow({ includeEmpty: true }, (row, rn) => {
      if (rn >= 6) {

        row.eachCell({ includeEmpty: true }, (cell, cn) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.font = { size: 10 };
        })
      }
    })
    ListResponsibles.map((el => {
      const row = this.worksheet.addRow([...el])
      row.eachCell((cell) => {
        cell.font = { size: 10 };
      });
    }))
    ListSectors.map((el => {
      const row = this.worksheet.addRow([...el])
      row.eachCell((cell) => {
        cell.font = { size: 10 };
      });
    }))
    // return 
    this.workbook.xlsx.writeBuffer().then(function (data: any) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ปรับแผน.xlsx");
    })
  }

}

