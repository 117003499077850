import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import AssignmentModel,{AssignmentAttachmentModel,AssignmentReplyAttachmentModel,AssignmentMemberModel} from "@/model/assignment";
import AssignmentServices from "@/service/assignment";
import { Service } from 'typedi';

@Service()
export class ActiveAssignmentSingleClass {
  data?: AssignmentModel;
  loading: boolean = true;
  async getMyAssignmentSingle(id: string) {
    this.loading = true
    this.data = await new AssignmentServices().getMyAssignmentSingle(id)
    this.loading = false
  }
  async updateMyAssignmentSingle() {
    this.loading = true
    console.log('data',this.data)
    this.data = await new AssignmentServices().updateMyAssignment(this.data!.id!, this.data!)
    this.loading = false
  }
  async createMyAssignmentSingle() {
    this.loading = true
    this.data = await new AssignmentServices().createMyAssignment(this.data!)
    this.loading = false
  }
}
@Service()
export class AssignmentAttachmentSingleClass {
  data?: AssignmentAttachmentModel;
  loading: boolean = true;
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAttachmentSingle() {
    console.log(this.data!.id+"service")
    this.loading = true
    this.data = await new AssignmentServices().deleteMyAssignmentAttachmentSingle(this.data!.id!)
    this.loading = false
  }
}

@Service()
export class AssignmentMemberSingleClass {
  data?: AssignmentMemberModel;
  loading: boolean = true;
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAttachmentMemberSingle() {
    console.log(this.data!.id+"service")
    this.loading = true
    this.data = await new AssignmentServices().deleteMyAssignmentMemberSingle(this.data!.id!)
    this.loading = false
  }
}

@Service()
export class AssignmentReplyAttachmentSingleClass {
  data?: AssignmentReplyAttachmentModel;
  loading: boolean = true;
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteReplyAttachmentSingle() {
    console.log(this.data!.id+"service")
    this.loading = true
    this.data = await new AssignmentServices().deleteMyAssignmentReplyAttachmentSingle(this.data!.id!)
    this.loading = false
  }
}
@Service()
export class ActiveAssignmentListClass {
  data?: PaginateResponse<AssignmentModel>;
  loading: boolean = true;
  query:any = {}
  async getAssignmentList(query: any) {
    this.query = query
    this.loading = true
    this.data = await new AssignmentServices().getAssignmentList(query)
    this.loading = false
  }
  async refreshAssignmentList() {
    this.loading = true
    this.data = await new AssignmentServices().getAssignmentList(this.query)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteMyAssignmentFromList(id:string){
    this.loading = true
    let response = await new AssignmentServices().deleteMyAssignmentSingle(id)
    this.refreshAssignmentList()
    this.loading = false
  }
}