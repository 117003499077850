
import { Options, Vue } from "vue-class-component";
import {
  IndicatorMetricModel,
  IndicatorResponsiblePersonModel,
} from "@/model/indicator";
import { ActiveIndiciatorSingleClass } from "@/data/indicator";
import Container from "typedi";
import { DocumentDocx } from "@/document/docx/B";
import { ReportB03Class } from "@/data/report";
import LoadingWidget from "@/components/LoadingWidget.vue";
import { DocumentExcel } from "@/document/xlsx";

@Options({
  components: {},
})
export default class ReportB01View extends Vue {
  IndicatorMetricModel = IndicatorMetricModel;
  IndicatorResponsiblePersonModel = IndicatorResponsiblePersonModel;
  dataSingle: ReportB03Class = Container.get(ReportB03Class);
  expenseType:string = ""
  expenseTypes = [
    { value:"การจ้างผู้ช่วยปฏิบัติงาน", name: "การจ้างผู้ช่วยปฏิบัติงาน ฯ (ประกอบด้วย จำนวน/วุฒิการศึกษา เป็นต้น)"},
    { value:"ค่าใช้จ่ายในการเดินทางต่างประเทศ", name: "ค่าใช้จ่ายในการเดินทางต่างประเทศ"},
    { value:"ค่าใช้จ่ายในการเดินทางในประเทศ", name: "ค่าใช้จ่ายในการเดินทางในประเทศ"},
    { value:"ค่าเช่าเครื่องถ่ายเอกสาร", name: "ค่าเช่าเครื่องถ่ายเอกสาร"},
    { value:"การจัดซื้อวัสดุสำนักงาน", name: "การจัดซื้อวัสดุสำนักงาน"},
    { value:"ค่าเบี้ยประชุม/ค่าตอบแทน", name: "ค่าเบี้ยประชุม/ค่าตอบแทน"},
    { value:"ค่าจัดประชุมคณะกรรมการต่างๆ", name: "ค่าจัดประชุมคณะกรรมการต่างๆ"},
    { value:"จ้างที่ปรึกษา", name: "จ้างที่ปรึกษา"},
    { value:"การจัดทำระบบไอที", name: "การจัดทำระบบไอที"},
    { value:"งบลงทุนภายในรายการ", name: "งบลงทุนภายในรายการ"},
    { value:"การปฏิบัติงานนอกเวลาราชการ (OT)", name: "การปฏิบัติงานนอกเวลาราชการ (OT)"},
  ]
  exportExcel(){

    let docExcel = new DocumentExcel();
    docExcel.createB03(this.dataSingle.data!, 2565);
  }
  mounted() {
    this.fetchData();
  }
  async fetchData() {
    await this.dataSingle.getReportB03List({ type: this.expenseType, budgetYear: 2565 });
  }
}
