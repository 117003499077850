import BudgetModel from "../budget";
import WithdrawModel from "../withdraw";

export  default class FollowModel {
  id? : string;
  smisCode:string;
  comment:string;
  month:Number;
  activeMonth:boolean;
  budget_id:string;
  budget:BudgetModel;
  withdraw: WithdrawModel;
  isCancel:boolean;
  followDetail:string;
  followDateStart:Date;
  followDateEnd:Date;
  createdIP : string;
  modifiedIP : string;
  createdAt : Date;
  modifiedAt : Date;

  constructor(obj:any) {
    this.id = obj.id
    this.smisCode = obj.smisCode
    this.comment = obj.comment
    this.month = obj.month;
    this.activeMonth =  obj.activeMonth;
    this.budget_id = obj.budget_id;
    this.budget = obj.budget;
    this.withdraw = obj.withdraw;
    this.isCancel = obj.isCancel;
    this.followDetail = obj.followDetail
    this.followDateStart = obj.followDateStart
    this.followDateEnd = obj.followDateEnd
    this.createdIP = obj.createdIP
    this.modifiedIP = obj.modifiedIP
    this.createdAt = obj.createdAt
    this.modifiedAt = obj.modifiedAt
  }
  toJSON(){
    let obj = Object.assign({},this)
    
    return JSON.parse(JSON.stringify(obj))
  }
}
