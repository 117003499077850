
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import SystemModel from "@/model/system";
import {
  ActiveProjectListClass,
  ActiveProjectSingleClass,
} from "@/data/project";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import ProjectModel from "@/model/project";
import { ActiveSectorListClass } from "@/data/sector";
import { ActiveBudgetYearClass } from "@/data/system";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class ProjectListView extends Vue {
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  dataList: ActiveProjectListClass = Container.get(ActiveProjectListClass);
  sector_id = "";
  search = "";
  budgetYear = new Date().getFullYear() + 543;
  docs: Array<ProjectModel> = [];
  pages: number = 0;
  total: number = 0;
  show: number = 20;
  page: number = 1;
  store = useStore();

  renderTableTool(item: ProjectModel) {
    let result: Array<string> = ["Detail"];
    if (this.store.getters.hasRight("withdraw_form_editall"))
      result.push("Edit");
    if (this.store.getters.hasRight("withdraw_form_create"))
      result.push("Edit");
    if (this.store.getters.hasRight("withdraw_form_detail"))
      result.push("Detail");
    if (
      this.store.getters.hasRight("withdraw_form_editsector") &&
      this.store.state.sector2 === item.sector_id
    ) {
      result.push("Edit");
    }
    return result;
  }
  mounted() {
    this.yearSetting.getBudgetYearSetting();
    this.sectorList.getSectorList({ search: { category: "ฝ่าย/สำนัก" } });
    this.fetchData();
  }
  async fetchData() {
    console.log("page", this.page);
    let query: any = {
      search: {
        step: 4,
      },
      sort: [
        { step: "asc" },
        { code: "asc" },
        { sector: { name: "asc" } },
        { createdAt: "desc" },
      ],
      limit: this.show,
      skip: (this.page - 1) * this.show,
    };
    query.search.budgetYear = this.budgetYear;
    if (this.sector_id != "") query.search.sector_id = this.sector_id;
    if (this.search != "") {
      query.search = {
        ...query.search,
        OR: [
          { name: { contains: this.search } },
          { code: { contains: this.search } },
        ],
      };
    }

    await this.dataList.getProjectList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
