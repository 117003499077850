
import { Options, Vue } from "vue-class-component";
import AssignmentModel, { AssignmentMemberModel,AssignmentReplyAttachmentModel } from "@/model/assignment";
import {ActiveAssignmentSingleClass,AssignmentReplyAttachmentSingleClass} from "@/data/assignment";
import Container from "typedi";
import ADDRESS from "@/setting";
import { useStore } from "vuex";
import { confirmAction } from "@/decorator";

@Options({
  components: {},
})
export default class MyAssignmentFormView extends Vue {
  ADDRESS = ADDRESS;
  store = useStore()
  AssignmentMemberModel = AssignmentMemberModel
  dataSingle: ActiveAssignmentSingleClass = Container.get( ActiveAssignmentSingleClass );
  status = [
    {value:"ยังไม่ดำเนินการ", name: "ยังไม่ดำเนินการ" },
    { value:"อยู่ระหว่างการดำเนินการ", name: "อยู่ระหว่างการดำเนินการ" },
    { value:"ดำเนินการแล้ว", name: "ดำเนินการแล้ว" },
  ];
  priority = [
    { value:"ปกติ", name: "ปกติ" },
    { value:"ด่วน", name: "ด่วน" },
    { value:"ด่วนมาก", name: "ด่วนมาก" },
  ];
  get assigns() {
    try {
      return this.dataSingle.data!.assigns;
    } catch (error) {
      return [];
    }
  }
  get isDetail (){
    if(this.$route.path.search(`/detail/`)!=-1) return true
    else return false
  }
  mounted() {
    this.initData();
  }
  async initData() {
    await this.dataSingle.getMyAssignmentSingle(this.$route.params.id as string);
  }
  async submitForm() {
    this.dataSingle.updateMyAssignmentSingle();
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
   async deleteReplyAttachmentItem(item:AssignmentReplyAttachmentModel) {
    
    let attachment: AssignmentReplyAttachmentSingleClass = Container.get(
      AssignmentReplyAttachmentSingleClass
    );
    attachment!.data = item
    await attachment.deleteReplyAttachmentSingle()
    this.$nextTick(()=>{

    this.initData()
    })
  
  }
}
