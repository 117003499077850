import { saveAs } from "file-saver";
declare var ExcelJS: any;
// declare var saveAs: any;
export default class MyDocument {
  _blob = new Blob(["พบข้อผิดพลาดในการสร้างเอกสาร"], {
    type: "text/plain;charset=utf-8",
  });
  init:boolean = false
  public save(name: string) {
    console.log(this._blob)
    saveAs(this._blob, this.init?name:"error.txt")
    // saveAs(this._blob, this.init?name:"error.txt");
  }
  public get blob() {
    return this._blob
  }
  public testExcel(){
    var workbook = new ExcelJS.Workbook();
    var worksheet = workbook.addWorksheet('SheetName');
   
    workbook.xlsx.writeBuffer().then(function(buffer:any) {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 
      'FileName.xlsx');
    });
  }
}
