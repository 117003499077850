import ADDRESS from "@/setting";
import axios from "axios"
import Model, { BudgetItemModel } from "@/model/budget";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";
import ProjectModel, { ProjectBudget } from "@/model/project";

export default class BudgetServices {

  public getBudgetList(query: any) {
    return new Promise<PaginateResponse<Model>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/budgets/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<Model> = response.data.docs.map((el: any) => new Model(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<Model>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getBudgetSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/budget/get/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }


  public deleteBudgetSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/budget/delete/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }
  public deleteBudgetItemSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/budget/delete-item/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }
  public deleteBudgetIndicatorSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/budget/delete-indicator/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }

  public updateBudget(id: string, Budget: Model) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/budget/update/${Budget.id}`, Budget.toJSON());
      console.log('res',response);
      let doc: Model = new Model(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public updateBudgetLite(id: string, Budget: Model) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/budget/update-lite/${Budget.id}`, Budget.toJSON());
      console.log('res',response);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }
  public updateBudgetItemSingle(id: string, BudgetItem: BudgetItemModel) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/budget/update-item/${id}`, BudgetItem.toJSON());
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }
  public specialBudgetSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/budget/check-toggle/${id}`);
      let doc: Model = new Model(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public createBudget(project_id:string, Budget: Model) {
    return new Promise<Model>(async (resolve, reject) => {
      Budget.project_id = project_id
      let response = await axios.post(`${ADDRESS}/budget/create/`, Budget);
      console.log('response',Budget);
      let doc: Model = new Model(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
