export  default class PositionModel {
  id?: string;
  name: string;
  nameEN: string;
  degree: string;
  minLevel: number;
  maxLevel: number;
  // createdAt: Date;
  // modifiedAt: Date;
  // createdIP: string;
  // modifiedIP: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name;
    this.nameEN = jsonData.nameEN;
    this.degree = jsonData.degree;
    this.minLevel = jsonData.minLevel;
    this.maxLevel = jsonData.maxLevel;
    // this.createdAt = jsonData.createdAt;
    // this.modifiedAt = jsonData.modifiedAt;
    // this.createdIP = jsonData.createdIP;
    // this.modifiedIP = jsonData.modifiedIP;
  }
  toJSON(){
    let obj = Object.assign({},this)
    return JSON.parse(JSON.stringify(obj))
  }
}