
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import StrategicModel from "@/model/strategic";
import { ActiveStrategicListClass,ActiveStrategicSingleClass } from "@/data/strategic";
import { ActiveBudgetYearClass,ActiveStrategicClass } from "@/data/system";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import { DocumentDocx } from "@/document/docx/B";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class UACFormView extends Vue {
  dataList: ActiveStrategicListClass = Container.get(ActiveStrategicListClass);
  dataSingle: ActiveStrategicSingleClass = Container.get(ActiveStrategicSingleClass);
  yearSetting:ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  strategicSetting:ActiveStrategicClass = Container.get(ActiveStrategicClass);
  docs: Array<StrategicModel> = [];
  pages: number = 0;
  total: number = 0;
  show: number = 20;
  page: number = 1;
  filter1: any = {}
  filter2: any = {}
  store = useStore();


  renderTableTool(item: StrategicModel) {
    let result:Array<string> = ["Detail"]
    if(this.store.getters.hasRight("indicator_strategic_editall")) result.push("Edit")
    if(this.store.getters.hasRight("indicator_strategic_delete")) result.push("Delete")
    if(this.store.getters.hasRight("indicator_strategic_detail")) result.push("Detail")
    if(this.store.getters.hasRight("indicator_strategic_report")) result.push("Download")
    return result;
  }
  mounted() {
    this.initData();
  }
  async initData() {
    let query = {
      search: {
        // ...this.filter1,
        // ...this.filter2
        budgetYear : this.filter1,
        name : this.filter2
      },
      limit: this.show,
      page: this.page,
      sort: {
       createdAt:"desc"
      }
    }
    await this.dataList.getStrategicList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
    this.yearSetting.getBudgetYearSetting();
    await this.strategicSetting.getStrategicSetting("ระดับผังยุทธศาสตร์");
  }
  async Download(id:any) {
    await this.dataSingle.getStrategicSingle(id);
    const docx = new DocumentDocx();
    await docx.reportB02(this.dataSingle.data);
    docx.save("Report_B02.docx");
  }
  clear() {
    this.filter1 = undefined
    this.filter2 = undefined
    this.initData()
  }
  get budgetYear() {
    let arr : Array<Object> = []
    this.yearSetting.data?.forEach((el)=>{
      arr.push({
        name:el.name,
        value:parseInt(el.text1)
      })
    })
    return arr
  }
  get strategic() {
    let arr : Array<Object> = []
    this.strategicSetting.data?.forEach((el)=>{
      arr.push({
        name:el.name,
        value:el.name
      })
    })
    return arr
  }
}
