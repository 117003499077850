
import { Options, Vue } from "vue-class-component";
import Container from "typedi";
import { ActiveStrategicBudgetYearClass } from "@/data/strategic";
import { ActiveProjectSingleClass } from "@/data/project";
import TextField from "../../../components/TextField.vue";
import { Watch } from "vue-property-decorator";
import JMAutoPerson from "../../../components/JMAutoPerson.vue";
import UserModel from "@/model/user";
@Options({
  props: {
    modelValue: [Array, String],
    label: String,
    disabled: Boolean,
  },
})
export default class PlanTableComponent extends Vue {
  strategicBudgetYear: ActiveStrategicBudgetYearClass = Container.get(
    ActiveStrategicBudgetYearClass
  );
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);
  label!: string;
  level: Array<any> = [{ n: "ผู้อนุมัติ" }];
  modelValue!: Array<string> | string;
  val: Array<any> = [{}, {}, {}];
  disabled!: boolean;
  updateFromAutoPerson(user: UserModel, item: any) {
    console.log("user", user);
    item.user_id = user.id!;
    item.fullName = user.fullName;
    item.position = user.position;
    item.sector1 = user.sector1?.name;
    item.sector2 = user.sector2?.name;
    item.telephone = user.telephone;
    item.email = user.email;
    item.signature = user.signature;
    this.emitUp();
  }
  pushItem() {
    if (typeof this.val === "string") this.val = [{}, {}, {}];
    else if (!Array.isArray(this.val)) this.val = [{}, {}, {}];
    this.val.push({});
    this.emitUp();
  }
  deleteItem(i: number) {
    let c = confirm("ยืนยันลบรายการหรือไม่?");
    if (c) {
      this.val.splice(i, 1);
      this.emitUp();
    }
  }
  mounted() {
    this.val = (this.modelValue as Array<any>) ?? [{}, {}, {}];
    if (this.modelValue === "") this.val = [{}, {}, {}];

    console.log("this.val.length", this.val.length);
    if (this.val.length > 3) {
      this.val = this.val.slice(0, 3);
    }
    this.emitUp();
  }
  emitUp() {
    this.$emit("update:modelValue", this.val);
  }
}
