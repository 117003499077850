import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import ProjectModel, { ProjectItemModel } from "@/model/project";
import ProjectServices from "@/service/project";
import SettingServices from "@/service/system";
import { Service } from 'typedi';
import store from "@/store"
@Service()
export class ActiveProjectSingleClass {
  data?: ProjectModel;
  loading: boolean = true;
  allRevisions:Array<any> = []
  currentRevision = 0
  showZero : boolean = false;
  isDuplicateCode: boolean = false;
  duplicateCode: string = ""
  // get zeroBudget() {
  //   try {
  //     let mapped = this.data?.budgets.map(budget=> budget.items.map(item=>item.amount != 0).reduce((a,b)=>a||b))
  //     // let filtered = mapped?.filter(item=>item===true)
  //     return mapped
  //   } catch (error) {
  //     return this.showZero;
  //   }
  // }
  get sector() {
    try {
      return this.data!.sector.name;
    } catch (error) {
      return {};
    }
  }

  get kpi6() {
    let found = this.data?.items.find((item) => item.code === "kpi6");
    let value: any;
    console.log('found',found);
    
    try {
      value = found?.value;
    } catch (error) {
      console.log(error)
    }
    return value;
  }
  get kpi12() {
    let found = this.data?.items.find((item) => item.code === "kpi12");
    let value: any;
    try {
      value = found?.value;
    } catch (error) {}
    return value;
  }

  async getAllRevision(adjustment_id:string){
    try {
      
    this.allRevisions = (await new ProjectServices().getAllRevision(adjustment_id)).map((item:any)=>item.revision);
    } catch (error) {
      
    }
  }
  async getAdjustmentD2Single(id: string) {
    this.loading = true;
    this.data = await new ProjectServices().getAdjustmentD2Single(id);
    this.loading = false;
  }
  async getAdjustmentD2SingleWithRevision(id: string, revision:number) {
    this.loading = true;
    this.data = await new ProjectServices().getAdjustmentD2SingleWithRevision(id,revision);
    this.loading = false;
  }
  async checkCodeSingle(code: string) {
    this.loading = true;
    let response = await new ProjectServices().checkCodeSingle(code);
    if(response==="success"){
      this.isDuplicateCode = false
      this.duplicateCode = ""
    } else {
      this.isDuplicateCode = true
      this.duplicateCode = code
      try {
        this.data!.code = ""
      } catch (error) {
        
      }
    }
    this.loading = false;
  }
  async getProjectSingle(id: string) {
    this.loading = true
    this.data = await new ProjectServices().getProjectSingle(id)
    this.loading = false
  }
  async refreshProjectSingle() {
    this.loading = true
    this.data = await new ProjectServices().getProjectSingle(this.data!.id!)
    this.loading = false
  }
  async updateProjectSingle() {
    this.loading = true
    delete this.data?.approver1_id
    delete this.data?.approver2_id
    delete this.data?.approver3_id
    delete this.data?.approver4_id
    delete this.data?.approver5_id
    delete this.data?.approver6_id
    delete this.data?.c7ApproveName
    delete this.data?.c7ApproveDate
    delete this.data?.c7Signature
    let response = await new ProjectServices().updateProject(this.data!.id!, this.data!)
    this.refreshProjectSingle()
    this.loading = false
  }
  async createProjectSingle() {
    this.loading = true
    this.data = await new ProjectServices().createProject(this.data!)
    this.loading = false
  }

}

@Service()
export class ActiveProjectListClass {
  data?: PaginateResponse<ProjectModel>;
  loading: boolean = true;
  query: any = {};
  async getProjectList(query: any) {
    this.query = query;
    this.loading = true
    this.data = await new ProjectServices().getProjectList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  async getProjectFollowList(query: any) {
    this.query = query;
    this.loading = true
    this.data = await new ProjectServices().getProjectFollowList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteProject(id: string) {
    this.loading = true;
    console.log(id);
    let result = await new ProjectServices().deleteProjectFromList(id);
    await this.getProjectList(this.query);
    this.loading = false;
  }
  async approveProject(id: string, approver_id: string) {
    this.loading = true;
    let result = await new ProjectServices().approveProjectFromList(id,approver_id);
    await this.getProjectList(this.query);
    let budget = await new ProjectServices().getNotification(approver_id)
    console.log(budget);
    store.commit("setBudgetNotification", budget.data.notification_count);
    this.loading = false;
  }
  async rejectProject(id: string, reject_id: string) {
    this.loading = true;
    let result = await new ProjectServices().rejectProjectFromList(id, reject_id);
    await this.getProjectList(this.query);
    let budget = await new ProjectServices().getNotification(reject_id)
    console.log(budget);
    store.commit("setBudgetNotification", budget.data.notification_count);
    this.loading = false;
  }
}
@Service()
export class ActiveProjectForPetitionClass {
loading: boolean = true;
petition:any;
async getForCreatePetition(id:string) {
  this.loading = true
  this.petition = await new ProjectServices().getForCreatePetition(id)
  this.loading = false
}
async getForEditPetition(id:string,from:number,to:number) {
  this.loading = true
  this.petition = await new ProjectServices().getForEditPetition(id,from,to)
  this.loading = false
}
}