
import { Options, Vue } from "vue-class-component";
import {IndicatorMetricModel,IndicatorResponsiblePersonModel} from "@/model/indicator";
import { ReportB19Class} from "@/data/report";
import Container from "typedi";
import { DocumentDocx } from "@/document/docx/B";

@Options({
    components: {},
})
export default class ReportB01View extends Vue {
    IndicatorMetricModel = IndicatorMetricModel
    IndicatorResponsiblePersonModel = IndicatorResponsiblePersonModel
    dataSingle: ReportB19Class = Container.get(
      ReportB19Class
    );
    mounted() {
    this.initData();
    }
    async initData() {
      let query: any = {}
      query.search = this.$route.params.budgetYear 
    await this.dataSingle.getReportB19List(query);
    }

    async createDocx() {
    const docx = new DocumentDocx();
    await docx.reportB01(this.dataSingle.data);
    docx.save("Report_B01.docx");
    }
}
