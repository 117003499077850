
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import IndicatorProfileModel from "@/model/indicator";
import { ActiveIndiciatorListClass } from "@/data/indicator";
import { ActiveBudgetYearClass,ActiveTypeClass } from "@/data/system";
import { ActiveSectorListClass } from "@/data/sector";
import SectorModel from "@/model/sector";
import Container from "typedi";
import { result } from "lodash";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class IndiciatorListView extends Vue {
  dataList: ActiveIndiciatorListClass = Container.get(ActiveIndiciatorListClass);
  yearSetting:ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  dataSetting: ActiveTypeClass = Container.get(ActiveTypeClass);
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  docs: Array<IndicatorProfileModel> = [];
  sector: Array<SectorModel> = [];
  pages: number = 0;
  total: number = 0;
  filter1: any = {}
  filter2: any = {}
  show: number = 20;
  page: number = 1;
  budgetYearTo:number = 2565
  store = useStore();


  renderTableTool(item: IndicatorProfileModel) {
    let result:Array<string> = ["Detail"]
    if(this.store.getters.hasRight("indicator_followup_editsector") && this.store.state.sector2===item.sector) {
      result.push("List")
    }
  }
  mounted() {
    this.initData();
  }
  async initData() {
    this.budgetYearTo = 2565
    this.dataSetting.getTypeSetting("ประเภทตัวชี้วัด");
    this.yearSetting.getBudgetYearSetting();
    let query = {
      search: {
        budgetYear : this.filter1,
        type : this.filter2
      },
      limit: this.show,
      page: this.page,
      sort: {
       createdAt:"desc"
      }
    }
    console.log("ok", this.dataList)
    await this.dataList.getIndiciatorList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
    await this.sectorList.getSectorList({});
    this.sector = this.sectorList.data!.docs;
  }
  getSector(id:any) {
    console.log('id',id)
    try {
      let found = this.sector.find((el)=>el.id = id)
      return found!.name

    } catch (error) {
      return "-"
    }
  }
  clear() {
    this.filter1 = undefined
    this.filter2 = undefined
    this.initData()
  }
  get types() {
    let arr : Array<Object> = []
    this.dataSetting.data?.forEach((el)=>{
      arr.push({
        name:el.name,
        value:el.name
      })
    })
    return arr
  }
  get budgetYear() {
    let arr: Array<Object> = [];
    this.yearSetting.data?.forEach((el) => {
      arr.push({
        name: el.text1,
        value: parseInt(el.name),
      });
    });
    return arr;
  }
}
