
  import { Options, Vue } from "vue-class-component";
  import Container from "typedi";
  import { ActiveStrategicBudgetYearClass } from "@/data/strategic";
  import { ActiveProjectSingleClass } from "@/data/project";
  @Options({
    props:{
      modelValue: Array,
      label: String,
      disabled:Boolean,
    }
  })
  export default class PlanTableComponent extends Vue {
    strategicBudgetYear: ActiveStrategicBudgetYearClass = Container.get(
      ActiveStrategicBudgetYearClass
    );
    project: ActiveProjectSingleClass = Container.get(
      ActiveProjectSingleClass
    );
    label!:string
    modelValue!:Array<string>
    val:Array<string> = []
    disabled!:boolean
    pushItem(){
      if (typeof this.val === "string") this.val = [];
      else if (!Array.isArray(this.val)) this.val = [];
      this.val.push("");
      this.emitUp();
    }
    deleteItem(i:number){
      let c = confirm("ยืนยันลบรายการหรือไม่?")
      if(c){
        this.val.splice(i,1)
        this.emitUp()
      }
    }
    mounted(){
      this.val = this.modelValue??[]
      this.initData()
    }
    initData(){
      this.strategicBudgetYear.getStrategicFromBudgetYear(this.project.data!.budgetYear)
    }
    emitUp(){
      this.$emit("update:modelValue", this.val);
    }
  }
  