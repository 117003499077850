
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveFollowUpSingleClass } from "@/data/followup";
import Container from "typedi";
import BudgetModel from "@/model/budget";
import FollowUpModel from "@/model/followup";
import { ActiveProjectSingleClass } from "@/data/project";
import { Watch } from "vue-property-decorator";
import JMCalendar from "../../../components/JMCalendar.vue";
import { ActiveWithdrawSingleClass } from "@/data/withdraw";
import _ from 'lodash'


@Options({
  components: {},
})
export default class PlanTableComponent extends Vue {
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);
  budget: ActiveBudgetSingleClass = Container.get(ActiveBudgetSingleClass);
  followup: ActiveFollowUpSingleClass = Container.get(
    ActiveFollowUpSingleClass
  );
  withdraw: ActiveWithdrawSingleClass = Container.get(
    ActiveWithdrawSingleClass
  );
  withdrawDetail: ActiveWithdrawSingleClass = Container.get(
    ActiveWithdrawSingleClass
  );
  ERP =
    "กระดาษหัวจดหมายมีที่อยู่ A4|55/2566|Active|ใช้สำหรับอาคารสำนักงาน A|PR66000220";
  doc: ProjectModel = new ProjectModel({});

  get sumERP() {
    try {
      return this.ERPData.map((d) => d.IVZS_AmountUsed).reduce(
        (a, b) => a + b,
        0
      );
    } catch (error) {
      return 0;
    }
  }
  mounted() {
    this.followup.data = new FollowUpModel({});
    // this.doc = this.budget.data!
    this.withdrawDetail.getWithdrawSingle(this.withdraw.data?.id!);
  }

  get ERPData() {
    try {
      let arr = this.withdrawDetail.data?.erp as Array<any>;
      return arr.map((item) => ({
        IVZS_AmountUsed: item.IVZS_AmountUsed,
        IVZS_POHeadText: item.IVZS_POHeadText,
        IVZS_POLineText: item.IVZS_POLineText,
        IVZS_Voucher: item.IVZS_Voucher,
        IVZS_MainAccAndDim: item.IVZS_MainAccAndDim,
        IVZS_Date: item.IVZS_Date,
      }));
    } catch (error) {
      return [];
    }
  }
  get ERPContractNumber() {
    try{
    let POLineText = this.ERPData[0].IVZS_POLineText
    let ContractNumber = _.split(POLineText, '|')[1]
    return ContractNumber}
    catch (error) {
      return "-";
    }
  }
  clearBudget() {
    this.budget.data = new BudgetModel({});
  }
  async submitForm() {
    if (this.followup.data?.id === undefined || this.followup.data?.id === "") {
      await this.followup.createFollowUpSingle(this.withdraw.data?.smisCode!);
    } else {
      await this.followup.updateFollowUpSingle();
    }
    await this.project.getProjectSingle(this.$route.params.id as string);
  }
  openSandITModal() {
    // alert("เปิดสิ")
    let btn = document.getElementById("modalITSandBtn") as HTMLElement;
    btn.click();
  }

  getMonth(i: number) {
    switch (i) {
      case 0:
        return "ต.ค.";
        break;
      case 1:
        return "พ.ย.";
        break;
      case 2:
        return "ธ.ค.";
        break;
      case 3:
        return "ม.ค.";
        break;
      case 4:
        return "ก.พ.";
        break;
      case 5:
        return "มี.ค.";
        break;
      case 6:
        return "เม.ย.";
        break;
      case 7:
        return "พ.ค.";
        break;
      case 8:
        return "มิ.ย.";
        break;
      case 9:
        return "ก.ค.";
        break;
      case 10:
        return "ส.ค.";
        break;
      case 11:
        return "ก.ย.";
        break;
    }
  }
}
