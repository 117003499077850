
import { Options, Vue } from "vue-class-component";
import IndicatorProfileModel, {
  IndicatorMetricModel,
  IndicatorResponsiblePersonModel,
} from "@/model/indicator";
import { ActiveIndiciatorSingleClass } from "@/data/indicator";
import { ActiveBudgetYearClass, ActiveTypeClass } from "@/data/system";
import Container from "typedi";
import { ActiveSectorListClass } from "@/data/sector";
import SectorModel from "@/model/sector";
import UserModel from "@/model/user";

@Options({
  components: {},
})
export default class IndiciatorFormView extends Vue {
  IndicatorMetricModel = IndicatorMetricModel;
  IndicatorResponsiblePersonModel = IndicatorResponsiblePersonModel;
  dataSingle: ActiveIndiciatorSingleClass = Container.get(
    ActiveIndiciatorSingleClass
  );
  dataSetting: ActiveTypeClass = Container.get(ActiveTypeClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  dataList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  docs: Array<SectorModel> = [];
  pages: number = 0;
  total: number = 0;

  updateFromAutoPerson(user:UserModel,item:any){
    item.name = user.fullName
  }

  get metric() {
    try {
      return this.dataSingle.data!.metric;
    } catch (error) {
      return [];
    }
  }
  get responsibles() {
    try {
      return this.dataSingle.data!.responsibles;
    } catch (error) {
      return [];
    }
  }
  get types() {
    let arr: Array<Object> = [];
    this.dataSetting.data?.forEach((el) => {
      arr.push({
        name: el.name,
        value: el.name,
      });
    });
    return arr;
  }
  get budgetYear() {
    let arr: Array<Object> = [];
    this.yearSetting.data?.forEach((el) => {
      arr.push({
        name: el.text1,
        value: parseInt(el.text1),
      });
    });
    return arr;
  }
  get sector() {
    let arr: Array<Object> = [];
    this.docs.forEach((el) => {
      arr.push({
        name: el.name,
        value: el.id,
      });
    });
    return arr;
  }
  mounted() {
    this.getData();
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new IndicatorProfileModel({});
      this.dataSingle.data.metric.push(
        new IndicatorMetricModel({finishDate:new Date()}),
        new IndicatorMetricModel({finishDate:new Date()}),
        new IndicatorMetricModel({finishDate:new Date()}),
        new IndicatorMetricModel({finishDate:new Date()}),
        new IndicatorMetricModel({finishDate:new Date()})
      );
    }
  }
  async getData() {
    await this.dataList.getSectorList({
      search: { category: "ฝ่าย/สำนัก" } });
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
    this.yearSetting.getBudgetYearSetting();
    this.dataSetting.getTypeSetting("ประเภทตัวชี้วัด");
  }
  async initData() {
    this.dataSingle.data = undefined;
    await this.dataSingle.getIndiciatorSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createIndiciatorSingle();
    } else {
      this.dataSingle.updateIndiciatorSingle();
    }
  }
}
