
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import {
  ActiveBudgetSingleClass,
  ActiveBudgetParentSingleClass,
} from "@/data/budget";
import { ActiveWithdrawListClass, ActiveWithdrawSingleClass } from "@/data/withdraw";
import Container from "typedi";
import BudgetModel from "@/model/budget";
import WithdrawModel, { WithdrawItemModel } from "@/model/withdraw";
import { ActiveProjectSingleClass } from "@/data/project";
import { Watch } from "vue-property-decorator";
import WithdrawServices from "@/service/withdraw";
import SystemServices from "@/service/system";
import { ActiveAccountListClass } from "@/data/account";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import * as _ from "lodash"
import { confirmAction } from "@/decorator";

@Options({
  components: {},
})
export default class PlanTableComponent extends Vue {
  store = useStore();
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);
  budget: ActiveBudgetSingleClass = Container.get(ActiveBudgetSingleClass);
  parent: ActiveBudgetParentSingleClass = Container.get(
    ActiveBudgetParentSingleClass
  );
  withdraw: ActiveWithdrawSingleClass = Container.get(
    ActiveWithdrawSingleClass
  );
  ZPs: Array<any> = [];

  ZPList: ActiveAccountListClass = Container.get(ActiveAccountListClass);
  doc: ProjectModel = new ProjectModel({});
  items: Array<WithdrawItemModel> = [];
  withdrawList: ActiveWithdrawListClass = Container.get(
    ActiveWithdrawListClass
  )
  disable = false
 


  @Watch("withdraw.data")
  isSent: string = "";
  //remain:number = 0
  // updatewithdrawType(){
  //   this.remain =  this.budget.monthRemain(this.withdraw.data!.month!,this.withdraw.data!.withdrawType!)
  // }
  updateItems() {
    try {
      this.items = this.withdraw.data!.items!;
    } catch (error) {}
  }
  //monthNow: Date = DateTime.fromObject({day:13,month:4,year:2023}).toJSDate()
  monthNow: Date = DateTime.now().toJSDate();
  get withdrawableMonths() {
    let results: Array<number> = [];
    switch (this.withdraw.data!.month) {
      case 0:
        results = [0];
        break;
      case 1:
        results = [0, 1];
        break;
      case 2:
        results = [0, 1, 2];
        break;
      case 3:
        results = [3];
        break;
      case 4:
        results = [3, 4];
        break;
      case 5:
        results = [3, 4, 5];
        break;
      case 6:
        results = [6];
        break;
      case 7:
        results = [6, 7];
        break;
      case 8:
        results = [6, 7, 8];
        break;
      case 9:
        results = [9];
        break;
      case 10:
        results = [9, 10];
        break;
      case 11:
        results = [9, 10, 11];
        break;
      default:
        results = [];
        break;
    }
    let monthToday = this.monthNow.getMonth();
    let monthTodayDate = this.monthNow.getDate();
    if (monthToday === 0) {
      if (monthTodayDate >= 1 && monthTodayDate <= 15)
        results.push(...[0, 1, 2]);
    }
    if (monthToday === 3) {
      if (monthTodayDate >= 1 && monthTodayDate <= 15)
        results.push(...[3, 4, 5]);
    }
    if (monthToday === 6) {
      if (monthTodayDate >= 1 && monthTodayDate <= 15)
        results.push(...[6, 7, 8]);
    }
    return results;
  }
 

  @Watch("items", { deep: true })
  zpUpdate() {
    console.log("update @input");
    this.items.forEach((item) => {
      try {
        let found = this.ZPs.find((zp) => {
          // console.log({ zp });
          // console.log("zp/code", zp.code, item.name, zp.code === item.name);
          return zp.code === item.name;
        });
        // console.log({ found });
        item.unit = found.unit;
        item.account = found.accountCode;
        item.product = found.product
      } catch (error) {}
    });
  }
  async fetchZP() {
    // ประเภทแหล่งเงินงบประมาณในหน้าแผน
    await this.ZPList.getAccountList({});
    this.ZPs = this.ZPList.data!.docs.map((item) => ({
      value: item.id,
      code: item.code,
      unit: item.unit,
      product: item.codeName,
      accountCode: item.accountCode,
      name: `${item.code} : ${item.codeName} [${item.accountCode}]`,
    }));
  }
  checkAmount(index:number){
   let  amount = this.withdraw.data!.items?.map(item=>item.amount).reduce((a,b)=>a+b,0)??0
    if(amount>this.budget.monthRemain(this.withdraw.data!.month!,this.withdraw.data!.withdrawType!)){
      alert("คุณเบิกเงินเกินยอดเงินที่เบิกได้");
      this.withdraw.data!.items![index].amount = 0
    }
   

  }
  get withdrawItems(){
    if (this.withdraw.data?.id == undefined || this.withdraw.data?.id == "") {
      this.items = this.withdraw.data!.items!;

    } else {
      this.items = this.withdraw.data!.items!

    }
    return  this.items
  }
  get isCreate(){
    if(this.store.state.username==this.withdraw.data!.sAMAccountName || this.withdraw.data!.id ==""|| this.withdraw.data!.id ==undefined){
    this.disable = false
    return true
   }
   else if (this.store.state.username!=this.withdraw.data!.sAMAccountName){
    this.disable = true
    return false
   }
  else{
    this.disable = false
    return false
  }


}
  mounted() {
    if (this.withdraw.data?.id == undefined || this.withdraw.data?.id == "") {
      this.withdraw.data = new WithdrawModel({});
    } else {
      this.items = this.withdraw.data!.items!
    }
 
    this.fetchZP();
    // this.doc = this.budget.data!

  }
  clearBudget() {
    this.budget.data = new BudgetModel({});
  }
  pushBudgetOverlap() {
    this.items.push(new WithdrawItemModel({}));
  }

  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  deleteItem(i: number, id?: string) {
    try {
      if (id) {
        let service = new WithdrawServices();
        service.deleteWithdrawMemberSingle(id);
      }
    } catch (error) {}
    try {
      this.withdraw.data!.items?.splice(i, 1);
    } catch (error) {}
  }
  async submitForm() {
    this.withdraw.data!.items = this.items;
    console.log("items", this.withdraw.data!.items);
    if (this.withdraw.data?.id === undefined || this.withdraw.data?.id === "") {
      this.withdraw.data!.budget_code = this.budget.data!.code;
      await this.withdraw.createWithdrawSingle(
        this.budget.data?.id!,
        this.store.state.userId
      );
      
    } else {
      await this.withdraw.updateWithdrawSingle();
    }
    await this.project.getProjectSingle(this.$route.params.id as string);
    this.getWithdrawList();
  }
  async getWithdrawList() {
    let query: any = {
      search: {
        budget: {
          project: {
            id: this.$route.params.id as string,
          },
        },
      },
      // sort: { isSent: "desc" ,createdAtFormat: "desc"},
      sort: [{ isSent: "desc" }, { createdAt: "desc" }],
      // {
      //   // createdAt:"desc",

      //   // smisCode: "desc",
      // },
    };
    if (this.isSent != "" && this.isSent == "yes") query.search.isSent = true;
    if (this.isSent != "" && this.isSent == "no") query.search.isSent = false;
    await this.withdrawList.getWithdrawList(query);
  }
  getMonth(i: number) {
    switch (i) {
      case 0:
        return "ต.ค.";
        break;
      case 1:
        return "พ.ย.";
        break;
      case 2:
        return "ธ.ค.";
        break;
      case 3:
        return "ม.ค.";
        break;
      case 4:
        return "ก.พ.";
        break;
      case 5:
        return "มี.ค.";
        break;
      case 6:
        return "เม.ย.";
        break;
      case 7:
        return "พ.ค.";
        break;
      case 8:
        return "มิ.ย.";
        break;
      case 9:
        return "ก.ค.";
        break;
      case 10:
        return "ส.ค.";
        break;
      case 11:
        return "ก.ย.";
        break;
    }
  }
get getNotAdmin(){
 let admin =  this.store.state.roles.includes("ผู้ดูแลระบบ")
 let notAdmin:boolean =false
 if(admin !=true){
  notAdmin = true
 }
  return notAdmin
  }
get getUser(){
 let username =  this.store.state.username
  return username
  }
}
