
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import DocumentModel from "@/model/document";
import { ActiveDocumentListClass } from "@/data/document";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import ADDRESS from "@/setting"
import { useStore } from "vuex";
@Options({
  components: {},
})
export default class DocumentListView extends Vue {
  dataList: ActiveDocumentListClass = Container.get(ActiveDocumentListClass);
  docs: Array<DocumentModel> = [];
  pages: number = 0;
  total: number = 0;
  show: number = 20;
  page: number = 1;
  active: number = 0
  ADDRESS=ADDRESS
  store = useStore();

  renderTableTool(item: DocumentModel) {
    let result:Array<string> = ["Detail"]
    if(this.store.getters.hasRight("publicrelation_document_editall")) result.push("Edit")
    if(this.store.getters.hasRight("publicrelation_document_delete")) result.push("Delete")
    if(this.store.getters.hasRight("publicrelation_document_detail")) result.push("Detail")
    return result;
  }
openModalItem(i:number) {
 
    this.active = i;
    (this.$refs.openmodal as HTMLElement).click()
  }
  mounted() {
    this.initData();
  }
  async initData() {
    let query: any = {
     
      limit: this.show,
      page: this.page,
      skip: (this.page-1)*this.show,
      sort: {
        createdAt: "desc",
      },}

    await this.dataList.getDocumentList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
