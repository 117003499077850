
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import PlanTable from "@/views/project/components/PlanTable.vue";
import ModalBudgetIndicator from "@/views/project/components/ModalBudgetIndicator.vue";
import ModalBudgetItemMoney from "@/views/project/components/ModalBudgetItemMoney.vue";
import { ActiveProjectSingleClass } from "@/data/project";
import Container from "typedi";
import { ActiveBudgetSingleClass } from "@/data/budget";
import BudgetModel from "../../model/budget";
import { DocumentExcel } from "@/document/xlsx";
import { useStore } from "vuex";
import Document from "@/document/pdf";
import { confirmAction } from "@/decorator";

@Options({
  components: {
    PlanTable,
    ModalBudgetIndicator,
    ModalBudgetItemMoney,
  },
})
export default class BudgetPlanView extends Vue {
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  store = useStore();
  show: number = 20;
  page: number = 1;

  @confirmAction("คุณต้องการขออนุมัติคำของบประมาณหรือไม่?")
  requestApprove() {
    this.dataSingle.data!.isRequest = true
    this.dataSingle.data!.step = 0
    this.dataSingle.updateProjectSingle();
  }

  async clearBudget() {
    this.activeBudget.data = new BudgetModel({});
    console.log(this.activeBudget, typeof this.activeBudget.data)
    this.openModal()
  }
  openModal() {
    this.$nextTick(() => {
      let ref = this.$refs.openModalFromPlanView as HTMLElement;
      ref.click();
    });
  }
  mounted() {
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new ProjectModel({});
    }
  }
  async initData() {
    this.dataSingle.data = undefined;
    await this.dataSingle.getProjectSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createProjectSingle();
    } else {
      this.dataSingle.updateProjectSingle();
    }
  }
  ExportExcel() {
    let docExcel = new DocumentExcel();
    docExcel.createA09(this.dataSingle);
  }
  ExportPDF() {
    const document = new Document();
    document.create09(this.dataSingle);
    document.save(`reportA09.pdf`);
    console.log('pdf');
    
  }
}
