import { PaginateResponse } from "@/common";
import UserModel from "@/model/user";
import ProjectModel from "@/model/project";
import UserServices from "@/service/user";
import { Service } from 'typedi';
import router from "@/router"
import { useToast, POSITION } from "vue-toastification";
import store from "@/store"
import { confirmAction } from "@/decorator";

const toast = useToast();
@Service()
export class ActiveUserSingleClass {
  data?: UserModel;
  loading: boolean = true;

  getTableToolBudget(Project:ProjectModel) {
    try {
      return Project.items
    } catch (error) {
      return 'error'+error
    }
  }
  getTableToolAdjust(Project:ProjectModel) {
    
  }

  async loginUserSingle(username: string, password: string) {
    this.loading = true
    this.data = await new UserServices().loginUser(username, password)
    this.loading = false
  }
  async getUserSingle(id: string) {
    this.loading = true
    this.data = await new UserServices().getUserSingle(id)
    this.loading = false
  }
  async updateUserSingle(id: string) {
    this.loading = true
    this.data = await new UserServices().updateUser(id, this.data!)
    this.loading = false
  }
  async createUserSingle() {
    this.loading = true
    this.data = await new UserServices().createUser(this.data!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteUserSingle(id: string) {
    this.loading = true
    this.data = await new UserServices().deleteUserSingle(id)
    this.loading = false
  }
  async login(username: string, password: string) {
    console.log("loggin in...")
    this.loading = true
    let result = await new UserServices().Login(username, password).catch(error=>{
      alert("พบปัญหาในการลงชื่อเข้าใช้")
    })
    console.log('result',result);
    
    if (result === "error") {
      const toast = useToast();
      toast.error("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบ", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      console.log({result})
      store.commit("setUser", result)
      this.data = result as UserModel
      router.push("/portal")
    }
    this.loading = false
  }
  // async login(username: string, password: string) {
  //   this.loading = true
  //   let result = await new UserServices().Login(username, password).then(result => {
  //   this.data = result as UserModel
  //   store.commit("setUser", result);
  //   router.push("/portal")
  //   }).catch(error => {
  //     console.log(error)
  //     toast.error("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบ", {
  //       timeout: 2000,
  //       position: POSITION.BOTTOM_RIGHT,
  //     });
  //   })
  //   this.loading = false
  // }
}

@Service()
export class ActiveUserListClass {
  data?: PaginateResponse<UserModel>;
  loading: boolean = true;
  async getUserList(query: any) {
    this.loading = true
    this.data = await new UserServices().getUserList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteUserFromList(id: string) {
    this.loading = true
    let tmp = await new UserServices().deleteUserSingle(id)
    let index = this.data!.docs.findIndex(doc => doc.id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }
}