
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";

@Options({
  components: {},
})
export default class PlanTableComponent extends Vue {
  doc: ProjectModel = new ProjectModel({})
}
