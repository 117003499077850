import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-end selecto-target px-1" }
const _hoisted_2 = {
  class: "d-flex justify-content-stretch h-100 position-relative show-erp-item flex-column",
  style: {"width":"70px"}
}
const _hoisted_3 = { class: "drag-money-target w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (item, i) => {
    return _createElementVNode("td", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.budget.diffOfMonthFormat(i)), 1)
      ])
    ])
  }), 64))
}