
import { Options, Vue } from "vue-class-component";
import PositionModel from "@/model/position";
import {
  ActivePositionListClass,
  ActivePositionSingleClass,
} from "@/data/position";
import Container from "typedi";

@Options({
  components: {},
})
export default class PositionFormView extends Vue {
  disabled:boolean = false
  dataSingle: ActivePositionSingleClass = Container.get(
    ActivePositionSingleClass
  );
  mounted() {
    this.disabled = this.$route.path.search('/detail')!=-1
    if (this.$route.params.id !== undefined) this.initData();
    else { 
      this.dataSingle.data = new PositionModel({})
    }
  }
  async initData() {
    await this.dataSingle.getPositionSingle(this.$route.params.id as string);
  }
  async submitForm() {
  
    if (this.$route.params.id === undefined) {
      
      await this.dataSingle.createPositionSingle();
    } else {
      this.dataSingle.updatePositionSingle();
    }
  }
}
