
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import { ActiveBudgetSingleClass,ActiveBudgetParentSingleClass } from "@/data/budget";
import Container from "typedi";
import BudgetModel from "@/model/budget";
import ModalBudgetIndicator from "./ModalBudgetIndicator.vue";
import { ActiveProjectSingleClass } from "@/data/project";
import UserModel from "@/model/user";
import { ActiveBudgetSourceTypeClass, ActiveBudgetDegreeClass,ActiveSystemSingleClass } from "@/data/system";
import SystemServices from "@/service/system";

@Options({
  components: {
    ModalBudgetIndicator,
  },
})
export default class PlanTableComponent extends Vue {
  budget: ActiveBudgetSingleClass = Container.get(ActiveBudgetSingleClass);
  // budgetParent: ActiveBudgetParentSingleClass = Container.get(ActiveBudgetParentSingleClass);
  //budgetParent2: ActiveBudgetParentSingleClass = Container.get(ActiveBudgetParentSingleClass);
  get degreesMap(){
    return this.degreeSetting.data!.map(dat=>({value:dat.text1,name:dat.name}))
  }
  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  budgetSourceTypeSetting: ActiveBudgetSourceTypeClass = Container.get(
    ActiveBudgetSourceTypeClass
  );
  degreeSetting:ActiveBudgetDegreeClass= Container.get(
    ActiveBudgetDegreeClass
  );
  doc: ProjectModel = new ProjectModel({});
  budgetOverlap: Array<any> = [];
  disable = false
  operationTypes = [
    { name: "รายการอื่นๆ", value: "รายการอื่นๆ" },
    { name: "เตรียมการและบริหารจัดการ", value: "เตรียมการและบริหารจัดการ" },
  ];
  degree:string =""
  budgetTypes = [
      { value: "งบประมาณประจำปี,งบอุดหนุน,1400" , name: "งบประมาณประจำปี (งบอุดหนุน) 1400"},
      { value: "งบประมาณประจำปี,งบลงทุน,1300" , name: "งบประมาณประจำปี (งบลงทุน) 1300"},
      { value: "งบกองทุนพัฒนากีฬาแห่งชาติ,งบอุดหนุน,1600" , name: "งบกองทุนพัฒนากีฬาแห่งชาติ (งบอุดหนุน) 1600"},
      { value: "งบกองทุนพัฒนากีฬาแห่งชาติ,งบลงทุน,1600" , name: "งบกองทุนพัฒนากีฬาแห่งชาติ (งบลงทุน) 1600"},
      { value: "งบเงินสะสมของ กกท.,งบอุดหนุน,4000" , name: "งบเงินสะสมของ กกท. (งบอุดหนุน) 4000"},
      { value: "งบเงินสะสมของ กกท. ,งบลงทุน,4000" , name: "งบเงินสะสมของ กกท.  (งบลงทุน) 4000"},
      { value: "งบกลาง ,งบอุดหนุน,1500" , name: "งบกลาง  (งบอุดหนุน) 1500"},
      { value: "งบกลาง,งบลงทุน,1500" , name: "งบกลาง (งบลงทุน) 1500"},
      { value: "งบเงินรายได้,งบอุดหนุน,2000" , name: "งบเงินรายได้ (งบอุดหนุน) 2000"},
      { value: "งบเงินรายได้,งบลงทุน,2000" , name: "งบเงินรายได้ (งบลงทุน) 2000"},
      { value: "งบประมาณแผ่นดิน,งบอุดหนุน,1000" , name: "งบประมาณแผ่นดิน (งบอุดหนุน) 1000"},
      { value: "งบประมาณแผ่นดิน,งบลงทุน,1000" , name: "งบประมาณแผ่นดิน (งบลงทุน) 1000"},
  ];
  budgetTypes1 = [
  { value: "งบประมาณประจำปี,งบอุดหนุน,1400" , name: "งบประมาณประจำปี (งบอุดหนุน) 1400"},
      { value: "งบกองทุนพัฒนากีฬาแห่งชาติ,งบอุดหนุน,1600" , name: "งบกองทุนพัฒนากีฬาแห่งชาติ (งบอุดหนุน) 1600"},
      { value: "งบเงินสะสมของ กกท.,งบอุดหนุน,4000" , name: "งบเงินสะสมของ กกท. (งบอุดหนุน) 4000"},
      { value: "งบกลาง ,งบอุดหนุน,1500" , name: "งบกลาง  (งบอุดหนุน) 1500"},
      { value: "งบเงินรายได้,งบอุดหนุน,2000" , name: "งบเงินรายได้ (งบอุดหนุน) 2000"},
      { value: "งบประมาณแผ่นดิน,งบอุดหนุน,1000" , name: "งบประมาณแผ่นดิน (งบอุดหนุน) 1000"},
  ]
  budgetTypes2 = [
  { value: "งบประมาณประจำปี,งบลงทุน,1300" , name: "งบประมาณประจำปี (งบลงทุน) 1300"},
      { value: "งบกองทุนพัฒนากีฬาแห่งชาติ,งบลงทุน,1600" , name: "งบกองทุนพัฒนากีฬาแห่งชาติ (งบลงทุน) 1600"},
      { value: "งบเงินสะสมของ กกท. ,งบลงทุน,4000" , name: "งบเงินสะสมของ กกท.  (งบลงทุน) 4000"},
      { value: "งบกลาง,งบลงทุน,1500" , name: "งบกลาง (งบลงทุน) 1500"},
      { value: "งบเงินรายได้,งบลงทุน,2000" , name: "งบเงินรายได้ (งบลงทุน) 2000"},
      { value: "งบประมาณแผ่นดิน,งบลงทุน,1000" , name: "งบประมาณแผ่นดิน (งบลงทุน) 1000"},
  ]

  expenseTypes = [
    {
      value: "การจ้างผู้ช่วยปฏิบัติงาน",
      name: "การจ้างผู้ช่วยปฏิบัติงาน ฯ (ประกอบด้วย จำนวน/วุฒิการศึกษา เป็นต้น)",
    },
    {
      value: "ค่าใช้จ่ายในการเดินทางต่างประเทศ",
      name: "ค่าใช้จ่ายในการเดินทางต่างประเทศ",
    },
    {
      value: "ค่าใช้จ่ายในการเดินทางในประเทศ",
      name: "ค่าใช้จ่ายในการเดินทางในประเทศ",
    },
    { value: "ค่าเช่าเครื่องถ่ายเอกสาร", name: "ค่าเช่าเครื่องถ่ายเอกสาร" },
    { value: "การจัดซื้อวัสดุสำนักงาน", name: "การจัดซื้อวัสดุสำนักงาน" },
    { value: "ค่าเบี้ยประชุม/ค่าตอบแทน", name: "ค่าเบี้ยประชุม/ค่าตอบแทน" },
    {
      value: "ค่าจัดประชุมคณะกรรมการต่างๆ",
      name: "ค่าจัดประชุมคณะกรรมการต่างๆ",
    },
    { value: "จ้างที่ปรึกษา", name: "จ้างที่ปรึกษา" },
    { value: "การจัดทำระบบไอที", name: "การจัดทำระบบไอที" },
    { value: "งบลงทุนภายในรายการ", name: "งบลงทุนภายในรายการ" },
    {
      value: "การปฏิบัติงานนอกเวลาราชการ (OT)",
      name: "การปฏิบัติงานนอกเวลาราชการ (OT)",
    },
  ];
  updateName(val:any){
    console.log("ทำงานจริงรึเปล่า")
    console.log("budget", this.budget.data!.name)
    console.log("budget val", val)
  }
  updateFromAutoPerson(user:UserModel,ev:Event){
    console.log({user, ev})
    this.budget.data!.responsible_id = user.id!
    this.budget.data!.responsible.prefix = user.prefix
    this.budget.data!.responsible.firstName = user.firstName
    this.budget.data!.responsible.lastName = user.lastName
    // item.position = user.position
    // item.sector1 = user.sector1?.name
    // item.sector2 = user.sector2?.name
    // item.telephone = user.telephone
    // item.email = user.email
  }
  mounted() {
    // this.doc = this.budget.data!
    this.fetchSetting()
  }
  async fetchSetting(){
    // ประเภทแหล่งเงินงบประมาณในหน้าแผน
    let settingExpense = await new SystemServices().getSystemByName("ประเภทค่าใช้จ่ายในหน้าแผน")
    console.log({settingExpense})
    this.expenseTypes = settingExpense.map(item=>( { value:item.name, name: item.name}))

    this.budgetSourceTypeSetting.getBudgetSourceTypeSetting("ประเภทแหล่งงบประมาณในหน้าแผน")
    this.degreeSetting.getBudgetDegreeSetting("ประเภทวุฒิการศึกษาในหน้าแผน")
  }
  clearBudget() {
    this.budget.data = new BudgetModel({});
  }
  getMonth(i:number) {
    switch (i) {
      case 0 : return  "ตุลาคม"; break;
      case 1 : return  "พฤศจิกายน"; break;
      case 2 : return  "ธันวาคม"; break;
      case 3 : return  "มกราคม"; break;
      case 4 : return  "กุมภาพันธ์"; break;
      case 5 : return  "มีนาคม"; break;
      case 6 : return  "เมษายน"; break;
      case 7 : return  "พฤษภาคม"; break;
      case 8 : return  "มิถุนายน"; break;
      case 9 : return  "กรกฎาคม"; break;
      case 10 : return  "สิงหาคม"; break;
      case 11 : return  "กันยายน"; break;
    }
  }
  pushBudgetOverlap() {
    this.budgetOverlap.push({
      months: Object.assign([], new Array(12).fill(0)),
    });
  }
  setNameLevel1(){
   
    if(this.budget.data!.operationType ==="เตรียมการและบริหารจัดการ" && this.budget.data!.parent_id == ""){
      this.budget.data!.name = "เตรียมการและบริหารจัดการ"
      this.disable = true
    }

  }
  setNameLevel2(){
    if(this.budget.data!.expenseType ==="การจ้างผู้ช่วยปฏิบัติงาน"){
      this.budget.data!.name = "การจ้างผู้ช่วยปฏิบัติงาน"
      this.disable = true
    }
  }
get getbudgetTypes(){
  if(this.budget.data!.parent_id != ""){
    if(this.budget.data!.budgetTypeCategory=='งบอุดหนุน'){
      return this.budgetTypes1
    }
    else if(this.budget.data!.budgetTypeCategory=='งบลงทุน'){
      return this.budgetTypes2
    }
   else this.budgetSourceTypeSetting.data?.map(item=>({name:item.name,value:item.text1}))
   
  }
  else{
    return this.budgetSourceTypeSetting.data?.map(item=>({name:item.name,value:item.text1}))
  }
}

  async submitForm() {
    let element = this.$refs.closeModalBudgetItemMoney as HTMLElement
    element.click()
    if (this.budget.data?.id == undefined) {
      console.log(this.budget.data)
      await this.budget.createBudgetSingle(this.$route.params.id as string);
    } else {
      await this.budget.updateBudgetSingle();
    }
    await this.activeProject.getProjectSingle(this.$route.params.id as string);
  }
  async submitFormBeforeKPI() {
    let element = this.$refs.closeModalBudgetItemMoney as HTMLElement
    element.click()
    if (this.budget.data?.id == undefined) {
      console.log(this.budget.data)
      await this.budget.createBudgetSingle(this.$route.params.id as string);
      await this.activeProject.getProjectSingle(this.$route.params.id as string);
    }
  }
  checkAll(ev:any){
    let isChecked = ev.target.checked
    console.log('check',ev.target.checked);
    
    if(isChecked){
      for(var i = 0; i < 12; i++) {
        this.budget.data!.items[i].isActive = true
      }
    } else {
    // console.log("isChecked", isChecked)
      for(var i = 0; i < 12; i++) {
        this.budget.data!.items[i].isActive = false
      }
    }
  }
}
