import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import IndicatorProfileModel from "@/model/indicator";
import IndiciatorServices from "@/service/indicator";
import { Service } from 'typedi';
import { useToast, POSITION } from "vue-toastification";

@Service()
export class ActiveIndiciatorSingleClass {
  data?: IndicatorProfileModel;
  loading: boolean = true;
  async getIndiciatorSingle(id: string) {
    this.loading = true
    this.data = await new IndiciatorServices().getIndiciatorSingle(id)
    this.loading = false
  }
  async refreshIndiciatorSingle() {
    this.loading = true
    this.data = await new IndiciatorServices().getIndiciatorSingle(this.data!.id!)
    this.loading = false
  }
  async updateIndiciatorSingle() {
    console.log("update")
    this.loading = true
    this.data = await new IndiciatorServices().updateIndiciator(this.data!.id!, this.data!)
    this.loading = false
  }
  async createIndiciatorSingle() {
    console.log("create")
    this.loading = true
    this.data = await new IndiciatorServices().createIndiciator(this.data!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteIndicatorMetric(id:string) {
    this.loading = true
    let response = await new IndiciatorServices().deleteIndicatorMetric(id)
    const toast = useToast();
    toast.warning("ลบข้อมูลสำเร็จ (ref:S-U1)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT,
    });
    this.refreshIndiciatorSingle()
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteIndicatorResponsible(id:string) {
    this.loading = true
    let response = await new IndiciatorServices().deleteIndicatorResponsible(id)
    const toast = useToast();
    toast.warning("ลบข้อมูลสำเร็จ (ref:S-U1)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT,
    });
    this.refreshIndiciatorSingle()
    this.loading = false
  }
}

@Service()
export class ActiveIndiciatorListClass {
  data?: PaginateResponse<IndicatorProfileModel>;
  loading: boolean = true;
  query:any = {}
  async getIndiciatorList(query: any) {
    this.query = query
    this.loading = true
    this.data = await new IndiciatorServices().getIndiciatorList(query)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteIndiciatorItem(id:string) {
    this.loading = true
    let response = await new IndiciatorServices().deleteIndiciatorSingle(id)
    const toast = useToast();
    toast.warning("ลบข้อมูลสำเร็จ (ref:S-U1)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT,
    });
    this.getIndiciatorList(this.query)
    this.loading = false
  }
  
  
}