
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveProjectSingleClass } from "@/data/project";
import {
  ActiveWithdrawListClass,
  ActiveWithdrawSingleClass,
} from "@/data/withdraw";
import { confirmAction } from "@/decorator";
import BudgetModel from "@/model/budget";
import WithdrawModel from "@/model/withdraw";
import BudgetServices from "@/service/budget";
import Container from "typedi";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {
    budget: Object,
    level: Number,
    budgetParent: String,
    budgetChild: String,
  },
})
export default class PlanTableComponent extends Vue {
  store = useStore();
  budget!: any;
  level!: number;
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  withdraw: ActiveWithdrawSingleClass = Container.get(
    ActiveWithdrawSingleClass
  );
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);
  budgetParent!: string;
  budgetChild!: string;
  menuIsVisible = false;
  withdrawList: ActiveWithdrawListClass = Container.get(
    ActiveWithdrawListClass
  );
  checkMonth(m: number) {
    let budgetMonth = new Date().getMonth();
    let mapped: any = {
      "0": 3,
      "1": 4,
      "2": 5,
      "3": 6,
      "4": 7,
      "5": 8,
      "6": 9,
      "7": 10,
      "8": 11,
      "9": 0,
      "10": 1,
      "11": 2,
    };
    // console.log(mapped[budgetMonth.toString()],m)
    let isAdmin = this.store.state.roles.includes("ผู้ดูแลระบบ");
    return mapped[budgetMonth.toString()] === m || isAdmin;
  }
  filterWithdraws(month: number): Array<any> {
    try {
      // let filter = this.data.withdraws.filter((w: any) => w.month === month);
      // let filter = this.withdrawList.data!.docs.filter((w: any) => w.month === month&&w.budget_id===this.budget.id);
      let filter = this.withdrawList.data!.docs.filter(
        (w: any) => w.month === month && w.budget.code === this.budget.code
      );
      console.log(filter);
      return filter;
    } catch (error) {
      return [];
    }
  }
  sumWithdraws(month: number) {
    try {
      let result = this.filterWithdraws(month)
        .map((w: any) => w.amount)
        .reduce((a, b) => a + b, 0);
      return result;
    } catch (error) {
      return -1;
    }
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteWithdraw(withdraw: WithdrawModel) {
    this.withdraw.data = withdraw;
    await this.withdraw.deleteWithdrawSingle();
    await this.project.getProjectSingle(this.$route.params.id as string);
  }
  openWithdrawModal() {
    let btn = this.$refs.modalWithdrawItemMoneyBtn as HTMLElement;
    btn.click();
  }
  async editWithdraw(withdraw: WithdrawModel) {
    let budgetData = await new BudgetServices().getBudgetSingle(
      withdraw.budget_id!
    );
    this.activeBudget.data = budgetData;
    this.activeBudget.parent = this.budgetParent;
    this.activeBudget.child = this.budgetChild;
    this.activeBudget.withdraws = this.withdrawList.data?.docs.filter(
      (el) => el.budget_id === this.activeBudget.data?.id
    );
    this.withdraw.data = withdraw;
    this.activeBudget.calculateRemaining();
    this.openWithdrawModal();
  }
  createWithdraw(month: number) {
    if (this.budget.isSpecial) {
      alert(
        "กิจกรรมนี้เป็นกิจกรรมควบคุมพิเศษ กรุณาติดต่อ admin หรือ ผู้ประสานงานโครงการ"
      );
    } else {
      this.activeBudget.activeLevel = this.level
      this.activeBudget.parent = this.budgetParent;
      this.activeBudget.child = this.budgetChild;
      this.activeBudget.data = this.budget;
      this.withdraw.data = new WithdrawModel({ month });
      this.activeBudget.withdraws = this.withdrawList.data?.docs.filter(
        (el) => el.budget_id === this.activeBudget.data?.id
      );
      this.openWithdrawModal();
    }
  }
  getIndicatorCode() {
    try {
      let arr = (this.budget ?? {}).indicators;
      arr = arr.map((a: any) => a.indicator.code);
      return arr;
    } catch (error) {
      return "-";
    }
  }
  getIndicatorFull() {
    try {
      let arr = (this.budget ?? {}).indicators;
      arr = arr.map((a: any) => ({
        code: a.indicator.code,
        name: a.indicator.name,
      }));
      return arr;
    } catch (error) {
      return "-";
    }
  }
}
