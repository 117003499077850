
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import AssignmentModel from "@/model/assignment";
import { ActiveAssignmentListClass } from "@/data/assignment";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import { DateTime } from "luxon";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class HistoryAssignListView extends Vue {
  dataList: ActiveAssignmentListClass = Container.get(
    ActiveAssignmentListClass
  );
  docs: Array<AssignmentModel> = [];
  pages: number = 0;
  total: number = 0;
  fromDate: Date = DateTime.now().startOf("month").toJSDate();
  toDate: Date = DateTime.now().endOf("month").toJSDate();
  store = useStore();

  renderTableTool(item: AssignmentModel) {
    let result:Array<string> = ["Detail"]
    if(this.store.getters.hasRight("assignment_history_editall")) result.push("Edit")
    // if(this.store.getters.hasRight("assignment_history_delete")) result.push("Delete")
    if(this.store.getters.hasRight("assignment_history_detail")) result.push("Detail")
    return result;
  }
  mounted() {
    this.initData();
  }
  async initData() {
    let query = {
      search: {
        AND: [
          {
            assigns: {
              some: {
                user_id: this.store.state.userId,
              },
            },
          },
          {
            createdAt: {
              gte: this.fromDate,
              lte: this.toDate,
            },
          },
        ],
      },
      sort:{
        createdAt: "desc"
      }
    };
    await this.dataList.getAssignmentList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
