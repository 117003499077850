import { PaginateResponse } from "@/common";
import ReportModel, { ReportA01Model, ReportA02Model, ReportA04Model, ReportA10Model, ReportA08Type1Model, ReportA08Type2Model, ReportB03Model, ReportB04Model, ReportB05Model, ReportB19Model, ReportA03Model, ReportA05Model, ReportA07Model } from "@/model/report";
import ReportServices from "@/service/report";
import IndicatorReviewModel from "@/model/kpiReview"
import { Service } from 'typedi';
import StrategicModel, { StrategicItemModel } from "@/model/strategic";
import StrategicServices from "@/service/strategic";
import ProjectServices from "@/service/project";
import ProjectModel from "@/model/project";


@Service()
export class ActiveReportListClass {
  data?: PaginateResponse<ReportModel>;
  loading: boolean = true;
  async getReportA01List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA01List(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  async getReportA02List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA02List(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  async getReportA10List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA10List(query)
    console.log({ query, data: this.data })
    this.loading = false
  }

}


@Service()
export class ReportA04Class {
  data?: ReportA04Model;
  loading: boolean = true;
  async getReportA04List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA04(query)
    this.loading = false
  }
}
@Service()
export class ReportA05Class {
  data?: ReportA05Model;
  loading: boolean = true;
  async getReportA05List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA05(query)
    this.loading = false
  }
}
@Service()
export class ReportA07Class {
  data?: ReportA07Model;
  loading: boolean = true;
  async getReportA07List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA07(query)
    this.loading = false
  }
}

@Service()
export class ReportA01Class {
  data?: ReportA01Model;
  loading: boolean = true;
  async getReportA01List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA01(query)
    this.loading = false
  }
}
@Service()
export class ReportA02Class {
  data?: ReportA02Model;
  loading: boolean = true;
  async getReportA02List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA02(query)
    this.loading = false
  }
}
@Service()
export class ReportA03Class {
  data?: ReportA03Model;
  loading: boolean = true;
  async getReportA03SMISList(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA03SMIS(query)
    this.loading = false
  }
  async getReportA03EFORMList(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA03EFORM(query)
    this.loading = false
  }
}

@Service()
export class ReportA08Type1Class {
  data?: ReportA08Type1Model;
  loading: boolean = true;
  async getReportA08Type1List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA08Type1(query)
    this.loading = false
  }
}

@Service()
export class ReportA08Type2Class {
  data?: ReportA08Type2Model;
  loading: boolean = true;
  async getReportA08Type2List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportA08Type2(query)
    this.loading = false
  }
}


@Service()
export class ReportB03Class {
  data?: ReportB03Model;
  loading: boolean = true;
  async getReportB03List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportB03(query)
    this.loading = false
  }
}

@Service()
export class ReportB04Class {
  data?: ReportB04Model;
  loading: boolean = true;
  async getReportB04List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportB04(query)
    this.loading = false
  }
}


@Service()
export class ReportB18Class {
  data?: IndicatorReviewModel;
  loading: boolean = true;
  async getReportB18List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportB18(query)
    this.loading = false
  }
}
@Service()
export class ReportB19Class {
  data?: ReportB19Model;
  loading: boolean = true;
  async getReportB19List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportB19(query)
    this.loading = false
  }
}
@Service()
export class ReportA10Class {
  data: any = {
    plans: []
  }
  // projects?: PaginateResponse<ProjectModel>;
  projects?: Array<ReportA10Model>;
  strategics?: Array<StrategicItemModel>
  tactics?: Array<StrategicItemModel>

  loading: boolean = true;
  async getReportA10ListT2(query: any) {
    this.loading = true
    this.projects = (await new ReportServices().getReportA10ListT2(query.search))
    // this.projects = (await new ProjectServices().getProjectList(query.search))
    let tmpData = (await (new StrategicServices().getStrategicFromBudgetYear(query.search)))
    this.strategics = tmpData.strategies
    this.tactics = tmpData.tactics
    this.loading = false
  }
  pushPlan(){
    this.data.plans.push({
      name:'',
    })
  }
  pushProduct(item:any){
    console.log('item',item);
    
    if(item.products===undefined) item.products = []
    item.products.push({
      name:''
    })
  }
  pushActivities(item:any){
    // console.log('item',item);
    
    if(item.activities===undefined) item.activities = []
    item.activities.push({
      name:''
    })
  }
  pushProject(item:any){
    if(item.projects===undefined) item.projects = []
    item.projects.push({
      name:''
    })
  }
  updateProject(item:any,project:any){
    let foundProject :any = {}
    try {
      foundProject = this.projects!.find((item:any)=>item.code===project.code)
      project.months = foundProject?.months
      project.q1 = foundProject?.q1
      project.q2 = foundProject?.q2
      project.q3 = foundProject?.q3
      project.q4 = foundProject?.q4
      project.sector = foundProject?.sector
      project.budgetSource = foundProject?.budgetSource
      project.totalInvestment = foundProject?.totalInvestment
      project.totalCollection = foundProject?.totalCollection
      project.sumTypes = foundProject?.sumTypes
      // project.total = foundProject?.total
      project.total = project.q1 + project.q2 + project.q3 + project.q4
      project.name = foundProject?.name
      project.months = foundProject?.months
      project.actuals = foundProject?.actuals
      project.budgetType = foundProject?.budgetType
      project.monthsQ1 = foundProject?.months[0]+foundProject?.months[1]+foundProject?.months[2]
      project.monthsQ2 = foundProject?.months[3]+foundProject?.months[4]+foundProject?.months[5]
      project.monthsQ3 = foundProject?.months[6]+foundProject?.months[7]+foundProject?.months[8]
      project.monthsQ4 = foundProject?.months[9]+foundProject?.months[10]+foundProject?.months[11]
      project.monthsSum = foundProject?.months.reduce((a:number,b:number)=>a+b,0)
      project.actualsQ1 = foundProject?.actuals[0]+foundProject?.actuals[1]+foundProject?.actuals[2]
      project.actualsQ2 = foundProject?.actuals[3]+foundProject?.actuals[4]+foundProject?.actuals[5]
      project.actualsQ3 = foundProject?.actuals[6]+foundProject?.actuals[7]+foundProject?.actuals[8]
      project.actualsQ4 = foundProject?.actuals[9]+foundProject?.actuals[10]+foundProject?.actuals[11]
      project.actualsSum = foundProject?.actuals.reduce((a:number,b:number)=>a+b,0)
    } catch (error) {
      
    }
    this.sumMoney(item,foundProject)
    
  }
  sumMoney(item:any,project:any){
    try {
      item.products.forEach((product:any)=>{
        console.log('product',product);
        product.activities.forEach((act:any)=>{
          act.sumQ1 = act.projects.map((project:any)=>project.q1).flat().reduce((a:number,b:number)=>a+b,0)
          act.sumQ2 = act.projects.map((project:any)=>project.q2).flat().reduce((a:number,b:number)=>a+b,0)
          act.sumQ3 = act.projects.map((project:any)=>project.q3).flat().reduce((a:number,b:number)=>a+b,0)
          act.sumQ4 = act.projects.map((project:any)=>project.q4).flat().reduce((a:number,b:number)=>a+b,0)
          act.sumTotalInvestment = act.projects.filter((el:any)=>el.budgetType=="งบลงทุน").map((project:any)=>project.months).flat().reduce((a:number,b:number)=>a+b,0)
          act.sumTotalCollection = act.projects.filter((el:any)=>el.budgetType=="งบอุดหนุน").map((project:any)=>project.months).flat().reduce((a:number,b:number)=>a+b,0)
          // act.sumTotalInvestment = act.projects.map((project:any)=>project.totalInvestment).flat().reduce((a:number,b:number)=>a+b,0)
          // act.sumTotalCollection = act.projects.map((project:any)=>project.totalCollection).flat().reduce((a:number,b:number)=>a+b,0)
          act.sumTotal = act.sumQ1 + act.sumQ2 + act.sumQ3 + act.sumQ4
        })
        product.sumQ1 = product.activities.map((activity:any)=>activity.sumQ1).flat().reduce((a:number,b:number)=>a+b,0)
        product.sumQ2 = product.activities.map((activity:any)=>activity.sumQ2).flat().reduce((a:number,b:number)=>a+b,0)
        product.sumQ3 = product.activities.map((activity:any)=>activity.sumQ3).flat().reduce((a:number,b:number)=>a+b,0)
        product.sumQ4 = product.activities.map((activity:any)=>activity.sumQ4).flat().reduce((a:number,b:number)=>a+b,0)
        product.sumTotalInvestment = product.activities.map((activity:any)=>activity.sumTotalInvestment).flat().reduce((a:number,b:number)=>a+b,0)
        product.sumTotalCollection = product.activities.map((activity:any)=>activity.sumTotalCollection).flat().reduce((a:number,b:number)=>a+b,0)
        product.sumTotal = product.sumQ1 + product.sumQ2 + product.sumQ3 + product.sumQ4
      })
      item.sumQ1 = item.products.map((product:any)=>product.sumQ1).flat().reduce((a:number,b:number)=>a+b,0)
      item.sumQ2 = item.products.map((product:any)=>product.sumQ2).flat().reduce((a:number,b:number)=>a+b,0)
      item.sumQ3 = item.products.map((product:any)=>product.sumQ3).flat().reduce((a:number,b:number)=>a+b,0)
      item.sumQ4 = item.products.map((product:any)=>product.sumQ4).flat().reduce((a:number,b:number)=>a+b,0)
      item.sumTotalInvestment = item.products.map((product:any)=>product.sumTotalInvestment).flat().reduce((a:number,b:number)=>a+b,0)
      item.sumTotalCollection = item.products.map((product:any)=>product.sumTotalCollection).flat().reduce((a:number,b:number)=>a+b,0)
      item.sumTotal = item.sumQ1 + item.sumQ2 + item.sumQ3 + item.sumQ4
      // console.log('item',item);
      // console.log('project',project);
    } catch (error) {
      console.log('er',error);
    }
    
  }
  
}

@Service()
export class ReportB05Class {
  data?: ReportB05Model;
  loading: boolean = true;
  async getReportB05List(query: any) {
    this.loading = true
    this.data = await new ReportServices().getReportB05(query)
    this.loading = false
  }
}
