
import { Options, Vue } from "vue-class-component";
import Container from "typedi";
import UserModel from "@/model/user";
import { ActiveUserSingleClass } from "@/data/user";
import { Watch } from "vue-property-decorator";

@Options({
  components: {},
})
export default class ProjectListView extends Vue {
  singleData: ActiveUserSingleClass = Container.get(ActiveUserSingleClass);
  username: string = "";
  password: string = "";
  showPassword: boolean = true;
  cannotLogin: boolean = false;
  mounted() {
    console.log("env", process.env)
  }
  @Watch("singleData.data")
  checkIfUserLoggedInSuccess() {
    console.log(this.singleData.data);
    if (this.singleData.data!.id != undefined) {
      this.$router.push("/portal/pr/flashnews");
    }
  }
  @Watch("cannotLogin")
  changeToFlase() {
    setTimeout(() => {
      this.cannotLogin = false;
    }, 3000);
  }
  get passwordType(){
    return this.showPassword?"password":"text"
  }
  show() {
    this.showPassword = !this.showPassword;
    console.log("showPassword", this.showPassword);
    this.$forceUpdate()
  }
  async login() {
    console.log("loggin in...")
    let response = await this.singleData.login(this.username, this.password);
     console.log(response);
  }
}
