import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import CabinetTargetModel from "@/model/cabinetTarget";
import CabinetTargetServices from "@/service/cabinetTarget";
import { Service } from 'typedi';

@Service()
export class ActiveCabinetTargetSingleClass {
  data?: CabinetTargetModel;
  loading: boolean = true;
  async getCabinetTargetSingle(id: string) {
    this.loading = true
    this.data = await new CabinetTargetServices().getCabinetTargetSingle(id)
    this.loading = false
  }
  async updateCabinetTargetSingle(id: string) {
    this.loading = true
    this.data = await new CabinetTargetServices().updateCabinetTarget(id, this.data!)
    this.loading = false
  }
  async createCabinetTargetSingle() {
    this.loading = true
    this.data = await new CabinetTargetServices().createCabinetTarget(this.data!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteCabinetTargetSingle(id: string) {
    this.loading = true
    let response = await new CabinetTargetServices().deleteCabinetTargetSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveCabinetTargetListClass {
  data?: PaginateResponse<CabinetTargetModel>;
  loading: boolean = true;
  query:any = {}
  async getCabinetTargetList(query: any) {
    this.query = query
    this.loading = true
    this.data = await new CabinetTargetServices().getCabinetTargetList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  async refreshCabinetTargetList() {
    this.loading = true
    this.data = await new CabinetTargetServices().getCabinetTargetList(this.query)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteCabinetTargetFromList(id: string) {
    this.loading = true
    let tmp = await new CabinetTargetServices().deleteCabinetTargetSingle(id)
    this.refreshCabinetTargetList()
    // let index = this.data!.docs.findIndex(doc => doc.id === id)
    // this.data!.docs.splice(index, 1)
    this.loading = false
  }
}