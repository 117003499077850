import ADDRESS from "@/setting";
import axios from "axios"
import Model from "@/model/withdraw";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class WithdrawServices {
  toast = useToast();
  public getWithdrawList(query: any) {
    return new Promise<PaginateResponse<Model>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/withdraws/paginate`, query);
      console.log("paginate ni", { data: response.data })
      let docs: Array<Model> = response.data.docs.map((el: any) => new Model(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<Model>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getWithdrawSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/withdraw/get/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }

  public deleteWithdrawSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/withdraw/delete/${id}`);
      let doc: Model = new Model(response.data.doc)
      this.toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteWithdrawMemberSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/withdraw/delete-member/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }

  public sendToERP(id: string, Withdraw: Model) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/withdraw/sendToERP/${id}`, Withdraw.toJSON());
      let doc: Model = new Model(response.data.doc)
      this.toast.success("บันทึกข้อมูลแล้ว ", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public sendItemToERPK2(id: string, smisCode: string, sAMAccountName: string) {
    console.log("ส่งข้อมูลเข้าระบบ ERP")
    return new Promise<Model>(async (resolve, reject) => {
      let query = {
        id: id,
        smisCode: smisCode,
        sAMAccountName: sAMAccountName
      }
      let response = axios.post(`${ADDRESS}/interface/erp/push`, query);
      let response1 = axios.post(`${ADDRESS}/interface/k2/push`, query);
      this.toast.success("ส่งข้อมูลเข้าระบบ ERP", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
    })
  }

  public updateWithdraw(id: string, Withdraw: Model) {
   let  myWithdraw = Withdraw.toJSON()
   try {
    myWithdraw.amount = myWithdraw.items.map((item:any)=>item.amount).reduce((a:any,b:any)=>a+b,0)
   } catch (error) {
    myWithdraw.amount = 0
   }
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/withdraw/update/${id}`, myWithdraw);
      let doc: Model = new Model(response.data.doc)
      this.toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createWithdraw(budget_id: string, Withdraw: Model, user_id: string) {
    Withdraw.budget_id = budget_id
    Withdraw.user_id = user_id
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/withdraw/create/`, Withdraw);
      console.log("response.data", response.data)
      let doc: Model = new Model(response.data.doc)
      this.toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
