import UserModel from "../user";

export  default class AssignmentModel {
  id?: string;
  subject: string;
  status: string;
  priority: string;
  description: string;
  originator: string;
  targetDate: Date;
  resolution: string;
  user_id: string;
  project_id: string;
  assigns: Array<AssignmentMemberModel>
  attachments :Array<AssignmentAttachmentModel>
  replyAttachments :Array<AssignmentReplyAttachmentModel>
  createdAt: Date;
  modifiedAt: Date;
  createdIP: string;
  modifiedIP: string;

  constructor(jsonData: any) {
    let assigns:Array<AssignmentMemberModel> = []
    let attachments:Array<AssignmentAttachmentModel> = []
    let replyAttachments:Array<AssignmentReplyAttachmentModel> = []
    try {
      assigns = jsonData.assigns.map((m:any)=>new AssignmentMemberModel(m))
    } catch (error) {
      
    }
    try {
      attachments = jsonData.attachments.map((doc:any)=>new AssignmentAttachmentModel(doc))
    } catch (error) {
      console.log({error})
    }
    try {
      replyAttachments = jsonData.replyAttachments.map((doc:any)=>new AssignmentReplyAttachmentModel(doc))
    } catch (error) {
      console.log({error})
    }
    this.id = jsonData.id;
    this.subject = jsonData.subject ?? "";
    this.status = jsonData.status ?? "";
    this.priority = jsonData.priority ?? "";
    this.description = jsonData.description ?? "";
    this.originator = jsonData.originator ?? "";
    this.targetDate = jsonData.targetDate ?? new Date;
    this.resolution = jsonData.resolution ?? "";
    this.user_id = jsonData.user_id;
    this.project_id = jsonData.project_id;
    this.assigns = assigns;
    this.attachments = attachments;
    this.replyAttachments = replyAttachments;
    this.createdAt = jsonData.createdAt ?? new Date;
    this.modifiedAt = jsonData.modifiedAt;
    this.createdIP = jsonData.createdIP;
    this.modifiedIP = jsonData.modifiedIP;
  }
  toJSON(){
    let obj = Object.assign({},this)
    
    obj.assigns.forEach(o=>{
      try {
        console.log({o})
      } catch (error) {
        console.log(error)
      }
    })
    obj.attachments = obj.attachments.map( el=> {
      return new AssignmentAttachmentModel(el).toJSON()
    })
    obj.replyAttachments = obj.replyAttachments.map( el=> {
      return new AssignmentReplyAttachmentModel(el).toJSON()
    })
    return JSON.parse(JSON.stringify(obj))
  }
}
export class AssignmentMemberModel {
  id?: string;
  name?: string;
  user_id: string;
  // user:UserModel

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name ?? "";
    this.user_id = jsonData.user_id ?? "";
    // this.user = new UserModel(jsonData.user??{})
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return obj
  }
}
export class AssignmentAttachmentModel {
  id?: string;
  path?: string;
  size?: string;
  percent?: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.path = jsonData.path ?? "";
    this.size = jsonData.size ?? "";
    this.percent = jsonData.percent ?? "";
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return obj
  }
}
export class AssignmentReplyAttachmentModel {
  id?: string;
  path?: string;
  size?: string;
  percent?: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.path = jsonData.path ?? "";
    this.size = jsonData.size ?? "";
    this.percent = jsonData.percent ?? "";
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return obj
  }
}