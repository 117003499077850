
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modelValue: [String, Number],
    options: Array,
    label: String,
    request:Boolean,
    "onUpdate:modelValue": Function,
    disabled:Boolean,
  },
})
export default class Dropdown extends Vue {
  request!:boolean ;
  label!:string;
  modelValue!: string|number;
  val:string|number = ""
  options!: Array<{name:string,value:string|number|boolean}>;
  disabled!:boolean
  mounted(){
    this.val = this.modelValue
  }
  emitUp() {
    console.log("this.val",this.val,this.modelValue)
    this.$emit("update:modelValue", this.val);
    this.$emit("change", this.val);
  }
}
