import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import SystemModel, { SettingMemberModel } from "@/model/system";
import SystemServices from "@/service/system";
import { Service } from 'typedi';

@Service()
export class ActiveSystemSingleClass {
  data?: SystemModel;
  loading: boolean = true;
  async getSystemByName(name: string) {
    this.loading = true
    this.data = new SystemModel({})
    this.data!.member! = await new SystemServices().getSystemByName(name)
    this.loading = false
  }
  async getSystemSingle(id: string) {
    this.loading = true
    this.data = await new SystemServices().getSystemSingle(id)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteMember(i: number) {
    this.loading = true
    console.log(this.data!.member)
    let memberId = this.data!.member[i].id
    if (memberId) {
      await new SystemServices().deleteSystemMemberSingle(memberId!)
    }
    this.data?.member.splice(i, 1)
  }
  async updateSystemSingle() {
    this.loading = true
    try {
      this.data!.member = this.data!.member.map((item, i) => {
        item.order = i
        return item
      })
    } catch (error) {

    }
    this.data = await new SystemServices().updateSystem(this.data!.id!, this.data!)
    this.loading = false
  }
  async createSystemSingle() {
    this.loading = true
    this.data = await new SystemServices().createSystem(this.data!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteSystemSingle(id: string) {
    this.loading = true
    let response = await new SystemServices().deleteSystemSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveSystemListClass {
  data?: PaginateResponse<SystemModel>;
  data2?: Array<SettingMemberModel>;
  loading: boolean = true;
  query:any = {}
  async getSystemList(query: any) {
    this.loading = true
    this.data = await new SystemServices().getSystemList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  async refreshSystemList() {
    this.loading = true
    this.data = await new SystemServices().getSystemList(this.query)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteSystemFromList(id: string) {
    this.loading = true
    let tmp = await new SystemServices().deleteSystemSingle(id)
    this.refreshSystemList()
    this.loading = false
  }

}
@Service()
export class ActiveBudgetYearClass {
  data?: Array<SettingMemberModel>;
  loading: boolean = true;
  async getBudgetYearSetting() {
    this.loading = true
    this.data = await new SystemServices().getSystemByName("ปีงบประมาณ")
    this.loading = false
  }

}
@Service()
export class ActiveTypeClass {
  data?: Array<SettingMemberModel>;
  loading: boolean = true;
  async getTypeSetting(name: string) {
    this.loading = true
    this.data = await new SystemServices().getSystemByName(name)
    this.loading = false
  }

}
@Service()
export class ActiveBudgetSourceClass {
  data?: Array<SettingMemberModel>;
  loading: boolean = true;
  async getBudgetSourceSetting(name: string) {
    this.loading = true
    this.data = await new SystemServices().getSystemByName(name)
    this.loading = false
  }
}
@Service()
export class ActiveStrategicClass {
  data?: Array<SettingMemberModel>;
  loading: boolean = true;
  async getStrategicSetting(name: string) {
    this.loading = true
    this.data = await new SystemServices().getSystemByName(name)
    this.loading = false
  }

}
@Service()
export class ActiveBudgetSourceTypeClass {
  data?: Array<SettingMemberModel>;
  loading: boolean = true;
  async getBudgetSourceTypeSetting(name: string) {
    this.loading = true
    this.data = await new SystemServices().getSystemByName(name)
    this.loading = false
  }
}
@Service()
export class ActiveBudgetDegreeClass {
  data?: Array<SettingMemberModel>;
  loading: boolean = true;
  async getBudgetDegreeSetting(name: string) {
    this.loading = true
    this.data = await new SystemServices().getSystemByName(name)
    this.loading = false
  }
}
@Service()
export class ActiveBudgetTypeClass {
  //ประเภทงบ อุดหนุน ลงทุน
  data?: Array<SettingMemberModel>;
  loading: boolean = true;
  async getBudgetTypeSetting(name: string) {
    this.loading = true
    this.data = await new SystemServices().getSystemByName(name)
    this.loading = false
  }
}
