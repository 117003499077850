import ADDRESS from "@/setting";
import axios from "axios"
import IndicatorProfileModel from "@/model/indicator";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class IndiciatorServices {
  public getIndiciatorList(query: any) {
    return new Promise<PaginateResponse<IndicatorProfileModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/indicators/paginate`, query);
      console.log('res',response);
      
      let docs: Array<IndicatorProfileModel> = response.data.docs.map((el: any) => new IndicatorProfileModel(el))
      
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<IndicatorProfileModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public deleteIndicatorMetric(id:string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/indicator/delete-metric/${id}`);
      resolve("done")
    })
  }

  public deleteIndicatorResponsible(id:string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/indicator/delete-responsible/${id}`);
      resolve("done")
    })
  }

  public getIndiciatorSingle(id: string) {
    return new Promise<IndicatorProfileModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/indicator/get/${id}`);
      let doc: IndicatorProfileModel = new IndicatorProfileModel(response.data.doc)
      resolve(doc)
    })
  }

  public deleteIndiciatorSingle(id: string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/indicator/delete/${id}`);
      resolve("ok")
    })
  }

  public updateIndiciator(id: string, Indiciator: IndicatorProfileModel) {
    return new Promise<IndicatorProfileModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/indicator/update/${id}`, Indiciator.toJSON());
      console.log('response1',id)
      console.log('res',Indiciator);
      let doc: IndicatorProfileModel = new IndicatorProfileModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createIndiciator(Indiciator: IndicatorProfileModel) {
    return new Promise<IndicatorProfileModel>(async (resolve, reject) => {
      console.log('response1')
      let response = await axios.post(`${ADDRESS}/indicator/create/`, Indiciator);
      console.log('response2',response)
      let doc: IndicatorProfileModel = new IndicatorProfileModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
