import ADDRESS from "@/setting";
import axios from "axios"
import PositionModel from "@/model/position";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class PositionServices {
  public getPositionList(query: any) {
    return new Promise<PaginateResponse<PositionModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/positions/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<PositionModel> = response.data.docs.map((el: any) => new PositionModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<PositionModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getPositionSingle(id: string) {
    return new Promise<PositionModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/position/get/${id}`);
      let doc: PositionModel = new PositionModel(response.data.doc)
      resolve(doc)
    })
  }

  public updatePosition(id: string, Position: PositionModel) {
    return new Promise<PositionModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/position/update/${id}`, Position.toJSON());
      
      let doc: PositionModel = new PositionModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deletePosition(id: string) {
    return new Promise<PositionModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/position/delete/${id}`);
      
      let doc: PositionModel = new PositionModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createPosition(Position: PositionModel) {
    console.log(Position.toJSON)
    return new Promise<PositionModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/position/create/`, Position.toJSON());
      let doc: PositionModel = new PositionModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
