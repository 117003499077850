
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import TableHeader from "@/views/withdraw/components/TableHeader.vue"
import TableBody from "@/views/withdraw/components/TableBody.vue"
@Options({
  components: {
    TableHeader,
    TableBody
  },
})
export default class PlanTableComponent extends Vue {
  doc: ProjectModel = new ProjectModel({})
  showRows: string[] = ["budget", "withdraw", "diffMonth", "diffAccum"];
}
