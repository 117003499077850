import ADDRESS from "@/setting";
import axios from "axios"
import AdjustmentModel, { AdjustmentAttachmentModel, AdjustmentItemModel } from "@/model/adjustment";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";
import BudgetModel from "@/model/budget";
import ProjectModel from "@/model/project";


export default class AdjustmentServices {
  public getAdjustmentList(query: any) {
    return new Promise<PaginateResponse<AdjustmentModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/adjustments/paginate`, query);
      let docs: Array<AdjustmentModel> = response.data.docs.map((el: any) => new AdjustmentModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<AdjustmentModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }


  public deleteAdjustmentFromList(id: string) {
    return new Promise<AdjustmentModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/adjustment/delete/${id}`);
      let doc: AdjustmentModel = new AdjustmentModel(response.data.doc)
      resolve(doc)
    })
  }

  public approveAdjustmentFromList(id: string) {
    return new Promise<AdjustmentModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/adjustment/approve/`, { id });
      let doc: AdjustmentModel = new AdjustmentModel({})
      resolve(doc)
    })
  }
  public rejectAdjustmentFromList(id: string) {
    return new Promise<AdjustmentModel>(async (resolve, reject) => {
      let reason = prompt("กรุณาระบุเหตุผล", "-");
      let response = await axios.patch(`${ADDRESS}/adjustment/reject/`, { id, reason });
      let doc: AdjustmentModel = new AdjustmentModel({})
      resolve(doc)
    })
  }

  public createPeriodAdjustment(arr: Array<{ project_id: string }>) {
    return new Promise<AdjustmentModel>(async (resolve, reject) => {
      let suffix = prompt("กรุณาระบุชื่อในการทบทวนครั้งนี้", "-");
      let response = await axios.post(`${ADDRESS}/adjustment/create-many/`, { projects: arr.map(item => ({ ...item, suffix })) });
      let doc: AdjustmentModel = new AdjustmentModel({})
      console.log(doc)
      resolve(doc)
    })
  }

  public getAdjustmentSingle(id: string) {
    return new Promise<{ doc: AdjustmentModel, before: Array<BudgetModel>, after: Array<BudgetModel> }>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/adjustment/get/${id}`);
      let doc: AdjustmentModel = new AdjustmentModel(response.data.doc)
      let before: Array<BudgetModel> = response.data.before.map((item: any) => {
        console.log({ item })
        let newitem = new BudgetModel(item)
        console.log({ newitem })
        return newitem
      })
      let after: Array<BudgetModel> = response.data.after.map((item: any) => {
        console.log({ item })
        let newitem = new BudgetModel(item)
        console.log({ newitem })
        return newitem
      })
      resolve({ doc, before, after })
    })
  }

  public updateAdjustment(id: string, Adjustment: AdjustmentModel) {
    // console.log('id', id == Adjustment.id);
    // console.log('update', Adjustment.attachments);
    console.log('json2', Adjustment.toJSON());

    return new Promise<AdjustmentModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/adjustment/update/${Adjustment.id}`, Adjustment.toJSON());
      // console.log('Adjustment', Adjustment.toJSON());
      console.log('res', response);
      let doc: AdjustmentModel = new AdjustmentModel(response.data.doc)
      // console.log('doc', doc);
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    }) 
  }
  public requestAdjustmentApprove(id: string) {
    return new Promise<AdjustmentModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/adjustment/update-draft`, {id:id});
      // console.log('Adjustment', Adjustment.toJSON());
      console.log('resAdjustment', response);
      let doc: AdjustmentModel = new AdjustmentModel(response.data.doc)
      // console.log('doc', doc);
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    }) 
  }

  public createAdjustment(Adjustment: AdjustmentModel) {
    return new Promise<AdjustmentModel>(async (resolve, reject) => {
      console.log("Adjustment", Adjustment)
      console.log('json',Adjustment.toJSON());
      let response = await axios.post(`${ADDRESS}/adjustment/create/`, Adjustment.toJSON());
      console.log('res',response);
      
      let doc: AdjustmentModel = new AdjustmentModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteAttachmentSingle(id: string) {
    return new Promise<AdjustmentAttachmentModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/adjustment/delete-attachment/${id}`);
      let doc: AdjustmentAttachmentModel = new AdjustmentAttachmentModel(response.data.doc)
      console.log('id', id);
      console.log('res', response);
      console.log('doc', doc);
      const toast = useToast();
      toast.success("ลบไฟล์แนบแล้ว", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteAdjustmentItemsSingle(id: string) {
    return new Promise<AdjustmentItemModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/adjustment/delete-item/${id}`);
      let doc: AdjustmentItemModel = new AdjustmentItemModel(response.data.doc)
      const toast = useToast();
      toast.success("ลบไฟล์แนบแล้ว", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteAdjustmentBudgetSingle(id: string) {
    return new Promise<AdjustmentItemModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/adjustment/delete-budget/${id}`);
      let doc: AdjustmentItemModel = new AdjustmentItemModel(response.data.doc)
      const toast = useToast();
      toast.success("ลบไฟล์แนบแล้ว", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public getAdjustmentHistory(id: string) {
      return new Promise<{ doc: any, before: ProjectModel, after: ProjectModel }>(async (resolve, reject) => {
        let response = await axios.get(`${ADDRESS}/adjustment/get-history/${id}`);
        let doc: string = response.data.name
        let before: ProjectModel = response.data.from
        let after: ProjectModel= response.data.to
        let data = {doc,before,after}
        resolve(data)
      });
     
  }
  public getAdjustPending(id:string) {
    return new Promise<any>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/adjustment/notification/${id}` );
      console.log('ad',response);    
      resolve(response)
    })
  }



}
