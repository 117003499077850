export  default class PetitionModel {
  id?: string;
  project_id: string;
  quarter: number;
  departmentCode: string;
  workCode:string;
  accountCode:string;
  accountName:string
  planName:string;
  product:string
  activityNumber:Number;
  activityName:string;
  budgetCode:string;
  number:number;
  productCode:string;
  activityMainCode:string;
  accountSubCode:string;
  items: Array<PetitionItemModel>;
  notes:string;
  fromRevision:number;
  toRevision:number;
  // petitions:Array<String>
  // plans:Array<String>
  //results: Array<ResultItemModel>;


  constructor(jsonData: any) {
   
    this.id = jsonData.id;
    this.project_id = jsonData.project_id ?? "";
    this.quarter = jsonData.quarter?? 0 ;
    this.departmentCode = jsonData.departmentCode?? "";
    this.workCode = jsonData.workCode?? "";
    this.accountCode = jsonData.accountCode?? "";
    this.accountName = jsonData.accountName?? "";
    this.planName = jsonData.planName?? "";
    this.number = jsonData.number;
    this.product = jsonData.product?? ""
    this.activityNumber =  jsonData.activityNumber?? 0;
    this.activityName =  jsonData.activityName?? "";
    this.budgetCode =  jsonData.budgetCode?? "";
    this.productCode =  jsonData.productCode?? "";
    this.activityMainCode =  jsonData.activityMainCode?? "";
    this.accountSubCode =  jsonData.accountSubCode?? "";
    this.items = jsonData.items ??[].map((item:any) => new PetitionItemModel(item)) 
    this.notes = jsonData.notes ?? ""
    this.fromRevision = jsonData.fromRevision?? 0 ;
    this.toRevision = jsonData.toRevision?? 0 ;

    // this.petitions = jsonData.petitions ?? []
    // this.plans = jsonData.plans ?? []
    //this.results = jsonData.results ??[].map((item:any) => new ResultItemModel(item)) 
  }
  toJSON(){
    let obj = Object.assign({},this)
    try {
      obj.items = obj.items.map((el: any) => {
        return new PetitionItemModel(el).toJSON()
      })
    } catch (error) {

    }
  //   try {
  //     obj.results = obj.results.map((el: any) => {
  //       return new ResultItemModel(el).toJSON()
  //     })
  //   } catch (error) {

  //   }    
    return JSON.parse(JSON.stringify(obj))
  }
}
// export class ResultItemModel {
//   id?: string;
//   name: string;
//   quarter1:number
//   quarter2:number
//   quarter3:number
//   quarter4:number
//   constructor(jsonData: any) {
//     this.id = jsonData.id
//     this.name = jsonData.name
//     this.quarter1 = jsonData.quarter1
//     this.quarter2 = jsonData.quarter2
//     this.quarter3 = jsonData.quarter3
//     this.quarter4 = jsonData.quarter4
//   }
//   toJSON() {
//     let obj: any = Object.assign({}, this)
//     return JSON.parse(JSON.stringify(obj))
//   }
// }

export class PetitionItemModel {
  id?: string;
  month: number
  amount: number
  constructor(jsonData: any) {
    this.id = jsonData.id
    this.month = jsonData.month ?? 12;
    this.amount = jsonData.amount ?? 0;
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return JSON.parse(JSON.stringify(obj))
  }
}