
import { Options, Vue } from "vue-class-component";
import {
  IndicatorMetricModel,
  IndicatorResponsiblePersonModel,
} from "@/model/indicator";
import { ActiveIndiciatorSingleClass } from "@/data/indicator";
import Container from "typedi";
import { DocumentDocx } from "@/document/docx/B";
import { ReportB04Class } from "@/data/report";
import LoadingWidget from "@/components/LoadingWidget.vue";
import { DocumentExcel } from "@/document/xlsx";
import DocumentA from "@/document/pdf";

@Options({
  components: {},
})
export default class ReportB01View extends Vue {
  dataSingle: ReportB04Class = Container.get(ReportB04Class);
  exportExcel(){

    let docExcel = new DocumentExcel();
    docExcel.createB04(this.dataSingle.data!, 2565);
  }
  mounted() {
    this.fetchData();
  }
  async fetchData() {
    await this.dataSingle.getReportB04List({ budgetYear: 2565 });
  }
  async generatePDF() {
    console.log("generatePDF");
    const documentA = new DocumentA();
    console.log(this.dataSingle.data)
    documentA.createB04(this.dataSingle.data!, 2565);
    documentA.save(`reportB04.pdf`);
  }
}
