
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import ProjectModel from "@/model/project";
import PlanTable from "@/views/withdraw/components/PlanTable.vue";
import ModalWithdraw from "@/views/withdraw/components/ModalWithdraw.vue";
import { ActiveProjectSingleClass } from "@/data/project";
import Container from "typedi";
import TableTool from "../../components/TableTool.vue";
import { ActiveBudgetSingleClass } from "@/data/budget";
import {
  ActiveWithdrawListClass,
  ActiveWithdrawSingleClass,
} from "@/data/withdraw";
import WithdrawModel, { WithdrawItemModel } from "@/model/withdraw";
import BudgetServices from "@/service/budget";

import { confirmAction } from "@/decorator";
import { useStore } from "vuex";
import { getStructTreeRoot } from "pdfkit";
@Options({
  components: {
    PlanTable,
    ModalWithdraw,
  },
})
export default class BudgetPlanView extends Vue {
  store = useStore();
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  withdraw: ActiveWithdrawSingleClass = Container.get(
    ActiveWithdrawSingleClass
  );
  withdrawList: ActiveWithdrawListClass = Container.get(
    ActiveWithdrawListClass
  );
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);
  isSent: string = "";

  get withdraws() {
    let results = [];
    try {
      for (const budget of this.dataSingle.data!.budgets) {
        results.push(...budget.withdraws);
        for (const child1 of budget!.children!) {
          results.push(...child1.withdraws);
          for (const child2 of child1!.children!) {
            results.push(...child2.withdraws);
          }
        }
      }
      try {
        if (this.isSent === "yes")
          results = results.filter((item) => item.isSent);
        else if (this.isSent === "no")
          results = results.filter((item) => !item.isSent);
        else results = results;
      } catch (error) {}
      return results;
    } catch (error) {
      return [];
    }
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteWithdraw(withdraw: WithdrawModel) {
    this.withdraw.data = withdraw;
    await this.withdraw.deleteWithdrawSingle();
    await this.project.getProjectSingle(this.$route.params.id as string);
    this.initData();
  }
  openWithdrawModal() {
    let btn = this.$refs.modalWithdrawItemMoneyBtn as HTMLElement;
    btn.click();
  }
  async editWithdraw(withdraw: WithdrawModel) {
    let budgetData = await new BudgetServices().getBudgetSingle(
      withdraw.budget_id!
    );
    console.log("OK", withdraw.budget_id)
    console.log("???", budgetData)
    this.activeBudget.data = budgetData;
    this.activeBudget.withdraws = this.withdrawList.data?.docs.filter(el=>el.budget_id===this.activeBudget.data?.id)
    this.withdraw.data = withdraw
    this.openWithdrawModal();
  }
  mounted() {
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new ProjectModel({});
    }
  }
  async initData() {
    this.dataSingle.data = undefined;
    await this.dataSingle.getProjectSingle(this.$route.params.id as string);
    this.getWithdrawList();
  }
  async getWithdrawList() {
    let query: any = {
      search: {
        budget: {
          project: {
            id: this.$route.params.id as string,
          },
        },
      },
      // sort: { isSent: "desc" ,createdAtFormat: "desc"},
      sort: [{ isSent: "desc" }, { createdAt: "desc" }],
      // {
      //   // createdAt:"desc",

      //   // smisCode: "desc",
      // },
    };
    if (this.isSent != "" && this.isSent == "yes") query.search.isSent = true;
    if (this.isSent != "" && this.isSent == "no") query.search.isSent = false;
    await this.withdrawList.getWithdrawList(query);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createProjectSingle();
    } else {
      this.dataSingle.updateProjectSingle();
    }
    this.initData();
  }
  @confirmAction("การกระทำนี้จะส่งรายการที่คุณเลือกคุณแน่ใจหรือไม่?")
  async sentToEFormERP(withdraw: WithdrawModel) {
    this.withdraw.data = withdraw;
    this.withdraw.data!.isSent! = true;
    await this.withdraw.sendToERP();
    await this.withdraw.sendItemToERPK2(
      this.withdraw.data!.id as string,
      this.withdraw.data!.smisCode as string,
      this.store.state.sAMAccountName ?? "sAMAccountName error"
    );
    this.initData();

    // alert("ดำเนินการส่งเรียบร้อยแล้ว")
  }
  get totalBudget() {
    try {
      // let map = this.dataSingle.data?.budgets.map(budget=> budget)
      //let map = this.dataSingle.data?.budgets.map(budget=> budget.items.map(item=>item.amount).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b,0)
      let map = this.dataSingle.data?.budgets
        .map((budget) =>
          budget.items.map((item) => item.amount).reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0);
      return map;
    } catch (error) {
      return 0;
    }
  }
 get getUser(){
 let username =  this.store.state.username
  return username
  }
get getAdmin(){
 let admin =  this.store.state.roles.includes("ผู้ดูแลระบบ")
  return admin
  }
}
