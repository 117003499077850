
import { Options, Vue } from "vue-class-component";
import StrategicModel, {StrategicItemModel, StrategicItemIndicatorModel,MissionModel,ObjectiveModel} from "@/model/strategic";
import ADDRESS from "@/setting";
import IndicatorProfileModel from "@/model/indicator";
import {ActiveStrategicSingleClass,} from "@/data/strategic";
import { ActiveBudgetYearClass,ActiveStrategicClass } from "@/data/system";
import { ActiveIndiciatorListClass } from "@/data/indicator";
import StrategicServices from "@/service/strategic"
import Container from "typedi";
import { Watch } from "vue-property-decorator";
import { confirmAction } from "@/decorator";

@Options({
  components: {},
})
export default class StrategicFormView extends Vue {
  MissionModel = MissionModel
  ObjectiveModel = ObjectiveModel
  StrategicItemModel = StrategicItemModel
  StrategicItemIndicatorModel = StrategicItemIndicatorModel
  dataSingle: ActiveStrategicSingleClass = Container.get(ActiveStrategicSingleClass);
  yearSetting:ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  strategicSetting:ActiveStrategicClass = Container.get(ActiveStrategicClass);
  dataList: ActiveIndiciatorListClass = Container.get(ActiveIndiciatorListClass);
  docs: Array<IndicatorProfileModel> = [];
  ADDRESS = ADDRESS;


  get missions() {
    try {
      return this.dataSingle.data!.missions;
    } catch (error) {
      return [];
    }
  }
  get objectives() {
    try {
      return this.dataSingle.data!.objectives;
    } catch (error) {
      return [];
    }
  }
  addLevel1(){
    // this.dataSingle.data!.items.push(new StrategicItemModel({parent_id:""}))
    this.dataSingle.createStrategicItemSingle(this.dataSingle.data!.id!, new StrategicItemModel({parent_id:""}))
  }
  addLevelChild(parent_id:string){
    this.dataSingle.createStrategicItemSingle(this.dataSingle.data!.id!, new StrategicItemModel({parent_id:parent_id}))
  }
  addIndicatorToLevel(strategic_item_id:string){
    console.log('id',strategic_item_id);
    
    this.dataSingle.createStrategicItemIndicatorSingle(this.dataSingle.data!.id!, new StrategicItemIndicatorModel({strategicItem_id:strategic_item_id}))
  }
  updateItem(strategicItem:StrategicItemModel){
    this.dataSingle.updateStrategicItemSingle(strategicItem)
  }
  updateItemIndicator(id:string,strategicItemIndicator:StrategicItemIndicatorModel){
    this.dataSingle.updateStrategicItemIndicatorSingle(id,strategicItemIndicator)
  }

  mounted() {
    if (this.$route.params.id !== undefined) this.initData();
    else { 
      this.dataSingle.data = new StrategicModel({})
    }
    this.getData();
  }
  get strategic() {
    let arr : Array<Object> = []
    this.strategicSetting.data?.forEach((el)=>{
      arr.push({
        name:el.name,
        value:el.name
      })
    })
    return arr
  }
  get budgetYear() {
    let arr : Array<Object> = []
    this.yearSetting.data?.forEach((el)=>{
      arr.push({
        name:el.name,
        value:parseInt(el.text1)
      })
    })
    return arr
  }
  get indicators() {
    let arr : Array<Object> = []
    this.docs.forEach((el) => { 
      arr.push({
        value:el.id,
        name:el.code + " - " +el.name,
      })
    });
    console.log('arr',arr);
    
    return arr
  }
  async getData() {
    await this.strategicSetting.getStrategicSetting("ระดับผังยุทธศาสตร์");
    this.yearSetting.getBudgetYearSetting();
    let query = {
      search: {},
      sort: {}
    }
    console.log('query',query)
    await this.dataList.getIndiciatorList({});
    this.docs = this.dataList.data!.docs;
  }
  // @Watch('this.docs')
  async initData() {
    await this.dataSingle.getStrategicSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createStrategicSingle();
    } else {
      this.dataSingle.updateStrategicSingle(this.$route.params.id as string);
    }
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteMission(id: string) {
    await this.dataSingle.deleteStrategicMissionSigle(id);
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteObjective(id: string) {
    await this.dataSingle.deleteStrategicObjectiveSigle(id);
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteStrategicItem(id: string) {
    await this.dataSingle.deleteStrategicItemSigle(id);
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteStrategicItemIndicator(id: string) {
    await this.dataSingle.deleteStrategicItemIndicatorSigle(id);
  }
}
