
import { Options, Vue } from "vue-class-component";
import ProjectModel, { ProjectItemModel } from "@/model/project";
import {
  ActiveProjectListClass,
  ActiveProjectSingleClass,
} from "@/data/project";
import Container from "typedi";
import { ActiveSectorListClass } from "@/data/sector";
import { ActiveBudgetYearClass } from "@/data/system";
import { ActiveWithdrawListClass } from "@/data/withdraw";
import TextField from "../../components/TextField.vue";

@Options({
  components: {},
})
export default class BudgetFormView extends Vue {
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  dataList: ActiveWithdrawListClass = Container.get(ActiveWithdrawListClass);
  budgetSource = "";
  sector_id = "";
  budgetYear = new Date().getFullYear() + 543;
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  projects: ActiveProjectListClass = Container.get(ActiveProjectListClass);
  name = "";
  mounted() {
    this.fetchData();
    this.yearSetting.getBudgetYearSetting();
    this.sectorList.getSectorList({ search: { category: "ฝ่าย/สำนัก" } });
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new ProjectModel({});
    }
  }
  async fetchData() {
    console.log("fetching");
    let query: any = {
      search: {},
      sort: {
        createdAt: "desc",
      },
    };
    let sectorQuery;
    if (this.sector_id !== "") {
      sectorQuery = {
        sector_id: this.sector_id,
      };
    }
    let nameQuery: any;
    if (this.name != "") {
      nameQuery = {
        OR: [
          { code: { contains: this.name } },
          { name: { contains: this.name } },
        ],
      };
    }
    query.search = {
      budget: {
        project: {
          budgetYear: this.budgetYear,
          ...sectorQuery,
          ...nameQuery,
        },
      },
    };
    await this.dataList.getWithdrawList(query);
  }
  async initData() {
    this.dataSingle.data = undefined;
    await this.dataSingle.getProjectSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createProjectSingle();
    } else {
      this.dataSingle.updateProjectSingle();
    }
  }
}
