
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import { ActiveProjectSingleClass } from "@/data/project";
import Container from "typedi";

@Options({
  components: {},
})
export default class PlanTableComponent extends Vue {
  doc: ProjectModel = new ProjectModel({})
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  mounted(){
    if(this.$route.params.id !== undefined) this.dataSingle.getProjectSingle(this.$route.params.id as string);
  }
}
