
import { Options, Vue } from "vue-class-component";

import sparkline from "@fnando/sparkline";
//@ts-ignore
import Chart, { Chart as chartjs, TooltipItem } from "chart.js/auto";
// import { Chart } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ADDRESS from "@/setting";
import Container from "typedi";
import { ActiveSectorListClass } from "@/data/sector";
import { ActiveBudgetSourceClass, ActiveBudgetYearClass } from "@/data/system";
import {
  ActiveProjectListClass,
  ActiveProjectSingleClass,
} from "@/data/project";
import { ActiveUserSingleClass } from "@/data/user";
import { ActiveCabinetTargetListClass } from "@/data/cabinetTarget";
import axios from "axios";
import { off } from "process";

@Options({
  components: {},
})
export default class Dashboard extends Vue {
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  budgetSourceList: ActiveBudgetSourceClass = Container.get(
    ActiveBudgetSourceClass
  );
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  dataList: ActiveProjectListClass = Container.get(ActiveProjectListClass);
  userSingle: ActiveUserSingleClass = Container.get(ActiveUserSingleClass);
  cabinetTarget: ActiveCabinetTargetListClass = Container.get(
    ActiveCabinetTargetListClass
  );
  
  test: string = "";
  budgetType = "งบลงทุน";
  progress = 0;
  docs: Array<any> = [];
  limit: number = 15;
  show: number = 20;
  page: number = 1;
  pages: number = 0;
  total: number = 0;
  sector_id = "";
  search = "";
  budgetYear = new Date().getFullYear() + 543;
  budgetSource = "";
  filter1: number = this.getBudgetYear(new Date());
  filter2: any = {};
  filter3: any = {};
  types: Array<any> = [];
  totalBudgetMonths: Array<number> = [];
  totalERPMonths: Array<number> = [];
  activeCharts: Array<any> = [];

  actual: Array<number> = [0, 0, 0, 0];
  plan: Array<number> = [0, 0, 0, 0];
  cabinet: Array<number> = [0, 0, 0, 0];

  getBudgetYear(date: Date): number {
    const currentMonth = date.getMonth();
    let fiscalYear = date.getFullYear();
    if (currentMonth > 9) fiscalYear++;
    return fiscalYear + 543;
  }

  clearSearch() {
    this.filter1 = 0;
    this.filter2 = "";
    this.fetchData();
  }

  async fetchData() {
    let query: any = {
      search: {
        step:4
      },
      limit: this.show,
      page: this.page,
      sort: {
        createdAt: "desc",
      },
    };
    query.search.budgetYear = this.budgetYear;
    if (this.sector_id != "") query.search.sector_id = this.sector_id;
    if (this.budgetSource != "") query.search.budgetSource = this.budgetSource;
    if (this.search != "") {
      query.search = {
        ...query.search,
        OR: [
          { name: { contains: this.search } },
          { code: { contains: this.search } },
        ],
      };
    }
    await this.dataList.getProjectFollowList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
  initAllChart() {
    let docs = this.docs ?? [];
    this.initChartBar(
      "chart-summary",
      this.totalBudgetMonths,
      this.totalERPMonths
    );
    docs.forEach((doc, i) => {
      this.initChartBar("chart-bar-" + i, doc.months, doc.erpsMonths);
      this.initChartPie(
        "chart-pie-" + i,
        (doc.totalBudgetAmount ?? 0) - (doc.totalWithdrawAmount ?? 0),
        doc.totalWithdrawAmount ?? 0
      );
    });
  }
  async initAll() {
    for (const chart of this.activeCharts) {
      console.log("chart", chart);
      await chart.destroy();
    }
    await this.fetchData();
    await this.cabinetTarget.getCabinetTargetList({
      search: {
        budgetYear: this.getBudgetYear(new Date()),
      },
    });
    this.initAllChart();
  }
  mounted() {
    // Chart.register(ChartDataLabels);
    this.yearSetting.getBudgetYearSetting();
    this.budgetSourceList.getBudgetSourceSetting("แหล่งงบประมาณในคำขอ");
    this.sectorList.getSectorList({ search: { category: "ฝ่าย/สำนัก" } });
    this.initAll();
  }
  initChartSummary(name: string, budget: Array<number>, actual: Array<number>) {
    var element = document.getElementById(name) as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((el) =>
          this.resolveMonth(el)
        ),
        datasets: [
          {
            label: "งบประมาณ",
            data: budget,
            backgroundColor: ["rgba(54, 162, 235, 0.7)"],
            borderColor: ["rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
          {
            label: "เบิกจ่ายจริง",
            data: actual,
            backgroundColor: ["rgba(75, 192, 192, 0.7)"],
            borderColor: ["rgba(75, 192, 192, 1)"],
            borderWidth: 1,
          },
          {
            label: "ส่วนต่าง (งบประมาณ-เบิกจ่าย)",
            type: "line",
            data: (actual ?? []).map((ac, i) => Math.abs(ac - budget[i])),
            backgroundColor: ["rgba(192, 75, 75, 0.7)"],
            borderColor: ["rgba(192, 75, 75, 1)"],
            borderWidth: 1,
          },
          //
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    this.activeCharts.push(myChart);
  }
  async initChartBar(
    name: string,
    budget: Array<number>,
    actual: Array<number>
  ) {
    var element = document.getElementById(name) as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    let query: any = {
      search: {},
    };
    if (this.sector_id != "") query.search.sector_id = this.sector_id;
    let response = await axios.post(
      `${ADDRESS}/dashboard/${this.filter1}/${this.budgetType}`,
      query
    );
    let data = response.data;
    console.log({ data });
    let totalActual = data.actual.reduce((a: number, b: number) => a + b, 0);
    let totalPlan = data.plan.reduce((a: number, b: number) => a + b, 0);
    // this.actual = data.actual.map((item:number,i:number)=>(item/totalActual)*100)
    // this.plan = data.plan.map((item:number,i:number)=>(item/totalPlan)*100)
    this.actual = [
      (data.actual[0] / totalPlan) * 100,
      ((data.actual[0] + data.actual[1]) / totalPlan) * 100,
      ((data.actual[0] + data.actual[1] + data.actual[2]) / totalPlan) * 100,
      ((data.actual[0] + data.actual[1] + data.actual[2] + data.actual[3]) /
        totalPlan) *
        100,
    ];
    this.plan = [
      (data.plan[0] / totalPlan) * 100,
      ((data.plan[0] + data.plan[1]) / totalPlan) * 100,
      ((data.plan[0] + data.plan[1] + data.plan[2]) / totalPlan) * 100,
      ((data.plan[0] + data.plan[1] + data.plan[2] + data.plan[3]) /
        totalPlan) *
        100,
    ];
    this.cabinet = data.cabinet;
    const myChart = new Chart(ctx, {
      type: "bar",
      plugins: [ChartDataLabels],
      data: {
        labels: ["ไตรมาส 1", "ไตรมาส 2", "ไตรมาส 3", "ไตรมาส 4"],
        datasets: [
          {
            label: "แผน",
            data: this.plan,
            backgroundColor: ["rgba(54, 162, 235, 0.7)"],
            borderColor: ["rgba(54, 162, 235, 1)"],
            borderWidth: 1,
          },
          {
            label: "ผล",
            data: this.actual,
            backgroundColor: ["rgba(75, 192, 192, 0.7)"],
            borderColor: ["rgba(75, 192, 192, 1)"],
            borderWidth: 1,
          },
          {
            label: "มติ ครม.",
            data: this.cabinet,
            backgroundColor: ["rgba(192, 75, 75, 0.7)"],
            borderColor: ["rgba(192, 75, 75, 1)"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: { mirror: true },
          },
        },
        layout: {
          padding: {
            top: 30,
          },
        },
        plugins: {
          legend: {
            position: "bottom",
          },
          datalabels: {
            formatter: (value: any, context: any) => {
              return value.formatFull() + " %";
            },
            offset: 5,
            align: "end",
            clamp: true,
            anchor: "end",
            color: "#333333",
            // color: "#36A2EB",
          },
          tooltip: {
            callbacks: {
              label: (TooltipItem: any) => {
                // console.log({TooltipItem})
                return ((TooltipItem.raw as number) ?? 0).formatFull() + " %";
                // return TooltipItem.formattedValue + " %"
              },
              footer: (tooltipItems: any) => {
                let sumPlan = 0;
                let sumActual = 0;
                let sumCabinet = 0;

                tooltipItems.forEach(function (tooltipItem: any) {
                  for (var q = tooltipItem.dataIndex; q >= 0; q--) {
                    sumPlan += data.plan[q];
                    sumActual += data.actual[q];
                    sumCabinet += data.cabinet[q];
                  }
                });

                if (tooltipItems[0].dataset.label === "แผน") {
                  return "ยอดเงิน: " + sumPlan.formatFull() + " บาท";
                } else if (tooltipItems[0].dataset.label === "ผล") {
                  return "ยอดเงิน: " + sumActual.formatFull() + " บาท";
                } else if (tooltipItems[0].dataset.label === "มติ ครม.") {
                  return ""; //"ยอดเงิน: " + sumCabinet.formatFull() + " บาท";
                }
                return "";
              },
            },
          },
        },
      },
    });
    this.activeCharts.push(myChart);
  }
  initChartPie(name: string, budget: number, actual: number) {
    var element = document.getElementById(name) as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["เบิกจ่ายแล้ว", "คงเหลือ"],
        datasets: [
          {
            label: "ข้อมูลทั้งโครงการ",
            data: [actual, budget],
            backgroundColor: ["rgba(75, 192, 192, 1)", "rgb(54, 162, 235)"],
            // hoverOffset: 4
          },
        ],
      },
    });
    this.activeCharts.push(myChart);
  }

  resolveMonth(n: number) {
    let year = 2565;
    switch (n) {
      case 0:
        return "ตุลาคม " + year;
        break;
      case 1:
        return "พฤศจิกายน " + year;
        break;
      case 2:
        return "ธันวาคม " + year;
        break;
      case 3:
        return "มกราคม " + (year + 1);
        break;
      case 4:
        return "กุมภาพันธ์ " + (year + 1);
        break;
      case 5:
        return "มีนาคม " + (year + 1);
        break;
      case 6:
        return "เมษายน " + (year + 1);
        break;
      case 7:
        return "พฤษภาคม " + (year + 1);
        break;
      case 8:
        return "มิถุนายน " + (year + 1);
        break;
      case 9:
        return "กรกฏาคม " + (year + 1);
        break;
      case 10:
        return "สิงหาคม " + (year + 1);
        break;
      case 11:
        return "กันยายน " + (year + 1);
        break;

      default:
        return n;
        break;
    }
  }
}
