
  import { Options, Vue } from "vue-class-component";
  import Container from "typedi";
  import { ActiveStrategicBudgetYearClass } from "@/data/strategic";
  import { ActiveProjectSingleClass } from "@/data/project";
import TextField from "../../../components/TextField.vue";
import { Watch } from "vue-property-decorator";
import UserModel from "@/model/user";
  @Options({
    props:{
      modelValue: Array,
      label: String,
      disabled:Boolean
    }
  })
  export default class PlanTableComponent extends Vue {
    strategicBudgetYear: ActiveStrategicBudgetYearClass = Container.get(
      ActiveStrategicBudgetYearClass
    );
    project: ActiveProjectSingleClass = Container.get(
      ActiveProjectSingleClass
    );
    label!:string
    modelValue!:Array<string>
    val:Array<any> = [{}]
    disabled!:boolean
  updateFromAutoPerson(user:UserModel,item:any){
    console.log("user", user)
    item.user_id = user.id!
    item.fullName = user.fullName
    item.position = user.position
    item.sector1 = user.sector1?.name
    item.sector2 = user.sector2?.name
    item.telephone = user.telephone
    item.email = user.email
    item.signature = user.signature;
    this.emitUp();
  }
    pushItem(){
      if((typeof this.val === 'string')) this.val = [{}]
      else if (!Array.isArray(this.val)) this.val = []
      this.val.push({})
      this.emitUp()
    }
    deleteItem(i:number){
      let c = confirm("ยืนยันลบรายการหรือไม่?")
      if(c){
        this.val.splice(i,1)
        this.emitUp()
      }
    }
    @Watch("modelValue")
    updateVal(){
      this.val = this.modelValue??[]
    }
    mounted(){
      this.val = this.modelValue??[]
      this.initData()
    }
    initData(){
      this.strategicBudgetYear.getStrategicFromBudgetYear(this.project.data!.budgetYear)
    }
    emitUp(){
      this.$emit("update:modelValue", this.val);
    }
  }
  