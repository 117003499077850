import ADDRESS from "@/setting";
import axios from "axios"
import PetitionModel from "@/model/petition";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class PetitionServices {
  public getPetitionList(query: any) {
    return new Promise<PaginateResponse<PetitionModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/petitions/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<PetitionModel> = response.data.docs.map((el: any) => new PetitionModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<PetitionModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getPetitionSingle(id: string) {
    return new Promise<PetitionModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/petition/get/${id}`);
      let doc: PetitionModel = new PetitionModel(response.data.doc)
      resolve(doc)
    })
  }
  public getPetitionPrevious(project_id: string, quarter:number) {
    return new Promise<PetitionModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/petition/get-previous/${project_id}/${quarter}`);
      let doc: PetitionModel = new PetitionModel(response.data.doc)
      resolve(doc)
    })
  }

  public updatePetition(id: string, Petition: PetitionModel) {
    return new Promise<PetitionModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/petition/update/${id}`, Petition.toJSON());
      console.log(response,"AAAA")
      let doc: PetitionModel = new PetitionModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createPetition(Petition: PetitionModel) {
    console.log(Petition.toJSON)
    return new Promise<PetitionModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/petition/create/`, Petition.toJSON());
      let doc: PetitionModel = new PetitionModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
