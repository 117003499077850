import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row mb-2" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 0,
  class: "form-label"
}
const _hoisted_4 = {
  class: "position-relative w-100",
  style: {"display":"inline"}
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "list-group position-absolute px-0 start-0",
  style: {"min-width":"20rem","z-index":"500"}
}
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.label!=undefined)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          class: "form-control",
          list: "datalistOptions",
          placeholder: "พิมพ์เพื่อค้นหา...",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputData) = $event)),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fetchData && _ctx.fetchData(...args))),
          onFocusin: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focus= true)),
          disabled: _ctx.disabled
        }, null, 40, _hoisted_5), [
          [_vModelText, _ctx.inputData]
        ]),
        (_ctx.dataList.data!=undefined&&_ctx.focus)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataList.data!.docs, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "list-group-item hoverable mx-0 cursor-pointer",
                  onClick: ($event: any) => (_ctx.emitUp(item))
                }, [
                  _createElementVNode("span", null, _toDisplayString(item.fullName), 1)
                ], 8, _hoisted_7))
              }), 256))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}