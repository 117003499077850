export default class PublicrelationModel {
  id?: string;
  subject: string;
  type: string;
  description: string;
  attachments: Array<PublicRelationAttachmentModel>;
  images: Array<PublicRelationAttachmentModel>;
  createdAt : Date;


  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.subject = jsonData.subject;
    this.type = jsonData.type;
    this.description = jsonData.description;
    // this.images = jsonData.images
    // this.attachments = jsonData.attachments
    this.images = jsonData.images ?? [].map((item:any)=>new PublicRelationAttachmentModel(item));
    this.attachments = jsonData.attachments?? [].map((item:any)=>new PublicRelationAttachmentModel(item));
    this.createdAt = new Date(jsonData.createdAt)
  }
  get createdAtFormat() {
    return this.createdAt.toThaiLong()
  }

  toJSON() {
    let obj = Object.assign({}, this)
    
    try {
      obj.attachments = obj.attachments.map(o => new PublicRelationAttachmentModel(o).toJSON())
    } catch (error) {
      
    }
    try {
      obj.images = obj.images.map(o => new PublicRelationAttachmentModel(o).toJSON())
    } catch (error) {
      
    }
    return JSON.parse(JSON.stringify(obj))
  }
}
export class PublicRelationAttachmentModel {
  id?: string;
  path: string;
  size: number;
  constructor(jsonData: any) {
    this.id = jsonData.id
    this.path = jsonData.path ?? ""
    this.size = jsonData.size ?? 0
    
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return JSON.parse(JSON.stringify(obj))
  }
}