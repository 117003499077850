
import { Options, Vue } from "vue-class-component";
import {ActiveStrategicSingleClass,} from "@/data/strategic";
import { ActiveIndiciatorListClass } from "@/data/indicator";
import IndicatorProfileModel from "@/model/indicator";
import Container from "typedi";

@Options({
  components: {},
})
export default class ReportB02View extends Vue {
  dataSingle: ActiveStrategicSingleClass = Container.get(ActiveStrategicSingleClass);
  dataList: ActiveIndiciatorListClass = Container.get(ActiveIndiciatorListClass);
  docs: Array<IndicatorProfileModel> = [];

  get missions() {
    try {
      return this.dataSingle.data!.missions;
    } catch (error) {
      return [];
    }
  }
  get objectives() {
    try {
      return this.dataSingle.data!.objectives;
    } catch (error) {
      return [];
    }
  }
  getIndicatorName(id:any) {
    try {
      let found = this.docs.find((el)=>el.id = id)
      return found!.name

    } catch (error) {
      return "-"
    }
  }
  mounted() {
    this.initData();
  }
  async initData() {
    await this.dataSingle.getStrategicSingle(this.$route.params.id as string);
    await this.dataList.getIndiciatorList({});
    this.docs = this.dataList.data!.docs;
  }
}
