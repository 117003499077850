import { json } from "d3";
import BudgetModel from "../budget";
import ProjectModel from "../project";
import SectorModel from "../sector";
import StrategicModel from "../strategic";
import UserModel from "../user";

export default class ReportModel {
  //A01-A02
  id?: string;
  name: string;
  indicatorCode: string;
  items: Array<ItemReportModel>;
  //
  //A04
  code: string;
  number: string;
  subject: string;
  source: string;
  responsible: string;
  type: string;
  value: string;
  project: string;
  projectCode: string;
  sector: string;
  //
  //A08
  sector_id: string | SectorModel;
  project_id: string | ProjectModel;
  bachelor: string;
  master: string;
  other: string;
  bachelorAmount: string;
  masterAmount: string;
  otherAmount: string;
  sumQty: string;
  sum1: string;
  sum2: string;
  sum3: string;
  sumTotal: string;
  //
  //A10
  strategicItems: Array<strategicReportModel>;
  tacticsItems: Array<tacticsReportModel>;
  projects: Array<ProjectModel>;
  //

  constructor(jsonData: any) {
    this.id = jsonData.id,
      this.code = jsonData.code,
      this.name = jsonData.name,
      this.indicatorCode = jsonData.indicatorCode,
      this.items = jsonData.items.map((m: any) => new ItemReportModel(m))
    this.number = jsonData.number,
      this.subject = jsonData.subject,
      this.source = jsonData.source,
      this.responsible = jsonData.responsible,
      this.type = jsonData.type,
      this.value = jsonData.value,
      this.project = jsonData.project,
      this.projectCode = jsonData.projectCode,
      this.sector = jsonData.sector,
      this.sector_id = jsonData.sector_id,
      this.project_id = jsonData.project_id,
      this.bachelor = jsonData.bachelor,
      this.master = jsonData.master,
      this.other = jsonData.other,
      this.bachelorAmount = jsonData.bachelorAmount,
      this.masterAmount = jsonData.masterAmount,
      this.otherAmount = jsonData.otherAmount,
      this.sumQty = jsonData.sumQty,
      this.sum1 = jsonData.sum1,
      this.sum2 = jsonData.sum2,
      this.sum3 = jsonData.sum3,
      this.sumTotal = jsonData.sumTotal,
      this.strategicItems = jsonData.strategicItems.map((m: any) => new strategicReportModel(m)),
      this.tacticsItems = jsonData.tacticsItems.map((m: any) => new tacticsReportModel(m)),
      this.projects = jsonData.projects.map((m: any) => new ProjectModel(m))

  }
}
export class ItemReportModel {
  id?: string;
  code: string;
  name: string;
  sourceCode: string;
  subject: string;
  responsible: string;
  budgetSource: string;
  budget: number;
  project_id: string | ProjectModel;
  number: string;
  sector: string;
  indicator: Array<string>;
  constructor(obj: any) {
    this.id = obj.id
    this.code = obj.code
    this.name = obj.name
    this.sourceCode = obj.sourceCode
    this.subject = obj.subject
    this.responsible = obj.responsible
    this.budgetSource = obj.budgetSource
    this.budget = obj.budget
    this.project_id = obj.project_id
    this.number = obj.number
    this.sector = obj.sector
    this.indicator = obj.indicator
  }

}
export class strategicReportModel {
  id?: string;
  strategic_id: string | StrategicModel;
  items: Array<{ name: string }>;

  constructor(obj: any) {
    this.id = obj.id
    this.strategic_id = obj.strategic_id
    this.items = obj.items
  }
}
export class tacticsReportModel {
  id?: string;
  strategic_id: string | StrategicModel;
  items: Array<{ name: string, parent: string }>

  constructor(obj: any) {
    this.id = obj.id
    this.strategic_id = obj.strategic_id
    this.items = obj.items
  }
}

export class ReportA01Model {
  budgetYear: number
  docs: Array<ReportA01DocModel>
  sums:Array<ReportA01SumBudgetSourcesModel>
  sum: {
    annual: number,
    foundation: number,
    collected: number,
    central: number,
    income: number,
    country: number,
    total: number
  }
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.docs = (jsonData.docs ?? []).map((item: any) => new ReportA01DocModel(item))
    this.sum = jsonData.sum
    this.sums = (jsonData.sums ?? []).map((item: any) => new ReportA01SumBudgetSourcesModel(item))
  }
}

export class ReportA01SumBudgetSourcesModel {
  name: string
  amount:number
  constructor(jsonData: any) {
    this.name = jsonData.name ?? ""
    this.amount = jsonData.amount ?? 0
  }
}
export class ReportA01DocModel {
  indicatorCode: number
  name: string
  items: Array<ReportA01ItemModel>
  constructor(jsonData: any) {
    this.indicatorCode = jsonData.indicatorCode ?? ""
    this.name = jsonData.name ?? ""
    this.items = (jsonData.items ?? []).map((item: any) => new ReportA01ItemModel(item))
  }

}
export class ReportA01ItemModel {
  seq: number
  code: string
  name: string
  number: string
  budgetSource: string
  subject: string
  responsible: string
  type: string
  budget: number
  sum: number
  source: string
  projectName: string
  sectorName: string
  constructor(jsonData: any) {
    this.seq = jsonData.seq
    this.code = jsonData.code ?? ""
    this.name = jsonData.name ?? ""
    this.number = jsonData.number ?? ""
    this.budgetSource = jsonData.budgetSource ?? ""
    this.subject = jsonData.subject ?? ""
    this.responsible = jsonData.responsible ?? ""
    this.type = jsonData.type ?? ""
    this.budget = jsonData.budget ?? 0
    this.sum = jsonData.sum ?? 0
    this.source = jsonData.source ?? ""
    this.projectName = jsonData.projectName ?? ""
    this.sectorName = jsonData.sectorName ?? ""
  }

}
export class ReportA02Model {
  budgetYear: number
  items: Array<ReportA02ItemModel>
  indicators: Array<{
    indicatorCode: string
    indicatorType: string
    name: string
    items: Array<ReportA02ItemModel>
  }>
  constructor(jsonData: any) {

    this.budgetYear = jsonData.budgetYear
    this.items = (jsonData.items ?? []).map((item: any) => new ReportA02ItemModel(item))
    this.indicators = (jsonData.indicators ?? []).map((doc: any) => {
      console.log((doc))
      return {
        indicatorCode: doc.indicatorCode,
        indicatorType: doc.indicatorType,
        name: doc.name,
        items: (doc.items ?? []).map((el: any) => new ReportA02ItemModel(el))
      }
    })
  }
}
export class ReportA02ItemModel {
  seq: number
  indicatorCode: string
  indicatorType: string
  projectName: string
  projectCode: string
  followUp: string
  budgetAmount: number
  budgetName: string
  budgetNumber: string
  responsible: string
  budgetTypeName: string
  sectorName: string
  constructor(jsonData: any) {
    this.seq = jsonData.seq
    this.followUp = jsonData.followUp
    this.budgetName = jsonData.budgetName
    this.budgetAmount = jsonData.budgetAmount
    this.budgetTypeName = jsonData.budgetTypeName
    this.budgetNumber = jsonData.budgetNumber
    this.indicatorCode = jsonData.indicatorCode
    this.indicatorType = jsonData.indicatorType
    this.responsible = jsonData.responsible
    this.projectCode = jsonData.projectCode
    this.projectName = jsonData.projectName
    this.sectorName = jsonData.sectorName
  }

}
export class ReportA03Model {
  budgetYear: number
  items: Array<ReportA03ItemModel>
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.items = (jsonData.items ?? []).map((item: any) => new ReportA03ItemModel(item))
  }
}
export class ReportA03ItemModel {
  id: string
  withdrawType: string
  smisCode: string
  comment: string
  month: number
  amount: number
  erpData: number
  isSent: boolean
  sentDate: Date
  budget: BudgetModel
  project: ProjectModel
  user_id: string
  createdBy: UserModel
  budget_code: string
  status: string
  createdAt: Date
  constructor(jsonData: any) {
    this.id = jsonData.id
    this.withdrawType = jsonData.withdrawType
    this.smisCode = jsonData.smisCode
    this.comment = jsonData.comment
    this.month = jsonData.month
    this.amount = jsonData.amount
    this.erpData = jsonData.erpData
    this.isSent = jsonData.isSent
    this.sentDate = new Date(jsonData.sentDate)
    this.budget = new BudgetModel(jsonData.budget)
    this.project = new ProjectModel(jsonData.budget.project)
    this.user_id = jsonData.user_id
    this.createdBy = new UserModel(jsonData.createdBy)
    this.budget_code = jsonData.budget_code
    this.status = jsonData.status
    this.createdAt = new Date(jsonData.createdAt)
  }

}
export class ReportA04Model {
  budgetYear: number
  items: Array<ReportA04ItemModel>
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.items = (jsonData.items ?? []).map((item: any) => new ReportA04ItemModel(item))
  }
}
export class ReportA04ItemModel {
  seq: number
  number: string
  name: string
  responsible: string
  supportBudget: number
  investmentBudget: number
  source: string
  projectName: string
  sectorName: string
  constructor(jsonData: any) {
    this.seq = jsonData.seq
    this.number = jsonData.number ?? ""
    this.name = jsonData.name
    this.responsible = jsonData.responsible
    this.supportBudget = jsonData.supportBudget ?? 0
    this.investmentBudget = jsonData.investmentBudget ?? 0
    this.source = jsonData.source
    this.projectName = jsonData.projectName
    this.sectorName = jsonData.sectorName
  }

}

export class ReportA05Model {
  budgetYear: number
  items: Array<ReportA05ItemModel>

  sumBudgetInvestment: number
  sumBudgetSupport: number
  sumActual: number
  sumDiff: number
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.items = (jsonData.items ?? []).map((item: any) => new ReportA05ItemModel(item))
    this.sumBudgetInvestment = jsonData.sumBudgetInvestment
    this.sumBudgetSupport = jsonData.sumBudgetSupport
    this.sumActual = jsonData.sumActual
    this.sumDiff = jsonData.sumDiff
  }
}
export class ReportA05ItemModel {
  seq: number
  budgetSource: string
  budgetType: string
  responsible: string
  budgetInvestment: number
  budgetSupport: number
  actual: number
  diff: number
  source: string
  projectName: string
  sectorName: string
  constructor(jsonData: any) {
    this.seq = jsonData.seq
    this.budgetSource = jsonData.budgetSource
    this.budgetType = jsonData.budgetType
    this.responsible = jsonData.responsible
    this.budgetInvestment = jsonData.budgetInvestment ?? 0
    this.budgetSupport = jsonData.budgetSupport ?? 0
    this.actual = jsonData.actual ?? 0
    this.diff = jsonData.diff ?? 0
    this.source = jsonData.source
    this.projectName = jsonData.projectName
    this.sectorName = jsonData.sectorName
  }

}

export class ReportA07Model {
  budgetYear: number
  budgetInvestment: number
  budgetSupport: number
  actual: number
  diff: number
  sectors: Array<ReportA07SectorModel>
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.sectors = (jsonData.items ?? []).map((item: any) => new ReportA07SectorModel(item))
    this.budgetInvestment = jsonData.budgetInvestment ?? 0
    this.budgetSupport = jsonData.budgetSupport ?? 0
    this.actual = jsonData.actual ?? 0
    this.diff = jsonData.diff ?? 0
  }
}
export class ReportA07SectorModel {
  budgetInvestment: number
  budgetSupport: number
  actual: number
  diff: number
  name: string
  projects: Array<ReportA07ItemModel>

  constructor(jsonData: any) {
    this.budgetInvestment = jsonData.budgetInvestment
    this.budgetSupport = jsonData.budgetSupport
    this.actual = jsonData.actual
    this.diff = jsonData.diff
    this.name = jsonData.name
    this.projects = (jsonData.projects ?? []).map((item: any) => new ReportA07ItemModel(item))
  }
}
export class ReportA07ItemModel {
  seq: number
  budgetSource: string
  budgetType: string
  responsible: string
  budgetInvestment: number
  budgetSupport: number
  actual: number
  diff: number
  source: string
  projectName: string
  sectorName: string
  constructor(jsonData: any) {
    this.seq = jsonData.seq
    this.budgetSource = jsonData.budgetSource
    this.budgetType = jsonData.budgetType
    this.responsible = jsonData.responsible
    this.budgetInvestment = jsonData.budgetInvestment ?? 0
    this.budgetSupport = jsonData.budgetSupport ?? 0
    this.actual = jsonData.actual ?? 0
    this.diff = jsonData.diff ?? 0
    this.source = jsonData.source
    this.projectName = jsonData.projectName
    this.sectorName = jsonData.sectorName
  }


}

export class ReportA08Type1Model {
  budgetYear: number
  items: Array<ReportA08Type1ItemModel>
  sumBachelor: number
  sumMaster: number
  sumOther: number
  sumTotalQty: number
  sumBachelorAmount: number
  sumMasterAmount: number
  sumOtherAmount: number
  sumTotalAmount: number
  sumtotalInOtherBenefit: number
  sumtotalInOtherSpecialLiving: number
  sumtotalInOtherThreeProvinceBanefit: number
  sumtotalInOtherThreeProvinceRisk: number
  sumtotalInOtherOther: number
  sumtotalInOtherTotal: number
  sumFinalTotal:number
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.sumBachelor = jsonData.sumBachelor
    this.sumMaster = jsonData.sumMaster
    this.sumOther = jsonData.sumOther
    this.sumTotalQty = jsonData.sumTotalQty
    this.sumBachelorAmount = jsonData.sumBachelorAmount
    this.sumMasterAmount = jsonData.sumMasterAmount
    this.sumOtherAmount = jsonData.sumOtherAmount
    this.sumTotalAmount = jsonData.sumTotalAmount
     this.sumtotalInOtherBenefit = jsonData.sumtotalInOtherBenefit
     this.sumtotalInOtherSpecialLiving = jsonData.sumtotalInOtherSpecialLiving
     this.sumtotalInOtherThreeProvinceBanefit = jsonData.sumtotalInOtherThreeProvinceBanefit
     this.sumtotalInOtherThreeProvinceRisk = jsonData.sumtotalInOtherThreeProvinceRisk
     this.sumtotalInOtherOther = jsonData.sumtotalInOtherOther
     this.sumtotalInOtherTotal = jsonData. sumtotalInOtherTotal
     this.sumFinalTotal = jsonData.sumFinalTotal
     this.items = (jsonData.items ?? []).map((item: any) => new ReportA08Type1ItemModel(item))
  }
}
export class ReportA08Type1ItemModel {
  seq: number
  revison: number
  projectCode: string
  projectName: string
  sectorName: string
  budgetSource: string
  bachelor: number
  master: number
  other: number
  sumQty: number
  bachelorAmount: number
  masterAmount: number
  otherAmount: number
  sumAmount: number
  finalTotal:number
  otherObject: {
    benefit: number
    specialLiving: number
    threeProvinceBanefit: number
    threeProvinceRisk:number
    other:number
    total: number
  }

  constructor(jsonData: any) {
    this.seq = jsonData.seq
    this.revison = jsonData.revison
    this.projectCode = jsonData.projectCode
    this.projectName = jsonData.projectName
    this.sectorName = jsonData.sectorName
    this.budgetSource = jsonData.budgetSource
    this.bachelor = jsonData.bachelor ?? 0
    this.master = jsonData.master ?? 0
    this.other = jsonData.other ?? 0
    this.sumQty = jsonData.sumQty ?? 0
    this.bachelorAmount = jsonData.bachelorAmount ?? 0
    this.masterAmount = jsonData.masterAmount ?? 0
    this.otherAmount = jsonData.otherAmount ?? 0
    this.sumAmount = jsonData.sumAmount ?? 0
    this.finalTotal = jsonData.finalTotal ?? 0
    this.otherObject = (jsonData.otherObject ?? {
      benefit:0,
      specialLiving:0,
      threeProvinceBanefit:0,
      threeProvinceRish:0,
      other:0,total:0})
  }
}


export class ReportA08Type2Model {
  budgetYear: number
  items: Array<ReportA08Type2ItemModel>
  sumAfter: number
  sumBefore: number
  sumDiff: number
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.sumAfter = jsonData.sumAfter
    this.sumBefore = jsonData.sumBefore
    this.sumDiff = jsonData.sumDiff
    this.items = (jsonData.items ?? []).map((item: any) => new ReportA08Type2ItemModel(item))
  }
}

export class ReportA08Type2ItemModel {
  seq: number
  revison: number
  projectCode: string
  projectName: string
  sectorName: string
  budgetTypeName: string
  budgetNumber: string
  budgetCode: string
  budgetName: string
  budgetGoals: string
  budgetAfter: number
  budgetBefore: number
  diff: number
  constructor(jsonData: any) {
    this.seq = jsonData.seq
    this.revison = jsonData.revison
    this.projectCode = jsonData.projectCode
    this.projectName = jsonData.projectName
    this.sectorName = jsonData.sectorName
    this.budgetTypeName = jsonData.budgetTypeName
    this.budgetNumber = jsonData.budgetNumber
    this.budgetCode = jsonData.budgetCode
    this.budgetName = jsonData.budgetName
    this.budgetGoals = jsonData.budgetGoals
    this.budgetAfter = jsonData.budgetAfter
    this.budgetBefore = jsonData.budgetBefore
    this.diff = jsonData.diff
  }
}
export class ReportA10Model {
  code: string
  name: string
  sector: string
  months: Array<number>
  budgetSourceType: string
  types: Array<string>
  sumTypes: Array<{ name: string, amount: number }>
  q1: number
  q2: number
  q3: number
  q4: number
  totalInvestment: number
  totalCollection: number
  total: number

  constructor(jsonData: any) {
    this.code = jsonData.code
    this.name = jsonData.name
    this.sector = jsonData.sector
    this.months = jsonData.months
    this.budgetSourceType = jsonData.budgetSourceType ?? "-"
    this.types = jsonData.types ?? []
    this.sumTypes = jsonData.sumTypes ?? []
    this.q1 = jsonData.q1
    this.q2 = jsonData.q2
    this.q3 = jsonData.q3
    this.q4 = jsonData.q4
    this.totalInvestment = jsonData.totalInvestment
    this.totalCollection = jsonData.totalCollection
    this.total = jsonData.total
  }
}

export class ReportB03Model {
  budgetYear: number
  type: string
  items: Array<ReportB03ItemModel>
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.type = jsonData.type
    this.items = (jsonData.items ?? []).map((item: any) => new ReportB03ItemModel(item))
  }
}
export class ReportB03ItemModel {
  seq: number
  type: string
  sectorName: string
  projectSeq: number
  budgetSeq: number
  projectCode: string
  projectName: string
  projectAmount: number
  budgetName: string
  budgetNumber: string
  budget: number
  remark: string

  constructor(jsonData: any) {
    this.seq = jsonData.seq
    this.type = jsonData.type
    this.sectorName = jsonData.sectorName
    this.projectSeq = jsonData.projectSeq
    this.budgetSeq = jsonData.budgetSeq
    this.projectSeq = jsonData.projectSeq
    this.projectCode = jsonData.projectCode
    this.projectName = jsonData.projectName
    this.projectAmount = jsonData.projectAmount
    this.budgetName = jsonData.budgetName
    this.budgetNumber = jsonData.budgetNumber
    this.budget = jsonData.budget ?? 0
    this.remark = jsonData.remark
  }
}

export class ReportB04Model {
  budgetYear: number
  items: Array<ReportB04ItemModel>
  sumMaster: number
  sumBachelor: number
  sumProfessional: number
  sumHighVocational: number
  sumVocational: number
  sumGrade12: number
  sumGrade9: number
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.sumBachelor = jsonData.sumBachelor
    this.sumMaster = jsonData.sumMaster
    this.sumProfessional = jsonData.sumProfessional
    this.sumHighVocational = jsonData.sumHighVocational
    this.sumVocational = jsonData.sumVocational
    this.sumGrade12 = jsonData.sumGrade12
    this.sumGrade9 = jsonData.sumGrade9
    this.items = (jsonData.items ?? []).map((item: any) => new ReportB04ItemModel(item))

  }
}
export class ReportB04ItemModel {
  seq: number
  sectorCode: string
  sectorName: string
  projectCode: string
  projectName: string
  master: number
  bachelor: number
  professional: number
  highVocational: number
  vocational: number
  grade12: number
  grade9: number
  remark: string

  constructor(jsonData: any) {
    this.seq = jsonData.seq
    this.sectorCode = jsonData.sectorCode
    this.sectorName = jsonData.sectorName
    this.projectCode = jsonData.projectCode
    this.projectName = jsonData.projectName
    this.master = jsonData.master ?? 0
    this.bachelor = jsonData.bachelor ?? 0
    this.professional = jsonData.professional ?? 0
    this.highVocational = jsonData.highVocational ?? 0
    this.vocational = jsonData.vocational ?? 0
    this.grade12 = jsonData.grade12 ?? 0
    this.grade9 = jsonData.grade9 ?? 0
    this.remark = jsonData.remark
  }
}

export class ReportB19Model {

  items: Array<ReportB19ItemModel>
  itemsNoKPI: Array<ReportB19ItemNoKPIModel>
  constructor(jsonData: any) {

    this.items = (jsonData.items ?? []).map((item: any) => new ReportB19ItemModel(item))
    this.itemsNoKPI = (jsonData.noKPI ?? []).map((item: any) => new ReportB19ItemNoKPIModel(item))
  }
}
export class ReportB19ItemNoKPIModel {
  projectCode: string
  projectName: string
  constructor(jsonData: any) {
    this.projectCode = jsonData.projectCode
    this.projectName = jsonData.projectName

  }
}
export class ReportB19ItemModel {
  indicatorCode: string
  indicatorName: string
  itemsBudget: Array<ReportB19ItemKPIModel>
  constructor(jsonData: any) {
    this.indicatorCode = jsonData.indicatorCode
    this.indicatorName = jsonData.indicatorName
    this.itemsBudget = (jsonData.items ?? []).map((item: any) => new ReportB19ItemKPIModel(item))
  }
}
export class ReportB19ItemKPIModel {
  projectCode: string
  projectName: string
  budgetNumber: string
  budgetName: string
  amount: number
  constructor(jsonData: any) {
    this.projectCode = jsonData.projectCode
    this.projectName = jsonData.projectName
    this.budgetNumber = jsonData.budgetNumber
    this.budgetName = jsonData.budgetName
    this.amount = jsonData.amount

  }
}
export class ReportB05Model {
  budgetYear: number
  items: Array<ReportB05ItemModel>
  constructor(jsonData: any) {
    this.budgetYear = jsonData.budgetYear
    this.items = (jsonData.items ?? []).map((item: any) => new ReportB05ItemModel(item))
  }
}

export class ReportB05ItemModel {
  name: string
  level: number
  months: Array<number>
  constructor(jsonData: any) {
    this.name = jsonData.name
    this.level = jsonData.level ?? 0
    this.months = jsonData.months ?? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  }
}

