
import { Options, Vue } from "vue-class-component";
import AdjustmentModel from "@/model/adjustment";

@Options({
  components: {},
})
export default class PlanTableComponent extends Vue {
  doc: AdjustmentModel = new AdjustmentModel({})
}
