
import { Options, Vue } from "vue-class-component";
import { ActiveAdjustmentSingleClass } from "@/data/adjustment";
import AdjustmentModel from "../../model/adjustment";
import Container from "typedi";
import SectorModel from "@/model/sector";
import { ActiveSectorListClass } from "@/data/sector";
import NumberField from "../../components/NumberField.vue";
import DocumentA from "@/document/pdf";
import { DocumentExcel } from "@/document/xlsx";
import { ActiveProjectSingleClass } from "@/data/project";
import UserModel from "@/model/user";
import store from "@/store";
import UserServices from "@/service/user";

@Options({
  components: {},
})
export default class AdjustmentPlanView extends Vue {
  disabled: boolean = false;
  dataSingle: ActiveAdjustmentSingleClass = Container.get(
    ActiveAdjustmentSingleClass
  );
  projectSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  sectorData: Array<SectorModel> = [];
  revisionType = [
    { value: "ปรับครั้งที่", name: "ปรับครั้งที่" },
    { value: "ทบทวนครั้งที่", name: "ทบทวนครั้งที่" },
    { value: "โอนครั้งที่", name: "โอนครั้งที่" },
  ];
  store = store;
  reporter =""
  sumAmountBefore(item: any, type: string) {
    try {
      let arr: Array<number> = this.dataSingle
        .findBudgetFromFlatted(item, "before")
        .items.map((el: any) => el.amount ?? 0);
      return arr.reduce((a, b) => a + b, 0).formatComma();
    } catch (error) {
      return 0;
    }
  }
  sumAmountAfter(item: any, type: string) {
    try {
      let arr: Array<number> = this.dataSingle
        .findBudgetFromFlatted(item, "after")
        .items.map((el: any) => el.amount ?? 0);
      return arr.reduce((a, b) => a + b, 0).formatComma();
    } catch (error) {
      return 0;
    }
  }
  sumAfter() {}

  get sector() {
    try {
      let arr: Array<Object> = [];
      this.sectorData.forEach((el) => {
        arr.push({
          name: el.name,
          // value:el.name
          value: `${el.name}`,
        });
      });
      return arr;
    } catch (error) {
      console.log("sector error",error)
      return [];
    }
  }
  mounted() {
    this.disabled = this.$route.path.search("/detail") != -1;
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new AdjustmentModel({});
      try {
        this.dataSingle.data!.budgetYear = this.projectSingle.data!.budgetYear;
      } catch (error) {}
      try {
        this.dataSingle.data!.projectName =
          this.projectSingle.data!.code + "  " + this.projectSingle.data!.name;
      } catch (error) {}
      this.dataSingle.loading = false;
    }
    this.reporter =  this.store.state.firstName
  }
  async initData() {
    await this.dataSingle.getAdjustmentSingle(this.$route.params.id as string);
    console.log(this.dataSingle.data, "ดีสาม");
    await this.sectorList.getSectorList({search: { category: "ฝ่าย/สำนัก" }});
    this.sectorData = this.sectorList.data!.docs;
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createAdjustmentSingle();
    } else {
      this.dataSingle.updateAdjustmentSingle();
    }
  }
  getMonth(i: number) {
    switch (i) {
      case 0:
        return "ตุลาคม";
        break;
      case 1:
        return "พฤศจิกายน";
        break;
      case 2:
        return "ธันวาคม";
        break;
      case 3:
        return "มกราคม";
        break;
      case 4:
        return "กุมภาพันธ์";
        break;
      case 5:
        return "มีนาคม";
        break;
      case 6:
        return "เมษายน";
        break;
      case 7:
        return "พฤษภาคม";
        break;
      case 8:
        return "มิถุนายน";
        break;
      case 9:
        return "กรกฎาคม";
        break;
      case 10:
        return "สิงหาคม";
        break;
      case 11:
        return "กันยายน";
        break;
    }
  }
  async generatePDF() {
    console.log("generatePDF");
    const documentA = new DocumentA();
    let sector: Array<any> = [];
      try {
      sector = this.projectSingle.data!.items!.find(
        (el: any) => el.code == "sectors"
      )!.value;
      for (const user of sector) {
        try {
          user.signature = await (
            await new UserServices().getUserSingle(user.user_id)
          ).signature;
          console.log("user.signature", user.signature);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
    console.log(sector);
    documentA.createD301(this.dataSingle,this.reporter,sector);
    documentA.save(`reportD301.pdf`);
    // documentA.createD302(this.dataSingle);
    // documentA.save(`reportD302.pdf`);
    // documentA.createD303(this.dataSingle);
    // documentA.save(`reportD303.pdf`);
  }
  async generateTablePDF() {
    console.log("generatePDF");
    const documentA = new DocumentA();
    documentA.createD303(this.dataSingle);
    documentA.save(`reportD303.pdf`);
    // documentA.createD302(this.dataSingle);
    // documentA.save(`reportD302.pdf`);
    // documentA.createD303(this.dataSingle);
    // documentA.save(`reportD303.pdf`);
  }
  async generateTableExcel() {
    let docExcel = new DocumentExcel();
    docExcel.createAdjustTable(this.dataSingle,this.dataSingle.sumBeforeFormatted,this.dataSingle.sumAfterFormatted);
  }
  updateFromAutoPerson(user:UserModel,ev:Event){
    console.log({user, ev})
    this.dataSingle.data!.forUserId = user.id!
    this.dataSingle.data!.forUserFullName = user.fullName
  }
}
