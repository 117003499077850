import ADDRESS from "@/setting";
import axios from "axios"
import KpiReviewModel,{ReviewResultModel,EvidenceModel} from "@/model/kpiReview";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class IndiciatorReviewServices {
  public getIndiciatorReviewList(query: any) {
    return new Promise<PaginateResponse<KpiReviewModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/indicatorReviews/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<KpiReviewModel> = response.data.docs.map((el: any) => new KpiReviewModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<KpiReviewModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getIndiciatorReviewSingle(id: string) {
    return new Promise<KpiReviewModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/indicatorReview/get/${id}`);
      let doc: KpiReviewModel = new KpiReviewModel(response.data.doc)
      resolve(doc)
    })
  }

  public updateIndiciatorReview(id: string, IndiciatorReview: KpiReviewModel) {
    console.log('response1',id)
    return new Promise<KpiReviewModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/indicatorReview/update/${id}`, IndiciatorReview.toJSON());
      let doc: KpiReviewModel = new KpiReviewModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createIndiciatorReview(IndiciatorReview: KpiReviewModel) {
    return new Promise<KpiReviewModel>(async (resolve, reject) => {
      console.log('response1')
      let response = await axios.post(`${ADDRESS}/indicatorReview/create/`, IndiciatorReview);
      console.log('response2',response)
      let doc: KpiReviewModel = new KpiReviewModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public deleteIndicatorReviewResult(id:string) {
    return new Promise<string>(async (resolve, reject) => {
      let query = {
        id : id
      }
      let response = await axios.delete(`${ADDRESS}/indicatorReview/delete-result/`,{ data: { id: id } });
      let doc: ReviewResultModel = new ReviewResultModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-IR1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }
  public deleteIndicatorReviewEvidence(id:string) {
    return new Promise<any>(async (resolve, reject) => {
      let query = {
        id : id
      }
      let response = await axios.delete(`${ADDRESS}/indicatorReview/delete-evidence/`,{ data: { id: id } });
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-IR2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }

  public deleteIndicatorReviewAttachment(id:string) {
    return new Promise<any>(async (resolve, reject) => {
      let query = {
        id : id
      }
      let response = await axios.delete(`${ADDRESS}/indicatorReview/delete-attachment/`,{ data: { id: id } });
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-IR3)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }
}
