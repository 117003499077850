
import { Options, Vue } from "vue-class-component";
@Options({
  props:{
    modelValue:Array,
    name:String,
    link:String,
    disabled:Boolean,
    limit:Number,
    type!:String,
    label:String,
  },
})
export default class TextArray extends Vue {
  name!:string
  link!:string
  label!:string
  disabled!:boolean
  limit!:number
  type!:string
  modelValue!:Array<string>
  val:Array<string> = []

  mounted(){
    this.val = this.modelValue??[]
  }
  pushItem(){
    if (typeof this.val === "string") this.val = [];
    else if (!Array.isArray(this.val)) this.val = [];
    this.val.push("");
    this.emitUp();
  }
  deleteItem(i:number){
    let c = confirm("ยืนยันลบรายการหรือไม่?")
    if(c){
      this.val.splice(i,1)
      this.emitUp()
    }
  }

  emitUp(){
    this.$emit("update:modelValue", this.val);
  }
}
