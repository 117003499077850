
import { Options, Vue } from "vue-class-component";
import AdjustmentModel from "@/model/adjustment";
import {
  ActiveBudgetSingleClass,
  ActiveBudgetParentSingleClass,
} from "@/data/budget";
import Container from "typedi";
import BudgetModel from "@/model/budget";
import ModalBudgetIndicator from "./ModalBudgetIndicator.vue";
import { ActiveAdjustmentSingleClass } from "@/data/adjustment";
import { ActiveProjectSingleClass } from "@/data/project";
import SystemServices from "@/service/system";
import { ActiveBudgetDegreeClass, ActiveBudgetSourceTypeClass } from "@/data/system";
@Options({
  components: {
    ModalBudgetIndicator,
  },
})
export default class PlanTableComponent extends Vue {
  budget: ActiveBudgetSingleClass = Container.get(ActiveBudgetSingleClass);
  // budgetParent: ActiveBudgetParentSingleClass = Container.get(ActiveBudgetParentSingleClass);
  // budgetParent2: ActiveBudgetParentSingleClass = Container.get(ActiveBudgetParentSingleClass);

  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  budgetSourceTypeSetting: ActiveBudgetSourceTypeClass = Container.get(
    ActiveBudgetSourceTypeClass
  );
  degreeSetting:ActiveBudgetDegreeClass = Container.get(
    ActiveBudgetDegreeClass
  );
  doc: AdjustmentModel = new AdjustmentModel({});
  budgetOverlap: Array<any> = [];
  operationTypes = [
    { name: "รายการอื่นๆ", value: "รายการอื่นๆ" },
    { name: "เตรียมการและบริหารจัดการ", value: "เตรียมการและบริหารจัดการ" },
  ];
  budgetTypes = [
    {
      value: "งบประมาณประจำปี,งบอุดหนุน,1400",
      name: "งบประมาณประจำปี (งบอุดหนุน) 1400",
    },
    {
      value: "งบประมาณประจำปี,งบลงทุน,1300",
      name: "งบประมาณประจำปี (งบลงทุน) 1300",
    },
    {
      value: "งบกองทุนพัฒนากีฬาแห่งชาติ,งบอุดหนุน,1600",
      name: "งบกองทุนพัฒนากีฬาแห่งชาติ (งบอุดหนุน) 1600",
    },
    {
      value: "งบกองทุนพัฒนากีฬาแห่งชาติ,งบลงทุน,1600",
      name: "งบกองทุนพัฒนากีฬาแห่งชาติ (งบลงทุน) 1600",
    },
    {
      value: "งบเงินสะสมของ กกท.,งบอุดหนุน,4000",
      name: "งบเงินสะสมของ กกท. (งบอุดหนุน) 4000",
    },
    {
      value: "งบเงินสะสมของ กกท. ,งบลงทุน,4000",
      name: "งบเงินสะสมของ กกท.  (งบลงทุน) 4000",
    },
    { value: "งบกลาง,งบอุดหนุน,1500", name: "งบกลาง  (งบอุดหนุน) 1500" },
    { value: "งบกลาง,งบลงทุน,1500", name: "งบกลาง (งบลงทุน) 1500" },
    {
      value: "งบเงินรายได้,งบอุดหนุน,2000",
      name: "งบเงินรายได้ (งบอุดหนุน) 2000",
    },
    { value: "งบเงินรายได้,งบลงทุน,2000", name: "งบเงินรายได้ (งบลงทุน) 2000" },
    {
      value: "งบประมาณแผ่นดิน,งบอุดหนุน,1000",
      name: "งบประมาณแผ่นดิน (งบอุดหนุน) 1000",
    },
    {
      value: "งบประมาณแผ่นดิน,งบลงทุน,1000",
      name: "งบประมาณแผ่นดิน (งบลงทุน) 1000",
    },
  ];
  expenseTypes = [
    {
      value: "การจ้างผู้ช่วยปฏิบัติงาน",
      name: "การจ้างผู้ช่วยปฏิบัติงาน ฯ (ประกอบด้วย จำนวน/วุฒิการศึกษา เป็นต้น)",
    },
    {
      value: "ค่าใช้จ่ายในการเดินทางต่างประเทศ",
      name: "ค่าใช้จ่ายในการเดินทางต่างประเทศ",
    },
    {
      value: "ค่าใช้จ่ายในการเดินทางในประเทศ",
      name: "ค่าใช้จ่ายในการเดินทางในประเทศ",
    },
    { value: "ค่าเช่าเครื่องถ่ายเอกสาร", name: "ค่าเช่าเครื่องถ่ายเอกสาร" },
    { value: "การจัดซื้อวัสดุสำนักงาน", name: "การจัดซื้อวัสดุสำนักงาน" },
    { value: "ค่าเบี้ยประชุม/ค่าตอบแทน", name: "ค่าเบี้ยประชุม/ค่าตอบแทน" },
    {
      value: "ค่าจัดประชุมคณะกรรมการต่างๆ",
      name: "ค่าจัดประชุมคณะกรรมการต่างๆ",
    },
    { value: "จ้างที่ปรึกษา", name: "จ้างที่ปรึกษา" },
    { value: "การจัดทำระบบไอที", name: "การจัดทำระบบไอที" },
    { value: "งบลงทุนภายในรายการ", name: "งบลงทุนภายในรายการ" },
    {
      value: "การปฏิบัติงานนอกเวลาราชการ (OT)",
      name: "การปฏิบัติงานนอกเวลาราชการ (OT)",
    },
  ];
  mounted() {
    // this.doc = this.budget.data!
    this.fetchSetting()
  }

  async fetchSetting(){
    // ประเภทแหล่งเงินงบประมาณในหน้าแผน
    let settingExpense = await new SystemServices().getSystemByName("ประเภทค่าใช้จ่ายในหน้าแผน")
    console.log({settingExpense})
    this.expenseTypes = settingExpense.map(item=>( { value:item.name, name: item.name}))

    this.budgetSourceTypeSetting.getBudgetSourceTypeSetting("ประเภทแหล่งงบประมาณในหน้าแผน")
    this.degreeSetting.getBudgetDegreeSetting("ประเภทวุฒิการศึกษาในหน้าแผน")
  }
  clearBudget() {
    this.budget.data = new BudgetModel({});
  }
  getMonth(i: number) {
    switch (i) {
      case 0:
        return "ตุลาคม";
        break;
      case 1:
        return "พฤศจิกายน";
        break;
      case 2:
        return "ธันวาคม";
        break;
      case 3:
        return "มกราคม";
        break;
      case 4:
        return "กุมภาพันธ์";
        break;
      case 5:
        return "มีนาคม";
        break;
      case 6:
        return "เมษายน";
        break;
      case 7:
        return "พฤษภาคม";
        break;
      case 8:
        return "มิถุนายน";
        break;
      case 9:
        return "กรกฎาคม";
        break;
      case 10:
        return "สิงหาคม";
        break;
      case 11:
        return "กันยายน";
        break;
    }
  }
  pushBudgetOverlap() {
    this.budgetOverlap.push({
      months: Object.assign([], new Array(12).fill(0)),
    });
  }
  async submitForm() {
    let element = this.$refs.closeModalBudgetItemMoney as HTMLElement;
    element.click();
    if (this.budget.data?.id == "") {
      await this.budget.createBudgetSingle(this.$route.params.id as string);
    } else {
      await this.budget.updateBudgetSingle();
    }
    await this.activeProject.getAdjustmentD2Single(
      this.$route.params.id as string
    );
  }
  checkAll(ev:any){
    let isChecked = ev.target.checked
    if(isChecked){
      for(var i = 0; i < 12; i++) {
        this.budget.data!.items[i].isActive = true
      }
    } else {
    // console.log("isChecked", isChecked)
      for(var i = 0; i < 12; i++) {
        this.budget.data!.items[i].isActive = false
      }
    }
  }
}
