
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import AccountModel from "@/model/account";
import { ActiveAccountListClass } from "@/data/account";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class AccountListView extends Vue {
  dataList: ActiveAccountListClass = Container.get(ActiveAccountListClass);
  docs: Array<AccountModel> = [];
  pages: number = 0;
  total: number = 0;
  store = useStore();

  renderTableTool(item: AccountModel) {
    let result:Array<string> = ["Detail"]
    if(this.store.getters.hasRight("setting_zpcode_editall")) result.push("Edit")
    if(this.store.getters.hasRight("setting_zpcode_delete")) result.push("Delete")
    if(this.store.getters.hasRight("setting_zpcode_detail")) result.push("Detail")
    return result;
  }
  mounted() {
    this.initData();
  }
  async initData() {
    await this.dataList.getAccountList({
    sort:{
        code: "asc"
      }
    });
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
