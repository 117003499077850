
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import PublicrelationModel from "@/model/publicrelation";
import { ActivePublicrelationSingleClass } from "@/data/publicrelation";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import ADDRESS from "@/setting"

@Options({
  components: {},
})
export default class PositionFormView extends Vue {

  dataSingle: ActivePublicrelationSingleClass = Container.get(ActivePublicrelationSingleClass);
  
  ADDRESS = ADDRESS
 
  mounted() {
    this.initData();
  }
  async initData() {
    await this.dataSingle.getflashnewsSingle();
    
  }
}
