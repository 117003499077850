
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import IndicatorReviewModel from "@/model/kpiReview";
import { ActiveReviewIndiciatorListClass } from "@/data/kpiReview";
import { ActiveIndiciatorSingleClass } from "@/data/indicator";
import SectorModel from "@/model/sector";
import Container from "typedi";
import { result } from "lodash";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class IndiciatorListView extends Vue {
  dataSingle: ActiveIndiciatorSingleClass = Container.get(
    ActiveIndiciatorSingleClass
  );
  dataList: ActiveReviewIndiciatorListClass = Container.get(ActiveReviewIndiciatorListClass);
  docs: Array<IndicatorReviewModel> = [];
  pages: number = 0;
  total: number = 0;
  filter1: any = {}
  filter2: any = {}
  show: number = 20;
  page: number = 1;
  store = useStore();

  renderTableTool(item: IndicatorReviewModel) {
    let result:Array<string> = []
    if(this.store.getters.hasRight("indicator_followup_editall")) result.push("EditList")
    if(this.store.getters.hasRight("indicator_followup_delete")) result.push("Delete")
    if(this.store.getters.hasRight("indicator_followup_detail")) result.push("DetailList")
    return result;
  }
  mounted() {
    this.initData();
    this.dataSingle.getIndiciatorSingle(this.$route.params.id as string)
  }
  async initData() {
    
    let query = {
      search: {
        // ...this.filter1,
        // ...this.filter2
        indicator_id : this.$route.params.id,
      },
      limit: this.show,
      page: this.page,
      sort: {
       createdAt:"desc"
      }
    }
    console.log('query',query)
    await this.dataList.getReviewIndiciatorList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
  
}
