export  default class SectorModel {
  id?: string;
  parent_id?: string;
  parent?: SectorModel;
  name: string;
  nameEN: string;
  code: string;
  longCode: string;
  category: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.parent_id = jsonData.parent_id;
    try { this.parent = new SectorModel(jsonData.parent); } catch (error) {}
    this.name = jsonData.name;
    this.nameEN = jsonData.nameEN;
    this.code = jsonData.code;
    this.longCode = jsonData.longCode;
    this.category = jsonData.category;
  }
  get parentName(){
    return this.parent?.name ?? "-"
  }
  toJSON(){
    let obj = Object.assign({},this)
    
    return JSON.parse(JSON.stringify(obj))
  }
}