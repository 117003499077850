
import { Options, Vue } from "vue-class-component";
import TableRow from "@/views/adjustment/components/TableRow.vue";
import Selecto from "selecto";
import { ActiveBudgetSingleClass } from "@/data/budget";
import Container from "typedi";
import { ActiveProjectSingleClass } from "@/data/project";
import { ActiveWithdrawListClass } from "@/data/withdraw";

@Options({
  components: {
    TableRow,
  },
  props:{
    tudNgern:Boolean,
  }
})
export default class PlanTableComponent extends Vue {
  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  withdrawList:ActiveWithdrawListClass = Container.get(ActiveWithdrawListClass)
  budgetitems: Array<any> = [];
  sumSelected: number = 0;
  sum = 0;
  tudNgern!:boolean
  get showSumDetail(){
    if(this.sum%100==0){
      return false
    }
    return true
  }
  get increaseAlert() {
    let sumString = this.sum.toString();
    let d1 = sumString[sumString.length - 2];
    let d2 = sumString[sumString.length - 1];
    let d = parseInt(d1 + d2);
    return (
      "ต้องเพิ่มจำนวนเงินอีก " +
      (100 - d) +
      ` จะได้ ${(this.sum + 100 - d).formatFull()}`
    );
  }
  get decreaseAlert() {
    let sumString = this.sum.toString();
    let d1 = sumString[sumString.length - 2];
    let d2 = sumString[sumString.length - 1];
    let d = parseInt(d1 + d2);
    return "ต้องลดจำนวนเงินอีก " + d + ` จะได้ ${(this.sum - d).formatFull()}`;
  }

sortByNumber(arr: Array<any>) {
  let mapped = arr.map((a) => {
    let flt = 0;
    try {
      flt = parseFloat(a.number);
    } catch (error) {}
    a.n = flt
    return a
  });
  return mapped.sort((a, b) => a.n-b.n);
}
  mounted() {
    this.initSelecto();
    this.withdrawList.getWithdrawList({ search: { budget: {project_id:this.$route.params.id} } })
  }
  initSelecto() {
    try {
      const selecto = new Selecto({
        container: document.getElementById("selecto-container")!,
        rootContainer: null,
        selectableTargets: [".selecto-target"],
        selectByClick: false,
        selectFromInside: true,
        continueSelect: false,
        toggleContinueSelect: "shift",
        keyContainer: window,
        // hitRate: 100,
      });
      selecto.on("select", (e) => {
        var sum = 0;
        e.selected.forEach((el) => {
          console.log(el.innerHTML);
          let innerHTML: any = el.innerHTML;
          let myRegexp = new RegExp(/>(.*?)</, "g");
          var match = myRegexp.exec(innerHTML);
          let fl: any = match![1].split(",").join("");
          // console.log(match)
          if (fl == "-") fl = 0;
          else fl = parseFloat(fl);
          sum += fl;
          this.sum = sum;
        });
        // console.dir("e", sum);
        e.added.forEach((el) => {
          el.classList.add("selected");
        });
        e.removed.forEach((el) => {
          el.classList.remove("selected");
        });
      });
    } catch (error) {
      console.log({ error });
    }
  }

  get totalBudget() {
    try {
      let map = this.activeProject.data?.budgets.map(budget=> budget.items.map(item=>item.amount).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b,0)
    return map
    } catch (error) {
      return 0;
    }
  }
}
