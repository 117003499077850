
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import SectorModel from "@/model/sector";
import { ActiveSectorListClass } from "@/data/sector";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import ADDRESS from "@/setting"
import Container from "typedi";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class SectorFormView extends Vue {
  dataList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  docs: Array<SectorModel> = [];
  pages: number = 0;
  total: number = 0;
  tree: Array<any> = []
  store = useStore();

  renderTableTool(item: SectorModel) {
    let result:Array<string> = ["Detail"]
    if(this.store.getters.hasRight("setting_sector_editall")) result.push("Edit")
    if(this.store.getters.hasRight("setting_sector_delete")) result.push("Delete")
    if(this.store.getters.hasRight("setting_sector_detail")) result.push("Detail")
    return result;
  }
  mounted() {
    this.initData();
    this.fetchData()
  }
  async initData() {
    let query: any = {
      sort: {
        name:"asc"
      }
    };
    await this.dataList.getSectorList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }

  async fetchData(){
    let response = await axios.post(`${ADDRESS}/sectors/tree`)
    this.tree = response.data.docs
    // console.log("response", response.data.docs)
  }
}
