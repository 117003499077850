
import { Options, Vue } from "vue-class-component";
import PetitionModel, { PetitionItemModel } from "@/model/petition";
import {
  ActiveProjectSingleClass,
  ActiveProjectForPetitionClass,
} from "@/data/project";
import { ActivePetitionSingleClass } from "@/data/petition";
import { DocumentExcel } from "@/document/xlsx";
import Container from "typedi";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class PositionFormView extends Vue {
  store = useStore();
  PetitionItemModel = PetitionItemModel;
  projectActiveData: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  petitionData: ActiveProjectForPetitionClass = Container.get(
    ActiveProjectForPetitionClass
  );
  dataSingle: ActivePetitionSingleClass = Container.get(
    ActivePetitionSingleClass
  );
  async getPrevious()  {
    try {
      
    await this.dataSingle.getPetitionPrevious(this.$route.params.projectid as string,this.dataSingle.data?.quarter!)

    // this.dataSingle.data = Object.assign({

    // },this.dataSingle.previous)
    this.dataSingle.data!.accountCode = this.dataSingle.previous!.accountCode??""
    this.dataSingle.data!.accountName = this.dataSingle.previous!.accountName??""
    this.dataSingle.data!.planName = this.dataSingle.previous!.planName??""
    this.dataSingle.data!.product = this.dataSingle.previous!.product??""
    this.dataSingle.data!.activityNumber = this.dataSingle.previous!.activityNumber??0
    this.dataSingle.data!.productCode = this.dataSingle.previous!.productCode??""
    this.dataSingle.data!.activityMainCode = this.dataSingle.previous!.activityMainCode??""
    this.dataSingle.data!.accountSubCode = this.dataSingle.previous!.accountSubCode??""
    this.dataSingle.data!.notes = this.dataSingle.previous!.notes??""
    this.dataSingle.data!.activityName = this.dataSingle.previous!.activityName??""
    this.dataSingle.data!.budgetCode = this.dataSingle.previous!.budgetCode??""
    
    } catch (error) {
      
    }
  }
  mounted() {
    if (this.$route.params.id !== undefined) {
      this.initData();
    } else {
      this.getData();
    }
  }
  async getData() {
    await this.projectActiveData.getProjectSingle(
      this.$route.params.projectid as string
    );
    await this.petitionData.getForCreatePetition(
      this.$route.params.projectid as string
    );
    this.dataSingle.data = new PetitionModel({});
    this.dataSingle.loading = false;
  }

  get items() {
    try {
      return this.dataSingle.data!.items;
    } catch (error) {
      return [];
    }
  }
  async initData() {
    await this.dataSingle.getPetitionSingle(this.$route.params.id as string);
    await this.projectActiveData.getProjectSingle(
      this.dataSingle.data!.project_id as string
    );
    await this.petitionData.getForEditPetition(
      this.dataSingle.data!.project_id as string,
      this.dataSingle.data!.fromRevision,
      this.dataSingle.data!.toRevision
    );
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      this.dataSingle.data!.project_id = this.$route.params.projectid as string;
      this.dataSingle.data!.fromRevision =
        this.petitionData.petition.data.current_revision;
      this.dataSingle.data!.toRevision =
        this.petitionData.petition.data.previous_revision;
      await this.dataSingle.createPetitionSingle();
      this.$router.push(
        `/portal/project/petition/list/${this.$route.params.projectid}`
      );
    } else {
      await this.dataSingle.updatePetitionSingle();
      this.$router.push(
        `/portal/project/petition/list/${this.dataSingle.data!.project_id}`
      );
    }
  }
get getUserNomal(){
 let nomal =  this.store.state.roles.includes("ผู้ใช้งานทั่วไป")
  return nomal
  }
  get roles() {
    try {
      if (typeof this.store.state.roles === "string")
        return JSON.parse(this.store.state.roles);
      else return this.store.state.roles[1];
    } catch (error) {
      return [];
    }
  }
get getIsAdmin(){
 let admin =  this.store.state.roles.includes("ผู้ดูแลระบบ")
  return admin
  }
  sumQ1(item: any) {
    if (item != undefined) {
      let m1 = item[0];
      let m2 = item[1];
      let m3 = item[2];
      let result = m1 + m2 + m3;
      return result;
    } else {
      return 0;
    }
  }
  sumQ2(item: any) {
    if (item != undefined) {
      let m1 = item[3];
      let m2 = item[4];
      let m3 = item[5];
      let result = m1 + m2 + m3;
      return result;
    } else {
      return 0;
    }
  }
  sumQ3(item: any) {
    if (item != undefined) {
      let m1 = item[6];
      let m2 = item[7];
      let m3 = item[8];
      let result = m1 + m2 + m3;
      return result;
    } else {
      return 0;
    }
  }
  sumQ4(item: any) {
    if (item != undefined) {
      let m1 = item[9];
      let m2 = item[10];
      let m3 = item[11];
      let result = m1 + m2 + m3;
      return result;
    } else {
      return 0;
    }
  }
  async generateReportExcel() {
    let documentExcel = new DocumentExcel();

    documentExcel.createPT(
      this.$route.params.id as string,
      this.dataSingle.data!.project_id
    );
  }
}
