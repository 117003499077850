import ADDRESS from "@/setting";
import axios from "axios"
import ProjectModel, { ProjectItemModel } from "@/model/project";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";
import Setting, { SettingMemberModel } from "@/model/system";


export default class ProjectServices {

  public getAdjustmentD2Single(id: string) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/adjustment/get-D2/${id}`);
      console.log(response.data.doc)
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      resolve(doc)
    })
  }
  public getAdjustmentD2SingleWithRevision(id: string, revision:number) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/adjustment/get-D2-revision/${id}/${revision}`);
      console.log(response.data.doc)
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      resolve(doc)
    })
  }
  public getAllRevision(id: string) {
    return new Promise<any>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/adjustment/get-D2-allrevision/${id}`);
      resolve(response.data.revisions)
    })
  }
  public checkCodeSingle(code: string) {
    console.log(code)
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/project/check-code/${code}`);
      resolve(response.data.status)
    })
  }
  public getBudgetSettingSingle() {
    return new Promise<Array<SettingMemberModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/setting/get-by-name/คำของบประมาณ`);
      let member = response.data.doc.member
      let docs: Array<SettingMemberModel> = member.map((element: any) => {
        return new SettingMemberModel(element)
      });
      resolve(docs)
    })
  }
  public getProjectList(query: any) {
    return new Promise<PaginateResponse<ProjectModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/projects/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<ProjectModel> = response.data.docs.map((el: any) => new ProjectModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<ProjectModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getProjectFollowList(query: any) {
    return new Promise<PaginateResponse<ProjectModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/projects/paginate-followup`, query);
      console.log({ data: response.data })
      let docs: Array<ProjectModel> = response.data.docs.map((el: any) => new ProjectModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<ProjectModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getProjectSingle(id: string) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let system = await this.getBudgetSettingSingle()
      let response = await axios.get(`${ADDRESS}/project/get/${id}`);
      let doc: ProjectModel = new ProjectModel(response.data.doc, system)
      system!.forEach(element => {
        let foundItem = doc.items.find(item => item.code === element.text1)
        if (!foundItem) {
          doc.items.push(new ProjectItemModel({ name: element.name, code: element.text1, itemType: element.text2, value: "" }))
        }
        else {

        }
      });
      resolve(doc)
    })
  }

  public updateProject(id: string, Project: ProjectModel) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let system = await this.getBudgetSettingSingle()
      let response = await axios.patch(`${ADDRESS}/project/update/${id}`, Project.toJSON());
      console.log('response', response)
      let doc: ProjectModel = new ProjectModel(response.data.doc, system)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createProject(Project: ProjectModel) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/project/create/`, Project.toJSON());
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }


  public deleteProjectFromList(id: string) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/project/delete/${id}`);
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public approveProjectFromList(id: string, approver_id: string) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/project/approve/`, { id, approver_id });
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      resolve(doc)
    })
  }
  public rejectProjectFromList(id: string, reject_id: string) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let reason = prompt("กรุณาระบุเหตุผล", "-");
      let response = await axios.patch(`${ADDRESS}/project/reject/`, { id, reason, reject_id });
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      resolve(doc)
    })
  }
  public getNotification(id:string) {
    return new Promise<any>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/project/notification/${id}` );
      console.log('ad',response);
      
      resolve(response)
    })
  }
  public getForCreatePetition(id:string) {
    return new Promise<any>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/project/get-for-create-petition/${id}` );
     
      resolve(response)
    })
  }
  public getForEditPetition(id:string,from:number,to:number) {
    return new Promise<any>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/project/get-for-edit-petition/${id}/${from}/${to}` );
      resolve(response)
    })
  }
}
