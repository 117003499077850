<template>
  <div
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    style="display: block">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content modal-glass" style="min-height: 80vh">
        <div class="modal-header">
          <h5 class="modal-title">กำลังปรับปรุงข้อมูล</h5>
        </div>
        <div
          class="modal-body d-flex align-items-center justify-content-center">
          <div class="block text-center">
            <img
              src="@/assets/logo.png"
              alt="SAT Logo"
              style="width: 10rem; height: 10rem" />

            <h5 class="pt-2" style="font-weight: 600">
              ระบบบริหารยุทธศาสตร์อัจฉริยะ
            </h5>
            <span
              class="d-flex text-primary align-items-center justify-content-center my-3">
              <i class="fa-solid fa-spinner fa-spin-pulse fa-3x"></i>
            </span>

            <p>กำลังปรับปรุงข้อมูลจากแม่ข่าย</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.modal-glass {
  background: rgba(255, 255, 255, 0.26);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
</style>
