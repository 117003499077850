
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveProjectSingleClass } from "@/data/project";
import BudgetModel from "@/model/budget";
import Container from "typedi";
import { confirmAction } from "@/decorator";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import ModalBudgetIndicator from "@/views/project/components/ModalBudgetIndicator.vue";
import ModalBudgetItemMoney from "@/views/project/components/ModalBudgetItemMoney.vue";

@Options({
  components: {
    ModalBudgetIndicator,
    ModalBudgetItemMoney,

  },
  props: {
    budget: Object,
    level: Number,
    showRows: Array,
    budgetParent: String,
    budgetChild: String,
  },
})
export default class PlanTableComponent extends Vue {
  budget!: any;
  level!: number;
  showRows!: string[];

  budgetParent!: string;
  budgetChild!: string;
  store = useStore()
  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  budgetSpecial: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  isFollow = false;
  menuIsVisible = false;
  get rowLength() {
    try {
      return this.showRows.length;
    } catch (error) {
      return 1;
    }
  }
  async clearBudget() {
    this.activeBudget.data = new BudgetModel({});
    console.log(this.activeBudget, typeof this.activeBudget.data)
    this.openModal()
  }
  showContextMenu() {
    if (this.activeProject.data!.step < 3 || this.store.getters.isAdmin) {
    this.menuIsVisible = !this.menuIsVisible;
    } else {
      alert("ไม่สามารถแก้ไขได้โครงการถูกอนุมัติ");
    }
  }
  activeChild() {
    if(this.budget.isSpecial){
      alert("กิจกรรมนี้เป็นกิจกรรมควบคุมพิเศษ กรุณาติดต่อ admin หรือ ผู้ประสานงานโครงการ")
      return 
    } 
    else  if (this.activeProject.data!.step < 3 || this.store.getters.isAdmin) {
      let parent_id = this.budget.id;
      let budgetTypeCategory = this.budget.budgetTypeCategory
      this.activeBudget.data = new BudgetModel({ parent_id: parent_id, budgetTypeCategory:budgetTypeCategory });
      this.activeBudget.parent = this.budgetParent;
      this.activeBudget.child = this.budgetChild;
      this.openModal();
    } else {
        alert("ไม่สามารถแก้ไขได้โครงการถูกอนุมัติ");
      }
  }
  activeItem() {
    if(this.budget.isSpecial){
      alert("กิจกรรมนี้เป็นกิจกรรมควบคุมพิเศษ กรุณาติดต่อ admin หรือ ผู้ประสานงานโครงการ")
      return 
    }
   else  if (this.activeProject.data!.step < 3 || this.store.getters.isAdmin) {
      console.log(this.budget);
      this.activeBudget.data = new BudgetModel({});
      this.activeBudget.data = this.budget;
      this.activeBudget.parent = this.budgetParent;
      this.activeBudget.child = this.budgetChild;
      this.openModal();
    } else {
      alert("ไม่สามารถแก้ไขได้โครงการถูกอนุมัติ");
    }
  }

  openModal() {
    this.$nextTick(() => {
      let ref = this.$refs.openModalFromTableRow as HTMLElement;
      ref.click();
    });
  }

  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteItem() {
    this.activeBudget.data = this.budget;
    await this.activeBudget.deleteBudgetSingle();
    await this.activeProject.updateProjectSingle();
  }

  get userAllowedToCheck() {
    try {
      if (this.coordinatorName === this.store.state.userId) return true
      else if (this.store.getters.isAdmin) return true
    } catch (error) {
      return false;
    }
    return false;
  }
  get coordinatorName() {
    try {
      return this.activeProject.data!.items.find(
        (item) => item.code === "coordinator"
      )!.value[0];
    } catch (error) {
      return "-";
    }
  }
  updateSpecial(id: string) {
    this.budgetSpecial.specialBudgetSingle(id as string);
  }
  mounted() {
    this.isFollow = this.$route.path.search("/followup") != -1;
  }

}
