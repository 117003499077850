
import { Options, Vue } from "vue-class-component";
import AdjustmentModel from "@/model/adjustment";
import Container from "typedi";
import { ActiveAdjustmentSingleClass } from "@/data/adjustment";
import TextArea from "../../components/TextArea.vue";
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveProjectSingleClass } from "@/data/project";
import BudgetModel, {
  BudgetIndicatorModel,
  BudgetItemModel,
} from "@/model/budget";
import DocumentA from "@/document/pdf";
import { DocumentDocx } from "@/document/docx";
import { useStore } from "vuex";
import { count } from "console";
import { Watch } from "vue-property-decorator";
import UserServices from "@/service/user";
import { nextTick } from "vue";
import { confirmAction } from "@/decorator";

@Options({
  components: {},
})
export default class D1FormView extends Vue {
  disabled: boolean = false;
  dataSingle: ActiveAdjustmentSingleClass = Container.get(
    ActiveAdjustmentSingleClass
  );
  projectSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  store = useStore();
  stack: AdjustmentModel[] = [];
  addPlans: Array<Object> = [
    {
      name: "",
      plan: "",
      product: "",
      note: "",
    },
  ];

  public toDefault() {
    if (this.stack.length > 1) {
      this.dataSingle.data = this.stack[0];
      this.stack = [this.dataSingle.data];
    }
  }
  undoing = false;
  public undo() {
    this.undoing = true;
    if (this.stack.length > 1) {
      this.stack.pop();
      setTimeout(() => {
        this.dataSingle.data = Object.assign(
          {},
          this.stack[this.stack.length - 1]
        );
      }, 150);
    }
  }

  sortByNumber(arr: Array<any>) {
    let mapped = arr.map((a) => {
      let flt = 0;
      try {
        flt = parseFloat(a.number);
      } catch (error) {}
      a.n = flt;
      return a;
    });
    return mapped.sort((a, b) => a.n - b.n);
  }

  @Watch("dataSingle.data", { deep: true })
  checkActive() {
    if (!this.undoing) {
      let data = Object.assign({}, this.dataSingle.data!);
      let items = Object.assign([], this.dataSingle.data?.items);
      let budgets = Object.assign([], this.dataSingle.data?.budgets);
      data.items = items;
      data.budgets = budgets;
      this.stack.push(Object.assign({}, data));
    } else {
      this.undoing = false;
    }
    this.dataSingle.data!.budgets.forEach((el) => {
      if (el.level1_code != "") {
        let before = this.dataSingle
          .findBudgetFromFlatted(el, "before")
          .items.map((el: any) => el.amount)
          .reduce((a: any, b: any) => a + b, 0);
        let after = this.dataSingle
          .findBudgetFromFlatted(el, "after")
          .items.map((el: any) => el.amount)
          .reduce((a: any, b: any) => a + b, 0);
        console.log({ before });
        console.log({ after });

        if (before === after) {
          el.adjustType = "ไม่เปลี่ยนแปลง";
          el.description1 = "";
          el.description2 = "";
        } else if (before < after) {
          el.adjustType = "ปรับเพิ่มงบประมาณ";
          el.description2 = "";
        } else {
          el.adjustType = "ปรับลดงบประมาณ";
          el.description1 = "";
        }
      }
    });
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async delete21(item: any, i: number) {
    try {
      this.dataSingle.deleteAdjustmentItemsSingle(item.id!);
    } catch (error) {}
    this.dataSingle.data?.items.splice(i, 1);
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async delete22(item: any, i: number) {
    try {
      this.dataSingle.deleteAdjustmentBudgetSingle(item.id!);
    } catch (error) {}
    this.dataSingle.data?.budgets.splice(i, 1);
  }
  sumFromFlatted(arr: Array<any>) {
    //{{sumFromFlatted(dataSingle.findBudgetFromFlatted(item, 'after')!.items).formatComma()}}
    try {
      return arr.map((item) => item.amount).reduce((a, b) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }
async  getProject(){
 await this.projectSingle.getProjectSingle(
        this.$route.params.projectid as string
      );
  }
  mounted() {
    this.disabled = this.$route.path.search("/detail") != -1;
    // this.checkActive()
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new AdjustmentModel({
        project_id: this.$route.params.projectid,
        creatorName: this.store.state.fullName,
        creatorTelephone: this.store.state.telephone,
        creatorEmail: this.store.state.email,
      });
     this.getProject()
      try {
        this.dataSingle.data!.budgetYear = this.projectSingle.data!.budgetYear;
      } catch (error) {}
      try {
        this.dataSingle.data!.projectName =
          this.projectSingle.data!.code + "  " + this.projectSingle.data!.name;
      } catch (error) {}
      this.dataSingle.loading = false;
    }
    if (this.dataSingle.data?.addPlans != "") {
      try {
        this.addPlans = JSON.parse(this.dataSingle.data!.addPlans);
      } catch (error) {}
    }
  }
  getMonth(i: number) {
    switch (i) {
      case 0:
        return "ตุลาคม";
        break;
      case 1:
        return "พฤศจิกายน";
        break;
      case 2:
        return "ธันวาคม";
        break;
      case 3:
        return "มกราคม";
        break;
      case 4:
        return "กุมภาพันธ์";
        break;
      case 5:
        return "มีนาคม";
        break;
      case 6:
        return "เมษายน";
        break;
      case 7:
        return "พฤษภาคม";
        break;
      case 8:
        return "มิถุนายน";
        break;
      case 9:
        return "กรกฎาคม";
        break;
      case 10:
        return "สิงหาคม";
        break;
      case 11:
        return "กันยายน";
        break;
    }
  }
  get allowSave() {
    try {
      if (this.dataSingle.data!.budgets.length === 0) return true;
      else {
        let countSelected = this.dataSingle.data!.budgets.filter(
          (item) => item.adjustType !== undefined && item.adjustType !== ""
        ).length;
        let countAll = this.dataSingle.data!.budgets.length;
        if (countSelected === countAll) return true;
        else return false;
      }
    } catch (error) {
      return false;
    }
  }
  async initData() {
    await this.dataSingle.getAdjustmentSingle(this.$route.params.id as string);
    await this.projectSingle.getProjectSingle(
      this.dataSingle.data!.project_id as string
    );
  }
  async submitForm() {
    if (!this.allowSave) {
      alert("กรุณาเลือกปรับเพิ่ม/ปรับลดก่อน");
      return null;
    }
    this.dataSingle.data!.addPlans = JSON.stringify(this.addPlans);
    if (this.$route.params.id === undefined) {
      this.dataSingle.data!.isDraft = true;
      await this.dataSingle.createAdjustmentSingle();
      this.$router.push(
        `/portal/adjustment/request/${this.$route.params.projectid}`
      );
    } else {
      this.dataSingle.updateAdjustmentSingle();
    }
  }
  async updateBudget(budget: BudgetModel) {
    this.activeBudget.data! = new BudgetModel(budget);
    // console.log(this.activeBudget.data)
    await this.activeBudget.updateBudgetSingle();
  }
  async updateBudget21(item: BudgetModel, field: string, ev: InputEvent) {
    //@ts-ignore
    item[field] = ev.target.value;
    this.activeBudget.data = new BudgetModel(item);
    this.activeBudget.updateBudgetSinglePromise().then((val) => {
      this.dataSingle.updateAdjustmentSingleFromPromise().then((val) => {
        this.dataSingle.getAdjustmentSingleFromPromise(
          this.$route.params.id as string
        );
      });
    });
    //
  }
  // getTotalBefore(item:BudgetItemModel){
  //   let arr =  item
  //   console.log(arr)

  // }
  pushAddplan() {
    this.addPlans.push({
      name: "",
      plan: "",
      product: "",
      note: "",
    });
  }
  async generatePDF() {
    console.log("generatePDF");
    const documentA = new DocumentA();
    let sector: Array<any> = [];
    try {
      sector = this.projectSingle.data!.items!.find(
        (el: any) => el.code == "sectors"
      )!.value;
      for (const user of sector) {
        try {
          user.signature = await (
            await new UserServices().getUserSingle(user.user_id)
          ).signature;
          console.log("user.signature", user.signature);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
    documentA.createD1(this.dataSingle, this.projectSingle, sector);
    documentA.save(`reportD1.pdf`);
  }
  async createDocx() {
    const docx = new DocumentDocx();
    let sector: Array<any> = [];
    try {
      sector = this.projectSingle.data!.items!.find(
        (el: any) => el.code == "sectors"
      )!.value;
      for (const user of sector) {
        try {
          user.signature = await (
            await new UserServices().getUserSingle(user.user_id)
          ).signature;
          console.log("user.signature", user.signature);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    } 

    await docx.createD1(this.dataSingle, this.projectSingle, sector);
    docx.save("Report_D1.docx");
  }
}
