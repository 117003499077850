
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import ProjectModel from "@/model/project";
import PlanTable from "@/views/followup/components/PlanTable.vue";
import ModalFollowUp from "@/views/followup/components/ModalFollowUp.vue";
import { ActiveProjectSingleClass } from "@/data/project";
import Container from "typedi";
import TableTool from "../../components/TableTool.vue";
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveWithdrawSingleClass } from "@/data/withdraw";
import WithdrawModel, { WithdrawItemModel } from "@/model/withdraw";
import ModalITSand from "@/views/followup/components/ModalITSand.vue"
import BudgetServices from "@/service/budget";
import { confirmAction } from "@/decorator";

@Options({
  components: {
    PlanTable,
    ModalFollowUp,
    ModalITSand
  },
})
export default class BudgetPlanView extends Vue {
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  withdraw: ActiveWithdrawSingleClass = Container.get(
    ActiveWithdrawSingleClass
  );
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);

  get withdraws() {
    let results = [];
    try {
      for (const budget of this.dataSingle.data!.budgets) {
        results.push(...budget.withdraws);
        for (const child1 of budget!.children!) {
          results.push(...child1.withdraws);
          for (const child2 of child1!.children!) {
            results.push(...child2.withdraws);
          }
        }
      }
      return results;
    } catch (error) {
      return [];
    }
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteWithdraw(withdraw: WithdrawModel) {
    this.withdraw.data = withdraw;
    await this.withdraw.deleteWithdrawSingle();
    await this.project.getProjectSingle(this.$route.params.id as string);
  }
  openWithdrawModal() {
    let btn = this.$refs.modalWithdrawItemMoneyBtn as HTMLElement;
    btn.click();
  }
  async editWithdraw(withdraw: WithdrawModel) {
    let budgetData = await new BudgetServices().getBudgetSingle(
      withdraw.budget_id!
    );
    this.activeBudget.data = budgetData;
    console.log(withdraw);
    this.withdraw.data = withdraw;
    this.openWithdrawModal();
  }
  mounted() {
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new ProjectModel({});
    }
  }
  async initData() {
    this.dataSingle.data = undefined;
    await this.dataSingle.getProjectSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createProjectSingle();
    } else {
      this.dataSingle.updateProjectSingle();
    }
  }
  get totalBudget() {
    try {
      // let map = this.dataSingle.data?.budgets.map(budget=> budget)
      //let map = this.dataSingle.data?.budgets.map(budget=> budget.items.map(item=>item.amount).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b,0)
      let map = this.dataSingle.data?.budgets
        .map((budget) =>
          budget.items.map((item) => item.amount).reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0);
      return map;
    } catch (error) {
      return 0;
    }
  }
}
