
import { Options, Vue } from "vue-class-component";
import ProjectModel, { ProjectItemModel } from "@/model/project";
import {
  ActiveProjectListClass,
  ActiveProjectSingleClass,
} from "@/data/project";
import Container from "typedi";
import { ActiveSectorListClass } from "@/data/sector";
import { ActiveBudgetYearClass } from "@/data/system";
import { ActiveWithdrawListClass } from "@/data/withdraw";
import TextField from "../../components/TextField.vue";
import ADDRESS from "@/setting";
import axios from "axios";

@Options({
  components: {},
})
export default class ERPOutBoundView extends Vue {
 docs:any=[]
  mounted() {
    this.fetchData();
   
  }
  async fetchData() {
   
    let query={}
    let response = await axios.post(`${ADDRESS}/withdraws-inboundK2/paginate`, query);
    console.log(response.data)
    

    this.docs = response.data.docs
    }
    
  
 
}
