
import { Options, Vue } from "vue-class-component";
import DocumentModel,{DocumentAttachmentModel} from "@/model/document";
import ADDRESS from "@/setting"
import {
  ActiveDocumentListClass,
  ActiveDocumentSingleClass,
  DocumentAttachmentSingleClass
} from "@/data/document";
import Container from "typedi";
import { confirmAction } from "@/decorator";

@Options({
  components: {},
})
export default class PositionFormView extends Vue {
  ADDRESS = ADDRESS
  dataSingle: ActiveDocumentSingleClass = Container.get(
    ActiveDocumentSingleClass
  );
  mounted() {
    if (this.$route.params.id !== undefined) this.initData();
    else { 
      this.dataSingle.data = new DocumentModel({})
    }
  }
  // async deleteItem(id:string,index:number) {
  //   if(id != undefined){
  //   // await this.dataSingle.deleteFileItemSingle(this.$route.params.id as string);
  //   this.initData()
  //   }
  //   else{
  //        this.dataSingle.data!.attachments.splice(index,1)
  //   }
  // }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
   async deleteAttachmentItem(index:number,item:DocumentAttachmentModel) {
    if(item.id != undefined) {
      let attachment: DocumentAttachmentSingleClass = Container.get(
        DocumentAttachmentSingleClass
      );
      attachment!.data = item
      await attachment.deleteAttachmentSingle()
      this.$nextTick(()=>{

      this.initData()
      })
    }else{
      try {
        this.dataSingle.data?.attachments.splice(index,1)
        this.$nextTick(()=>{

        this.initData()
        })
      } catch (error) {
        console.log(error);
        
      }
    }
  
  }
  async initData() {
    await this.dataSingle.getDocumentSingle(this.$route.params.id as string);
  }
  async submitForm() {
  
    if (this.$route.params.id === undefined) {
      
      await this.dataSingle.createDocumentSingle();
    } else {
      this.dataSingle.updateDocumentSingle();
    }
    this.$router.push('/portal/pr/document');
  }
}
