import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import PositionModel from "@/model/position";
import PositionServices from "@/service/position";
import { Service } from 'typedi';

@Service()
export class ActivePositionSingleClass {
  data?: PositionModel;
  loading: boolean = true;
  async getPositionSingle(id: string) {
    this.loading = true
    this.data = await new PositionServices().getPositionSingle(id)
    this.loading = false
  }
  async updatePositionSingle() {
    this.loading = true
    this.data = await new PositionServices().updatePosition(this.data!.id!, this.data!)
    this.loading = false
  }
  async createPositionSingle() {
    this.loading = true
    this.data = await new PositionServices().createPosition(this.data!)
    this.loading = false
  }
}

@Service()
export class ActivePositionListClass {
  data?: PaginateResponse<PositionModel>;
  loading: boolean = true;
  query:any = {}
  async getPositionList(query: any) {
    this.loading = true
    this.data = await new PositionServices().getPositionList(query)
    this.loading = false
  }
  async refreshPositionList() {
    this.loading = true
    this.data = await new PositionServices().getPositionList(this.query)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deletePositionFromList(id:string) {
    this.loading = true
    let response = await new PositionServices().deletePosition(id)
    this.refreshPositionList()
    this.loading = false
  }
}