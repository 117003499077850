
import { Options, Vue } from "vue-class-component";
import KpiReviewModel,{ReviewResultModel,EvidenceModel} from "@/model/kpiReview";
import { ActiveReviewIndiciatorSingleClass,} from "@/data/kpiReview";
import { ActiveIndiciatorSingleClass,} from "@/data/indicator";
import Container from "typedi";
import ADDRESS from "@/setting";
import JMAutoPerson from "../../../components/JMAutoPerson.vue";
import UserModel from "@/model/user";


@Options({
  components: {},
})
export default class IndiciatorFormView extends Vue {
  ADDRESS = ADDRESS
  ReviewResultModel = ReviewResultModel
  EvidenceModel = EvidenceModel
  dataSingle: ActiveReviewIndiciatorSingleClass = Container.get(
    ActiveReviewIndiciatorSingleClass
  );
   dataIndicator: ActiveIndiciatorSingleClass = Container.get(
    ActiveIndiciatorSingleClass
  );
  disabled:boolean = false
  pages: number = 0;
  total: number = 0;

  updateFromAutoPerson(user:UserModel,item:any){
    this.dataSingle.data!.reportName = user.fullName
    this.dataSingle.data!.reportPosition = user.position
  }
  get results() {
    try {
      return this.dataSingle.data!.results;
    } catch (error) {
      return [];
    }
  }
   get evidences() {
    try {
      return this.dataSingle.data!.evidences;
    } catch (error) {
      return [];
    }
  }
  mounted() {
    this.disabled = this.$route.path.search('/detail')!=-1
    this.dataIndicator.getIndiciatorSingle(this.$route.params.indicatorid as string);
    if (this.$route.params.id !== undefined) this.initData();
     else { 
      this.dataSingle.data = new KpiReviewModel({})
    }
      this.initData();
  }
// async deleteEvidence(id: string) {
//     await this.dataSingle.deleteIndicatorReviewEvidence(id);
//   }
  async initData() {
    await this.dataSingle.getReviewIndiciatorSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if(this.$route.params.id ==undefined){
        this.dataSingle.data!.indicator_id = this.$route.params.indicatorid as string
        await this.dataSingle.createReviewIndiciatorSingle();
    }
    else{
      this.dataSingle.updateReviewIndiciatorSingle();
    }
      
  }
}
