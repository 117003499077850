
import { Options, Vue } from "vue-class-component";
import {IndicatorMetricModel,IndicatorResponsiblePersonModel} from "@/model/indicator";
import { ActiveIndiciatorSingleClass,} from "@/data/indicator";
import Container from "typedi";
import { DocumentDocx } from "@/document/docx/B";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class ReportB01View extends Vue {
  IndicatorMetricModel = IndicatorMetricModel
  IndicatorResponsiblePersonModel = IndicatorResponsiblePersonModel
  dataSingle: ActiveIndiciatorSingleClass = Container.get(
    ActiveIndiciatorSingleClass
  );
  store = useStore();
  mounted() {
    this.initData();
  }
  async initData() {
    await this.dataSingle.getIndiciatorSingle(this.$route.params.id as string);
  }
  get metric() {
    try {
      return this.dataSingle.data!.metric;
    } catch (error) {
      return [];
    }
  }
  get responsibles() {
    try {
      return this.dataSingle.data!.responsibles;
    } catch (error) {
      return [];
    }
  }
  async createDocx() {
    const docx = new DocumentDocx();
    await docx.reportB01(this.dataSingle.data);
    docx.save("Report_B01.docx");
  }
}
