import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import PublicrelationModel, { PublicRelationAttachmentModel } from "@/model/publicrelation";
import PublicrelationServices, { PublicrelationAttachmentServices } from "@/service/publicrelation";
import { Service } from 'typedi';
import { useToast,POSITION } from "vue-toastification";

@Service()
export class ActivePublicrelationSingleClass {
  data?: PublicrelationModel;
  loading: boolean = true;
  async getPublicrelationSingle(id: string) {
    this.loading = true
    this.data = await new PublicrelationServices().getPublicrelationSingle(id)
    this.loading = false
  }
  async getflashnewsSingle() {
    this.loading = true
    this.data = await new PublicrelationServices().getFlashNew()
    this.loading = false
  }
  async updatePublicrelationSingle() {
    this.loading = true
    this.data = await new PublicrelationServices().updatePublicrelation(this.data!.id!, this.data!)
    this.loading = false
  }
  async createPublicrelationSingle() {
    this.loading = true
    this.data = await new PublicrelationServices().createPublicrelation(this.data!)
    this.loading = false
  }



}


@Service()
export class PublicRelationAttachmentSingleClass {
  data?: PublicRelationAttachmentModel;
  loading: boolean = true;
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteImageSingle() {
    this.loading = true
    this.data = await new PublicrelationAttachmentServices().deleteImageSingle(this.data!.id!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAttachmentSingle() {
    this.loading = true
    this.data = await new PublicrelationAttachmentServices().deleteAttachmentSingle(this.data!.id!)
    this.loading = false
  }
}


@Service()
export class ActivePublicrelationListClass {
  data?: PaginateResponse<PublicrelationModel>;
  loading: boolean = true;
  async getPublicrelationList(query: any) {
    this.loading = true
    this.data = await new PublicrelationServices().getPublicrelationList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }

  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deletePublicrelationSingle(id: string) {
  this.loading = true
   let response = await new PublicrelationServices().deletePublicrelation(id)
   const toast = useToast();
   toast.warning("ลบข้อมูลสำเร็จ (ref:S-U1)", {
     timeout: 2000,
     position: POSITION.BOTTOM_RIGHT,
   });
   this.getPublicrelationList({sort: {
    createdAt: "desc",
  }});
    this.loading = false

  }
}