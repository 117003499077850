
import { Options, Vue } from "vue-class-component";
import PlanTable from "@/views/overlap/components/PlanTable.vue";
import Container from "typedi";
import { ActiveBudgetListClass, ActiveBudgetSingleClass } from "@/data/budget";
import { useStore } from "vuex";
import { ActiveBudgetYearClass } from "@/data/system";
import * as _ from "lodash";
import { DocumentExcel } from "@/document/xlsx";

@Options({
  components: {
    PlanTable,
  },
})
export default class BudgetPlanView extends Vue {
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  dataList: ActiveBudgetListClass = Container.get(ActiveBudgetListClass);
  store = useStore();

  mounted() {
    this.fetchData();
  }
  get grandTotal() {
    try {
      let filtered = this.dataList.data!.docs
      let mapped = filtered.map(
        (item) =>
          (item.overlapBudgetAmount ?? 0) + (item.overlapRemainingAmount ?? 0)
      );
      let reduced = mapped.reduce((a, b) => a + b, 0);
      return {
        count: filtered.length,
        amount: reduced,
      };
    } catch (error) {}
    return {
      count: 0,
      amount: 0,
    };
  }
  get uniqueBudgetTypeCategory() {
    try {
      let results = _.uniq(
        this.dataList.data!.docs.map((item) => item.budgetTypeCategory)
      );
      results = results.sort((a, b) => a.localeCompare(b));
      results.reverse();
      return results;
    } catch (error) {
      return [];
    }
  }
  get uniqueBudgetTypeName() {
    try {
      let results = _.uniq(
        this.dataList.data!.docs.map((item) => item.budgetTypeName)
      );
      results = results.sort((a, b) => a.localeCompare(b));
      results.reverse();
      return results;
    } catch (error) {
      return [];
    }
  }

  getByName(name: string) {
    try {
      let filtered = this.dataList.data!.docs.filter(
        (item) => item.budgetTypeName === name
      );
      let mapped = filtered.map(
        (item) =>
          (item.overlapBudgetAmount ?? 0) + (item.overlapRemainingAmount ?? 0)
      );
      let reduced = mapped.reduce((a, b) => a + b, 0);
      return {
        count: filtered.length,
        amount: reduced,
      };
    } catch (error) {}
    return {
      count: 0,
      amount: 0,
    };
  }

  getByNameCategory(name: string, category: string) {
    try {
      let filtered = this.dataList.data!.docs.filter(
        (item) =>
          item.budgetTypeName === name && item.budgetTypeCategory === category
      );
      let mapped = filtered.map(
        (item) =>
          (item.overlapBudgetAmount ?? 0) + (item.overlapRemainingAmount ?? 0)
      );
      let reduced = mapped.reduce((a, b) => a + b, 0);
      return {
        count: filtered.length,
        amount: reduced,
      };
    } catch (error) {}
    return {
      count: 0,
      amount: 0,
    };
  }

  getByNameCategoryOverlap(name: string, category: string, overlap: string) {
    try {
      let filtered = this.dataList.data!.docs.filter(
        (item) =>
          item.budgetTypeName === name &&
          item.budgetTypeCategory === category &&
          item.overlapType === overlap
      );
      let mapped = filtered.map(
        (item) =>
          (item.overlapBudgetAmount ?? 0) + (item.overlapRemainingAmount ?? 0)
      );
      let reduced = mapped.reduce((a, b) => a + b, 0);
      return {
        count: filtered.length,
        amount: reduced,
      };
    } catch (error) {}
    return {
      count: 0,
      amount: 0,
    };
  }
  async fetchData() {
    let budgetYear = parseInt(this.$route.params.budgetYear as string);
    await this.dataList.getBudgetList({
      search: {
        isOverlap: true,
        project: {
          budgetYear,
        },
      },
    });
    console.log("333", this.dataList.data!);
  }
  exportExcel(){
    let budgetYear = parseInt(this.$route.params.budgetYear as string);
    let docExcel = new DocumentExcel();
    docExcel.createOverlapForm(this.dataList.data?.docs!,budgetYear);
  }
}
