
import { Options, Vue } from "vue-class-component";
import Container from "typedi";
import { ActiveProjectSingleClass } from "@/data/project";
import { ActiveIndiciatorListClass } from "@/data/indicator";
@Options({
  props: {
    modelValue: Array,
    label: String,
    disabled:Boolean,
   
  },
})
export default class PlanTableComponent extends Vue {
  indicators: ActiveIndiciatorListClass = Container.get(
    ActiveIndiciatorListClass
  );
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);
  label!: string;
  budgetYear = 2566
  
  modelValue!: Array<string>;
  val: Array<string> = [];
  disabled!:boolean
  pushItem() {
    if (typeof this.val === "string") this.val = [];
    else if (!Array.isArray(this.val)) this.val = [];
    this.val.push("");
    this.emitUp();
  }
  deleteItem(i: number) {
    let c = confirm("ยืนยันลบรายการหรือไม่?");
    if (c) {
      this.val.splice(i, 1);
      this.emitUp();
    }
  }
  mounted() {
    this.val = this.modelValue ?? [];
    this.initData();
  }
  initData() {
    this.budgetYear = this.project.data!.budgetYear
    this.indicators.getIndiciatorList({
      search: {
        budgetYear: this.budgetYear,
      },
    });
  }
  emitUp() {
    this.$emit("update:modelValue", this.val);
  }
}
