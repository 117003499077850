
import { Options, Vue } from "vue-class-component";
import CabinetTargetModel from "@/model/cabinetTarget";
import {
  ActiveCabinetTargetListClass,
  ActiveCabinetTargetSingleClass,
} from "@/data/cabinetTarget";
import Container from "typedi";
import { ActiveBudgetYearClass } from "@/data/system";

@Options({
  components: {},
})
export default class CabinetTargetFormView extends Vue {
  disabled:boolean = false
  dataSingle: ActiveCabinetTargetSingleClass = Container.get(
    ActiveCabinetTargetSingleClass
  );
  dataList: ActiveCabinetTargetListClass = Container.get(
    ActiveCabinetTargetListClass
  );
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  mounted() {
    this.yearSetting.getBudgetYearSetting();
    this.disabled = this.$route.path.search('/detail')!=-1
    this.dataList.getCabinetTargetList({})
    if (this.$route.params.id !== undefined) this.initData();
    else { 
      this.dataSingle.data = new CabinetTargetModel({})
    }
  }
  async initData() {
    await this.dataSingle.getCabinetTargetSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createCabinetTargetSingle();
    } else {
      this.dataSingle.updateCabinetTargetSingle(this.$route.params.id as string);
    }
  }
}
