export default class SettingModel {
  id?: string;
  name: string;
  type: string;
  member: Array<SettingMemberModel>
  image:string;
  constructor(jsonData: any) {
    let member:Array<SettingMemberModel> = []
    try {
      member = jsonData.member.map((m:any)=>new SettingMemberModel(m))
    } catch (error) {
      
    }
    this.id = jsonData.id;
    this.name = jsonData.name ?? "";
    this.type = jsonData.type ?? "";
    this.member = member;
    this.image = jsonData.image?? "";
  }
  toJSON() {
    let obj = Object.assign({}, this)
    
    obj.member.forEach(o=>{
      try {
        console.log({o})
      } catch (error) {
        console.log(error)
      }
    })
    console.log("member", obj.member)
    // obj.member = obj.member.map(o=>o.toJSON())
    return obj
  }
}

export class SettingMemberModel {
  id?: string;
  name: string
  order: number
  type: string
  text1: string
  text2: string
  text3: string
  text4: string
  text5: string
  text6: string
  int1: number
  int2: number
  int3: number
  int4: number
  int5: number
  int6: number
  float1: number
  float2: number
  float3: number
  float4: number
  float5: number
  float6: number

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name ?? "";
    this.order = jsonData.order ?? 0;
    this.type = jsonData.type ?? ""
    this.text1 = jsonData.text1 ?? ""
    this.text2 = jsonData.text2 ?? ""
    this.text3 = jsonData.text3 ?? ""
    this.text4 = jsonData.text4 ?? ""
    this.text5 = jsonData.text5 ?? ""
    this.text6 = jsonData.text6 ?? ""
    this.int1 = jsonData.int1 ?? 0
    this.int2 = jsonData.int2 ?? 0
    this.int3 = jsonData.int3 ?? 0
    this.int4 = jsonData.int4 ?? 0
    this.int5 = jsonData.int5 ?? 0
    this.int6 = jsonData.int6 ?? 0
    this.float1 = jsonData.float1 ?? 0
    this.float2 = jsonData.float2 ?? 0
    this.float3 = jsonData.float3 ?? 0
    this.float4 = jsonData.float4 ?? 0
    this.float5 = jsonData.float5 ?? 0
    this.float6 = jsonData.float6 ?? 0
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    
    return obj
  }
}