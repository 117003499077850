export  default class AccountModel {
  id?: string;
  code: string;
  codeName: string;
  unit: string;
  accountCode: string;
  createdAt: Date;
  modifiedAt: Date;
  createdIP: string;
  modifiedIP: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.code = jsonData.code;
    this.codeName = jsonData.codeName;
    this.unit = jsonData.unit;
    this.accountCode = jsonData.accountCode;
    this.createdAt = jsonData.createdAt;
    this.modifiedAt = jsonData.modifiedAt;
    this.createdIP = jsonData.createdIP;
    this.modifiedIP = jsonData.modifiedIP;
  }
  toJSON(){
    let obj = Object.assign({},this)
    
    return obj
  }
}