
import { Options, Vue } from "vue-class-component";
import SectorModel from "@/model/sector";
import {
  ActiveSectorListClass,
  ActiveSectorSingleClass,
} from "@/data/sector";
import Container from "typedi";

@Options({
  components: {},
})
export default class SectorFormView extends Vue {
  disabled:boolean = false
  dataSingle: ActiveSectorSingleClass = Container.get(
    ActiveSectorSingleClass
  );
  dataList: ActiveSectorListClass = Container.get(
    ActiveSectorListClass
  );
  mounted() {
    this.disabled = this.$route.path.search('/detail')!=-1
    this.dataList.getSectorList({})
    if (this.$route.params.id !== undefined) this.initData();
    else { 
      this.dataSingle.data = new SectorModel({})
    }
  }
  async initData() {
    await this.dataSingle.getSectorSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createSectorSingle();
    } else {
      this.dataSingle.updateSectorSingle(this.$route.params.id as string);
    }
  }
}
