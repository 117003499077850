
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import SystemModel from "@/model/system";
import {
  ActiveProjectListClass,
  ActiveProjectSingleClass,
} from "@/data/project";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import ProjectModel from "@/model/project";
import { ActiveSectorListClass } from "@/data/sector";
import { ActiveBudgetYearClass } from "@/data/system";
import AdjustmentServices from "@/service/adjustment";
import { useStore } from "vuex";
import { ActiveAdjustmentListClass } from "@/data/adjustment";

@Options({
  components: {},
})
export default class ProjectListView extends Vue {
  store = useStore()
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  adjustmentServices = new AdjustmentServices();
  dataList: ActiveProjectListClass = Container.get(ActiveProjectListClass);
  docs: Array<ProjectModel> = [];
  pages: number = 0;
  total: number = 0;
  sector_id = "";
  search = "";
  budgetYear = new Date().getFullYear() + 543;
  checked: Array<any> = [];
  adjustmentList: ActiveAdjustmentListClass = Container.get(
    ActiveAdjustmentListClass
  );

  isApproval(item: ProjectModel) {
    let userId = "f49a7132-f32b-4637-9e56-662973987464"
    let array:Array<string> = [
      // item.approver1_id,
      // item.approver2_id,
      // item.approver3_id,
      // item.approver4_id,
      // item.approver5_id,
      // item.approver6_id,
    ];
    let lastAdjustmentStep = 0
    try {
      lastAdjustmentStep = this.lastAdjustment(item).step as number
    } catch (error) {
      
    }
    if(lastAdjustmentStep == 1) array.push(item.approver1_id!)
    if(lastAdjustmentStep == 2) array.push(item.approver2_id!)
    if(lastAdjustmentStep == 3) array.push(item.approver3_id!)
    if(lastAdjustmentStep == 4) array.push(item.approver4_id!)
    if(lastAdjustmentStep == 5) array.push(item.approver5_id!)
    if(lastAdjustmentStep == 6) array.push(item.approver6_id!)
    let result = array.includes(userId)
    console.log({code:item.code, lastAdjustmentStep, array, result, userId:userId})
    return result;
  }
  lastAdjustment(item: ProjectModel) {
    try {
      if(item.adjustment[0]!=undefined)
      return item.adjustment[0]
      else return {step:""}
    } catch (error) {
      return {step:""}
    }
  }
  lastStatus(item: ProjectModel){
    let step = 0
    try {
      step = this.lastAdjustment(item)!.step as number
    } catch (error) {
      
    }
    if(step>=4&&step<=7) {
      return "อยู่ระหว่างการปรับแผน"
    } else {
      return "-"
    }
  }

  getStatus(id: any) {
    this.adjustmentList.getAdjustmentList(id);
  }
  checkedAll(ev: any) {
    if (ev.target.checked)
      this.checked = this.dataList.data!.docs.map((item) => ({
        project_id: item.id,
      }));
    else this.checked = [];
    console.log(ev.target.checked);
  }
  get roles() {
    try {
      if (typeof this.store.state.roles === "string")
        return JSON.parse(this.store.state.roles);
      else return this.store.state.roles[1];
    } catch (error) {
      return [];
    }
  }
  mounted() {
    this.yearSetting.getBudgetYearSetting();
    this.sectorList.getSectorList({ search: { category: "ฝ่าย/สำนัก" } });
    this.fetchData();
  }
  async fetchData() {
    let query: any = {
      search: {
        step: 4,
      },
      sort:[
        { code: "asc" },
      ]
    };
    query.search.budgetYear = this.budgetYear;
    if (this.sector_id != "") query.search.sector_id = this.sector_id;
    if (this.search != "") {
      query.search = {
        ...query.search,
        OR: [
          { name: { contains: this.search } },
          { code: { contains: this.search } },
        ],
        step: 4,
      };
    }
    await this.dataList.getProjectList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
