import ADDRESS from "@/setting";
import axios from "axios"
import PublicrelationModel, { PublicRelationAttachmentModel } from "@/model/publicrelation";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class PublicrelationServices {
  public getPublicrelationList(query: any) {
    return new Promise<PaginateResponse<PublicrelationModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/publicrelations/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<PublicrelationModel> = response.data.docs.map((el: any) => new PublicrelationModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<PublicrelationModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }
  public getFlashNew() {
    return new Promise<PublicrelationModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/publicrelation/get-lastest-flashnews`);
      let doc: PublicrelationModel = new PublicrelationModel(response.data.doc)
      resolve(doc)
    })
  }
  public getPublicrelationSingle(id: string) {
    return new Promise<PublicrelationModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/publicrelation/get/${id}`);
      let doc: PublicrelationModel = new PublicrelationModel(response.data.doc)
      resolve(doc)
    })
  }

  public updatePublicrelation(id: string, Publicrelation: PublicrelationModel) {
    return new Promise<PublicrelationModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/publicrelation/update/${id}`, Publicrelation.toJSON());
      let doc: PublicrelationModel = new PublicrelationModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createPublicrelation(Publicrelation: PublicrelationModel) {
    console.log(Publicrelation)
    return new Promise<PublicrelationModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/publicrelation/create/`, Publicrelation.toJSON());
      let doc: PublicrelationModel = new PublicrelationModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
     resolve(doc)
    })
  }
  public deletePublicrelation(id: string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/publicrelation/delete/${id}`);
      resolve("ok")
    })

  }
}

export class PublicrelationAttachmentServices{
  public deleteImageSingle(id: string) {
    return new Promise<PublicRelationAttachmentModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/publicrelation/delete-image/${id}`);
      console.log('id',id);
      
      console.log('response',response);
      
      let doc: PublicRelationAttachmentModel = new PublicRelationAttachmentModel(response.data.doc)
      const toast = useToast();
      toast.success("ลบภาพแนบแล้ว", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public deleteAttachmentSingle(id: string) {
    return new Promise<PublicRelationAttachmentModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/publicrelation/delete-attachment/${id}`);
      let doc: PublicRelationAttachmentModel = new PublicRelationAttachmentModel(response.data.doc)
      const toast = useToast();
      toast.success("ลบไฟล์แนบแล้ว", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}