import { PaginateResponse } from "@/common";
import PetitionModel from "@/model/petition";
import PetitionServices from "@/service/petition";
import { Service } from 'typedi';

@Service()
export class ActivePetitionSingleClass {
  data?: PetitionModel;
  previous?: PetitionModel;
  loading: boolean = true;
  loadingPrevious: boolean = true;
  async getPetitionSingle(id: string) {
    console.log("getting petition data")
    this.loading = true
    this.data = await new PetitionServices().getPetitionSingle(id)
    this.loading = false
  }
  async getPetitionPrevious(project_id: string, quarter: number) {
    this.loadingPrevious = true
    this.previous = await new PetitionServices().getPetitionPrevious(project_id, quarter)
    this.loadingPrevious = false
  }
  async updatePetitionSingle() {
    this.loading = true
    this.data = await new PetitionServices().updatePetition(this.data!.id!, this.data!)
    this.loading = false
  }
  async createPetitionSingle() {
    this.loading = true
    this.data = await new PetitionServices().createPetition(this.data!)
    this.loading = false
  }
}

@Service()
export class ActivePetitionListClass {
  data?: PaginateResponse<PetitionModel>;
  loading: boolean = true;
  async getPetitionList(query: any) {
    this.loading = true
    this.data = await new PetitionServices().getPetitionList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
}