
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import BudgetModel from "@/model/budget";
import { ActiveProjectSingleClass } from "@/data/project";
import Container from "typedi";
@Options({
  components: {},
  props: {
    step: Number,
    cleanStep: Number,
    stepPercent: Number,
  },
})
export default class ApprovalProgress extends Vue {
  step!: number;
  cleanStep!: number;
  stepPercent!: number;
  callouts = [
    "C7(รับผิดชอบ)",
    "C8(รับผิดชอบ)",
    "C9(รับผิดชอบ)",
    "C7(แผน)",
    "C8(แผน)",
    "C9(แผน)",
    "C10(รองผู้ว่าฯ)",
    "C11(ผู้ว่าฯ)",
  ];
}
