import ADDRESS from "@/setting";
import axios from "axios"
import PreparationModel from "@/model/preparation";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class PreparationServices {

  public createPreparation(query: any) {
    return new Promise<any>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/process/copy/`, query);
      console.log('query', query);
      console.log('response', response);
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("")
    })
  }
}
