
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import CabinetTargetModel from "@/model/cabinetTarget";
import { ActiveCabinetTargetListClass } from "@/data/cabinetTarget";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import ADDRESS from "@/setting"
import Container from "typedi";
import axios from "axios";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class CabinetTargetFormView extends Vue {
  dataList: ActiveCabinetTargetListClass = Container.get(ActiveCabinetTargetListClass);
  docs: Array<CabinetTargetModel> = [];
  pages: number = 0;
  total: number = 0;
  tree: Array<any> = []
  store = useStore();

  renderTableTool(item: CabinetTargetModel) {
    let result:Array<string> = []
    if(this.store.getters.isAdmin||this.store.getters.hasRight("setting_cabinetTarget_editall")) result.push("Edit")
    if(this.store.getters.isAdmin||this.store.getters.hasRight("setting_cabinetTarget_delete")) result.push("Delete")
    return result;
  }
  mounted() {
    this.fetchData();
  }
  async fetchData() {
    let query: any = {
      sort: {
        budgetYear:"desc"
      }
    };
    await this.dataList.getCabinetTargetList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }

}
