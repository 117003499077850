
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveFollowUpSingleClass } from "@/data/followup";
import Container from "typedi";
import BudgetModel from "@/model/budget";
import FollowUpModel from "@/model/followup";
import { ActiveProjectSingleClass } from "@/data/project";
import { Watch } from "vue-property-decorator";
import JMCalendar from "../../../components/JMCalendar.vue";
import { ActiveWithdrawSingleClass } from "@/data/withdraw";

@Options({
  components: {},
})
export default class ModalITSand extends Vue {
}
