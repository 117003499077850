
import { Options, Vue } from "vue-class-component";
import SystemModel, { SettingMemberModel } from "@/model/system";
import { ActiveSystemListClass, ActiveSystemSingleClass } from "@/data/system";
import Container from "typedi";
import draggable from "vuedraggable";
import UserModel from "@/model/user";
import ADDRESS from "@/setting"

@Options({
  components: {
    draggable
  },
})
export default class SystemFormView extends Vue {
  ADDRESS = ADDRESS
  SettingMemberModel = SettingMemberModel
  dataSingle: ActiveSystemSingleClass = Container.get(ActiveSystemSingleClass);
  types = [
    { value: "pagerender", name: "การแสดงผลหน้าจอ" },
    { value: "master,options", name: "มาสเตอร์ (ตัวเลือก)" },
    { value: "master,ERP", name: "มาสเตอร์ (ตั้งเบิก ERP)" },
    { value: "master,plan", name: "มาสเตอร์ (plan)" },
    { value: "adjustment", name: "ผู้อนุมัติการปรับแผน" },
    { value: "others", name: "อื่นๆ" },
  ];
  pagerenderOptions = [
    { value: "inputText", name: "ช่องกรอกแบบตัวหนังสือ" },
    { value: "inputNumber", name: "ช่องกรอกแบบตัวเลข" },
    { value: "textarray", name: "ช่องกรอกตัวหนังสือแบบตาราง" },
    { value: "ResponsibleArray", name: "ช่องกรอกตัวหนังสือแบบตารางสูงสุด 3 รายการ" },
    { value: "textarea", name: "ช่องกรอกตัวหนังสือแบบหลายบรรทัด" },
    { value: "richtext", name: "ช่องกรอกตัวหนังสือแบบ RichText" },
    { value: "attachment", name: "ช่องแนบไฟล์" },
    { value: "link-strategic", name: "ตัวเลือกเชื่อมต่อไปยังตัวชี้วัด-ยุทธศาสตร์" },
    { value: "link-tactic", name: "ตัวเลือกเชื่อมต่อไปยังตัวชี้วัด-กลยุทธ์" },
    { value: "link-plan", name: "ตัวเลือกเชื่อมต่อไปยังตัวชี้วัด-แผนงาน" },
    { value: "link-indicator", name: "ตัวเลือกเชื่อมต่อไปยังตัวชี้วัด-ตัวชี้วัด" },
    { value: "plan", name: "แผนการใช้งบประมาณ" },
    { value: "sector1", name: "ตัวเลือกฝ่าย/กอง/งาน" },
    { value: "sector2", name: "ตัวเลือกผู้ประสานงาน" },
    { value: "sector3", name: "ตัวเลือกผู้รับผิดชอบโครงการ" },
    { value: "responsible", name: "ตัวเลือกผู้รับผิดชอบ" },
  ]
  get member() {
    try {
      return this.dataSingle.data!.member;
    } catch (error) {
      return [];
    }
  }
  updateFromAutoPerson(user:UserModel,index:number){
    console.log("user", user.id)
    let item = this.dataSingle.data!.member[index]
    item.name = user.fullName
    this.dataSingle.data!.member[index].text1 = user.fullName
    this.dataSingle.data!.member[index].text2 = user.id!
  }
  mounted() {
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new SystemModel({});
    }
  }
  async initData() {
    this.dataSingle.data = undefined;
    await this.dataSingle.getSystemSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createSystemSingle();
    } else {
      this.dataSingle.updateSystemSingle();
    }
  }
  emitUp() {
    this.$emit("update:modelValue", this.member);
  }
}
