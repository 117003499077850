import ADDRESS from "@/setting";
import axios from "axios"
import Model,{SettingMemberModel} from "@/model/system";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class SystemServices {
  public getSystemList(query: any) {
    return new Promise<PaginateResponse<Model>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/settings/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<Model> = response.data.docs.map((el: any) => new Model(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<Model>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getSystemSingle(id: string) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/setting/get/${id}`);
      let doc: Model = new Model(response.data.doc)
      resolve(doc)
    })
  }

  public getSystemByName(name: string) {
    return new Promise<Array<SettingMemberModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/setting/get-by-name/${name}`,{name:name});
      let member = []
      try {
        member = response.data.doc.member
      } catch (error) {
        
      }
      let docs: Array<SettingMemberModel> = member.map((element: any) => {
        return new SettingMemberModel(element)
      });
      resolve(docs)
    })
  }

  public deleteSystemSingle(id: string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/setting/delete/${id}`);
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }
  public deleteSystemMemberSingle(id: string) {
    return new Promise<string>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/setting/delete-member/${id}`);
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve("done")
    })
  }

  public updateSystem(id: string, System: Model) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/setting/update/${id}`, System.toJSON());
      let doc: Model = new Model(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createSystem(System: Model) {
    return new Promise<Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/setting/create/`, System);
      console.log('res',response);
      
      let doc: Model = new Model(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
