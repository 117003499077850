
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import UACModel from "@/model/uac";
import { ActiveUserListClass } from "@/data/user";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import UACFormView from "./UACFormView.vue";
import { ActiveUserSingleClass } from "@/data/user";
import UserModel from "@/model/user";
import { useStore } from "vuex";
import { ActiveSectorListClass } from "@/data/sector";
import { result } from "lodash";

@Options({
  components: {
    UACFormView
  },
})
export default class UACListView extends Vue {
  store = useStore()
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  dataList: ActiveUserListClass = Container.get(ActiveUserListClass);
  dataSingle: ActiveUserSingleClass = Container.get(ActiveUserSingleClass);
  docs: Array<UserModel> = [];
  pages: number = 0;
  total: number = 0;
  show: number = 20;
  page: number = 1;
  search: string = "";
  userId:string = ""

  filter_sector1_id = ""
  filter_sector2_id = ""
  filter_sector3_id = ""
  filter_sector4_id = ""
  filter_sector5_id = ""
  renderSectorName(sector:any){
    try {
      return sector.name
    } catch (error) {
      return "-"
    }
  }
  get sector_01(){
    return this.sectorList.data?.docs.filter(doc=>doc.category === "ผู้ว่าการกีฬาแห่งประเทศไทย")
  }
  get sector_02(){
    let results =  this.sectorList.data?.docs.filter(doc=>doc.category === "รองผู้ว่าการกีฬาแห่งประเทศไทย")
    if(this.filter_sector1_id !== "") results = results?.filter(doc=>doc.parent_id === this.filter_sector1_id)
    return results
  }
  get sector_03(){
    let results =  this.sectorList.data?.docs.filter(doc=>doc.category === "ฝ่าย/สำนัก"||doc.category === "สำนัก"||doc.category === "ฝ่าย")
    if(this.filter_sector2_id !== "") results = results?.filter(doc=>doc.parent_id === this.filter_sector2_id)
    return results
  }
  get sector_04(){
    let results =  this.sectorList.data?.docs.filter(doc=>doc.category === "กอง")
    if(this.filter_sector3_id !== "") results = results?.filter(doc=>doc.parent_id === this.filter_sector3_id)
    return results
  }
  get sector_05(){
    let results =  this.sectorList.data?.docs.filter(doc=>doc.category === "งาน")
    if(this.filter_sector4_id !== "") results = results?.filter(doc=>doc.parent_id === this.filter_sector4_id)
    return results
  }

  renderTableTool(item: UserModel) {
    let result:Array<string> = []
    if(this.store.getters.hasRight("user_access_editall")) result.push("Edit")
    if(this.store.state.userId == item.id) result.push("Edit")
    if(this.store.getters.hasRight("user_access_detail")) result.push("Detail")
    return result
  }

  roles(roles:any){
    try {
      if(typeof roles==='string') return JSON.parse(roles)
      else return roles
    } catch (error) {
      return []
    }
  }
  mounted() {
    this.initData();
    this.sectorList.getSectorList({limit:99999})
  }
  async initData() {
    let query: any = {
      search: {
      },
      limit: this.show,
      page: this.page,
      skip: (this.page-1)*this.show,
    };
    if (this.search != "") {
      query.search = {
        ...query.search,
        OR: [
          { firstName: { contains: this.search } },
          { lastName: { contains: this.search } },
          { username: { contains: this.search } },
        ],
      };
    }
    if(this.filter_sector1_id !== "") query.search.sector1_id = this.filter_sector1_id
    if(this.filter_sector2_id !== "") query.search.sector2_id = this.filter_sector2_id
    if(this.filter_sector3_id !== "") query.search.sector3_id = this.filter_sector3_id
    if(this.filter_sector4_id !== "") query.search.sector4_id = this.filter_sector4_id
    if(this.filter_sector5_id !== "") query.search.sector5_id = this.filter_sector5_id
    await this.dataList.getUserList(query);
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
