
import { Options, Vue } from "vue-class-component";
import SectorModel from "@/model/sector";
import { ActiveSectorListClass, ActiveSectorSingleClass } from "@/data/sector";
import Container from "typedi";
import * as d3 from "d3";
import axios from "axios";
import ADDRESS from "@/setting";

@Options({
  components: {},
})
export default class SectorMapView extends Vue {
  mode = "new"
  viewportWidth: number = 0;
  viewportHeight: number = 0;
  svg!: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  sectors: Array<SectorModel> = [];
  dataList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  timerId: any = undefined;
  activeSector: SectorModel = new SectorModel({});
  link: any;
  node: any;
  tree: Array<any> = [];
  parentId = ""
  parentName = ""
  mounted() {
    this.fetchTree();
  }
  async fetchTree() {
    let response = await axios.post(`${ADDRESS}/sectors/tree`);
    console.log("response", response.data);
    this.tree = response.data.docs;
    this.initialize();
  }
  async formSubmit() {
    let dataSingle: ActiveSectorSingleClass = Container.get(
      ActiveSectorSingleClass
    );
    if(this.mode==='new'){
      dataSingle.data = new SectorModel({ parent_id:this.parentId, name: this.activeSector.name, id:"" });
      await dataSingle.createSectorSingle();
    } else {
      dataSingle.data = new SectorModel({...this.activeSector!,id:this.activeSector.id!});
      await dataSingle.updateSectorSingle(this.activeSector.id!);
    }
    window.location.reload()
      // this.addSector(d.data.id, d.data.name)
      // this.drawTree(this.svg)
  }
  async initialize() {
    await this.dataList.getSectorList({});
    try {
      this.sectors = this.tree;
    } catch (error) {}
    let container = document.getElementById(
      "sector-map-container"
    ) as HTMLElement;
    this.viewportHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByName("body")[0].clientHeight;
    // this.viewportWidth =
    //   window.innerWidth ||
    //   document.documentElement.clientWidth ||
    //   document.getElementsByName("body")[0].clientWidth;
    this.viewportWidth = container.offsetWidth;
    this.initD3();
  }

  throttleFunction(func: Function, delay: number) {
    let self = this;
    if (this.timerId) {
      return;
    }
    this.timerId = setTimeout(function () {
      func();
      self.timerId = undefined;
    }, delay);
  }

  containerZoomHandler() {
    let self = this;
    var zoomHanlder = d3
      .zoom<SVGSVGElement, unknown>()
      .on("zoom", function (elem: any, data: any) {
        self.throttleFunction(() => {
          console.log("move");
          //@ts-ignore
          this.node.attr("transform", elem.transform);
          //@ts-ignore
          this.link.attr("transform", elem.transform);
          // let d3container =  d3.select("#sector-map")
          // let x = elem.transform.x;
          // d3container.attr("transform", (el) => {
          //   return `translate(${x},${0}) scale(${elem.transform.k})`;
          // });
        }, 20);
      });
    return zoomHanlder;
  }
  initD3() {
    let svg = d3
      .select("#sector-map")
      .classed("svg-container", true)
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", `0 0 ${this.viewportWidth} ${this.viewportHeight}`)
      .classed("svg-content-responsive", true)
      .attr("width", `${this.viewportWidth}px`)
      .attr("height", `${this.viewportHeight}px`)
      .call(this.containerZoomHandler());
    this.svg = svg;
    this.drawTree(svg);
    const zoom = d3.zoom<SVGSVGElement, unknown>().on("zoom", this.zoomed)
      svg.call(zoom)
  }

  zoomed(ev:any){
    let x = ev.transform.x
    let y = ev.transform.y
    let k = ev.transform.k
    let zoomNodeHeader = d3
      .select("g")
      .attr("transform", d=>`translate(${x},${y}) scale(${k})`)
  }

  drawTree(svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>) {
    d3.selectAll("g > *").remove();
    let collapse = (d: any) => {
      if (d.children) {
        d._children = d.children;
        d.children = null;
        d._children.forEach(collapse);
      }
    };

    let click = (d: any) => {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
    };

    var treeData: any = {
      name: "การกีฬาแห่งประเทศไทย",
      children: [
        {
          name: "Level 2: A",
          children: [{ name: "Son of A" }, { name: "Daughter of A" }],
        },
        { name: "Level 2: B" },
      ],
    };
    treeData.children = this.tree;
    var margin = { top: 40, right: 90, bottom: 150, left: 90 },
      width = this.viewportWidth - margin.left - margin.right,
      height = this.viewportHeight - margin.top - margin.bottom;
    let count = 1
    try {
      count = Math.sqrt(this.dataList.data!.docs.length)
    } catch (error) {
      
    }
    let finalWidth = width * count
    var treemap = d3.tree().size([finalWidth, height]);
    var nodes: any = d3.hierarchy(treeData);
    nodes = treemap(nodes);
    let g = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // adds the links between the nodes
    var link = g
      .selectAll(".link")
      .data(nodes.descendants().slice(1))
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", (d: any) => {
        return (
          "M" +
          d.x +
          "," +
          d.y +
          "L" +
          d.x +
          "," +
          (d.y + d.parent.y) / 2 +
          " " +
          d.parent.x +
          "," +
          (d.y + d.parent.y) / 2 +
          " " +
          d.parent.x +
          "," +
          d.parent.y
        );
      });

    // adds each node as a group
    var node = g
      .selectAll(".node")
      .data(nodes.descendants())
      .enter()
      .append("g")
      .attr("class", (d: any) => {
        return "node" + (d.children ? " node--internal" : " node--leaf");
      })
      .attr("transform", (d: any) => {
        return "translate(" + d.x + "," + d.y + ")";
      })
      .on("click", click);

    // adds the circle to the node
    node
      .append("rect")
      .attr("width", "8rem")
      .attr("height", "3rem")
      .attr("fill", "white")
      .attr("stroke", "#aaa")
      .attr("x", "-4rem")
      .attr("y", "-1.5rem");

    node.append("circle").attr("r", 15).attr("cx", "4rem").attr("cy", "1.5rem").on("click",(e,d:any)=>{
      // console.log(d.data.name,d.data.id)
      //@ts-ignore
      this.parentId = d.data.id
      this.parentName = d.data.name
      this.mode = 'new'
      let ref = this.$refs.editSectorModal as HTMLElement;
      ref.click();
    })

    node
      .append("text")
      .attr("dy", ".35em")
      .attr("x", "4rem")
      .attr("y", "1.5rem")
      .style("text-anchor", "middle")
      .style("pointer-events","none")
      .text("เพิ่ม");

    // adds the text to the node
    node
      .append("text")
      .attr("dy", ".35em")
      .attr("y", 0)
      .style("text-anchor", "middle")
      .text((d: any) => {
        return d.data.name;
      }).on("click",(e,d:any)=>{
        this.mode = 'edit'
        this.activeSector = d.data
        let ref = this.$refs.editSectorModal as HTMLElement;
        ref.click();
      
      // this.addSector(d.data.id, d.data.name)
      // this.drawTree(this.svg)
    })

  }
}
