
import { Options, Vue } from "vue-class-component";
import AssignmentModel, {
  AssignmentMemberModel,
  AssignmentAttachmentModel,
} from "@/model/assignment";
import {
  ActiveAssignmentSingleClass,
  AssignmentAttachmentSingleClass,
  AssignmentMemberSingleClass,
} from "@/data/assignment";
import Container from "typedi";
import ADDRESS from "@/setting";
import { useStore } from "vuex";
import UserModel from "@/model/user";
import ProjectModel from "@/model/project";

import { ActiveProjectListClass } from "@/data/project";
import { confirmAction } from "@/decorator";


@Options({
  components: {},
})
export default class MyAssignmentFormView extends Vue {
  ADDRESS = ADDRESS;
  store = useStore();
  AssignmentMemberModel = AssignmentMemberModel;
  AssignmentModel = AssignmentModel;
  dataSingle: ActiveAssignmentSingleClass = Container.get(
    ActiveAssignmentSingleClass
  );
  dataList: ActiveProjectListClass = Container.get(ActiveProjectListClass);
  filter: number = this.getBudgetYear(new Date());
  disabled: boolean = false;
  docs: Array<ProjectModel> = [];

  status = [
    { value: "ยังไม่ดำเนินการ", name: "ยังไม่ดำเนินการ" },
    { value: "อยู่ระหว่างการดำเนินการ", name: "อยู่ระหว่างการดำเนินการ" },
    { value: "ดำเนินการแล้ว", name: "ดำเนินการแล้ว" },
  ];
  priority = [
    { value: "ปกติ", name: "ปกติ" },
    { value: "ด่วน", name: "ด่วน" },
    { value: "ด่วนมาก", name: "ด่วนมาก" },
  ];
  get assigns() {
    try {
      return this.dataSingle.data!.assigns;
    } catch (error) {
      return [];
    }
  }
  projects() {
    let query: any = {
      search: {
        budgetYear: this.filter
      },
      sort: [{ code: "asc" }]
    };
    this.dataList.getProjectList(query);
  }

  getBudgetYear(date: Date): number {
    const currentMonth = date.getMonth();
    let fiscalYear = date.getFullYear();
    if (currentMonth > 9) fiscalYear++;
    return fiscalYear + 543;
  }


  updateFromAutoPerson1(user: UserModel, item: AssignmentModel) {
    item.originator = user.fullName;
    //@ts-ignore
    item.user_id = user.id;
  }
  updateFromAutoPerson(user: UserModel, item: AssignmentMemberModel) {
    item.name = user.fullName;
    //@ts-ignore
    item.user_id = user.id;
  }
  get isDetail() {
    if (this.$route.path.search(`/detail/`) != -1) return true;
    else return false;
  }
  mounted() {
    this.projects()
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new AssignmentModel({
        originator: this.store.state.fullName,
        user_id: this.store.state.userId,
      });
    }
  }
  async initData() {
    await this.dataSingle.getMyAssignmentSingle(
      this.$route.params.id as string
    );
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      this.dataSingle.createMyAssignmentSingle();
    } else {
      this.dataSingle.updateMyAssignmentSingle();
    }
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAttachmentItem(item: AssignmentAttachmentModel) {
    let attachment: AssignmentAttachmentSingleClass = Container.get(
      AssignmentAttachmentSingleClass
    );
    attachment!.data = item;
    await attachment.deleteAttachmentSingle();
    this.$nextTick(() => {
      this.initData();
    });
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAssignmentMember(item: AssignmentMemberModel) {
    let attachment: AssignmentMemberSingleClass = Container.get(
      AssignmentMemberSingleClass
    );
    attachment!.data = item;
    await attachment.deleteAttachmentMemberSingle();
    this.$nextTick(() => {
      this.initData();
    });
  }
}
