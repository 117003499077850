import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import StrategicModel, { StrategicItemIndicatorModel, StrategicItemModel,MissionModel,ObjectiveModel } from "@/model/strategic";
import StrategicServices from "@/service/strategic";
import { Service } from 'typedi';

@Service()
export class ActiveStrategicBudgetYearClass {
  data?: StrategicModel;
  strategies?: Array<StrategicItemModel>
  tactics?: Array<StrategicItemModel>
  plans?: Array<StrategicItemModel>
  loading: boolean = true;
  async getStrategicFromBudgetYear(budgetYear: number) {
    this.loading = true
    let {doc, strategies, tactics, plans} = await new StrategicServices().getStrategicFromBudgetYear(budgetYear)
    this.data = doc
    this.strategies = strategies
    this.tactics = tactics
    this.plans = plans
    this.loading = false
  }
}

@Service()
export class ActiveStrategicSingleClass {
  data?: StrategicModel;
  data2?: StrategicItemIndicatorModel;
  data3?: StrategicItemModel;
  data4?: MissionModel;
  data5?: ObjectiveModel;
  loading: boolean = true;
  current_id: string = ""
  async getStrategicSingle(id: string) {
    this.current_id = id
    this.loading = true
    this.data = await new StrategicServices().getStrategicSingle(id)
    this.loading = false
  }
  async updateStrategicSingle(id: string) {
    this.loading = true
    this.data = await new StrategicServices().updateStrategic(this.data!.id!, this.data!)
    this.loading = false
  }
  async createStrategicSingle() {
    this.loading = true
    this.data = await new StrategicServices().createStrategic(this.data!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteStrategicSingle(id: string) {
    this.loading = true
    this.data = await new StrategicServices().deleteStrategicSingle(this.data!.id!)
    this.data = await new StrategicServices().getStrategicSingle(this.current_id)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteStrategicMissionSigle(id: string) {
    this.loading = true
    console.log('data',this.data)
    this.data4 = await new StrategicServices().deleteStrategicMission(id)
    this.data = await new StrategicServices().getStrategicSingle(this.current_id)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteStrategicObjectiveSigle(id: string) {
    this.loading = true
    this.data5 = await new StrategicServices().deleteStrategicObjective(id)
    this.data = await new StrategicServices().getStrategicSingle(this.current_id)
    this.loading = false
  }
  async createStrategicItemSingle(strategic_id:string, item:StrategicItemModel){
    this.loading = true
    this.data3 = await new StrategicServices().createStrategicItem(strategic_id, item)
    this.data = await new StrategicServices().getStrategicSingle(this.current_id)
    this.loading = false
  }
  async updateStrategicItemSingle(item:StrategicItemModel){
    this.loading = true
    this.data3 = await new StrategicServices().updateStrategicItem(this.data!.id!, item)
    this.data = await new StrategicServices().getStrategicSingle(this.current_id)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteStrategicItemSigle(id: string) {
    this.loading = true
    this.data3 = await new StrategicServices().deleteStrategicItem(id)
    this.data = await new StrategicServices().getStrategicSingle(this.current_id)
    this.loading = false
  }
  async createStrategicItemIndicatorSingle(strategicItem_id:string, item:StrategicItemIndicatorModel){
    this.loading = true
    this.data2 = await new StrategicServices().createStrategicItemIndicator(strategicItem_id, item)
    this.data = await new StrategicServices().getStrategicSingle(this.current_id)
    this.loading = false
  }
  async updateStrategicItemIndicatorSingle(id:string,item:StrategicItemIndicatorModel){
    this.loading = true
    await new StrategicServices().updateStrategicItemIndicator(id, item)
    this.data = await new StrategicServices().getStrategicSingle(this.current_id)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteStrategicItemIndicatorSigle(id: string) {
    this.loading = true
    this.data2 = await new StrategicServices().deleteStrategicItemIndicator(id)
    this.data = await new StrategicServices().getStrategicSingle(this.current_id)
    this.loading = false
  }
  
}

@Service()
export class ActiveStrategicListClass {
  data?: PaginateResponse<StrategicModel>;
  loading: boolean = true;
  async getStrategicList(query: any) {
    this.loading = true
    this.data = await new StrategicServices().getStrategicList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteStrategicFromList(id: string) {
    this.loading = true
    let tmp = await new StrategicServices().deleteStrategicSingle(id)
    let index = this.data!.docs.findIndex(doc => doc.id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }
}