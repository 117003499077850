
import { Options, Vue } from "vue-class-component";
import {
  IndicatorMetricModel,
  IndicatorResponsiblePersonModel,
} from "@/model/indicator";
import { ActiveStrategicBudgetYearClass } from "@/data/strategic";
import { ReportA10Class } from "@/data/report";
import Container from "typedi";
import DocumentPDF from "@/document/pdf";
import { DocumentExcel } from "@/document/xlsx";
@Options({
  components: {},
})
export default class ReportA10View extends Vue {
  reportData: ReportA10Class = Container.get(ReportA10Class);
  mounted() {
    this.initData();
  }
  async initData() {
    let query: any = {};
    query.search = this.$route.params.budgetYear;
    await this.reportData.getReportA10ListT2(query);
  }
  async generateReportPDF() {
    const documentPDF = new DocumentPDF();
    documentPDF.create10(
      this.reportData,
      parseInt(this.$route.params.budgetYear as string)
    );
    documentPDF.save(`reportA10.pdf`);
  }
  async generateReportExcel() {
    let documentExcel = new DocumentExcel();
    documentExcel.createA10(
      this.reportData,
      parseInt(this.$route.params.budgetYear as string)
    );
  }
  getProjectByType(item: any, type: string) {
    try {
      let found = item.sumTypes.find((el: any) => el.name === type);
      return found.amount;
    } catch (error) {
      return 0;
    }
  }
  getActivityByType(item: any, type: string) {
    try {
      let sum = 0
      item.projects.forEach((project:any) => {
        let found = this.getProjectByType(project,type)
        sum += found
      });
      return sum
    } catch (error) {
      console.log({error})
      return 0;
    }
  }
  getProductByType(item: any, type: string) {
    try {
      let sum = 0
      item.activities.forEach((activity:any) => {
        let found = this.getActivityByType(activity,type)
        sum += found
      });
      return sum
    } catch (error) {
      console.log({error})
      return 0;
    }
  }
  getItemByType(item: any, type: string) {
    try {
      let sum = 0
      item.products.forEach((product:any) => {
        let found = this.getProductByType(product,type)
        sum += found
      });
      return sum
    } catch (error) {
      console.log({error})
      return 0;
    }
  }
  getTotal(type: string) {
    try {
      let sum = 0
      this.reportData.data.plans.forEach((item:any) => {
        let found = this.getItemByType(item,type)
        sum += found
      });
      return sum
    } catch (error) {
      console.log({error})
      return 0;
    }
  }
  get types() {
    try {
      return this.reportData.projects![0].types;
    } catch (error) {}
  }
}
