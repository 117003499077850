
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    modelValue: [String, Number],
    label: String,
    "onUpdate:modelValue": Function,
  },
})
export default class Dropdown extends Vue {
  label!:string;
  modelValue!: string|number;
  val:string|number = ""
  mounted(){
    this.val = this.modelValue
  }
  emitUp() {
    console.log(this.val)
    this.$emit("update:modelValue", this.val);
  }
}
