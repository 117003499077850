import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import AccountModel from "@/model/account";
import AccountServices from "@/service/account";
import { Service } from 'typedi';

@Service()
export class ActiveAccountSingleClass {
  data?: AccountModel;
  loading: boolean = true;
  async getAccountSingle(id: string) {
    this.loading = true
    this.data = await new AccountServices().getAccountSingle(id)
    this.loading = false
  }
  async updateAccountSingle() {
    this.loading = true
    this.data = await new AccountServices().updateAccount(this.data!.id!, this.data!)
    this.loading = false
  }
  async createAccountSingle() {
    this.loading = true
    this.data = await new AccountServices().createAccount(this.data!)
    this.loading = false
  }
}

@Service()
export class ActiveAccountListClass {
  data?: PaginateResponse<AccountModel>;
  loading: boolean = true;
  query:any = {}
  async getAccountList(query: any) {
    this.query = query
    this.loading = true
    this.data = await new AccountServices().getAccountList(query)
    this.loading = false
  }
  async refreshAccountList() {
    this.loading = true
    this.data = await new AccountServices().getAccountList(this.query)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAccountFromList(id:string) {
    this.loading = true
    let response = await new AccountServices().deleteAccount(id)
    this.refreshAccountList()
    this.loading = false
  }
}