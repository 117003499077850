import ADDRESS from "@/setting";
import axios from "axios"
import IndicatorReviewModel from "@/model/kpiReview";
import ReportModel, { ReportA01Model, ReportA02Model, ReportA04Model, ReportA08Type1Model, ReportA08Type2Model, ReportB03Model,ReportB04Model, ReportB05Model,ReportB19Model,ReportA10Model, ReportA03Model, ReportA05Model, ReportA07Model} from "@/model/report";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class ReportServices {
  public getReportA01List(query: any) {
    return new Promise<PaginateResponse<ReportModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A01`, query);
      console.log({ data: response.data.docs })
      let docs: Array<ReportModel> = response.data.docs

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<ReportModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }
  public getReportA02List(query: any) {
    return new Promise<PaginateResponse<ReportModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A02`, query);
      console.log({ data: response.data })
      let docs: Array<ReportModel> = response.data.docs

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<ReportModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }
  public getReportA01(query: any) {
    return new Promise<ReportA01Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A01`, query);
      let doc: ReportA01Model = response.data
      resolve(doc)
    })
  }
  public getReportA10List(query: any) {
    return new Promise<PaginateResponse<ReportModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A02`, query);
      console.log({ data: response.data })
      let docs: Array<ReportModel> = response.data.docs

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<ReportModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public getReportA02(query: any) {
    return new Promise<ReportA02Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A02`, query);
      console.log("reportA02", response.data)
      let doc: ReportA02Model = new ReportA02Model(response.data.doc)
      resolve(doc)
    })
  }
  public getReportA03SMIS(query: any) {
    return new Promise<ReportA03Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A03-SMIS`, query);
      let doc: ReportA03Model = new ReportA03Model(response.data)
      resolve(doc)
    })
  }
  public getReportA03EFORM(query: any) {
    return new Promise<ReportA03Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A03-EFORM`, query);
      let doc: ReportA03Model = new ReportA03Model(response.data)
      resolve(doc)
    })
  }
  public getReportA04(query: any) {
    return new Promise<ReportA04Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A04`, query);
      let doc: ReportA04Model = new ReportA04Model(response.data.doc)
      console.log({doc})
      resolve(doc)
    })
  }
  public getReportA05(query: any) {
    return new Promise<ReportA05Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A05`, query);
      console.log({response})
      let doc: ReportA05Model = new ReportA05Model(response.data)
      console.log({doc})
      resolve(doc)
    })
  }
  public getReportA07(query: any) {
    return new Promise<ReportA07Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A07`, query);
      console.log("response", response.data)
      let doc: ReportA07Model = new ReportA07Model(response.data.doc)
      resolve(doc)
    })
  }
  public getReportA08Type1(query: any) {
    return new Promise<ReportA08Type1Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A08/type1`, query);
      let doc: ReportA08Type1Model = new ReportA08Type1Model(response.data.doc)
      resolve(doc)
    })
  }
  public getReportA08Type2(query: any) {
    return new Promise<ReportA08Type2Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A08/type2`, query);
      let doc: ReportA08Type2Model = new ReportA08Type2Model(response.data.doc)
      resolve(doc)
    })
  }


  public getReportA10ListT2(query: any) {
    return new Promise<Array<ReportA10Model>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/A10`, query);
      let docs: Array<ReportA10Model> = response.data.docs
      resolve(docs)
    })
  }
  public getReportB03(query: any) {
    return new Promise<ReportB03Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B03`, query);
      let doc: ReportB03Model = new ReportB03Model(response.data.doc)
      resolve(doc)
    })
  }

  public getReportB04(query: any) {
    return new Promise<ReportB04Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B04`, query);
      let doc: ReportB04Model = new ReportB04Model(response.data.doc)
      resolve(doc)
    })
  }

  public getReportB18(query: any) {
    return new Promise<IndicatorReviewModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B18`, query);
      let doc: IndicatorReviewModel = new IndicatorReviewModel(response.data.doc)
      resolve(doc)
    })
  }
  public getReportB19(query: any) {
    return new Promise<ReportB19Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B19`, query);
      let doc: ReportB19Model = new ReportB19Model(response.data.doc)
      resolve(doc)
    })
  }
  public getReportB05(query: any) {
    return new Promise<ReportB05Model>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/report/B05`, query);
      let doc: ReportB05Model = new ReportB05Model(response.data.doc)
      resolve(doc)
    })
  }
}