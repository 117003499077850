
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import PlanTable from "@/views/overlap/components/PlanTable.vue";
import { ActiveProjectSingleClass } from "@/data/project";
import Container from "typedi";
import { ActiveBudgetSingleClass } from "@/data/budget";
import BudgetModel from "../../model/budget";
import { DocumentExcel } from "@/document/xlsx";
import { useStore } from "vuex";
import Document from "@/document/pdf";
import { confirmAction } from "@/decorator";

@Options({
  components: {
    PlanTable,
  },
})
export default class BudgetPlanView extends Vue {
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  store = useStore();

  mounted() {
    if (this.$route.params.id !== undefined) this.initData();
    else {
      this.dataSingle.data = new ProjectModel({});
    }
  }
  async initData() {
    this.dataSingle.data = undefined;
    await this.dataSingle.getProjectSingle(this.$route.params.id as string);
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createProjectSingle();
    } else {
      this.dataSingle.updateProjectSingle();
    }
  }
}
