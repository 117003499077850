import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "position-relative indicator-tooltip" }
const _hoisted_2 = { class: "me-2 cursor-pointer" }
const _hoisted_3 = { class: "position-absolute top-100 end-0 indicator-tooltip-child" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("td", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString((_ctx.budget??{}).indicatorCode), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.budget??{}).indicatorFull, (i) => {
          return (_openBlock(), _createElementBlock("p", null, [
            _createElementVNode("span", null, _toDisplayString(i), 1)
          ]))
        }), 256))
      ])
    ])
  ]))
}