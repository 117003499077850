
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import { ActiveProjectSingleClass } from "@/data/project";
import TableHeader from "@/views/overlap/components/TableHeader.vue"
import TableBody from "@/views/overlap/components/TableBody.vue"
import Container from "typedi";
import { rawListeners } from "process";
import { ActiveBudgetSingleClass } from "@/data/budget";
@Options({
  components: {
    TableHeader,
    TableBody
  },
})
export default class PlanTableComponent extends Vue {
  doc: ProjectModel = new ProjectModel({})
  // budget: BudgetModel = new BudgetModel({})
  isZero: boolean = false;
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  budget: ActiveBudgetSingleClass = Container.get(ActiveBudgetSingleClass);
  tudNgern: boolean = false;
  get totalBudget() {
    try {
      // let map = this.dataSingle.data?.budgets.map(budget=> budget)
      //let map = this.dataSingle.data?.budgets.map(budget=> budget.items.map(item=>item.amount).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b,0)
      let map = this.dataSingle.data?.budgets.map(budget=> budget.items.map(item=>item.amount).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b,0)
    return map
    } catch (error) {
      return 0;
    }
  }
}
