
import { Options, Vue } from "vue-class-component";
import PublicrelationModel, { PublicRelationAttachmentModel } from "@/model/publicrelation";
import ADDRESS from "@/setting"
import {
  ActivePublicrelationListClass,
  ActivePublicrelationSingleClass,
  PublicRelationAttachmentSingleClass,
  
} from "@/data/publicrelation";
import Container from "typedi";
import { confirmAction } from "@/decorator";

@Options({
  components: {},
})
export default class PositionFormView extends Vue {
  ADDRESS = ADDRESS
  dataSingle: ActivePublicrelationSingleClass = Container.get(
    ActivePublicrelationSingleClass
  );

  mounted() {
    if (this.$route.params.id !== undefined) this.initData();
    else { 
      this.dataSingle.data = new PublicrelationModel({})
    }
  }
  async initData() {
    await this.dataSingle.getPublicrelationSingle(this.$route.params.id as string);
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAttachmentItem(index:number,item:PublicRelationAttachmentModel) {
    if(item.id != undefined) {
      let attachment: PublicRelationAttachmentSingleClass = Container.get(
        PublicRelationAttachmentSingleClass
      );
      attachment!.data = item
      await attachment.deleteAttachmentSingle()
      this.$nextTick(()=>{

      this.initData()
      })
    }else{
      try {
        this.dataSingle.data?.attachments.splice(index,1)
        this.$nextTick(()=>{

        this.initData()
        })
      } catch (error) {
        console.log(error);
        
      }
    }
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteImageItem(index:number,item:PublicRelationAttachmentModel) {
    if(item.id != undefined) {
      let image: PublicRelationAttachmentSingleClass = Container.get(
        PublicRelationAttachmentSingleClass
      );
      image!.data = item
      console.log('da',item.id);
      await image.deleteImageSingle()
      this.$nextTick(()=>{

      this.initData()
      })
    }else{
      try {
        this.dataSingle.data?.images.splice(index,1)
        this.$nextTick(()=>{

        this.initData()
        })
      } catch (error) {
        console.log(error);
        
      }
    }

  
  }
  
  async submitForm() {
    if (this.$route.params.id === undefined) {
      
      await this.dataSingle.createPublicrelationSingle();

    } else {
     await  this.dataSingle.updatePublicrelationSingle();
    }
   
    this.$router.push('/portal/pr/announcement');
  }
}
