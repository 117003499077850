
import ADDRESS from "@/setting";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { confirmAction } from "@/decorator";
@Options({
  components: {},
  props: {
    deleteFn: Function,
    modelValue: String,
    tools: Array,
    id: String,
    id2: String,
    status: String,
    path: String,
    pathDelete: String,
    pathDetail: String,
    name: String,
  },
})
export default class PRTableTools extends Vue {
  modelValue!: string;
  deleteFn!: Function;
  id!: string;
  id2!: string;
  status!: string;
  path!: string;
  pathDelete!: string;
  pathDetail!: string;
  tools!: Array<string>;
  disabledDuplicate!: boolean;
  disabledPDF!: boolean;
  disabledApprove!: boolean;
  disabledReject!: boolean;
  disabledEdit!: boolean;
  name!: string;
  toolIntersect(array1: Array<any>, array2: Array<any>) {
    const filteredArray = array1.filter((value) => array2.includes(value));
    return filteredArray.length > 0;
  }
  showTool(tool: string): boolean {
    try {
      if (this.tools.includes(tool)) return true;
      else return false;
    } catch (error) {
      return false;
    }
  }
  disableTool(tool: string): boolean {
    return false;
    if (tool === "PDF") return false;
    else if (tool === "Approve") {
      if (this.status !== "Approved") return false;
      else return true;
    } else if (tool === "Reject") {
      if (this.status !== "Rejected") return false;
      else return true;
    } else if (tool === "Edit") {
      if (this.status === "Rejected" || this.status === "Approved") return true;
      else return false;
    } else if (tool === "Delete") {
      return false;
    }
    return true;
  }
  toConvertPath(tool: string) {
    if (this.disableTool(tool)) return null;
    this.$router.push(`/portal/delivery/convert/${this.id}`);
  }

  toEditPath(tool: string) {
    if (this.disableTool(tool)) return null;
    // console.log("ddsda", this.id);
    if (this.id == undefined) {
      this.$router.push(`/portal/${this.path}`);
    } else {
      this.$router.push(`/portal/${this.path}/edit/${this.id}`);
    }
  }
  toEditListPath(tool: string) {
    if (this.disableTool(tool)) return null;
    this.$router.push(`/portal/${this.path}/edit/${this.id2}/${this.id}`);
  }
  toListPath(tool: string) {
    if (this.disableTool(tool)) return null;
    this.$router.push(`/portal/${this.path}/list/${this.id}`);
  }
  toPDFPath(tool: string) {
    if (this.disableTool(tool)) return null;
    this.$router.push(`/portal/${this.path}/pdf/${this.id}`);
  }
  toDetailPath(tool: string) {
    if (this.disableTool(tool)) return null;
    this.$router.push(
      `/portal/${this.pathDetail == undefined ? this.path : this.pathDetail
      }/detail/${this.id}`
    );
  }
  toDetailListPath(tool: string) {
    if (this.disableTool(tool)) return null;
    this.$router.push(
      `/portal/${this.pathDetail == undefined ? this.path : this.pathDetail
      }/detail/${this.id2}/${this.id}`
    );
  }

  async deliver(tool: string) {
    if (this.disableTool(tool)) return null;
    let query = {
      _id: this.id,
    };
    console.log("path", this.path);
    const response = await axios.patch(
      `${ADDRESS}/${this.path}/delivered`,
      query
    );
    console.log(response.data);
    this.$emit("update");
  }
  async verify(tool: string) {
    if (this.disableTool(tool)) return null;
    let query = {
      _id: this.id,
    };
    const response = await axios.patch(
      `${ADDRESS}/${this.path}/verified`,
      query
    );
    console.log(response.data);
    this.$emit("update");
  }
  async approved(tool: string) {
    this.$emit("approve");
  }
  async rejected(tool: string) {
    this.$emit("reject");
  }

  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteItem(tool: string) {
    if (this.disableTool(tool)) return null;
    const response = await axios.delete(
      `${ADDRESS}/${this.pathDelete == undefined ? this.path : this.pathDelete
      }/delete/${this.id}`
    );
    console.log(response.data);
    this.$emit("update");
  }
  doNothing() {
    console.log("disabled");
  }
}
