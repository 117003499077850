import { PaginateResponse } from "@/common";
import AttendantModel from "@/model/attendant";
import AttendantServices from "@/service/attendant";
import store from "@/store"
import { Service } from "typedi";

@Service()
export class ActiveAttendantSingleClass {
  data?: AttendantModel;
  loading: boolean = true;
  // store = store
  async getAttendantSingle(id: string) {
    this.loading = true;
    this.data = await new AttendantServices().getAttendantSingle(id);
    this.loading = false;
  }
  async updateAttendantSingle() {
    this.loading = true;
    this.data = await new AttendantServices().updateAttendant(
      this.data!.id!,
      this.data!
    );
    this.loading = false;
  }
  async UserSignIn() {
    this.loading = true;
    this.data = await new AttendantServices().createAttendant(
      this.data ?? new AttendantModel({ staff: store.state.userId, inTime: new Date() })
    );
    this.loading = false;
  }
  async UserSignOut() {
    this.loading = true;
    this.data = await new AttendantServices().createAttendant(
      this.data ?? new AttendantModel({ staff: store.state.userId, outTime: new Date() })
    );
    this.loading = false;
  }
}

@Service()
export class ActiveAttendantListClass {
  data?: PaginateResponse<AttendantModel>;
  loading: boolean = true;
  async getAttendantList(query: any) {
    this.loading = true;
    this.data = await new AttendantServices().getAttendantList(query);
    this.loading = false;
  }

  userInTime(data: PaginateResponse<AttendantModel>) {
    try {
      return new Date(data.docs[0].inTime).toTime();
    } catch (error) {
      return "-";
    }
  }
  userOutTime(data: PaginateResponse<AttendantModel>) {
    try {
      return new Date(data.docs[0].outTime).toTime()
    } catch (error) {
      return "-";
    }
  }
}
