
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import { ActiveBudgetSingleClass } from "@/data/budget";
import Container from "typedi";
import { ActiveIndiciatorListClass } from "@/data/indicator";
import { ActiveProjectSingleClass } from "@/data/project";
import { Watch } from "vue-property-decorator";
import BudgetModel, {BudgetIndicatorModel} from "@/model/budget";

@Options({
  components: {},
})
export default class PlanTableComponent extends Vue {

  doc: BudgetModel = new BudgetModel({})
  project: ActiveProjectSingleClass = Container.get(ActiveProjectSingleClass);
  budget: ActiveBudgetSingleClass = Container.get(ActiveBudgetSingleClass);
  indicatorList: ActiveIndiciatorListClass = Container.get(ActiveIndiciatorListClass);
  
  mounted() {
  }
  @Watch("project.data")
  async initData(){
    await this.indicatorList.getIndiciatorList({search:{budgetYear:this.project.data!.budgetYear}});
  }
  pustIndicator(){
    this.budget.data!.indicators.push(new BudgetIndicatorModel({}))
    console.log(this.budget.data)
  }
  async submitForm() {
    await this.budget.updateBudgetSingle();
    await this.project.getProjectSingle(this.$route.params.id as string);
  }
}
