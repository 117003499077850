
import { Options, Vue } from "vue-class-component";
import {
  ActiveBudgetYearClass,
  ActiveTypeClass,
  ActiveBudgetSourceClass,
} from "@/data/system";
import {
  ActiveReportListClass,
  ReportA01Class,
  ReportA02Class,
  ReportA03Class,
  ReportA04Class,
  ReportA05Class,
  ReportA07Class,
  ReportA08Type1Class,
  ReportA08Type2Class,
} from "@/data/report";
import SectorModel from "@/model/sector";
import { ActiveSectorListClass } from "@/data/sector";
import Container from "typedi";
import ADDRESS from "@/setting";
import DocumentA from "@/document/pdf";
import { DocumentExcel } from "@/document/xlsx";
import ReportModel from "@/model/report";
import { useStore } from "vuex";
@Options({
  components: {},
})
export default class DocumentListView extends Vue {
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  dataSetting: ActiveTypeClass = Container.get(ActiveTypeClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  budgetSourceSetting: ActiveBudgetSourceClass = Container.get(
    ActiveBudgetSourceClass
  );
  sectorData: Array<SectorModel> = [];
  dataList: ActiveReportListClass = Container.get(ActiveReportListClass);
  docs: Array<ReportModel> = [];
  pages: number = 0;
  total: number = 0;
  active: number = 0;
  filter1: any = "";
  filter2: any = "";
  filter3: any = "";
  filter4: any = "";
  search4:string = "";
  store = useStore();
  ADDRESS = ADDRESS;

  reportA01: ReportA01Class = Container.get(ReportA01Class);
  reportA02: ReportA02Class = Container.get(ReportA02Class);
  reportA03: ReportA03Class = Container.get(ReportA03Class);
  reportA04: ReportA04Class = Container.get(ReportA04Class);
  reportA05: ReportA05Class = Container.get(ReportA05Class);
  reportA07: ReportA07Class = Container.get(ReportA07Class);
  reportA08Type1: ReportA08Type1Class = Container.get(ReportA08Type1Class);
  reportA08Type2: ReportA08Type2Class = Container.get(ReportA08Type2Class);

  get types() {
    let arr: Array<Object> = [];
    this.dataSetting.data?.forEach((el) => {
      arr.push({
        name: el.name,
        value: el.name,
      });
    });
    return arr;
  }
  get budgetYear() {
    let arr: Array<Object> = [];
    this.yearSetting.data?.forEach((el) => {
      arr.push({
        name: el.name,
        value: parseInt(el.text1),
      });
    });
    return arr;
  }
  get sector() {
    let arr: Array<Object> = [];
    this.sectorData.forEach((el) => {
      arr.push({
        name: el.name,
        value: el.id,
      });
    });
    return arr;
  }
  get budgetSource() {
    let arr: Array<Object> = [];
    this.budgetSourceSetting.data?.forEach((el) => {
      arr.push({
        name: el.name,
        value: el.name,
      });
    });
    return arr;
  }
  mounted() {
    this.getData();
  }
  async getData() {
    this.dataSetting.getTypeSetting("ประเภทตัวชี้วัด");
    this.yearSetting.getBudgetYearSetting();
    await this.budgetSourceSetting.getBudgetSourceSetting(
      "แหล่งงบประมาณในคำขอ"
    );
    await this.sectorList.getSectorList({
      search: { category: "ฝ่าย/สำนัก" }
    });
    this.sectorData = this.sectorList.data!.docs;
  }
  async getReport(name: string, type: string) {
    let query = {};
    if(name === "A04"){
      query = {
        budgetYear: this.filter1,
        search4:this.search4
      };
    }
    else if (name == "A03" || name == "A02" || name == "A01") {
      query = {
        budgetYear: this.filter1,
      };
    } 
    else if (name == "A05" || name == "A07"){
      query = {
        budgetYear: this.filter1,
        budgetSource : this.filter4,
      };
    }
    else {
      query = {
        budgetYear: this.filter1,
        type: this.filter2,
      };
    }
    //getdata
    if (name == "A01") {
      await this.reportA01.getReportA01List(query);
    } else if (name == "A02") {
      await this.reportA02.getReportA02List(query);
    }else if (name == "A03-SMIS") {
      await this.reportA03.getReportA03SMISList(query);
    }else if (name == "A03-EFORM") {
      await this.reportA03.getReportA03EFORMList(query);
    } else if (name == "A04") {
      await this.reportA04.getReportA04List(query);
    } else if (name == "A05") {
      await this.reportA05.getReportA05List(query);
    } else if (name == "A07") {
      await this.reportA07.getReportA07List(query);
    } else if (name == "A08Type1") {
      await this.reportA08Type1.getReportA08Type1List(query);
    } else if (name == "A08Type2") {
      await this.reportA08Type2.getReportA08Type2List(query);
    } else if (name == "A10") {
      await this.dataList.getReportA10List(query);
      this.docs = this.dataList.data!.docs;
    } else {
    }
    //generatePDF/Excel
    if (type == "PDF") {
      this.generateReportPDF(name);
    } else {
      this.generateReportExcel(name);
    }
  }
  //PDF
  async generateReportPDF(name: string) {
    console.log("generatePDF");
    const documentA = new DocumentA();
    if (name == "A01") {
      documentA.create01(this.reportA01.data!, this.filter1);
    } else if (name == "A02") {
      documentA.create02(this.reportA02.data!, this.filter1);
    } else if (name == "A03-SMIS") {
      documentA.create03(this.reportA03.data?.items!, this.filter1);
    } else if (name == "A03-EFORM") {
      documentA.create03(this.reportA03.data?.items!, this.filter1);
    } else if (name == "A04") {
      documentA.create04(this.reportA04.data!, this.filter1);
    } else if (name == "A05") {
      documentA.create05(this.reportA05.data?.items!,this.reportA05.data!, this.filter1);
    } else if (name == "A07") {
      documentA.create07(this.reportA07.data!,this.filter4, this.filter1);
    } else if (name == "A10") {
      alert("Report A10 ต้องใช้หน้าฟอร์ม");
      // documentA.create10;
    } else if (name == "A08Type1") {
      documentA.create08Type1(this.reportA08Type1.data!, this.filter1);
    } else if (name == "A08Type2") {
      documentA.create08Type2(this.reportA08Type2.data!, this.filter1,this.filter4);
    } else {
    }
    documentA.save(`report${name}.pdf`);
  }
  //EXCEL
  async generateReportExcel(name: string) {
    console.log("generateExcel");
    let docExcel = new DocumentExcel();
    if (name == "A01") {
      docExcel.createA01(this.reportA01.data!, this.filter1);
    } else if (name == "A02") {
      docExcel.createA02(this.reportA02.data!, this.filter1);
    } else if (name == "A03-SMIS") {
      docExcel.createA03(this.reportA03.data?.items!, this.filter1);
    } else if (name == "A03-EFORM") {
      docExcel.createA03(this.reportA03.data?.items!, this.filter1);
    } else if (name == "A04") {
      docExcel.createA04(this.reportA04.data!, this.filter1);
    } else if (name == "A05") {
      docExcel.createA05(this.reportA05.data?.items!,this.reportA05.data!, this.filter1);
    } else if (name == "A07") {
      docExcel.createA07(this.reportA07.data!,this.filter4, this.filter1);
    } else if (name == "A10") {
      // docExcel.createA10(this.docs, 2565);
      alert("A10 ใช้หน้ารายงาน");
    } else if (name == "A08Type1") {
      docExcel.createA08Type1(this.reportA08Type1.data!, this.filter1);
    } else if (name == "A08Type2") {
      docExcel.createA08Type2(this.reportA08Type2.data!, this.filter1,this.filter4);
    } else {
      // docExcel.createA01(this.docs, 2565, 30);
    }
  }
}
