
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import axios from "axios";
import { update } from "lodash";
import Container from "typedi";
import { ActiveUserListClass } from "@/data/user";

@Options({
  props: {
    modelValue: String,
    label: String,
    "onUpdate:modelValue": Function,
    disabled:Boolean,
  },
})
export default class JMAutoPerson extends Vue {
  label!:string;
  modelValue!: any;
  inputData: any = "";
  focus = false;
  docs: Array<any> = [];
  dataList: ActiveUserListClass = Container.get(ActiveUserListClass);
  disabled!:boolean
  @Watch("inputData")
  clear(){
    if(this.inputData===" ") this.inputData = ""
  }
  updateList() {
    this.fetchData();
  }
  emitUp(item: any) {
    this.focus = false
    this.inputData = item.fullName
    this.$emit("update:modelValue", item);
    this.$emit("change", item);

  }
  mounted() {
    if (this.modelValue !== undefined) this.inputData = this.modelValue ?? "";
  }
  fetchData() {
    let query: any = {
      search: {
        OR: [
          {
            firstName: {
              contains: this.inputData,
            },
          },
          {
            lastName: {
              contains: this.inputData,
            },
          },
        ],
      },
    };
    this.dataList.getUserList(query);
  }
  emitup = (event: any) => {
    this.$emit("update:modelValue", this.inputData);
  };
}
