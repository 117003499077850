import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import FollowUpModel from "@/model/followup";
import FollowUpServices from "@/service/followup";
import { Service } from 'typedi';

@Service()
export class ActiveFollowUpSingleClass {
  data?: FollowUpModel;
  loading: boolean = true;
  
  async getFollowUpSingle(smisCode: string) {
    this.loading = true
    this.data = await new FollowUpServices().getFollowUpSingle(smisCode)
    this.loading = false
  }
  async updateFollowUpSingle() {
    this.loading = true
    this.data = await new FollowUpServices().updateFollowUp(this.data!.id!, this.data!)
    this.loading = false
  }
  async createFollowUpSingle(smisCode:string) {
    this.loading = true
    this.data = await new FollowUpServices().createFollowUp(smisCode, this.data!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteFollowUpSingle() {
    this.loading = true
    this.data = await new FollowUpServices().deleteFollowUpSingle(this.data!.id!)
    this.loading = false
  }

}

@Service()
export class ActiveFollowUpListClass {
  data?: PaginateResponse<FollowUpModel>;
  loading: boolean = true;
  async getFollowUpList(query: any) {
    this.loading = true
    this.data = await new FollowUpServices().getFollowUpList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteFollowUpFromList(id: string) {
    this.loading = true
    let tmp = await new FollowUpServices().deleteFollowUpSingle(id)
    let index = this.data!.docs.findIndex(doc => doc.id === id)
    this.data!.docs.splice(index, 1)
    this.loading = false
  }

}