import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import DocumentModel,{DocumentAttachmentModel} from "@/model/document";
import DocumentServices from "@/service/document";
import { Service } from 'typedi';

@Service()
export class ActiveDocumentSingleClass {
  data?: DocumentModel;
  loading: boolean = true;
  async getDocumentSingle(id: string) {
    this.loading = true
    this.data = await new DocumentServices().getDocumentSingle(id)
    this.loading = false
  }
  async updateDocumentSingle() {
    this.loading = true
    this.data = await new DocumentServices().updateDocument(this.data!.id!, this.data!)
    this.loading = false
  }
  async createDocumentSingle() {
    this.loading = true
    this.data = await new DocumentServices().createDocument(this.data!)
    this.loading = false
  }
}
@Service()
export class DocumentAttachmentSingleClass {
  data?: DocumentAttachmentModel;
  loading: boolean = true;
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAttachmentSingle() {
    console.log(this.data!.id+"service")
    this.loading = true
    this.data = await new DocumentServices().deleteAttachmenDocumentSingle(this.data!.id!)
    this.loading = false
  }
}
@Service()
export class ActiveDocumentListClass {
  data?: PaginateResponse<DocumentModel>;
  loading: boolean = true;
  async getDocumentList(query: any) {
    this.loading = true
    this.data = await new DocumentServices().getDocumentList(query)
    console.log({ query, data: this.data })
    this.loading = false
  }


  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteDocumentSingle(id: string) {
    this.loading = true
   let response = await new DocumentServices().deleteDocumentSingle(id)
   this.getDocumentList({ sort: {
    createdAt: "desc",
  },});
    this.loading = false

  }
}