
import { Options, Vue } from "vue-class-component";
import UserModel from "@/model/user";
import ADDRESS from "@/setting";
import { ActiveUserSingleClass } from "@/data/user";
import { ActiveSectorListClass } from "@/data/sector";
import { ActivePositionListClass } from "@/data/position";
import PositionModel from "@/model/position";
import SectorModel from "@/model/sector";
import Container from "typedi";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {
    isModal: Boolean,
    isDetail: Boolean,
    userId: String,
  },
})
export default class UserFormView extends Vue {
  disabled: boolean = false;
  dataSingle: ActiveUserSingleClass = Container.get(ActiveUserSingleClass);
  sectors: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  docs: Array<SectorModel> = [];
  positions: ActivePositionListClass = Container.get(ActivePositionListClass);
  docs2: Array<PositionModel> = [];
  ADDRESS = ADDRESS;
  isModal!: boolean;
  isDetail!: boolean;
  store = useStore();


  get sector_01(){
    return this.docs.filter(doc=>doc.category === "ผู้ว่าการกีฬาแห่งประเทศไทย")
  }
  get sector_02(){
    let results =  this.docs.filter(doc=>doc.category === "รองผู้ว่าการกีฬาแห่งประเทศไทย")
    if(this.dataSingle.data!.sector1_id !== "") results = results?.filter(doc=>doc.parent_id === this.dataSingle.data!.sector1_id)
    return results
  }
  get sector_03(){
    let results =  this.docs.filter(doc=>doc.category === "ฝ่าย/สำนัก"||doc.category === "สำนัก"||doc.category === "ฝ่าย")
    if(this.dataSingle.data!.sector1_id !== "") results = results?.filter(doc=>doc.parent_id === this.dataSingle.data!.sector2_id)
    return results
  }
  get sector_04(){
    let results =  this.docs.filter(doc=>doc.category === "กอง")
    if(this.dataSingle.data!.sector1_id !== "") results = results?.filter(doc=>doc.parent_id === this.dataSingle.data!.sector3_id)
    return results
  }
  get sector_05(){
    let results =  this.docs.filter(doc=>doc.category === "งาน")
    if(this.dataSingle.data!.sector1_id !== "") results = results?.filter(doc=>doc.parent_id === this.dataSingle.data!.sector4_id)
    return results
  }

  systems: Array<any> = [
    { name: "ตัวชี้วัด", value: "indicator_profile" },
    { name: "ผังยุทธศาสตร์", value: "indicator_strategic" },
    { name: "ติดตามและประเมิณตัวชี้วัด", value: "indicator_followup" },
    { name: "จัดเตรียมงบฯ ของปีถัดไป", value: "indicator_duplicate" },
    { name: "งบประมาณ", value: "budget_form" },
    { name: "การปรับแผน", value: "adjustment_form" },
    { name: "กันเหลื่อม", value: "overlap" },
    { name: "การเบิก/ยืมเงิน", value: "withdraw_form" },
    { name: "รายงาน การเบิก/ยืมเงิน", value: "withdraw_summary" },
    { name: "ติดตามผลการปฏิบัติงาน", value: "followup_form" },
    { name: "รายงาน", value: "report_access" },
    { name: "แดชบอร์ด", value: "dashboard_access" },
    { name: "ประชาสัมพันธ์", value: "publicrelation_news" },
    { name: "ข่าวด่วน", value: "publicrelation_flashnews" },
    { name: "เอกสาร", value: "publicrelation_document" },
    { name: "สั่งงาน Work From Home", value: "assignment_form" },
    { name: "ตอบ Work From Home", value: "assignment_history" },
    { name: "ลงชื่อเข้างาน", value: "assignment_signin" },
    { name: "จัดการผู้ใช้", value: "user_access" },
    { name: "ตั้งค่าทั่วไป", value: "setting_general" },
    { name: "ตั้งค่าตำแหน่ง", value: "setting_position" },
    { name: "ตั้งค่าหน่วยงาน", value: "setting_sector" },
    { name: "ตั้งค่ารหัสสินค้า", value: "setting_zpcode" },
  ];
  checkAdmin(){
    if(this.dataSingle.data!.roles.includes("ผู้ดูแลระบบ")){
      this.dataSingle.data!.rights = [
        "indicator_profile_list",
        "indicator_strategic_list",
        "indicator_followup_list",
        "indicator_duplicate_list",
        "budget_form_list",
        "withdraw_summary_editsector",
        "followup_form_editsector",
        "indicator_profile_create",
        "indicator_strategic_create",
        "indicator_followup_create",
        "indicator_duplicate_create",
        "adjustment_form_create",
        "adjustment_form_editall",
        "report_access_editall",
        "adjustment_form_list",
        "setting_zpcode_list",
        "setting_sector_list",
        "setting_position_list",
        "setting_general_list",
        "setting_zpcode_editsector",
        "setting_sector_editsector",
        "setting_position_editsector",
        "setting_general_editsector",
        "user_access_list",
        "assignment_signin_list",
        "assignment_history_list",
        "assignment_form_list",
        "publicrelation_document_list",
        "publicrelation_flashnews_list",
        "publicrelation_news_list",
        "dashboard_access_list",
        "report_access_list",
        "followup_form_list",
        "indicator_profile_editall",
        "withdraw_form_create",
        "withdraw_summary_create",
        "budget_form_create",
        "budget_form_editsector",
        "indicator_duplicate_editsector",
        "publicrelation_document_create",
        "publicrelation_flashnews_create",
        "publicrelation_news_create",
        "dashboard_access_create",
        "report_access_create",
        "followup_form_create",
        "assignment_form_create",
        "setting_zpcode_create",
        "setting_sector_create",
        "setting_position_create",
        "setting_general_create",
        "user_access_create",
        "assignment_signin_create",
        "assignment_history_create",
        "setting_zpcode_editall",
        "setting_sector_editall",
        "setting_position_editall",
        "setting_general_editall",
        "user_access_editall",
        "assignment_signin_editall",
        "assignment_history_editall",
        "assignment_form_editall",
        "user_access_editsector",
        "assignment_signin_editsector",
        "assignment_history_editsector",
        "assignment_form_editsector",
        "publicrelation_document_editall",
        "publicrelation_flashnews_editall",
        "publicrelation_news_editall",
        "dashboard_access_editall",
        "followup_form_editall",
        "withdraw_summary_editall",
        "withdraw_form_editall",
        "budget_form_editall",
        "indicator_duplicate_editall",
        "indicator_followup_editall",
        "indicator_strategic_editall",
        "setting_zpcode_detail",
        "setting_zpcode_delete",
        "setting_zpcode_report",
        "setting_sector_report",
        "setting_sector_delete",
        "setting_sector_detail",
        "setting_position_detail",
        "setting_position_delete",
        "setting_position_report",
        "setting_general_report",
        "setting_general_delete",
        "setting_general_detail",
        "user_access_detail",
        "user_access_report",
        "assignment_signin_report",
        "assignment_signin_delete",
        "assignment_signin_detail",
        "assignment_history_detail",
        "assignment_history_delete",
        "assignment_history_report",
        "assignment_form_report",
        "assignment_form_delete",
        "assignment_form_detail",
        "publicrelation_document_report",
        "publicrelation_document_delete",
        "publicrelation_document_detail",
        "publicrelation_flashnews_detail",
        "publicrelation_flashnews_delete",
        "publicrelation_flashnews_report",
        "publicrelation_news_report",
        "publicrelation_news_delete",
        "publicrelation_news_detail",
        "dashboard_access_detail",
        "dashboard_access_delete",
        "dashboard_access_report",
        "report_access_report",
        "report_access_delete",
        "report_access_detail",
        "indicator_profile_editsector",
        "indicator_strategic_editsector",
        "followup_form_detail",
        "followup_form_delete",
        "followup_form_report",
        "withdraw_summary_report",
        "withdraw_form_report",
        "adjustment_form_report",
        "adjustment_form_delete",
        "adjustment_form_detail",
        "budget_form_detail",
        "budget_form_delete",
        "budget_form_report",
        "indicator_duplicate_report",
        "indicator_duplicate_delete",
        "indicator_duplicate_detail",
        "indicator_followup_detail",
        "indicator_followup_delete",
        "indicator_followup_report",
        "indicator_strategic_report",
        "indicator_strategic_delete",
        "indicator_strategic_detail",
        "indicator_profile_detail",
        "indicator_profile_delete",
        "indicator_profile_report",
        "adjustment_form_approval",
        "assignment_signin_editsector",
        "assignment_history_editsector",
        "assignment_form_editsector",
        "dashboard_access_editsector",
        "report_access_editsector",
        "adjustment_form_editsector",
        "indicator_followup_editsector",
        "budget_form_approval",
        "withdraw_form_list",
        "assignment_form_approval",
        "assignment_history_approval",
        "withdraw_form_detail",
        "withdraw_summary_detail",
        "withdraw_form_delete",
        "withdraw_summary_delete",
        "user_access_delete",
        "setting_zpcode_approval",
        "indicator_profile_approval",
        "indicator_strategic_approval",
        "indicator_followup_approval",
        "indicator_duplicate_approval",
        "withdraw_form_approval",
        "withdraw_summary_approval",
        "report_access_approval",
        "followup_form_approval",
        "dashboard_access_approval",
        "publicrelation_flashnews_approval",
        "publicrelation_news_approval",
        "publicrelation_document_approval",
        "setting_sector_approval",
        "setting_position_approval",
        "setting_general_approval",
        "user_access_approval",
        "assignment_signin_approval",
        "overlap_list",
        "overlap_create",
        "overlap_report",
        "overlap_editall",
        "overlap_detail",
        "overlap_delete",
        "overlap_approval",
        "withdraw_summary_list",
        "overlap_editsector",
        "withdraw_form_editsector",
        "publicrelation_document_editsector",
        "publicrelation_flashnews_editsector",
        "publicrelation_news_editsector",
      ]} else if (this.dataSingle.data!.roles.includes("ผู้บริหารระดับสูง CEO")) {
      this.dataSingle.data!.rights = [
          "indicator_profile_list",
          "indicator_strategic_list",
          "user_access_list",
          "assignment_history_list",
          "assignment_form_list",
          "dashboard_access_list",
          "dashboard_access_delete",
          "report_access_list",
          "assignment_form_create",
          "assignment_signin_create",
          "assignment_signin_editsector",
          "assignment_form_editall",
          "dashboard_access_editall",
          "assignment_signin_report",
          "assignment_signin_detail",
          "assignment_history_detail",
          "assignment_history_report",
          "assignment_form_detail",
          "dashboard_access_delete",
          "dashboard_access_report",
          "report_access_report",
          "report_access_detail",
          "followup_form_detail",
          "followup_form_list",
          "followup_form_report",
          "dashboard_access_create",
          "assignment_signin_list",
          "assignment_history_list",
          "assignment_form_list",
          "publicrelation_document_list",
          "publicrelation_flashnews_list",
          "publicrelation_news_list",
          "publicrelation_document_detail",
          "publicrelation_flashnews_detail",
          "publicrelation_news_detail",
          "publicrelation_document_report",
          "publicrelation_flashnews_report",
          "publicrelation_news_report",
        ]
    } else if (this.dataSingle.data!.roles.includes("ผู้รับผิดชอบโครงการ")){
      this.dataSingle.data!.rights  = [
          "indicator_profile_list",
          "indicator_followup_list",
          "indicator_duplicate_list",
          "budget_form_list",
          "budget_form_delete",
          "withdraw_summary_editsector",
          "user_access_list",
          "assignment_history_list",
          "assignment_form_list",
          "dashboard_access_list",
          "report_access_list",
          "assignment_form_create",
          "assignment_signin_create",
          "assignment_history_create",
          "assignment_history_editsector",
          "assignment_form_editsector",
          "adjustment_form_editsector",
          "adjustment_form_delete",
          "assignment_signin_report",
          "assignment_signin_detail",
          "assignment_history_detail",
          "assignment_history_report",
          "assignment_form_report",
          "assignment_form_detail",
          "dashboard_access_detail",
          "dashboard_access_report",
          "report_access_report",
          "followup_form_detail",
          "followup_form_report",
          "withdraw_summary_report",
          "withdraw_form_report",
          "adjustment_form_create",
          "adjustment_form_report",
          "adjustment_form_detail",
          "budget_form_detail",
          "budget_form_report",
          "indicator_followup_detail",
          "indicator_followup_report",
          "indicator_profile_detail",
          "indicator_profile_report",
          "withdraw_form_detail",
          "withdraw_summary_detail",
          "overlap_list",
          "overlap_report",
          "budget_form_editsector",
          "overlap_detail",
          "withdraw_form_editsector",
          "assignment_signin_list",
          "budget_form_create",
          "adjustment_form_list",
          "withdraw_form_list",
          "withdraw_summary_list",
          "followup_form_list",
          "withdraw_form_create",
          "withdraw_summary_create",
          "publicrelation_news_list",
          "publicrelation_flashnews_list",
          "publicrelation_news_detail",
          "publicrelation_flashnews_detail",
          "publicrelation_document_detail",
          "publicrelation_document_report",
          "publicrelation_news_report",
          "publicrelation_flashnews_report",
        ]
    } else if (this.dataSingle.data!.roles.includes("ผู้ประสานงานโครงการ")) {
      this.dataSingle.data!.rights = [
      "indicator_profile_list",
        "indicator_strategic_list",
        "indicator_followup_list",
        "indicator_duplicate_list",
        "budget_form_list",
        "withdraw_summary_editsector",
        "followup_form_editsector",
        "indicator_profile_create",
        "indicator_strategic_create",
        "indicator_followup_create",
        "indicator_duplicate_create",
        "adjustment_form_create",
        "adjustment_form_editall",
        "report_access_editall",
        "adjustment_form_list",
        "setting_zpcode_list",
        "setting_sector_list",
        "setting_position_list",
        "setting_general_list",
        "setting_zpcode_editsector",
        "setting_sector_editsector",
        "setting_position_editsector",
        "setting_general_editsector",
        "user_access_list",
        "assignment_signin_list",
        "assignment_history_list",
        "assignment_form_list",
        "publicrelation_document_list",
        "publicrelation_flashnews_list",
        "publicrelation_news_list",
        "dashboard_access_list",
        "report_access_list",
        "followup_form_list",
        "indicator_profile_editall",
        "withdraw_form_create",
        "withdraw_summary_create",
        "budget_form_create",
        "budget_form_editsector",
        "indicator_duplicate_editsector",
        "publicrelation_document_create",
        "publicrelation_flashnews_create",
        "publicrelation_news_create",
        "dashboard_access_create",
        "report_access_create",
        "followup_form_create",
        "assignment_form_create",
        "setting_zpcode_create",
        "setting_sector_create",
        "setting_position_create",
        "setting_general_create",
        "user_access_create",
        "assignment_signin_create",
        "assignment_history_create",
        "setting_zpcode_editall",
        "setting_sector_editall",
        "setting_position_editall",
        "setting_general_editall",
        "user_access_editall",
        "assignment_signin_editall",
        "assignment_history_editall",
        "assignment_form_editall",
        "user_access_editsector",
        "assignment_signin_editsector",
        "assignment_history_editsector",
        "assignment_form_editsector",
        "publicrelation_document_editall",
        "publicrelation_flashnews_editall",
        "publicrelation_news_editall",
        "dashboard_access_editall",
        "followup_form_editall",
        "withdraw_summary_editall",
        "withdraw_form_editall",
        "budget_form_editall",
        "indicator_duplicate_editall",
        "indicator_followup_editall",
        "indicator_strategic_editall",
        "setting_zpcode_detail",
        "setting_zpcode_delete",
        "setting_zpcode_report",
        "setting_sector_report",
        "setting_sector_delete",
        "setting_sector_detail",
        "setting_position_detail",
        "setting_position_delete",
        "setting_position_report",
        "setting_general_report",
        "setting_general_delete",
        "setting_general_detail",
        "user_access_detail",
        "user_access_report",
        "assignment_signin_report",
        "assignment_signin_delete",
        "assignment_signin_detail",
        "assignment_history_detail",
        "assignment_history_delete",
        "assignment_history_report",
        "assignment_form_report",
        "assignment_form_delete",
        "assignment_form_detail",
        "publicrelation_document_report",
        "publicrelation_document_delete",
        "publicrelation_document_detail",
        "publicrelation_flashnews_detail",
        "publicrelation_flashnews_delete",
        "publicrelation_flashnews_report",
        "publicrelation_news_report",
        "publicrelation_news_delete",
        "publicrelation_news_detail",
        "dashboard_access_detail",
        "dashboard_access_delete",
        "dashboard_access_report",
        "report_access_report",
        "report_access_delete",
        "report_access_detail",
        "indicator_profile_editsector",
        "indicator_strategic_editsector",
        "followup_form_detail",
        "followup_form_delete",
        "followup_form_report",
        "withdraw_summary_report",
        "withdraw_form_report",
        "adjustment_form_report",
        "adjustment_form_delete",
        "adjustment_form_detail",
        "budget_form_detail",
        "budget_form_delete",
        "budget_form_report",
        "indicator_duplicate_report",
        "indicator_duplicate_delete",
        "indicator_duplicate_detail",
        "indicator_followup_detail",
        "indicator_followup_delete",
        "indicator_followup_report",
        "indicator_strategic_report",
        "indicator_strategic_delete",
        "indicator_strategic_detail",
        "indicator_profile_detail",
        "indicator_profile_delete",
        "indicator_profile_report",
        "adjustment_form_approval",
        "assignment_signin_editsector",
        "assignment_history_editsector",
        "assignment_form_editsector",
        "dashboard_access_editsector",
        "report_access_editsector",
        "adjustment_form_editsector",
        "indicator_followup_editsector",
        "budget_form_approval",
        "withdraw_form_list",
        "assignment_form_approval",
        "assignment_history_approval",
        "withdraw_form_detail",
        "withdraw_summary_detail",
        "withdraw_form_delete",
        "withdraw_summary_delete",
        "user_access_delete",
        "setting_zpcode_approval",
        "indicator_profile_approval",
        "indicator_strategic_approval",
        "indicator_followup_approval",
        "indicator_duplicate_approval",
        "withdraw_form_approval",
        "withdraw_summary_approval",
        "report_access_approval",
        "followup_form_approval",
        "dashboard_access_approval",
        "publicrelation_flashnews_approval",
        "publicrelation_news_approval",
        "publicrelation_document_approval",
        "setting_sector_approval",
        "setting_position_approval",
        "setting_general_approval",
        "user_access_approval",
        "assignment_signin_approval",
        "overlap_list",
        "overlap_create",
        "overlap_report",
        "overlap_editall",
        "overlap_detail",
        "overlap_delete",
        "overlap_approval",
        "withdraw_summary_list",
        "overlap_editsector",
        "withdraw_form_editsector",
        "publicrelation_document_editsector",
        "publicrelation_flashnews_editsector",
        "publicrelation_news_editsector",
       
        ]
    } else if (this.dataSingle.data!.roles.includes("ผู้ใช้งานทั่วไป")) {
      this.dataSingle.data!.rights = [
          "indicator_profile_list",
          "indicator_strategic_list",
          "indicator_followup_list",
          "indicator_duplicate_list",
          "budget_form_list",
          "adjustment_form_list",
          "assignment_signin_list",
          "assignment_signin_create",
          "assignment_signin_editsector",
          "assignment_history_list",
          "assignment_form_list",
          "assignment_history_create",
          "assignment_history_editsector",
          "publicrelation_document_list",
          "publicrelation_flashnews_list",
          "publicrelation_news_list",
          "dashboard_access_list",
          "report_access_list",
          "assignment_signin_report",
          "assignment_history_report",
          "assignment_form_report",
          "publicrelation_document_report",
          "publicrelation_flashnews_report",
          "publicrelation_news_report",
          "dashboard_access_report",
          "report_access_report",
          "followup_form_list",
          "followup_form_report",
          "withdraw_summary_report",
          "withdraw_form_report",
          "adjustment_form_report",
          "budget_form_report",
          "indicator_duplicate_report",
          "indicator_followup_report",
          "indicator_strategic_report",
          "indicator_profile_report",
          "withdraw_form_list",
          "overlap_list",
          "withdraw_summary_list",
          "overlap_report",
        ]
    }
    else {
      this.dataSingle.data!.rights = []
    }
  }
  mounted() {
    if (!this.isModal) {
      this.disabled = this.$route.path.search("/detail") != -1;
      if (this.$route.params.id !== undefined) {
        this.initData();
      } else {
        this.dataSingle.data = new UserModel({});
      }
     this.getData();
    }
  }
  async getData() {
    await this.sectors.getSectorList({limit:99999});
    this.docs = this.sectors.data!.docs;
    await this.positions.getPositionList({});
    this.docs2 = this.positions.data!.docs;
  }
  async initData() {
    await this.dataSingle.getUserSingle(this.$route.params.id as string);
    this.$forceUpdate()
  }
  async submitForm() {
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createUserSingle();
    } else {
      this.dataSingle.updateUserSingle(this.$route.params.id as string);
    }
  }
get getIsAdmin(){
 let admin =  this.store.state.roles.includes("ผู้ดูแลระบบ")
  return admin
  }
}
