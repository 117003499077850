
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveProjectSingleClass } from "@/data/project";
import BudgetModel from "@/model/budget";
import Container from "typedi";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {
    budget: Object,
    level: Number,
    budgetParent: String,
    budgetChild: String,
    tudNgern:Boolean,
  },
})
export default class PlanTableComponent extends Vue {
  budget!: BudgetModel;
  level!: number;
  budgetParent!: string;
  budgetChild!: string;
  store = useStore()

  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );

  monthClass(month: number) {
    try {
      let found = this.budget.items.find((item: any) => item.month === month);
      if (found!.isActive) {
        if (this.level === 1) return "bg-plan1";
        else if (this.level === 2) return "bg-plan2";
        else if (this.level === 3) return "bg-plan3";
      } else return "";
    } catch (error) {
      return "";
    }
  }


  activeItem() {
    if(this.budget.isSpecial){
      alert("กิจกรรมนี้เป็นกิจกรรมควบคุมพิเศษ กรุณาติดต่อ admin หรือ ผู้ประสานงานโครงการ")
      return 
    }
   else  if (this.activeProject.data!.step < 3 || this.store.getters.isAdmin) {
      console.log(this.budget);
      this.activeBudget.data = new BudgetModel({});
      this.activeBudget.data = this.budget;
      this.activeBudget.parent = this.budgetParent;
      this.activeBudget.child = this.budgetChild;
      this.openModal();
    } else {
      alert("ไม่สามารถแก้ไขได้โครงการถูกอนุมัติ");
    }
  }

  openModal() {
    this.$nextTick(() => {
      let ref = this.$refs.openModalFromTableRow as HTMLElement;
      ref.click();
    });
  }
}
