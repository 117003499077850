import 'reflect-metadata';
import "@/extension/number.extension";
import "@/extension/string.extension";
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
global.Buffer = global.Buffer || require('buffer').Buffer
import "@/extensions/date.extension";

import "@/scss/_color.scss"
import "@popperjs/core";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "../node_modules/@fortawesome/fontawesome-pro/css/all.css";
import "vue-toastification/dist/index.css";
import '../node_modules/quill/dist/quill.snow.css';
import 'vue-select/dist/vue-select.css';
import Toast, { PluginOptions } from "vue-toastification";
import money from 'v-money3'

axios.interceptors.request.use((config) => {
  const APIKEY = "2f66df29a833f9c87023ee0bb728531d"
  const token = APIKEY;
  config!.headers!.apikey = token;
  config!.headers!.token = token;
  // config!.headers!.authorization = `Bearer ${this.$store.state.jwt}`;

  return config;
});
import vSelect from 'vue-select'
import LoadingWidget from "@/components/LoadingWidget.vue";
import LoadingModal from "@/components/LoadingModal.vue";
import Dropdown from "@/components/Dropdown.vue";
import TextField from "@/components/TextField.vue";
import TableTool from "@/components/TableTool.vue";
import NumberField from "@/components/NumberField.vue";
import PasswordField from "@/components/PasswordField.vue";
import TextArea from "@/components/TextArea.vue";
import RichText from "@/components/RichText.vue";
import TextArray from "@/components/TextArray.vue";
import JMCalendar from "@/components/JMCalendar.vue";
import JMUploads from "@/components/JMUploads.vue";
import JMUpload from "@/components/JMUpload.vue";
import JMAutoPerson from "@/components/JMAutoPerson.vue";
import TextGroupField from "@/components/TextGroupField.vue";
import JMTablePaginate from "@/components/JMTablePaginate.vue";

import TableRow00Header from "@/components/TableRow00Header.vue";
import TableRow00Indicator from "@/components/TableRow00Indicator.vue";
import TableRow01Budget from "@/components/TableRow01Budget.vue";
import TableRow02Withdraw from "@/components/TableRow02Withdraw.vue";
import TableRow03DiffMonth from "@/components/TableRow03DiffMonth.vue";
import TableRow04DiffAccum from "@/components/TableRow04DiffAccum.vue";
import TableRow10Sum from "@/components/TableRow10Sum.vue";

import axios from 'axios';

const app = createApp(App).use(store).use(money).use(router).use(Toast)
app.component('v-select', vSelect)
app.component("LoadingWidget", LoadingWidget);
app.component("LoadingModal", LoadingModal)
app.component("Dropdown", Dropdown);
app.component("TextField", TextField);
app.component("TableTool", TableTool);
app.component("NumberField", NumberField);
app.component("PasswordField", PasswordField);
app.component("TextArea", TextArea);
app.component("TextGroupField", TextGroupField);
app.component("RichText", RichText);
app.component("TextArray", TextArray);
app.component("JMCalendar", JMCalendar);
app.component('JMAutoPerson', JMAutoPerson)
app.component("JMUploads", JMUploads);
app.component("JMUpload", JMUpload);
app.component("JMTablePaginate", JMTablePaginate);
app.component("TableRow00Header", TableRow00Header);
app.component("TableRow00Indicator", TableRow00Indicator)
app.component("TableRow01Budget", TableRow01Budget);
app.component("TableRow02Withdraw", TableRow02Withdraw);
app.component("TableRow03DiffMonth", TableRow03DiffMonth);
app.component("TableRow04DiffAccum", TableRow04DiffAccum);
app.component("TableRow10Sum", TableRow10Sum);

app.mount('#app')
