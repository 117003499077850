
import { DateTime } from "luxon";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
@Options({
  components: {},
  props: {
    modelValue: [Date, String, Number],
    label: String,
    readonly: Boolean,
    isDisabled: Boolean,
  },
})
export default class JMEventCalendar extends Vue {
  label!: string;
  modelValue!: Date | string;
  isActive: boolean = false;
  showYear: boolean = false;
  selectedYear: string = (new Date().getFullYear() + 543).toString();
  selectedMonth: string = (new Date().getMonth() + 1).toString();
  selectedDate = new Date();
  dayArr: Array<Date> = [];
  yearArr: Array<number> = [];
  monthArr: Array<number> = [];
  isDisabled!: boolean;
  strTyping: string = "";
  isDetail: boolean = false;

  sameDay(dt:Date){
    let md:DateTime
    let checkDate = DateTime.fromJSDate(dt)
    if(typeof this.modelValue === "string") md = DateTime.fromISO(this.modelValue)
    else md = DateTime.fromJSDate(this.modelValue)
    let result = md.startOf("day").hasSame(checkDate.startOf("day"),"day")
    return result
  }
  toDay(dt:Date){
    let checkDate = DateTime.fromJSDate(dt)
    let md = DateTime.now()
    let result = md.startOf("day").hasSame(checkDate.startOf("day"),"day")
    return result
  }
  @Watch("modelValue")
  updateValue() {
    if (this.modelValue == undefined) {
      let date = new Date();
      // this.$emit("update:modelValue", date);
      // this.$emit("change", date);
      /// กรณีอยากให้ปฏิทินจ่ายวันที่แก้ undefined แล้วขึ้น -
    }
  }
  keymonitor(ev: any) {
    this.strTyping += ev.key;
    if (this.strTyping.length >= 8) {
      let dd = this.strTyping.substring(0, 2);
      let mm = this.strTyping.substring(2, 4);
      let yyyy = parseInt(this.strTyping.substring(4, 8)) - 543;
      this.strTyping = "";
      let date = new Date(`${yyyy}-${mm}-${dd}`);
      this.$emit("update:modelValue", date);
      this.isActive = false;
    }
  }
  mounted() {
    if (this.$route.path.search("detail/") != -1) this.isDetail = true;
    if (typeof this.modelValue == "string") {
      this.selectedDate = new Date(this.modelValue);
    } else {
      this.selectedDate = this.modelValue;
    }
    if (this.modelValue == undefined) {
      this.selectedDate = new Date();
    }
    for (var i = 0; i < 200; i++) {
      this.yearArr.push(new Date().getFullYear() - 100 + i + 543);
    }
    for (var i = 0; i < 12; i++) {
      this.monthArr.push(i);
    }
    this.updateValue();
    this.init();
  }
  init() {
    this.dayArr = [];
    let firstDateOfMonth = new Date(this.selectedDate).firstDayOfMonth();
    let lastDateOfMonth = new Date(this.selectedDate).lastDayOfMonth();
    let firstDayOfMonth = firstDateOfMonth.getDay();
    let lastDayOfMonth = lastDateOfMonth.getDay();
    let lastWeekDiff = 7 - lastDayOfMonth;
    for (var i = firstDayOfMonth; i > 0; i--) {
      this.dayArr.push(firstDateOfMonth.clone().addDays(-1 * i));
    }
    for (
      var i = 0;
      i < new Date(this.selectedDate).lastDayOfMonth().getDate();
      i++
    ) {
      this.dayArr.push(firstDateOfMonth.clone().addDays(i));
    }
    for (var i = 1; i < lastWeekDiff; i++) {
      this.dayArr.push(lastDateOfMonth.clone().addDays(i));
    }
  }
  changeMonth() {
    let mo = parseInt(this.selectedMonth) - 1;
    let date = new Date(this.selectedDate);
    date.setMonth(mo);
    this.selectedDate = date as Date;
    this.init();
    console.log("change");
    this.$emit("update:modelValue", date);
  }
  changeYear(ev: InputEvent) {
    let num = 0;
    let target = ev.target as HTMLInputElement;
    num = parseInt(target.value);
    let date = new Date(this.modelValue);
    date.setFullYear(num - 543);
    this.selectedDate = date;
    this.selectedYear = num.toString();
    this.$emit("update:modelValue", date);
    this.$emit("change", date);
    this.init();
  }
  changeDate(date: Date) {
    this.selectedDate = date;
    this.$emit("update:modelValue", date);
    this.$emit("change", date);
    this.isActive = false;
  }
  checkDay(date: Date) {
    let classArray = [];
    if (date.getDay() == 0) classArray.push("sun");
    if (date.getDay() == 6) classArray.push("sat");
    if (date.getMonth() < new Date(this.selectedDate).getMonth())
      classArray.push("prev");
    if (date.getMonth() > new Date(this.selectedDate).getMonth())
      classArray.push("prev");
    // if (date.isSameDate(new Date(this.selectedDate))) classArray.push("active");
    return classArray;
  }
}
