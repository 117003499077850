import { thru } from "lodash";
import BudgetModel, { BudgetItemModel } from "../budget";
import SectorModel from "../sector";
import { SettingMemberModel } from "../system";

export default class AdjustmentModel {
  id?: string;
  budgetSource: string;
  budgetType: string;
  project_id: string;
  budgetYear: Number;
  location: string;
  telephone: string;
  requestDate: Date;
  projectName: string
  name: string
  type: string
  number: Number
  reason: string
  fromRevision: number
  toRevision: number
  status: string
  sectorName: string
  step: number
  creatorName: string
  creatorTelephone: string
  creatorEmail: string
  sector: SectorModel;
  sector_id: string;
  code: string;
  budgets: Array<AdjustmentBudgetModel>
  attachments: Array<AdjustmentAttachmentModel>
  items: Array<AdjustmentItemModel>
  settings?: Array<SettingMemberModel>;
  createdAt?: Date
  isAdjust: boolean
  isAdjust14?: boolean
  isEffect: boolean
  isPlan: boolean
  isType: boolean
  finalReason?: string
  legal?: string
  reasonEffect?: string
  adjustmentSuggestion?: string
  isDraft?:boolean



  //D3
  D3adjustType: string
  documentNumber: string
  documentDate: Date
  revisionType: string;

  revision: number;
  to: string;
  detail: string;
  budget: number;
  analysisOne: string;
  analysisDetailOne: string;
  analysisTwo: string;
  analysisDetailTwo: string;
  analysisThree: string;
  analysisDetailThree: string;
  analysisFour: string;
  analysisDetailFour: string;
  analysisFive: string;
  analysisDetailFive: string;
  analysisSix: string;
  analysisDetailSix: string;
  analysisSeven: string;
  analysisDetailSeven: string;
  analysisEight: string;
  analysisDetailEight: string;
  regulationsAndLaws: string;
  opinion: string

  approvals: Array<AdjustmentApproval>
  by:string;
  suggestion:string;
  directorDate:Date;
  managerDate:Date;
  operatorDate:Date;
  isFor:boolean;
  forUserId:string;
  forPositionName:string;
  forUserFullName:string;

  addPlans:string;
  constructor(jsonData: any) {
    let budgets: Array<AdjustmentBudgetModel> = []
    let attachments: Array<AdjustmentAttachmentModel> = []
    let items: Array<AdjustmentItemModel> = []
    let approvals: Array<AdjustmentApproval> = []
    try {
      budgets = (jsonData.budgets ?? []).map((o: any) => new AdjustmentBudgetModel(o))
    } catch (error) {
      console.log({ error })
    }
    try {
      items = (jsonData.items ?? []).map((o: any) => new AdjustmentItemModel(o))
    } catch (error) {
      console.log({ error })
    }
    try {
      attachments = (jsonData.attachments ?? []).map((doc: any) => new AdjustmentAttachmentModel(doc))
    } catch (error) {
      console.log({ error })
    }
    try {
      
      approvals = (jsonData.approvals ?? []).map((o: any) => new AdjustmentApproval(o))
    } catch (error) {
      console.log({ error })
    }
    let by = ""
    console.log(jsonData.budgetYear)
    if(jsonData.budgetYear!=undefined){
      by = `โดยมีรายละเอียดประกอบการพิจารณาตามเอกสารแบบเสนอปรับแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${jsonData.budgetYear} ที่แนบจำนวน ๑ แผ่น`
    }
    let suggestion = ""
    if(jsonData.budgetYear!=undefined){
      suggestion =`จึงเรียนมาเพื่อโปรดพิจารณาอนุมัติและลงนามในแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${jsonData.budgetYear} รายการที่ ${jsonData.projectName}`
    }
    this.id = jsonData.id
    this.budgetSource = jsonData.budgetSource;
    this.budgetType = jsonData.budgetType;
    this.project_id = jsonData.project_id;
    this.budgetYear = jsonData.budgetYear;
    this.location = jsonData.location;
    this.telephone = jsonData.telephone;
    this.requestDate = jsonData.requestDate;
    this.projectName = jsonData.projectName??"";
    this.name = jsonData.name;
    this.type = jsonData.type;
    this.status = jsonData.status;
    this.step = jsonData.step;
    this.number = jsonData.number;
    this.fromRevision = jsonData.fromRevision;
    this.toRevision = jsonData.toRevision;
    this.reason = jsonData.reason;
    this.budgets = budgets
    this.items = items
    this.sector = jsonData.sector;
    this.sectorName = jsonData.sectorName;
    this.sector_id = jsonData.sector_id;
    this.code = jsonData.code;
    this.isAdjust = jsonData.isAdjust
    this.isAdjust14 = jsonData.isAdjust14
    this.isEffect = jsonData.isEffect
    this.finalReason = jsonData.finalReason
    this.legal = jsonData.legal
    this.isPlan = jsonData.isPlan
    this.isType = jsonData.isType
    this.reasonEffect = jsonData.reasonEffect;
    this.adjustmentSuggestion = jsonData.adjustmentSuggestion;
    this.attachments = attachments;
    this.creatorName = jsonData.creatorName;
    this.creatorTelephone = jsonData.creatorTelephone;
    this.creatorEmail = jsonData.creatorEmail;
    this.isDraft = jsonData.isDraft
    this.createdAt = new Date(jsonData.createdAt)
    this.approvals = approvals
    //
    this.D3adjustType = jsonData.D3adjustType
    this.documentDate = jsonData.documentDate
    this.documentNumber = jsonData.documentNumber
    this.revisionType = jsonData.revisionType
    this.revision = jsonData.revision
    this.to = jsonData.to
    this.detail = jsonData.detail
    this.budget = jsonData.budget
    this.analysisOne = jsonData.analysisOne??""
    this.analysisDetailOne = jsonData.analysisDetailOne??""
    this.analysisTwo = jsonData.analysisTwo??""
    this.analysisDetailTwo = jsonData.analysisDetailTwo??""
    this.analysisThree = jsonData.analysisThree??""
    this.analysisDetailThree = jsonData.analysisDetailThree??""
    this.analysisFour = jsonData.analysisFour??""
    this.analysisDetailFour = jsonData.analysisDetailFour??""
    this.analysisFive = jsonData.analysisFive??""
    this.analysisDetailFive = jsonData.analysisDetailFive??""
    this.analysisSix = jsonData.analysisSix??""
    this.analysisDetailSix = jsonData.analysisDetailSix??""
    this.analysisSeven = jsonData.analysisSeven??""
    this.analysisDetailSeven = jsonData.analysisDetailSeven??""
    this.analysisEight = jsonData.analysisEight??""
    this.analysisDetailEight = jsonData.analysisDetailEight??""
    this.regulationsAndLaws = jsonData.regulationsAndLaws??""
    this.by = by
    this.suggestion = suggestion
    this.directorDate =jsonData.directorDate?? new Date();
    this.managerDate =jsonData.managerDate?? new Date();
    this.operatorDate =jsonData.operatorDate?? new Date();
    this.isFor = jsonData.isFor
    this.forUserId = jsonData.forUserId
    this.forPositionName = jsonData.forPositionName??""
    this.forUserFullName = jsonData.forUserFullName??""

    this.addPlans = jsonData.addPlans??""

    this.createdAt = new Date(jsonData.createdAt)
    this.opinion = jsonData.opinion
    console.log('json1', jsonData);
  }
  get cleanStep() {
    if (this.step >= 0) return this.step
    else return Math.abs(this.step)
  }

  get stepPercent() {
    try {
      let maxStep = 7
      let result = 100 * (this.cleanStep / maxStep)
      return result
    } catch (error) {
      return 0
    }

  }
  get createdAtFormat() {
    try {
      return this.createdAt?.toThaiShort()
    } catch (error) {
      return "-"
    }
  }

  toJSON() {
    let obj = Object.assign({}, this)
    delete obj.settings
    obj.items = obj.items.map(el => {
      return new AdjustmentItemModel(el).toJSON()
    })
    obj.budgets = obj.budgets.map(el => {
      return new AdjustmentBudgetModel(el).toJSON()
    })
    obj.attachments = obj.attachments.map(el => {
      return new AdjustmentAttachmentModel(el).toJSON()
    })
    obj.approvals = obj.approvals.map(el => {
      return new AdjustmentApproval(el).toJSON()
    })
    console.log("obj", obj)
    return JSON.parse(JSON.stringify(obj))
  }
}
export class AdjustmentItemModel {
  id?: string;
  level1_code: string;
  level2_code: string;
  level3_code: string;
  before: string;
  after: string;
  reason: string
  goalsRemark: string
  nameRemark: string
  plansRemark: string
  previousBudgetPlan: string
  adjustBudgetPlan: string
  value: any;

  constructor(jsonData: any) {
    this.id = jsonData.id
    this.level1_code = jsonData.level1_code ?? ""
    this.level2_code = jsonData.level2_code ?? ""
    this.level3_code = jsonData.level3_code ?? ""
    this.before = jsonData.before ?? ""
    this.after = jsonData.after ?? ""
    this.reason = jsonData.reason ?? ""
    this.goalsRemark = jsonData.goalsRemark ?? ""
    this.nameRemark = jsonData.nameRemark ?? ""
    this.plansRemark = jsonData.plansRemark ?? ""
    this.previousBudgetPlan = jsonData.previousBudgetPlan ?? ""
    this.adjustBudgetPlan = jsonData.adjustBudgetPlan ?? ""
  }

  toJSON() {
    let obj: any = Object.assign({}, this)
    return JSON.parse(JSON.stringify(obj))
  }
}
export class AdjustmentBudgetModel {
  id?: string;
  level1_code: string;
  level2_code: string;
  level3_code: string;
  reason: string
  adjustType: string;
  description1: string;
  description2: string;
  description3: string;
  adjustReferenceBudget: number;

  constructor(jsonData: any) {
    this.id = jsonData.id
    this.level1_code = jsonData.level1_code ?? ""
    this.level2_code = jsonData.level2_code ?? ""
    this.level3_code = jsonData.level3_code ?? ""
    this.reason = jsonData.reason ?? ""
    this.adjustType = jsonData.adjustType ?? ""
    this.description1 = jsonData.description1 ?? ""
    this.description2 = jsonData.description2 ?? ""
    this.description3 = jsonData.description3 ?? ""
    this.adjustReferenceBudget = jsonData.adjustReferenceBudget ?? 0
  }

  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.id = obj.id

    return JSON.parse(JSON.stringify(obj))
  }
}

export class AdjustmentAttachmentModel {
  id?: string;
  path?: string;
  size?: string;
  percent?: string;
  category?: string;

  constructor(jsonData: any) {
    this.id = jsonData.id
    this.path = jsonData.path;
    this.size = jsonData.size;
    this.percent = jsonData.percent;
    this.category = jsonData.category;
    // console.log("constructorAtt", doc)
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    return JSON.parse(JSON.stringify(obj))
  }
}


export class AdjustmentApproval {
  id?: string;
  reason: string;
  fromStep: number;
  toStep: number;
  createdAt: Date;
  maxStep = 7;
  constructor(jsonData: any, maxStep?: number) {
    this.id = jsonData.id
    this.reason = jsonData.reason ?? "-"
    this.fromStep = jsonData.fromStep ?? -10
    this.toStep = jsonData.toStep ?? -10
    this.createdAt = new Date(jsonData.createdAt)
    this.maxStep = maxStep ?? 7
  }
  get createdAtFormat() {
    return this.createdAt.toThaiTime()
  }

  get cleanFromStep() {
    if (this.fromStep >= 0) return this.fromStep
    else return Math.abs(this.fromStep)
  }

  get fromStepPercent() {
    try {

      let result = 100 * (this.cleanFromStep / this.maxStep)
      return result
    } catch (error) {
      return 0
    }

  }
  get cleanToStep() {
    if (this.toStep >= 0) return this.toStep
    else return Math.abs(this.toStep)
  }

  get toStepPercent() {
    try {
      let result = 100 * (this.cleanToStep / this.maxStep)
      return result
    } catch (error) {
      return 0
    }
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    return JSON.parse(JSON.stringify(obj))
  }
}