
import { Options, Vue } from "vue-class-component";
import PlanTable from "@/views/adjustment/components/PlanTable.vue";
import ModalBudgetIndicator from "@/views/adjustment/components/ModalBudgetIndicator.vue";
import ModalBudgetItemMoney from "@/views/adjustment/components/ModalBudgetItemMoney.vue";
import { ActiveBudgetSingleClass } from "@/data/budget";
import { ActiveAdjustmentSingleClass } from "@/data/adjustment";
import BudgetModel from "../../model/budget";
import Container from "typedi";
import { ActiveProjectSingleClass } from "@/data/project";
import { confirmAction } from "@/decorator";
import { json } from "d3-fetch";
import { DocumentExcel } from "@/document/xlsx";

@Options({
  components: {
    PlanTable,
    ModalBudgetIndicator,
    ModalBudgetItemMoney,
  },
})
export default class AdjustmentPlanView extends Vue {
  activeBudget: ActiveBudgetSingleClass = Container.get(
    ActiveBudgetSingleClass
  );
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  adjustmentSingle: ActiveAdjustmentSingleClass = Container.get(
    ActiveAdjustmentSingleClass
  );
  get kpi6() {
    try {
      if (Array.isArray(this.dataSingle.kpi6)) return this.dataSingle.kpi6;
      return JSON.parse(this.dataSingle.kpi6);
    } catch (error) {
      return [];
    }
  }
  get kpi12() {
    try {
      if (Array.isArray(this.dataSingle.kpi12)) return this.dataSingle.kpi12;
      return JSON.parse(this.dataSingle.kpi12);
    } catch (error) {
      return [];
    }
  }
  @confirmAction("คุณต้องการขออนุมัติคำของบประมาณหรือไม่?")
  requestApprove() {
    this.adjustmentSingle.requestApprove();
  }

  async undoPressed() {
    this.dataSingle.data = undefined;
    let i = this.dataSingle.allRevisions.findIndex(
      (el) => el === this.dataSingle.currentRevision
    );
    if (i > -1) {
      await this.dataSingle.getAdjustmentD2SingleWithRevision(
        this.$route.params.id as string,
        this.dataSingle.allRevisions[i - 1]
      );
      this.dataSingle.currentRevision = this.dataSingle.allRevisions[i - 1]
    }
  }
  async defaultPressed() {
    this.dataSingle.data = undefined;
      await this.dataSingle.getAdjustmentD2SingleWithRevision(
        this.$route.params.id as string,
        this.dataSingle.allRevisions[this.dataSingle.allRevisions.length - 2]
      );
      this.dataSingle.currentRevision = this.dataSingle.allRevisions[this.dataSingle.allRevisions.length - 2]
  }
  async initData() {
    this.dataSingle.data = undefined;
    await this.dataSingle.getAdjustmentD2Single(
      this.$route.params.id as string
    );
    await this.dataSingle.getAllRevision(this.$route.params.id as string);
    await this.adjustmentSingle.getAdjustmentSingle(
      this.$route.params.id as string
    );
    this.dataSingle.currentRevision = this.adjustmentSingle.data?.toRevision!;
  }
  mounted() {
    this.initData();
  }
  async clearBudget() {
    this.activeBudget.data = new BudgetModel({});
    this.openModal();
  }
  openModal() {
    this.$nextTick(() => {
      let ref = this.$refs.openModalFromPlanView as HTMLElement;
      ref.click();
    });
  }

    ExportExcel() {
    let docExcel = new DocumentExcel();
    docExcel.createPlanAdjust(this.dataSingle,this.adjustmentSingle.data!.name );
  }
}
