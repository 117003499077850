
import { ActiveProjectSingleClass } from "@/data/project";
import BudgetModel from "@/model/budget";
import Container from "typedi";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    budget: Object,
    level: Number,
    budgetParent: String,
    budgetChild: String,
    tudNgern:Boolean,
  },
})
export default class PlanTableComponent extends Vue {
  budget!: BudgetModel;
  level!: number;
  budgetParent!: string;
  budgetChild!: string;

  activeProject: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );

  monthClass(month: number) {
    try {
      let found = this.budget.items.find((item: any) => item.month === month);
      if (found!.isActive) {
        if (this.level === 1) return "bg-plan1";
        else if (this.level === 2) return "bg-plan2";
        else if (this.level === 3) return "bg-plan3";
      } else return "";
    } catch (error) {
      return "";
    }
  }
}
