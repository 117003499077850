import MyDocument from "../index";
import pdfMake from "pdfmake/build/pdfmake.js";
import htmlToPdfmake from "html-to-pdfmake"
import { TDocumentDefinitions } from "pdfmake/interfaces";
import IReport06, { IReport06Item } from "@/interface/report06";
import IReport08, { IReport08Item } from "@/interface/report08";
import IReport10, { IReport10Item } from "@/interface/report10";
import * as _ from "lodash"
import { ReportA01Model, ReportA02Model, ReportA04Model, ReportA07Model, ReportA08Type1Model, ReportA08Type2Model, ReportB04Model } from "@/model/report";
import store from "@/store";
import { ActiveStrategicBudgetYearClass } from "@/data/strategic";
import Container from "typedi";
import { ReportA10Class } from "@/data/report";
import ADDRESS from "@/setting";
import { linkVertical, text } from "d3";
import axios from "axios";
import UserServices from "@/service/user";
import BudgetModel from "@/model/budget";
import { head } from "lodash";
import { SettingMemberModel } from "@/model/system";
import { useStore } from "vuex";


export default class DocumentPDF extends MyDocument {
  before: Array<any> = []
  after: Array<any> = []
  strategicBudgetYear: ActiveStrategicBudgetYearClass = Container.get(
    ActiveStrategicBudgetYearClass
  );
  stripHTML(text: string) {
    try {

      return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, "");
    } catch (error) {
      return text
    }
  }
  sumAmountBefore(doc: any, item: any, type: string) {
    try {
      let arr: Array<number> = doc.findBudgetFromFlatted(item, 'before').items.map((el: any) => el.amount ?? 0)
      return arr.reduce((a, b) => a + b, 0).formatComma()
    } catch (error) {
    }
  }
  sumAmountAfter(doc: any, item: any, type: string) {
    try {
      let arr: Array<number> = doc.findBudgetFromFlatted(item, 'after').items.map((el: any) => el.amount ?? 0)
      return arr.reduce((a, b) => a + b, 0).formatComma()
    } catch (error) {
    }
  }
  getMonth(i: number) {
    switch (i) {
      case 0: return `ตุลาคม`; break;
      case 1: return `พฤศจิกายน`; break;
      case 2: return `ธันวาคม`; break;
      case 3: return `มกราคม`; break;
      case 4: return `กุมภาพันธ์`; break;
      case 5: return `มีนาคม`; break;
      case 6: return `เมษายน`; break;
      case 7: return `พฤษภาคม`; break;
      case 8: return `มิถุนายน`; break;
      case 9: return `กรกฎาคม`; break;
      case 10: return `สิงหาคม`; break;
      case 11: return `กันยายน`; break;
    }
  }
  getMonthYear(i: number, budgetYear: number) {
    switch (i) {
      case 0: return `ตุลาคม ${(budgetYear) - 1}`; break;
      case 1: return `พฤศจิกายน ${(budgetYear) - 1}`; break;
      case 2: return `ธันวาคม ${(budgetYear - 1)}`; break;
      case 3: return `มกราคม ${budgetYear}`; break;
      case 4: return `กุมภาพันธ์ ${budgetYear}`; break;
      case 5: return `มีนาคม ${budgetYear}`; break;
      case 6: return `เมษายน ${budgetYear}`; break;
      case 7: return `พฤษภาคม ${budgetYear}`; break;
      case 8: return `มิถุนายน ${budgetYear}`; break;
      case 9: return `กรกฎาคม ${budgetYear}`; break;
      case 10: return `สิงหาคม ${budgetYear}`; break;
      case 11: return `กันยายน ${budgetYear}`; break;
    }
  }
  // Before(data:any,item:any) {
  //   this.before.push({text:'แผนงบประมาณ (เดิม)',decoration: 'underline',alignment:'left'})
  //   this.before.push({
  //     columns:[
  //     {text:"จำนวน ",style: "bold",alignment:'left'},
  //     {text:data.findBudgetFromFlatted(item, 'before').items.map((el:any)=>el.amount).reduce((a:any,b:any)=>a+b,0).formatFull()},
  //     {text:"บาท",style: "bold"},]
  //  } )
  //   data.findBudgetFromFlatted(item, 'before')?.items.forEach((el:any)=>{
  //     this.before.push(
  //     {columns:[
  //       {text:this.getMonth(el.month),alignment:'left'}, el.amount.formatFull(), "บาท"
  //     ]}
  //     )
  //   })
  // }
  // After(data:any,item:any) {
  //   this.after.push({text:'แผนงบประมาณ (ปรับ)',decoration: 'underline',alignment:'left'})
  //   this.after.push({
  //     columns:[
  //     {text:"จำนวน ",style: "bold",alignment:'left'},
  //     {text:data.findBudgetFromFlatted(item, 'after').items.map((el:any)=>el.amount).reduce((a:any,b:any)=>a+b,0).formatFull()},
  //     {text:"บาท",style: "bold"}]}
  //   )
  //   data.findBudgetFromFlatted(item, 'after')?.items.forEach((el:any)=>{
  //     this.after.push(
  //       {columns:[
  //         {text:this.getMonth(el.month),alignment:'left'}, el.amount.formatFull(), "บาท"
  //       ]}
  //     )
  //   })
  // }

  _docDefinition: TDocumentDefinitions = {
    content: "พบข้อผิดพลาดในการสร้างเอกสาร PDF",
  };
  _styles = {
    bold: {
      bold: true,
    },
    fontawesome: {
      'font': 'FontAwesome',
      'color': "#656565"
    }
  };
  _fonts: any = {
    Roboto: {
      normal: "http://smis2.sat.or.th/api/v2/static/sarabun_regular.ttf",
      bold: "http://smis2.sat.or.th/api/v2/static/sarabun_bold.ttf",
      italics: "http://smis2.sat.or.th/api/v2/static/sarabun_italic.ttf",
      bolditalics: "http://smis2.sat.or.th/api/v2/static/sarabun_bolditalic.ttf",
    },
    FontAwesome: {
      normal: "http://smis2.sat.or.th/api/v2/static/fa5-pro-light-300.otf",
    },
    Fontello: {
      normal: 'fontello.ttf',
      bold: 'fontello.ttf',
      italics: 'fontello.ttf',
      bolditalics: 'fontello.ttf'
    }
  };
  htmlStyle: any = {
    a: { color: 'blue', decoration: 'underline' },
    strike: { decoration: 'lineThrough' },
    p: { margin: [0, 0, 0, 0] },
  }
  styles: any = {};

  // "รายงานแบบที่ 1",
  async create01(doc: ReportA01Model, budgetYear: Number = new Date().getFullYear() + 543): Promise<any> {

    const totalAmount = doc.sums.reduce((accumulator, item) => {
      return accumulator + item.amount;
    }, 0);
    doc.sums.push({
      amount: totalAmount,
      name: "รวมทั้งสิ้น"
    });
    let data: Array<Array<any>> = [];
    const sortedData = _.sortBy(doc.docs, ['indicatorCode']);
    //const groupedData = _.groupBy(sortedData, 'indicatorCode');

    let myArray = doc.docs
    let newArray: Array<any> = []
    myArray.forEach((el, i) => {
      newArray.push({ items: el.items, code: el.indicatorCode })
    })
    let groupedData = newArray.flat()
    let newGroupedData = _.groupBy(groupedData, 'code')
    //Group A01

    let x = 0
    for (const [k, v] of Object.entries(newGroupedData)) {
      let i = 0
      for (const group of newGroupedData[k]) {
        let j = 0
        let ind_length = group.items.length
        let itemGrouped = _.groupBy(group.items, 'code')
        for (const [k2, v2] of Object.entries(itemGrouped)) {
          let kk = 0
          let item_length = v2.length
          let item = group.items[0]
          for (const el of v2) {
            data.push([
              { border: [true, true, true, true], text: x + 1, rowSpan: j === 0 ? ind_length : 1 },
              { border: [true, true, true, true], alignment: "left", text: (k + " : " + item.name ?? ""), rowSpan: j === 0 ? ind_length : 1 },
              { border: [true, true, true, true], alignment: "left", text: (item.code ?? "") + " " + (item.name ?? ""), rowSpan: kk === 0 ? item_length : 1 },
              { border: [true, true, true, true], alignment: "left", text: el.number + ". " + el.subject },
              { border: [true, true, true, true], text: el.responsible },
              { border: [true, true, true, true], alignment: "right", text: (el.budget ?? 0).formatFull() },
              { border: [true, true, true, true], text: (el.type ?? "-,-").split(",")[0] },
              { border: [true, true, true, true], text: el.sectorName ?? "-" }
            ])
            kk++
          }
          j++
        }
        i++
      }
      x++
    }
    // return
    // sortedData.forEach((el, i) => {
    //   el.items.forEach((item, j) => {
    //     data.push([
    //       { border: [true, true, true, true], text: i + 1, rowSpan: j == 0 ? el.items.length : 1 },
    //       { border: [true, true, true, true], alignment: "left", text: (el.indicatorCode + " : " + el.name ?? ""), rowSpan: j == 0 ? el.items.length : 1 },
    //       { border: [true, true, true, true], alignment: "left", text: (item.code ?? "") + " " + (item.name ?? "") },
    //       { border: [true, true, true, true], alignment: "left", text: item.number + ". " + item.subject },
    //       { border: [true, true, true, true], text: item.responsible },
    //       { border: [true, true, true, true], alignment: "right", text: (item.budget ?? 0).formatFull() },
    //       { border: [true, true, true, true], text: (item.type ?? "-,-").split(",")[0] },
    //       { border: [true, true, true, true], text: item.sectorName ?? "-" }
    //     ])
    //   })
    // });
    // return
    this.init = true;

    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 10],
      styles: this._styles,
      content: [
        {
          text: [{ text: `รายงานความเชื่อมโยงตัวชี้วัด ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableExample",
          alignment: "center",
          table: {
            widths: [20, 170, 80, 125, 60, 60, 70, 95],
            headerRows: 1,
            body: [
              [
                { text: "ลำดับ", fillColor: "#DCE6F1" },
                { text: "ตัวชี้วัด", fillColor: "#DCE6F1" },
                { text: "รายการ", fillColor: "#DCE6F1" },
                { text: "ข้อดำเนินการ/กิจกรรม", fillColor: "#DCE6F1" },
                { text: "ผู้รับผิดชอบ", fillColor: "#DCE6F1" },
                { text: "งบประมาณ", fillColor: "#DCE6F1" },
                { text: "แหล่งงบประมาณ", fillColor: "#DCE6F1" },
                { text: "ฝ่ายและสำนักที่รับผิดชอบ", fillColor: "#DCE6F1" },
              ],
              ...data,
            ],
          },
        },
        {
          pageBreak: "before",
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 80, 80, 25, 230, 100, 60, 95],
            body:
              doc.sums.map(item => ([
                "",
                "",
                "",
                "",
                { text: ("รวม" + item.name).replace("รวมรวม", "รวม"), alignment: 'left' },
                { text: item.amount.formatFull(), alignment: 'right' },
                "",
                "",
              ])),
          },
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 180],
            heights: 50,
            body: [
              [
                "",
                (".").repeat(60) + "ลงชื่อ",
              ],
              [
                "",
                "        ชาญยุทธ คำหวาน       ",
              ],

            ]
          },
        },
      ],
    };
  }

  // "รายงานแบบที่ 2",
  async create02(doc: ReportA02Model, budgetYear: Number): Promise<any> {

    let data: Array<Array<any>> = [];
    doc.indicators.forEach((el, i) => {
      el.items.forEach((item, j) => {
        data.push([
          { text: i + 1, rowSpan: j == 0 ? el.items.length : 1 },
          { text: (el.name ?? ""), rowSpan: j == 0 ? el.items.length : 1 },
          { text: item.projectCode + " " + item.projectName },
          { text: item.budgetNumber + " " + item.budgetName },
          { text: item.followUp, alignment: 'center' },
          { text: item.responsible },
          { text: item.budgetAmount.formatDash() },
          { text: item.budgetTypeName, alignment: 'center' },
          { text: item.sectorName, alignment: 'center' },
        ])


      })
    });
    this.init = true;
    console.log("creating report A02");

    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [10, 20, 10, 20],
      styles: this._styles,
      defaultStyle: {
        alignment: 'center'
      },
      content: [
        {
          text: [
            { text: `การรายงานผลในแต่ละกิจกรรมที่สอดคล้องกับตัวชี้วัด หรือความเสี่ยง\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['auto', 100, '*', 60, 70, 70, 70, 70, 100],
            headerRows: 1,
            body: [
              [
                { text: 'ลำดับ', fillColor: "#DCE6F1" },
                { text: 'ตัวชี้วัด', fillColor: "#DCE6F1" },
                { text: 'รายการ', fillColor: "#DCE6F1" },
                { text: 'ข้อดำเนินงาน', fillColor: "#DCE6F1" },
                { text: 'ผลการดำเนินงาน', fillColor: "#DCE6F1" },
                { text: 'ผู้รับผิดชอบ', fillColor: "#DCE6F1" },
                { text: 'งบประมาณ', fillColor: "#DCE6F1" },
                { text: 'แหล่งงบประมาณ', fillColor: "#DCE6F1" },
                { text: 'ฝ่ายและสำนักที่รับผิดชอบ', fillColor: "#DCE6F1" },
              ],
              ...data,
            ]
          }
        },
      ],
    };
  }


  // "รายงานแบบที่ 3",
  async create03(docs: Array<any>, budgetYear: Number): Promise<any> {
    let data: Array<Array<any>> = [];
    docs.forEach((doc, i: number) => {
      data.push([
        { text: `${i + 1}` },
        { text: `${doc.smisCode}` },
        { text: `${doc.amount.formatFull()}` },
        { text: doc.createdAt.toThaiShort() },
        { text: doc.budget.code },
        { text: doc.project.code },
        { text: "-" },
        { text: doc.createdBy.sAMAccountName + "/" + doc.createdBy.fullName },
      ]);
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      content: [
        {
          text: [
            { text: `รายละเอียดการปรับรายการเบิก/ยืม\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableExample",
          alignment: "center",
          table: {
            widths: [20, 75, 75, 85, 85, 85, 160, 100],
            body: [
              [
                "ลำดับ",
                "เลขที่SMIS",
                "จำนวนเงิน",
                "วันที่ทำรายการ\r\n(วัน/เดือน/ปี)เป็น พ.ศ.",
                "รหัสข้อการดำเนินงาน\r\n(bdgltrmID)",
                "รหัสข้อการดำเนินงาน\r\n(bdgID)",
                "สาเหตุที่ดำเนินการ\r\nลบเลข SMIS",
                "ผู้ดำเนินการลบ",
              ],
              ...data,
            ],
          },
        },
        {
          text: "หมายเหตุ : กรณีลบแล้ว สามารถกู้เลข SMIS กลับคืนได้",
          margin: [0, 10, 0, 0],
        },
      ],
    };
  }
  // "รายงานแบบที่ 4",
  async create04(doc: ReportA04Model, budgetYear: Number): Promise<any> {
    let data: Array<Array<any>> = [];
    doc.items.forEach((el, i) => {
      data.push([
        { text: i + 1 },
        { text: el.number + ". " + el.name, alignment: 'left' },
        { text: el.responsible },
        { text: el.supportBudget.formatDash() },
        { text: el.investmentBudget.formatDash() },
        { text: el.source },
        { text: el.projectName, alignment: 'left' },
        { text: el.sectorName },
        { text: "" },
      ]);
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายละเอียดกิจกรรมและงบประมาณที่ต้องการจากแหล่งเงินและแผนการปฏิบัติงานฯ ที่ต่างกัน\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            // widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', '*', 'auto', 'auto'],
            widths: [20, 140, 40, 40, 40, 70, '*', 50, 40],
            headerRows: 2,
            body: [
              [{ text: 'ลำดับ', rowSpan: 2, fillColor: "#DCE6F1" },
              { text: 'กิจกรรม / ข้อการดำเนินงาน', rowSpan: 2, fillColor: "#DCE6F1" },
              { text: 'ผู้รับผิดชอบ', rowSpan: 2, fillColor: "#DCE6F1" },
              { text: 'งบประมาณ', colSpan: 2, fillColor: "#DCE6F1" }, '',
              { text: 'แหล่งงบประมาณ', rowSpan: 2, fillColor: "#DCE6F1" },
              { text: 'รายการที่', rowSpan: 2, fillColor: "#DCE6F1" },
              { text: 'ฝ่ายและสำนักที่รับผิดชอบ', rowSpan: 2, fillColor: "#DCE6F1" },
              { text: 'หมายเหตุ', rowSpan: 2, fillColor: "#DCE6F1" },
              ],
              ['', '', '', { text: 'งบอุดหนุน', fillColor: "#DCE6F1" }, { text: 'งบลงทุน', fillColor: "#DCE6F1" }, '', '', '', ''],
              ...data,
            ]
          }
        },
      ],
    };
  }

  // "รายงานแบบที่ 5",
  async create05(docs: Array<any>, doc: any, budgetYear: number): Promise<any> {
    let data: Array<Array<any>> = [];

    let groupProjectCode = _.sortBy(docs, 'projectName')
    groupProjectCode.forEach((el, i: number) => {
      data.push([
        i + 1,
        el.projectName,
        el.sectorName ?? "",
        el.budgetSource ?? "",
        (el.budgetSupport ?? 0).formatFull(),
        (el.budgetInvestment ?? 0).formatFull(),
        (el.actual ?? 0).formatFull(),
        (el.diff ?? 0).formatFull(),
        ""
      ])
    });
    this.init = true;
    console.log(data)
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายงานสรุปแผนและผลการเบิกจ่ายโดยแยกประเภทงบประมาณ และแหล่งงบประมาณ\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['*', 150, 'auto', 60, 70, 70, 70, 70, 100],
            headerRows: 2,
            body: [
              [{ text: 'ลำดับ', rowSpan: 2 },
              { text: 'รายการที่', rowSpan: 2 },
              { text: 'ฝ่ายและสำนัก\r\nที่รับผิดชอบ', rowSpan: 2 },
              { text: 'แหล่งงบประมาณ', rowSpan: 2 },
              { text: 'งบประมาณ', colSpan: 2 }, '',
              { text: 'ผลการเบิกจ่าย', rowSpan: 2 },
              { text: 'งบประมาณคงเหลือ', rowSpan: 2 },
              { text: 'หมายเหตุ', rowSpan: 2 },
              ],
              ['', '', '', '', 'งบอุดหนุน', 'งบลงทุน', '', '',],
              ...data,
              [
                { text: "", border: [false, false, false, false] },
                { text: "", border: [false, false, false, false] },
                { text: "", border: [false, false, false, false] },
                {
                  text: 'รวม', alignment: "center",
                  border: [false, false, false, false]
                },
                {
                  text: `${(doc.sumBudgetInvestment ?? 0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },

                {
                  text: `${(doc.sumBudgetSupport ?? 0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },

                {
                  text: `${(doc.sumActual ?? 0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },

                {
                  text: `${(doc.sumDiff ?? 0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                { text: "", border: [false, false, false, false] },
              ],
              [
                { text: "", border: [false, false, false, false] },
                { text: "", border: [false, false, false, false] },
                { text: "", border: [false, false, false, false] },
                {
                  text: 'รวมทั้งสิ้น', alignment: "center",
                  border: [false, false, false, false]
                },
                {
                  text: `${((doc.sumBudgetInvestment ?? 0) + (doc.sumBudgetSupport ?? 0)).formatFull()}`, alignment: "right",
                  border: [true, false, true, true],
                  colSpan: 2
                },
                { text: "", border: [false, false, false, false] },
                { text: "", border: [false, false, false, false] },
                { text: "", border: [false, false, false, false] },
                { text: "", border: [false, false, false, false] },
              ],
            ]
          }
        },
        // {
        //   margin: [307, 0, 0, 0],
        //   table: {
        //     widths: [60, 50, 51, 70, 70],
        //     body: [
        //       [
        //         {
        //           text: 'รวม', alignment: "center",
        //           border: [false, false, false, false]
        //         },

        //         {
        //           text: `${sum1}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },

        //         {
        //           text: `${sum2}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },

        //         {
        //           text: `${sum3}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },

        //         {
        //           text: `${sum4}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },
        //       ],
        //     ]
        //   },
        // },
        // {
        //   margin: [307, 0, 0, 0],
        //   table: {
        //     widths: [60, 109],
        //     body: [
        //       [
        //         {
        //           text: 'รวมทั้งสิ้น', alignment: "center",
        //           border: [false, false, false, false]
        //         },

        //         {
        //           text: `${sum5}`, alignment: "right",
        //           border: [true, false, true, true]
        //         },
        //       ],
        //     ]
        //   },
        // }
      ],
    };
  }
  async createA06(reportData: ReportA10Class, budgetYear: number): Promise<any> {
    this.init = true
    let data: Array<any> = []
    let types = () => {
      try {
        return reportData.projects![0].types;
      } catch (error) { }
    }
    let getProjectByType = (item: any, type: string) => {
      try {
        let found = item.sumTypes.find((el: any) => el.name === type);
        return found.amount;
      } catch (error) {
        return 0;
      }
    }
    let getActivityByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.projects.forEach((project: any) => {
          let found = getProjectByType(project, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getProductByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.activities.forEach((activity: any) => {
          let found = getActivityByType(activity, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getItemByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.products.forEach((product: any) => {
          let found = getProductByType(product, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let sumTotalInvestment = () => {
      try {
        return reportData.data.plans.map((product: any) => product.sumTotalInvestment).flat().reduce((a: number, b: number) => a + b, 0).formatComma()
      } catch (error) {
        return 0;
      }
    }
    let sumTotalCollection = () => {
      try {
        return reportData.data.plans.map((product: any) => product.sumTotalCollection).flat().reduce((a: number, b: number) => a + b, 0).formatComma()
      } catch (error) {
        return 0;
      }
    }

    reportData.data.plans!.forEach((item: any, i: number) => {
      data.push([
        { text: item.name, alignment: "left", style: "bold" },
        "",
        "",
        "",
        sumTotalCollection,
        sumTotalInvestment,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",

      ])
      item.products.forEach((pro: any) => {
        data.push([
          { text: "\t\t" + "ผลผลิต : " + pro.name, alignment: "left", style: "bold" },
          "",
          "",
          "",
          pro.sumTotalCollection.formatComma(),
          pro.sumTotalInvestment.formatComma(),
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ])
        pro.activities.forEach((act: any) => {
          data.push([
            { text: "\t\t\t\t" + "กิจกรรมหลัก : " + act.name, alignment: "left", style: "bold" },
            "",
            "",
            "",
            act.sumTotalCollection.formatComma(),
            act.sumTotalInvestment.formatComma(),
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ])
          act.projects.forEach((project: any, i: number) => {
            data.push([
              { text: `${i + 1}` },
              { text: "รายการ " + project.code + " " + project.name, alignment: "left" },
              { text: project.sector },
              { text: project.budgetSource },
              { text: project.budgetType == "งบอุดหนุน" ? project.total.formatComma() : "", aligment: "rigth" },
              { text: project.budgetType == "งบลงทุน" ? project.total.formatComma() : "", aligment: "rigth" },
              { text: project.months[0].formatComma(), aligment: "rigth" },
              { text: project.actuals[0].formatComma(), aligment: "rigth" },
              { text: project.months[1].formatComma(), aligment: "rigth" },
              { text: project.actuals[1].formatComma(), aligment: "rigth" },
              { text: project.months[2].formatComma(), aligment: "rigth" },
              { text: project.actuals[2].formatComma(), aligment: "rigth" },
              { text: project.monthsQ1.formatComma(), aligment: "rigth" },
              { text: project.actualsQ1.formatComma(), aligment: "rigth" },
              { text: project.months[3].formatComma(), aligment: "rigth" },
              { text: project.actuals[3].formatComma(), aligment: "rigth" },
              { text: project.months[4].formatComma(), aligment: "rigth" },
              { text: project.actuals[4].formatComma(), aligment: "rigth" },
              { text: project.months[5].formatComma(), aligment: "rigth" },
              { text: project.actuals[5].formatComma(), aligment: "rigth" },
              { text: project.monthsQ2.formatComma(), aligment: "rigth" },
              { text: project.actualsQ2.formatComma(), aligment: "rigth" },
              { text: project.months[6].formatComma(), aligment: "rigth" },
              { text: project.actuals[6].formatComma(), aligment: "rigth" },
              { text: project.months[7].formatComma(), aligment: "rigth" },
              { text: project.actuals[7].formatComma(), aligment: "rigth" },
              { text: project.months[8].formatComma(), aligment: "rigth" },
              { text: project.actuals[8].formatComma(), aligment: "rigth" },
              { text: project.monthsQ3.formatComma(), aligment: "rigth" },
              { text: project.actualsQ3.formatComma(), aligment: "rigth" },
              { text: project.months[9].formatComma(), aligment: "rigth" },
              { text: project.actuals[9].formatComma(), aligment: "rigth" },
              { text: project.months[10].formatComma(), aligment: "rigth" },
              { text: project.actuals[10].formatComma(), aligment: "rigth" },
              { text: project.months[11].formatComma(), aligment: "rigth" },
              { text: project.actuals[11].formatComma(), aligment: "rigth" },
              { text: project.monthsQ4.formatComma(), aligment: "rigth" },
              { text: project.actualsQ4.formatComma(), aligment: "rigth" },
              { text: project.monthsSum.formatComma(), aligment: "rigth" },
              { text: project.actualsSum.formatComma(), aligment: "rigth" },


              // {text:project.q1.formatComma().formatComma(),aligment:"rigth"},alignment:"right"}.formatComma(),aligment:"rigth"},
              // {text:project.q2.formatComma(),alignment:"right"},
              // {text:project.q3.formatComma(),alignment:"right"},
              // {text:project.q4.formatComma(),alignment:"right"},
              // {text:project.total.formatComma(),alignment:"right"},
              // ...types()!.map(typeStr=>({text:getProjectByType(project,typeStr)})),
              // project.page??"-",
            ])
          })
        })
      })
    })

    this._docDefinition = {
      pageSize: "A0",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 10],
      styles: this._styles,
      defaultStyle: {
        alignment: 'center'
      },
      content: [
        {
          text: [
            { text: `รายงานสรุปแผนและผลการเบิกจ่ายเป็นรายเดือน/รายไตรมาส/รายปี`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", }
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableExample",
          alignment: "center",
          table: {
            widths: [
              250, "*", 100, 100, 60, 60,
              60, 60, 60, 60, 60, 60, 60, 60, 60, 60,
              60, 60, 60, 60, 60, 60, 60, 60, 60, 60,
              60, 60, 60, 60, 60, 60, 60, 60, 60, 60,
              60, 60, 60, 60
            ],
            //40
            headerRows: 4,
            body: [
              [
                { text: 'ลำดับ', rowSpan: 4 },
                { text: 'ผลผลิต/กิจกรรม/รายการ', rowSpan: 4 },
                { text: 'ฝ่ายและสำนักที่รับผิดชอบ', rowSpan: 4 },
                { text: 'แหล่งงบประมาณ', rowSpan: 4 },
                { text: 'งบประมาณ', rowSpan: 2, colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'แผน/ผลการเบิกจ่าย', colSpan: 34 },
                ...new Array(33).fill({ text: "" }),
              ],
              [
                ...new Array(6).fill({ text: "" }),
                { text: 'ไตรมาสที่ 1', colSpan: 8 },
                ...new Array(7).fill({ text: "" }),
                { text: 'ไตรมาสที่ 2', colSpan: 8 },
                ...new Array(7).fill({ text: "" }),
                { text: 'ไตรมาสที่ 3', colSpan: 8 },
                ...new Array(7).fill({ text: "" }),
                { text: 'ไตรมาสที่ 4', colSpan: 8 },
                ...new Array(7).fill({ text: "" }),
                { text: 'รวมทั้งสิ้น', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
              ],
              [
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: 'งบอุดหนุน', rowSpan: 2 },
                { text: 'งบลงทุน', rowSpan: 2 },
                { text: 'ต.ค.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'พ.ย.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'ธ.ค.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'รวม', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'ม.ค.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'ก.พ.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'มี.ค.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'รวม', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'เม.ย.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'พ.ค.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'มิ.ย.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'รวม', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'ก.ค.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'ส.ค.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'ก.ย.', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'รวม', colSpan: 2 },
                ...new Array(1).fill({ text: "" }),
                { text: 'แผน', rowSpan: 2 },
                { text: 'ผล', rowSpan: 2 },
              ],
              [
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: 'แผน' },
                { text: 'ผล' },
                { text: '' },
                { text: '' },
              ],
              ...data
            ]
          },
        }]
    }
  }



  // "รายงานแบบที่ 6"
  async create06(doc: Array<any>, budgetYear: number): Promise<any> {
    let months = ["ต.ค.", "พ.ย.", "ธ.ค.", "รวม", "ม.ค.", "ก.พ.", "มี.ค.", "รวม", "เม.ย.", "พ.ค.", "มิ.ย.", "รวม", "ก.ค.", "ส.ค.", "ก.ย.", "รวม"]
    let quarter = ["ไตรมาสที่ 1", "ไตรมาสที่ 2", "ไตรมาสที่ 3", "ไตรมาสที่ 4"]
    let data: Array<Array<any>> = [];
    // doc.items.forEach((el: IReport06Item, i: number) => {
    //   data.push([
    //     { text: el.title, colSpan: 2, alignment: "left" }, '',
    //     '','',
    //     { text: el.subsidyBudget.to2DigitFormat(), alignment: "right" },
    //     { text: el.investmentBudget.to2DigitFormat(), alignment: "right" },
    //     ...el.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //     ...el.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //     ...el.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //     ...el.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //     { text: el.plan.to2DigitFormat(), alignment: "right" },
    //     { text: el.result.to2DigitFormat(), alignment: "right" },
    //   ]);
    //   el.products.forEach((product, j) => {
    //     data.push([
    //       { text: product.title, colSpan: 2, alignment: "left" ,decoration: "underline"}, '',
    //       '','',
    //       { text: product.subsidyBudget.to2DigitFormat(), alignment: "right" },
    //       { text: product.investmentBudget.to2DigitFormat(), alignment: "right" },
    //       ...product.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //       ...product.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //       ...product.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //       ...product.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //       { text: product.plan.to2DigitFormat(), alignment: "right" },
    //       { text: product.result.to2DigitFormat(), alignment: "right" },
    //     ])
    //     product.activities.forEach((activity, k) => {
    //       data.push([
    //         { text: activity.title, colSpan: 2, alignment: "left" }, '',
    //         '','',
    //         { text: activity.subsidyBudget.to2DigitFormat(), alignment: "right" },
    //         { text: activity.investmentBudget.to2DigitFormat(), alignment: "right" },
    //         ...activity.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //         ...activity.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //         ...activity.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //         ...activity.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //         { text: activity.plan.to2DigitFormat(), alignment: "right" },
    //         { text: activity.result.to2DigitFormat(), alignment: "right" },
    //       ]);
    //       activity.details.forEach((detail, l) => {
    //         data.push([
    //           l+1,
    //           { text: detail.title},
    //           { text: detail.sector},
    //           { text: detail.source},
    //           { text: detail.subsidyBudget.to2DigitFormat(), alignment: "right" },
    //           { text: detail.investmentBudget.to2DigitFormat(), alignment: "right" },
    //           ...detail.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //           ...detail.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //           ...detail.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //           ...detail.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
    //           { text: detail.plan.to2DigitFormat(), alignment: "right" },
    //           { text: detail.result.to2DigitFormat(), alignment: "right" },
    //         ]);
    //       })
    //     })
    //   });
    // });
    this.init = true;
    this._docDefinition = {
      pageSize: "A3",
      pageOrientation: "landscape",
      pageMargins: [5, 10, 5, 20],
      styles: this._styles,
      defaultStyle: {
        fontSize: 6,
      },
      content: [
        {
          text: [
            { text: `รายงานสรุปแผนและผลการเบิกจ่ายเป็นรายเดือน/รายไตรมาส/รายปี\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        // {
        //   style: 'tableExample',
        //   alignment: "center",
        //   margin: [0, 0, 0, 10],
        //   table: {
        //     widths: [...new Array(6).fill("auto"),...new Array(34).fill("*")],
        //     headerRows: 4,
        //     body: [
        //       [
        //         { text: 'ลำดับ', rowSpan: 4 },
        //         { text: 'ผลผลิต/กิจกรรม/รายการ', rowSpan: 4 },
        //         { text: 'ฝ่ายและสำนักที่รับผิดชอบ', rowSpan: 4 },
        //         { text: 'แหล่งงบประมาณ', rowSpan: 4 },
        //         { text: 'งบประมาณ', colSpan: 2, rowSpan: 2 },"",
        //         { text: 'แผน/ผลการเบิกจ่าย', colSpan: 34 },
        //         '','','','','',
        //         '','','','','',
        //         '','','','','',
        //         '','','','','',
        //         '','','','','',
        //         '','','','','',
        //         '','',''
        //       ],
        //       [
        //         '','','','','','',
        //         ...(_.flatten(quarter.map(d => { return [{ text: d, colSpan: 8, alignment: "center" },"","","","","","",""] }))),
        //         {text: "รวมทั้งสิ้น",colSpan:2},'',
        //       ],

        //       [
        //         '','','','',
        //         { text: 'งบอุดหนุน', rowSpan: 2 },
        //         { text: 'งบลงทุน', rowSpan: 2 },
        //         ...(_.flatten(months.map(d => { return [{ text: d, colSpan: 2, alignment: "center" },""] }))),
        //         { text: 'แผน', rowSpan: 2 },
        //         { text: 'ผล', rowSpan: 2 },
        //       ],
        //       [
        //         '','','','','','',
        //         ...new Array(32).fill(true).map((o,i)=>{ return i%2==0?"แผน":"ผล" }),
        //         '','',
        //       ],
        //       [
        //         { text: `รวมงบประมาณทั้งสิ้น`, colSpan: 2 },'',
        //         '','',
        //         { text: `${doc.investmentBudget.to2DigitFormat()}`,alignment:"right" },
        //         { text: `${doc.subsidyBudget.to2DigitFormat()}`,alignment:"right" },
        //         ...doc.quarter1.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
        //         ...doc.quarter2.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
        //         ...doc.quarter3.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
        //         ...doc.quarter4.map(d => { return [{ text: d.to2DigitFormat(), alignment: "right" }]} ),
        //         { text: `${doc.plan.to2DigitFormat()}`,alignment:"right" },
        //         { text: `${doc.result.to2DigitFormat()}`,alignment:"right" },
        //       ],
        //       ...data,
        //       ]
        //     }
        //   },
      ],
    };
  }

  // "รายงานแบบที่ 7",
  async create07(doc: ReportA07Model, budgetType: string, budgetYear: number): Promise<any> {
    console.log({ doc })
    let data: Array<any> = []
    data.push([
      '', { text: "รวมทั้งสิ้น", alignment: "left", colSpan: 2 }, '',
      (doc.budgetInvestment + doc.budgetSupport).formatFull(),
      doc.budgetInvestment.formatFull(),
      doc.budgetSupport.formatFull(),
      ((doc.budgetInvestment + doc.budgetSupport) / (doc.budgetInvestment + doc.budgetSupport)).formatFull(),
      doc.actual.formatFull(),
      (((doc.actual) / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatFull(),
      doc.diff.formatFull(),
      (((doc.diff) / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatFull(),
      (((doc.diff) / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatFull(),
    ]);
    doc.sectors.forEach(((sector, i) => {
      data.push([
        "",
        { text: sector.name, alignment: "left" },
        "",
        (sector.budgetInvestment + sector.budgetSupport).formatFull(),
        sector.budgetInvestment.formatFull(),
        sector.budgetSupport.formatFull(),
        (100 * (sector.budgetInvestment + sector.budgetSupport) / (doc.budgetInvestment + doc.budgetSupport)).formatFull(),
        sector.actual.formatFull(),
        ((sector.actual / (sector.budgetInvestment + sector.budgetSupport)) * 100).formatFull(),
        sector.diff.formatFull(),//คงเหลือ
        ((sector.diff / (sector.budgetInvestment + sector.budgetSupport)) * 100).formatFull(),
        ((sector.diff / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatFull(),
      ])
      sector.projects.forEach((project, j) => {
        data.push([
          j + 1,
          { text: project.projectName, alignment: "left" },
          project.budgetSource,
          (project.budgetInvestment + project.budgetSupport).formatFull(),
          project.budgetInvestment.formatFull(),
          project.budgetSupport.formatFull(),
          (((project.budgetInvestment + project.budgetSupport) / (sector.budgetInvestment + sector.budgetSupport)) * 100).formatFull(),
          project.actual.formatFull(),
          ((project.actual / (project.budgetInvestment + project.budgetSupport)) * 100).formatFull(),//ต้องทำ
          project.diff.formatFull(),//คงเหลือ
          ((project.diff / (project.budgetInvestment + project.budgetSupport)) * 100).formatFull(),//ต้องทำ
          ((project.diff / (doc.budgetInvestment + doc.budgetSupport)) * 100).formatComma()
        ])
      })
    }))
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [20, 20, 20, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายงานแผนเทียบกับผลการเบิกจ่ายโดยแยกเป็นรายฝ่ายและสำนักของ กกท. (มีร้อยละ) และสามารถเปรียบเทียบการแสดงผลระหว่างฝ่ายและสำนัก\r\n`, style: "bold", },
            { text: `แหล่งงบประมาณ : `, style: "bold", },
            { text: budgetType + `\r\n` },
            { text: `${(doc.budgetInvestment + doc.budgetSupport).formatFull()} บาท`, decoration: "underline" },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['auto', 155, 70, 60, 60, 60, 30, 50, 30, 'auto', 'auto', 'auto'],
            headerRows: 2,
            body: [
              [{ text: 'ลำดับ', rowSpan: 2 },
              { text: 'รายการที่', rowSpan: 2 },
              { text: 'แหล่งงบประมาณ', rowSpan: 2 },
              { text: 'งบประมาณ', colSpan: 4 },
                "",
                "",
                "",
              { text: 'ผลการเบิกจ่าย', colSpan: 2 },
                "",
              { text: 'งบประมาณคงเหลือ', rowSpan: 2 },
              { text: 'คงเหลือคิดเป็น\r\nร้อยละ', rowSpan: 2 },
              { text: 'ผลการเบิกจ่าย\r\nคิดเป็นร้อยละ\r\nของการรวม\r\n(งบประจำปี)', rowSpan: 2 },
              ],
              ["", "", "", 'รวม\r\n(อุดหนุน+ลงทุน)', 'งบอุดหนุน', 'งบลงทุน', 'ร้อยละ', 'งบประมาณ', 'ร้อยละ', "", "", ""],
              // [
              //   '',
              //   { text: 'รวมทั้งสิ้น', colSpan: 2 },
              //   '',
              //   doc.budget.to2DigitFormat(),
              //   doc.investmentBudget.to2DigitFormat(),
              //   doc.subsidyBudget.to2DigitFormat(),
              //   ((doc.budget / doc.annualBudget) * 100).to2Digit(),
              //   doc.disbursement.to2DigitFormat(),
              //   ((doc.disbursement / doc.budget) * 100).to2Digit(),
              //   doc.remainBudget.to2DigitFormat(),
              //   ((doc.remainBudget / doc.budget) * 100).to2Digit(),
              //   ((doc.disbursement / doc.annualBudget) * 100).to2Digit(),
              // ],
              ...data,
            ]
          }
        },
      ],
    };
  }

  // "รายงานที่ 8 แบบที่ 1",
  async create08Type1(doc: ReportA08Type1Model, budgetYear: Number): Promise<any> {
    let data: Array<Array<any>> = [];
    doc.items.forEach((el, i) => {
      console.log({ el })
      data.push([
        { text: i + 1 },
        { text: el.projectCode + " " + el.projectName, alignment: 'left' },
        { text: el.sectorName },
        { text: el.budgetSource },
        { text: el.bachelor.formatDash(), alignment: 'right' },
        { text: el.master.formatDash(), alignment: 'right' },
        { text: el.other.formatDash(), alignment: 'right' },
        { text: el.sumQty.formatDash(), alignment: 'right' },
        { text: el.bachelorAmount.formatFull(), alignment: 'right' },
        { text: el.masterAmount.formatFull(), alignment: 'right' },
        { text: el.otherAmount.formatFull(), alignment: 'right' },
        { text: el.sumAmount.formatFull(), alignment: 'right' },
        { text: el.otherObject.benefit.formatFull(), alignment: 'right' },
        { text: el.otherObject.specialLiving.formatFull(), alignment: 'right' },
        { text: el.otherObject.threeProvinceBanefit.formatFull(), alignment: 'right' },
        { text: el.otherObject.threeProvinceRisk.formatFull(), alignment: 'right' },
        { text: el.otherObject.other.formatFull(), alignment: 'right' },
        { text: el.otherObject.total.formatFull(), alignment: 'right' },
        { text: el.sumAmount.formatFull(), alignment: 'right' },
        ""
      ]);
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A3",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายงานค่าใช้จ่ายในประเภทต่างๆ ที่อยู่ในหน้าแผนฯ โดยสามารถรวมและแยกเป็นฝ่ายและสำนักต่างๆ\r\nการจ้างผู้ช่วยปฏิบัติงาน `, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: [10, 100, 60, 60, 30, 30, 30, 30, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50],
            headerRows: 2,
            body: [
              [
                { text: 'ลำดับ', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'รายการที่', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'ฝ่ายและสำนัก\r\nที่รับผิดชอบ', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'แหล่งงบ\r\nประมาณ', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'วุฒิการศึกษา (จำนวนคน)', colSpan: 4, fillColor: "#DCE6F1" },
                '',
                '',
                '',
                { text: 'จำนวนงบประมาณ', colSpan: 4, fillColor: "#DCE6F1" },
                '',
                '',
                '',
                { text: 'อื่นๆ', colSpan: 6, fillColor: "#DCE6F1" },
                '',
                '',
                '',
                '',
                '',
                { text: 'รวมงบ\r\nประมาณ\r\nทั้งสิ้น', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'หมายเหตุ', rowSpan: 2, fillColor: "#DCE6F1" },
              ],
              ['', '', '', '', { text: 'ปริญญา\r\nตรี', fillColor: "#DCE6F1" }, { text: 'ปริญญา\r\nโท', fillColor: "#DCE6F1" }, { text: 'อื่นๆ', fillColor: "#DCE6F1" }, { text: 'รวม', fillColor: "#DCE6F1" }, { text: 'ปริญญา\r\nตรี', fillColor: "#DCE6F1" }, { text: 'ปริญญา\r\nโท', fillColor: "#DCE6F1" }, { text: 'อื่นๆ', fillColor: "#DCE6F1" }, { text: 'รวม', fillColor: "#DCE6F1" }, { text: 'สวัสดิการ', fillColor: "#DCE6F1" }, { text: 'ค่าครอง\r\nชีพพิเศษ', fillColor: "#DCE6F1" }, { text: 'ค่า\r\nสวัสดิการ\r\nพื้นที่ \r\n3 จังหวัด\r\nชายแดน\r\nภาคใต้', fillColor: "#DCE6F1" }, { text: 'ค่าเบี้ย\r\nเสี่ยงภัย\r\n3 จังหวัด\r\nชายแดน\r\nภาคใต้', fillColor: "#DCE6F1" }, { text: 'อื่นๆ', fillColor: "#DCE6F1" }, { text: 'รวม', fillColor: "#DCE6F1" }, '', ''],
              ...data,
              [
                { text: '', border: [false, true, false, false] },
                { text: '', border: [false, true, false, false] },
                { text: '', border: [false, true, false, false] },
                { text: 'รวม', border: [false, true, true, false] },
                { alignment: "right", text: doc.sumBachelor.formatDash() },
                { alignment: "right", text: doc.sumMaster.formatDash() },
                { alignment: "right", text: doc.sumOther.formatDash() },
                { alignment: "right", text: doc.sumTotalQty.formatDash() },
                { alignment: "right", text: doc.sumBachelorAmount.formatFull() },
                { alignment: "right", text: doc.sumMasterAmount.formatFull() },
                { alignment: "right", text: doc.sumOtherAmount.formatFull() },
                { alignment: "right", text: doc.sumTotalAmount.formatFull() },
                { alignment: "right", text: doc.sumtotalInOtherBenefit.formatFull() },//1
                { alignment: "right", text: doc.sumtotalInOtherSpecialLiving.formatFull() },//2
                { alignment: "right", text: doc.sumtotalInOtherThreeProvinceBanefit.formatFull() },//3
                { alignment: "right", text: doc.sumtotalInOtherThreeProvinceRisk.formatFull() },//4
                { alignment: "right", text: doc.sumtotalInOtherOther.formatFull() },//5
                { alignment: "right", text: doc.sumtotalInOtherTotal.formatFull() },//6
                { alignment: "right", text: doc.sumFinalTotal.formatFull() },
                { text: '', border: [true, true, false, false] },
              ],
            ],

          }
        },
        {
          text: [
            { text: "หมายเหตุ", style: "bold", },
          ],

        },
        { text: "วุฒิ: หมายถึง คุณวุฒิที่จัดจ้างผู้ช่วยปฏิบัติงาน ฯ ในโครงการ \r\nปริญญาตรี,ปริญญาโท ประกอบด้วย (ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี , ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี (คนพิการ), ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี (อื่นๆ) )\r\nอื่นๆ ประกอบด้วย (ม.3, ม.6, ปวช., ปวส.,ป.ตรี เชี่ยวชาญ, ป.ตรี คอมพิวเตอร์ (เชี่ยวชาญ), ป.ตรี นิติกร (เชี่ยวชาญ), ป.ตรี คอมพิวเตอร์ (เชี่ยวชาญ), วุฒิอื่นๆที่ไม่ใช่ ป.ตรี และ ป.โท)", margin: [10, 0, 0, 10] },
        { text: "จํานวนงบประมาณ: หมายถึง จํานวนงบประมาณที่จัดจ้างผู้ช่วยปฏิบัติงานฯ ในโครงการ \r\nปริญญาตรี,ปริญญาโท ประกอบด้วย (ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี , ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี (คนพิการ), ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี (อื่นๆ) )\r\nอื่นๆ ประกอบด้วย (ม.3, ม.6, ปวช., ปวส.,ป.ตรี เชี่ยวชาญ, ป.ตรี คอมพิวเตอร์ (เชี่ยวชาญ), ป.ตรี นิติกร (เชี่ยวชาญ),ป.ตรี คอมพิวเตอร์ (เชี่ยวชาญ), วุฒิอื่นๆที่ไม่ใช่ ป.ตรี และ ป.โท)", margin: [10, 0, 0, 10] },
        { text: "สวัสดิการ : หมายถึง สวัสดิการของผู้ช่วยปฏิบัติงานฯ ในโครงการ \r\nปริญญาตรี,ปริญญาโท ประกอบด้วย (ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี , ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี (คนพิการ), ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี (อื่นๆ) ) \r\n อื่นๆ ประกอบด้วย (ม.3, ม.6, ปวช., ปวส.,ป.ตรี เชี่ยวชาญ, ป.ตรี คอมพิวเตอร์ (เชี่ยวชาญ), ป.ตรี นิติกร (เชี่ยวชาญ), ป.ตรี คอมพิวเตอร์ (เชี่ยวชาญ), วุฒิอื่นๆที่ไม่ใช่ ป.ตรี และ ป.โท) ", margin: [10, 0, 0, 10] },
        { text: " ค่าครองชีพพิเศษ/ค่าสวัสดิการพื้นที่ชายแดน 3 จังหวัดชายแดนภาคใต้/ค่าเบี้ยเสี่ยงภัย 3 จังหวัดชายแดนภาคใต้/(ค่าใช้จ่ายอื่นๆที่ไม่ใช่ค่าสวัสดิการ) : \r\nปริญญาตรี,ปริญญาโท ประกอบด้วย (ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี , ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี (คนพิการ), ผู้ช่วยปฏิบัติงานโครงการฯ ป.ตรี (อื่นๆ) ) \r\nอื่นๆ ประกอบด้วย (ม.3, ม.6, ปวช., ปวส.,ป.ตรี เชี่ยวชาญ, ป.ตรี คอมพิวเตอร์ (เชีี่ยวชาญ), ป.ตรี นิติกร (เชี่ยวชาญ), ป.ตรี คอมพิวเตอร์ (เชี่ยวชาญ), วุฒิอื่นๆที่ไม่ใช่ ป.ตรี และ ป.โท) ", margin: [10, 0, 0, 10] },
      ],
    };
  }
  // "รายงานที่ 8 แบบที่ 2",
  async create08Type2(doc: ReportA08Type2Model, budgetYear: Number ,expenseTypes: string): Promise<any> {
    let data: Array<Array<any>> = [];
    doc.items.forEach((el, i) => {
      data.push([
        { text: i + 1 },
        { text: el.projectCode + " " + el.projectName, alignment: 'left' },
        { text: el.sectorName },
        { text: el.budgetTypeName },
        { text: el.budgetNumber + " " + el.budgetName },
        { text: el.budgetGoals },
        { text: el.budgetBefore.formatFull(), alignment: 'right' },
        { text: el.budgetAfter.formatFull(), alignment: 'right' },
        { text: el.diff.formatFull(), alignment: 'right' },
      ]);
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายงานค่าใช้จ่ายในประเภทต่างๆ (${expenseTypes})\r\n`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: [20, "*", 60, 80, 80, 70, 60, 60, 60],
            headerRows: 2,
            body: [
              [
                { text: 'ลำดับ', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'รายการที่', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'ฝ่ายและสำนัก\r\nที่รับผิดชอบ', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'แหล่งงบประมาณ', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'ข้อการดำเนินงานหลัก/ย่อย', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'ผลผลิตและ\r\nค่าเป้าหมาย', rowSpan: 2, fillColor: "#DCE6F1" },
                { text: 'จำนวนงบประมาณ', colSpan: 2, fillColor: "#DCE6F1" },
                '',
                { text: 'ส่วนต่าง', rowSpan: 2, fillColor: "#DCE6F1" },
              ],
              ['', '', '', '', '', '', { text: 'แผนตั้งต้น', fillColor: "#DCE6F1" }, { text: 'แผนอนุมัติล่าสุด', fillColor: "#DCE6F1" }, ''],
              ...data,
              [
                { text: '', border: [false, true, false, false] }, { text: '', border: [false, true, false, false] }, { text: '', border: [false, true, false, false] }, { text: '', border: [false, true, false, false] }, { text: '', border: [false, true, false, false] }, { text: 'รวม', border: [false, true, true, false] }, { alignment: "right", text: doc.sumBefore.formatDash() }, { alignment: "right", text: doc.sumAfter.formatDash() }, { alignment: "right", text: doc.sumDiff.formatDash() }
              ]
            ]
          }
        },
      ],
    };
  }

  // "รายงานแบบที่ 8",
  async create08(docs: Array<any>, budgetYear: number = 2565): Promise<any> {
    let data: Array<any> = []
    let sum1 = 0
    let sum2 = 0
    let sum3 = 0
    let sum4 = 0
    let sum5 = 0
    let sum6 = 0
    let sum7 = 0
    let sum8 = 0
    docs.forEach((el: any, i: number) => {
      data.push([
        i + 1,
        { text: `${el.code} ${el.name}`, alignment: 'left' },
        `${el.sector}`,
        `${el.source}`,
        { text: `${(el.bachelor ?? 0).formatComma()}`, alignment: 'right' },
        { text: `${(el.master ?? 0).formatComma()}`, alignment: 'right' },
        { text: `${(el.other ?? 0).formatComma()}`, alignment: 'right' },
        { text: `${((el.bachelor ?? 0) + (el.master ?? 0) + (el.other ?? 0)).formatComma()}`, alignment: 'right' },
        { text: `${(el.bachelorAmount ?? 0).formatFull()}`, alignment: 'right' },
        { text: `${(el.masterAmount ?? 0).formatFull()}`, alignment: 'right' },
        { text: `${(el.otherAmount ?? 0).formatFull()}`, alignment: 'right' },
        { text: `${((el.bachelorAmount ?? 0) + (el.masterAmount ?? 0) + (el.otherAmount ?? 0)).formatFull()}`, alignment: 'right' },
        el.reason ?? ""
      ]);
      sum1 += el.bachelor
      sum2 += el.master
      sum3 += el.other
      sum4 += el.bachelor + el.master + el.other;
      sum5 += el.bachelorAmount
      sum6 += el.masterAmount
      sum7 += el.otherAmount
      sum8 += el.bachelorAmount + el.masterAmount + el.otherAmount;
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [20, 20, 20, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายงานค่าใช้จ่ายในประเภทต่างๆที่อยู่ในหน้าแผนฯ โดยสามารถรวมและแยกเป็นฝ่ายและสำนักต่างๆ`, style: "bold", },
            { text: `การจ้างผู้ช่วยปฏิบัติงานฯ`, style: "bold", },
            { text: `ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },

        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['auto', 150, 70, 70, 30, 30, 30, 30, 45, 45, 45, 45, 70],
            headerRows: 2,
            body: [
              [
                { text: 'ลำดับ', rowSpan: 2 },
                { text: 'รายการที่', rowSpan: 2 },
                { text: 'ฝ่ายและสำนักที่\r\nรับผิดชอบ', rowSpan: 2 },
                { text: 'แหล่งงบประมาณ', rowSpan: 2 },
                { text: 'วุฒิการศึกษา(จำนวนคน)', colSpan: 4 }, '', '', '',
                { text: 'จำนวนงบประมาณ', colSpan: 4 }, '', '', '',
                { text: 'หมายเหตุ', rowSpan: 2 },
              ],
              ['', '', '', '', 'ป.ตรี', 'ป.โท', 'อื่นๆ', 'รวม', 'ป.ตรี', 'ป.โท', 'อื่นๆ', 'รวม', ''],
              ...data,
              [
                {
                  text: '',
                  border: [false, false, false, false]
                },
                {
                  text: '',
                  border: [false, false, false, false]
                },
                {
                  text: '',
                  border: [false, false, false, false]
                },
                {
                  text: 'รวม', alignment: "center", colSpan: 2,
                  border: [false, false, false, false]
                },
                {
                  text: `${(sum1 ?? 0).formatComma()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum2 ?? 0).formatComma()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum3 ?? 0).formatComma()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum4 ?? 0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum5 ?? 0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum6 ?? 0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum7 ?? 0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: `${(sum8 ?? 0).formatFull()}`, alignment: "right",
                  border: [true, false, true, true]
                },
                {
                  text: '',
                  border: [false, false, false, false]
                },
              ]
            ]
          }
        },
      ],
    };
  }

  // "รายงานแบบที่ 9",
  async create09(docs: any): Promise<any> {
    let result: Array<any> = [];
    let ListSectors: Array<any> = [];
    let ListResponsibles: Array<any> = [];

    docs.data!.grandTotalArray.forEach((lv1: any) => {
      result.push(
        lv1
      )
    })
    const totalAmount = () => {
      try {
        let map = docs.data?.budgets
          .map((budget: any) =>
            budget.items.map((item: any) => item.amount).reduce((a: number, b: number) => a + b, 0)
          )
          .reduce((a: number, b: number) => a + b, 0);
        return map.formatComma();
      } catch (error) {

      }
    }
    const sortByNumberString = (budgets: Array<BudgetModel>) => {
      budgets = _.sortBy(budgets, el => {
        try {
          return parseFloat(el.number)
        } catch (error) {
          return 0
        }
      })
      return budgets
    }

    const resolveIndicator = (id: string) => {
      try {
        let arr = (docs.data?.budget ?? {}).indicators;
        arr = arr.map((a: any) => a.indicator.code);
        return arr;
      } catch (error) {
        return "-";
      }
    }
    const kpi6 = () => {
      try {
        let found = docs.kpi6 ?? [].map((a: any, i: number) => {

          return i + 1 + "." + a
        })
        console.log('found', found)
        return found
      } catch (error) {

      }
    }
    const kpi12 = () => {
      try {
        let found = docs.kpi12 ?? [].map((a: any, i: number) => {
          return i + 1 + "." + a
        })
        console.log('found', found)
        return found
      } catch (error) {

      }
    }
    let responsibles = []
    try {
      responsibles = docs.data.items.find((el: any) => el.code == "responsibles").value
    } catch (error) {

    }
    const sortKPI = (arr: string) => {
      try {
        const newArr: string[] = arr.split(", ");
        console.log(newArr)
        return _.sortBy(newArr)

      } catch (error) {
        "-"
      }
    }

    responsibles?.forEach((el: any) => {
      ListResponsibles.push([
        { text: `ผู้รับผิดชอบโครงการ: ${el.fullName} เบอร์โทร: ${el.telephoone ?? "-"}` },
      ])
    })
    let sectors = []
    try {
      sectors = docs.data.items.find((el: any) => el.code == "sectors").value
    } catch (error) {

    }
    console.log(sectors)
    sectors?.forEach((el: any, i: number) => {
      let position = ""
      switch (i) {
        case 0:
          position = "หัวหน้างาน"
          break;

        case 1:
          position = "ผู้อำนวยการกอง"
          break;

        case 2:
          position = "ผู้อำนวยการฝ่าย"
          break;
      }
      console.log(el)
      if (el.fullName != undefined) {

        ListSectors.push(
          [
            { text: `${el.position ?? "-"} : ${el.fullName}  email: ${el.email ?? "-"} เบอร์โทร: ${el.telephoone ?? "-"}` },
          ],
        )
      }

    })

    let totalBudget = docs.data?.budgets.map((budget: any) => budget.items.map((item: any) => item.amount).reduce((a: any, b: any) => a + b, 0)).reduce((a: any, b: any) => a + b, 0)
    console.log('budget', totalBudget);
    let data: Array<any> = [];
    let isMonthActive = (months: Array<any>, month: number): boolean => {
      try {

        let found = months.find((item: any) => item.month === month);
        return found.isActive
      } catch (error) {
        return false
      }
    }
    sortByNumberString((docs.data ?? { budgets: [] }).budgets).forEach((lv1: any, i: number) => {
      data.push([
        { text: lv1.number },
        { text: lv1.name + `(${lv1.code})`, alignment: 'left', preserveLeadingSpaces: true },
        { text: lv1.goals, alignment: 'left', preserveLeadingSpaces: true },
        { text: lv1.responsible.firstName, alignment: 'left' },
        { text: lv1.totalSupportAmountFormat, alignment: 'right', preserveLeadingSpaces: true },
        { text: lv1.totalInvestAmountFormat, alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat01 ?? 0, fillColor: isMonthActive(lv1.items, 0) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat02 ?? 0, fillColor: isMonthActive(lv1.items, 1) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat03 ?? 0, fillColor: isMonthActive(lv1.items, 2) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat04 ?? 0, fillColor: isMonthActive(lv1.items, 3) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat05 ?? 0, fillColor: isMonthActive(lv1.items, 4) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat06 ?? 0, fillColor: isMonthActive(lv1.items, 5) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat07 ?? 0, fillColor: isMonthActive(lv1.items, 6) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat08 ?? 0, fillColor: isMonthActive(lv1.items, 7) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat09 ?? 0, fillColor: isMonthActive(lv1.items, 8) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat10 ?? 0, fillColor: isMonthActive(lv1.items, 9) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat11 ?? 0, fillColor: isMonthActive(lv1.items, 10) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: (lv1 ?? {}).monthAmountFormat12 ?? 0, fillColor: isMonthActive(lv1.items, 11) ? '#CCCCCC' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
        { text: lv1.indicators.map((ind: any) => ind.indicator.code).join(", ") }
      ])
      sortByNumberString(lv1.children).forEach((lv2: any, j: number) => {
        data.push([
          { text: "" },
          { text: `\t${lv2.number} ${lv2.name} (${lv2.code})`, alignment: 'left', preserveLeadingSpaces: true },
          { text: lv2.goals, alignment: 'left', preserveLeadingSpaces: true },
          { text: lv2.responsible.firstName, alignment: 'left' },
          { text: lv2.totalSupportAmountFormat, alignment: 'right', preserveLeadingSpaces: true },
          { text: lv2.totalInvestAmountFormat, alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat01 ?? 0, fillColor: isMonthActive(lv2.items, 0) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat02 ?? 0, fillColor: isMonthActive(lv2.items, 1) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat03 ?? 0, fillColor: isMonthActive(lv2.items, 2) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat04 ?? 0, fillColor: isMonthActive(lv2.items, 3) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat05 ?? 0, fillColor: isMonthActive(lv2.items, 4) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat06 ?? 0, fillColor: isMonthActive(lv2.items, 5) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat07 ?? 0, fillColor: isMonthActive(lv2.items, 6) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat08 ?? 0, fillColor: isMonthActive(lv2.items, 7) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat09 ?? 0, fillColor: isMonthActive(lv2.items, 8) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat10 ?? 0, fillColor: isMonthActive(lv2.items, 9) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat11 ?? 0, fillColor: isMonthActive(lv2.items, 10) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: (lv2 ?? {}).monthAmountFormat12 ?? 0, fillColor: isMonthActive(lv2.items, 11) ? '#e4ecfc' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
          { text: lv2.indicators.map((ind: any) => ind.indicator.code).join(", ") }
        ])
        sortByNumberString(lv2.children).forEach((lv3: any, k: number) => {
          data.push([
            { text: "" },
            { text: `\t\t${lv3.number} ${lv3.name} (${lv3.code})`, alignment: 'left', preserveLeadingSpaces: true },
            { text: lv3.goals, alignment: 'left', preserveLeadingSpaces: true },
            { text: lv3.responsible.firstName, alignment: 'left' },
            { text: lv3.totalSupportAmountFormat, alignment: 'right', preserveLeadingSpaces: true },
            { text: lv3.totalInvestAmountFormat, alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat01 ?? 0, fillColor: isMonthActive(lv3.items, 0) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat02 ?? 0, fillColor: isMonthActive(lv3.items, 1) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat03 ?? 0, fillColor: isMonthActive(lv3.items, 2) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat04 ?? 0, fillColor: isMonthActive(lv3.items, 3) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat05 ?? 0, fillColor: isMonthActive(lv3.items, 4) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat06 ?? 0, fillColor: isMonthActive(lv3.items, 5) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat07 ?? 0, fillColor: isMonthActive(lv3.items, 6) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat08 ?? 0, fillColor: isMonthActive(lv3.items, 7) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat09 ?? 0, fillColor: isMonthActive(lv3.items, 8) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat10 ?? 0, fillColor: isMonthActive(lv3.items, 9) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat11 ?? 0, fillColor: isMonthActive(lv3.items, 10) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: (lv3 ?? {}).monthAmountFormat12 ?? 0, fillColor: isMonthActive(lv3.items, 11) ? '#f5f8f9' : '#ffffff', alignment: 'right', preserveLeadingSpaces: true },
            { text: lv3.indicators.map((ind: any) => ind.indicator.code).join(", ") }
          ])
        })
      })
    })
    let sum1 = 0;
    let sum2 = 0;
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [10, 100, 10, 20],
      styles: this._styles,
      defaultStyle: {
        fontSize: 10,
      },
      header: {

        stack: [
          { text: `แผนการปฎิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${docs.data?.budgetYear ?? new Date().getFullYear() + 543} \r\n`, style: "bold", },

          // {
          // text: {text:`รายการที่ ${docs.data?.fullName ?? ''}`, alignment: "left" },},
          {
            style: 'tableExample',
            alignment: "center",
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  { text: `รายการที่ ${docs.data?.fullName ?? ''}`, border: [false, false, false, false], alignment: "left" },
                  { text: `งบประมาณ ${totalBudget.formatComma()} บาท`, border: [false, false, false, false], alignment: "center" },
                  { text: "", border: [false, false, false, false], alignment: "left" },
                ],

              ]
            }
          },
          {
            style: 'tableExample',
            alignment: "center",

            table: {

              widths: [50, '*', 0],
              body: [

                [
                  { text: "KPI 6 เดือน", border: [false, false, false, false], alignment: "left" },
                  { text: kpi6().join(","), border: [false, false, false, false], alignment: "left" },
                  { text: "", border: [false, false, false, false], alignment: "left" },
                ],
                [
                  { text: "KPI 12 เดือน", border: [false, false, false, false], alignment: "left" },
                  { text: kpi12().join(","), border: [false, false, false, false], alignment: "left" },
                  { text: "", border: [false, false, false, false], alignment: "left" },
                ],
                [
                  { text: "ตัวชี้วัดที่เกี่ยวข้อง:", border: [false, false, false, false], alignment: "left", },
                  { text: `${sortKPI(docs.data.indicatorsText)}`, border: [false, false, false, false], alignment: "left" },
                  { text: "", border: [false, false, false, false], alignment: "left" },
                ],
              ]
            }
          },


        ],
        alignment: 'center', margin: [10, 10, 10, 10]



      },
      content: [

        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['auto', 60, 70, 35, 35, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, '*'],
            headerRows: 2,
            body: [
              [
                { text: 'ลำดับที่', rowSpan: 2 },
                { text: 'การดำเนินงานหลัก', rowSpan: 2 },
                { text: 'ผลผลิตและค่าเป้าหมาย', rowSpan: 2 },
                { text: 'ผู้รับผิดชอบ', rowSpan: 2 },
                { text: `${docs.data?.budgetSource}`, colSpan: 2 }, '',
                // { text: 'งบอุดหนุน', rowSpan: 2 },
                // { text: 'งบลงทุน', rowSpan: 2 },
                { text: `${docs.data!.budgetYear - 1}`, colSpan: 3 }, '', '',
                { text: `${docs.data!.budgetYear}`, colSpan: 9 }, '', '', '', '', '', '', '', '',
                { text: 'ความเชื่อมโยง', rowSpan: 2 },
              ],
              ['', '', '', '', 'งบอุดหนุน', 'งบลงทุน', 'ต.ค.', 'พ.ย.', 'ธ.ค.', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', ''],
              ...data,
              [
                { text: `รวมงบประมาณ${docs.data?.budgetSource}`, colSpan: 4, rowSpan: 2, alignment: "right" }, ``, ``, ``,
                { text: docs.data!.grandTotalSupportAmount, alignment: 'right', preserveLeadingSpaces: true },
                { text: docs.data!.grandTotalInvestAmount, alignment: 'right', preserveLeadingSpaces: true },
                ...result.map(rs => ({ text: (rs ?? 0).formatComma(), rowSpan: 2, alignment: 'right', preserveLeadingSpaces: true })),
                { text: "", rowSpan: 2 },
              ],
              [
                '', '', '', '',
                { text: totalAmount(), colSpan: 2, alignment: 'center', preserveLeadingSpaces: true },
                '', '', '', '', '', '', '', '', '', '', '', '', ''
              ]
            ]
          }
        },
        {
          text: [
            { text: ``, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 5, 0, 0],
        },
        ...ListResponsibles,
        ...ListSectors
      ],
    };
  }

  // "รายงานแบบที่ 10",
  async create10(reportData: ReportA10Class, budgetYear: number) {
    this.init = true;
    let data: Array<any> = []
    let types = () => {
      try {
        return reportData.projects![0].types;
      } catch (error) { }
    }
    let getProjectByType = (item: any, type: string) => {
      try {
        let found = item.sumTypes.find((el: any) => el.name === type);
        return found.amount;
      } catch (error) {
        return 0;
      }
    }
    let getActivityByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.projects.forEach((project: any) => {
          let found = getProjectByType(project, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getProductByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.activities.forEach((activity: any) => {
          let found = getActivityByType(activity, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getItemByType = (item: any, type: string) => {
      try {
        let sum = 0
        item.products.forEach((product: any) => {
          let found = getProductByType(product, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    let getTotal = (type: string) => {
      try {
        let sum = 0
        reportData.data.plans.forEach((item: any) => {
          let found = getItemByType(item, type)
          sum += found
        });
        return sum
      } catch (error) {
        console.log({ error })
        return 0;
      }
    }
    reportData.data.plans!.forEach((item: any, i: number) => {
      data.push([
        { text: item.name, alignment: "left", style: "bold" },
        "",
        { text: item.sumQ1.formatComma(), alignment: "right" },
        { text: item.sumQ2.formatComma(), alignment: "right" },
        { text: item.sumQ3.formatComma(), alignment: "right" },
        { text: item.sumQ4.formatComma(), alignment: "right" },
        { text: item.sumTotal.formatComma(), alignment: "right" },
        ...types()!.map(typeStr => ({ text: getItemByType(item, typeStr) })),
        "",
      ])
      item.products.forEach((pro: any) => {
        data.push([
          { text: "\t\t" + "ผลผลิต : " + pro.name, alignment: "left", style: "bold" },
          "",
          { text: pro.sumQ1.formatComma(), alignment: "right" },
          { text: pro.sumQ2.formatComma(), alignment: "right" },
          { text: pro.sumQ3.formatComma(), alignment: "right" },
          { text: pro.sumQ4.formatComma(), alignment: "right" },
          { text: pro.sumTotal.formatComma(), alignment: "right" },
          ...types()!.map(typeStr => ({ text: getProductByType(pro, typeStr) })),
          "",
        ])
        pro.activities.forEach((act: any) => {
          data.push([
            { text: "\t\t\t\t" + "กิจกรรมหลัก : " + act.name, alignment: "left", style: "bold" },
            "",
            { text: act.sumQ1.formatComma(), alignment: "right" },
            { text: act.sumQ2.formatComma(), alignment: "right" },
            { text: act.sumQ3.formatComma(), alignment: "right" },
            { text: act.sumQ4.formatComma(), alignment: "right" },
            { text: act.sumTotal.formatComma(), alignment: "right" },
            ...types()!.map(typeStr => ({ text: getActivityByType(act, typeStr) })),
            "",
          ])
          act.projects.forEach((project: any, i: number) => {
            data.push([
              { text: "รายการ " + project.code + " " + project.name, alignment: "left" },
              { text: project.sector },
              { text: project.q1.formatComma(), alignment: "right" },
              { text: project.q2.formatComma(), alignment: "right" },
              { text: project.q3.formatComma(), alignment: "right" },
              { text: project.q4.formatComma(), alignment: "right" },
              { text: project.total.formatComma(), alignment: "right" },
              ...types()!.map(typeStr => ({ text: getProjectByType(project, typeStr) })),
              project.page ?? "-",
            ])
          })
        })
      })
    })
    console.log('data', data);

    this._docDefinition = {
      pageSize: "A3",
      pageOrientation: "landscape",
      pageMargins: [20, 20, 20, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: ['*', 80, 50, 50, 50, 50, 70, ...new Array(types()?.length).fill(70), 30],
            body: [
              [
                { text: 'ผลผลิต / กิจกรรม / รายการ' },
                { text: 'ฝ่าย/สำนัก' },
                { text: 'ไตรมาส 1' },
                { text: 'ไตรมาส 2' },
                { text: 'ไตรมาส 3' },
                { text: 'ไตรมาส 4' },
                { text: 'รวมงบประมาณ\r\nได้รับจัดสรร' },
                ...types()!.map(item => ({ text: item })),
                { text: 'หน้า' },
              ],
              [
                { text: 'รวมงบประมาณทั้งสิ้น', alignment: "left" },
                "",
                { text: reportData.data.plans.map((el: any) => el.sumQ1 ?? 0).reduce((a: number, b: number) => a + b, 0).formatComma(), alignment: "right" },
                { text: reportData.data.plans.map((el: any) => el.sumQ2 ?? 0).reduce((a: number, b: number) => a + b, 0).formatComma(), alignment: "right" },
                { text: reportData.data.plans.map((el: any) => el.sumQ3 ?? 0).reduce((a: number, b: number) => a + b, 0).formatComma(), alignment: "right" },
                { text: reportData.data.plans.map((el: any) => el.sumQ4 ?? 0).reduce((a: number, b: number) => a + b, 0).formatComma(), alignment: "right" },
                { text: reportData.data.plans.map((el: any) => el.sumTotal ?? 0).reduce((a: number, b: number) => a + b, 0).formatComma(), alignment: "right" },
                ...types()!.map(item => ({ text: getTotal(item) })),
                ''
              ],
              ...data
            ]
          }
        },
      ],
    };
  }

  // "รายงานแบบที่ 11",
  async create11(docs: any, base64: string, base64s: string[], base64_1: string,): Promise<any> {
    if (docs.data.step < 4) base64 = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
    const sortByNumberString = (budgets: Array<BudgetModel>) => {
      budgets = _.sortBy(budgets, el => {
        try {
          return parseFloat(el.number)
        } catch (error) {
          return 0
        }
      })
      return budgets
    }
    let mm = (u: number) => {
      return u * 0.352777778;
    };
    // console.log('c7Signature',docs.data.c7Signature.split(".crdownload")[0]);

    this.init = true;
    let budgets: Array<any> = [];
    let ListSectors: Array<any> = [];
    let ListResponsibles: Array<any> = [];

    this.strategicBudgetYear.getStrategicFromBudgetYear(docs.data!.budgetYear)
    //   let url = "https://res.cloudinary.com/practicaldev/image/fetch/s--nh8zSFgY--/c_fill,f_auto,fl_progressive,h_320,q_auto,w_320/https://dev-to-uploads.s3.amazonaws.com/uploads/user/profile_image/285604/94170c66-2590-4002-90e0-ec4dc94ed7b5.png";
    //   async function c7Signature(url:any){
    //     try{
    //     // download the raw image file
    //     const raw = await axios.get(url, {
    //         responseType: "arraybuffer",
    //     })

    //     // create a base64 encoded string
    //     let base64 = Buffer.from(raw.data, "binary").toString("base64");

    //     // create image src
    //     let image = `data:${raw.headers["content-type"]};base64,${base64}`;

    //     // actual image 
    //     let imgFile = `<img src="${image}"/>`;
    //     }catch(e){
    //         console.log(e);
    //     }
    // }
    let getBase64 = (url: any) => {
      return axios
        .get(url, {
          responseType: 'arraybuffer'
        })
        .then(response => Buffer.from(response.data, 'binary').toString('base64'))
    }
    // function getBase64(url: any) {
    //   return axios
    //     .get(url, {
    //       responseType: 'arraybuffer'
    //     })
    //     .then(response => Buffer.from(response.data, 'binary').toString('base64'))
    // }
    //   async function image() {
    //     const fileData = "your base64 url data";
    //     const resp = await fetch(fileData);
    //     const fblob = await resp.blob();
    //     const file = new File([fblob], "file_name.jpg");   
    //     const formData = new FormData();
    //     formData.append("file", file);
    //     await axios.post("https://amymhaddad.s3.amazonaws.com/morocco-blue.png", formData, {
    //      headers: { "Content-Type": "multipart/form-data" },
    //     });
    //  }

    //ผลผลิต
    let result1: Array<any> = [];
    let strategics = JSON.parse(JSON.stringify(docs.data.items.filter((el: any) => el.code == "strategics").map((a: any) => a.value)))[0]
    // console.log('str',strategics);
    strategics?.forEach((el: any) => {
      this.strategicBudgetYear.strategies?.filter((a: any) => a.id == el).forEach((b: any) => {
        result1.push([
          { text: `${b.name}`, fontSize: 16 },
        ])
      })
    })
    //กลยุทธ์
    let result2: Array<any> = [];
    let tactics = JSON.parse(JSON.stringify(docs.data.items.filter((el: any) => el.code == "tactics").map((a: any) => a.value)))[0]
    tactics?.forEach((el: any) => {
      this.strategicBudgetYear.tactics?.filter((a: any, j: number) => a.id == el).forEach((b: any) => {
        result2.push([
          { text: `${b.name}`, fontSize: 16 },
        ])
      })
    })
    //แผนงาน
    let result3: Array<any> = [];
    let plans = JSON.parse(JSON.stringify(docs.data.items.filter((el: any) => el.code == "plans").map((a: any) => a.value)))[0]
    plans?.forEach((el: any) => {
      this.strategicBudgetYear.plans?.filter((a: any) => a.id == el).forEach((b: any) => {
        result3.push([
          { text: `${b.name}`, fontSize: 16 },
        ])
      })
    })
    //หลักการและเหตุผล
    let principles = docs.data.items.filter((el: any) => el.code == "principles").map((a: any) => a.value)
    let result9: Array<any> = [];
    // principles[0].forEach((el:any)=>{
    //   result9.push([{text:el, margin: [40, 0, 0, 0]}])
    // })
    //วัตถุประสงค์
    let result4: Array<any> = [];
    let objectives = docs.data.items.filter((el: any) => el.code == "objectives").map((a: any) => a.value)
    objectives.forEach((el: any) => {
      result4.push([{ text: el, margin: [40, 0, 0, 0], fontSize: 16 }])
    })
    //Goal
    let goals = docs.data.items.filter((el: any) => el.code == "goals").map((a: any) => a.value)
    //target
    let target = docs.data.items.filter((el: any) => el.code == "target").map((a: any) => a.value)
    let result10: Array<any> = [];
    // target[0].forEach((el:any)=>{
    //   result10.push([{text:el, margin: [40, 0, 0, 0]}])
    // })
    //kpi6
    let result5: Array<any> = [];
    let dataMapped: Map<string, string | string[]> = new Map(docs.data.items.map((d: any) => [d.code, d.value]))
    let kpi6 = ((dataMapped.get('kpi6') as string[]) ?? []).map((item: any) => {
      result5.push([{ text: item, margin: [40, 0, 0, 0], fontSize: 16 }])
    })
    // let kpi6 = docs.data.items.filter((el:any)=>el.code == "kpi6").map((a:any)=>a.value)
    // kpi6.forEach((el:any)=>{
    //   result5.push([{text:el , margin: [40, 0, 0, 0], fontSize: 16}])
    // })
    //kpi12
    let result6: Array<any> = [];
    let kpi12 = ((dataMapped.get('kpi12') as string[]) ?? []).map((item: any) => {
      result6.push([{ text: item, margin: [40, 0, 0, 0], fontSize: 16 }])
    })
    // let kpi12 = docs.data.items.filter((el:any)=>el.code == "kpi12").map((a:any)=>a.value)
    // kpi12.forEach((el:any)=>{
    //   result6.push([{text:el, margin: [40, 0, 0, 0], fontSize: 16}])
    // })
    //วิธีดำเนินการ
    let result7: Array<any> = [];
    let strategies = docs.data.items.filter((el: any) => el.code == "strategies").map((a: any) => a.value)
    strategies.forEach((el: any) => {
      result7.push([{ text: el, margin: [40, 0, 0, 0], fontSize: 16 }])
    })
    //ผลที่คาดว่าจะได้รับ
    let result8: Array<any> = [];
    let expected = docs.data.items.filter((el: any) => el.code == "expected").map((a: any) => a.value)
    // expected.forEach((el:any)=>{
    //   result8.push([{text:el, margin: [40, 0, 0, 0]}])
    // })
    //ผู้รับผิดชอบ
    let responsibles = []
    try {
      responsibles = docs.data.items.find((el: any) => el.code == "responsibles").value
    } catch (error) {

    }
    responsibles?.forEach((el: any, i: number) => {
      ListResponsibles.push([
        { text: `ผู้รับผิดชอบ: ${el.fullName} ${el.sectors ?? "-"} เบอร์โทร: ${el.telephoone ?? "-"}`, margin: [10, 0, 0, 0], fontSize: 16 },
      ],
        [
          {
            image: "data:image/png;base64," + base64s[i],
            height: 30,
            width: 100,
            margin: [40, 0, 0, 0]
          }
        ],
        [
          { text: "(ลงชื่อ" + (".").repeat(60) + ")", margin: [10, 0, 0, 0], fontSize: 16 },
        ])
    })
    let sectors = []
    let indicators: Array<any> = [];
    try {
      sectors = docs.data.items.find((el: any) => el.code == "sectors").value
    } catch (error) {

    }
    sectors?.forEach((el: any, i: number) => {
      if (el.signature == null || el.signature == undefined) {
        el.signature = ""
      }
      try {

        ListSectors.push(
          [
            { text: `${el.fullName} ${el.position ??= "-"} ${el.sector ?? "-"} email: ${el.email ?? "-"} เบอร์โทร: ${el.telephoone ?? "-"}`, margin: [10, 0, 0, 0], fontSize: 16 },
          ],
          [
            {
              image: "data:image/png;base64," + base64s[i],
              height: 30,
              width: 100,
              margin: [40, 0, 0, 0]

            }
          ],

          [
            { text: "(ลงชื่อ" + (".").repeat(60) + ")", margin: [10, 0, 0, 0], fontSize: 16 },
          ]
        )
      } catch (error) {

      }
    })
    // sectors?.forEach((el:any)=>{
    //   if(el.signature==null || el.signature==undefined){
    //     el.signature
    //   }
    //   ListSectors.push(
    //     [
    //       { text: `${el.fullName} ${el.position??="-"} ${el.sector ?? "-"} email: ${el.email ?? "-"} เบอร์โทร: ${el.telephoone ?? "-"}`, margin: [10, 0, 0, 0], fontSize: 16 },
    //     ],
    //     [
    //       {
    //         image: `${el.signature}`,
    //         fit: [50, 50],
    //         alignment: 'center'
    //       }
    //     ],
    //     [
    //       { text: "(ลงชื่อคคคค" + (".").repeat(60) + ")", margin: [10, 0, 0, 0], fontSize: 16 },
    //     ]
    //   )
    // })
    sortByNumberString(docs.data.budgets).forEach((lv1: any, i: number) => {

      budgets.push(
        [
          {
            text: `${lv1.number ?? ""}. ${lv1.operationType == "เตรียมการและบริหารจัดการ"
              ? "เตรียมการและบริหารจัดการ"
              : lv1.name ?? "-"
              }`, alignment: 'left', fontSize: 16
          },
          { text: `${lv1.items.map((el: any) => el.amount).reduce((a: number, b: number) => a + b, 0).formatFull()} บาท`, alignment: 'right', fontSize: 16 },
        ],
      )
      sortByNumberString(lv1.children).forEach((lv2: any, j: number) => {
        budgets.push(
          [
            { text: `${lv2.number ?? ""}. ${lv2.name ?? ""}`, margin: [10, 0, 0, 0], alignment: 'left', fontSize: 16 },
            { text: `${lv2.items.map((el: any) => el.amount).reduce((a: number, b: number) => a + b, 0).formatFull()} บาท`, margin: [10, 0, 0, 0], alignment: 'right', fontSize: 16 },
          ],
        )
        sortByNumberString(lv2.children).forEach((lv3: any, j: number) => {
          budgets.push(
            [
              { text: `${lv3.number ?? ""}. ${lv3.name ?? ""}`, margin: [10, 0, 0, 0], alignment: 'left', fontSize: 16 },
              { text: `${lv3.items.map((el: any) => el.amount).reduce((a: number, b: number) => a + b, 0).formatFull()} บาท`, margin: [10, 0, 0, 0], alignment: 'right', fontSize: 16 },
            ],
          )
        })
      })
    })

    docs.data.indicatorsResolved.forEach((el: any) => {
      indicators.push([{ text: el, fontSize: 16 }])
    })
    console.log('docs.data.indicatorsResolved', docs.data.indicatorsResolved.map((el: any) => el));

    this._docDefinition = {
      pageSize: "A4",
      pageMargins: [55, 80, 55, 150],
      styles: this._styles,
      content: [
        {
          // text: [{ text: `แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${docs.data!.budgetYear}`, style: "bold", fontSize: 18 },],
          text: [{ text: `แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${docs.data!.budgetYear}`, style: "bold", fontSize: 18 },],
          alignment: "center", margin: [0, 0, 0, 10],
        },
        {
          canvas: [
            {
              type: "line",
              x1: mm(140),
              y1: 5,
              x2: mm(1200),
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },
        {
          text: [{ text: `1. ยุทธศาสตร์ (Strategic)`, fontSize: 16 },],
          margin: [20, 20, 0, 0],
        },
        {
          layout: "noBorders",
          alignment: "left",
          margin: [40, 0, 0, 0],
          table: {
            widths: [390],
            body:
              [
                ...result1
              ]
          },
        },
        {
          text: [{ text: `2. กลยุทธ์ (กิจกรรมหลัก) กกท. ที่`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        {
          layout: "noBorders",
          alignment: "left",
          margin: [40, 0, 0, 0],
          table: {
            widths: [390],
            body:
              [
                ...result2
              ]
          },
        },
        {
          text: [{ text: `3. แผนงานหลัก (กิจกรรมย่อย) กกท. ที่`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        {
          layout: "noBorders",
          alignment: "left",
          margin: [40, 0, 0, 0],
          table: {
            widths: [390],
            body:
              [
                ...result3
              ]
          },
        },
        {
          text: [{ text: ` ตัวชี้วัดที่สำคัญ/ตัวชี้วัดที่เกี่ยวข้อง`, fontSize: 16 },],
          margin: [30, 0, 0, 0],
        },
        {
          layout: "noBorders",
          alignment: "left",
          margin: [40, 0, 0, 0],
          table: {
            widths: [390],
            body: [
              ...indicators
            ]
          },
        },
        {
          text: [{ text: `4. รายการที่ ${docs.data!.fullName ?? ""}`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        {
          text: [{ text: `5. หลักการและเหตุผล (Principle and Reason)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        { text: `\t${this.stripHTML(principles[0])}`, margin: [40, 0, 0, 0], fontSize: 16 },

        {
          text: [{ text: `6. วัตถุประสงค์ (Objective)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        { text: `\t${this.stripHTML(objectives[0])}`, margin: [40, 0, 0, 0], fontSize: 16 },

        {
          text: [{ text: `7. ความเชื่อมโยงกับยุทธาศาสตร์และแผนระดับชาติ (Project Goal)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        { text: `\t${this.stripHTML(goals[0])}`, margin: [40, 0, 0, 0], fontSize: 16 },
        {
          text: [{ text: `8. กลุ่มเป้าหมายของรายการ (Target Group)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        { text: `\t${this.stripHTML(target[0])}`, margin: [40, 0, 0, 0], fontSize: 16 },

        {
          text: [{ text: `9. ตัวชี้วัดและค่าเป้าหมาย 6 เดือน (Product & KPI)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        {
          layout: "noBorders",
          alignment: "left",
          table: {

            widths: [715],
            body:
              [
                ...result5
              ]
          },
        },
        {
          text: [{ text: `   ตัวชี้วัดและค่าเป้าหมาย 12 เดือน`, fontSize: 16 },],
          margin: [30, 0, 0, 0],
        },
        {
          layout: "noBorders",
          alignment: "left",
          table: {
            widths: [715],
            body:
              [
                ...result6
              ]
          },
        },
        {
          text: [{ text: `10. สถานที่ดำเนินการ (Location)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        { text: `\t${docs.data.items.filter((el: any) => el.code == "location").map((a: any) => a.value)}`, margin: [40, 0, 0, 0], fontSize: 16 },
        {
          text: [{ text: `11. ระยะเวลาดำเนินการ (Time period)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        { text: `\t${docs.data.items.filter((el: any) => el.code == "period").map((a: any) => a.value)}`, margin: [40, 0, 0, 0], fontSize: 16 },
        {
          text: [{ text: `12. วิธีดำเนินการ (Strategy)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        { text: `\t${this.stripHTML(strategies[0])}`, margin: [40, 0, 0, 0], fontSize: 16 },

        {
          text: [{ text: `13. แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ${docs.data.items.filter((el: any) => el.code == "budget").map((a: any) => a.value[0]).reduce((a: number, b: number) => a + b, 0).formatFull()} บาท`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        {

          style: "tableExample",
          layout: 'noBorders',
          alignment: "center",
          margin: [40, 0, 40, 0],
          table: {
            widths: ['*', 100],
            body: [
              ...budgets,
            ],
          },
        },
        {
          text: [{ text: `14. ผลที่คาดว่าจะได้รับ (Expected Benefit)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        { text: `\t${this.stripHTML(expected[0])}`, margin: [40, 0, 0, 0], fontSize: 16 },

        // {
        //   layout: "noBorders",
        //   alignment: "left",
        //   table: {
        //     widths: [715],
        //     body:
        //       [
        //         ...result8
        //       ]
        //   },
        // },
        {
          text: [{ text: `15. ผู้รับผิดชอบ (Responsible Person)`, fontSize: 16 },],
          margin: [20, 0, 0, 0],
        },
        {
          style: "tableExample",
          layout: 'noBorders',
          margin: [40, 0, 40, 0],
          table: {
            widths: ["*"],
            body: [
              ...ListResponsibles,
            ],
          },
        },
        {
          style: "tableExample",
          layout: 'noBorders',
          margin: [40, 0, 40, 0],
          table: {
            widths: ["*"],
            body: [
              ...ListSectors,
            ],
          },
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, 180],
            heights: 60,
            body:
              [
                [
                  "",
                  "",
                ],
                [
                  "",
                  "",
                ],

              ]
          },
        },

      ],
      footer: function (currentPage, pageCount) {
        let c7Signature
        if (docs.data.c7Signature == null || docs.data.c7Signature == undefined) {
          c7Signature = ''

        } else if (docs.data.step != 0) {
          c7Signature = {
            image: `${c7Signature}`,
            fit: [50, 50],
            alignment: 'center'
          }
        }
        else {
          c7Signature = {
            image: `${c7Signature}`,
            fit: [50, 50],
            alignment: 'center'
          }
          // c7Signature = ADDRESS+"/"+docs.data.c7Signature
        }
        let c7ApproveDate
        if (docs.data.c7ApproveDate == null || docs.data.c7ApproveDate == undefined || docs.data.c7ApproveDate == "") {
          c7ApproveDate = ''
        } else {
          c7ApproveDate = (docs.data.c7ApproveDate).toThaiShort()
        }
        console.log("okokok", base64)
        return {
          layout: "noBorders",
          alignment: "center",
          margin: [0, 0, 45, 1],
          table: {
            widths: ["*", 200],
            // margin: [100, 100, 100, 100],
            body:
              [
                ['',
                  { text: 'ตรวจแล้ว', alignment: 'center', fontSize: 16 }
                ],
                [
                  '',
                  {
                    image: "data:image/png;base64," + base64,
                    height: 30,
                    width: 100
                  },
                ],
                [
                  "",
                  (".").repeat(60),
                ],
                [
                  "",
                  { text: "        (หัวหน้างานพัฒนาและประสานแผน)       ", fontSize: 16 },
                ],
                [
                  "",
                  { text: c7ApproveDate, fontSize: 16 },
                ],
              ]
          }
        }
      },
    };
  }

  // "รายงานแบบที่ D301",
  async createD301(doc: any, reporter: string, sector: Array<any>): Promise<any> {
    this.init = true;
    let isFor = {}
    if (doc.data!.isFor) {
      isFor = {

        text: `     ${doc.data!.forPositionName} ปฏิบัติหน้าที่แทน    `,
        fontSize: 13,
      }
    }
    let mm = (u: number) => {
      return u * 0.352777778;
    };
    this._docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 40, 40, 90],

      styles: this._styles,
      images: {
        signature0: ADDRESS + "/" + sector[3].signature,//C7แผน
        signature1: ADDRESS + "/" + sector[4].signature,//C8แผน
        signature2: ADDRESS + "/" + sector[5].signature,//C9แผน
      },
      header: function (currentPage, pageCount) {
        if (currentPage === 2) {
          return [
            { text: '๒', alignment: "center", fontSize: 14, margin: [0, 15, 0, 0] },
          ]
        }
      },
      content: [
        {
          text: [{ text: `บันทึกข้อความ`, style: "bold", },
          ],
          fontSize: 24,
          alignment: "center",
          // margin: [0, 20, 0, 10],
        },
        {
          image: store.state.base64Logo,
          height: 55,
          width: 50,
          absolutePosition: { x: 40, y: 20 },
        },
        {
          text: [
            { text: "การกีฬาแห่งประเทศไทย ", style: "bold", fontSize: 16 },
            { text: `ฝ่ายนโยบายและแผน กองแผนงานและงบประมาณ โทรศัพท์ ${(doc.data!.telephone ?? "").formatThai()}`, fontSize: 14 }
          ]
        },
        {
          layout: "noBorders",
          // alignment: "center",
          table: {
            widths: [10, "*", 20, "*"],
            body:
              [
                [
                  { text: "ที่", style: "bold", fontSize: 13 },
                  { text: `ฝนผ ๕๑๐๘ / ${(doc.data!.documentNumber ?? "").formatThai()}`, fontSize: 13 },
                  { text: "วันที่", style: "bold", fontSize: 13 },
                  { text: `${new Date(doc.data!.documentDate).toThaiLong().formatThai()}`, fontSize: 13 },
                ],

              ]
          },
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, "*"],
            body:
              [
                [
                  { text: "เรื่อง", style: "bold", fontSize: 13 },
                  {
                    text: `ขออนุมัติปรับแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${(doc.data!.budgetYear ?? 0).toString().formatThai()}`, fontSize: 13,
                    alignment: "left",
                  }
                ],
                [
                  "",
                  {
                    text: `รายการที่ ${(doc.data!.projectName ?? "").formatThai()}`, fontSize: 13,
                    alignment: "left",
                  }
                ],
                [
                  "",
                  {
                    text: `(ปรับครั้งที่ ${(doc.data!.number ?? 0).toString().formatThai()})`, fontSize: 13,
                    alignment: "left",
                  }
                ],

              ]
          },
        },
        {
          canvas: [
            {
              type: "line",
              x1: mm(0),
              y1: 5,
              x2: mm(1500),
              y2: 5,
              lineWidth: 0.5,
            },
          ],
        },

        // {
        //   canvas: [
        //     {
        //       type: "line",
        //       x1: mm(0),
        //       y1: 2,
        //       x2: mm(1500),
        //       y2: 2,
        //       lineWidth: 0.5,
        //     },
        //   ],
        // },
        {
          layout: "noBorders",
          // alignment: "center",
          table: {
            widths: [20, "*"],
            body:
              [
                [
                  { text: "เรียน", style: "bold", fontSize: 13 },
                  {
                    text: `${doc.data!.to}`, fontSize: 13,
                    alignment: "left"
                  }
                ],
                [
                  // { text: "เรื่องเดิม", style: "bold", fontSize: 14 },
                  {
                    text: ``, fontSize: 14,
                    alignment: "left",
                  },
                  { text: "เรื่องเดิม", style: "bold", fontSize: 13, margin: [20, 0, 0, 0] },
                ],
                [
                  "",
                  {
                    text: `ตามที่ ${doc.data!.sectorName} ขออนุมัติปรับแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ. ${(doc.data!.budgetYear ?? 0).toString().formatThai()} รายการที่ ${(doc.data!.projectName ?? "").formatThai()} (ปรับครั้งที่ ${(doc.data!.number ?? 0).toString().formatThai()}) รายละเอียดตามแนบ นั้น`, fontSize: 13,
                    alignment: "left", margin: [20, 0, 0, 0]
                  }
                ],
              ]
          },
        },
        {
          text: [{ text: `การวิเคราะห์ของฝ่ายนโยบายและแผน`, style: "bold", },
          ],
          fontSize: 13,
          alignment: "left",
          margin: [45, 0, 0, 0],
        },
        {
          text: [{ text: `ฝ่ายนโยบายและแผนได้วิเคราะห์ผลกระทบด้านต่างๆ แล้ว ดัังนี้`, },
          ],
          fontSize: 13,
          alignment: "left",
          margin: [45, 0, 0, 0],
        },
        {
          alignment: "center",
          table: {
            widths: [30, 185, 120, "*"],
            body:
              [
                [
                  { text: "ลำดับ", fontSize: 13 },
                  { text: "หัวข้อผลกระทบ", fontSize: 13 },
                  { text: "ผลการวิเคราะห์ผลกระทบ", fontSize: 13 },
                  { text: "รายละเอียดที่ได้รับผลกระทบ\n(ถ้ามี)", fontSize: 13 },
                ],
                [
                  { text: "๑", fontSize: 13 },
                  { text: "ผลกระทบต่อ กฎหมาย/กฏ/ข้อบังคับ/ระเบียบ/นโยบาย และข้อกำหนดที่เกี่ยวข้อง", fontSize: 13, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisOne == "มี" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "มี", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ผลกระทบ", fontSize: 13 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisOne == "ไม่มี" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ไม่มี", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ผลกระทบ", fontSize: 13 }
                      ], alignment: "left"
                    },
                  ],
                  { text: doc.data!.analysisDetailOne.formatThai(), fontSize: 13, alignment: "left" },
                ],
                [
                  { text: "๒", fontSize: 13 },
                  { text: "ผลกระทบต่อยุทธศาสตร์และตัวชี้วัดระดับต่างๆ", fontSize: 13, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisTwo == "มี" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "มี", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ผลกระทบ", fontSize: 13 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisTwo == "ไม่มี" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ไม่มี", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ผลกระทบ", fontSize: 13 }
                      ], alignment: "left"
                    },
                  ],
                  { text: (doc.data!.analysisDetailTwo ?? "").formatThai(), fontSize: 13, alignment: "left" },
                ],
                [
                  { text: "๓", fontSize: 13 },
                  { text: "ผลกระทบต่อการบริหารความเสี่ยง", fontSize: 13, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisThree == "มี" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "มี", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ผลกระทบ", fontSize: 13 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisThree == "ไม่มี" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ไม่มี", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ผลกระทบ", fontSize: 13 }
                      ], alignment: "left"
                    },
                  ],
                  { text: (doc.data!.analysisDetailThree ?? "").formatThai(), fontSize: 13, alignment: "left" },
                ],
                [
                  { text: "๔", fontSize: 13 },
                  { text: "ผลกระทบต่อผลผลิตของรายการ/ผลผลิตและค่าเป้าหมายของการดำเนินงานหลัก", fontSize: 13, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFour == "มี" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "มี", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ผลกระทบ", fontSize: 13 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFour == "ไม่มี" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ไม่มี", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ผลกระทบ", fontSize: 13 }
                      ], alignment: "left"
                    },
                  ],
                  { text: (doc.data!.analysisDetailFour ?? "").formatThai(), fontSize: 13, alignment: "left" },
                ],
                [
                  { text: "๕", fontSize: 13 },
                  { text: "ผลกระทบต่อการใช้งบประมาณตามนโยบายรัฐบาล", fontSize: 13, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFive == "เพิ่ม" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เพิ่มขึ้น", decoration: 'underline', style: "bold", fontSize: 13 },
                        // { text: "กว่าแผน", fontSize: 14 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFive == "ลด" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ลดลง", decoration: 'underline', style: "bold", fontSize: 13 },
                        // { text: "กว่าแผน", fontSize: 14 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFive == "เป็นไปตามแผน" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เป็นไปตามแผน", style: "bold", fontSize: 13 },
                      ], alignment: "left"
                    },
                  ],
                  { text: (doc.data!.analysisDetailFive ?? "").formatThai(), fontSize: 13, alignment: "left" },
                ],
                [
                  { text: "๖", fontSize: 13 },
                  { text: "ผลกระทบต่อแผนการปฏิบัติงานของรายการ", fontSize: 13, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisSix == "เร็ว" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เร็ว", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "กว่าแผน", fontSize: 13 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisSix == "ช้า" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ช้า", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "กว่าแผน", fontSize: 13 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisSix == "เป็นไปตามแผน" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เป็น", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ไปตาทแผน", fontSize: 13 }
                      ], alignment: "left"
                    },
                  ],
                  { text: (doc.data!.analysisDetailSix ?? "").formatThai(), fontSize: 13, alignment: "left" },
                ],
                [
                  { text: "๗", fontSize: 13 },
                  { text: "ผลกระทบต่อแผนการใช้จ่ายงบประมาณ", fontSize: 13, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisSeven == "เร็ว" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เร็ว", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "กว่าแผน", fontSize: 13 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisSeven == "ช้า" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ช้า", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "กว่าแผน", fontSize: 13 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisSeven == "เป็นไปตามแผน" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เป็น", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "ไปตามแผน", fontSize: 13 }
                      ], alignment: "left"
                    },
                  ],
                  { text: (doc.data!.analysisDetailSeven ?? "").formatThai(), fontSize: 13, alignment: "left" },
                ],
                [
                  { text: "๘", fontSize: 13 },
                  { text: "การดำเนินงานโดยใช้เงินเหลือจ่ายจากการดำเนินงานตามวัตถุประสงค์และเป้าหมาย", fontSize: 13, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisEight == "ไม่" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ไม่", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "เป็นการใช้เงินเหลือจ่าย", fontSize: 13 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisEight == "เป็น" ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เป็น", decoration: 'underline', style: "bold", fontSize: 13 },
                        { text: "การใช้เงินเหลือจ่าย", fontSize: 13 }
                      ], alignment: "left"
                    },
                  ],
                  { text: (doc.data!.analysisDetailEight ?? "").formatThai(), fontSize: 13, alignment: "left" },
                ],

              ]
          },
        },
        // {
        //   pageBreak: "before",
        //   text: [{ text: `โดยมีรายละเอียดประกอบการพิจารณาตามเอกสารแบบเสนอปรับแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ ประจำปีงบประมาณ พ.ศ.${doc.data!.budgetYear} ที่แนบ จำนวน ๑ แผ่น` },
        //   ],
        //   fontSize: 14,
        //   margin: [80, 0, 0, 0],
        // },
        {
          pageBreak: "before",
          text: [{ text: `\t\t${(doc.data!.by ?? "").formatThai()}` },
          ],
          fontSize: 13,
          margin: [40, 0, 0, 0],
        },
        {
          text: [{ text: `ข้อเสนอ` },
          ],
          fontSize: 13,
          style: "bold",
          margin: [40, 10, 0, 0],
        },
        {
          // text: [{ text: `จึงเรียนมาเพื่อโปรดพิจารณาอนุมัติและลงนามในแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณประจำปีงบประมาณ พ.ศ. ประจำปีงบประมาณ พ.ศ.${(doc.data!.budgetYear??0).toString().formatThai()} รายการที่ ${(doc.data!.projectName??"").formatThai()} (ปรับครั้งที่ ${(doc.data!.number??0).toString().formatThai()})` },
          // ],
          text: [{ text: `${(doc.data!.suggestion ?? "").formatThai()}` }],
          fontSize: 13,
          margin: [40, 0, 0, 0],
        },
        // {
        //   pageBreak: "before",
        //   text: [{
        //     text:
        //       doc.data!.D3adjustType === "ปรับแผนฯ" ? `ข้อเสนอ` : `ระเบียบและกฎหมายที่เกี่ยวข้อง01`, style: "bold",
        //   },
        //   ],
        //   fontSize: 14,
        //   margin: [80, 20, 0, 0],
        // },
        // {
        //   // pageBreak: "before",
        //   text: [{ text: `${doc.data!.regulationsAndLaws}` },],
        //   fontSize: 14,
        // },
        // {
        //   pageBreak: "before",
        //   text: [{ text: `ประจำปีงบประมาณ พ.ศ. ${doc.data!.budgetYear} รายการที่ ${doc.data!.projectName} (ปรับครั้งที่ ${doc.data!.number})` },
        //   ],
        //   fontSize: 14,
        // },
        // {
        //   text: [{ text: `ข้อเสนอแนะ` },
        //   ],
        //   fontSize: 14,
        //   margin: [0, 10, 0, 0],
        // },
        // {
        //   text: [{ text: `${doc.data!.regulationsAndLaws}` },
        //   ],
        //   fontSize: 14,
        //   margin: [20, 0, 0, 0],
        // },

        {
          layout: "noBorders",
          alignment: "center",
          margin: [0, 100, 0, 0],
          table: {
            widths: [200, "*"],
            body:
              [
                [
                  "",
                  "(" + (".").repeat(60) + ")",
                ],
                [
                  "",
                  isFor,

                ],
                ["",
                  {
                    text: "        ผู้อำนวยการฝ่ายนโยบายและแผน       ",
                    fontSize: 13,
                  }]
              ]
          },
        },
      ],
      footer: function (currentPage, pageCount) {
        if (currentPage === 1) {
          return {
            columns: [
              {
                margin: [50, 0, 0, 0],
                table: {
                  widths: [160],
                  body:
                    [
                      [
                        {
                          image: 'signature1',
                          absolutePosition: { x: 110, y: 0 },
                          fit: [50, 50],
                          border: [true, true, true, false]
                        },
                      ],
                      [
                        { text: `ผู้อำนวยการกอง` + (".").repeat(30) + `${new Date(doc.data.directorDate).toThaiShort().formatThai()}`, alignment: "left", border: [true, false, true, false], fontSize: 12, },
                      ],
                      [
                        {
                          image: 'signature0',
                          absolutePosition: { x: 90, y: 25 },
                          fit: [50, 50],
                          border: [true, false, true, false]
                        },
                      ],
                      [
                        { text: `หัวหน้างาน` + (".").repeat(30) + `${new Date(doc.data.managerDate).toThaiShort().formatThai()}`, alignment: "left", border: [true, false, true, false], fontSize: 12, },
                      ],
                      [
                        { text: `ผู้ดำเนินการ` + `.....${reporter} ${new Date(doc.data.operatorDate).toThaiShort().formatThai()}......`, alignment: "left", border: [true, false, true, true], fontSize: 12, }

                      ],
                    ],
                },
              },
              { text: "/โดยมี...", alignment: "right", margin: [0, 50, 50, 0] },
            ]
          }
        }


      },
    }
  }

  // "รายงานแบบที่ D303",
  async createD303(doc: any): Promise<any> {
    console.log("D3")
    console.log(doc.data!)
    let data: Array<Array<any>> = [];
    doc.data!.budgets.forEach((el: any, i: number) => {

      let startMonth = null;
      let endMonth = null;
      let rangeBefore = ""

      for (let i = 0; i < (doc.findBudgetFromFlatted(el, 'before')).items.length; i++) {
        const month = (doc.findBudgetFromFlatted(el, 'before')).items[i];
        if (month.isActive) {
          if (startMonth === null) {
            startMonth = month.month;
          }
          endMonth = month.month;
        }
      }
      if (startMonth !== null && endMonth !== null) {
        rangeBefore = `${this.getMonth(startMonth)} - ${this.getMonth(endMonth)}`;
      } else {
        console.log("No active months found.");
      }
      let startMonthAfter = null;
      let endMonthAfter = null;
      let rangeAfter = ""

      for (let i = 0; i < (doc.findBudgetFromFlatted(el, 'after')).items.length; i++) {
        const month = (doc.findBudgetFromFlatted(el, 'after')).items[i];
        if (month.isActive) {
          if (startMonthAfter === null) {
            startMonthAfter = month.month;
          }
          endMonthAfter = month.month;
        }
      }
      if (startMonthAfter !== null && endMonthAfter !== null) {
        rangeAfter = `${this.getMonth(startMonthAfter)} - ${this.getMonth(endMonthAfter)}`;
      } else {
        console.log("No active months found.");
      }

      data.push([
        i + 1,
        {
          text: [
            { text: ` ${(doc.findBudgetFromFlatted(el, 'before')).number} ${(doc.findBudgetFromFlatted(el, 'before')).name}\n`, alignment: "left", fontSize: 11 },
            { text: ` ${doc.getBeforeLevel2(el.level1_code)?.map((el: any) => el.number ?? 0)} ${doc.getBeforeLevel2(el.level1_code)?.map((el: any) => el.name ?? 0)}\n`, alignment: "left", fontSize: 11 },
            { text: ` ${doc.getBeforeLevel3(el.level2_code, el.level3_code)?.map((el: any) => el.number ?? 0)} ${doc.getBeforeLevel3(el.level2_code, el.level3_code)?.map((el: any) => el.name ?? 0)}\n`, alignment: "left", fontSize: 11 },
            { text: `ผลผลิตและค่าเป้าหมาย`, decoration: 'underline', alignment: "left", fontSize: 11, }, { text: `: ${(doc.findBudgetFromFlatted(el, 'before')).goals} \n`, alignment: "left", fontSize: 11, },
            { text: `แผนการปฏิบัติงาน`, decoration: 'underline', alignment: "left", fontSize: 11, }, { text: `:${rangeBefore} ${doc.data!.budgetYear}\n`, alignment: "left", fontSize: 11, },
            { text: `แผนงบประมาณ`, decoration: 'underline', alignment: "left", fontSize: 11, }, { text: `: ${this.sumAmountBefore(doc, el, 'before')} บาท\n`, alignment: "left", fontSize: 11, },
            { text: `เดือน....พ.ศ...จำนวน...บาท`, alignment: "left", fontSize: 11, },
          ]
        },
        { text: `${this.sumAmountBefore(doc, el, 'before')} บาท\n`, alignment: "rigth", fontSize: 11, },
        {
          text: [
            { text: `${(doc.findBudgetFromFlatted(el, 'after')).number} ${(doc.findBudgetFromFlatted(el, 'after')).name}\n`, alignment: "left", fontSize: 11, },
            { text: `${doc.getBeforeLevel2(el.level1_code)?.map((el: any) => el.number ?? 0)} ${doc.getBeforeLevel2(el.level1_code)?.map((el: any) => el.name ?? 0)}\n`, alignment: "left", fontSize: 11, },
            { text: `${doc.getBeforeLevel3(el.level2_code, el.level3_code)?.map((el: any) => el.number ?? 0)} ${doc.getBeforeLevel3(el.level2_code, el.level3_code)?.map((el: any) => el.name ?? 0)}\n`, alignment: "left", fontSize: 11, },
            { text: `ผลผลิตและค่าเป้าหมาย`, decoration: 'underline', alignment: "left", fontSize: 11, }, { text: `: ${(doc.findBudgetFromFlatted(el, 'after')).goals} \n`, alignment: "left", fontSize: 11, },
            { text: `แผนการปฏิบัติงาน`, decoration: 'underline', alignment: "left", fontSize: 11, }, { text: `:${rangeAfter} ${doc.data!.budgetYear}\n`, alignment: "left", fontSize: 11, },
            { text: `แผนงบประมาณ`, decoration: 'underline', alignment: "left", fontSize: 11, }, { text: `: จำนวน ${this.sumAmountAfter(doc, el, 'after')} บาท\n`, alignment: "left", fontSize: 11, },
            { text: `เดือน....พ.ศ...จำนวน...บาท`, alignment: "left", fontSize: 11, },
          ]
        },
        { text: `${this.sumAmountAfter(doc, el, 'after')} บาท\n`, alignment: "rigth", fontSize: 11, },
        "",
      ])
    })
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [50, 80, 40, 10],
      styles: this._styles,
      header: {
        stack: [
          {
            text: [{ text: `แบบเสนอปรับ${doc.data!.name}` },
            ],
            fontSize: 14,
            alignment: "center",
            margin: [0, 10, 0, 0],

          },
          {
            text: [{ text: `รายการที่ ${doc.data!.projectName} ` },
            ],
            fontSize: 14,
            margin: [50, 10, 0, 0],
          },
          {
            text: [{ text: `ฝ่าย/สำนัก ${doc.data!.sectorName}` },
            ],
            fontSize: 14,
            margin: [50, 0, 0, 0],
          },
        ],
      },
      content: [
        {
          alignment: "center",
          table: {
            headerRows: 2,
            widths: [10, 200, 50, 200, 50, "*"],
            body:
              [
                [
                  { text: "ที่", rowSpan: 2, fontSize: 11, },
                  { text: "แผนเดิม", colSpan: 2, fontSize: 11, },
                  "",
                  { text: "แผนที่ปรับ", colSpan: 2, fontSize: 11, },
                  "",
                  { text: "เหตุผลการปรับแผน", rowSpan: 2, fontSize: 11, }
                ],
                [
                  "",
                  { text: "(ข้อ) การดำเนินงาน", fontSize: 11, },
                  { text: "จำนวนเงิน(บาท)", fontSize: 11, },
                  { text: "(ข้อ) การดำเนินงาน", fontSize: 11, },
                  { text: "จำนวนเงิน(บาท)", fontSize: 11, },
                  ""
                ],
                ...data,
                [
                  "", "รวมงบประมาณ", { text: `${doc.data!.sumBeforeFormatted}` }, "", { text: `${doc.data!.sumAfterFormatted}` }, ""
                ]
              ],
          },
        },
        {
          text: [{ text: `ความเห็นประกอบการพิจารณา`, decoration: 'underline' },
          ],
          fontSize: 14,
          margin: [0, 10, 0, 0],
        },
        {
          text: [{ text: `${doc.data!.opinion}` },
          ],
          fontSize: 14,
          margin: [0, 10, 0, 0],
        },
        {
          layout: "noBorders",
          alignment: "center",
          margin: [0, 50, 0, 0],
          table: {
            widths: [500, 180],
            body:
              [
                [
                  "",
                  "อนุมัติ",
                ],

              ]
          },
        },
        {
          layout: "noBorders",
          alignment: "center",
          margin: [0, 30, 0, 0],
          table: {
            widths: [500, 180],
            body:
              [
                [
                  "",
                  "(" + (".").repeat(70) + ")",
                ],
                [
                  "",
                  {
                    text: "        ผู้ว่าการ/รองผู้ว่าการฝ่าย  " + (".").repeat(30),
                    fontSize: 14,
                  },
                ],

              ]
          },
        },
      ]
    }
  }

  // "รายงานแบบที่ D302",
  async createD302(doc: any): Promise<any> {
    let mm = (u: number) => {
      return u * 0.352777778;
    };
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [{ text: `บันทึกข้อความ`, style: "bold", },
          ],
          fontSize: 24,
          alignment: "center",
          margin: [0, 20, 0, 10],
        },
        {
          text: [
            { text: "การกีฬาแห่งประเทสไทย ", style: "bold", fontSize: 16 },
            { text: "ฝ่ายนโยบายและแผน กองแผนงานและงบประมาณ โทรศัพท์ ๗๙๒๑ - ๗๙๒๔", fontSize: 14 }
          ]
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [10, 100, 120, 20, 100],
            body:
              [
                [
                  { text: "ที่", style: "bold", fontSize: 14 },
                  { text: `ฝนผ ๕๑๐๘ / ${doc.data!.documentNumber}`, fontSize: 14 },
                  "",
                  { text: "วันที่", fontSize: 14 },
                  { text: `${doc.data!.documentDate}`, fontSize: 14 },
                ],

              ]
          },
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, "*"],
            body:
              [
                [
                  { text: "เรื่อง", style: "bold", fontSize: 14 },
                  {
                    text: `ขออนุมัติรับแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ และการโอนเปลี่ยนแปลงงบประมาณ`, fontSize: 14,
                    alignment: "left",
                  }
                ],
                [
                  "",
                  {
                    text: `ประจำปีงบประมาณ พ.ศ. ${doc.data!.budgetYear} (โอนครั้งที่)`, fontSize: 14,
                    alignment: "left",
                  }
                ],

              ]
          },
        },
        {
          canvas: [
            {
              type: "line",
              x1: mm(0),
              y1: 2,
              x2: mm(1500),
              y2: 2,
              lineWidth: 0.5,
            },
          ],
        },
        {
          layout: "noBorders",
          alignment: "center",
          table: {
            widths: [20, "*"],
            body:
              [
                [
                  { text: "เรียน", style: "bold", fontSize: 14 },
                  {
                    text: `ผู้ว่าการการกีฬาแห่งประเทศไทย / รองผู้ว่าการฝ่าย ${doc.data!.to}`, fontSize: 14,
                    alignment: "left",
                  }
                ],
                [
                  "",
                  {
                    text: `ตามที่ ${doc.data!.sector} ขอดำเนินการ.....วงเงิน.....บาทนั้น`, fontSize: 14,
                    alignment: "left", margin: [20, 0, 0, 0]
                  }
                ],
              ]
          },
        },
        {
          text: [{ text: `การวิเคราะห์ของฝ่ายนโยบายและแผน`, style: "bold", },
          ],
          fontSize: 14,
          margin: [80, 0, 0, 0],
        },
        {
          alignment: "center",
          table: {
            widths: [30, 185, 120, "*"],
            body:
              [
                [
                  { text: "ลำดับ", fontSize: 14 },
                  { text: "หัวข้อผลกระทบ", fontSize: 14 },
                  { text: "ผลการวิเคราะห์ผลกระทบ", fontSize: 14 },
                  { text: "รายละเอียดที่ได้รับผลกระทบ\n(ถ้ามี)", fontSize: 14 },
                ],
                [
                  { text: "๑", fontSize: 14 },
                  { text: "ผลกระทบกฎหมาย/ข้อบังคับ/ระเบียบที่เกี่ยวข้อง", fontSize: 14, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisOne == true ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "มี", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "ผลกระทบ", fontSize: 14 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisOne == false ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ไม่มี", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "ผลกระทบ", fontSize: 14 }
                      ], alignment: "left"
                    },
                  ],
                  { text: doc.data!.analysisDetailOne, fontSize: 14 },
                ],
                [
                  { text: "๒", fontSize: 14 },
                  { text: "ผลกระทบยุทธศาสตร์และตัวชี้วัดระดับต่างๆ", fontSize: 14, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisTwo == true ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "มี", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "ผลกระทบ", fontSize: 14 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisTwo == false ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ไม่มี", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "ผลกระทบ", fontSize: 14 }
                      ], alignment: "left"
                    },
                  ],
                  { text: doc.data!.analysisDetailTwo, fontSize: 14 },
                ],
                [
                  { text: "๓", fontSize: 14 },
                  { text: "ผลกระทบการบริหารความเสี่ยง", fontSize: 14, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisThree == true ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "มี", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "ผลกระทบ", fontSize: 14 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisThree == false ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ไม่มี", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "ผลกระทบ", fontSize: 14 }
                      ], alignment: "left"
                    },
                  ],
                  { text: doc.data!.analysisDetailThree, fontSize: 14 },
                ],
                [
                  { text: "๔", fontSize: 14 },
                  { text: "ผลกระทบผลผลิตและค่าเป้าหมายรายการ", fontSize: 14, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFour == true ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "มี", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "ผลกระทบ", fontSize: 14 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFour == false ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ไม่มี", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "ผลกระทบ", fontSize: 14 }
                      ], alignment: "left"
                    },
                  ],
                  { text: doc.data!.analysisDetailFour, fontSize: 14 },
                ],
                [
                  { text: "๕", fontSize: 14 },
                  { text: "ผลกระทบการบริหารแผนการใช้จ่ายงบประมาณ", fontSize: 14, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFive == 1 ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เร็ว", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "กว่าแผน", fontSize: 14 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFive == 2 ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "ช้า", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "กว่าแผน", fontSize: 14 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisFive == 3 ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เป็นไปตามแผน", style: "bold", fontSize: 14 },
                      ], alignment: "left"
                    },
                  ],
                  { text: doc.data!.analysisDetailFive, fontSize: 14 },
                ],
                [
                  { text: "๖", fontSize: 14 },
                  { text: "การดำเนินงานโดยใช้เงินเหลือจ่ายจากการดำเนินงานตามวัตถุประสงค์และเป้าหมาย", fontSize: 14, alignment: "left" },
                  [
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisSix == true ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "มี", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "เป็นการใช้เงินเหลือจ่าย", fontSize: 14 }
                      ], alignment: "left"
                    },
                    {
                      text: [
                        { text: "(" },
                        { text: `${doc.data!.analysisSix == false ? "\uf00c" : " "}`, style: "fontawesome" },
                        { text: ") " },
                        { text: "เป็น", decoration: 'underline', style: "bold", fontSize: 14 },
                        { text: "การใช้เงินเหลือจ่าย", fontSize: 14 }
                      ], alignment: "left"
                    },
                  ],
                  { text: doc.data!.analysisDetailSix, fontSize: 14 },
                ],

              ]
          },
        },
        {
          text: [{ text: `รายละเอียดประกอบการพิจารณาตามเอกสารที่แนบ` },
          ],
          fontSize: 14,
          margin: [0, 10, 0, 0],
        },
        {
          alignment: "center",
          table: {
            widths: [100],
            body:
              [
                [
                  { text: `ผู้อำนวยการกอง` + (".").repeat(12) + '(ว/ด/ป)', alignment: "left", border: [true, true, true, false] },
                ],
                [
                  { text: `หัวหน้างาน` + (".").repeat(20) + '(ว/ด/ป)', alignment: "left", border: [true, false, true, false] },
                ],
                [
                  { text: `ผู้ดำเนินการ` + (".").repeat(19) + '(ว/ด/ป)', alignment: "left", border: [true, false, true, true] },
                ],

              ]
          },
        },
        {
          pageBreak: "before",
          text: [{
            text:
              doc.data!.D3adjustType === "ปรับแผนฯ" ? `ข้อเสนอ` : `ระเบียบและกฎหมายที่เกี่ยวข้อง`, style: "bold",
          },
          ],
          fontSize: 14,
          margin: [80, 20, 0, 0],
        },
        {
          text: [{ text: `${doc.data!.regulationsAndLaws}` },
          ],
          fontSize: 14,
        },
        {
          text: [{ text: `รายละเอียดตามเอกสารแนบ` },
          ],
          fontSize: 14, style: "bold",
          margin: [0, 10, 0, 0],
        },
        {
          layout: "noBorders",
          alignment: "center",
          margin: [0, 50, 0, 0],
          table: {
            widths: [250, 180],
            body:
              [
                [
                  "",
                  "(" + (".").repeat(60) + ")",
                ],
                [
                  "",
                  {
                    text: "        ผู้อำนวยการฝ่ายนโยบายและแผน       ",
                    fontSize: 14,
                  },
                ],

              ]
          },
        },
      ]
    }
  }

  // "รายงานแบบที่ D1",
  async createD1(doc: any, project: any, sector: Array<any>): Promise<any> {
    let data1: Array<any> = []
    let data2: Array<any> = []



    doc.data!.items.forEach((el: any, i: number) => {
      data1.push(
        [
          { text: i + 1, rowSpan: 3 },
          { text: `${(doc.findBudgetFromFlatted(el, 'before')).name}`, alignment: "left", },
          { text: `${(doc.findBudgetFromFlatted(el, 'after')).name}`, alignment: "left", },
          { text: `${el.nameRemark}`, alignment: "left" }
        ],
        [
          "",
          { text: `${(doc.findBudgetFromFlatted(el, 'before')).goals}`, alignment: "left", },
          { text: `${(doc.findBudgetFromFlatted(el, 'after')).goals}`, alignment: "left", },
          { text: `${el.goalsRemark}`, alignment: "left" }
        ],
        [
          "",
          // { text: `${(el.findBudgetFromFlatted(el, 'before')).previousBudgetPlan}`, alignment: "left", },
          // { text: `${(el.findBudgetFromFlatted(el, 'after')).adjustBudgetPlan}`, alignment: "left", },
          { text: `${el.previousBudgetPlan}`, alignment: "left" },
          { text: `${el.adjustBudgetPlan}`, alignment: "left" },
          { text: `${el.plansRemark}`, alignment: "left" }
        ],
        // [
        //   "",
        //   { text: ``, alignment: "left" },
        //   { text: ``, alignment: "left" },
        //   { text: `${el.plansRemark}`, alignment: "left" }
        // ],

      )
      console.log('el', data1);
    })
    let dataLength = doc.data!.items.length
    let addPlans: any[] = []
    if (Array.isArray(doc.data!.addPlans)) {
      addPlans = doc.data!.addPlans
    } else {
      try {
        addPlans = JSON.parse(doc.data!.addPlans)
      } catch (error) {
        addPlans = []
      }
    }
    addPlans.forEach((el: any, i: number) => {
      data1.push(
        [
          { text: dataLength + i + 1, rowSpan: 3 },
          { text: ``, alignment: "left", },
          { text: `${el.name}`, alignment: "left", },
          { text: `${el.note}`, alignment: "left" }
        ],
        [
          "",
          { text: ``, alignment: "left", },
          { text: `${el.plan}`, alignment: "left", },
          { text: ``, alignment: "left" }
        ],
        [
          "",
          { text: ``, alignment: "left", },
          { text: `${el.product}`, alignment: "left", },
          { text: ``, alignment: "left" }
        ],
      )
    })
    doc.data!.budgets.forEach((el: any, i: number) => {
      // this.Before(doc,el)
      // this.After(doc,el)
      let before: Array<any> = [];
      let after: Array<any> = [];
      console.log('dd', doc);

      doc.findBudgetFromFlatted(el, 'before')?.items.forEach((el: any) => {
        before.push([
          { text: this.getMonthYear(el.month, doc.data!.budgetYear), alignment: 'left' },
          { text: el.amount.formatFull(), alignment: 'right' },
          { text: " บาท" },
        ]
        )
      })
      doc.findBudgetFromFlatted(el, 'after')?.items.forEach((el: any) => {
        after.push([
          { text: this.getMonthYear(el.month, doc.data!.budgetYear), alignment: 'left' },
          { text: el.amount.formatFull(), alignment: 'right' },
          { text: " บาท" },
        ]
        )
      })

      data2.push(
        [
          { text: i + 1, rowSpan: 4 },
          { text: `ข้อการดำเนินงานหลักที่ ${(doc.findBudgetFromFlatted(el, 'before')).number} ${(doc.findBudgetFromFlatted(el, 'before')).name}`, alignment: "left", colSpan: 2 },
          "",
          {
            text: [
              {
                text: [
                  "[",
                  { text: `${el.adjustType == 'ไม่เปลี่ยนแปลง' ? "\uf00c" : " "}`, style: "fontawesome" },
                  "] ",
                  { text: "ไม่เปลี่ยนแปลง\r\n", style: "bold" }
                ]
              },
              {
                text: [
                  { text: `(เกลี่ยงบประมาณในข้อเดิม)\r\n` },
                ]
              },
              {
                text: [
                  "[",
                  { text: `${el.adjustType == 'ปรับเพิ่มงบประมาณ' ? "\uf00c" : " "}`, style: "fontawesome" },
                  "] ",
                  { text: "ปรับเพิ่มงบประมาณ\r\n", style: "bold" },
                  { text: `ปรับเพิ่มงบประมาณ\r\n จำนวน ${el.adjustType == 'ปรับเพิ่มงบประมาณ' ? Math.abs(doc.findBudgetFromFlatted(el, 'before').items.map((el: any) => el.amount).reduce((a: any, b: any) => a + b, 0) - doc.findBudgetFromFlatted(el, 'after').items.map((el: any) => el.amount).reduce((a: any, b: any) => a + b, 0)).formatFull() : ""} บาท\r\n` },
                  { text: el.description1 == undefined ? "" : el.description1 + "\r\n" }
                ], margin: [0, 10, 0, 0]
              },
              {
                text: [
                  "[",
                  { text: `${el.adjustType == 'ปรับลดงบประมาณ' ? "\uf00c" : " "}`, style: "fontawesome" },
                  "] ",
                  { text: "ปรับลดงบประมาณ\r\n", style: "bold" },
                  { text: `ปรับลดงบประมาณ\r\n จำนวน ${el.adjustType == 'ปรับลดงบประมาณ' ? Math.abs(doc.findBudgetFromFlatted(el, 'before').items.map((el: any) => el.amount).reduce((a: any, b: any) => a + b, 0) - doc.findBudgetFromFlatted(el, 'after').items.map((el: any) => el.amount).reduce((a: any, b: any) => a + b, 0)).formatFull() : ""} บาท\r\n` },
                  { text: el.description2 == undefined ? "" : el.description2 + "\r\n" }
                ], margin: [0, 10, 0, 0]
              },
            ],
            rowSpan: 4, alignment: "left"
          },
        ],
        // { text: `การดำเนินงานหลักที่ ${(doc.findBudgetFromFlatted(el, 'before')).number} ${(doc.findBudgetFromFlatted(el, 'before')).name}` },
        // { text: `การดำเนินงานรองที่ ${doc.getBeforeLevel2(el.level1_code)?.map((el: any) => el.number ?? 0)} ${doc.getBeforeLevel2(el.level1_code)?.map((el: any) => el.name ?? 0)}` },
        // { text: `การดำเนินงานย่อยที่ ${doc.getBeforeLevel3(el.level2_code, el.level3_code)?.map((el: any) => el.number ?? 0)} ${doc.getBeforeLevel3(el.level2_code, el.level3_code)?.map((el: any) => el.name ?? 0)}` },

        [
          "",
          { text: `${doc.getBeforeLevel2(el.level1_code) != undefined ? "ไม่ได้เปลี่ยนแปลงข้อการดำเนินงานรอง" : `ข้ออการดำเนินงานรองที่ ${doc.getBeforeLevel2(el.level1_code)?.map((j: any) => j.number ?? 0)} ${doc.getBeforeLevel2(el.level1_code)?.map((j: any) => j.name ?? 0)}`}`, colSpan: 2, alignment: "left" },
          "",
          "",
        ],
        [
          "",
          { text: `${doc.getBeforeLevel3(el.level1_code, el.level2_code) != undefined ? "ไม่ได้เปลี่ยนแปลงข้อการดำเนินงานย่อย" : `ข้ออการดำเนินงานย่อยที่ ${doc.getBeforeLevel3(el.level2_code, el.level3_code)?.map((el: any) => el.number ?? 0)} ${doc.getBeforeLevel3(el.level2_code, el.level3_code)?.map((el: any) => el.name ?? 0)}`}`, colSpan: 2, alignment: "left" },
          "",
          "",
        ],
        [
          "",
          {
            layout: "noBorders",
            table: {
              widths: [50, 50, 50],
              body:
                [
                  [
                    { text: 'แผนงบประมาณ (เดิม)', decoration: 'underline', alignment: 'center', colSpan: 3 },
                    "",
                    "",
                  ],
                  [
                    { text: "จำนวน ", style: "bold", alignment: 'left' },
                    { text: doc.findBudgetFromFlatted(el, 'before').items.map((el: any) => el.amount).reduce((a: any, b: any) => a + b, 0).formatFull(), alignment: 'right' },
                    { text: "บาท", style: "bold" },
                  ],
                  ...before,
                ]
            }
          },
          {
            layout: "noBorders",
            table: {
              widths: [50, 50, 50],
              body:
                [
                  [
                    { text: 'แผนงบประมาณ (ปรับ)', decoration: 'underline', alignment: 'center', colSpan: 3 },
                    "",
                    "",
                  ],
                  [
                    { text: "จำนวน ", style: "bold", alignment: 'left' },
                    { text: doc.findBudgetFromFlatted(el, 'after').items.map((el: any) => el.amount).reduce((a: any, b: any) => a + b, 0).formatFull(), alignment: 'right' },
                    { text: "บาท", style: "bold" },

                  ],
                  ...after,
                ]
            }
          },
          "",
        ],
        [
          "",
          {
            text: [
              { text: "เหตุผลในการปรับ\r\n", colSpan: 2, alignment: "left", style: "bold", decoration: 'underline' },
              { text: el.reason, alignment: "left" }
            ], colSpan: 2
          },
          "",
          "",

        ]
      )
    })
    let mm = (u: number) => {
      return u * 0.352777778;
    };

    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 20, 20, 80],
      styles: this._styles,
      images: {
        signature0: ADDRESS + "/" + sector[0].signature,
        signature1: ADDRESS + "/" + sector[1].signature,
        signature2: ADDRESS + "/" + sector[2].signature,
      },
      content: [
        {
          text: [
            { text: `แบบคำขอปรับแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ` },
          ],
          fontSize: 16,
          alignment: "center",
          style: "bold",
          margin: [0, 20, 0, 0],
        },
        {
          text: [
            { text: `ประจำปีงบประมาณ พ.ศ. ${doc.data!.budgetYear}` },
          ],
          fontSize: 16,
          alignment: "center",
          style: "bold",
          margin: [0, 0, 0, 10],
        },
        {
          canvas: [
            {
              type: "line",
              x1: mm(600),
              y1: 2,
              x2: mm(800),
              y2: 2,
              lineWidth: 1,
            },
          ],
        },
        {
          layout: "noBorders",
          table: {
            widths: [100, 200, 100],
            body:
              [
                [
                  { text: `ที่ ${doc.data!.location}`, style: "bold", fontSize: 14 },
                  "",
                  { text: `วันที่ ${new Date(doc.data!.requestDate).toThaiShort()}`, style: "bold", fontSize: 14 },
                ],

              ]
          },
        },
        {
          text: [
            { text: `เรียน ผู้ว่าการ (ผ่านฝ่ายนโยบายและแผน)` },
          ],
          fontSize: 14,
          style: "bold",
        },
        {
          text: [
            {
              text: `รายการที่ ${doc.data!.projectName}`,
              style: "bold",
            },
          ],
          fontSize: 14,
        },
        {
          text: [
            {
              text: `การขอปรับแผน ครั้งที่ ${doc.data!.number}`,
              style: "bold",
            },
          ],
          fontSize: 14,
        },
        {
          text: [
            { text: `1.เหตุผลการขอปรับแผน` },
          ],
          style: "bold",
          fontSize: 14,
        },
        {
          text: [
            { text: `${doc.data!.reason}` },
          ],
          fontSize: 14,
        },
        {
          text: [
            { text: `2. แผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณเปรียบเทียบแผนเดิมและแผนที่ขอปรับ` },
          ],
          fontSize: 14,
          style: "bold",
        },
        {
          text: [
            {
              text: `2.1 แผนการปฏิบัติงาน`,
            },
          ],
          fontSize: 14,
          style: "bold", margin: [20, 0, 0, 0],
        },
        {
          text: [
            { text: "[" },
            { text: `${doc.data!.isAdjust == false ? "\uf00c" : " "}`, style: "fontawesome" },
            "] ",
            {
              text: `ไม่มีการปรับแผนการปฏิบัติงาน (ให้ดำเนินการต่อในข้อ 2.2)`,
              margin: [20, 0, 0, 0],
            },
          ],
          fontSize: 14,
          margin: [40, 0, 0, 0]
        },
        {
          text: [
            { text: "[" },
            { text: `${doc.data!.isAdjust == true ? "\uf00c" : " "}`, style: "fontawesome" },
            "] ",
            {
              text: `มีการปรับแผนการปฏิบัติงาน ดังนี้`,
              margin: [20, 0, 0, 0],
            },
          ],
          fontSize: 14,
          margin: [40, 0, 0, 0]
        },
        {
          alignment: "center",
          margin: [0, 10, 0, 0],
          table: {
            widths: ["*", 200, 200, 50],
            body:
              [
                [
                  { text: "ที่", style: "bold" },
                  { text: "แผนการปฏิบัติงานเดิม", style: "bold" },
                  { text: "แผนการปฏิบัติงานที่ปรับ", style: "bold" },
                  { text: "หมายเหตุ", style: "bold" },
                ],
                ...data1
              ]
          },
        },
        {
          text: [
            {
              text: `2.2 แผนการใช้จ่ายงบประมาณ`,
            },
          ],
          fontSize: 14,
          style: "bold", margin: [20, 10, 0, 0],
          pageBreak: "before",
        },
        {
          text: [
            { text: "[" },
            { text: `${doc.data!.isPlan == false ? "\uf00c" : " "}`, style: "fontawesome" },
            "] ",
            {
              text: `ไม่มีการปรับแผนการใช้จ่ายงบประมาณ`,
              margin: [20, 0, 0, 0],
            },
          ],
          fontSize: 14,
          margin: [40, 0, 0, 0]
        },
        {
          text: [
            { text: "[" },
            { text: `${doc.data!.isPlan == true ? "\uf00c" : " "}`, style: "fontawesome" },
            "] ",
            {
              text: `มีการปรับแผนการใช้จ่ายงบประมาณ`,
              margin: [20, 0, 0, 0],
            },
          ],
          fontSize: 14,
          margin: [40, 0, 0, 0]
        },
        {
          text: [
            { text: "[" },
            { text: `${doc.data!.isType == false ? "\uf00c" : " "}`, style: "fontawesome" },
            "] ",
            {
              text: `ไม่เป็นการใช้งบประมาณเหลือจ่าย`,
              margin: [20, 0, 0, 0],
            },
          ],
          fontSize: 14,
          margin: [60, 0, 0, 0]
        },
        {
          text: [
            { text: "[" },
            { text: `${doc.data!.isType == true ? "\uf00c" : " "}`, style: "fontawesome" },
            "] ",
            {
              text: `เป็นการใช้งบประมาณเหลือจ่าย`,
              margin: [20, 0, 0, 0],
            },
          ],
          fontSize: 14,
          margin: [60, 0, 0, 0]
        },
        {
          alignment: "center",
          margin: [0, 10, 0, 0],
          table: {
            widths: [20, 180, 180, "*"],
            body:
              [
                [
                  { text: "ที่", style: "bold" },
                  { text: "แผนการใช้จ่ายงบประมาณ", colSpan: 2, style: "bold" },
                  "",
                  { text: "การปรับงบประมาณ", style: "bold" },
                ],
                ...data2
              ]
          },
        },
        {
          text: [
            { text: `3. กฎหมาย/กฎ/ข้อบังคับ/ระเบียบ/นโยบาย และข้อกำหนดที่เกี่ยวข้อง` },
          ],
          fontSize: 14,
          style: "bold",
          pageBreak: "before",
        },
        {
          text: [
            { text: `${doc.data!.legal}` },
          ],
          fontSize: 14,
          margin: [10, 0, 0, 0]
        },
        {
          text: [
            { text: `4. การวิเคราะห์ผลกระทบ` },
          ],
          fontSize: 14,
          style: "bold",
        },
        {
          text: [
            { text: "[" },
            { text: `${doc.data!.isEffect == false ? "\uf00c" : " "}`, style: "fontawesome" },
            "] ",
            {
              text: `ไม่มีผลกระทบ ซึ่งจะมีการบริหารจัดการให้เป็นไปตามแผนการปฏิบัติงานและแผนการใช้จ่ายงบประมาณ โดยไม่ส่งผลกระทบต่อการดำเนินงานและการใช้จ่ายงบประมาณในภายหลัง`,
              margin: [20, 0, 0, 0],
            },
          ],
          fontSize: 14,
          margin: [10, 0, 0, 0]
        },
        {
          text: [
            { text: "[" },
            { text: `${doc.data!.isEffect == true ? "\uf00c" : " "}`, style: "fontawesome" },
            "] ",
            {
              text: `มีผลกระทบ (ต่อตัวชี้วัดระดับต่างๆ การบริหารความเสี่ยง และอื่นๆ)`,
              margin: [20, 0, 0, 0],
            },
          ],
          fontSize: 14,
          margin: [10, 0, 0, 0]
        },
        {
          text: [
            { text: `(ระบุ)\r\n` },
            { text: `${doc.data!.reasonEffect}` },
          ],
          fontSize: 14,
          margin: [10, 0, 0, 0]
        },
        {
          text: [
            { text: `5. ข้อเสนอการขอปรับแผน` },
          ],
          fontSize: 14,
          style: "bold",
        },
        {
          text: [
            // { text: `(ระบุ)\r\n` },
            { text: `${doc.data!.adjustmentSuggestion}` },
          ],
          fontSize: 14,
          margin: [10, 0, 0, 0]
        },
        {
          layout: "noBorders",
          margin: [0, 30, 0, 0],
          alignment: "center",
          table: {
            widths: ['*', 200, '*'],
            body:
              [
                [
                  "",
                  {
                    image: 'signature0',
                    absolutePosition: { x: 20, y: 230 },
                    fit: [60, 60],
                    alignment: 'center'
                  },
                  "",
                ],
                [
                  "",
                  { text: `ลงชื่อ${(".").repeat(50)}ผู้รับผิดชอบ`, style: "bold", fontSize: 14 },
                  "",
                ],
                [
                  "",
                  { text: `(${sector[0].fullName})`, style: "bold", fontSize: 14 },
                  "",
                ],
                [
                  "",
                  { text: `ตำแหน่ง หัวหน้างาน ${sector[0].sector1}`, style: "bold", fontSize: 14 },
                  "",
                ],
                [
                  "",
                  { text: `วันที่${(".").repeat(50)}`, style: "bold", fontSize: 14 },
                  "",
                ],
              ]
          },
        },
        {
          layout: "noBorders",
          margin: [0, 30, 0, 0],
          alignment: "center",
          table: {
            widths: ['*', 200, '*'],
            body:
              [
                [
                  "",
                  {
                    image: 'signature1',
                    absolutePosition: { x: 20, y: 355 },
                    fit: [60, 60],
                    alignment: 'center'
                  },
                  "",
                ],
                [
                  "",
                  { text: `ลงชื่อ${(".").repeat(60)}`, style: "bold", fontSize: 14 },
                  "",
                ],
                [
                  "",
                  { text: `(${sector[1].fullName})`, style: "bold", fontSize: 14 },
                  "",
                ],
                [
                  "",
                  { text: `ตำแหน่ง ผู้อำนวยการกอง ${sector[1].sector1}`, style: "bold", fontSize: 14 },
                  "",
                ],
                [
                  "",
                  { text: `วันที่${(".").repeat(50)}`, style: "bold", fontSize: 14 },
                  "",
                ],

              ]
          },
        },
        {
          layout: "noBorders",
          margin: [0, 30, 0, 0],
          alignment: "center",
          table: {
            widths: ['*', 200, '*'],
            body:
              [
                [
                  "",
                  {
                    image: 'signature2',
                    absolutePosition: { x: 20, y: 480 },
                    fit: [60, 60],
                    alignment: 'center'
                  },
                  "",
                ],
                [
                  "",
                  { text: `ลงชื่อ${(".").repeat(60)}`, style: "bold", fontSize: 14 },
                  "",
                ],
                [
                  "",
                  { text: `(${sector[2].fullName})`, style: "bold", fontSize: 14 },
                  "",
                ],
                [
                  "",
                  { text: `ตำแหน่ง ผู้อำนวยการฝ่าย ${sector[2].sector1}`, style: "bold", fontSize: 14 },
                  "",
                ],
                [
                  "",
                  { text: `วันที่${(".").repeat(40)}`, style: "bold", fontSize: 14 },
                  "",
                ],

              ]
          },
        },
      ],
      footer: function (currentPage, pageCount) {
        if (currentPage === 1) {
          return {
            margin: [50, 10, 0, 0],
            table: {
              widths: [150],
              body:
                [
                  [
                    { text: `ผู้ดำเนินการ: ${(doc.data.creatorName)}`, alignment: 'left', border: [true, true, true, false] },
                  ],
                  [
                    { text: `เบอร์โทร: ${(doc.data.creatorTelephone)} `, alignment: 'left', border: [true, false, true, false] },
                  ],
                  [
                    { text: `Email:${(doc.data.creatorEmail)}`, alignment: 'left', border: [true, false, true, true] },
                  ],

                ]
            }
          }
        }
      },
    }
  }

  // "รายงานเข้า-ออกงาน",
  async attendant(doc: any, fromDate: Date, toDate: Date, sector1_name: string, sector2_name: string, sector3_name: string): Promise<any> {
    let data: Array<Array<any>> = [];
    console.log(doc)
    doc.docs!.forEach((el: any, i: number) => {
      data.push(
        [
          { text: `${i + 1}` },
          { text: `${el.intimeDate}` },
          { text: `${store.state.fullName}` },
          { text: `${store.state.position}` },
          { text: `${el.inTimeFormat}` },
          { text: `${el.outTimeFormat}` },
        ],
      )
      console.log('el', data);
    })
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [{ text: `บัญชีลงเวลาปฏิบัติงานของ ${store.state.position}`, style: "bold", },],
          alignment: "center",
          margin: [0, 0, 0, 5],
        },
        // {
        //   text: [{ text: `รายการที่ 207/01 โครงการการบริหารยุทธศาสตร์ทั่วทั้งองค์กรสู่รัฐวิสาหกิจชั้นนำ`, style: "bold", },],
        //   alignment: "center",
        //   margin: [0, 0, 0, 5],
        // },
        {
          text: [{ text: `ชื่อ - สกุล ${store.state.fullName} `, style: "bold", },],
          alignment: "center",
          margin: [0, 0, 0, 5],
        },
        {
          text: [{ text: `${sector3_name} ${sector2_name} ${sector1_name}`, style: "bold", },],
          alignment: "center",
          margin: [0, 0, 0, 5],
        },
        {
          // text: [{ text: `ประจำเดือน ${doc.fromDate}`, style: "bold", },],
          text: `${fromDate.toThaiShort()} - ${toDate.toThaiShort()}`,
          alignment: "center",
          margin: [0, 0, 0, 5],
        },
        {
          style: "tableExample",
          alignment: "center",
          table: {
            widths: [20, 50, 100, 180, 60, 60],
            body: [
              [
                "ลำดับ",
                "วันที่",
                "ชื่อ - สกุล",
                "ตำแหน่ง",
                "เข้างาน",
                "เลิกงาน",
              ],
              ...data,
            ],
          },
        },
        {
          layout: "noBorders",
          alignment: "center",
          margin: [250, 50, 0, 0],
          table: {
            widths: [20, 180],
            body:
              [
                [
                  "",
                  "        รับรองปฏิบัติงานจริง       ",
                ],
                [
                  "",
                  "ลงชื่อ" + (".").repeat(60),
                ],
                [
                  "",
                  "        (ผู้อำนวยการกองแผนงานและงบประมาณ)       ",
                ],

              ]
          },
        },
      ],
    }
  }

  // "รายงานที่ B04 ",
  async createB04(doc: ReportB04Model, budgetYear: Number): Promise<any> {
    let data: Array<Array<any>> = [];
    doc.items.forEach((el, i) => {
      console.log({ el })
      data.push([
        { text: i + 1 },
        { text: el.sectorName },
        { text: el.projectCode + " " + el.projectName, alignment: 'left' },
        { text: el.master ?? 0 },
        { text: el.bachelor ?? 0 },
        { text: el.professional ?? 0 },
        { text: el.highVocational ?? 0 },
        { text: el.vocational ?? 0 },
        { text: el.grade12 ?? 0 },
        { text: el.grade9 ?? 0 },
        { text: el.remark },
      ]);
    });
    this.init = true;
    this._docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 20, 40, 20],
      styles: this._styles,
      content: [
        {
          text: [
            { text: `รายงานจำนวนผู้ช่วยปฏิบัติงาน กกท. ประจำปีงบประมาณ พ.ศ. ${budgetYear}`, style: "bold", },
          ],
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: 'tableExample',
          alignment: "center",
          table: {
            widths: [20, 70, 250, 40, 40, 45, 40, 40, 40, 40, 45],
            headerRows: 1,
            body: [
              [
                { text: 'ลำดับ', fillColor: "#DCE6F1" },
                { text: 'ฝ่าย/สำนัก', fillColor: "#DCE6F1" },
                { text: 'รายการที่', fillColor: "#DCE6F1" },
                { text: 'ปริญญาโท', fillColor: "#DCE6F1" },
                { text: 'ปริญญาตรี', fillColor: "#DCE6F1" },
                { text: 'ปริญญาตรี \r\n (เชี่ยวชาญ)', fillColor: "#DCE6F1" },
                { text: 'ปวส.', fillColor: "#DCE6F1" },
                { text: 'ปวช.', fillColor: "#DCE6F1" },
                { text: 'ม.6', fillColor: "#DCE6F1" },
                { text: 'ม.3', fillColor: "#DCE6F1" },
                { text: 'หมายเหตุ', fillColor: "#DCE6F1" },
              ],
              ...data,
              [
                { text: '', border: [true, true, false, true] },
                { text: '', border: [false, true, false, true] },
                { text: 'รวมจำนวนผู้ช่วยปฏิบัติงาน', border: [false, true, true, true] },
                { text: doc.sumMaster ?? 0 },
                { text: doc.sumBachelor ?? 0 },
                { text: doc.sumProfessional ?? 0 },
                { text: doc.sumHighVocational ?? 0 },
                { text: doc.sumVocational ?? 0 },
                { text: doc.sumGrade12 ?? 0 },
                { text: doc.sumGrade9 ?? 0 },
                { text: '' },
              ]
            ]
          }
        },
      ],
    };
  }
  save(name: string) {
    let pdfDocGenerator = pdfMake
      .createPdf(this._docDefinition, undefined, this._fonts)
      .download(this.init ? name : "error.txt");
  }
}

