import { PaginateResponse } from "@/common";
import AdjustmentModel, { AdjustmentBudgetModel, AdjustmentItemModel, AdjustmentAttachmentModel } from "@/model/adjustment";
import BudgetModel, { BudgetItemModel } from "@/model/budget";
import AdjustmentServices from "@/service/adjustment";
import SettingServices from "@/service/system";
import { Service } from "typedi";
import { confirmAction } from "@/decorator";

@Service()
export class ActiveAdjustmentSingleClass {
  data?: AdjustmentModel;
  // _data: AdjustmentModel = new AdjustmentModel({})
  before?: Array<BudgetModel>;
  after?: Array<BudgetModel>;
  loading: boolean = true;
  refreshing: boolean = false;
  stack: AdjustmentModel[] = [];
  // get data(): AdjustmentModel|undefined {
  //   return this._data;
  // }

  // set data(value: AdjustmentModel|undefined) {
  //   this._data = value;
  //   this.stack.push(this._data!)
  //   console.log(`myProperty was set to ${value}`);
  // }
  findBudgetFromFlatted(item: AdjustmentBudgetModel | AdjustmentItemModel, type: string) {
    let bool1 = item.level1_code === ''
    let bool2 = item.level2_code === ''
    let bool3 = item.level3_code === ''
    try {
      let array: Array<any> = this.flattedBefore
      if (type === 'after') array = this.flattedAfter

      if (bool1 === false && bool2 === true && bool3 === true) {
        let found = array.find(element => element.code === item.level1_code) ?? {}
        return found
      }
      else if (bool1 === false && bool2 === false && bool3 === true) {
        let found = array.find(element => element.code === item.level2_code) ?? {}
        return found
      }
      else {
        let found = array.find(element => element.code == item.level3_code) ?? {}
        console.log(array)
        console.log(found)
        console.log("found")
        console.log(item.level3_code)
        return found


      }


      // if (item.level1_code !=undefined)
      //   return array.find(element => element.code === item.level1_code)
      // if (item.level2_code != '' && item.level3_code == '')
      //   return array.find(element => element.code === item.level2_code)
      // else if (item.level3_code != '')
      //   return array.find(element => element.code === item.level3_code)



    } catch (error) {
      return {}
    }
  }
  get flattedAfter() {
    try {
      let results: Array<BudgetModel> = []
      this.after?.forEach(item => {
        let prep: any = Object.assign({}, item)
        delete prep.children
        results.push(prep)
        item.children!.forEach(child => {
          let prep: any = Object.assign({}, child)
          delete prep.children
          results.push(prep)
          child.children!.forEach(yoy => {
            let prep: any = Object.assign({}, yoy)
            delete prep.children
            results.push(prep)
          })
        })
      })
      return results
    } catch (error) {
      console.log(error)
      return []
    }
  }
  get flattedBefore() {
    try {
      let results: Array<BudgetModel> = []
      this.before?.forEach(item => {
        let prep: any = Object.assign({}, item)
        delete prep.children
        results.push(prep)
        item.children!.forEach(child => {
          let prep: any = Object.assign({}, child)
          delete prep.children
          results.push(prep)
          child.children!.forEach(yoy => {
            let prep: any = Object.assign({}, yoy)
            delete prep.children
            results.push(prep)
          })
        })
      })
      return results
    } catch (error) {
      console.log(error)
      return []
    }
  }
  get sumBefore(): number {
    let budgets = this.data!.budgets ?? []
    let sum = 0
    budgets.forEach(item => {
      try {
        let before = this.findBudgetFromFlatted(item, 'before')
        let { items } = before
        let amountMapped: Array<number> = items.map((item: BudgetItemModel) => item.amount ?? 0)
        let amountReduced = amountMapped.reduce((a, b) => a + b, 0)
        sum += amountReduced
      } catch (error) {

      }
    });
    return sum
  }
  get sumBeforeFormatted() {
    try {
      return this.sumBefore.formatComma()
    } catch (error) {
      return 0
    }
  }
  get sumAfter(): number {
    let budgets = this.data!.budgets ?? []
    let sum = 0
    budgets.forEach(item => {
      try {
        let before = this.findBudgetFromFlatted(item, 'after')
        let { items } = before
        let amountMapped: Array<number> = items.map((item: BudgetItemModel) => item.amount ?? 0)
        let amountReduced = amountMapped.reduce((a, b) => a + b, 0)
        sum += amountReduced
      } catch (error) {

      }
    });
    return sum
  }
  get sumAfterFormatted() {
    try {
      return this.sumAfter.formatComma()
    } catch (error) {
      return 0
    }
  }

  getBeforeLevel1(code_level1: string) {
    console.log('this.before', this.before?.filter(item => item.code === code_level1));
    try {
      let item = this.before?.filter(item => item.code === code_level1);
      console.log(item);
      return item
    } catch (error) {
      return []
    }

    
  }
  getBeforeLevel2(code_level1: string) {
    console.log('this.before', this.before?.filter(item => item.code === code_level1));
    try {
      let children = this.before?.find(item => item.code === code_level1)!.children
      if (children === undefined) return []
      else return children
    } catch (error) {
      return []
    }
  }
  
  getBeforeLevel2ForDocument(code_level1: string,code_level2:string) {
    
    try {
      let children = this.before?.find(item => item.code === code_level1)!.children
      let code2 = children!.find(item => item.code === code_level2)
      console.log('this.code2', code2);
      if (children === undefined) return []
      else return code2
    } catch (error) {
      return []
    }
  }

  getBeforeLevel3(code_level1: string, code_level2: string) {
    try {
      let children1 = this.before!.find(item => item.code === code_level1)
      let children2 = children1?.children?.find(item => item.code === code_level2)!.children
      let childrenA1 = this.after!.find(item => item.code === code_level1)
      let childrenA2 = childrenA1?.children?.find(item => item.code === code_level2)!.children
      children2 = childrenA2!.filter((afterItem, i) => {
        // return i==0
        return children2!.some(beforeItem => beforeItem.code === afterItem.code)
      })
      return children2
    } catch (error) {
      return []
    }
  }

  getBeforeLevel3ForDocument(code_level1: string, code_level2: string, code_level3: string) {
    try {
      let children1 = this.before!.find(item => item.code === code_level1)
      let children2 = children1?.children?.find(item => item.code === code_level2)
      let children3 = children2?.children?.filter(item => item.code === code_level3)
      console.log('children3', children3);
      let code3 = children3!.find(item => item.code === code_level3)
      return code3
    } catch (error) {
      return []
    }
  }
  getBeforeLevel4(code_level1: string, code_level2: string, code_level3: string) {
    try {
      let children1 = this.before!.find(item => item.code === code_level1)
      let children2 = children1?.children?.find(item => item.code === code_level2)
      let children3 = children2?.children?.filter(item => item.code === code_level3)
      console.log('children1', children3);
      return children3
    } catch (error) {
      return []
    }
  }

  getAfterLevel1(code_level1: string) {
    console.log('this.after', this.after?.filter(item => item.code === code_level1));
    try {
      let item = this.after?.filter(item => item.code === code_level1);
      console.log(item);
      return item
    } catch (error) {
      return []
    }

    
  }
  getAfterLevel2ForDocument(code_level1: string,code_level2:string) {
    
    try {
      let children = this.after?.find(item => item.code === code_level1)!.children
      let code2 = children!.find(item => item.code === code_level2)
      console.log('this.code2', code2);
      if (children === undefined) return []
      else return code2
    } catch (error) {
      return []
    }
  }
  getAfterLevel3ForDocument(code_level1: string, code_level2: string, code_level3: string) {
    try {
      let children1 = this.after!.find(item => item.code === code_level1)
      let children2 = children1?.children?.find(item => item.code === code_level2)
      let children3 = children2?.children?.filter(item => item.code === code_level3)
      console.log('children3', children3);
      let code3 = children3!.find(item => item.code === code_level3)
      return code3
    } catch (error) {
      return []
    }
  }
  
  pushItem() {
    try {
      this.data!.items.push(new AdjustmentItemModel({}))
    } catch (error) {
    }
  }
  pushBudget() {
    try {
      this.data!.budgets.push(new AdjustmentBudgetModel({}))
    } catch (error) {
    }
  }


  async getAdjustmentSingle(id: string) {
    this.loading = true;
    let response = await new AdjustmentServices().getAdjustmentSingle(id)
    this.data = response.doc;
    this.before = response.before;
    this.after = response.after;
    try {

      let filteredBefore = this.before.filter(beforeItem => {
        return this.after!.some(afterItem => afterItem.code === beforeItem.code)
      })
      this.before = filteredBefore
    } catch (error) {
      console.log("errorerrorerror", { error })
    }
    this.loading = false;
  }
  async getAdjustmentSingleFromPromise(id: string) {
    this.refreshing = true;
    let response = await new AdjustmentServices().getAdjustmentSingle(id)
    this.data = response.doc;
    this.before = response.before;
    this.after = response.after;
    this.refreshing = false;
  }
  async refreshAdjustmentSingle() {
    this.loading = true;
    let response = await new AdjustmentServices().getAdjustmentSingle(this.data!.id!)
    this.data = response.doc;
    this.before = response.before;
    this.after = response.after;
    this.loading = false;
  }
  async updateAdjustmentSingle() {
    this.loading = true;
    console.log('data', this.data);
    let response = await new AdjustmentServices().updateAdjustment(
      this.data!.id!,
      this.data!
    );
    this.refreshAdjustmentSingle()
    this.loading = false;
  }
  async updateAdjustmentSingleFromPromise(): Promise<string> {
    return new Promise(async (resolve, reject) => {

      this.refreshing = true;
      let response = await new AdjustmentServices().updateAdjustment(
        this.data!.id!,
        this.data!
      );
      this.refreshing = false;
      resolve("done")
    })
  }
  async createAdjustmentSingle() {
    this.loading = true;
    console.log("data class", this.data!)
    this.data = await new AdjustmentServices().createAdjustment(this.data!);
    this.loading = false;
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAdjustmentItemsSingle(id: string) {
    this.loading = true
    console.log('data', this.data)
    let deleteItem = await new AdjustmentServices().deleteAdjustmentItemsSingle(id)
    let response = await new AdjustmentServices().getAdjustmentSingle(id)
    this.data = response.doc;
    this.before = response.before;
    this.after = response.after;
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAdjustmentBudgetSingle(id: string) {
    this.loading = true
    console.log('data', this.data)
    let deleteItem = await new AdjustmentServices().deleteAdjustmentBudgetSingle(id)
    let response = await new AdjustmentServices().getAdjustmentSingle(id)
    this.data = response.doc;
    this.before = response.before;
    this.after = response.after;
    this.loading = false
  }
  async requestApprove(){
    let response = await new AdjustmentServices().requestAdjustmentApprove(this.data!.id!)
    console.log(response);
    this.refreshAdjustmentSingle()
    this.loading = false
  }

}

@Service()
export class ActiveAdjustmentListClass {
  data?: PaginateResponse<AdjustmentModel>;
  loading: boolean = true;
  query: any = {};
  async getAdjustmentList(query: any) {
    this.query = query;
    this.loading = true;
    this.data = await new AdjustmentServices().getAdjustmentList(query);
    this.loading = false;
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAdjustment(id: string) {
    this.loading = true;
    let result = await new AdjustmentServices().deleteAdjustmentFromList(id);
    await this.getAdjustmentList(this.query);
    this.loading = false;
  }
  async approveAdjustment(id: string) {
    this.loading = true;
    let result = await new AdjustmentServices().approveAdjustmentFromList(id);
    await this.getAdjustmentList(this.query);
    this.loading = false;
  }
  async rejectAdjustment(id: string) {
    this.loading = true;
    let result = await new AdjustmentServices().rejectAdjustmentFromList(id);
    await this.getAdjustmentList(this.query);
    this.loading = false;
  }
}

@Service()
export class AttachmentSingleClass {
  data?: AdjustmentAttachmentModel;
  loading: boolean = true;
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAttachmentSingle() {
    this.loading = true
    this.data = await new AdjustmentServices().deleteAttachmentSingle(this.data!.id!)
    this.loading = false
  }
}

@Service()
export class AdjustmentItemDeleteClass {
  data?: AdjustmentItemModel;
  loading: boolean = true;
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteAdjustmentItemsSingle(id: string) {
    this.loading = true
    console.log('data', this.data)
    this.data = await new AdjustmentServices().deleteAdjustmentItemsSingle(id)
    this.loading = false
  }

  // export class AttachmentSingleClass {
  //   async deleteAdjustmentItemsSingle(id: string) {
  //     this.loading = true
  //     console.log('data',this.data)
  //     this.data!.items = await new AdjustmentServices().deleteAdjustmentItemsSingle(id)
  //     this.loading = false
  //   }

}
