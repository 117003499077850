import ADDRESS from "@/setting";
import axios from "axios"
import UserModel from "@/model/user";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class UserServices {
  public getUserList(query: any) {
    return new Promise<PaginateResponse<UserModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/users/paginate`, query);
      console.log({ data: response.data })
      let docs: Array<UserModel> = response.data.docs.map((el: any) => new UserModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<UserModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }
  public loginUser(username: string, password: string) {
    return new Promise<UserModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/user/login`, { username, password });
      let doc: UserModel = new UserModel(response.data.doc)
      resolve(doc)
    })
  }

  public getUserSingle(id: string) {
    return new Promise<UserModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/user/get/${id}`);
      let doc: UserModel = new UserModel(response.data.doc)
      resolve(doc)
    })
  }

  public deleteUserSingle(id: string) {
    return new Promise<UserModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/user/delete/${id}`);
      let doc: UserModel = new UserModel(response.data.doc)
      resolve(doc)
    })
  }

  public updateUser(id: string, User: UserModel) {
    return new Promise<UserModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/user/update/${id}`, User.toJSON());
console.log("response", response.data)
      let doc: UserModel = new UserModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createUser(User: UserModel) {
    return new Promise<UserModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/user/create/`, User);
      let doc: UserModel = new UserModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public Login(username: string, password: string) {
    let query = {
      "username": username,
      "password": password
    }
    return new Promise<UserModel | string>(async (resolve, reject) => {
      try {

        let response = await axios.post(`${ADDRESS}/user/login/`, query);
        console.log(response);
        if (response.data.status != 'success') {
          reject("error")
        } else {
          console.log("ถึงไหม")
          let doc: UserModel = new UserModel(response.data.doc)
          console.log("resolving")
          console.log('doc', doc);

          resolve(doc)
        }
      } catch (error) {
        reject(error)
      }
    })
  }
}
