
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import SystemModel from "@/model/system";
import {
  ActiveProjectListClass,
  ActiveProjectSingleClass,
} from "@/data/project";
import Container from "typedi";
import ProjectModel from "@/model/project";
import { ActiveSectorListClass } from "@/data/sector";
import { ActiveBudgetYearClass, ActiveBudgetSourceClass } from "@/data/system";
import { ActiveUserSingleClass } from "@/data/user";
import { useStore } from "vuex";

@Options({
  components: {
  },
})
export default class ProjectListView extends Vue {
  store = useStore();
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  budgetSourceList: ActiveBudgetSourceClass = Container.get(
    ActiveBudgetSourceClass
  );
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  dataList: ActiveProjectListClass = Container.get(ActiveProjectListClass);
  userSingle: ActiveUserSingleClass = Container.get(ActiveUserSingleClass);
  docs: Array<ProjectModel> = [];
  pages: number = 0;
  total: number = 0;
  show: number = 20;
  page: number = 1;
  sector_id = "";
  search = "";
  budgetYear = new Date().getFullYear() + 543;
  budgetSource = "";

  renderTableTool(item: ProjectModel) {
    let result:Array<string> = ["Edit"]
    return result;
  }
  mounted() {
    this.yearSetting.getBudgetYearSetting();
    this.budgetSourceList.getBudgetSourceSetting("แหล่งงบประมาณในคำขอ");
    this.sectorList.getSectorList({ search: { category: "ฝ่าย/สำนัก" } });
    this.fetchData();
  }
  async fetchData() {
    let query: any = {
      search: {},
      limit: this.show,
      page: this.page,
      skip: (this.page-1)*this.show,
      sort: [
        { step: "asc" },
        { sector: { name:"asc" } },
        { createdAt: "desc" },
      ],
    };
    query.search.budgetYear = this.budgetYear;
    if (this.sector_id != "") query.search.sector_id = this.sector_id;
    if (this.budgetSource != "") query.search.budgetSource = this.budgetSource;
    if (this.search != "") {
      query.search = {
        ...query.search,
        OR: [
          { name: { contains: this.search } },
          { code: { contains: this.search } },
        ],
      };
    }
    await this.dataList.getProjectList(query);
    // this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
