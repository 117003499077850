import { PaginateResponse } from "@/common";
import { confirmAction } from "@/decorator";
import SectorModel from "@/model/sector";
import SectorServices from "@/service/sector";
import { Service } from 'typedi';

@Service()
export class ActiveSectorSingleClass {
  data?: SectorModel;
  loading: boolean = true;
  async getSectorSingle(id: string) {
    this.loading = true
    this.data = await new SectorServices().getSectorSingle(id)
    this.loading = false
  }
  async updateSectorSingle(id: string) {
    this.loading = true
    this.data = await new SectorServices().updateSector(id, this.data!)
    this.loading = false
  }
  async createSectorSingle() {
    this.loading = true
    this.data = await new SectorServices().createSector(this.data!)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteSectorSingle(id: string) {
    this.loading = true
    let response = await new SectorServices().deleteSectorSingle(id)
    this.loading = false
  }
}

@Service()
export class ActiveSectorListClass {
  data?: PaginateResponse<SectorModel>;
  loading: boolean = true;
  query:any = {}
  async getSectorList(query: any) {
    this.query = query
    this.loading = true
    this.data = await new SectorServices().getSectorList(query)
    this.loading = false
  }
  async refreshSectorList() {
    this.loading = true
    this.data = await new SectorServices().getSectorList(this.query)
    this.loading = false
  }
  @confirmAction("การกระทำนี้จะลบรายการที่คุณเลือกออกคุณแน่ใจหรือไม่?")
  async deleteSectorFromList(id: string) {
    this.loading = true
    let tmp = await new SectorServices().deleteSectorSingle(id)
    this.refreshSectorList()
    // let index = this.data!.docs.findIndex(doc => doc.id === id)
    // this.data!.docs.splice(index, 1)
    this.loading = false
  }
}