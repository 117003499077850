
import { Options, Vue } from "vue-class-component";
import ProjectModel from "@/model/project";
import { ActiveProjectSingleClass } from "@/data/project";
import { ActiveSectorListClass } from "@/data/sector";
import Container from "typedi";
import ProjectFormLinkTactic from "@/views/project/components/ProjectFormLinkTactic.vue";
import ProjectFormLinkStrategic from "@/views/project/components/ProjectFormLinkStrategic.vue";
import ProjectFormLinkPlan from "@/views/project/components/ProjectFormLinkPlan.vue";
import ProjectFormBudgetPlan from "@/views/project/components/ProjectFormBudgetPlan.vue";
import ProjectFormLinkIndicator from "@/views/project/components/ProjectFormLinkIndicator.vue";
import ProjectFormResponsible from "@/views/project/components/ProjectFormResponsible.vue";
import ProjectFormResponsibleArray from "@/views/project/components/ProjectFormResponsibleArray.vue";
import ProjectFormCoordinator from "@/views/project/components/ProjectFormCoordinator.vue";
import {
  ActiveBudgetSourceClass,
  ActiveBudgetTypeClass,
  ActiveBudgetYearClass,
  ActiveSystemSingleClass,
} from "@/data/system";
import { useStore } from "vuex";

import Document from "@/document/pdf";
import { DocumentDocx } from "@/document/docx/A";
import ADDRESS from "@/setting";
import axios from "axios";

@Options({
  components: {
    ProjectFormBudgetPlan,
    ProjectFormLinkTactic,
    ProjectFormLinkStrategic,
    ProjectFormLinkPlan,
    ProjectFormLinkIndicator,
    ProjectFormResponsible,
    ProjectFormCoordinator,
    ProjectFormResponsibleArray,
  },
})
export default class BudgetFormView extends Vue {
  ADDRESS = ADDRESS;
  disabled: boolean = false;
  budgetSource = "";
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  settingSingle: ActiveSystemSingleClass = Container.get(
    ActiveSystemSingleClass
  );
  budgetSourceSetting: ActiveBudgetSourceClass = Container.get(
    ActiveBudgetSourceClass
  );
  budgetTypeSetting: ActiveBudgetTypeClass = Container.get(
    ActiveBudgetTypeClass
  );
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  store = useStore();

  updateItem() {
    try {
      let foundName = this.dataSingle.data!.items.find(
        (item) => item.code === "name"
      );
      console.log({ foundName });
      foundName!.value = this.dataSingle.data!.name;
    } catch (error) { }
    try {
      let foundCode = this.dataSingle.data!.items.find(
        (item) => item.code === "code"
      );
      foundCode!.value = this.dataSingle.data!.code;
      console.log({ foundCode });
    } catch (error) { }
  }
  mounted() {
    this.disabled = this.$route.path.search("/detail") != -1;
    this.init();
    this.getSignature()
  }
  async init() {
    this.budgetSourceSetting.getBudgetSourceSetting("แหล่งงบประมาณในคำขอ");
    this.yearSetting.getBudgetYearSetting();
    this.sectorList.getSectorList({ search: { category: "ฝ่าย/สำนัก" } });
    this.budgetTypeSetting.getBudgetTypeSetting("ประเภทงบในคำขอ");
    if (this.$route.params.id !== undefined) {
      this.initData();
    } else {
      await this.settingSingle.getSystemByName("คำของบประมาณ");
      console.log(this.settingSingle);
      this.dataSingle.data = new ProjectModel(
        {},
        this.settingSingle.data!.member
      );
    }
  }
  async initData() {
    this.dataSingle.data = undefined;
    await this.dataSingle.getProjectSingle(this.$route.params.id as string);
    // console.log("loaded", this.dataSingle.data)
    // this.$forceUpdate()
  }
  async submitForm() {
    if (this.dataSingle.isDuplicateCode) {
      alert("กรุณาตรวจสอบรหัสอีกครั้ง");
      return;
    }
    if (this.$route.params.id === undefined) {
      await this.dataSingle.createProjectSingle();
    } else {
      await this.dataSingle.updateProjectSingle();
    }
    this.$router.push("/portal/project/list");
  }
  async checkCode() {
    await this.dataSingle.checkCodeSingle(this.dataSingle.data!.code as string);
    console.log(this.dataSingle);
  }
  async ExportPDF() {
    const document = new Document();
    let base64s: string[] = []
    let base64: string = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
    let base64_1: string = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
    if ((this.dataSingle.data?.step ?? 0) < 4) {
      base64s = new Array(4).fill("iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=")
    }
    else {
      for (const el of this.dataSingle.data!.items.find(item => item.code === 'sectors')?.value) {
        try {
          console.log({ el })
          if (el.signature.length < 10) throw new Error("");

          const raw = await axios.get(
            ADDRESS + "/" + el.signature,
            {
              responseType: "arraybuffer",
            }
          );
          let nbase64 = Buffer.from(raw.data, "binary").toString("base64");
          base64s.push(nbase64)
        } catch (error) {
          base64s.push("iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=")
        }
      }
      const raw = await axios.get(
        ADDRESS + "/uploads/undefined/20221226101307_250-2501136_ลายเซ็น-ชัย-ชิดชอบ-ลายเซ็น-ชัย-ชิดชอบ.png",
        {
          responseType: "arraybuffer",
        }
      );
      
      base64 = Buffer.from(raw.data, "binary").toString("base64");
      
    }
    console.log({ base64, base64s ,base64_1})
    document.create11(this.dataSingle, base64, base64s,base64_1);
    document.save(`reportA11.pdf`);
    console.log("pdf");
  }
  async ExportWord() {
    const docx = new DocumentDocx();
    let base64s: string[] = []
    let base64: string = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
    let base64_1: string = "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
    if ((this.dataSingle.data?.step ?? 0) < 4) {
      base64s = new Array(4).fill("iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=")
    }
    else {
      for (const el of this.dataSingle.data!.items.find(item => item.code === 'sectors')?.value) {
        try {
          console.log({ el })
          if (el.signature.length < 10) throw new Error("");

          const raw = await axios.get(
            ADDRESS + "/" + el.signature,
            {
              responseType: "arraybuffer",
            }
          );
          let nbase64 = Buffer.from(raw.data, "binary").toString("base64");
          base64s.push(nbase64)
        } catch (error) {
          base64s.push("iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=")
        }
      }
      const raw = await axios.get(
        ADDRESS + "/uploads/undefined/20221226101307_250-2501136_ลายเซ็น-ชัย-ชิดชอบ-ลายเซ็น-ชัย-ชิดชอบ.png",
        {
          responseType: "arraybuffer",
        }
      );
      
      base64 = Buffer.from(raw.data, "binary").toString("base64");
      
    }
    console.log({ base64, base64s ,base64_1})
    // document.create11(this.dataSingle, base64, base64s,base64_1);

    await docx.reportA11(this.dataSingle, base64, base64s,base64_1);
    docx.save("ReportA11.docx");
    console.log("word");
  }

  async getSignature() {
    let base64 = await this.getBase64("http://smis2.sat.or.th/api/v2/uploads/undefined/20230105030821_ลายเซ็นอนุชา_สะสมทรัพย์.png")
    console.log(base64)
  }

  getBase64 = async (url: any) => {
    let response = await axios
      .get(url, {
        responseType: 'arraybuffer'
      })
    return Buffer.from(response.data, 'binary').toString('base64')
    // .then(response => Buffer.from(response.data, 'binary').toString('base64'))
  }
}
