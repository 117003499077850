export  default class AttendantModel {
  id?: string;
  staff: string;
  inTime: Date;
  outTime: Date;
  createdAt: Date;
  modifiedAt: Date;
  createdIP: string;
  modifiedIP: string;


  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.staff = jsonData.staff;
    this.inTime = jsonData.inTime;
    this.outTime = jsonData.outTime;
    this.createdAt = jsonData.createdAt;
    this.modifiedAt = jsonData.modifiedAt;
    this.createdIP = jsonData.createdIP;
    this.modifiedIP = jsonData.modifiedIP;
  }
  get inTimeFormat() {
    try {
      return new Date(this.inTime).toTime()
    } catch (error) { }
  }
  get outTimeFormat() {
    try {
      return new Date(this.outTime).toTime()
    } catch (error) { }
  }
  get intimeDate() {
    try {
      return new Date(this.inTime).toThaiShort()
    } catch (error) { }
  }
  toJSON(){
    let obj = Object.assign({},this)
    
    return obj
  }
}
