
import { Options, Vue } from "vue-class-component";
import Navigation from "@/components/Navigation.vue";
import store from "@/store";
@Options({
  components: {
    Navigation,
  },
})
export default class PortalView extends Vue {
  showNav: boolean = true;
  store = store;

  get roles() {
    try {
      if (typeof store.state.roles === "string")
        return JSON.parse(store.state.roles);
      else return store.state.roles;
    } catch (error) {
      return [];
    }
  }
  active(i: number) {
    if (this.navigations[i].active) {
      this.navigations[i].active = false;
      return;
    }
    this.navigations.forEach((item) => {
      item.active = false;
    });
    this.navigations[i].active = !this.navigations[i].active;
  }
  toggleNav() {
    this.showNav = !this.showNav;
  }
  submitLogout() {
    this.$router.push("/");
  }
  navigations: Array<NavConfig> = [
    {
      title: "ตัวชี้วัด",
      path: "/portal/kpi",
      icon: "fa-solid fa-ruler-triangle",
      access: "indicator_profile_list",
      children: [
        {
          title: "รายการตัวชี้วัด",
          path: "/portal/kpi/indicator",
          icon: "fa-solid fa-file-alt",
          access: "indicator_profile_list",
        },
        {
          title: "รายการผังยุทธศาสตร์",
          path: "/portal/kpi/strategic",
          icon: "fa-solid fa-file-alt",
          access: "indicator_strategic_list",
        },
        {
          title: "รายการติดตามและประเมินตัวชี้วัด",
          path: "/portal/kpi/review",
          icon: "fa-solid fa-file-alt",
          access: "indicator_followup_list",
        },
        {
          title: "รายการจัดเตรียมงบฯของปีถัดไป",
          path: "/portal/kpi/preparation",
          icon: "fa-solid fa-file-alt",
          access: "indicator_duplicate_list",
        },
      ],
    },
    {
      title: "ระบบงบประมาณ",
      path: "/portal/project/list",
      active: false,
      icon: "fa-solid fa-coins",
      access: "budget_form_list",
      notification: "budgetNotification",
    },
    {
      title: "การปรับแผน",
      path: "/portal/adjustment",
      active: false,
      notification: "adjustNotification",
      icon: "far fa-sliders-h-square",
      access: "adjustment_form_list",
    },
    {
      title: "กันเหลื่อม",
      path: "/portal/overlap",
      icon: "fa-solid fa-money-check-dollar",
      access: "overlap_list",
      children: [
        {
          title: "รายการโครงการ",
          path: "/portal/overlap/list",
          icon: "fa-solid fa-money-check-dollar",
          access: "overlap_list",
        },
        {
          title: "รายงาน",
          path: "/portal/overlap/report-list",
          icon: "fa-solid fa-file-chart-line",
          access: "overlap_report",
        },
      ],
    },
    {
      title: "การเบิก/ยืมเงินในระบบ ERP",
      path: "/portal/withdraw",
      icon: "fa-solid fa-money-check-edit-alt",
      access: "withdraw_form_list",
      children: [
        {
          title: "รายการเบิก/ยืมเงิน",
          path: "/portal/withdraw/list",
          icon: "fa-solid fa-money-check-edit-alt",
          access: "withdraw_form_list",
        },
        {
          title: "สรุปรายการ ERP",
          path: "/portal/withdraw/summary",
          icon: "fa-solid fa-money-check-edit-alt",
          access: "withdraw_summary_list",
        },
      ],
    },
    {
      title: "รายการติดตามผลปฏิบัติงาน",
      path: "/portal/followup",
      icon: "fa-solid fa-clipboard-list-check",
      access: "followup_form_list",
    },
    {
      title: "รายงาน",
      path: "/portal/report",
      icon: "fa-solid fa-file-chart-line",
      access: "report_access_list",
    },
    {
      title: "MIS Dashboard",
      path: "/portal/dashboard",
      icon: "fa-solid fa-tachometer-alt",
      access: "dashboard_access_list",
    },
    {
      title: "ระบบประชาสัมพันธ์ ",
      icon: "fa-solid fa-rss",
      path: "/portal/pr",
      access: "publicrelation_news_list",
      children: [
        {
          title: "ประกาศข่าวสาร",
          path: "/portal/pr/announcement",
          icon: "fa-solid fa-newspaper",
          access: "publicrelation_news_list",
        },
        {
          title: "ข่าวด่วน",
          path: "/portal/pr/flashnews",
          icon: "fa-solid fa-megaphone",
          access: "publicrelation_flashnews_list",
        },
        {
          title: "เอกสาร",
          path: "/portal/pr/document",
          icon: "fa-solid fa-briefcase",
          access: "publicrelation_document_list",
        },
      ],
    },
    {
      title: "การมอบหมายงานออนไลน์",
      path: "/portal/assignment",
      icon: "fa-solid fa-home-lg-alt",
      access: "assignment_form_list",
      notification: "assignmentTotalNotification",
      children: [
        {
          title: "รายการมอบหมายงาน",
          path: "/portal/assignment/my-assignment",
          icon: "fa-solid fa-list-alt",
          access: "assignment_form_list",
          notification: "assignmentOwnerNotification",
        },
        {
          title: "ประวัติงานที่ถูกมอบหมาย",
          path: "/portal/assignment/assignment-submission",
          icon: "fa-solid fa-list-alt",
          access: "assignment_history_list",
          notification: "assignmentMemberNotification",
        },
        {
          title: "การเข้างาน",
          path: "/portal/assignment/attendant",
          icon: "fa-solid fa-sign-in",
          access: "assignment_signin_list",
        },
      ],
    },
    {
      title: "รายการผู้ใช้",
      path: "/portal/uac",
      icon: "fa-solid fa-list-alt",
      access: "user_access_list",
    },
    {
      title: "ตั้งค่าระบบ",
      active: false,
      path: "/portal/setting",
      icon: "fa-solid fa-cogs",
      access: "setting_general_list",
      children: [
        {
          title: "ตั้งค่าระบบ",
          path: "/portal/setting/system",
          icon: "fa-solid fa-list-alt",
          access: "setting_general_list",
        },
        {
          title: "ระบบจัดการตำแหน่ง",
          path: "/portal/setting/position",
          icon: "fa-solid fa-list-alt",
          access: "setting_position_list",
        },
        {
          title: "รายการสำนักงาน",
          path: "/portal/setting/sector",
          icon: "fa-solid fa-list-alt",
          access: "setting_sector_list",
        },
        {
          title: "ตั้งค่ารหัสสินค้า",
          path: "/portal/setting/account",
          icon: "fa-solid fa-list-alt",
          access: "setting_zpcode_list",
        },
        {
          title: "ตั้งค่ามติ ครม.",
          path: "/portal/setting/cabinetTarget",
          icon: "fa-solid fa-list-alt",
          access: "setting_zpcode_list",
        },
      ],
    },
    {
      title: "ระบบบริหารความเสี่ยง",
      path: "https://risk.sat.or.th/",
      anchor: "https://risk.sat.or.th/",
      active: false,
      icon: "fa-solid fa-analytics",
      access: "user_access_list",
    },
  ];
}

interface NavConfig {
  title: string;
  path?: string;
  anchor?: string;
  name?: string;
  active?: Boolean;
  icon: string;
  notification?: string;
  access?: string;
  children?: Array<NavConfigChildren>;
}
interface NavConfigChildren {
  title: string;
  path: string;
  anchor?: string;
  name?: string;
  active?: Boolean;
  icon?: string;
  notification?: string;
  access?: string;
  children?: Array<NavConfigChildren>;
}
