
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import PublicrelationModel from "@/model/publicrelation";
import { ActivePublicrelationListClass } from "@/data/publicrelation";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import Container from "typedi";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class PositionFormView extends Vue {
  dataList: ActivePublicrelationListClass = Container.get(ActivePublicrelationListClass);
  docs: Array<PublicrelationModel> = [];
  pages: number = 0;
  total: number = 0;
  page: number = 1;
  show: number = 20;
  store = useStore();

renderTableTool(item: PublicrelationModel) {
  let result:Array<string> = ["Detail"]
  if(this.store.getters.hasRight("publicrelation_news_editall")) result.push("Edit")
  if(this.store.getters.hasRight("publicrelation_news_delete")) result.push("Delete")
  if(this.store.getters.hasRight("publicrelation_news_detail")) result.push("Detail")
  return result;
}
 
  mounted() {
    this.initData();
  }
  async initData() {
    let query: any = {
     
     limit: this.show,
     page: this.page,
     skip: (this.page-1)*this.show,
     sort: {
       createdAt: "desc",
     },}

    await this.dataList.getPublicrelationList(query);
   
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
