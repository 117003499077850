
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import PetitionModel from "@/model/petition";
import { ActivePetitionListClass } from "@/data/petition";
import Container from "typedi";
import { DocumentExcel } from "@/document/xlsx";
import { ActiveProjectSingleClass,ActiveProjectForPetitionClass } from "@/data/project";
import { ActivePetitionSingleClass } from "@/data/petition";

@Options({
  components: {},
})
export default class PositionFormView extends Vue {
  petitionList: ActivePetitionListClass = Container.get(ActivePetitionListClass);
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  

  mounted() {
    this.initData();
  }
  async initData() {
    let query: any = {
      search: {
        project_id: this.$route.params.projectid,
      },
    };
    this.petitionList.getPetitionList(query);
    this.dataSingle.getProjectSingle(this.$route.params.id as string);
    console.log('dataSingle',this.dataSingle);
    
  }
  async generateReportExcel(id:string,project_id:string){
    let documentExcel = new DocumentExcel();
    documentExcel.createPT(id,project_id);
  }
}
