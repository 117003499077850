
import { Options, Vue } from "vue-class-component";
import AdjustmentModel from "@/model/adjustment";
import AdjustmentServices from "@/service/adjustment";
import BudgetModel from "@/model/budget";
import { ActiveProjectSingleClass } from "@/data/project";
import TableHeader from "@/views/adjustment/components/TableHeader.vue";
import History from "@/views/adjustment/components/History.vue"
import TableBody from "@/views/adjustment/components/TableBody.vue";
import Container from "typedi";
import { rawListeners } from "process";
import { ActiveWithdrawListClass } from "@/data/withdraw";
@Options({
  components: {
    TableHeader,
    TableBody,
    History
  },
})
export default class PlanTableComponent extends Vue {
  doc: AdjustmentModel = new AdjustmentModel({});
  budget: BudgetModel = new BudgetModel({});
  isZero: boolean = false;
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  withdrawList: ActiveWithdrawListClass = Container.get(
    ActiveWithdrawListClass
  );
  history:any
  tudNgern: boolean = false;
  get totalBudget() {
    try {
      // let map = this.dataSingle.data?.budgets.map(budget=> budget)
      //let map = this.dataSingle.data?.budgets.map(budget=> budget.items.map(item=>item.amount).reduce((a,b)=>a+b,0)).reduce((a,b)=>a+b,0)
      let map = this.dataSingle.data?.budgets
        .map((budget) =>
          budget.items.map((item) => item.amount).reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0);
      return map;
    } catch (error) {
      return 0;
    }
  }
  mounted() {
    this.initData();
  }
  async initData() {
    this.history = await new AdjustmentServices().getAdjustmentHistory(this.$route.params.id as string)

    this.withdrawList.getWithdrawList({ search: { budget: {project_id:this.dataSingle.data?.id} } })
    // console.log(history);
    
  }
}
