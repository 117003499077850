
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import SystemModel from "@/model/system";
import { ActiveSystemListClass, ActiveSystemSingleClass } from "@/data/system";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import ADDRESS from "@/setting"
import Container from "typedi";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class PositionFormView extends Vue {
  ADDRESS = ADDRESS
  dataSingle: ActiveSystemSingleClass = Container.get(ActiveSystemSingleClass);
  dataList: ActiveSystemListClass = Container.get(ActiveSystemListClass);
  docs: Array<SystemModel> = [];
  pages: number = 0;
  total: number = 0;
  store = useStore();

  renderTableTool(item: SystemModel) {
    let result:Array<string> = ["Detail"]
    if(this.store.getters.hasRight("setting_general_editall")) result.push("Edit")
    if(this.store.getters.hasRight("setting_general_detail")) result.push("Delete")
    if(this.store.getters.hasRight("setting_general_delete")) result.push("Detail")
    return result;
  }
  mounted() {
    this.initData();
  }
  async initData() {
    await this.dataList.getSystemList({});
    this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
