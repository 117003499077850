export default class DocumentModel {
  id?: string;
  name: string;
  attachments: Array<DocumentAttachmentModel>;


  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name;
    this.attachments = jsonData.attachments ?? [].map((item: any) => new DocumentAttachmentModel(item));;
  }
  toJSON() {
    let obj = Object.assign({}, this)
    console.log("toJSON", obj)

    try {
      obj.attachments = obj.attachments.map(o => new DocumentAttachmentModel(o).toJSON())
    } catch (error) {

    }
    return JSON.parse(JSON.stringify(obj))
  }
}
export class DocumentAttachmentModel {
  id?: string;
  path: string;
  size: number;
  constructor(jsonData: any) {
    this.id = jsonData.id
    this.path = jsonData.path ?? ""
    this.size = jsonData.size ?? 0
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return JSON.parse(JSON.stringify(obj))
  }
}