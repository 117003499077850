
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import SystemModel from "@/model/system";
import {
  ActiveProjectListClass,
  ActiveProjectSingleClass,
} from "@/data/project";
import { PaginateResponse } from "@/common";
import TableTool from "../../components/TableTool.vue";
import ApprovalProgress from "./components/ApprovalProgress.vue";
import Container from "typedi";
import ProjectModel from "@/model/project";
import { ActiveSectorListClass } from "@/data/sector";
import { ActiveBudgetYearClass, ActiveBudgetSourceClass } from "@/data/system";
import { ActiveUserSingleClass } from "@/data/user";
import { useStore } from "vuex";
import { isThrowStatement } from "@babel/types";

@Options({
  components: {
    ApprovalProgress,
  },
})
export default class ProjectListView extends Vue {
  store = useStore();
  sectorList: ActiveSectorListClass = Container.get(ActiveSectorListClass);
  yearSetting: ActiveBudgetYearClass = Container.get(ActiveBudgetYearClass);
  budgetSourceList: ActiveBudgetSourceClass = Container.get(
    ActiveBudgetSourceClass
  );
  dataSingle: ActiveProjectSingleClass = Container.get(
    ActiveProjectSingleClass
  );
  dataList: ActiveProjectListClass = Container.get(ActiveProjectListClass);
  userSingle: ActiveUserSingleClass = Container.get(ActiveUserSingleClass);
  docs: Array<ProjectModel> = [];
  pages: number = 0;
  total: number = 0;
  show: number = 20;
  page: number = 1;
  sector_id = "";
  search = "";
  budgetYear = new Date().getFullYear() + 543;
  budgetSource = "";
  callouts = [
    "C7(รับผิดชอบ)",
    "C8(รับผิดชอบ)",
    "C9(รับผิดชอบ)",
    "C7(แผน)",
    "C8(แผน)",
    "C9(แผน)",
    "C10(รองผู้ว่าฯ)",
    "C11(ผู้ว่าฯ)",
  ];

  renderTableTool(item: ProjectModel) {
    let result:Array<string> = ["Detail"]
    if(this.store.getters.hasRight("budget_form_editall")) result.push("Edit")
    if(this.store.getters.hasRight("budget_form_delete")) result.push("Delete")
    if(this.store.getters.hasRight("budget_form_approval")) {
      if((item.step < 4 && item.isRequest === true) || item.isRequest === null || item.step < 0 ) {
        // if(item.step === 1 && item.approver1_id == this.store.state.userId) {
        //   result.push("Approve"); result.push("Reject")
        // } else if(item.step === 2 && item.approver2_id == this.store.state.userId) {
        //   result.push("Approve"); result.push("Reject")
        // } else if(item.step === 3 && item.approver3_id == this.store.state.userId) {
        //   result.push("Approve"); result.push("Reject")
        // } else if (this.store.state.roles.includes("ผู้ดูแลระบบ")) {
        //   result.push("Approve"); result.push("Reject")
        // } else {
        //   result.push("");
        // }
        if(item.step == 0) {
          if(item.approver1_id == this.store.state.userId) {
            result.push("Approve")
            result.push("Reject")
          }
        }
        else if(item.step == 1) {
          if(item.approver2_id == this.store.state.userId) {
            result.push("Approve")
            result.push("Reject")
          }
        }
        else if(item.step == 2) {
          if(item.approver3_id == this.store.state.userId) {
            result.push("Approve")
            result.push("Reject")
          }
        }
        else if(item.step <= 0) {
          result.push("Approve")
        }
        else {
          if(this.store.state.roles.includes("ผู้ดูแลระบบ")) {
            result.push("Approve") 
            result.push("Reject")
          } 
        }
        // if(item.approver1_id == this.store.state.userId || item.approver2_id == this.store.state.userId || 
        // item.approver3_id == this.store.state.userId || this.store.state.roles.includes("ผู้ดูแลระบบ")) {
        //   result.push("Approve"); result.push("Reject")
        // } 
      }
    } 
    if(this.store.getters.hasRight("budget_form_editsector") && this.store.state.sector3===item.sector_id) {
      result.push("Edit")
    }
    return result;
  }
  get roles() {
    try {
      if (typeof this.store.state.roles === "string")
        return JSON.parse(this.store.state.roles);
      else return this.store.state.roles[1];
    } catch (error) {
      return [];
    }
  }
  mounted() {
    if(this.roles !="ผู้ดูแลระบบ"){
      this.sector_id = this.store.state.sector3
    }
   
    this.yearSetting.getBudgetYearSetting();
    this.budgetSourceList.getBudgetSourceSetting("แหล่งงบประมาณในคำขอ");
    this.sectorList.getSectorList({ search: { category: "ฝ่าย/สำนัก" } });
    this.fetchData();
  }
  async fetchData() {
    let query: any = {
      search: {},
      limit: this.show,
      page: this.page,
      skip: (this.page-1)*this.show,
      sort: [
        { step: "asc" },
        { code: "asc" },
        { sector: { name:"asc" } },
        { createdAt: "desc" },
      ],
    };
    query.search.budgetYear = this.budgetYear;
    if (this.sector_id != "") query.search.sector_id = this.sector_id;
    if (this.budgetSource != "") query.search.budgetSource = this.budgetSource;
    if (this.search != "") {
      query.search = {
        ...query.search,
        OR: [
          { name: { contains: this.search } },
          { code: { contains: this.search } },
        ],
      };
    }
    await this.dataList.getProjectList(query);
    // this.docs = this.dataList.data!.docs;
    this.pages = this.dataList.data!.pages;
    this.total = this.dataList.data!.total;
  }
}
