import IndicatorProfileModel from "../indicator";

export default class IndicatorReviewModel {
  id?: string;
  indicator_id?: String;
  indicator?:IndicatorProfileModel
  name: string;
  operation: string;
  factorSupport: string;
  factorObstacle: string;
  results: Array<ReviewResultModel>;;
  evidences: Array<EvidenceModel>;
  attachments: Array<ReviewAttachmentModel>;
  reportName: string;
  reportPosition: string;
  position: string;
  createdIP: string;
  modifiedIP: string;
  createdAt: Date;
  modifiedAt: Date;

  constructor(obj: any) {
    let results: Array<ReviewResultModel> = []
    try {
      results = obj.results.map((m: any) => new ReviewResultModel(m))
    } catch (error) {

    }
    let evidences: Array<EvidenceModel> = []
    try {
      evidences = obj.evidences.map((m: any) => new EvidenceModel(m))
    } catch (error) {

    }

    this.id = obj.id
    this.indicator_id = obj.indicator_id
    this.name = obj.name
    this.operation = obj.operation
    this.results = results
    this.factorSupport = obj.factorSupport;
    this.factorObstacle = obj.factorObstacle;
    this.evidences = evidences;
    this.attachments = obj.attachments;
    this.reportName = obj.reportName;
    this.reportPosition = obj.reportPosition;
    this.position = obj.position;
    this.indicator = obj.indicator;
    this.createdIP = obj.createdIP
    this.modifiedIP = obj.modifiedIP
    this.createdAt = obj.createdAt
    this.modifiedAt = obj.modifiedAt
  }

  toJSON() {
    let obj = Object.assign({}, this)


    return JSON.parse(JSON.stringify(obj))
  }
}
export class ReviewResultModel {
  id?: string;
  name?: string;
  result: string;
  score: string

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name;
    this.result = jsonData.result;
    this.score = jsonData.score;
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id

    return JSON.parse(JSON.stringify(obj))
  }
}
export class EvidenceModel {
  id?: string;
  name: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name;

  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    return JSON.parse(JSON.stringify(obj))
  }
}
export class ReviewAttachmentModel {
  id?: string;
  path: string;
  size: number;
  constructor(jsonData: any) {
    this.id = jsonData.id
    this.path = jsonData.path ?? ""
    this.size = jsonData.size ?? 0
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return JSON.parse(JSON.stringify(obj))
  }
}
