import ADDRESS from "@/setting";
import axios from "axios"
import AttendantModel from "@/model/attendant";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class AttendantServices {
  public getAttendantList(query: any) {
    return new Promise<PaginateResponse<AttendantModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/attendants/paginate`, query);
      console.log( response.data ,"jjjjj")
      let docs: Array<AttendantModel> = response.data.docs.map((el: any) => new AttendantModel(el))

      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<AttendantModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }
  

  public getAttendantSingle(id: string) {
    return new Promise<AttendantModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/attendant/get/${id}`);
      let doc: AttendantModel = new AttendantModel(response.data.doc)
      resolve(doc)
    })
  }

  public updateAttendant(id: string, Attendant: AttendantModel) {
    console.log('updated')
    return new Promise<AttendantModel>(async (resolve, reject) => {
      console.log('response1',id)
      Attendant.outTime = new Date()
      let response = await axios.patch(`${ADDRESS}/attendant/update/${id}`, Attendant.toJSON());
      console.log('response2',response)
      let doc: AttendantModel = new AttendantModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public createAttendant(Attendant: AttendantModel) {
    return new Promise<AttendantModel>(async (resolve, reject) => {
      console.log('response1',Attendant)
      let response = await axios.post(`${ADDRESS}/attendant/create/`, Attendant);
      console.log('response2',response)
      let doc: AttendantModel = new AttendantModel({})
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
